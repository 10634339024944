import React, {useState, useRef, useEffect} from 'react';
import {Select, Label, SelectTitle, Img, CustomCheckbox} from './multi-select';
import DownArrow from '../../../components/Multiselect/elements/down-arrow.svg';

type Props = {
  title: String,
  values: Array,
  selectedIds: Array,
  onSelectOptions: Array,
  id: any,
  onSelectOptions: Function,
  disabled: Boolean,
};

export default function Dropdown({title, values, selectedIds, id, onSelectOptions, disabled}: Props) {
  const [expanded, setExpanded] = useState(false);

  const node = useRef();

  const onToggle = index => {
    const newItems = values;

    if (selectedIds?.includes(newItems[index].id)) {
      const ids = selectedIds.filter(item => {
        return item !== newItems[index].id;
      });
      onSelectOptions(ids);
    } else {
      const ids = selectedIds?.concat(newItems[index].id);
      onSelectOptions(ids);
    }
  };

  const showCheckboxes = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', showCheckboxes);
    return () => {
      document.removeEventListener('mousedown', showCheckboxes);
    };
  }, []);

  return (
    <div ref={node}>
      <SelectTitle
        className={disabled ? 'inactive' : [expanded ? 'active' : '']}
        aria-hidden="true"
        onClick={() => setExpanded(!expanded)}
      >
        <span>{title}</span>
        <Img src={DownArrow} />
        <div className="overSelect" />
      </SelectTitle>
      {expanded && !disabled && (
        <Select id={id}>
          {values.map((item, i) => (
            <Label htmlFor={id + i} className="checkLabel" key={item.id}>
              <input
                type="checkbox"
                id={id + i}
                checked={selectedIds?.includes(item.id)}
                onChange={onToggle.bind(this, i)}
              />
              {item.name}
              <CustomCheckbox />
            </Label>
          ))}
        </Select>
      )}
    </div>
  );
}
