import React, {useEffect, useState} from 'react';
import Tour from 'reactour';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import './style.css';
import {observer} from 'mobx-react-lite';
import TutorialDiv from '../elements/tutorial';
import {useStore} from '../../../store';

const HomeTutorial = observer(({isTutorialOpen, isExpand, isCollapse, setIsTourOpen}: boolean) => {
  const {accountStore} = useStore();
  const {userTutorialChanged} = accountStore;
  const [version, setVersion] = useState('desk');
  const [searchVersion, setSearchVersion] = useState('desk');
  const [expand, setExpand] = useState('');
  const [collapse, setCollapse] = useState('');

  useEffect(() => {
    if (isExpand) setExpand('_expand');
    else setExpand('');
    if (isCollapse) setCollapse('_collapse');
    else setCollapse('');
  }, [isExpand, isCollapse]);

  useEffect(() => {
    if (window.innerWidth > 1200) {
      setVersion(`desk${expand}`);
      setSearchVersion('desk');
    } else if (window.innerWidth >= 768) {
      setVersion(`tablet${collapse}`);
      setSearchVersion('desk');
    } else {
      setVersion('mobile');
      setSearchVersion('mobile');
    }
  }, [version, expand, collapse]);

  window.addEventListener('resize', () => {
    if (window.innerWidth > 1200) {
      setVersion(`desk${expand}`);
      setSearchVersion('desk');
    } else if (window.innerWidth >= 768) {
      setVersion(`tablet${collapse}`);
      setSearchVersion('desk');
    } else {
      setVersion('mobile');
      setSearchVersion('mobile');
    }
  });

  const tutorialConfig = [
    {
      content: () => (
        <div className="tutorial">
          <h3>Welcome to GIBLIB!</h3>
          <p>
            Take a quick tour through GIBLIB to see everything you can do with your plan. Updates are made frequently.
          </p>
        </div>
      ),
    },
    {
      selector: `[data-tut='hometutorial__browse_${version}']`,
      content: () => (
        <div className="tutorial">
          <div className="name">
            <span className="icon browse">
              <i className="fas fa-video" />
            </span>
            <span>Browse</span>
          </div>
          <p>
            Browse through different specialties, organizations, experts, and much more to find the content you want to
            watch.
          </p>
        </div>
      ),
    },
    {
      selector: `[data-tut="hometutorial__bookmark_${version}"]`,
      content: () => (
        <div className="tutorial">
          <div className="name">
            <span className="icon bookmark">
              <i className="fak fa-bookmark-solid" />
            </span>
            <span>Bookmarks</span>
          </div>
          <p>Bookmark videos and conferences to quickly access and learn when it is convenient for you.</p>
        </div>
      ),
    },
    {
      selector: `[data-tut="hometutorial__cme_${version}"]`,
      content: () => (
        <div className="tutorial">
          <div className="name cme-title">
            <div className="cme-title">
              <span className="icon cme">
                <i className="fak fa-cme-1" />
              </span>
              <span>CMEs</span>
            </div>
            <div className="cme-premium">Premium Plans Only</div>
          </div>
          <p>Earn CME credits by watching and completing assessments on accredited videos.</p>
        </div>
      ),
    },
    {
      selector: `[data-tut="hometutorial__playlist_${version}"]`,
      content: () => (
        <div className="tutorial">
          <div className="name">
            <span className="icon playlist">
              <i className="fak fa-list-ul-solid" />
            </span>
            <span>Playlists</span>
          </div>
          <p>
            Save, organize, and watch content to help accomplish your learning objectives. Create unlimited playlists.
          </p>
        </div>
      ),
    },
    {
      selector: `[data-tut="hometutorial__search_${searchVersion}"]`,
      content: () => (
        <div className="tutorial">
          <div className="name">
            <div className="icon search">
              <i className="far fa-search" />
            </div>
            <span>Search</span>
          </div>
          <p>Search for keywords, names, specialty, and much more to find the most relevant content.</p>
        </div>
      ),
    },
    {
      selector: '[data-tut="hometutorial__learning"]',
      content: () => (
        <div className="tutorial">
          <div className="name">
            <span className="icon learning">
              <i className="fak fa-fire-alt-light" alt="learning" />
            </span>
            <span>Personal Dashboard</span>
          </div>
          <p>Track your personal activity and progress.</p>
        </div>
      ),
    },
  ];

  const accentColor = '#5cb7b7';
  const closeTour = () => {
    setIsTourOpen(false);
    userTutorialChanged('show_homepage_tutorial', true);
  };
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  return (
    <TutorialDiv>
      <Tour
        onRequestClose={closeTour}
        steps={version.includes('desk') ? tutorialConfig : tutorialConfig.slice(0, 7)}
        startAt={0}
        isOpen={isTutorialOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        // lastStepNextButton={<Button variant="primary">Done!</Button>}
      />
    </TutorialDiv>
  );
});

export default HomeTutorial;
