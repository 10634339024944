import styled from 'styled-components';
import React from 'react';
import {Container} from 'react-bootstrap';

const Style = styled.div`
  background: ${({theme}) => (theme.name === 'light' ? '#F9F9F9' : '#181A21')};

  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
  }

  button {
    box-sizing: border-box;
    padding: 0.3em 0.6em;
    border: ${({theme}) => {
      const color = theme.name === 'light' ? '#B8BBBE' : '#4e505a';
      return `1px solid ${color}`;
    }};
    border-radius: 4px;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#ffffff')};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 22px;
  }

  .title {
    font-size: 1.25em;
    font-weight: 500;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

type Props = {
  children: any,
};

export default ({children}: Props) => {
  return (
    <Style>
      <Container>{children}</Container>
    </Style>
  );
};
