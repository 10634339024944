import amplitude from 'amplitude-js';

export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY, null, {
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
  });
};

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = userId => {
  let amplitude_user_id = userId;
  if (!amplitude_user_id && localStorage.user) {
    const user = JSON.parse(localStorage.user);
    amplitude_user_id = user.id;
  }
  amplitude.getInstance().setUserId(amplitude_user_id);
};

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeEventData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

export {amplitude};
