import React from 'react';
import {Container} from 'react-bootstrap';
import {CourseDescContainer} from '../elements/courseLanding';

type Props = {
  text: String,
};
const CourseDescription = ({text}: Props) => {
  const newText = text ? text.replace(/'/g, '').replace(/\\n/g, '').replace(/\+/g, '') : '';
  return (
    <Container>
      <CourseDescContainer dangerouslySetInnerHTML={{__html: newText}} />
    </Container>
  );
};

export default CourseDescription;
