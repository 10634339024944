// @flow
import {makeAutoObservable} from 'mobx';
import _ from 'lodash';
import type {Product, PromoCode, RetryInvoiceBody, CreateSubscriptionBody} from '../../utils/types';
import * as Api from '../../api/subscription';
import {updatePayment, updateSubscription} from '../../api/account';
import AuthStore from '../auth/authStore';
import AccountStore from '../account/accountStore';

class SubscriptionStore {
  authStore: AuthStore;

  errorStore: ErrorStore;

  accountStore: AccountStore;

  productsLoading = false;

  updateSubscriptionCompleted = false;

  promoCodeError = null;

  products: Product[] = [];

  promoCode: PromoCode | null;

  latestInvoicePaymentIntentStatus = null;

  isSubscribing = false;

  isSkipTrial = false;

  subscription: any;

  paymentMethod: any;

  invoice: any;

  constructor({authStore, accountStore, errorStore}) {
    this.authStore = authStore; // Store that can resolve auth.
    this.errorStore = errorStore; // Store that can resolve auth.
    this.accountStore = accountStore; // Store that can resolve account.
    makeAutoObservable(this);
  }

  /**
   * set promo code observables
   * @param {string} code
   * @param {string} error
   */
  setProductsObservables = (products: Product[]) => {
    this.productsLoading = true;
    this.products = [
      ...products
        .filter(item => item.metadata.display_name)
        // sort prices based on display_order metadata
        .map(product => {
          let productPrices = product?.prices;
          if (productPrices?.length) {
            productPrices = product.prices.sort((a, b) => a?.metadata?.display_order - b?.metadata?.display_order);
          }
          return {
            ...product,
            prices: productPrices,
          };
        }),
    ];
  };

  /** Fetch All products */
  fetchStripeProducts = async () => {
    this.productsLoading = true;
    try {
      const resp = await Api.listProducts();
      const products = [...resp.data.products].sort(
        (a, b) => parseInt(a.metadata.order, 10) - parseInt(b.metadata.order, 10),
      );
      this.setProductsObservables(products);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All products + inst premium */
  fetchInstStripeProducts = async () => {
    this.productsLoading = true;
    try {
      const resp = await Api.listProducts();
      const products = [
        ...resp.data.products.reverse(),
        {
          id: 'inst-premium',
          metadata: {
            display_name: 'Institutional',
          },
        },
      ];
      this.setProductsObservables(products);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set promo code observables
   * @param {string} code
   * @param {string} error
   */
  setPromoCodeObservables = (code: PromoCode | null, error: string | null) => {
    this.promoCode = code;
    this.promoCodeError = error;
  };

  /**
   * Check promoCode valid
   * @param {string} code
   */
  checkPromoCode = async (code: string) => {
    try {
      const resp = await Api.checkPromoCode(code);
      this.setPromoCodeObservables(resp.data.promoCode, null);
    } catch {
      this.setPromoCodeObservables(null, 'Invalid coupon');
    }
  };

  resetCodes = () => {
    this.promoCode = null;
    this.promoCodeError = null;
  };

  // set subscribing boolean
  startSubscribing = () => {
    this.isSubscribing = true;
  };

  // unset subscribing boolean
  stopSubscribing = () => {
    this.isSubscribing = false;
  };

  setPaymentMethod = (paymentMethod: any) => {
    this.paymentMethod = paymentMethod;
  };

  setSubscription = (subscription: any) => {
    this.subscription = subscription;
  };

  setInvoice = (invoice: any) => {
    this.invoice = invoice;
  };

  setIsSkipTrial = (skipTrial: boolean) => {
    this.isSkipTrial = skipTrial;
  };

  retrySubscription = async () => {
    const email = this.authStore?.user?.email;
    const customerId = this.authStore?.user?.stripe_id;
    const invoiceId = this.invoice?.id;
    const paymentMethodId = this.paymentMethod?.id;
    if (!email || !customerId || !invoiceId) {
      throw new Error('Invalid data');
    }
    const body: RetryInvoiceBody = {
      email,
      paymentMethodId,
      customerId,
      invoiceId,
    };
    return Api.retrySubscription(body);
  };

  createSubscription = async (
    skipTrial: boolean,
    address: string | null,
    state: string | null,
    city: string | null,
    zipcode: string | null,
    mobilephone: string | null,
  ) => {
    const email = this.authStore?.user?.email || this.accountStore.account?.email;
    const customerId = this.authStore?.user?.stripe_id || this.accountStore.account?.stripe_id;
    const priceId = this.authStore?.selectedPrice?.id;
    const giftCardPriceId = this.authStore?.selectedGiftCard?.id;
    const paymentMethodId = this.paymentMethod?.id;
    const plan = this.authStore?.selectedPrice?.metadata?.id;
    if (!email || !customerId || !priceId) {
      throw new Error('Invalid data');
    }
    const body: CreateSubscriptionBody = {
      email,
      paymentMethodId,
      customerId,
      priceId,
      giftCardPriceId,
      plan,
      skipTrial,
      shipping_address: address,
      shipping_state: state,
      shipping_city: city,
      shipping_zip: zipcode,
      mobilephone,
    };
    if (this.promoCode?.id) {
      body.promoCode = this.promoCode?.id;
    }
    if (this.promoCode?.isJustCoupon) {
      body.coupon = this.promoCode?.coupon?.name;
    }

    return Api.createSubscription(body).then(
      resp => {
        const subscription = {};
        subscription.name = resp?.data?.subscription.plan.nickname;
        subscription.status = resp?.data?.subscription.status;
        this.accountStore.account.subscription = subscription;
        return resp?.data?.subscription;
      },
      err => {
        throw (
          (err.response?.data && err.response?.data?.error?.raw) || {
            message: 'Error',
          }
        );
      },
    );
  };

  updatePaymentDetails = async () => {
    const {id, card} = this.paymentMethod;
    try {
      const resp = await updatePayment(id);
      if (resp.status) {
        this.accountStore.paymentDetails = {card};
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setUpgradeCompletionFalse = () => {
    this.stopSubscribing();
    this.updateSubscriptionCompleted = false;
  };

  upgradeSubscription = async (
    couponStripeId,
    shipping_address,
    shipping_state,
    shipping_city,
    shipping_zip,
    mobilephone,
  ) => {
    this.updateSubscriptionCompleted = false;
    const skipTrial = false;
    const priceId = this.authStore?.selectedPrice?.id;
    let couponCode = null;
    if (couponStripeId) couponCode = this.promoCode?.coupon?.name;
    try {
      const resp = await updateSubscription(
        priceId,
        couponCode,
        couponStripeId,
        skipTrial,
        shipping_address,
        shipping_zip,
        shipping_city,
        shipping_state,
        mobilephone,
      );
      if (resp.status) {
        const {id, plan, status} = resp.data.updated_subscription;
        this.accountStore.subscription = {
          ...this.accountStore.subscription,
          id,
          name: plan.metadata.plan_name,
          status,
        };
        this.updateSubscriptionCompleted = true;
        this.accountStore.getAccount();
      }
    } catch (error) {
      this.errorStore.setError(error);
      throw (
        (error.response.data && error.response.data.error.raw) || {
          message: 'Error',
        }
      );
    }
  };

  skipTrialSubscription = async () => {
    try {
      const subscriptionId = this.accountStore.account?.subscription.id;
      await Api.skipTrial(subscriptionId);
      this.accountStore.getAccount();
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  get prices() {
    return _.compact(_.flatMap(this.products, 'prices'));
  }

  getGiftCardPrices = (price: Price | null) => {
    if (!price) return [];
    return _.filter(this.prices, p => p.metadata?.premium_price_metadata_id === price.metadata.id);
  };

  filteredPrices(priceIds) {
    return this.prices.filter(price => priceIds.includes(price.id));
  }

  filteredProducts(priceIds) {
    return this.products.filter(product => _.intersection(_.map(product.prices, 'id'), priceIds).length > 0);
  }
}

export default SubscriptionStore;
