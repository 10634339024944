import styled from 'styled-components';

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};

  .a_resultDiv {
    height: calc(100vh - 92px) !important;
  }
`;

export const SearchHomeDiv = styled.div`
  padding-top: 3rem !important;
  width: 100% !important;
  margin: 1.5rem !important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 23px) !important;

  .a_searchHomeFilter {
    input,
    label {
      cursor: pointer;
    }
  }

  .a_content {
    font-size: 15px;
    letter-spacing: -0.25px;
    line-height: 20px;
  }

  .a_contentRequest {
    color: #008aff;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding-top: 3rem !important;
    margin-left: 5rem !important;
    margin-right: 0px !important;
    width: auto !important;
  }

  @media screen and (max-width: 576px) {
    padding-top: 1rem !important;
    margin: 0px !important;

    .a_searchFilterCMEText {
      display: none;
    }
    .a_orgTile {
      width: 150px;
      height: 150px;
      margin-top: 20px !important;
    }
    .search-head {
      font-size: 18px;
    }
  }
`;

export const SearchExpertDiv = styled.div`
  padding-top: 3rem !important;
  width: 100% !important;
  margin: 1.5rem !important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  .a_filterHeadDiv {
    margin-bottom: 15px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding-top: 3rem !important;
    margin-left: 5rem !important;
    margin-right: 0px !important;
    width: auto !important;
  }

  @media screen and (max-width: 576px) {
    padding-top: 1rem !important;
    margin: 0px !important;

    .a_filterHeadDiv {
      flex-direction: column !important;
    }
  }
`;

export const SearchVideoDiv = styled.div`
  padding-top: 3rem !important;
  width: 100% !important;
  margin: 1.5rem !important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  .transcriptContainer {
    margin-top: 24px;
  }

  .a_searchVideoFilter {
    input,
    label {
      cursor: pointer;
    }
  }

  .a_filterHeadDiv {
    margin: 48px 0 15px;
  }

  .a_searchVideoCrumb {
    padding-left: 6px;
  }

  .a_searchVideoHead {
    padding-left: 6px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding-top: 3rem !important;
    margin-left: 5rem !important;
    margin-right: 0px !important;
    width: auto !important;
  }

  @media screen and (max-width: 576px) {
    padding-top: 1rem !important;
    margin: 0px !important;

    .a_filterHeadDiv {
      flex-direction: column !important;
    }

    .a_searchCmeFormGroup {
      margin-left: 0px !important;
      padding-left: 6px;
    }

    .a_searchSortBy {
      padding-left: 6px;
    }
  }
`;

export const SearchCourseDiv = styled.div`
  padding-top: 3rem !important;
  width: 100% !important;
  margin: 1.5rem !important;
  margin-right: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  .a_filterHeadDiv {
    margin-bottom: 15px;
  }

  .a_searchCourseCrumb {
    padding-left: 6px;
  }

  .a_searchCourseHead {
    padding-left: 6px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding-top: 3rem !important;
    margin-left: 5rem !important;
    margin-right: 0 !important;
    width: auto !important;
  }

  @media screen and (max-width: 576px) {
    padding-top: 1rem !important;
    margin: 0px !important;

    .a_filterHeadDiv {
      flex-direction: column !important;
    }

    .a_searchCmeFormGroup {
      margin-left: 0px !important;
      padding-left: 6px;
    }

    .a_searchSortBy {
      padding-left: 6px;
    }
  }
  }
`;

export const Breadcrumbs = styled.ul`
  list-style: none;
  font-weight: 600;
  padding-left: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  & > li:after {
    content: '${props => props.separator || '/'}';
    padding: 0 5px;
  }
`;

export const Crumb = styled.li`
  color: #b8bbbe;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.12px;
  line-height: 16px;

  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;

export const ActiveCrumb = styled.li`
  display: inline-block;
  color: ${({theme}) => (theme.body === '#FFF' ? '#000' : '#fff')};
  font-weight: bold;
  letter-spacing: -0.12px;
  font-size: 16px;
  line-height: 20px;

  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;

export const PageTitle = styled.h5`
  font-weight: 600;
  margin-bottom: 0px;
  height: 1em;

  @media screen and (max-width: 576px) {
    max-width: 100% !important;
  }
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 80px 8px 16px;
  border-radius: 30px;
  margin-top: 0.1em;
  width: 250px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  &:focus {
    outline: 0;
  }
`;

export const FormLabel = styled.h5`
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
`;

export const CourseFilter = styled.div`
  .form-group {
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: auto;
    margin-right: 12px;
  }

  @media screen and (max-width: 576px) {
    max-width: 100% !important;
    padding: 0px;
    margin-top: 10px;

    .form-row {
      margin-left: 0px;
    }

    .a_searchExpertSortBy {
      margin-left: 0px;
      margin-top: 10px !important;
    }

    .a_searchExpertFilter {
      padding: 0px;
    }
  }
`;

export const Line = styled.div`
  height: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  margin: 20px 0;
`;
