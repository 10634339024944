import axios from './axios';

export const getUserMessage = () => axios.get('/message');

export const sendMessage = data =>
  axios.post('/message', data, {
    headers: {'Content-Type': 'multipart/form-data'},
  });
export const getSignedS3Url = (name, putOrGet) => axios.get(`/message/getSignedUrl`, {params: {name, putOrGet}});
export const fetchMessageList = user_id => axios.get(`/message/${user_id}`);
