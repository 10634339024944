import axios from './axios';

export const getBookmarks = (sortBy, page, count, searchText) =>
  axios.get(`/bookmark?sortBy=${sortBy}`, {
    params: {
      page,
      count,
      q: searchText,
    },
  });

export const getWatchedVideos = (sortBy, page, count, searchText) =>
  axios.get(`/watch-history/watched-videos/?sortBy=${sortBy}`, {
    params: {
      page,
      count,
      q: searchText,
    },
  });

export const deleteHistory = id => axios.delete(`/watch-history/${id}`);

export const getLikedVideos = (sortBy, page, count, searchText) =>
  axios.get(`/like/?sortBy=${sortBy}`, {
    params: {
      page,
      count,
      q: searchText,
    },
  });
