import axios from './axios';

/**
 * calls the getNotifications api
 */

export const getNotifications = () => {
  return axios.get('/notification').then(
    res => res?.data,
    err => err.response.data,
  );
};

/**
 * calls the readAllNotifications api
 */

export const readAllNotifications = () => {
  return axios.put('/notification/').then(
    res => res?.data,
    err => err.response.data,
  );
};

/**
 * calls the readNotification api
 */

export const readNotification = id => {
  return axios.put('/notification/read', {notification_id: id});
};
