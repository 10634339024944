import styled from 'styled-components';
import {Modal} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

export const UpgradeModal = styled(Modal)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  .modal-dialog {
    max-width: 459px !important;
    min-height: 225px !important;
    margin: 7.75rem auto !important;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;
    height: 273px;
    width: 375px;
    border-radius: 8px;
    margin-left: 3rem !important;

    .a_iconDiv {
      height: 50px;
      width: 50px;
      background-color: #35cb95;
      border-radius: 30px;
      margin-top: 23px;
      margin-bottom: 9px;
    }
    .a_cmeIcon {
      width: 33px;
      height: 33px;
    }
    .a_upgradeModalHeader {
      //Beta version
      width: 267px;
      // width: 179.52px;
      color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#dcdee1')};
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 2px;
    }

    .a_upgradeModalContent {
      width: 276px;
      color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#dcdee1')};
      font-size: 15px;
      letter-spacing: -0.1px;
      line-height: 20px;
      text-align: center;
    }
    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-body {
      padding: 14px;
    }
    .modal-footer {
      border: none !important;
    }
    .a_upgradeButton {
      // Beta button style
      height: 32px;
      width: 194px;
      // height: 32px;
      // width: 105px;
    }
  }
  @media all and (max-width: 768px) {
    .modal-content {
      margin-left: 0 !important;
      max-width: 375px;
      width: 100%;
    }
  }
  &.paymentInfoModal .modal-dialog {
    max-width: 636px;
  }
`;

export const Main = styled.main`
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }
  display: flex;
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  position: relative;
  width: 100%;
  margin-right: 0;
  transition: margin-right 0.8s ease-in-out;
  height: 100%;
  @media screen and (min-width: 576px) {
    overflow: hidden auto;
  }
  .cme-Container {
    width: 100%;
  }
  &.sidetab-expanded .cme-Container {
    width: calc(100% - 380px);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .expanded {
    width: 400px;
    padding: 15px;
    background-color: ${({theme}) => (theme.name === 'light' ? '#f9f9f9' : '#252730')};
    border-left: 1px solid #4e505a;
    position: fixed;
    right: 0;
    padding-top: 80px;
    top: 0;
    height: 100vh;
  }

  .picker-select {
    @media screen and (max-width: 660px) {
      flex-direction: column;

      & div:first-child {
        margin-bottom: 3px;
      }
    }
  }
  .completed-sort {
    @media screen and (max-width: 576px) {
      margin-left: 10px;
    }
  }
  .q_expand {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .pagination-div {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const InformationDiv = styled.div`
  background-color: ${({theme}) => (theme.body === '#FFF' ? '#ededed' : '#252730')};
  border-bottom: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  padding-top: 80px;
  @media screen and (max-width: 576px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
`;

export const NavItem = styled.li`
  width: auto !important;
`;

export const Tab = styled.nav`
  background-color: ${({theme}) => (theme.name === 'light' ? '#f9f9f9' : '#252730')};
  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    & .nav {
      justify-content: center;
    }
  }
`;

export const Link = styled(NavLink)`
  color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#DCDEE1')};
  font-size: 17px;
  letter-spacing: -0.17px;
  line-height: 20px;
  text-align: center;
  padding: 18px 18px 15px 15px;
  &.active {
    font-weight: bold;
    border-bottom: 4px var(--primary) solid;
  }
  &:hover {
    color: ${({theme}) => theme.text};
  }
`;

export const DescriptionDiv = styled.div`
  padding: 0 15px 12px;
  @media screen and (max-width: 576px) {
    padding: 0 0 12px;
  }
  .a_cmeIcon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    @media screen and (max-width: 576px) {
      width: 28px;
      height: 28px;
    }
  }
  .a_setGoal {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  }
  h5 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.33px;
    line-height: 21px;
    margin-bottom: 0;
    @media screen and (max-width: 576px) {
      font-size: 16px;
    }
  }
  p {
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 20px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
    @media screen and (max-width: 576px) {
      font-size: 12px;
    }
  }
  h6 {
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
    font-weight: normal;
  }
`;

export const InfoTitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.62px;
  line-height: 46px;
  margin-bottom: 0;
`;

export const Logo = styled.div`
  height: 60px;
  width: 60px;
  background-color: #4e505a;
  border-radius: 50px;
  margin: auto;
  padding-top: 13px;
  margin-bottom: 16px;
  .a_cmeIcon {
    width: 33px;
    height: 33px;
  }
`;

export const NoCertificate = styled.p`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 24px;
  color: #b8bbbe;
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 7px 30px 7px 16px;
  border-radius: 30px;
  width: 160px;
  height: 40px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  font-weight: 500;

  &:active,
  &:focus {
    outline: 0;
  }
`;

export const ThumbnailDiv = styled.div`
  margin-right: 20px;
  .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }
`;

export const DetailDiv = styled.div`
  &.primary {
    margin-top: 16px;
  }
`;

export const VideoCardHeader = styled.div`
  margin-bottom: 12px;
  & .progress-badge {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    padding: 4px 6px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    margin-right: 12px;
  }
  & .inProgress-badge {
    background: #008aff;
  }
  & .expired-badge {
    background: #ff5964;
  }
  & .completed-badge {
    background: #0dfff3;
    color: #050507 !important;
  }
  & .revoked-badge {
    background: #ff50a0;
  }
  & .credit {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ThumbnailImg = styled.img`
  width: 160px;
  height: 90.32px;
  cursor: pointer;
`;

export const ButtonGroup = styled.div`
  text-align: right;
  @media screen and (max-width: 768px) {
    &.button-alignment {
      text-align: left;
      margin-top: 21px;
    }
  }
  .action-icon-button {
    margin-bottom: 42px;
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    color: ${({theme}) => theme.text1};
  }
`;

export const VideoTitle = styled.div`
  margin-bottom: 4px;
  overflow: hidden;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    height: 33px;
  }
  &:hover {
    text-decoration: underline;
  }
  h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.16px;
    @media screen and (max-width: 576px) {
      font-size: 14px;
      letter-spacing: -0.14px;
      line-height: 16px;
    }
    margin-bottom: 0px;
  }
`;

export const Organization = styled.h6`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.1px;
  margin-top: 0px;
  margin-bottom: 7.79px;
  @media screen and (max-width: 576px) {
    font-size: 12px;
    letter-spacing: -0.08px;
  }
`;

export const Details = styled.p`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.1px;
  margin-bottom: 0;
  @media screen and (max-width: 576px) {
    font-size: 12px;
    letter-spacing: -0.08px;
  }

  & .expired {
    color: #ff5964;
  }

  & .revoked_span {
    color: #ff50a0 !important;
  }

  & .icon-cme {
    margin-right: 4px;
    margin-bottom: 4px;
    width: 19px;
    @media screen and (max-width: 576px) {
      width: 14px;
    }
  }
`;

export const VideoCard = styled.div`
  .container {
    position: relative;
  }

  .card-border-bottom {
    border-bottom: ${({theme}) => (theme.name === 'light' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  }
  .card-padding {
    padding: 16px 0;
  }
  .detail-container {
    padding-right: 30px;
    @media screen and (max-width: 768px) {
      padding-right: 0;
    }
  }
`;

export const UpdateModal = styled(Modal)`
  .modal-dialog {
    max-width: 400px;
    margin: 7.75rem auto !important;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;

    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-footer {
      border: none !important;
    }
    .close {
      color: ${({theme}) => theme.circle};
      opacity: 1;
      font-weight: 400;
    }
    .btn-outline-secondary {
      border-color: #4e505a;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.11px;
      padding: 4px 16px;
    }
    .a_removeVideoButton {
      color: ${({theme}) => theme.circle};
      @media screen and (max-width: 576px) {
        width: 117px;
      }
    }
    .a_removeVideoButton:hover {
      background-color: #008aff;
    }
    .deleteCheckbox {
      margin-top: 28px;
    }
  }
  &.addGoalModal .modal-header {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.1px;
    line-height: 20px;
    padding-bottom: 18px;
  }
  &.addGoalModal .modal-body {
    padding-top: 0;
  }
  &.addGoalModal label small {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
  &.deletePlaylistModal .modal-body {
    padding-top: 0;
  }
  &.deletePlaylistModal #deleteCheckbox {
    margin-top: 28px;
  }
  @media screen and (max-width: 576px) {
    &.deletePlaylistModal .modal-dialog {
      padding: 0 15px;
    }
  }
  &.deletePlaylistModal p {
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
    color: #dcdee1;
  }
  &.deletePlaylistModal .a_deletePlaylistButton {
    margin-top: 15px;
    color: ${({theme}) => theme.circle};
  }
  &.addGoalModal .modal-dialog {
    max-width: 520px;
    @media screen and (max-width: 576px) {
      padding: 0 15px;
    }
    .save_button_container {
      text-align: right;
      @media screen and (max-width: 576px) {
        text-align: center;
      }
    }
  }
  &.addGoalModal .rounded-pill {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 24px;
    color: #e9e9e9;
    background: rgb(101, 104, 110) none repeat scroll 0% 0%;
    border: none;
    height: 40px;
    padding: 10px 16px;
  }
  &.addGoalModal .rounded-pill::placeholder {
    color: #e9e9e9;
    font-size: 14px;
  }
  &.addGoalModal .a_save {
    background-color: #4e505a;
    color: #b8bbbe;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.11px;
    line-height: 16px;
    border: none;
    padding: 8px 17px;
    opacity: 0.5;
  }
  &.addGoalModal .a_save.btn-primary {
    background-color: #008aff;
    opacity: 1;
    color: #fff;
  }
  &.addGoalModal .a_clear {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    color: #b8bbbe;
  }
  &.addGoalModal .a_clear:hover {
    font-weight: bold;
    color: #fff;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    filter: ${({theme}) => (theme.body === '#FFF' ? 'invert(0)' : 'invert(1)')};
  }
  input[type='date']::-webkit-calendar-picker-indicator:focus {
    outline: none;
  }
`;

export const SortLabel = styled.label`
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 14px;
`;

export const Label = styled.label`
  display: block;
  font-size: 15px !important;
  line-height: 40px;
  margin-bottom: 0;
  color: ${({theme}) => theme.text};
  position: relative;
  // padding-left: 20px;
  padding-left: 30px;
  cursor: pointer;

  input[type='checkbox'] {
    visibility: hidden;
    display: none;
  }

  &:hover input ~ span {
    box-shadow: 0 0 3px #008aff;
  }

  input:checked ~ span {
    background-color: #008aff;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 5px;
    bottom: 3px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CustomCheckbox = styled.span`
  position: absolute;
  top: calc(50% - 8px);
  left: 7px;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid #008aff;
  box-shadow: 0 0 2px #008aff;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const SelectedCredits = styled.div`
  line-height: 40px;
  span {
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 20px;
    color: ${({theme}) => theme.text1};
  }
  .a_downloadCredit {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.11px;
    line-height: 16px;
  }
`;

export const DateFilter = styled.div`
  display: flex;

  .form-control,
  .rounded-pill {
    font-size: 14px;
    font-weight: 500;
    width: 128px;
    height: 40px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
    font-family: Roboto;
    background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
    border: 1px solid rgba(184, 187, 190, 0.4);
    padding: 8px 16px;

    @media screen and (max-width: 576px) {
      width: 115px;
      margin-left: 10px;
    }
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    filter: ${({theme}) => (theme.body === '#FFF' ? 'invert(0)' : 'invert(1)')};
  }
  input[type='date']::-webkit-calendar-picker-indicator:focus {
    outline: none;
  }
`;

export const CertificateFinishAssessment = styled.div`
  width: 400px;
  &.in-progress-assessment .expanded {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
    &.in-progress-assessment {
      display: block;
      .expanded {
        width: 100%;
        position: absolute;
        top: -30px;
        height: auto;
        padding-top: 15px;
        min-height: calc(100vh - 200px);
        display: block;
      }
    }
  }
`;
