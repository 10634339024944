import styled from 'styled-components';

export const VideoContainer = styled.div`
  height: 236px;
  width: 262px;
  border-radius: 8px;
  background-color: #2e3038;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
`;

export const CardWrapper = styled.div`
  height: 100%;
  backgroud: tranpsarent;
  .card {
    border-radius: 3px !important;
    @media screen and (max-width: 576px) {
      width: 158px;
    }
  }
  .unlock-wrapper {
    position: absolute;
    width: 38px;
    height: 38px;
    right: 12px;
    top: 12px;
    width: 38px;
    height: 38px;
    background: rgba(24, 26, 33, 0.85);
    border-radius: 34px;
    overflow: hidden;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    padding: 8px;
  }
  .unlock-icon {
    margin: auto;
  }

  & .card-img-overlay {
    padding: 8px 6px;
    @media screen and (max-width: 576px) {
      padding: 4px 3px;
    }
  }

  & .card-img {
    height: 138px;
    object-fit: cover;
    @media screen and (max-width: 576px) {
      width: 158px;
      height: 88px;
      object-fit: cover;
    }
  }

  & .card-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background: #181a21;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 576px) {
      padding: 6px;
    }
  }

  & .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  & .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }

  & .hover-card {
    border-radius: 0px;
    overflow: hidden;
    cursor: pointer;
    height: calc(100% - 1rem);
    background-color: ${({theme}) => (theme.name === 'light' ? '#f9f9f9' : '#2e3038')};
    box-shadow: 0 4px 10px 0 ${({theme}) => (theme.name === 'light' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.7)')};
  }

  & .hover-card-img {
    border-radius: 0px;
  }

  & .icon-lock {
    height: 40px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
  }
`;

export const Badge = styled.div`
  display: flex;
  height: 30px;
  justify-content: end;

  & img {
    @media screen and (max-width: 576px) {
      height: 14px;
    }
  }

  & .icon-VR {
    margin-top: auto;
    margin-bottom: auto;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    width: 30px;
    margin-right: 8px;
  }

  & .icon-giblib {
    margin-top: auto;
    margin-bottom: auto;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    height: 20px;
    margin-right: 8px;
  }
  .ApprovedIndicator {
    padding: 2px 8px;
    position: absolute;
    right: 0;
    top: 10%;
    border-radius: 8px 0 0 8px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    text-transform: lowercase;
    text-transform: capitalize;
  }
  // .ApprovedIndicatoronHover {
  //   position: absolute;
  //   right: 0;
  //   top: 10%;
  // }
  .APPROVED {
    background: #137c06;
  }
  .REJECTED {
    background: #9d0a0a;
  }
  .PENDING {
    background: #3085d1;
  }
`;

export const BadgeNew = styled.div`
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 12px;
  padding: 4px 6px;
  background: linear-gradient(90deg, #08d1f8 0%, #008aff 100%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-right: 8px;

  @media screen and (max-width: 576px) {
    height: 18px;
    font-size: 10px;
  }
`;

export const DurationText = styled.div`
  display: flex;
  width: 39px;
  height: 19px;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 4px;
  position: absolute;
  top: 109px;
  right: 12px;
  background: rgba(24, 26, 33, 0.85);
  border-radius: 4px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 576px) {
    top: 61px;
    right: 8px;
  }
`;

export const Title = styled.div`
  min-height: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({theme}) => (theme.name === 'light' ? '#2e3038' : '#f9f9f9')};
  letter-spacing: -0.2px;
  margin-top: 16px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 44px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    .title-tip {
      font-size: 13px;
      font-weight: 300;
      display: block;
      position: absolute;
      top: 5px;
      left: 5%;
      padding: 5px;
      -webkit-line-clamp: 2;
      font-size: 12px;
      color: #fff;
      background-color: #4e505a;
    }
  }
  .title-tip {
    display: none;
    max-width: 240px;
    white-space: normal !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 1.2em;
    height: 2.4em;
    margin: 4px 0;
    max-height: 2.4em;
  }
`;

export const Text = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: ${({theme}) => (theme.name === 'light' ? '#2e3038' : '#f8f8f8')};
  letter-spacing: -0.13px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  height: 18px;
  white-space: nowrap;
  margin-bottom: 0;
  &.f-expert {
    font-weight: 500;
    font-size: 13px;
    margin-top: 2px;
  }
  span::after {
    content: ', ';
  }
  span:last-child::after {
    content: '';
  }
  .specialties-tip {
    display: none;
    max-width: 240px;
    white-space: normal !important;
  }
  &:hover {
    .specialties-tip {
      display: block;
      position: absolute;
      top: 5px;
      left: 5%;
      padding: 5px;
      -webkit-line-clamp: 2;
      font-size: 12px;
      color: #fff;
      background-color: #4e505a;
    }
  }
  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 9px;
    height: 9px;
  }
`;

export const TitlePopover = styled.div`
  min-height: 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({theme}) => theme.title};
  margin-bottom: 8px;
`;

export const TextPopover = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${({theme}) => theme.title};
  margin-bottom: 8px;
`;

export const Description = styled.span`
  font-size: 13px;
  color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#e9e9e9')};
  letter-spacing: -0.13px;
  line-height: 18px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 54px;

  .searchterm {
    background-color: #ff6600;
    color: ${({theme}) => theme.descript};
  }

  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 9px;
    max-height: 27px;
  }
`;

export const PopoverDiv = styled.div`
  background: transparent;
  .lock-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 217px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.4;
      z-index: 0;
    }
  }
  .lock-link {
    position: relative;
    z-index: 1;
    height: 62px;
    width: 62px;
    border: 3px solid #fff;
    border-radius: 2rem;
    background-color: #a7a7a791;
    display: flex;
    align-items: center;
    justify-content: center;
    .lock-icon {
      color: #fff;
      font-size: 32px;
    }
  }
  .a_transcript {
    max-width: 275px;
  }

  & .popover-body {
    padding: 0;
    border-radius: 0px;
  }
  .card-img {
    height: 217px;
    object-fit: cover;
  }

  & .popover-card {
    width: ${({width}) => `${width}px`};
    min-height: ${({height}) => `${height}px`};
    max-height: 553px;
    box-shadow: ${({theme}) =>
      theme.name === 'light' ? '0 8px 12px 0 rgba(0,0,0,0.2)' : '0 4px 10px 0 rgba(0,0,0,0.7)'};
    background-color: ${({theme}) => (theme.name === 'light' ? '#f9f9f9' : '#2e3038')};
    border-radius: 0px;
    border: none !important;
    & .card-body {
      background: linear-gradient(180deg, rgba(5, 5, 7, 0.75) 56.59%, rgba(0, 138, 255, 0.75) 177.91%);
      position: relative;
      padding: 16px;
    }

    & .card-img-overlay {
      cursor: pointer;
    }
  }

  & .popover-card-img {
    border-radius: 0px;
  }

  & .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  & .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }

  & .fa-play {
    color: #ffffff;
    font-size: 36px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
  }

  & .icon-lock {
    height: 80px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
  }

  & .popover-top {
    & svg {
      color: #ffffff;
      font-weight: 300;
      font-size: 18px;
      line-height: 18px;
    }

    & .follow-text {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.9px;
      padding: 4px 7.5px;
      font-weight: bold;
    }
  }

  & .fa-layers {
    width: 32px;
  }

  & .user-cme-credit {
    display: block;
    padding: 1.5px 3px 1.5px 4px;
    border: 1px solid #35cb95;
    border-radius: 4px;
    color: #35cb95;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: -0.09px;
    text-align: center;
  }

  & .fa-check-circle {
    margin-left: 2px;
  }

  & .fa-cme {
    height: 32px;
    width: 32px !important;
    vertical-align: bottom;
    color: ${({theme}) => theme.card_img};
  }

  & .card-body-bottom {
    .icon-cme {
      margin-top: auto;
      margin-bottom: auto;
      width: 28px;
      margin-right: 6px;
      @media screen and (max-width: 576px) {
        width: 26px;
        height: 26px;
      }
    }
    .card-desc {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    .fa-calendar {
      margin-right: 6px;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #a3a3a9;
    }
    .organization-img-div {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 24px;
      overflow: hidden;
    }
    .organization-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .userUploadImage {
      width: 40px;
      height: 40px;
      transform: rotate(270deg);
      object-fit: contain;
    }
    .organization {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .videotitle {
    margin: 8px 0px 8px;
    font-size: 16px;
  }

  .a_transcriptsListDiv {
    overflow-y: scroll;
    max-height: 201px;
    margin-left: -20px;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: none;
    }

    &::-webkit-scrollbar-thumb {
      background: none;
    }
  }

  .a_transcriptsListScroll {
    margin-right: -14px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #4e505a;
    }

    &::-webkit-scrollbar-thumb {
      height: 31px;
      background: #008aff;
      border-radius: 50px;
    }
  }

  label {
    width: 100%;
    padding: 7px;
  }
  .form-check-input {
    display: none;
  }
  input:checked ~ label::after {
    content: '';
    left: 10px;
    bottom: 10px;
    width: 6px;
    height: 11px;
    border: solid white;
    position: absolute;
    transform: rotate(45deg);
    display: block;
    border-width: 0 2px 2px 0;
  }
  .form-check:hover {
    background-color: #4e505a;
  }

  .a_selectLanguage span {
    width: 4ch;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
  .a_selectLanguage {
    border: 1px solid ${({theme}) => theme.content_active_bg};
    width: 69px;
    text-align: left;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
    font-size: 12px;
    padding-left: 10px;
    padding-top: 6px;
    margin-top: 38px;
    position: relative;
    padding-bottom: 0;
  }
  .a_selectLanguage:hover,
  .a_selectLanguage:active,
  .a_selectLanguage:focus {
    font-size: 12px;
    background-color: #252730;
    color: #dcdee1;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 8px;
    top: 13px;
  }
  .dropdown-menu {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 65px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 16px;
    min-width: 5rem;
  }
  .dropdown {
    display: inline-block;
    position: absolute;
    right: 14px;
    top: 20px;
  }
`;

const handleColorType = status => {
  switch (status) {
    case 'Completed':
      return '#0dfff3';
    case 'Revoked':
      return '#ff5964';
    default:
      return '#ffffff';
  }
};

export const CreditDiv = styled.div`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px;
  color: ${({theme}) => (theme.name === 'light' ? '#2e3038' : props => handleColorType(props.status))};
`;

export const TranscriptCard = styled.div`
  color: ${({theme}) => theme.text1};
  padding: 1px;
  padding-left: 16px;

  &:hover {
    background: #4e505a;
  }

  .a_transcript {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  .a_time {
    font-size: 12px;
    margin-top: 7px;
    margin-bottom: -5px;
  }
`;

export const CardBottom = styled.div`
  display: flex;
  margin-top: 16px;
  position: relative;

  & .icon-cme {
    margin-top: auto;
    margin-bottom: auto;
    width: 28px;
    margin-right: 8px;
    @media screen and (max-width: 576px) {
      width: 26px;
      height: 26px;
    }
  }

  & .card-desc {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }

  .fa-calendar {
    margin-right: 6px;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #a3a3a9;
  }

  .organization-img-div {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 24px;
    overflow: hidden;
  }
  .organization-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .userUploadImage {
    width: 35px;
    height: 35px;
    transform: rotate(270deg);
    object-fit: contain;
  }
  .organization {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
  .date-span {
    overflow: hidden;
    width: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default {
  VideoContainer,
  CardWrapper,
  Badge,
  BadgeNew,
  Title,
  Text,
  Description,
  PopoverDiv,
  CardBottom,
};
