import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Button} from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../store';
import {
  PopoverWrapper,
  MobilePopoverDiv,
  DeskPopoverDiv,
  TitleDiv,
  Title,
  CreateButton,
  AddButton,
  Input,
} from './styles';
// import PlaylistForm from '../../pages/Playlist/components/PlaylistForm';
import UpgradeModal from '../UpgradeModal';
import {amplitude} from '../../utils/Amplitude';

const PlaylistPopover = observer(
  ({onExisting, onNew, videoPlaylist, props, userState, showUpgradeModal, onHideUpgradeModal}: any) => {
    const [isNew, setIsNew] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [checkCount, setCheckCount] = useState(0);
    const [isExisting, setIsExisting] = useState(false);
    const [editablePlaylists, setEditablePlaylists] = useState([]);
    const [isShowAddPlaylistModal, setShowAddPlaylistModal] = useState(false);

    const {register: createRegister, handleSubmit: createSubmit, formState: createFormState, watch} = useForm({
      mode: 'onChange',
    });
    const watchField = watch('listname');

    const {accountStore, playlistStore} = useStore();
    const {userSettings, account} = accountStore;
    const desktopUpdateForm = useForm();
    const mobileUpdateForm = useForm();
    const allPlaylist = playlistStore.playlist;

    useEffect(() => {
      setEditablePlaylists(allPlaylist?.filter(p => p.user_id == account.id));
    }, [account.id, allPlaylist]);

    useEffect(() => {
      playlistStore.getAllPlaylists();
    }, [playlistStore]);

    const handleAdd = data => {
      onNew(data);
      document.body.click();
    };

    const updatePlaylist = async formData => {
      const data = await Object.keys(formData).map(key => ({
        playlistId: key,
        value: formData[key],
      }));
      onExisting(data);
      document.body.click();
    };

    useEffect(() => {
      if (videoPlaylist?.length) {
        setIsExisting(true);
        setCheckCount(videoPlaylist.length);
      }
    }, [videoPlaylist]);

    const addPlaylist = check => {
      if (check) {
        setCheckCount(checkCount + 1);
        setIsAdd(checkCount + 1 > 0);
        amplitude.getInstance().logEvent('playlist created');
      } else {
        setCheckCount(checkCount - 1);
        if (isExisting) {
          setIsAdd(true);
        } else {
          setIsAdd(checkCount - 1 > 0);
          amplitude.getInstance().logEvent('playlist deleted');
        }
      }
    };

    const showAddPlaylistModal = () => {
      setShowAddPlaylistModal(!isShowAddPlaylistModal);
    };

    const renderNewPlaylistForm = () => (
      <>
        <Title>Add to Playlist</Title>
        <form onSubmit={createSubmit(handleAdd)}>
          <Input
            placeholder="New Playlist Name"
            className={`w-100 ${watchField ? 'text-dark border' : 'bg-secondary border-0'}`}
            name="listname"
            ref={createRegister({required: true})}
            watch={watchField}
          />
          <AddButton
            className={`d-block mr-0 ml-auto ${
              watchField
                ? `border ${userSettings?.selected_theme === 'light' ? 'bg-primary' : 'bg-transparent'}`
                : 'bg-secondary border-0'
            }`}
            type="submit"
            disabled={!createFormState.isValid}
            watch={watchField}
          >
            Create and Add
          </AddButton>
        </form>
      </>
    );

    const renderExistingPlaylistForm = () => (
      <>
        <TitleDiv>
          <Title>Add to Playlist</Title>
          <CreateButton onClick={() => setIsNew(true)}>Create New Playlist</CreateButton>
        </TitleDiv>
        <form onSubmit={desktopUpdateForm.handleSubmit(updatePlaylist)}>
          <ul>
            {editablePlaylists?.map(item => {
              return (
                <li key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    name={item.id}
                    onChange={e => addPlaylist(e.target.checked)}
                    onClick={e => !e.target.checked}
                    defaultChecked={videoPlaylist?.find(vp => vp.id === item.id)}
                    ref={desktopUpdateForm.register()}
                  />
                  <label htmlFor={item.name}>{item.name}</label>
                </li>
              );
            })}
          </ul>
          <AddButton
            className={`d-block mr-0 ml-auto ${
              isAdd
                ? `border ${userSettings?.selected_theme === 'light' ? 'bg-primary' : 'bg-transparent'}`
                : 'bg-secondary border-0'
            }`}
            type="submit"
            disabled={!isAdd}
            watch={isAdd}
          >
            Save
          </AddButton>
        </form>
      </>
    );

    const renderMobilePlaylistForm = () => (
      <>
        <div className="title">Add to Playlist</div>
        <form onSubmit={mobileUpdateForm.handleSubmit(updatePlaylist)}>
          <ul>
            {editablePlaylists?.map(item => {
              return (
                <li key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    name={item.id}
                    onChange={e => addPlaylist(e.target.checked)}
                    onClick={e => !e.target.checked}
                    defaultChecked={videoPlaylist?.find(vp => vp.id === item.id)}
                    ref={mobileUpdateForm.register()}
                  />
                  <label htmlFor={item.name}>{item.name}</label>
                </li>
              );
            })}
          </ul>
          <button type="button" className="addPlylist" onClick={showAddPlaylistModal}>
            <b className="plus">+</b> Add a Playlist
          </button>
          <br />
          <Button type="submit" variant={isAdd ? 'primary' : 'secondary'} className="updateplylist">
            Save
          </Button>
        </form>
      </>
    );

    return (
      <PopoverWrapper>
        {userState ? (
          <Popover {...props} id="playlist-popover">
            <MobilePopoverDiv>
              {isShowAddPlaylistModal ? renderNewPlaylistForm() : renderMobilePlaylistForm()}
            </MobilePopoverDiv>
            <DeskPopoverDiv>
              <Popover.Content className="mw-100">
                {isNew ? renderNewPlaylistForm() : renderExistingPlaylistForm()}
              </Popover.Content>
            </DeskPopoverDiv>
          </Popover>
        ) : (
          <UpgradeModal access="playlist" isShow={showUpgradeModal} onHideModal={onHideUpgradeModal} />
        )}
      </PopoverWrapper>
    );
  },
);

export default PlaylistPopover;
