import {observer} from 'mobx-react-lite';
import LocalStorageService from '../../../utils/LocalStorageService';

const CLIENT_ID = 'AsEwI4nttDAgqwjhYfY5C3LgIcBV1mnt';
const REDIRECT_URI = process.env.REACT_APP_OAUTH_REDIRECT_URI;
const SCOPES = 'openid affiliation';

const OAuthRedirect = observer(() => {
  const params = Object.fromEntries(new URLSearchParams(window.location.search));
  const iss = 'https://auth.ebsco.zone/api/dispatcher/oauth/authorize';
  const state = LocalStorageService.generateStateToken();
  const redirect_params = new URLSearchParams();
  redirect_params.append('response_type', 'code');
  redirect_params.append('client_id', CLIENT_ID);
  redirect_params.append('state', state);
  redirect_params.append('scope', SCOPES);
  redirect_params.append('redirect_uri', REDIRECT_URI);
  redirect_params.append('login_hint', params.login_hint);
  if (params.acr_values) {
    redirect_params.append('acr_values', params.acr_values);
  }
  const ebsco_redirect = `${iss}?${redirect_params.toString()}`;
  window.location.href = ebsco_redirect;
  // Helpful for debugging:
  // return (
  //   <>
  //     <RegNavBar />
  //     <Container className="py-3 py-sm-5" fluid>
  //       <Card>
  //         <Card.Body>
  //           <pre>
  //             <code>{JSON.stringify(params, null, 2)}</code>
  //           </pre>
  //           <a target="_blank" rel="noreferrer" href={ebsco_redirect}>
  //             Go to EBSCO: {ebsco_redirect}
  //           </a>
  //         </Card.Body>
  //       </Card>
  //     </Container>
  //   </>
  // );
});

export default OAuthRedirect;
