import styled from 'styled-components';

export const VideoCardHolder = styled.div`
  .desk {
    display: block;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .mobile {
    display: none;
    @media screen and (max-width: 576px) {
      display: block;
    }
  }

  min-height: 167px;

  :hover {
    background: #181a21;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 16px 0 18px 16px;
  }
  .row {
    margin-top: 10px;
  }
  #playlist_remove {
    display: none;
    color: #fff;
    font-size: 12px;
    @media screen and (max-width: 576px) {
      display: block;
    }
  }
  #playlist_remove::after {
    border-top: none;
    border-right: none;
  }
  .title {
    width: 563px;
    margin-bottom: 8px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    line-height: 20px;
    text-overflow: ellipsis;
    cursor: pointer;
    @media screen and (max-width: 576px) {
      width: 160px;
      font-size: 14px;
    }
  }
  .specialty {
    min-height: 15px;
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .detail {
    @media screen and (max-width: 576px) {
      font-size: 10px;
      line-height: 11px;
    }
  }
  .icon-giblib {
    margin-top: auto;
    margin-bottom: auto;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
    height: 20px;
    margin-right: 8px;
  }
  @media screen and (max-width: 576px) {
    min-height: 94px;
  }
`;

export const ThumbnailImg = styled.img`
  width: 248px;
  height: 140px;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    width: 168px;
    height: 94px;
  }
`;

export const ThumbnailDiv = styled.div`
  position: relative;
  margin-right: 20px;
  width: 245px;
  height: 95px;
  .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }
  &:hover {
    cursor: pointer;
  }
  .mobile-icon-div {
    position: absolute;
    top: 10px;
    left: 8px;
  }
  @media screen and (max-width: 576px) {
    width: 168px;
    height: 94px;
  }
`;

export const DurationText = styled.div`
  display: flex;
  width: 39px;
  height: 19px;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 4px;
  position: absolute;
  top: 109px;
  right: 12px;
  background: rgba(24, 26, 33, 0.85);
  border-radius: 4px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 576px) {
    top: 61px;
    right: 8px;
  }
`;

export const ButtonGroup = styled.div`
  width: 230px;

  .a_follow {
    font-size: 14px;
    padding: 4px 5px 2px 8px;
    line-height: 16px;
    letter-spacing: -0.09px;
    background: #0677d8;
    font-weight: 500;
  }

  .a_playlist,
  .a_delete {
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-right: 15px;
    color: #ffffff;
  }
  @media screen and (max-width: 576px) {
    display: block;
  }
`;

export const IconDiv = styled.div`
  display: flex;
  margin-bottom: 22px;
  svg {
    margin-right: 8px;
    path {
      height: 20px;
    }
  }
  .cme-icon {
    margin-right: 3px;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 6px;
  }
`;

export const MobileButton = styled.div`
  display: none;
  @media screen and (max-width: 576px) {
    display: block;
  }
`;

export const VideoCardFooter = styled.div`
  display: flex;
  margin-top: 16px;

  & .icon-cme {
    margin-top: auto;
    margin-bottom: auto;
    width: 28px;
    margin-right: 8px;
    @media screen and (max-width: 576px) {
      width: 26px;
      height: 26px;
    }
  }

  & .card-desc {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .fa-calendar {
    margin-right: 6px;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #a3a3a9;
  }
  .organization {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
`;
