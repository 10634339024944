// @flow
import {makeAutoObservable, runInAction} from 'mobx';
import axios from 'axios';
import {followItem, unfollowItem, getSpecialties, createBookmark, deleteBookmark} from '../../api/course';
import {
  getOrganization,
  getSortedOrganizations,
  getOrganizationVideos,
  getOrganizationExperts,
  getOrganizationCourses,
} from '../../api/organization';
import type {Organization as OrganizationBasic, Expert, Video, Course, Playlist} from '../../utils/types';
import LocalStorageService from '../../utils/LocalStorageService';
import {getExpert} from '../../api/expert';
import {getVideo, getAllPlaylists} from '../../api/video';
import {getCourse} from '../../api/courseLanding';
import {addCoursePlaylist, addNewCoursePlaylist} from '../../api/playlist';
import ErrorStore from '../error/errorStore';
import MediaStore from '../media/mediaStore';
import AlertStore from '../alert/alertStore';

const {CancelToken} = axios;

type Organization = OrganizationBasic & {
  expert: Expert[],
  video: Video[],
  course: Course[],
};
class OrganizationStore {
  errorStore: ErrorStore;

  mediaStore: MediaStore;

  alertStore: AlertStore;

  organization: Organization = {};

  loading: boolean = true;

  page: number = 2;

  organizationPage: number = 1;

  organizationVideos: Array<Video> = [];

  orgVideosLoading: boolean = true;

  orgAllVideosLoading: boolean = false;

  organizationExperts: Array<Expert> = [];

  organizationCourses: Array<Course> = [];

  experts: Array<Expert> = [];

  organizationList: Array<Organization> = [];

  allOrganizationVideos: Array<Video> = [];

  listOrganizationsAlpha: Array<Organization> = [];

  listSponsored: Array<Organization> = [];

  allOrganizationSpecialtiesList: Array<any> = [];

  specialtiesList: Array<any> = [];

  selectedSpecialtiesList: Array<any> = [];

  selectedSpecialtyIds: Array<number> = [];

  sortBy: string = 'created_at';

  sortOrder: string = 'DESC';

  isLoading: boolean = false;

  hasMoreVideos: boolean = true;

  playlist: Array<Playlist> = [];

  source;

  count: Number = 20;

  organizationCount: Number = 0;

  get organizationDetail(): Organization {
    return this.organization;
  }

  setOrganization = (organization: Organization) => {
    this.organization = organization;
    this.loading = false;
  };

  constructor({errorStore, mediaStore, alertStore}) {
    this.errorStore = errorStore;
    this.mediaStore = mediaStore;
    this.alertStore = alertStore;
    makeAutoObservable(this);
  }

  setOrganizationObservables = (organizations: Array<Organization>) => {
    this.organizationList = organizations;
  };

  setOrganizationVideosObservables = (videos: Array<Video>) => {
    this.organizationVideos = videos;
    this.orgVideosLoading = false;
  };

  setOrganizationExpertsObservables = (experts: Array<Expert>) => {
    this.organizationExperts = experts;
    this.experts = experts;
  };

  setOrganizationCoursesObservables = (courses: Array<Course>) => {
    this.organizationCourses = courses;
  };

  setAllOrganizationVideosObservables = (videos: Array<Video>) => {
    this.allOrganizationVideos = videos;
    this.orgAllVideosLoading = false;
  };

  pageIncrement = () => {
    this.organizationPage += 1;
  };

  pageDecrement = () => {
    this.organizationPage -= 1;
  };

  changeCount = option => {
    this.count = option;
    this.organizationPage = 1;
    this.organizationList = [];
  };

  resetExpert = () => {
    this.experts = [];
    this.organizationExperts = [];
  };

  /** Fetch organizations */
  listOrganizations = async (sortBy: string, sort: string, sidebar, sponsored) => {
    this.loading = true;
    try {
      const res = await getSortedOrganizations(sortBy, sort, this.count, this.organizationPage, sponsored);
      const {status, organizations, count} = res.data;
      if (status) {
        this.loading = false;
      }
      const loggedUser = LocalStorageService.getUser();
      const organizationList = organizations.map(organization => {
        let isFollowing = false;
        if (!organization.follow.length) {
          isFollowing = false;
        } else {
          const userIndex = organization.follow.findIndex(item => loggedUser && item.user_id === loggedUser?.id);
          isFollowing = userIndex !== -1;
        }
        return {
          ...organization,
          is_following: isFollowing,
        };
      });
      if (sidebar === true) {
        if (sponsored === true) {
          this.listSponsored = organizations;
        } else {
          this.listOrganizationsAlpha = organizations;
        }
      } else {
        this.organizationCount = count;
        this.setOrganizationObservables(organizationList);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // fetchItem on hover
  fetchItem = async (type: string, carouselId: number, itemId: number, storeVar: string = null) => {
    this.source?.cancel('Operation canceled by nextItem.');
    this.source = CancelToken.source();
    let itemList = [];
    let item = null;
    try {
      switch (type) {
        case 'videos':
          item = await getVideo(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.video || null);
          itemList = !storeVar ? this.organizationVideos : this[storeVar];
          break;
        case 'expert':
          item = await getExpert(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.expert || null);
          itemList = this.experts;
          break;
        case 'organization':
          item = await getOrganization(itemId, {onHover: true}, this.source?.token).then(
            res => res?.data?.organization || null,
          );
          this.updateItem(this.organizationList, item);
          break;
        case 'courses':
          item = await getCourse(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.course || null);
          itemList = this.organizationCourses;
          break;
        default:
          break;
      }
      this.updateItem(itemList, item, storeVar);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // hover update
  updateItem = (itemList: any[], item: any, storeVar: string = null) => {
    const itemIndex = itemList.findIndex(listItem => +listItem?.id === +item?.id);
    if (itemIndex !== -1) {
      /* eslint-disable no-param-reassign */
      itemList[itemIndex] = {
        isExtraDataLoaded: true,
        ...item,
      };
      itemList = [...itemList];
    }
    this.experts = [...this.experts];
    this.organizationList = [...this.organizationList];
    if (storeVar) {
      this[storeVar] = [...this[storeVar]];
    } else {
      this.organizationVideos = [...this.organizationVideos];
    }
    this.organizationExperts = [...this.experts];
    this.organizationCourses = [...this.organizationCourses];
  };

  /** Follow organization */
  onFollowOrganization = async (id: number) => {
    try {
      const resp = await followItem(id, 'organization');
      const {status, follow} = resp.data;
      if (status) {
        this.mediaStore.updateCarouselFollow(id, true, 'organization');
        const updatedOrganization = [...this.organizationList];
        const index = updatedOrganization.findIndex(item => item.id === id);
        updatedOrganization[index].is_following = true;
        updatedOrganization[index].follow?.push({Follow: follow});
        this.setOrganizationObservables(updatedOrganization, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow organization */
  onUnfollowOrganization = async (organization: Organization) => {
    const {id} = organization;
    let deleteIndex = null;
    organization.follow?.map((item, i) => {
      const loggedUser = LocalStorageService.getUser();
      if (item.user_id === loggedUser?.id) {
        deleteIndex = i;
      }
      return organization;
    });
    try {
      const resp = await unfollowItem(id, 'organization');
      const {status} = resp.data;

      if (status) {
        this.mediaStore.updateCarouselFollow(id, false, 'organization');
        const updatesOrganization = this.organizationList.map(item => {
          if (item.id === organization.id) {
            item.is_following = false;
            item.follow?.splice(deleteIndex, 1);
          }
          return item;
        });
        this.setOrganizationObservables(updatesOrganization, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch organization */
  getOrganization = async (id: number) => {
    this.loading = true;
    try {
      const res = await getOrganization(id);
      const {status, organization} = res.data;
      if (status) this.setOrganization(organization);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch organization videos */
  onFetchOrganizationVideos = async (id: number) => {
    this.orgVideosLoading = true;
    this.allOrganizationVideos = [];
    this.allOrganizationSpecialtiesList = [];
    try {
      const res = await getOrganizationVideos(id, 16, 'created_at', 'DESC', 1);
      const {videos} = res.data;
      this.setOrganizationVideosObservables(videos);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch organization experts */
  onFetchOrganizationExperts = async (id: number, page, limit) => {
    this.isLoading = true;
    try {
      const res = await getOrganizationExperts(id, page, limit);
      const {experts} = res.data;
      if (experts.length === limit) {
        this.isLoading = false;
      }
      const expertsExtend = [...this.experts, ...experts];
      this.setOrganizationExpertsObservables(expertsExtend);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch organization courses */
  onFetchOrganizationCourses = async (id: number) => {
    try {
      const res = await getOrganizationCourses({
        id,
        limit: 9,
        sort: 'created_at',
        sortBy: 'DESC',
        page: 1,
        type: 'tile',
      });
      const {courses} = res.data;
      this.setOrganizationCoursesObservables(courses);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow organization */
  followOrganization = async (id: number) => {
    try {
      const resp = await followItem(id, 'organization');
      const {status, follow} = resp.data;
      if (status) {
        runInAction(() => {
          this.mediaStore.updateCarouselFollow(id, true, 'organization');
          this.organization.is_following = true;
          this.organization.followId = follow?.id;
          this.organization.follow = follow;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow organization */
  unFollowOrganization = async (id: number) => {
    try {
      const resp = await unfollowItem(id, 'organization');
      const {status} = resp.data;
      if (status) {
        runInAction(() => {
          this.mediaStore.updateCarouselFollow(id, false, 'organization');
          this.organization.is_following = false;
          this.organization.followId = null;
          this.organization.follow = [];
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow expert */
  followExpert = async (id: number) => {
    try {
      const resp = await followItem(id, 'expert');
      const {status, follow} = resp.data;
      runInAction(() => {
        if (status) {
          const index = this.organizationExperts.findIndex(exp => exp?.id === id);
          this.organizationExperts[index] = {
            ...this.organizationExperts[index],
            is_following: true,
            followId: follow?.id,
            follow,
          };
          this.organizationExperts[index].follow = [follow];
          this.experts[index].follow = [follow];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow expert */
  unFollowExpert = async (id: number) => {
    try {
      const resp = await unfollowItem(id, 'expert');
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          const index = this.organizationExperts.findIndex(exp => exp?.id === id);
          this.organizationExperts[index] = {
            ...this.organizationExperts[index],
            is_following: false,
            followId: null,
            follow: [],
          };
          this.organizationExperts[index].follow = [];
          this.experts[index].follow = [];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow course */
  followCourse = async (id: number) => {
    try {
      const resp = await followItem(id, 'course');
      const {status, follow} = resp.data;
      runInAction(() => {
        if (status) {
          const index = this.organizationCourses.findIndex(exp => exp?.id === id);
          this.organizationCourses[index] = {
            ...this.organizationCourses[index],
            is_following: true,
            followId: follow?.id,
            follow,
          };
          this.organizationCourses = [...this.organizationCourses];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow course */
  unFollowCourse = async (id: number) => {
    try {
      const resp = await unfollowItem(id, 'course');
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          const index = this.organizationCourses.findIndex(exp => exp?.id === id);
          this.organizationCourses[index] = {
            ...this.organizationCourses[index],
            is_following: false,
            followId: null,
            follow: [],
          };
          this.organizationCourses = [...this.organizationCourses];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Bookmark Course */
  onCreateBookmark = async selectedItem => {
    try {
      const resp = await createBookmark(selectedItem.id, 'course');
      const {status, bookmark} = resp.data;
      runInAction(() => {
        if (status) {
          const {id} = bookmark;
          const index = this.organizationCourses.findIndex(org => org?.id === selectedItem.id);
          this.organizationCourses[index] = {
            ...this.organizationCourses[index],
            bookmark: [{id}],
          };
          this.organizationCourses = [...this.organizationCourses];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete course bookmark */
  onDeleteBookmark = async selectedItem => {
    const {id} = selectedItem;
    try {
      const resp = await deleteBookmark(id, 'course');
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          const index = this.organizationCourses.findIndex(org => org?.id === id);
          this.organizationCourses[index] = {
            ...this.organizationCourses[index],
            bookmark: null,
          };
          this.organizationCourses = [...this.organizationCourses];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setPlaylistObservables = (playlist: Array<Playlist>) => {
    this.playlist = playlist;
  };

  getAllPlaylists = async () => {
    try {
      const res = await getAllPlaylists();
      if (res.data.status) {
        this.setPlaylistObservables(res.data.playlist);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addCourseToPlaylist = async (courseId: number, data: any) => {
    try {
      const resp = await addCoursePlaylist(courseId, data);
      const {status} = resp.data;
      if (status) {
        this.getAllPlaylists();
        this.alertStore.setMessage('Playlist updated successfully', 'success');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addCourseToNewPlaylist = async (courseId: number, name: string) => {
    try {
      const resp = await addNewCoursePlaylist(courseId, name);
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          const {playlist, playlistvideos} = resp.data;
          const videos = playlistvideos.map(pv => ({
            id: pv.video_id,
          }));
          this.alertStore.setMessage('Playlist created successfully', 'success');
          const index = this.playlist.findIndex(pl => pl?.id === playlist.id);
          if (index !== -1) {
            this.playlist[index] = {
              ...playlist,
              videos,
            };
            this.playlist = [...this.playlist];
          } else {
            this.playlist.push({...playlist, videos});
          }
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  sortExperts = (option: string) => {
    const expertlist = [...this.experts];
    switch (option) {
      case 'alphainc':
        expertlist.sort((a, b) => (a.name > b.name ? 1 : -1));
        break;
      case 'alphadec':
        expertlist.sort((a, b) => (a.name < b.name ? 1 : -1));
        break;
      default:
        break;
    }
    this.experts = expertlist;
  };

  /** Fetch organization videos */
  onFetchAllOrganizationVideos = async (id: number, page) => {
    this.orgAllVideosLoading = true;
    try {
      const res = await getOrganizationVideos(id, 16, this.sortBy, this.sortOrder, page, this.selectedSpecialtyIds);
      this.orgAllVideosLoading = false;
      const {videos} = res.data;
      if (videos.length) {
        const videosExtend = page > 1 ? [...this.allOrganizationVideos, ...videos] : videos;
        this.setAllOrganizationVideosObservables(videosExtend);
        this.hasMoreVideos = true;
      } else {
        this.hasMoreVideos = false;
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setSelectedSpecialties = Ids => {
    this.selectedSpecialtyIds = Ids;
    this.selectedSpecialtiesList = this.specialtiesList.filter(item => Ids.includes(item.id));
    this.allOrganizationVideos = [];
    this.page = 2;
  };

  /**
   * set specialties list observables
   * @param {array} specialties
   */
  setSpecialtiesObservables = specialties => {
    this.specialtiesList = specialties;
  };

  /** Fetch All specialties */
  listSpecialties = async () => {
    try {
      const resp = await getSpecialties();
      let {specialties} = resp.data;
      specialties = specialties.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setSpecialtiesObservables(specialties);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set organziation videos' specialties list observables
   * @param {array} specialties
   */
  setOrgVideosSpecialtiesObservables = specialties => {
    this.allOrganizationSpecialtiesList = specialties;
  };

  /** Fetch Organization specialties */
  listOrgVideosSpecialties = async orgId => {
    try {
      const resp = await getSpecialties('video', null, orgId);
      let {specialties} = resp.data;
      specialties = specialties.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setOrgVideosSpecialtiesObservables(specialties);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Remove Specialty */
  onRemoveSpecialty = id => {
    this.selectedSpecialtiesList = this.selectedSpecialtiesList.filter(item => item.id !== id);
    this.selectedSpecialtyIds = this.selectedSpecialtyIds.filter(item => item !== id);
    this.allOrganizationVideos = [];
    this.page = 2;
  };

  changeSort = (option: string) => {
    switch (option) {
      case 'Most Viewed':
        this.sortBy = 'most_watched';
        this.sortOrder = 'DESC';
        break;
      case 'Newest':
        this.sortBy = 'created_at';
        this.sortOrder = 'DESC';
        break;
      case 'Oldest':
        this.sortBy = 'created_at';
        this.sortOrder = 'ASC';
        break;
      default:
        break;
    }
    this.allOrganizationVideos = [];
  };

  scrollLoad = async orgId => {
    if (!this.orgAllVideosLoading && this.hasMoreVideos) {
      try {
        await this.onFetchAllOrganizationVideos(orgId, this.page);
        this.page += 1;
      } catch (error) {
        this.errorStore.setError(error);
      }
    }
  };
}

export default OrganizationStore;
