import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Form, Dropdown} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {TitleDiv} from '../../elements/video';
import StyledVideoTabContent from './styles';
import {useStore} from '../../../../store';

const VideoTabContent = observer(({children, title}) => {
  const {videoStore} = useStore();
  const {video, setTranscriptLanguage} = videoStore;
  const displayLanguage = term => {
    switch (term) {
      case 'en':
        return 'English';
      case 'ko':
        return '한국어';
      case 'es':
        return 'Español';
      case 'zh-CN':
        return '中文';
      case 'ja':
        return '日本語';
      case 'fr':
        return 'Français';
      case 'pt':
        return 'Português';
      case 'ru':
        return 'Pусский';
      case 'de':
        return 'Deutsch';
      default:
        return term;
    }
  };

  useEffect(() => {
    if (video?.transcripts) {
      setTranscriptLanguage('en');
    }
  }, [video, setTranscriptLanguage]);

  const [language, setLanguage] = useState('English');

  return (
    <StyledVideoTabContent>
      {title === 'Transcript' && video?.transcripts ? (
        <>
          <TitleDiv className="py-2 m-0">{title}</TitleDiv>
          <Dropdown>
            <Dropdown.Toggle variant="basic" id="selectLanguage" className="a_selectLanguage">
              <span>{language}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu
              onChange={e => {
                setTranscriptLanguage(e.target.value);
                setLanguage(e.target.labels[0].innerText);
              }}
            >
              {video?.transcripts
                ?.slice()
                ?.sort((a, b) => a.language.localeCompare(b.language))
                .map(item => (
                  <Form.Check
                    type="radio"
                    label={displayLanguage(item.language)}
                    id={item.language}
                    value={item.language}
                    name="language"
                    checked={language === displayLanguage(item.language)}
                    readOnly
                  />
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <TitleDiv className="py-3 m-0">
          {title}
          {title === 'ChatGPT' && <sup className="beta-tag">Beta</sup>}
        </TitleDiv>
      )}
      <div
        className={classNames('content-wrapper h-100', {
          'notes-wrap': title === 'Notes',
        })}
      >
        {children}
      </div>
    </StyledVideoTabContent>
  );
});

VideoTabContent.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
};

export default VideoTabContent;
