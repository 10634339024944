import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Row, Col, Form, Container, Spinner} from 'react-bootstrap';
import {useRouteMatch} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {
  Breadcrumbs,
  PageTitle,
  SortSelect,
  FormLabel,
  ActiveCrumb,
  Crumb,
  ChipText,
  CourseFilter,
  MobileFilter,
  Main,
  Line,
} from './elements/styles';
import VideoTile from '../../components/VideoTile';
import Dropdown from '../../components/Multiselect';
import {useStore} from '../../store';
import close from './elements/close-chip.png';
import AlertComponent from '../../components/AlertComponent';

const OrganizationVideos = observer(() => {
  const match = useRouteMatch();
  const orgId = match.params?.id;

  const {organizationStore, videoStore, alertStore} = useStore();

  const {message, status, resetAlert} = alertStore;

  const {
    organization,
    allOrganizationVideos,
    getOrganization,
    onFetchAllOrganizationVideos,
    allOrganizationSpecialtiesList,
    selectedSpecialtiesList,
    selectedSpecialtyIds,
    setSelectedSpecialties,
    onRemoveSpecialty,
    listOrgVideosSpecialties,
    sortBy,
    sortOrder,
    changeSort,
    orgAllVideosLoading,
    scrollLoad,
  } = organizationStore;

  const {getAllPlaylists} = videoStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  useEffect(() => {
    getOrganization(orgId);
  }, [getOrganization, orgId, match.path]);

  useEffect(() => {
    listOrgVideosSpecialties(orgId);
  }, [listOrgVideosSpecialties, orgId]);

  useEffect(() => {
    onFetchAllOrganizationVideos(orgId, 1);
  }, [selectedSpecialtyIds, sortBy, sortOrder, onFetchAllOrganizationVideos, orgId]);

  const selectOptions = selectedIDs => {
    setSelectedSpecialties(selectedIDs);
  };

  const removeSpecialty = item => {
    onRemoveSpecialty(item.id);
  };

  useEffect(() => {
    window.addEventListener('scroll', e => {
      const {scrollTop, clientHeight, scrollHeight} = e.target.scrollingElement;
      if (Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight)) {
        scrollLoad(orgId);
      }
    });
  }, [scrollLoad, orgId]);

  const [isSponsored, setSponsored] = useState(false);

  useEffect(() => {
    const sponsored = window.location.pathname.split('/');
    setSponsored(sponsored.includes('sponsored'));
  }, [setSponsored]);

  return (
    <div className="d-flex">
      <Helmet
        title={`${organization?.name} | Videos`}
        meta={[
          {
            name: 'description',
            content: 'Learn from the top subject matter experts from the leading academic medical institutions.',
          },
          {
            property: 'og:title',
            content: 'GIBLIB | Organization Videos',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Sidebar />
      <div className="w-100">
        <Navbar />
        <Main>
          {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
          <div>
            <div className="d-flex container">
              <Breadcrumbs>
                {isSponsored ? (
                  <Crumb href="/sponsored">Sponsored</Crumb>
                ) : (
                  <Crumb href="/organization">Organizations</Crumb>
                )}
                <Crumb href="#">{organization.name}</Crumb>
                <ActiveCrumb href="#">View All Videos</ActiveCrumb>
              </Breadcrumbs>
            </div>

            <Container>
              <div className="mt-2">
                <Row>
                  <PageTitle>{organization.name} Videos</PageTitle>
                  <CourseFilter>
                    <div className="d-flex">
                      <Form.Group as={Col} controlId="filterSpecialty">
                        <div className="d-flex">
                          <FormLabel className="mt-3 mr-2">Filter</FormLabel>
                          <Dropdown
                            values={allOrganizationSpecialtiesList}
                            selectedIds={selectedSpecialtyIds}
                            id="filterSpecialty"
                            title="Specialty"
                            onSelectOptions={selectedIDs => selectOptions(selectedIDs)}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} controlId="sort">
                        <div className="d-flex">
                          <FormLabel className="mt-3 mr-2">Sort</FormLabel>
                          <SortSelect data-testid="select" onChange={e => changeSort(e.target.value)}>
                            <option value="Newest">Newest</option>
                            <option value="Oldest">Oldest</option>
                            <option value="Most Viewed">Most Viewed</option>
                          </SortSelect>
                        </div>
                      </Form.Group>
                    </div>
                  </CourseFilter>
                </Row>
              </div>
              <MobileFilter>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} xs={6} sm={8} controlId="filterSpecialty">
                      <div className="d-flex">
                        <FormLabel className="mt-3 mr-2">Filter</FormLabel>
                        <Dropdown
                          values={allOrganizationSpecialtiesList}
                          selectedIds={selectedSpecialtyIds}
                          id="filterSpecialty"
                          title="Specialty"
                          onSelectOptions={selectedIDs => selectOptions(selectedIDs)}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} sm={3} controlId="sort">
                      <Line />
                      <div className="d-flex mobileSort">
                        <FormLabel className="mt-3 mr-2">Sort</FormLabel>
                        <SortSelect onChange={e => changeSort(e.target.value)}>
                          <option value="Newest">Newest</option>
                          <option value="Oldest">Oldest</option>
                          <option value="Most Viewed">Most Viewed</option>
                        </SortSelect>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </MobileFilter>

              <div className="d-flex container mt-2 flex-wrap">
                {selectedSpecialtiesList?.map(item => (
                  <ChipText>
                    {item.name}
                    <span>
                      <button type="button" className="bg-transparent border-0" onClick={() => removeSpecialty(item)}>
                        <img alt="close" src={close} />
                      </button>
                    </span>
                  </ChipText>
                ))}
              </div>
              <Row className="mt-4">
                {allOrganizationVideos?.map(v => (
                  <Col key={v.id} xs={6} md={6} lg={3}>
                    <VideoTile
                      key={v.title}
                      item={v}
                      storeName="organizationStore"
                      referrer="organization video page"
                      storeVar="allOrganizationVideos"
                    />
                  </Col>
                ))}
                {!allOrganizationVideos?.length && !orgAllVideosLoading && <p className="px-3 pt-5">No results</p>}
              </Row>
              {!allOrganizationVideos?.length && orgAllVideosLoading && (
                <div className="text-center my-3 mt-5 pt-5">
                  <Spinner animation="grow" className="mt-5" />
                </div>
              )}
            </Container>
          </div>
          {allOrganizationVideos?.length && orgAllVideosLoading ? (
            <div className="text-center my-3">
              <Spinner animation="grow" />
            </div>
          ) : (
            <></>
          )}
          {!orgAllVideosLoading && <Footer />}
        </Main>
      </div>
    </div>
  );
});

export default OrganizationVideos;
