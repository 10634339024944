import styled from 'styled-components';

const SortContainer = styled.div`
  .a_sort {
    background: transparent;
    padding: 5px 30px 5px 16px;
    border-radius: 3px;
    position: relative;
    border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
    color: #fff;
    width: 190px;
    text-align: left;

    ::after {
      right: 8px;
      position: absolute;
      top: calc(50% - 0.25em);
      border-top: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-bottom: 0;
      border-left: 0.5em solid transparent;
    }
    ~ .dropdown-menu {
      width: 190px;
      background: #2f3137;
    }
  }
  .a_profession {
    width: 100%;
    padding: 8px 30px 8px 16px;

    ~ .dropdown-menu {
      width: 100%;
    }
  }
  text-align: right;
  position: relative;
  label {
    width: 100%;
    padding: 8px 0;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
  }
  .form-check-input {
    display: none;
  }
  .form-check {
    padding-left: 44px;
  }
  input ~ label::after {
    content: '';
    left: 16px;
    bottom: 10px;
    width: 16px;
    height: 16px;
    border: 2px solid #008aff;
    position: absolute;
    display: block;
    border-radius: 50px;
  }
  input:checked ~ label::before {
    position: absolute;
    display: block;
    border-radius: 50px;
    content: '';
    left: 14px;
    bottom: 8px;
    border: 2px solid rgba(0, 138, 255, 0.5);
    width: 20px;
    height: 20px;
  }
  input:checked ~ label {
    color: #008aff;
  }
  input:checked ~ label::after {
    border: 4px solid #008aff;
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  span {
    font-size: 12px;
  }
`;

export default SortContainer;
