import styled from 'styled-components';

export const Header = styled.div`
  background: transparent;
  padding-top: 55px;
  .title-div {
    height: 126px;
  }
  .title {
    margin-left: 20px;
    margin-bottom: 0;
  }
  button {
    box-sizing: border-box;
    padding: 0.3em 0.6em;
    border: ${({theme}) => {
      const color = theme.name === 'light' ? '#B8BBBE' : '#4e505a';
      return `1px solid ${color}`;
    }};
    border-radius: 4px;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#ffffff')};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 22px;
  }
  @media screen and (max-width: 576px) {
    background: transparent;
    padding: 48px 0 12px;
    .title-div {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      margin-left: 20px;
      margin-bottom: 0;
    }
    button {
      box-sizing: border-box;
      padding: 0.3em 0.6em;
      border: ${({theme}) => {
        const color = theme.name === 'light' ? '#B8BBBE' : '#4e505a';
        return `1px solid ${color}`;
      }};
      border-radius: 4px;
      background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
      color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#ffffff')};
      font-size: 15px;
      font-weight: 600;
      letter-spacing: -0.1px;
      line-height: 22px;
    }
    .fa-chevron-left {
      margin-right: 18px;
    }
    .mobile-select {
      background-color: transparent;
      color: white;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
  }
`;
