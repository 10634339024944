import {Modal} from 'react-bootstrap';
import styled from 'styled-components';

const DarkModal = styled(Modal)`
  text-align: center;
  .modal-dialog {
    width: 375px;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;

    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-body {
      padding: 2.5rem;
      .tbd-text {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 24px;
        margin-bottom: 2px;
      }
      .tbd-sub-text {
        font-size: 15px;
        letter-spacing: -0.1px;
        line-height: 20px;
      }
      .icon-wrapper {
        margin-bottom: 1rem;
      }
      .icon-img {
        width: 50px;
        height: 50px;
      }
    }
    .close {
      color: ${({theme}) => theme.circle};
    }
  }
`;

export default DarkModal;
