// @flow
import React, {useState, useRef} from 'react';
import {Overlay, Nav} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {BrowseDiv} from './mobileSidebar';
import {useStore} from '../../store';
import OrganizationList from '../Sidebar/elements/organizationList';

const OrganizationBrowse = observer(() => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const {organizationStore} = useStore();
  const {listOrganizationsAlpha} = organizationStore;

  return (
    <>
      <Nav.Item
        id="open-organizations-browse-sidebar"
        data-testid="open-organizations-browse-sidebar"
        className="a_openOrganizationsBrowseButton mr-2"
        ref={target}
        onClick={() => setShow(!show)}
      >
        <span className="collapseText">Organizations</span>
      </Nav.Item>
      <Overlay target={target.current} show={show} placement="bottom">
        {({placement, arrowProps, show: _show, popper, ...props}) => (
          <BrowseDiv {...props}>
            <div className="row browse">
              <Nav.Item onClick={() => setShow(!show)} className="closeBrowse">
                <Nav.Link>
                  <i className="fal fa-chevron-left" />
                  <span className="collapseText">Browse</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item id="Organizations">
                {listOrganizationsAlpha?.map(el => (
                  <div key={el.id}>
                    <OrganizationList org={el} storeName="organizationStore" />
                  </div>
                ))}
              </Nav.Item>
            </div>
          </BrowseDiv>
        )}
      </Overlay>
    </>
  );
});

export default OrganizationBrowse;
