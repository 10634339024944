import {observer} from 'mobx-react-lite';
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useStore} from '../../store';
import {ReactComponent as TimeIcon} from '../../assets/icons/time.svg';
import {ReactComponent as CMEIcon} from '../../assets/icons/CME.svg';
import {ReactComponent as BlueCheckIcon} from '../../assets/icons/blue.svg';
import {ReactComponent as CMESuccessIcon} from '../../assets/icons/cme_success.svg';
import {Body2, Title1} from '../../utils/styles/typography';

export const ProgressDiv = styled.div`
  position: absolute;
  right: 0;

  @media screen and (max-width: 576px) {
    position: unset;
    margin-top: 48px;
  }

  .desk {
    display: block;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
  .mobile {
    display: none;

    .sub-text {
      display: flex;
      align-items: center;
      margin-left: 12px;
    }

    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 45px;
      .progress-div {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  margin-left: 17px;

  h5 {
    margin-top: 4px;
    margin-bottom: 0;
  }
  .cme-icon {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 576px) {
    width: 50px;
    height: 50px;
    margin: 0;
  }
`;

const CircleProgressBar = observer(({item, videos}) => {
  const [runtime, setRuntime] = useState(0);
  const [userCMECredits, setUserCMECredits] = useState(0);
  const [watchTime, setWatchTime] = useState(0);

  const {cmeStore} = useStore();
  const {getCompletedCmes, cmeCompleted} = cmeStore;

  useEffect(() => {
    getCompletedCmes('completed', 'completed_at');
  }, [getCompletedCmes]);

  useEffect(() => {
    if (item.total_runtime) {
      const {hours, minutes, seconds} = item?.total_runtime;
      const runningtime = (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
      setRuntime(runningtime);
    }
  }, [item]);

  useEffect(() => {
    setWatchTime(((item.watch_time / runtime) * 100).toFixed(0));
  }, [item, runtime]);

  useEffect(() => {
    if (!cmeCompleted.length) setUserCMECredits(0);
    else {
      let cmeCredits = 0;
      videos.forEach(cv => {
        cmeCompleted.forEach(cc => {
          if (cv.video_id.toString() === cc.organizationCME?.video.id)
            cmeCredits += Number(cc.organizationCME?.credit_count);
        });
      });
      setUserCMECredits(cmeCredits);
    }
  }, [cmeCompleted, videos]);

  return (
    <ProgressDiv>
      <div className="desk">
        <ProgressBar>
          <CircularProgressbarWithChildren
            value={watchTime}
            styles={{
              path: {
                stroke: 'rgba(0, 138, 255, 1)',
              },
            }}
          >
            {watchTime === 100 ? <BlueCheckIcon /> : <TimeIcon />}
            <div>
              <Title1 className={`${watchTime === '100' && 'text-primary'}`}>{watchTime}%</Title1>
            </div>
            <div>
              <Body2>Watched</Body2>
            </div>
          </CircularProgressbarWithChildren>
        </ProgressBar>
        {item.credit_count ? (
          <ProgressBar>
            <CircularProgressbarWithChildren
              value={userCMECredits / item.credit_count}
              styles={{
                path: {
                  stroke: 'rgba(13, 255, 243, 1)',
                },
              }}
            >
              {userCMECredits / item.credit_count === 1 ? (
                <CMESuccessIcon />
              ) : (
                <>
                  <CMEIcon className="cme-icon" />
                  <div>
                    <Title1>
                      {userCMECredits}/{item.credit_count}
                    </Title1>
                  </div>
                  <div>
                    <Body2>Credits</Body2>
                  </div>
                </>
              )}
            </CircularProgressbarWithChildren>
          </ProgressBar>
        ) : null}
      </div>
      <div className="mobile">
        <div className="progress-div">
          <ProgressBar>
            <CircularProgressbarWithChildren
              value={watchTime}
              styles={{
                path: {
                  stroke: 'rgba(0, 138, 255, 1)',
                },
              }}
            >
              {watchTime === 100 ? <BlueCheckIcon /> : <TimeIcon />}
            </CircularProgressbarWithChildren>
          </ProgressBar>
          <div className="sub-text">
            <Title1 className={`${watchTime === '100' && 'text-primary'} m-0`}>{watchTime}%</Title1>
            &nbsp;
            <Body2 className="d-inline">Watched</Body2>
          </div>
        </div>
        {item.credit_count ? (
          <div className="progress-div">
            <ProgressBar>
              <CircularProgressbarWithChildren
                value={userCMECredits / item.credit_count}
                styles={{
                  path: {
                    stroke: 'rgba(13, 255, 243, 1)',
                  },
                }}
              >
                <CMEIcon className="cme-icon" />
              </CircularProgressbarWithChildren>
            </ProgressBar>
            <div className="sub-text">
              <Title1 className="m-0">
                {userCMECredits}/{item.credit_count}
              </Title1>
              &nbsp;
              <Body2 className="d-inline">Credits</Body2>
            </div>
          </div>
        ) : null}
      </div>
    </ProgressDiv>
  );
});

export default CircleProgressBar;
