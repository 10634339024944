import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {Row, Col, Container} from 'react-bootstrap';
import Navbar from '../../../components/Navbar';
import Sidebar from '../../../components/Sidebar';
import AlertComponent from '../../../components/AlertComponent';
import ScreenLoader from '../../../components/ScreenLoader';
import {containsAny} from '../../../utils/helper';
import {PageTitle, ViewAllFilter, ViewAllDiv, Line} from '../elements/home';
import VideoTile from '../../../components/VideoTile';
import {useStore} from '../../../store';
import UpgradeModal from '../../../components/UpgradeModal';
import Sort from '../../../components/Sort';
import Pagination from '../../../components/Pagination';
import Filter from '../../../components/Filter';
import PageBottom from '../../../components/PageBottom';
import {ActiveSmallBtn, DefaultSmallBtn} from '../../../components/FollowButtons';
import {Breadcrumb} from '../../../components/Breadcrumbs';
import Footer from '../../../components/Footer';

const replaceTags = ['SPECIALTY', 'INSTITUTION', 'NAME'];
// Video carousel view all page
const VideoViewAll = observer(() => {
  const {mediaStore, accountStore, alertStore} = useStore();
  const match = useRouteMatch();
  const location = useHistory();
  const carouselId = match.params?.id;
  const {
    loadCarouselVideos,
    loadLogicCarouselVideos,
    setCurrentCarouselId,
    videos,
    title,
    sortBy,
    sort,
    changeSortBy,
    resetTitle,
    followCarousel,
    unFollowCarousel,
    carousel,
    page,
    pageIncrement,
    pageDecrement,
    changeCount,
    videoCount,
    count,
    selectedContenttypes,
    selectedContenttypeList,
    onRemoveContenttype,
    setSelectedContenttype,
    contenttypeList,
    listContentTypes,
    listOrganizations,
    organizationsList,
    selectedOrganizationList,
    onRemoveOrganization,
    selectedOrganizationIds,
    setSelectedOrganizations,
    setSelectedSpecialties,
    setSearch,
    searchText,
    resetSearch,
    isCME,
    setCME,
    isVR,
    setVR,
    selectedSpecialtiesList,
    selectedSpecialtyIds,
    specialtiesList,
    onRemoveSpecialty,
    selectedSubSpecialtyIds,
    selectedSubSpecialtiesList,
    setSelectedSubSpecialties,
    onRemoveSubSpecialty,
    subSpecialtiesList,
    listSpecialties,
  } = mediaStore;
  const {account} = accountStore;
  const {message, status, resetAlert} = alertStore;
  const [newTitle, setNewTitle] = useState('');
  const [isLoading, setLoading] = useState(false);
  const isLogicCarousel = !/^\d+$/.test(carouselId); // if id is not a number, it's a slug, then a logic carousel
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    resetTitle();
    setCurrentCarouselId(carouselId);
  }, [resetTitle, setCurrentCarouselId, carouselId]);

  useEffect(() => {
    listContentTypes();
    listOrganizations();
    listSpecialties();
  }, [listContentTypes, listOrganizations, listSpecialties]);

  useEffect(() => {
    setLoading(true);
    if (isLogicCarousel) {
      loadLogicCarouselVideos(
        carouselId,
        selectedContenttypeList.map(item => item.name),
      ).then(() => setLoading(false));
    } else {
      loadCarouselVideos(
        carouselId,
        selectedContenttypeList.map(item => item.name),
      ).then(() => setLoading(false));
    }
  }, [
    sortBy,
    sort,
    selectedOrganizationIds,
    loadCarouselVideos,
    carouselId,
    isLogicCarousel,
    loadLogicCarouselVideos,
    page,
    count,
    selectedContenttypeList,
    isCME,
    isVR,
    searchText,
    selectedSubSpecialtyIds,
    selectedSpecialtyIds,
  ]);

  useEffect(() => {
    setNewTitle(title);
  }, [title, carouselId]);

  useEffect(() => {
    const substituteTag = tag => {
      let replace = tag;
      const specialties = [];
      switch (tag) {
        case 'SPECIALTY':
          if (account?.specialties?.length) {
            specialties.push(...new Set(account.specialties.map(s => s.name)));
          }
          if (specialties.length) {
            [replace] = specialties;
          } else {
            replace = account.specialty ? account.specialty.name : 'your specialty';
          }
          break;
        case 'INSTITUTION':
          replace = account.institute ? account.institute.name : tag;
          break;
        case 'NAME':
          replace = `${account.first_name} ${account.last_name}`;
          break;
        default:
          replace = tag;
          break;
      }
      return replace;
    };

    const matchs = containsAny(title, replaceTags);
    if (matchs.status) {
      const newStr = title.replace(matchs.tag, substituteTag(matchs.tag));
      setNewTitle(newStr);
    } else {
      setNewTitle(title);
    }
  }, [title, account]);

  const carouselFollowHandler = () => {
    const user = localStorage.getItem('user');
    if (user) {
      if (!account?.subscription?.name?.includes('Basic')) {
        if (carousel?.is_following) {
          unFollowCarousel(carouselId);
        } else {
          followCarousel(carouselId);
        }
      } else setShowFollowUpgradeModal(true);
    } else {
      location.push('/login');
    }
  };

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedOrganizations(selectedIDs, false);
    } else if (type === 2) {
      setSelectedSpecialties(selectedIDs, false);
    } else if (type === 3) {
      setSelectedSubSpecialties(selectedIDs, false);
    } else {
      setSelectedContenttype(selectedIDs, false);
    }
  };

  const clearFunc = () => {
    selectOptions([], 0);
    selectOptions([], 1);
    selectOptions([], 2);
    selectOptions([], 3);
    setCME(false);
    setVR(false);
    resetSearch();
  };

  useEffect(() => {
    clearFunc();
    // eslint-disable-next-line
  }, [match.params?.id]);

  const removeOrganization = item => {
    onRemoveOrganization(item.id);
  };

  const removeContenttype = item => {
    onRemoveContenttype(item.id);
  };

  const removeSubSpecialty = item => {
    onRemoveSubSpecialty(item.id);
  };

  const removeSpecialty = item => {
    onRemoveSpecialty(item.id);
  };

  const sortValues = [
    {text: 'Name (A-Z)', id: 'name_asc', order: 'ASC', sort: 'title'},
    {text: 'Name (Z-A)', id: 'name_desc', order: 'DESC', sort: 'title'},
    {text: 'Date added (Newest)', id: 'date_desc', order: 'DESC', sort: 'release_date'},
    {text: 'Date added (Oldest)', id: 'date_asc', order: 'ASC', sort: 'release_date'},
  ];

  const organization = {
    selectedOrganizationList,
    selectedOrganizations: selectedOrganizationIds,
    organizationFilter: organizationsList,
    removeOrganization,
  };

  const subSpecialties = {
    selectedSubSpecialtiesList,
    selectedSubSpecialties: selectedSubSpecialtyIds,
    subSpecialityFilter: subSpecialtiesList,
    removeSubSpecialty,
  };

  const contenttype = {
    contenttypeFilter: contenttypeList,
    selectedContenttypes,
    selectedContenttypeList,
    removeContenttype,
  };

  const cme = {
    isCme: isCME,
    setCME,
  };

  const vr = {
    isVR,
    setVR,
  };

  const search = {
    submitSearch: setSearch,
    searchText,
    resetSearch,
  };

  const specialties = {
    selectedSpecialtiesList,
    selectedSpecialties: selectedSpecialtyIds,
    specialityFilter: specialtiesList,
    removeSpecialty,
  };
  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <div id="videoViewAll" className="d-flex">
        <Sidebar />
        <div className="w-100 pb-4">
          <Navbar />
          {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
          <ViewAllDiv>
            <div className="d-flex container">
              <Breadcrumb className="a_videoCrumb" activeCrumb_name={newTitle} />
              {/* <Breadcrumbs className="a_videoCrumb">
                <Crumb>
                  <a href="/">Home</a>
                </Crumb>
                <ActiveCrumb href="#">{newTitle}</ActiveCrumb>
              </Breadcrumbs> */}
            </div>

            <Container>
              <div className="a_filterHeadDiv">
                <div className="d-flex">
                  <PageTitle className="a_videoHead">{newTitle}</PageTitle>
                  {carousel?.is_following ? (
                    <ActiveSmallBtn className="a_specialtyFollowed_btn" onClick={carouselFollowHandler}>
                      <span className="following">
                        <i className="fas fa-check mr-2" />
                        Following
                      </span>
                      <span className="unfollow">
                        <i className="fas fa-times mr-2" />
                        Unfollow
                      </span>
                    </ActiveSmallBtn>
                  ) : (
                    !isLogicCarousel && (
                      <DefaultSmallBtn onClick={carouselFollowHandler}>
                        <i className="fas fa-plus mr-2" />
                        Follow
                      </DefaultSmallBtn>
                    )
                  )}
                </div>
                <Line />
                <ViewAllFilter>
                  <div className="d-flex mt-2 flex-wrap justify-content-between">
                    <Filter
                      count={videoCount}
                      organization={organization}
                      selectOptions={selectOptions}
                      specialties={specialties}
                      subSpecialties={subSpecialties}
                      contenttype={contenttype}
                      cme={cme}
                      vr={vr}
                      search={search}
                      clearFunc={clearFunc}
                      carouselType={carouselId}
                    />
                    <div className="d-flex">
                      <Sort change={changeSortBy} sort={sortValues} sortOrder={sortBy} sortBy={sort} />
                      <Pagination
                        totalCount={videoCount}
                        page={page}
                        countPerPage={count}
                        pageIncrement={pageIncrement}
                        pageDecrement={pageDecrement}
                        changeCount={changeCount}
                      />
                    </div>
                  </div>
                </ViewAllFilter>
              </div>
              {isLoading && <ScreenLoader containerHeight="calc(80vh - 3.5rem)" />}
              {!isLoading && (
                <Row>
                  {videos.map(el => (
                    <Col key={el.id} id={el.id} md={4} lg={3} xl={3} className="mb-3">
                      <VideoTile
                        key={el.title}
                        item={el}
                        storeName="mediaStore"
                        storeVar="videos"
                        type={carouselId}
                        referrer="view_all_carousel"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </Container>
            {!isLoading && <Footer />}
          </ViewAllDiv>
        </div>
      </div>
      <PageBottom />
    </>
  );
});

export default VideoViewAll;
