import React from 'react';
import {Col, Container, Row, Form, Card} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useLocation, useHistory, Link} from 'react-router-dom';
import queryString from 'query-string';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../store';
import logo from '../../assets/images/logo.svg';
import {ResetPasswordModal, Logo, ResetPasswordButton} from './ResetPassword';

const ResetPassword = observer(() => {
  const location = useHistory();
  const params = useLocation();
  const paramValues = queryString.parse(params.search);
  const {token} = paramValues;
  const {authStore} = useStore();
  const {onResetPassword} = authStore;
  const {handleSubmit, register, watch, errors} = useForm();

  const onSubmit = data => {
    const newData = {password: data.new_password, token};
    onResetPassword(newData).then(resp => {
      if (resp.status) {
        location.push('/login');
      }
    });
  };
  return (
    <Container fluid className="min-vh-100 pt-5">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Container>
        <ResetPasswordModal>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={7} xl={6}>
              <Card className="w-100 reset-password-card">
                <Card.Body>
                  <Card.Title className="text-uppercase text-center">
                    <Link to="/">
                      <Logo src={logo} alt="GIBLIB" className="logo" height="32" />
                    </Link>
                  </Card.Title>
                  <h5 className="text-center">Reset Password</h5>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                      <Form.Group as={Col} sm={12} controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          size="sm"
                          type="password"
                          name="new_password"
                          placeholder="New Password"
                          className="rounded-pill"
                          ref={register({
                            required: 'This field is required.',
                          })}
                        />
                        {errors.new_password && <small className="text-danger">{errors.new_password.message}</small>}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={12} controlId="confirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          size="sm"
                          type="password"
                          name="confirm_password"
                          placeholder="Confirm Password"
                          className="rounded-pill"
                          ref={register({
                            required: 'This field is required.',
                            validate: value => value === watch('new_password') || "Passwords don't match.",
                          })}
                        />
                        {errors.confirm_password && (
                          <small className="text-danger">{errors.confirm_password.message}</small>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <ResetPasswordButton id="ResetPassword-Submit" type="submit">
                      Submit
                    </ResetPasswordButton>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </ResetPasswordModal>
      </Container>
    </Container>
  );
});

export default ResetPassword;
