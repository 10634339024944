import axios from './axios';

/**
 * calls the organization GET api to get organization details
 */
export const getOrganization = (id, params = null, cancelToken = null) =>
  axios.get(`/organization/${id}`, {params, cancelToken});

export const getSortedOrganizations = (sortBy, sort = 'created_at', limit, page, sponsored) =>
  axios.get(`/organization/`, {
    params: {
      limit,
      page,
      sponsored,
      sort,
      sortBy,
    },
  });

export const getExpertsOrganizations = () => axios.get('/organization?type=expert');

export const getOrganizationVideos = (id, limit, sort, sortBy, page, specialty) =>
  axios.get(`/video/list?organization_id=${id}`, {
    params: {
      page,
      limit,
      sort,
      sortBy,
      specialty,
    },
  });

export const getOrganizationExperts = (id: number, page, limit = 10) =>
  axios.get(`/expert/list?organization_id=${id}&page=${page}&limit=${limit}`);

export const getOrganizationCourses = ({id, limit, sort, sortBy, page, specialty, type}) =>
  axios.get(`/course/list?organization_id=${id}`, {
    params: {
      page,
      limit,
      sort,
      sortBy,
      specialty,
      type,
    },
  });
