import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import {Row, Col, Form, Container, Spinner} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {
  Breadcrumbs,
  PageTitle,
  SortSelect,
  FormLabel,
  ActiveCrumb,
  Crumb,
  CourseFilter,
  SearchCourseDiv,
} from '../elements/search';
import ConferenceTile from '../../../components/ConferenceTile';
import {useStore} from '../../../store';

// Search Conferences page
const SearchConferences = observer(() => {
  const params = useLocation();
  const text = new URLSearchParams(params.search).get('q');
  const [sortValue, setSortValue] = useState('default');
  const {searchStore} = useStore();
  const {conferenceResults, getConferenceResults, isLoading} = searchStore;
  const id = 1;

  const changeSort = option => {
    setSortValue(option.value);
    getConferenceResults(text, 0, option.value);
  };

  const scroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && !isLoading) {
      const sortBy = sortValue === 'default' ? null : sortValue;
      getConferenceResults(text, conferenceResults.length, sortBy);
    }
  };

  useEffect(() => {
    getConferenceResults(text);
  }, [getConferenceResults, text]);

  return (
    <>
      <SearchCourseDiv
        onScroll={scroll}
        className={classNames({
          a_resultDiv: isLoading,
        })}
      >
        <div className="d-flex container">
          <Breadcrumbs className="a_searchConferenceCrumb">
            <Crumb href="/home">Search</Crumb>
            <ActiveCrumb href="#">{text}</ActiveCrumb>
          </Breadcrumbs>
        </div>

        <Container>
          <div className="d-flex align-items-center a_filterHeadDiv">
            <PageTitle className="mr-auto a_searchConferenceHead col-4">
              Conferences with &apos;{text || ' '}&apos;
            </PageTitle>
            <CourseFilter>
              <Form>
                <Form.Row>
                  <Form.Group controlId="sort" className="m-0 a_searchSortBy">
                    <div className="d-flex">
                      <FormLabel className="mt-3 mr-2">Sort</FormLabel>
                      <SortSelect data-testid="select" value={sortValue} onChange={e => changeSort(e.target)}>
                        <option value="default">Select</option>
                        <option value="asc">Name(A-Z)</option>
                        <option value="desc">Name(Z-A)</option>
                      </SortSelect>
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form>
            </CourseFilter>
          </div>
          <Row>
            {conferenceResults?.map(el => (
              <Col key={el.id} id={el.id} md={4} lg={4} xl={4}>
                <ConferenceTile key={el.name} item={el} type={id} onFollow={null} storeName="searchStore" />
              </Col>
            ))}
            {!conferenceResults?.length && !isLoading && <p className="px-4 pt-5 container">No results</p>}
          </Row>
          {!conferenceResults?.length && isLoading && (
            <div className="text-center my-3 mt-5 pt-5">
              <Spinner animation="grow" className="mt-5" />
            </div>
          )}
        </Container>
      </SearchCourseDiv>
      {conferenceResults?.length && isLoading ? (
        <div className="text-center my-3">
          <Spinner animation="grow" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default SearchConferences;
