import React from 'react';
import {useRouteMatch, Route, Redirect, Switch} from 'react-router-dom';
import {Nav, Container, Spinner} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {NavItem, Link, Tab, CmeFinishAssessment} from '../elements/cme';
import Completed from './Completed';
import InProgress from './InProgress';
import RevokedCME from './Revoked';
import {useStore} from '../../../store';
import CmeQuestions from '../../Video/components/CME';

type Props = {
  revoked: number,
};

const CMETab = observer(({revoked}: Props) => {
  const match = useRouteMatch();
  const {cmeStore} = useStore();
  const {
    question,
    cmeLoading,
    videoTitle,
    showQuestionBlock,
    showQuestions,
    inProgressTotalCount,
    completedTotalCount,
    revokedTotalCount,
  } = cmeStore;
  return (
    <>
      <Tab
        onClick={() => (question ? showQuestions(false) : '')}
        onKeyDown={() => (question ? showQuestions(false) : '')}
        aria-hidden="true"
      >
        <Container>
          <Nav defaultActiveKey={`${match.url}/in-progress`}>
            <NavItem className="nav-item">
              <Link className="nav-link a_showDashboard" activeClassName="active" to={`${match.url}/in-progress`}>
                {inProgressTotalCount}&nbsp;In Progress
              </Link>
            </NavItem>
            <NavItem className="nav-item">
              <Link className="nav-link" activeClassName="active" to={`${match.url}/completed`}>
                {completedTotalCount}&nbsp;Completed
              </Link>
            </NavItem>
            {revoked ? (
              <NavItem className="nav-item">
                <Link className="nav-link" activeClassName="active" to={`${match.url}/revoked`}>
                  {revokedTotalCount}&nbsp;Revoked
                </Link>
              </NavItem>
            ) : (
              <></>
            )}
          </Nav>
        </Container>
      </Tab>
      <div className="w-100 position-relative" id="cme-contents">
        <Switch>
          <Route exact path={`${match.path}/in-progress`} render={() => <InProgress />} />
          <Route exact path={`${match.path}/completed`} render={() => <Completed />} />
          <Route exact path={`${match.path}/revoked`} render={() => <RevokedCME />} />
          <Redirect to={`${match.path}/in-progress`} />
        </Switch>
        {question && showQuestionBlock && window.innerWidth < 768 ? (
          <CmeFinishAssessment className="in-progress-assessment">
            {cmeLoading ? (
              <div className="spinner-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
                <Spinner animation="grow" role="status" variant="secondary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <CmeQuestions expanded={question} title="CME Assessment" videoTitle={videoTitle} />
            )}
          </CmeFinishAssessment>
        ) : (
          ''
        )}
      </div>
    </>
  );
});

export default CMETab;
