import {makeAutoObservable, runInAction} from 'mobx';
import {getAds, trackAds, updateTrackAds, surveyResponse} from '../../api/ads';
import ErrorStore from '../error/errorStore';
import AccountStore from '../account/accountStore';

class AdsStore {
  ads = [];

  errorStore: ErrorStore;

  accountStore: AccountStore;

  trackStatus = false;

  adIds = [];

  bumperAd = {};

  showBumper = false;

  isBumperLoading = false;

  isAdLoading = false;

  bumperTrackId = null;

  survey = {};

  constructor({errorStore, accountStore}) {
    this.errorStore = errorStore;
    this.accountStore = accountStore;
    makeAutoObservable(this);
  }

  handleShowBumper = value => {
    this.showBumper = value;
  };

  getAd = async type => {
    if (type === 'bumper' && this.isBumperLoading) {
      return;
    }
    if (type != 'bumper' && this.isAdLoading) {
      return;
    }
    try {
      if (type === 'bumper') {
        this.isBumperLoading = true;
      } else {
        this.isAdLoading = true;
      }

      if (
        !this.accountStore?.account?.id ||
        (this.accountStore?.account?.access &&
          (this.accountStore?.account?.access?.bumper_ads || this.accountStore?.account?.access?.banner_ads)) ||
        !this.accountStore?.account?.subscription?.is_premium
      ) {
        const response = await getAds(type);
        if (response.status) {
          runInAction(() => {
            if (type === 'bumper') {
              if (response.data?.ads?.type === 'survey') {
                this.survey = response.data?.ads;
              } else {
                this.bumperAd = response.data?.ads;
              }

              this.showBumper = true;
            } else {
              this.ads = response.data?.ads;
            }
          });
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    } finally {
      if (type === 'bumper') {
        this.isBumperLoading = false;
      } else {
        this.isAdLoading = false;
      }
    }
  };

  userTrackingAds = async data => {
    try {
      const {impression_type, ad_id, impression_source, device, ad_type, is_answered, survey_id} = data;
      if (this.adIds?.indexOf(ad_id) === -1) {
        if (!this.accountStore?.account?.subscription?.is_premium) {
          const resp = await trackAds(
            ad_id,
            impression_type,
            impression_source,
            device,
            ad_type,
            is_answered,
            survey_id,
          );
          if (resp?.data?.track?.id && ad_type != 'survey') {
            this.adIds = [...this.adIds, ad_id];
          }
          if (ad_id == this.bumperAd.id) {
            this.bumperTrackId = resp?.data?.track?.id;
          }
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updateAdHistory = async (is_bumper_skipped, impression_source, ad_type, is_answered) => {
    try {
      if (this.bumperTrackId) {
        await updateTrackAds(this.bumperTrackId, is_bumper_skipped);
      } else if (!this.accountStore?.account?.subscription?.is_premium) {
        const resp = await trackAds(this.bumperAd.id, 'view', impression_source, '');
        await updateTrackAds(resp?.data?.track?.id, is_bumper_skipped);
      }
      if (ad_type === 'survey') {
        await updateTrackAds(this.bumperTrackId, is_answered);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addSurveyResponse = async (survey_id, answers) => {
    try {
      await surveyResponse(survey_id, answers);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default AdsStore;
