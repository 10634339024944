// @flow
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Container, ProgressBar, OverlayTrigger} from 'react-bootstrap';
import Moment from 'react-moment';
import {observer} from 'mobx-react-lite';
import Thumbnail from '../elements/video-thumbnail.png';
import {
  ThumbnailDiv,
  ThumbnailImg,
  ButtonGroup,
  VideoCardHeader,
  VideoTitle,
  Organization,
  Details,
  VideoCard,
  DetailDiv,
} from '../elements/cme';
import LocalStorageService from '../../../utils/LocalStorageService';
import {useStore} from '../../../store';
import UpgradeModal from '../../../components/UpgradeModal';
import {DeleteModal} from './DeleteModal';
import AlertComponent from '../../../components/AlertComponent';
import PlaylistPopover from '../../../components/PlaylistPopover';
import {DownloadButton} from '../../../components/AssessmentButton';
import {amplitude} from '../../../utils/Amplitude';
import {secondsToTime} from '../../../utils/helper';

type Props = {
  item: {
    id: number,
    watchProgress: number,
    completed_at: Date,
    organizationCME: {
      id: number,
      credit_count: number,
      release_date: Date,
      cme_expire_at: Date,
      withdraw_at: Date,
      video: {
        id: number,
        title: string,
        thumbnail: string,
        organization: {
          name: string,
        },
        duration: number,
        watchTime: number,
      },
    },
  },
  isDownload: boolean,
};

const CMECompleted = observer(({item, isDownload}: Props) => {
  const location = useHistory();
  const {videoStore, cmeStore, alertStore, accountStore} = useStore();
  const {download, deleteCompletedCme} = cmeStore;
  const {account} = accountStore;
  const {message, status, resetAlert} = alertStore;
  const {playlist, getAllPlaylists, addToPlaylist, updatePlaylist, toggleVideoBookmark} = videoStore;

  const [dowloadLoader, setDowloadLoader] = useState(false);
  const [watchProgress, setWatchProgress] = useState(0);
  const [isTrialUser, setIsTrialUser] = useState(false);

  /* Delete cme modal state */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);
  const handleDeleteModalClose = () => setShowDeleteModal(false);

  const handleSubmitForm = () => {
    deleteCompletedCme(item.id);
    setShowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    handleDeleteModalShow();
  };

  useEffect(() => {
    const wTime = item?.organizationCME?.video?.watchTime || 0;
    const duration = item?.organizationCME?.video?.duration || 0;
    const progress = Math.round((wTime / duration) * 100);
    setWatchProgress(progress);
  }, [item]);

  useEffect(() => {
    setIsTrialUser(account.subscription?.status === 'trialing');
  }, [account]);

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const loggedInUser = LocalStorageService.getUser();

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) {
      setUserState(true);
      getAllPlaylists();
    }
  }, [account, getAllPlaylists]);

  const createAndAddToPlaylist = data => {
    addToPlaylist(item?.organizationCME?.video?.id, data.listname, true);
  };

  const updateExistingPlaylist = data => {
    updatePlaylist(item?.organizationCME?.video?.id, data, true);
  };

  const [bookmarkIcon, setBookmarkIcon] = useState(false);

  useEffect(() => {
    if (item?.organizationCME?.video?.bookmark?.length) setBookmarkIcon(true);
  }, [item]);

  const openVideo = video => {
    location.push(`/video/${video.id}`);
  };

  const toggleBookmark = () => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic')) {
        toggleVideoBookmark(bookmarkIcon, item?.organizationCME?.video?.id).then(() => {
          setBookmarkIcon(!bookmarkIcon);
        });
        amplitude.getInstance().logEvent('bookmarked');
      } else setShowBookmarkUpgradeModal(true);
    } else location.push('/login');
  };

  const onDownload = () => {
    if (!dowloadLoader && isDownload) {
      setDowloadLoader(true);
      download([item?.id]).then(() => {
        setDowloadLoader(false);
        amplitude.getInstance().logEvent('cme-downloaded');
      });
    }
  };

  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <VideoCard>
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
        <Container>
          <div className="card-border-bottom d-flex card-padding" id={item.id}>
            <ThumbnailDiv>
              <ThumbnailImg
                src={item?.organizationCME?.video?.thumbnail || Thumbnail}
                alt={item?.organizationCME?.video?.title}
              />
              <ProgressBar now={watchProgress} />
            </ThumbnailDiv>
            <DetailDiv className="w-100">
              <VideoCardHeader className="d-flex">
                <div className="d-flex">
                  <div className="progress-badge completed-badge">Completed</div>
                  {item?.organizationCME ? (
                    <span className="credit">
                      {item?.organizationCME?.credit_count} Credit
                      {item?.organizationCME?.credit_count > 1 ? 's' : ''}
                    </span>
                  ) : null}
                </div>
              </VideoCardHeader>
              <VideoTitle onClick={() => openVideo(item?.organizationCME?.video)}>
                <h5>{item?.organizationCME?.video?.title}</h5>
              </VideoTitle>
              <Organization>
                {item?.organizationCME?.video?.organization?.name}
                {item?.organizationCME?.video?.organization?.name &&
                item?.organizationCME?.video?.specialties.length > 0
                  ? ' | '
                  : ''}
                {item?.organizationCME?.video?.specialties.length > 0
                  ? `${item?.organizationCME?.video?.specialties
                      .slice(0, 2)
                      ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                  : ''}
              </Organization>
              <Details>
                {secondsToTime(item?.organizationCME?.video?.duration || 0)}
                &nbsp;|&nbsp;
                <Moment format="MM/YYYY">{item?.organizationCME?.release_date}</Moment>
                &nbsp;|&nbsp;
                <span>
                  Completed <Moment format="MM/DD/YYYY">{item?.completed_at}</Moment>
                </span>
              </Details>
            </DetailDiv>
            <ButtonGroup>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={props => (
                  <PlaylistPopover
                    props={props}
                    videoPlaylist={item?.organizationCME?.video?.playlist}
                    allPlaylist={playlist}
                    onExisting={updateExistingPlaylist}
                    onNew={createAndAddToPlaylist}
                    userState={userState}
                    showUpgradeModal={isShowPlaylistUpgradeModal}
                    onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                  />
                )}
                rootClose
              >
                <Button
                  variant="basic"
                  className="a_playlist action-icon-button mr-3"
                  data-tip="Playlist"
                  data-for="videoActions"
                  onClick={() => setShowPlaylistUpgradeModal(true)}
                >
                  <i className="fak fa-list-ul" />
                </Button>
              </OverlayTrigger>
              <Button
                variant="basic"
                onClick={() => toggleBookmark()}
                className="a_bookmark action-icon-button mr-3"
                data-tip="Bookmark"
                data-for="videoActions"
              >
                {bookmarkIcon ? (
                  <span key="video_bookmark">
                    <i className="fas fa-bookmark" />
                  </span>
                ) : (
                  <span key="video_bookmark_active">
                    <i className="far fa-bookmark" />
                  </span>
                )}
              </Button>
              <Button variant="basic" className="action-icon-button a_remove" onClick={handleDeleteModal}>
                <i className="fa fa-trash" />
              </Button>
              <DownloadButton
                className="download_button"
                onDownload={onDownload}
                canDownload={isDownload}
                isTrialUser={isTrialUser}
                disabled={dowloadLoader}
              />
            </ButtonGroup>
          </div>
        </Container>
      </VideoCard>
      {/* Update Modal to delete cme */}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteModalClose={handleDeleteModalClose}
        handleSubmitForm={handleSubmitForm}
        videoTitle={item?.organizationCME?.video?.title}
        tabName="Completed"
      />
    </>
  );
});

export default CMECompleted;
