/* eslint-disable react/no-danger */
import React from 'react';
import ToastContainer from './styles';
import image from '../../assets/icons/close.png';

type Props = {
  toast: {
    id: number,
    text: string,
  },
  click: Function,
};

const Toast = ({toast, click}: Props) => {
  return (
    <ToastContainer>
      {/* <p>{toast ? toast.text : 'This is a notification component'}</p> */}
      <div dangerouslySetInnerHTML={{__html: toast?.text}} />
      <button type="button" className="a_closeAnnouncement" onClick={() => click(toast.id)}>
        <img src={image} alt="close_button" />
      </button>
    </ToastContainer>
  );
};

export default Toast;
