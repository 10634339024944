import React from 'react';
import {Spinner} from 'react-bootstrap';
import PropTypes from 'prop-types';

const ScreenLoader = ({spinnerSize, containerHeight}) => {
  return (
    <div style={{height: containerHeight}} className="d-flex w-100 align-items-center justify-content-center">
      <Spinner size={spinnerSize} animation="grow" />
    </div>
  );
};

ScreenLoader.propTypes = {
  spinnerSize: PropTypes.oneOf(['sm', 'lg']),
  containerHeight: PropTypes.string.isRequired,
};
ScreenLoader.defaultProps = {
  spinnerSize: 'lg',
};

export default ScreenLoader;
