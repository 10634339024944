/* eslint-disable react/no-danger */
import React, {useEffect, useState} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Image, Col, Form, Row} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import AlertComponent from '../../components/AlertComponent';
import {
  Main,
  ExpertHeader,
  Breadcrumbs,
  Crumb,
  SortSelect,
  ExpertCard,
  TitleBar,
  FormLabel,
  StyledModal,
  BioTitle,
} from './elements/expertdetails';
import VideoTile from '../../components/VideoTile';
import {useStore} from '../../store';
import close from './elements/close.png';
import LocalStorageService from '../../utils/LocalStorageService';
import UpgradeModal from '../../components/UpgradeModal';
import ScreenLoader from '../../components/ScreenLoader';
import {amplitude} from '../../utils/Amplitude';
import PageBottom from '../../components/PageBottom';
import {ActiveSmallBtn, DefaultSmallBtn} from '../../components/FollowButtons';

const ExpertDetails = observer(() => {
  const {expertStore, videoStore, alertStore, accountStore} = useStore();
  const match = useRouteMatch();

  const [sortValue, setSortValue] = useState('newest');
  const [show, setShow] = useState(false);

  const location = useHistory();

  const {
    expert,
    expertVideos,
    getExpert,
    followExpert,
    unFollowExpert,
    sortVideos,
    expertDetailIsLoading,
  } = expertStore;

  const {getAllPlaylists} = videoStore;

  const {message, status, resetAlert} = alertStore;

  const {account} = accountStore;

  const loggedInUser = LocalStorageService.getUser();

  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const {id} = match.params;
    getExpert(id);
  }, [getExpert, match.params]);

  useEffect(() => {
    const eventProperties = {
      'expert name': `${expert?.name}`,
    };
    amplitude.getInstance().logEvent('expert-page-viewed', eventProperties);
  }, [expert]);

  useEffect(() => {
    sortVideos(sortValue);
  }, [sortVideos, sortValue]);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  const follow = id => {
    const user = localStorage.getItem('user');
    if (user) {
      if (!account?.subscription?.name?.includes('Basic')) {
        if (expert.is_following) {
          unFollowExpert(id);
        } else {
          followExpert(id);
        }
      } else setShowFollowUpgradeModal(true);
    } else {
      location.push('/login');
    }
  };

  const changeSort = option => {
    setSortValue(option.value);
  };

  return (
    <div className="d-flex flex-column flex-fill">
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <Helmet
        title={`${expert?.name}`}
        meta={[
          {
            name: 'description',
            content: expert?.description,
          },
          {
            property: 'og:title',
            content: expert?.name,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content:
              expert?.thumbnail ||
              'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content:
              expert?.thumbnail ||
              'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex overflow-hidden">
        <Sidebar />
        <Main role="main" className="w-100">
          {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
          {expertDetailIsLoading ? (
            <ScreenLoader containerHeight="calc(50vh)" />
          ) : (
            <>
              <ExpertHeader className="mb-md-5">
                <Breadcrumbs>
                  <Crumb onClick={() => location.push('/')}>Home</Crumb>
                  <Crumb href="#">Experts</Crumb>
                  <Crumb>{expert.name}</Crumb>
                </Breadcrumbs>
                <ExpertCard className="d-flex">
                  <div className="d-flex align-items-center col-md-5 experts-profile">
                    <div>
                      <Image src={expert.thumbnail} style={{height: '120px'}} alt={expert.name} roundedCircle />
                    </div>
                    <div className="ml-3 text-left">
                      <h5 className="mb-2 font-weight-bold">{expert.name}</h5>
                      <p className="mb-0">{expert?.specialty?.name}</p>
                      <p className="mb-3">{expert?.organization?.name}</p>
                      {expert.is_following ? (
                        <ActiveSmallBtn
                          className="a_followed"
                          data-testid="follow-button"
                          onClick={() => follow(expert)}
                        >
                          <span className="following">
                            <i className="fas fa-check mr-2" />
                            Following
                          </span>
                          <span className="unfollow">
                            <i className="fas fa-times mr-2" />
                            Unfollow
                          </span>
                        </ActiveSmallBtn>
                      ) : (
                        <DefaultSmallBtn
                          className="a_follow"
                          data-testid="follow-button"
                          onClick={() => follow(expert)}
                        >
                          <i className="fas fa-plus mr-2" />
                          Follow
                        </DefaultSmallBtn>
                      )}
                      {loggedInUser && expert.display_referral ? (
                        <>
                          <br />
                          <a
                            href="https://info.giblib.com/refer-a-patient"
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-primary refer-patient"
                          >
                            Refer a Patient
                          </a>
                        </>
                      ) : null}
                    </div>
                  </div>
                </ExpertCard>
              </ExpertHeader>
              <StyledModal show={show} onHide={() => setShow(false)} size="lg">
                <Row>
                  <div>
                    <Image src={expert.thumbnail} style={{height: '60px'}} alt={expert.name} roundedCircle />
                  </div>
                  <Col>
                    <p id="modal-label">{expert.name}</p>
                  </Col>
                  <button type="button" className="bg-transparent border-0 close-icon" onClick={() => setShow(false)}>
                    <img alt="close" src={close} />
                  </button>
                </Row>
                <div className="bio-details">
                  <hr className="line" />
                  <Row>
                    <Col>
                      <Col>
                        <BioTitle>Primary Location</BioTitle>
                        <p>{expert.location}</p>
                      </Col>
                      <Col>
                        <BioTitle>Position</BioTitle>
                        <p dangerouslySetInnerHTML={{__html: expert.position}} />
                      </Col>
                      <Col>
                        <BioTitle>Board Certification</BioTitle>
                        <p>{expert.certification}</p>
                      </Col>
                      <Col>
                        <BioTitle>Fellowship</BioTitle>
                        <p>{expert.fellowship}</p>
                      </Col>
                      <Col>
                        <BioTitle>Residency</BioTitle>
                        <p>{expert.residency}</p>
                      </Col>
                      <Col>
                        <BioTitle>Medical School</BioTitle>
                        <p>{expert.medical_school}</p>
                      </Col>
                      <Col>
                        <BioTitle>College</BioTitle>
                        <p>{expert.college}</p>
                      </Col>
                    </Col>
                    <Col>
                      <Col>
                        <BioTitle>Focus Area</BioTitle>
                        <p>{expert.focus_area}</p>
                      </Col>
                      {expert.publications ? (
                        <>
                          <Col>
                            <p className="publication-title">Publications</p>
                            <hr className="line" />
                          </Col>
                          {expert.publications.map(el => (
                            <Col key={el.id}>
                              <BioTitle>Publication</BioTitle>
                              <p className="publication">{el}</p>
                            </Col>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </div>
              </StyledModal>
              {expertVideos?.length ? (
                <div className="m-md-5">
                  <TitleBar className="row mx-md-3 mx-lg-5 d-flex justify-content-between">
                    <h5 className="font-weight-bold pl-md-4 ml-md-3 mt-1">All Videos</h5>
                    <Form className="pl-md-4 ml-md-3">
                      <Form.Group controlId="sort">
                        <div className="d-flex">
                          <FormLabel style={{whiteSpace: 'nowrap'}} className="mr-md-2">
                            Sort
                          </FormLabel>
                          <SortSelect value={sortValue} data-testid="select" onChange={e => changeSort(e.target)}>
                            <option data-testid="select-option" value="newest">
                              Latest
                            </option>
                            <option value="oldest">Oldest</option>
                            <option value="longest">Longest</option>
                            <option value="shortest">Shortest </option>
                            <option value="alphainc">Title(A-Z)</option>
                            <option value="alphadec">Title(Z-A)</option>
                          </SortSelect>
                        </div>
                      </Form.Group>
                    </Form>
                  </TitleBar>
                  <div className="row px-md-4 mx-3 mx-lg-5">
                    {expertVideos.map(el => (
                      <Col key={el.id} className="mb-2 px-1" xs={6} md={6} lg={3}>
                        <VideoTile item={el} storeName="expertStore" referrer="expert page" />
                      </Col>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="m-5">
                  <h5 className="mb-4 ml-2 font-weight-bold">No Videos</h5>
                </div>
              )}
            </>
          )}
          {!expertDetailIsLoading && <Footer />}
        </Main>
      </div>
      <PageBottom />
    </div>
  );
});

export default ExpertDetails;
