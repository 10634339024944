import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

export const Img = styled.img`
  /* Adapt the colors based on primary prop */
  filter: ${({theme}) => (theme.body === '#FFF' ? 'invert(1)' : 'invert(.3)')};
`;

export const Main = styled.main`
  padding-top: 55px;
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-size: 15px;
  width: 100vw;
  .desktop-sort {
    display: block;
  }
  .mobile-sort {
    display: none;
  }
  @media screen and (max-width: 576px) {
    .mobile-pagination {
      display: none !important;
    }
  }

  /* Adapt the colors based on primary prop */
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
    padding-top: 54px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 25px;
    margin: auto;
  }
  @media screen and (max-width: 576px) {
    .desktop-sort {
      display: none;
    }
    .mobile-sort {
      display: block;
    }
  }
`;

export const Header = styled.div`
  height: 126px;

  div {
    background: linear-gradient(90.03deg, #000000 20.16%, rgba(5, 5, 7, 0) 100%);
    height: 100%;
  }
  img {
    margin-right: 19px;
  }

  h5 {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0;
    padding-top: 9px;
    @media screen and (max-width: 576px) {
      justify-content: center;
    }
  }

  @media only screen and (device-width: 1024px) {
    padding: 3rem 1rem 0rem 2rem;
    h5 {
      padding-left: 28px;
    }
  }
  @media only screen and (device-width: 768px) {
    padding: 3rem 1rem 0rem 2rem;
    h5 {
      padding-left: 28px;
    }
  }
  @media screen and (max-width: 576px) {
    h5 {
      padding-left: 10px;
    }
  }
`;
export const Sort = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: -30px;
  @media screen and (max-width: 720px) {
    margin-right: 23px;
  }
  @media screen and (max-width: 576px) {
    .mobileSort {
      width: 100px !important;
    }
  }
`;
export const CatalogList = styled.div`
  padding-bottom: 2rem;
  @media screen and (max-width: 576px) {
    #catalog_container,
    #course_container {
      padding-left: 0;
    }
  }
`;
export const SortSelect = styled.select`
  background: transparent;
  padding: 8px 30px 8px 16px;
  border-radius: 3px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='white' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: #fff;
  &:focus {
    outline: none;
  }
  @media screen and (min-width: 576px) {
    min-width: 200px;
  }
  @media screen and (min-width: 576px) and (max-width: 1200px) {
    width: 170px;
    margin-left: 5px;
  }
  @media screen and (max-width: 576px) {
    width: 175px;
    .mobileSort {
      width: 100px !important;
    }
  }
  @media screen and (max-width: 414px) {
    width: 158px;
  }
  @media screen and (max-width: 375px) {
    width: 158px;
  }
  @media screen and (max-width: 320px) {
    width: 130px;
  }
`;

export const Details = styled.p`
  color: ${({theme}) => theme.text2};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  @media screen and (max-width: 576px) {
    font-size: 10px;
    letter-spacing: -0.05px;
    line-height: 10px;
  }
`;

export const CourseTitle = styled.div`
  height: 25px;
  line-height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;

  p {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.14px;
    line-height: 25px;
  }
  .icon-cme {
    width: 14px;
  }
  .fa-head-vr {
    color: #ffea64;
    width: 14px;
  }
  @media screen and (max-width: 576px) {
    min-width: 300px;
    -webkit-box-orient: vertical;
    p {
      font-size: 14px;
      letter-spacing: -0.08px;
      line-height: 22px;
      font-weight: 400;
    }
    .icon-cme {
      height: 15px;
    }
  }
  @media screen and (max-width: 375px) {
    min-width: 265px;
  }

  @media screen and (max-width: 360px) {
    min-width: 252px;
  }

  @media screen and (max-width: 320px) {
    min-width: 218px;
  }
`;

export const ButtonGroup = styled.div`
  width: 260px;
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 300px;
  }
  @media (max-width: 576px) {
    width: 386px;
  }

  .a_playlist,
  .a_bookmark {
    padding: 0;
    vertical-align: top;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-left: 15px;
    color: ${({theme}) => theme.text2};
    @media screen and (max-width: 576px) {
      width: 10px;
      height: 10px;
    }
    @media screen and (max-width: 320px) {
      width: 8px;
      height: 8px;
    }
  }
`;

export const CourseFilter = styled.div`
  margin-bottom: 2rem;
  position: relative;

  h5 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 18px;
    font-weight: bold;

    .filter-btn {
      padding: 4.5px 9px;
      background: rgba(255, 255, 255, 0.1);
      border: none;
      display: none;
      margin-right: 12px;
      width: 36px;
      height: 36px;
      padding: 0;

      img {
        width: 27px;
      }
      @media screen and (max-width: 576px) {
        display: inline-block;
        &.show {
          display: block;
        }
        &.hide {
          display: none;
        }
      }
    }

    .filter-img {
      img {
        margin-right: 15px;
      }

      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }

  label {
    font-size: 15px;
  }

  .form-row {
    padding: 0.2rem 0rem;
  }

  .search::placeholder {
    color: #fff;
    font-family: Roboto;
  }

  button.search-button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 36px;
  }

  .form-control {
    background: rgba(255, 255, 255, 0.1);
    border-left: none;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    height: 36px;
    color: #fff !important;
    font-size: 14px;
    box-shadow: none;
    @media only screen and (device-width: 1024px) {
      min-width: 175px;
    }
    @media only screen and (device-width: 768px) {
      min-width: 175px;
    }
  }

  .input-group-prepend {
    margin-right: 0px;
    z-index: 0;
  }

  .input-group {
    padding: 0 1rem 1rem 1rem;
    @media only screen and (device-width: 1024px) {
      flex-wrap: nowrap;
    }
    @media only screen and (device-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  .search-button {
    height: 32px;
    padding: 0.4rem;
    padding-right: 12px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-right: none;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    z-index: 9998;
  }

  .fa-search {
    background-color: none !important;
    margin-left: 4px !important;
    margin-right: -15px !important;
    font-size: 18px !important;
  }

  .clear-btn {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    letter-spacing: 0;
    width: 69px;
    height: 22px;
    background: #2b2d33;
    border-radius: 3px;
    border: none;
    padding: 0;
    @media screen and (max-width: 576px) {
      height: 36px;
      margin-right: 36px;
    }
  }

  .form-group {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .catalog-page {
    display: block;
  }

  .switchFilter {
    background: #181a21;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 0 16px;
    margin: 10px 0;
    height: 48px;

    .custom-control {
      min-width: 48px;
    }

    .custom-switch .custom-control-label::before {
      width: 48px;
      height: 24px;
      border-radius: 50px;
      background: #a3a3a6;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: #0988fa;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }

    .custom-switch .custom-control-label::after {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #fff;
    }

    .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(24px);
    }

    label {
      margin-bottom: 0;
      line-height: 33px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 40%;
  }

  @media screen and (max-width: 576px) {
    float: none;
    width: 100%;
    padding-left: 1rem;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 0rem;
    .catalog-page,
    .switchFilter {
      display: none;
    }
    .dropdown,
    #filterSpecialty,
    #filterOrganization {
      width: 100% !important;
    }
  }
`;

export const Filter = styled.div`
  .text-nobreak {
    white-space: nowrap;
  }
  .a_filter-btn {
    width: 100%;
    background: #008aff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-weight: bold;
    margin-top: 10px;
  }
`;

export const FilterContainer = styled.div`
  border-radius: 3px;
  padding: 16px 5px;
  position: relative;

  #search-catalog::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    background: rgba(24, 26, 33, 0.8);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(9px);
    top: 0;
  }

  > div {
    position: relative;
  }

  .show-mobile {
    display: none;

    .filter-btn {
      background: #008aff;
    }

    h5 {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-left: 0;
      margin-bottom: 0;
    }
    @media screen and (max-width: 576px) {
      display: flex;
    }
  }

  @media screen and (max-width: 576px) {
    margin-left: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: -30px;
    z-index: 1;
  }

  .closeFilter {
    background: none;
    border: none;
    padding: 0;
    height: 35px;

    img {
      width: 21px;
    }
  }

  .hide-mobile {
    display: flex;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  @media screen and (max-width: 576px) {
    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
  }

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
  }
`;

export const MobileFilters = styled.div`
  display: none;
  .form-row {
    flex-wrap: nowrap;
    padding: 0rem 0rem;
  }
  @media screen and (max-width: 576px) {
    display: block;
  }
`;

export const ChipText = styled.p`
  color: #fff;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 5px 6px 5px 8px;
  height: 24px;
  border-radius: 3px;
  background-color: #008aff;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;

  button {
    padding-right: 0;
    img {
      width: 11.6px;
      height: 11.6px;
    }
  }

  :hover {
    background-color: #009994;
  }
  :focus {
    border: 1px solid #cce8ff;
  }
`;

export const PaginationContainer = styled.div`
  margin-left: 32px;
  .a_sort,
  .a_profession {
    background: transparent;
    padding: 5px 30px 5px 16px;
    border-radius: 3px;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
    color: #fff;
    width: 190px;
    text-align: left;

    ::after {
      right: 8px;
      position: absolute;
      top: calc(50% - 0.25em);
      border-top: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-bottom: 0;
      border-left: 0.5em solid transparent;
    }
    ~ .dropdown-menu {
      width: 190px;
    }
  }
  .a_profession {
    width: 100%;
    padding: 8px 30px 8px 16px;

    ~ .dropdown-menu {
      width: 100%;
    }
  }
  text-align: right;
  position: relative;
  label {
    width: 100%;
    padding: 8px 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
  .form-check-input {
    display: none;
  }
  .form-check {
    padding-left: 44px;
  }
  input ~ label::after {
    content: '';
    left: 16px;
    bottom: 10px;
    width: 16px;
    height: 16px;
    border: 2px solid #008aff;
    position: absolute;
    display: block;
    border-radius: 50px;
    top: calc(50% - 8px);
  }
  input:checked ~ label::before {
    position: absolute;
    display: block;
    border-radius: 50px;
    content: '';
    left: 14px;
    bottom: 8px;
    border: 2px solid rgba(0, 138, 255, 0.5);
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
  }
  input:checked ~ label {
    color: #008aff;
  }
  input:checked ~ label::after {
    border: 4px solid #008aff;
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  span {
    font-size: 12px;
  }
  .a_limit,
  .a_p_dec,
  .a_p_inc {
    padding: 0;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 16px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
    border-radius: 3px;
    margin-left: 8px;
  }
  .a_p_dec:hover,
  .a_p_inc:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .a_limit {
    font-size: 12px;
    padding-left: 4px;
    padding-top: 2px;
  }
  .a_limit:hover,
  .a_limit:active,
  .a_limit:focus {
    font-size: 12px;
    background-color: #008aff;
    color: #dcdee1;
  }
  .dropdown-menu {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 140px;
    text-align: left;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.18px;
    line-height: 16px;
    min-width: 7rem;
    padding: 8px 0;
  }
  .dropdown {
    display: inline-block;
  }
  &.professionDrop .dropdown {
    display: block;
  }
  &.professionDrop {
    margin-left: 0;

    .dropdown-menu {
      position: relative !important;
      transform: none !important;
      border-radius: 8px;
    }
  }
  #limitMenu::after {
    border: none;
  }

  &.sort-mobile {
    display: none;
  }
  @media screen and (max-width: 576px) {
    display: none;

    &.sort-mobile {
      display: inline-block;
      float: right;
    }
    &.professionDrop {
      display: block;
    }
  }
`;

export const NavItem = styled.li`
  width: 112px;
`;

export const Tab = styled.nav`
  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};
  margin-bottom: 30px;

  .nav {
    flex-wrap: nowrap;
  }
`;

export const Link = styled(NavLink)`
  color: #fff;
  letter-spacing: -0.17px;
  text-align: center;
  padding: 14px 18px 12px 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  &.active {
    font-weight: bold;
    border-bottom: 4px var(--primary) solid;
    border-image-source: linear-gradient(90deg, #0dfff3 0%, #008aff 100%);
    border-image-slice: 1;
    :hover {
      color: #fff;
    }
  }
  :hover {
    border-bottom: 4px solid #999999;
    color: #999999;
  }
`;
