import styled from 'styled-components';
import {Title2} from '../../utils/styles/typography';

export const ButtonGroup = styled.div`
  white-space: nowrap;

  .a_playlist,
  .a_bookmark {
    padding: 0;
    vertical-align: top;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-left: 15px;
    color: ${({theme}) => theme.text2};
    @media screen and (max-width: 576px) {
      width: 12px;
      height: 12px;
    }
    @media screen and (max-width: 320px) {
      width: 8px;
      height: 8px;
    }
  }
`;

export const ThumbnailImg = styled.img`
  width: 248px;
  height: 140px;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    width: 84px;
    height: 47.5px;
  }
  @media screen and (max-width: 320px) {
    width: 70px;
    height: 40px;
  }
`;

export const CardHolder = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(144, 67, 255, 0.2) 208.44%), rgba(24, 26, 33, 0.5);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 16px;
`;

export const NumOfVideos = styled.div`
  width: 72px;
  height: 20px;
  background: #008aff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  white-space: nowrap;
  font-weight: 700;
  position: absolute;
  margin-top: 12px;
  margin-left: 12px;
`;

export const IconDiv = styled.div`
  margin-bottom: 32px;
  svg {
    margin-right: 8px;
    path {
      height: 20px;
    }
  }
  .cme-icon {
    margin-right: 3px;
  }
`;

export const Title = styled(Title2)`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 44px;
`;
