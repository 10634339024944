import styled from 'styled-components';

export const FinishAssessment = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 4px 8px;
  width: 154px;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: linear-gradient(90deg, #08d1f8 0%, #008aff 100%);
  border-radius: 3px;
  :hover {
    opacity: 0.8;
  }
  span {
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const StartAssessment = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px;
  width: 147px;
  height: 30px;
  padding: 4px 8px;
  background: ${props => (props.watched ? '#008aff' : 'rgba(255, 255, 255, 0.1)')};
  border-radius: 3px;
  :hover {
    opacity: 0.8;
  }
  span {
    color: ${props => (props.watched ? '#ffffff' : 'rgba(255, 255, 255, 0.4)')};
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const DownloadCME = styled.button`
  display: flex;
  background: #0dfff3;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  border: 0px;
  height: 30px;
  width: 134px;
  padding: 8px;
  gap: 7px;
  box-sizing: border-box;
  :hover {
    display: flex;
    padding: 8px;
    opacity: 0.8;
  }
  :active {
    opacity: 0.8;
  }
  .icon-download {
    margin-right: 8px;
  }
`;

export const DownloadSpan = styled.span`
  color: #181a21;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.11px;
  line-height: 14px;
`;

export const RetakeBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px;
  width: 160px;
  height: 30px;
  padding: 4px 8px;
  background: #008aff;
  border-radius: 3px;
  :hover {
    opacity: 0.8;
  }
  span {
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
`;
