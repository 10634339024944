import React, {useEffect} from 'react';
import {Row, Col} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import VideoTile from '../../../../components/VideoTile';
import {useStore} from '../../../../store';

const UsersVideos = observer(() => {
  const {videoStore} = useStore();
  const {allUsersVideos, fetchAllUserVideos} = videoStore;
  useEffect(() => {
    fetchAllUserVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Row>
        {allUsersVideos.length ? (
          allUsersVideos?.map(el => (
            <Col key={el.id} id={el.id} md={4} lg={3} xl={3} className="mb-3">
              <VideoTile
                key={el.title}
                item={el}
                storeName="videoStore"
                storeVar="userVideos"
                brightcove_id={el.brightcove_id}
                fromMyVideo
                // type={carouselId}
                referrer="view_all_carousel"
              />
            </Col>
          ))
        ) : (
          <div>No Video Uploaded</div>
        )}
      </Row>
    </div>
  );
});

export default UsersVideos;
