import {observer} from 'mobx-react-lite';
import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Wrapper, Title, MenuLink, Icon, Copyright} from './style';
import app from '../../assets/icons/app-store.png';

const Footer = observer(() => {
  return (
    <Wrapper className="footer">
      <Row>
        <Col sm={12} md={3} className="px-4">
          <Title>Company</Title>
          <MenuLink as={Link} to="/">
            Home
          </MenuLink>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/pricing">
              Plans
            </a>
          </MenuLink>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/blog">
              Blog
            </a>
          </MenuLink>
        </Col>
        <Col sm={12} md={3} className="px-4">
          <Title>Product</Title>
          <MenuLink>
            <a href="https://watch.giblib.com/browse/specialty">Specialties</a>
          </MenuLink>
          <MenuLink>
            <a href="https://watch.giblib.com/conferences">Conferences</a>
          </MenuLink>
          {/* <MenuLink>
            <a href="https://watch.giblib.com/courses">Courses</a>
          </MenuLink> */}
        </Col>
        <Col sm={12} md={3} className="px-4">
          <Title>Institution</Title>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/institutions/institutional-pricing">
              Institutional Inquiries
            </a>
          </MenuLink>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/institutions/content-partner">
              Become a<br />
              Content Partner
            </a>
          </MenuLink>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/institutions/resources">
              Resources
            </a>
          </MenuLink>
        </Col>
        <Col sm={12} md={3} className="px-4">
          <Title>Support</Title>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/support/contact-us">
              Contact Us
            </a>
          </MenuLink>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/support/faq">
              FAQ
            </a>
          </MenuLink>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/support/cme-accreditation">
              CME Accreditation
            </a>
          </MenuLink>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/support/privacy-policy">
              Privacy Policy
            </a>
          </MenuLink>
          <MenuLink>
            <a target="_blank" rel="noreferrer" href="https://www.giblib.com/support/terms-of-service">
              Terms of Services
            </a>
          </MenuLink>
        </Col>
      </Row>
      <Row>
        <Title>Download our mobile app</Title>
      </Row>
      <Row>
        <a target="_blank" rel="noreferrer" href="https://apps.apple.com/app/apple-store/id1606398783">
          <img src={app} alt="download our mobile app" />
        </a>
      </Row>
      <Row className="pt-3">
        <Title>Follow Us</Title>
      </Row>
      <Row>
        <Icon>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/thegiblibapp/">
            <i className="fab fa-facebook-f" />
          </a>
        </Icon>
        <Icon>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/theGIBLIBapp">
            <i className="fab fa-twitter" />
          </a>
        </Icon>
        <Icon>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/giblib/?hl=en">
            <i className="fab fa-instagram" />
          </a>
        </Icon>
        <Icon>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCPpRfYLhUU2gi3B2P8cdt0Q">
            <i className="fab fa-youtube" />
          </a>
        </Icon>
        <Icon>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/giblib/mycompany/">
            <i className="fab fa-linkedin" />
          </a>
        </Icon>
        <Icon>
          <a target="_blank" rel="noreferrer" href="https://www.pinterest.com/giblibm/_created/">
            <i className="fab fa-pinterest" />
          </a>
        </Icon>
        <Icon>
          <a target="_blank" rel="noreferrer" href="https://medium.com/@GIBLIB">
            <i className="fab fa-medium" />
          </a>
        </Icon>
      </Row>
      <Row className="pt-3">
        <Copyright>Made with LOVE in Los Angeles | © Copyright {new Date().getFullYear()}</Copyright>
      </Row>
    </Wrapper>
  );
});

export default Footer;
