import styled from 'styled-components';

export const Label = styled.label`
  display: block;
  font-size: 15px !important;
  line-height: 19px;
  padding-top: 7px !important;
  padding-bottom: 7px;
  margin-bottom: 0;
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  position: relative;
  padding-right: 10px;
  padding-left: 40px;
  cursor: pointer;

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }

  input[type='checkbox'] {
    visibility: hidden;
    display: none;
  }

  input:checked ~ span {
    background-color: #008aff;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 5px;
    bottom: 3px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const SelectTitle = styled.div`
  background: transparent;
  padding: 8px 30px 8px 16px;
  border-radius: 3px;
  width: 100%;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: #fff;
  &.inactive {
    color: #4e505a;
    img {
      filter: invert(0.3);
    }
  }
`;

export const Select = styled.div`
  z-index: 100;
  background: #2f3137;
  color: #fff;
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
  margin-top: 2px;
  max-height: 214px;
  overflow-y: auto;
  scrollbar-color: ${({theme}) => (theme.body === '#FFF' ? '#008aff #dcdfee' : '#008aff #4e505a')};
  scrollbar-width: thin;

  .mobile-sort {
    width: 100px !important;
  }

  .desktopSort {
    width: 200px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => (theme.body === '#FFF' ? ' #dcdfee' : '#4e505a')};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #008aff;
    border-radius: 10px;
  }
`;

export const Img = styled.img`
  position: absolute;
  right: 13px;
  top: 14px;
  filter: invert(1);
`;

export const CustomCheckbox = styled.span`
  position: absolute;
  top: 8px;
  left: 10px;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 2px solid #008aff;
  top: calc(50% - 8px);

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;
