import styled from 'styled-components';

export const Img = styled.img`
  /* Adapt the colors based on primary prop */
  filter: ${({theme}) => (theme.body === '#FFF' ? 'invert(1)' : 'invert(.3)')};
`;

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-size: 15px;

  .courseCard {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(144, 67, 255, 0.2) 208.44%), rgba(24, 26, 33, 0.5);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  .courseBorder-top {
    border-top: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  }
  .courseBorder-bottom {
    border-bottom: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
    &:focus {
      outline: 0;
    }
  }
  /* Adapt the colors based on primary prop */
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
    padding-top: 54px;
  }
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
  margin-top: 5px;
  border-radius: 50px;

  @media screen and (max-width: 576px) {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    margin-top: 2px;
  }
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 30px 8px 16px;
  border-radius: 30px;
  width: 200px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => theme.text2};
  &:focus {
    outline: none;
  }
  @media screen and (min-width: 576px) and (max-width: 1200px) {
    width: 170px;
    margin-left: 5px;
  }
  @media screen and (max-width: 500px) {
    width: 175px;
  }
  @media screen and (max-width: 414px) {
    width: 158px;
  }
  @media screen and (max-width: 375px) {
    width: 158px;
  }
  @media screen and (max-width: 320px) {
    width: 130px;
  }
`;

export const Badge = styled.div`
  min-height: 26px;
  margin-bottom: 33px;
  @media screen and (max-width: 576px) {
    margin-bottom: 12px;
    min-height: 0px;
  }
  .icon-giblib {
    height: 20px;
    margin-right: 8px;
  }
  .icon-cme {
    width: 26px;
    margin-right: 8px;
    @media screen and (max-width: 576px) {
      width: 26px;
      height: 26px;
    }
  }
  .icon-vr {
    width: 30px;
    margin-right: 8px;
  }
  .credit {
    font-size: 12px;
  }
`;

export const Organization = styled.h6`
  color: ${({theme}) => theme.text2};
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.1px;
  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const ReleaseDate = styled.div`
  color: ${({theme}) => theme.text2};
  font-size: 12px;
  @media screen and (max-width: 576px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const CourseTitle = styled.div`
  margin-bottom: 16px;
  h5 {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @media screen and (max-width: 576px) {
    min-height: 25px;
    margin-bottom: 8px;
    h5 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const CaptionTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  letter-spacing: -0.8px;
  max-width: 55%;
  height: 84px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: 576px) {
    font-size: 16px;
    letter-spacing: -0.6px;
    line-height: 20px;
    height: 40px;
  }
`;

export const CarouselDescription = styled.div`
  .organization {
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 24px;
  }
  .descript {
    font-size: 16px;
    letter-spacing: -0.14px;
    line-height: 18px;
  }

  @media screen and (max-width: 576px) {
    .organization {
      font-size: 12px;
      letter-spacing: -0.15px;
      line-height: 14px;
    }
    .descript {
      font-size: 12px;
      letter-spacing: -0.1px;
      line-height: 13px;
    }
  }
`;

export const ButtonGroup = styled.div`
  width: 260px;
  white-space: nowrap;
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 300px;
  }
  @media (max-width: 576px) {
    margin-bottom: 12px;
    width: 386px;
  }

  .a_follow,
  .a_followed {
    margin-right: 15px;
  }

  .a_playlist,
  .a_bookmark {
    padding: 0;
    vertical-align: top;
    text-align: center;
    color: ${({theme}) => theme.text2};
    margin-right: 15px;
    font-size: 18px;
  }
`;

export const CourseFilter = styled.div`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : 'transparent')};

  label {
    font-size: 13px;
    padding-top: 11px;
  }

  .custom-switch label {
    padding-top: 3px;
  }

  .custom-switch {
    padding-top: 8px;
  }

  .clear-btn {
    color: ${({theme}) => theme.text2};
    margin-left: 10px;
    margin-top: 8px;
    width: 100px;
    height: 25px;
    font-size: 12px;
    line-height: 12px;
    background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
    border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
    border-radius: 0px;
    @media screen and (max-width: 576px) {
      margin-left: 0px;
      position: absolute;
      right: 0px;
    }
  }
  .clear-btn:active {
    background-color: #585a65 !important;
    border-color: #585a65 !important;
  }

  .form-group {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  @media only screen and (device-width: 768px) {
    .col-sm-4 {
      flex: 0 0 41.666667% !important;
      max-width: 41.666667% !important;
    }
  }
`;

export const DeskFilter = styled.div`
  display: none;
  @media screen and (min-width: 576px) {
    display: block;
  }
`;

export const MobFilter = styled.div`
  display: none;
  @media screen and (max-width: 576px) {
    display: block;
  }
`;
export const CarouselContainer = styled.div`
  @media screen and (min-width: 1200px) {
    padding-top: 54px;
  }
  .hero {
    @media screen and (max-width: 576px) {
      height: 211px;
    }
  }

  .carousel-caption {
    bottom: 63px;
    left: 60px;
    max-width: 55%;

    @media screen and (max-width: 768px) {
      bottom: 26px;
    }

    @media screen and (max-width: 576px) {
      left: 28px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 32px;
    height: 32px;
    border: 1px solid;
    border-radius: 50px;
    top: 65px;

    @media screen and (max-width: 768px) {
      top: 42px;
    }

    @media screen and (max-width: 576px) {
      width: 15px;
      height: 15px;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 13px;
    height: 13px;

    @media screen and (max-width: 576px) {
      width: 6px;
      height: 6px;
    }
  }

  .carousel-control-prev {
    left: 60px;

    @media screen and (max-width: 576px) {
      left: 28px;
    }
  }

  .carousel-control-next {
    left: 108px;

    @media screen and (max-width: 576px) {
      left: 52px;
    }
  }

  .a_WatchNow {
    @media screen and (max-width: 576px) {
      font-size: 10px;
      letter-spacing: normal;
      padding: 4px 8px;

      .fa-play {
        width: 11px;
        height: 11px;
        vertical-align: middle;
      }
    }
  }
`;

export const ChipText = styled.p`
  color: #0677d8;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 2px 2px 2px 8px;
  height: 28px;
  border-radius: 5px;
  background-color: #cce8ff;
`;

export const BadgeNew = styled.div`
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 12px;
  padding: 4px 6px;
  background: linear-gradient(90deg, #08d1f8 0%, #008aff 100%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-right: 8px;

  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 8px;
    letter-spacing: 0.5px;
    padding: 2px 2.5px 1.25px;
    border-radius: 2px;
    margin: auto 2.5px;
  }
`;

export const VideoImgCard = styled.div`
  position: relative;
`;

export const VideosCnt = styled.div`
  height: 20px;
  justify-content: center;
  align-items: center;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 12px;
  padding: 4px 6px;

  z-index: 1;
  position: absolute;
  top: 12px;
  left: 12px;
  background: #008aff;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

export const ThumbnailImg = styled.img`
  width: 248px;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    border-radius: 3px;
    width: 312px;
  }
  @media screen and (max-width: 320px) {
    width: 70px;
    height: 40px;
  }
`;

export const CarouselBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  @supports (backdrop-filter: blur(20px)) or (-webkit-backdrop-filter: blur(20px)) {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: linear-gradient(105.05deg, #181a21 0%, rgba(24, 26, 33, 0.9) 47.99%, rgba(24, 26, 33, 0) 100%);
  }
`;

export const CarouselThumbnail = styled.div`
  max-width: 450px;
  width: 40%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-25%, -50%);
  z-index: 1;

  @media screen and (min-width: 1200px) {
    max-width: 450px;
  }

  @media screen and (max-width: 1080px) {
    max-width: 375px;
  }

  @media screen and (max-width: 768px) {
    width: 30%;
  }

  @media screen and (max-width: 414px) {
    visibility: hidden;
  }
`;

export const Hero = styled.img`
  width: 100%;
`;
