import styled from 'styled-components';

export const Main = styled.div`
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #fffff;
    margin: 16px 0;
  }
  .text {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: ${({theme}) => theme.text1};
    margin-bottom: 16px;
  }

  .content::placeholder {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.09px;
    color: ${({theme}) => theme.input_text};
  }

  & .custom-select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) 1.15em, calc(100% - 15px) 1.15em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .content {
    background-color: #21232a !important;
    border: 1px solid #4e505a;
    color: ${({theme}) => theme.input_text} !important;
    font-size: 14px;
    line-height: -0.09px;
  }

  .control {
    width: 262px;
    height: 40px;
    margin-right: 12px;
  }

  .control-bottom {
    width: 357px;
    height: 40px;

    @media screen and (max-width: 576px) {
      max-width: 95%;
    }
  }

  .submit-btn {
    margin-top: 24px;
  }

  .success-submit-desk {
    @media screen and (max-width: 576px) {
      display: none;
    }
    display: inline-block;
    margin-left: 20px;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.1px;
  }

  .success-submit-mobile {
    @media screen and (min-width: 576px) {
      display: none;
    }
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.1px;
  }
`;

export const Top = styled.div`
  padding-left: 40px;
  padding-top: 10px;
  margin-bottom: 38px;

  @media screen and (max-width: 576px) {
    padding-left: 20px;
  }
`;

export const Bottom = styled.div`
  width: 100vw;

  .slick-arrow:hover {
    ~ .slick-dots {
      display: block !important;
    }
  }

  & .container {
    float: left;
  }

  & .slick-dots {
    display: none !important;
    bottom: 0;
    width: auto;
    top: -22px;
    right: 10px;

    @media screen and (max-width: 576px) {
      top: -19px;
    }

    & li,
    & li button:before {
      width: 4px;
      height: 4px;
    }

    & li.slick-active button:before,
    & li button:before {
      color: ${({theme}) => theme.circle};
    }
  }

  @media screen and (min-width: 576px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
