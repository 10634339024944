import {createGlobalStyle} from 'styled-components';

export const lightTheme = {
  name: 'light',
  body: '#FFF',
  text: '#4e505a',
  text1: '#4e505a',
  title: '#2e3038',
  descript: '#4e505a',
  descript1: '#2e3038',
  text2: '#4e505a',
  text3: '#4e505a',
  text4: '#4e505a',
  toggleBorder: '#FFF',
  background: '#363537',
  circle: '#000',
  card_text: '#4e505a',
  card_bg: '#f9f9f9',
  card_boxshadow: '0 8px 12px 0 rgba(0,0,0,0.2)',
  card_img: '#4e505a',
  card_active_btn: '#30a0ff',
  content_basic_bg: '#ededed',
  content_active_bg: '#dcdee1',
  content_border: '#b8bbbe',
  content_boxshadow: 'inset 1px 0 0 0 #dcdee1, inset -1px 0 0 0 #dcdee1',
  user_menu: '#f9f9f9',
  user_option: '#EDEDED',
  search_button: '#ADAEB2',
  nav_background: '#b8bbbe',
  links: '#4e505a',
  tos: '#4e505a',
  dropdown_item: '#252730',
  browse_submenu: '#dcdee1',
  video_right_panel: '#ededed',
  input_text: '#4e505a',
};
export const darkTheme = {
  name: 'dark',
  body: '#050507', // Regacy: '#181A21'
  text: '#FAFAFA',
  text1: '#dcdee1',
  title: '#f9f9f9',
  descript: '#e9e9e9',
  descript1: '#f8f8f8',
  text2: '#b8bbbe',
  text3: '#f9f9f9',
  text4: '#b8bbbe',
  toggleBorder: '#6B8096',
  background: '#999',
  circle: '#fff',
  card_text: '#ffffff',
  card_bg: '#2e3038',
  card_boxshadow: '0 4px 10px 0 rgba(0,0,0,0.7)',
  card_img: '#b8bbbe',
  card_active_btn: 'transparent',
  content_basic_bg: '#181A21',
  content_active_bg: '#4e505a',
  content_border: '#4e505a',
  content_boxshadow: 'inset 0 1px #4e505a',
  user_menu: '#2E3038',
  user_option: '#61636d',
  search_button: 'rgba(255, 255, 255, 0.1)',
  nav_background: '#4e505a',
  links: '#E9E9E9',
  tos: '#2e3038',
  dropdown_item: '#F9F9F9',
  browse_submenu: '#181A21',
  video_right_panel: '#181a21',
  input_text: '#bbbbbe',
};

export const Style = createGlobalStyle` body {
  box-sizing: border-box;
	padding: 0;
	margin: 0;
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  transition: all 0.50s linear;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .text-primary {
    color: #008aff !important;
  }
} `;
