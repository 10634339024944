import React from 'react';
import {Button} from 'react-bootstrap';
import {useStore} from '../../../store';
import {Body1, Title2} from '../../../utils/styles/typography';
import {amplitude} from '../../../utils/Amplitude';
import DarkModal from '../elements/DarkModal';

type Props = {
  onHide: () => {},
  show: boolean,
  dollar: string,
  profilePage: Boolean,
};
const AlertSkipTrialModal = ({show, dollar, onHide, profilePage}: Props) => {
  const {skipTrialSubscription} = useStore().subscriptionStore;

  const skipTrialFunc = () => {
    skipTrialSubscription();
    onHide();
    if (!profilePage) {
      const eventProperties = {
        location: 'cme message',
      };
      amplitude.getInstance().logEvent('skipped-trial', eventProperties);
    } else {
      const eventProperties = {
        location: 'account page',
      };
      amplitude.getInstance().logEvent('skipped-trial', eventProperties);
    }
  };

  return (
    <DarkModal show={show} centered>
      {/* <DarkModal.Header closeButton /> */}
      <DarkModal.Body>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="w-100">
            {profilePage ? (
              <Title2 className="mb-2">Would you like to Skip Trial?</Title2>
            ) : (
              <Title2 className="mb-2">Download not available for trial users.</Title2>
            )}
          </div>
          {profilePage ? (
            <Body1 className="mb-4">
              By clicking on Skip Trial, your account will be active and will be charged
              <span className="text-primary">&nbsp;{dollar}</span> immediately. For any questions, please contact us at
              <span className="text-primary"> support@giblib.com</span>.
            </Body1>
          ) : (
            <Body1 className="mb-4">
              To download, click Skip Trial to activate your subscription. You will be charged
              <span className="text-primary">&nbsp;{dollar}</span> immediately. For any questions, please contact us at
              <span className="text-primary"> support@giblib.com</span>.
            </Body1>
          )}
          <div className="w-100 text-right">
            <Button type="variant" variant="secondary" className="cancel-btn mr-2" onClick={onHide}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              data-testid="tbdModalClose"
              id="close-tbd-modal"
              className="a_closeTbdModal skip-btn"
              onClick={skipTrialFunc}
            >
              Skip Trial
            </Button>
          </div>
        </div>
      </DarkModal.Body>
    </DarkModal>
  );
};

export default AlertSkipTrialModal;
