import axios from 'axios';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Col, Dropdown, Form, Row} from 'react-bootstrap';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useForm} from 'react-hook-form';
import {ReactComponent as BlueCheckIcon} from '../../../assets/icons/blue.svg';
import {ReactComponent as TimeIcon} from '../../../assets/icons/time.svg';
import thumbnail from '../../../assets/images/thumbnailLogo.jpg';
import {ProgressBar} from '../../../components/CircleProgressBar';
import {useStore} from '../../../store';
import {Body2, Title1} from '../../../utils/styles/typography';
import {FormStyles} from '../elements/profile';
import DocumentsField from './documentField';
// import {PutObjectCommand, S3Client} from '@aws-sdk/client-s3';

const UploadForm = observer(() => {
  const {register, handleSubmit, reset, setValue} = useForm();

  const [file, setFile] = useState();
  const [error, setError] = useState('');
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [subSpeciality, setSubSpeciality] = useState('');
  const {videoStore, authStore} = useStore();
  const {specialtyList} = authStore;
  const {getS3urlForVideo, uploadVideoURL, submitted, setIsSubmitted, percentage, setPercentage} = videoStore;

  useEffect(() => {
    setValue('title', file?.name.split('.')[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progressEvent => {
      setPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    },
  };
  const onSubmit = value => {
    setIsSubmitted(true);
    getS3urlForVideo(file.name, 'putObject', async putSignedURL => {
      try {
        const s3 = await axios.put(putSignedURL, file, options);
        if (s3.status === 200) {
          uploadVideoURL(
            {
              s3url: s3.config.url,
              name: value.title,
              description: value.longDescription,
              speciality: selectedSpeciality,
              thumbnail,
              subSpeciality,
              user_organization: value.organization,
            },
            () => {
              reset();
              setError('');
              setFile();
              setTimeout(() => {
                setIsSubmitted(false);
                setPercentage(0);
              }, 1000);
            },
            () => {
              setIsSubmitted(false);
              setError('Video Uploading Failed');
            },
          );
        } else {
          setIsSubmitted(false);
          setError('Video Uploading Failed');
        }
      } catch (err) {
        setIsSubmitted(false);
        setError('Video Uploading Failed');
      }
    });

    // formData.append('name', value.title);
    // formData.append('description', value.longDescription);
    // formData.append('speciality', selectedSpeciality);
    // formData.append('subSpeciality', subSpeciality);
    // formData.append('clinic', value.clinic);
    // formData.append('filmedDate', filmedDate);
    // videoUpload(formData, () => {
    //   setIsSubmitted(false);
    //   reset();
    //   setFile();
    // });
  };
  return (
    <FormStyles>
      <h5>Video Details</h5>
      <DocumentsField setFile={setFile} file={file} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control
          type="text"
          name="title"
          id="title"
          placeholder="Title*"
          ref={register({required: true})}
          disabled={submitted}
        />

        <Form.Control
          as="textarea"
          type="text"
          name="longDescription"
          placeholder="Description*"
          id="longDescription"
          disabled={submitted}
          ref={register({required: true})}
          className="a_textArea"
        />
        <Row>
          <Form.Group as={Col} sm={6} controlId="account-speciality">
            <Dropdown>
              <Form.Control
                as="select"
                defaultValue=""
                name="speciality"
                placeholder="Specialty*"
                className={`bg-transparent step-field text-white ${selectedSpeciality && 'border-primary'}`}
                onChange={e => {
                  setSelectedSpeciality(e.target.value);
                }}
                ref={register({required: true})}
              >
                <option value="" desabled>
                  Specialty*
                </option>
                {specialtyList?.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.display_name}
                  </option>
                ))}
              </Form.Control>
            </Dropdown>
          </Form.Group>
          <Form.Group as={Col} sm={6} controlId="account-subSpeciality">
            <Dropdown>
              <Form.Control
                defaultValue=""
                as="select"
                placeholder="SubSpecialty"
                name="subSpeciality"
                className={`bg-transparent step-field text-white ${subSpeciality && 'border-primary'}`}
                onChange={e => setSubSpeciality(e.target.value)}
              >
                <option value="" desabled>
                  Subspecialty
                </option>
                {specialtyList
                  ?.find(el => el.id === Number(selectedSpeciality))
                  ?.children?.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.display_name}
                    </option>
                  ))}
              </Form.Control>
            </Dropdown>
          </Form.Group>
        </Row>
        <Form.Control
          type="text"
          name="organization"
          id="organization"
          placeholder="Organization"
          ref={register({required: false})}
          disabled={submitted}
          autoComplete="off"
        />
        <div className="mt-3 terms-span">
          By submitting your videos to GIBLIB, you acknowledge that you agree to GIBLIB&lsquo;s{' '}
          <a target="_blank" rel="noreferrer" href="https://www.giblib.com/support/terms-of-service">
            Terms of Services
          </a>
          . Please note that the video will be moderated prior publishing by our admins.
        </div>

        <div className="d-flex justify-content-end mt-3 buttinsDiv">
          {error && <div className="text-danger">{error}</div>}
          <button className="browseButton" type="submit">
            Upload Video
          </button>
        </div>
      </form>
      {submitted && (
        <div className="progressBarContainer d-flex justify-content-center align-items-center ">
          <ProgressBar style={{width: '150px', height: '150px'}}>
            <CircularProgressbarWithChildren
              value={percentage}
              styles={{
                path: {
                  stroke: 'rgba(0, 138, 255, 1)',
                },
              }}
            >
              {percentage === 100 ? <BlueCheckIcon /> : <TimeIcon />}
              <div>
                <Title1 className={`${percentage === '100' && 'text-primary'} `}>{percentage}%</Title1>
              </div>
              <div>
                <Body2>Completed</Body2>
              </div>
            </CircularProgressbarWithChildren>
          </ProgressBar>
        </div>
      )}
    </FormStyles>
  );
});

export default UploadForm;
