import React from 'react';
import {observer} from 'mobx-react-lite';
import {Container, Form, FormCheck, Button, Dropdown as DropdownBoot} from 'react-bootstrap';
import Dropdown from '../elements/Multiselect';
import {useStore} from '../../../store';
import {Filter, PaginationContainer} from '../elements/catalog';

type Props = {
  professionFilter: Array,
  specialityFilter: Array,
  organizationFilter: Array,
};
// Filters
const Filters = observer(({professionFilter, specialityFilter, organizationFilter}: Props) => {
  const {conferenceStore} = useStore();
  const {
    selectedProfessions,
    selectedSpecialties,
    selectedOrganizations,
    setSelectedProfessions,
    setSelectedSpecialities,
    setSelectedOrganizations,
    setCME,
    listConferences,
    isCme,
  } = conferenceStore;

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedProfessions(selectedIDs, false);
    } else if (type === 2) {
      setSelectedSpecialities(selectedIDs, false);
    } else {
      setSelectedOrganizations(selectedIDs, false);
    }
  };

  const loadConference = () => {
    listConferences(true);
  };

  return (
    <Container>
      <Filter>
        <Form>
          <Form.Row className="d-inline-block w-100 m-0">
            <Form.Group controlId="filterProfession">
              <PaginationContainer className="professionDrop">
                <DropdownBoot>
                  <DropdownBoot.Toggle variant="basic" id="profession" className="a_profession">
                    Profession
                  </DropdownBoot.Toggle>

                  <DropdownBoot.Menu onChange={e => setSelectedProfessions([e.target.value], false)}>
                    {professionFilter?.map((item, i) => (
                      <Form.Check
                        type="radio"
                        label={item.name}
                        id={`${item.id.toString()}_${i.toString()}`}
                        value={item.id}
                        name="profession"
                        checked={selectedProfessions?.includes(item.id.toString())}
                      />
                    ))}
                  </DropdownBoot.Menu>
                </DropdownBoot>
              </PaginationContainer>
            </Form.Group>
          </Form.Row>
          <Form.Row className="d-inline-block w-100 m-0">
            <Form.Group controlId="filterSpecialty">
              <div>
                <Dropdown
                  title="Specialty"
                  values={specialityFilter}
                  selectedIds={selectedSpecialties}
                  id="filterSpecialty"
                  onSelectOptions={selectedIDs => selectOptions(selectedIDs, 2)}
                />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row className="d-inline-block w-100 m-0">
            <Form.Group controlId="filterOrganization">
              <div>
                <Dropdown
                  title="Organization"
                  values={organizationFilter}
                  selectedIds={selectedOrganizations}
                  id="filterOrganization"
                  onSelectOptions={selectedIDs => selectOptions(selectedIDs, 4)}
                />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row className="switchFilter d-inline-block w-100 mx-0">
            <Form.Group controlId="cme">
              <div className="d-flex justify-content-between">
                <Form.Label className="mr-2">CME</Form.Label>
                <FormCheck id="cme" type="switch" onChange={e => setCME(e.target.checked, false)} checked={isCme} />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Button className="a_filter-btn" onClick={() => loadConference()}>
              Done
            </Button>
          </Form.Row>
        </Form>
      </Filter>
    </Container>
  );
});

export default Filters;
