import {action, makeObservable, observable} from 'mobx';
import {followItem, unfollowItem} from '../../api/course';
import getSpecialty from '../../api/specialty';
import type {Specialty} from '../../utils/types';
import ErrorStore from '../error/errorStore';

class SpecialtyStore {
  errorStore: ErrorStore;

  specialty: Specialty = {};

  specialtyLoading: boolean = false;

  parentSpecialty: String = null;

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeObservable(this, {
      specialty: observable,
      specialtyLoading: observable,
      updateSpecialtyFollow: action,
      followSpecialty: action,
      unFollowSpecialty: action,
      setBrowseSpecialty: action,
      getBrowseSpecialty: action,
    });
  }

  setParentSpecialty = value => {
    this.parentSpecialty = value;
  };

  updateSpecialtyFollow = (follow: boolean) => {
    this.specialty.is_following = follow;
  };

  updateSpecialtiesFollow = (follow: boolean) => {
    this.specialties.is_following = follow;
  };

  /** Follow specialty */
  followSpecialty = async (id: number) => {
    try {
      const resp = await followItem(id, 'specialty');
      const {status} = resp.data;
      if (status) {
        this.updateSpecialtyFollow(true);
        this.updateSpecialtiesFollow(true);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow specialty */
  unFollowSpecialty = async (id: number) => {
    try {
      const resp = await unfollowItem(id, 'specialty');
      const {status} = resp.data;
      if (status) {
        this.updateSpecialtyFollow(false);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setBrowseSpecialty = (specialty: Specialty) => {
    this.specialtyLoading = false;
    this.specialty = specialty;
  };

  getBrowseSpecialty = async id => {
    this.specialtyLoading = true;
    try {
      const res = await getSpecialty(id);
      if (res.data?.status) {
        const specialty = res.data?.specialty;
        this.setBrowseSpecialty(specialty);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default SpecialtyStore;
