import {makeAutoObservable, runInAction} from 'mobx';
import ErrorStore from '../error/errorStore';

export default class RegistrationUIStore {
  errorStore: ErrorStore;

  isCampaignSelected = false;

  _currentCampaign;

  currentCampaignCode;

  campaignStore;

  authStore;

  constructor({campaignStore, authStore, errorStore}) {
    this.errorStore = errorStore;
    this.campaignStore = campaignStore;
    this.authStore = authStore;
    makeAutoObservable(this);
  }

  async setCurrentCampaign(code) {
    this.currentCampaignCode = code;
    this.authStore.registrationForm.campaignCode = code;
    try {
      const campaign = await this.campaignStore.getCampaignByCode(code);
      if (campaign) {
        runInAction(() => {
          this.isCampaignSelected = true;
          this._currentCampaign = campaign;
        });
      }
    } catch (error) {}
  }

  get currentCampaign() {
    return this.isCampaignSelected ? this._currentCampaign : null;
  }
}
