import styled from 'styled-components';
import {Modal, ButtonGroup, Form, Button} from 'react-bootstrap';
import dropIcon from '../../assets/icons/sort-down-solid.svg';

export const RegisterContainer = styled.div`
  background-color: #050507;
  min-height: 100vh !important;
  width: 100vw;
  margin: auto;
  font-family: Roboto;

  & .nav {
    background-color: #181a21;
    padding: 13px 24px;
    width: 100vw;
  }

  .nav-div {
    padding: 8px 8px;
    width: 1330px;

    .nav-link {
      padding: 0px;
    }

    .svg-inline--fa {
      margin-right: 10px;
    }
    a.nav-link {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#FFF')};
    }

    @media screen and (max-width: 1360px) {
      width: 60%;
    }
    @media screen and (max-width: 576px) {
      width: 98%;
    }
  }
  .numberStepContainer {
    flex-direction: column;
  }
`;

export const AccessButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  margin-right: 12px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  width: 190px;
  height: 38px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
    color: #ffffff;
  }
`;

export const LoginButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  width: 100px;
  height: 38px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
    color: #ffffff;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  & .mob {
    display: none;

    @media screen and (max-width: 576px) {
      display: flex;
    }
  }

  & .desk {
    display: flex;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  .small-card {
    min-height: 80px !important;
    & .card-body {
      padding-right: 12px !important;
    }
    & .ready-card {
      opacity: 0.6;
    }
  }

  & .btn-primary, .bg-primary {
    background-color: #008aff !important;
  }
  & .border-primary {
    border-color: #008aff !important;
  }

  & .btn-secondary, .bg-secondary {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  & .border-secondary {
    border-color: rgba(255, 255, 255, 0.1) !important;
  }

  & .text-success {
    color: #0dfff3 !important;
  }
  & .text-danger{
    font-size:15px;
    font-weight:600;
  }

  & .opacity-3 {
    opacity: 0.75;
  }

  & .fa-google {
    margin-right: 10px;
    vertical-align: middle;
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: white url(${dropIcon}) no-repeat right 8px top 3px;
  }

  .form-control.is-invalid {
    -webkit-appearance: none;
    background: white url(${dropIcon}) no-repeat right 8px top 3px;
  }

  .form-control:focus {
    box-shadow: none;
  }

  & .card {
    @media screen and (max-width: 700px) {
      width: 95%;
    }
    width: 650px;
    min-height: 371px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background-color: #181a21;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;

    & .card-body {
      padding: 44px;
      width: 100%;
    }

    & .form-label {
      font-size: 12px;
    }
    & .form-control {
      font-size: 14px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      background-color: transparent;
    }
    & .dropdown-toggle {
      width:100%;
      display:flex;
      align-items:center;
      justify-content:space-between;
      font-size: 14px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      background-color: transparent;
      color: white;
    }
    option {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 100%;
    max-height:300px;
    overflow:auto;
    text-align: left;
    color: #fff !important;
    line-height: 16px;
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
    }
  }
  & .dropdown-item {
      background-color: #2f3137;
      color: white;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      border: 1px solid #2f3137;
      padding: 3px 0;
      :hover {
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid #008aff;
      }
    }
    & .step-field {
      padding: 0px 16px;
      border-radius: 3px;
      color: #ffffff;
      option {
        color: black;
      }
    }
    & .invalid-feedback {
      font-size: 10px;
    }

    & .fa-hand-pointer {
      font-size: 32px;
      line-height: 32px;
      color: #fff;
      font-weight: 300;
    }
  }

  & .access-code-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;

    & .access-link {
      background: #009994;
    }
  }

  & .card-specialty {
    min-height: 144px;

    @media screen and (min-width: 576px) {
      float: left;
    }
  }

  & .card-specialty2 {
    min-height: 294px;
    float: right;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  & .specialty-btns-div {
    position: relative;
    padding: 24px 0 16px;

    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 0 24px 24px;
    }
  }

  & .subspecialty-div {
    overflow-y: scroll;
    max-height: 390px;
    scrollbar-color: #008aff;
    scrollbar-width: thin;

    @media screen and (max-width: 576px) {
      overflow: unset;
      max-height: none;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #4e505a
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #008aff;
      border-radius: 10px;
    }
  }

  & .subspecialty-btn {
    & .btn {
      padding: 4px 8px;
      border-color: transparent;
      border-radius: 3px;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.5px;
      font-weight: bold;
      margin-right: 8px;
      margin-bottom: 8px;
    }
    & .btn-secondary {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  & .choose-plan {
    width: auto;
    background-color: transparent;
    border: none;

    & .card {
      min-height: 1px;
    }

    & .choose-plan-body {
      display: flex;
      flex-direction: row;
  
      @media screen and (max-width: 576px) {
        flex-direction: column;
        padding: 0;
      }
    }
  }

  & .card-header,
  & .card-footer {
    background-color: transparent;
    border: none;
    text-align: center;
  }

  & .plans {
    margin-right: 10px;
    background-color: transparent;
    border: none;

    & .row {
      margin: 0;
    }
    & .btn-group {
      .btn {
        padding: 5px 15px;
        border: none;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        white-space: nowrap;
        border-radius: 3px;
      }
      .togglebtn-active {
        background-color: #008aff;
        border-color: #008aff;
      }
    }
    & .price {
      border-bottom: 1px solid #fff;
      padding-bottom: 8px;
      margin-bottom: 8px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media screen and (max-width: 576px) {
        padding-bottom: 0;
      }
    }
    & .dollar {
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      color: #008AFF;
    }
    & .premium-dollar {
      color: #0dfff3;
    }
    & .stick {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #e04463;
      text-decoration: line-through;
    }
    & .plan {
      @media screen and (max-width: 336px) {
        width: 95%;
      }
      width: 336px;
      height: 107px;
      margin-bottom: 10px;
      color: white;
      font-family: Roboto;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .fa-icon-cme-filled-green {
        color: #0DFFF3;
        margin-right: 4px;
      }

      .card-body {
        padding: 16px;
      }

      .card-body:first-child {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 576px) {
          padding: 1rem;
        }
      }
    }
    .card-body:last-child {
      & div:first-child:not(.price, .dollar) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      & .sub-text:not(.mo) {
        padding-top: 9px;
      }
    }
    .select-plan {
      border: 2px solid #008aff;
      opacity: 1;
    }
    .select-premium-plan {
      border: 2px solid #0DFFF3;
      opacity: 1;
      .btn-primary {
        background-color: #0DFFF3 !important;
        color: #181A21;
      }
    }
    .card:not(.select-plan, .select-premium-plan) {
      opacity: 0.5;
    }
    .line {
      height: 70%;
      border-left: 1px solid #fff;
    }

    // mobiile ver
    @media screen and (max-width: 576px) {
      width: auto;
      flex-direction: column;
      margin-right: 0;

      & .plan {
        height: 88px;
      }
    }
  }

  & .plan-descript {
    width: 336px;
    height: 372px;
    margin-left: 10px;
    padding: 24px 20px;

    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #ffffff;
      background-color: transparent;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 15px;

      .card-text {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0;
        color: #35cb95;
      }
    }
    .card-body {
      font-weight: 700;
      font-size: 15px;
      padding: 0;
    }
    .check-box {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 13px;
    }

    // mobiile ver
    @media screen and (max-width: 576px) {
      width: auto;
      margin-left: 0;
    }
  }

  & .card-descript {
    text-align: left;
    margin-bottom: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    .text-line {
      text-decoration-line: line-through;
      color: rgba(255, 255, 255, 0.4);
    }
    .cme-false {
      color: #FF5964;
      font-weight: bold;
    }
    .true {
      color: white;
    }
    .false {
      color: transparent;
    }
  }

  & .card-descript-cme {
    color: #0dfff3;
    font-weight: bold;

    & .fa-check {
      margin-right: 10px;
      color: #0dfff3;
    }

    & .fa-times {
      font-size: 14px;
      margin-right: 10px;
    }
  }

  & .payment-warning {
    font-weight: 400;
    padding: 6px;
    font-size: 12px;
    line-height: 16px;
  }

  & .card-payment {
    // float: right;
    min-height: auto;

    @media screen and (max-width: 576px) {
      width: 100%;
    }

    & .card-body {
      padding: 24px 20px;
    }

    & .StripeElement--invalid {
      border: 1px solid #dc3545 !important;
    }
  }

  & .card-payment-summary {
    float: left;

    @media screen and (max-width: 576px) {
      margin-top: 16px;
      width: 100%;
    }

    & .card-body:first-child {
      padding: 24px 20px;
    }

    & table {
      font-weight: 700 !important;
      margin-bottom: 0;
      td {
        vertical-align: middle;
      }
    }
    & tr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
    & td,
    & th {
      padding: 0;
    }
    & tfoot {
      & td, & th {
        padding: 16px 0;
      }
    }
    & .payment-btn-div {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .button-line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 40px 0;
  }

  & .institution-card {
    & .form-control {
      color: white;
    }
    & .institution-input {
      & .form-control {
        border: 1px solid #008aff;
      }
    }
  }

  & .a_SkipButton {
    z-index: 1;
  }

  & .a_terms-privacy a {
    color: #fff;
    text-decoration: underline;
  }
 
`;

export const RegisterDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 24px 24px 40px;
  width: 100%;
`;

export const ShippingModal = styled(Modal)`
  top: 20%;
  .modal-dialog {
    width: 415px;
    border-radius: 8px;
    background-color: #181a21;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    padding: 12px 32px;
    @media screen and (max-width: 576px) {
      width: 96%;
      padding: 12px;
    }
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#181a21')};
    border-radius: 8px;

    .modal-header {
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      color: #fff;
      border: none;
    }
    .modal-body {
      padding-top: 0;
      .form-label {
        font-size: 12px;
      }
      & span {
        font-size: 14px;
        line-height: 17px;
        font-weight: 300;
        color: #dcdee1;
      }
      .coupon-code {
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-sizing: border-box;
        border-radius: 3px;
      }
      .btn-secondary {
        background-color: #252730;
        border: transparent;
      }
    }
    .modal-footer {
      border: none !important;
    }
    .close {
      color: ${({theme}) => theme.circle};
    }
  }
`;

export const NumberStep = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 10px;
flex-shrink: 0;
  height: 24px;
  width: 24px;

  background: ${props => (props.status ? '#008aff;' : 'none;')}
  border: ${props => (props.status ? 'none;' : '2px solid rgba(255, 255, 255, 0.4);')}
  color: ${props => (props.status ? '#ffffff;' : 'rgba(255, 255, 255, 0.4);')}

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 53px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  text-align: center;
`;

export const StepperLine = styled.hr`
  height: 4px;
  width: 46%;
  left: 0px;
  top: 0px;
  border-radius: 13px;

  margin-left: 4px;
  margin-right: 4px;

  background: ${props => (props.status ? '#008AFF;' : 'rgba(255, 255, 255, 0.4);')}
  border-radius: 13px;
  @media screen and (max-width: 576px) {
    width:74%;
   }
  @media screen and (min-width: 576px) and (max-width: 960px) {
    width: 60%;
  }
  @media screen and (min-width: 1360px) {
    width:74%;
   }
  
  
`;

// PlanSelection.js
export const TitleNumberDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  margin-right: 12px;

  width: 32px;
  height: 32px;

  background: #008aff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 53px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const TitleText = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  text-align: center;

  color: #ffffff;

  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
`;

export const VerticalDivider = styled.hr`
  width: 288px;
  height: 0px;
  border: 1px solid #46484d;
  flex: none;
  order: 1;
  margin-top: 12px;
  margin-bottom: 24px;
`;

export const HorizontalDivider = styled.hr`
  width: 30px;
  margin-top: 12px;
  margin-bottom: 12px;
  background: #46484d;
  border: 1px solid #46484d;
  transform: rotate(90deg);
`;

export const SummaryDesc = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;
`;

export const AccountDesc = styled.span`
  width: 65px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SectionTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #a3a3a9;
`;

export const PlanButtonGroup = styled(ButtonGroup)`
display:grid;
grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1rem;

  & .plan.premium-giftcard-product {
    text-align: left !important;
    padding: 8px 12px;
    letter-spacing: 3px;
    height: 74px;
    
    color: black !important;
    background: #0dfff3 !important;
    border: none;
    border-radius: 3px;
  }
  
  & .plan.premium-product {
    text-align: left !important;
    padding: 8px 12px;
    letter-spacing: 3px;
    display: grid;
    place-content: center;
    height: 74px;
    color: #FFFFFF !important;
    background: #2F3137 !important;
  }
  & .plan.free-product{
    text-align: left !important;
    padding: 8px 12px;
    letter-spacing: 3px;
    display: grid;
    place-content: center;
    height: 74px;

    color: #FFFFFF !important;
    background: #008AFF !important;
    border: ${props => (props.clicked ? '3px solid #0DFFF3;' : 'none;')}
    border-radius: 3px;
  }

  & .plan.standard-product, & .plan.basic-product ,& .plan.free-product{
    text-align: left !important;
    padding: 8px 12px;
    letter-spacing: 3px;
    height: 74px;
    color: #FFFFFF !important;
    background: #2F3137 !important;
    display: grid;
    place-content: center;
    border: ${props => (props.clicked ? '3px solid #008AFF' : 'none;')}
    border-radius: 3px;
  }

  
  label.focus.plan.premium-giftcard-product {
    border: 3px solid #008AFF;
  }
  
  label.focus.plan.premium-product {
    border: 3px solid #0DFFF3;
  }
  
  label.focus.plan.standard-product, label.focus.plan.basic-product,label.focus.plan.free-product {
    border: 3px solid #008AFF;
  }

  
`;

export const PlanButtonText = styled.div`
  & .plan_name {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  & .cme_desc {
    margin-top: 7px;
    margin-bottom: 8px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const DurationButtonGroup = styled(ButtonGroup)`
  display: flex;

  .duration {
    margin-right: 4px;
    color: #ffffff;

    justify-content: center;
    align-items: center;

    padding: 8px;
    gap: 8px;

    width: 93.33px;
    height: 94px;
    background: #46484d !important;

    border: none;
    border-radius: 3px;

    .duration_text {
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
    }

    .duration_year {
      margin: 0px;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }

    .duration_price {
      height: 22px;
      margin-top: 4px;
      letter-spacing: 0.25px;
      font-weight: 700;
      font-size: 20px;
    }

    .duration_giftCard {
      margin: 0px;
      color: #0dfff3;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
  }
  label.durationFocused {
    border: 3px solid #008aff;
  }
`;

export const FormCheckDiv = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;

  label.label-elm {
    margin-top: 12px;
    padding: 17px 16px;
    display: flex;
    cursor: pointer;
  }
  label.label-elm:hover {
    background: #46484d;
    border: 1px solid #008aff;
    border-radius: 3px;
  }

  #amazon-gc:checked ~ label.first,
  #visa-gc:checked ~ label.second {
    background: #46484d;
    border: 1px solid #008aff;
    border-radius: 3px;
  }

  label.label-elm .circle {
    height: 16px;
    width: 16px;
    background: #ccc;
    border: 5px solid transparent;
    display: inline-block;
    margin-right: 15px;
    border-radius: 50%;
    transition: all 0.25s ease;
    box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.2);
  }
  #amazon-gc:checked ~ label.first .circle,
  #visa-gc:checked ~ label.second .circle {
    border-color: #008aff;
    background: #fff;
  }
  label.label-elm .label-div {
    display: flex;
    width: 100%;
    align-items: center;
  }
  input[type='radio'] {
    display: none;
  }
`;

export const GiftCardExp = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
