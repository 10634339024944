import styled from 'styled-components';

export const PopoverDiv = styled.div`
  & .card {
    height: 365px;
    width: 248px;
    border: none !important;
    cursor: pointer;
    box-shadow: ${({theme}) =>
      theme.name === 'light' ? '0 8px 12px 0 rgba(0,0,0,0.2)' : '0 4px 10px 0 rgba(0,0,0,0.7)'};
    background-color: ${({theme}) => (theme.name === 'light' ? '#f9f9f9' : '#2e3038')};
  }
`;

export const Title = styled.div`
  font-weight: bold;
  color: ${({theme}) => (theme.name === 'light' ? '#2e3038' : '#f9f9f9')};
  letter-spacing: -0.2px;
  margin-top: 15px;
  font-size: 16px;
  line-height: 20px;
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: ${({theme}) => (theme.name === 'light' ? '#2e3038' : '#f8f8f8')};
  letter-spacing: -0.13px;
  margin: 10px 0;
`;

export default {PopoverDiv, Title, Text};
