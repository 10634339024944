import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {Form, Button} from 'react-bootstrap';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import {TimeStamp, CardWrap, DeleteModal} from './style';
import {useStore} from '../../../../store';

const PastNotes = observer(({id, note, videoTimeCode, createdAt}) => {
  const {videoStore} = useStore();
  const {videoPlayer, editNote, deleteNote} = videoStore;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditTextArea, setShowEditTextArea] = useState(false);
  const [inputNote, setInputNote] = useState(note);
  const [disableSave, setDisableSave] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteModalShow = e => {
    e.stopPropagation();
    if (!e.keyCode) {
      setShowDeleteModal(true);
    }
  };

  const time = videoTimeCode.split(':');
  const seconds = +time[0] * 60 * 60 + +time[1] * 60 + +time[2];
  let timeCode = videoTimeCode;
  if (seconds < 3600) {
    timeCode = videoTimeCode.substring(3);
  }

  const clickVideoNote = e => {
    if (!showEditTextArea && !showDeleteModal && !e.keyCode) {
      videoPlayer?.play();
      videoPlayer?.currentTime(seconds);
    }
  };

  const handleEditTextAreaClose = () => {
    setInputNote(note);
    setShowEditTextArea(false);
  };

  const handleEditTextAreaShow = e => {
    e.stopPropagation();
    setInputNote(note);
    setShowEditTextArea(true);
  };

  const onSaveEditedNote = e => {
    e.stopPropagation();
    setDisableSave(true);
    editNote(id, inputNote);
    setDisableSave(false);
    handleEditTextAreaClose();
  };

  const changeInput = event => {
    event.preventDefault();
    setInputNote(event.target.value);
  };

  const confirmDelete = () => {
    setDisableDelete(true);
    deleteNote(id);
    setDisableDelete(false);
    handleDeleteModalClose();
  };

  return (
    <>
      <CardWrap role="button" tabIndex="0" className="" onClick={clickVideoNote} onKeyDown={clickVideoNote}>
        <div>
          <TimeStamp>
            <i className="far fa-video" /> {timeCode}
          </TimeStamp>
          <TimeStamp>
            <i className="far fa-clock" /> <Moment fromNow>{createdAt}</Moment>
          </TimeStamp>
        </div>

        {showEditTextArea ? (
          <Form>
            <Form.Group controlId="note">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Add a note"
                value={inputNote}
                onChange={changeInput}
                className={classNames('mb-0', {bgTextarea: inputNote})}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                id="close-upgrade-info"
                className="a_cancelEditButton mr-2"
                onClick={e => handleEditTextAreaClose(e)}
              >
                Cancel
              </Button>
              <Button
                variant={inputNote ? 'primary' : 'secondary'}
                type="button"
                disabled={!inputNote || disableSave}
                className={classNames('py-0', {
                  a_saveNoteDisabled: !inputNote,
                })}
                onClick={e => onSaveEditedNote(e)}
              >
                Save
              </Button>
            </div>
          </Form>
        ) : (
          <>
            <p className="a_note">{note}</p>
            <div className="d-flex a_actionDiv">
              <div
                role="button"
                tabIndex="0"
                className="a_editNoteButton"
                onClick={e => handleEditTextAreaShow(e)}
                onKeyDown={e => handleEditTextAreaShow(e)}
              >
                <p className="mb-0">Edit</p>
              </div>
              <p className="mx-1 mb-0">|</p>
              <div
                role="button"
                tabIndex="0"
                className="a_deleteNoteButton"
                onClick={e => handleDeleteModalShow(e)}
                onKeyDown={e => handleDeleteModalShow(e)}
              >
                <p className="mb-0">Delete</p>
              </div>
            </div>
          </>
        )}
      </CardWrap>

      {/* Delete Note Modal */}
      <DeleteModal show={showDeleteModal} onHide={handleDeleteModalClose}>
        <DeleteModal.Header closeButton>Delete Note</DeleteModal.Header>
        <DeleteModal.Body>
          <div>
            <p className="a_DeleteModalContent">Are you sure you want to delete the note ?</p>
          </div>
          <div className="w-100 d-flex flex-row-reverse">
            <Button
              type="button"
              variant="primary"
              id="close-upgrade-info"
              disabled={disableDelete}
              className="a_deleteNoteButton"
              onClick={confirmDelete}
            >
              Delete
            </Button>
            <Button
              variant="secondary"
              id="close-upgrade-info"
              className="a_closeDeleteModalButton mr-2"
              onClick={handleDeleteModalClose}
            >
              Cancel
            </Button>
          </div>
        </DeleteModal.Body>
      </DeleteModal>
    </>
  );
});

PastNotes.propTypes = {
  id: PropTypes.number.isRequired,
  note: PropTypes.string.isRequired,
  videoTimeCode: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default PastNotes;
