import axios from './axios';

/**
 * calls the search GET api to get search results
 */

const getSearchResults = (
  text,
  types,
  cmeValue,
  sortBy,
  offset,
  count,
  suggested,
  content_subtype,
  videoType,
  specialty_id,
  organization_id,
) =>
  axios.get(`/search`, {
    params: {
      text,
      types,
      cmeValue,
      sortBy,
      offset,
      count,
      suggested,
      content_subtype,
      video_type: videoType ? ['360vr'] : null,
      specialty_id,
      organization_id,
    },
  });

export default getSearchResults;
