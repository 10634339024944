// @flow
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Nav} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import stringToURLSlug from '../../../utils/helper';
import {useStore} from '../../../store';

type Props = {
  specialty: {
    id: number,
    display_name: string,
  },
  topics: {
    id: number,
    title: string,
    carouselType: {
      text: string,
    },
  },
  children: {
    id: number,
  },
  specialty: {
    id: number,
    display_name: string,
  },
  subSpecialty: Boolean,
};

const SpecialtiesList = observer(({specialty, topics, children, subSpecialty}: Props) => {
  const location = useHistory();
  const topicTitle = topics?.title || topics?.carouselType.text || '';
  const {sidebarUIStore, specialtyStore, videoStore} = useStore();
  const {setParentSpecialty} = specialtyStore;
  const {setSpecialtyPage} = videoStore;

  const specialtyPage = id => {
    if (subSpecialty) {
      setSpecialtyPage(specialty.display_name);
      const slug = stringToURLSlug(specialty.display_name);
      location.push(`/specialty/${id}/${slug}`);
      sidebarUIStore.setShow(false);
      if (specialty?.level === 'specialty') setParentSpecialty(null);
    } else if (!subSpecialty) {
      if (specialty?.level === 'specialty') setParentSpecialty(specialty.display_name);
    }
  };
  const TopicPage = id => {
    if (topics) location.push(`/all/${id}`);
  };

  return (
    <div>
      {specialty ? (
        <div className="a_SpecialtyBrowseList">
          <Nav.Link
            className="a_SpecialtyBrowseItem"
            onClick={() => specialtyPage(specialty.id)}
            onKeyDown={() => specialtyPage(specialty.id)}
          >
            {specialty.display_name}
          </Nav.Link>
        </div>
      ) : (
        topics && (
          <div className="a_TopicBrowseList">
            <Nav.Link
              className="a_TopicBrowseItem"
              onClick={() => TopicPage(topics.id)}
              onKeyDown={() => TopicPage(topics.id)}
            >
              {topicTitle?.includes('Hot Topic : ' || 'Hot Topic: ')
                ? topicTitle.slice(12, topicTitle.length)
                : topicTitle}
            </Nav.Link>
          </div>
        )
      )}
      {children}
    </div>
  );
});

export default SpecialtiesList;
