import styled from 'styled-components';
import darkTheme from '../../utils/styles/darkTheme';
import {Body2} from '../../utils/styles/typography';

export const Main = styled.main`
  background: ${darkTheme.background};
  color: ${({theme}) => theme.text};
  /* Adapt the colors based on primary prop */
`;

export const MobSideBar = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 14px;
  z-index: 2000;
  margin-top: -46px;
  & .a_collapseIcon {
    & .fa-bars {
      color: #ffffff;
    }
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const SpecialtyBrowse = styled.div`
  color: ${({theme}) => theme.dropdown_item};
  opacity: 0;
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  min-height: calc(100vh - 55px);
  box-shadow: -1px 0px 0px #383838;
  margin-left: ${props => (props.level ? 318 : 176)}px !important;
  top: 0px;
  width: calc(100vw - 735px) !important;
  border-radius: 0 !important;
  z-index: 999999;
  background: ${({theme}) => theme.browse_submenu};
  display: block;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  border-left: 1px solid ${({theme}) => theme.content_basic_bg};
`;

export const Content = styled.div`
  color: ${({theme}) => theme.dropdown_item};
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-height: calc(100vh - 55px);
  box-shadow: -1px 0px 0px #383838;
  margin-left: ${props => (props.level ? 318 : 176)}px !important;
  top: 0px;
  width: ${props => (props.level ? 390 : 320)}px !important;
  border-radius: 0 !important;
  z-index: 999999;
  background: ${({theme}) => theme.browse_submenu};
  display: block;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  border-left: 1px solid ${({theme}) => theme.content_basic_bg};
`;

export const SubspecialtyDiv = styled.div`
  z-index: 300;
  margin-top: 43px;
  margin-left: -321px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  position: absolute;
  width: 100vw;
  height: 100vh;

  /* highlights/darken-high */
  background: rgba(24, 26, 33, 0.85);

  /* blur-background */
  backdrop-filter: blur(20px);
  a {
    color: ${({theme}) => theme.dropdown_item};
  }
  a:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  #Specialties {
    display: flex;
    flex-flow: column wrap;
    height: 70vh;
    margin-left: 25px;
  }
`;
export const Browse = styled.div`
  z-index: 300;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  position: absolute;
  width: 320px !important;
  height: 100vh;
  /* highlights/darken-high */
  background: rgba(24, 26, 33, 0.85);
  /* blur-background */
  backdrop-filter: blur(20px);
  a {
    color: ${({theme}) => theme.dropdown_item};
  }
  a:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const BrowseDiv = styled.div`
  overflow: hidden;
  background: ${({theme}) => theme.browse_submenu};
  color: ${({theme}) => theme.dropdown_item};
  height: 100vh;
  overflow-y: auto;
  z-index: 99999;
  width: 161px;
  margin-top: 48px;
  border-left: 1px solid ${({theme}) => theme.content_basic_bg};
  a {
    color: ${({theme}) => theme.dropdown_item};
  }
  a & hover {
    color: ${({theme}) => theme.text3};
    background: ${({theme}) => theme.nav_background};
  }
  & hover {
    color: ${({theme}) => theme.text3};
    background: ${({theme}) => theme.nav_background};
  }
  .active {
    background: ${({theme}) => (theme.body === '#FFF' ? '#B8BBBE' : '#4e505a')};
    .svg-inline--fa {
      color: #0677d8;
    }
  }
  .nav-item {
    cursor: pointer;
  }
  .nav-item.active,
  .nav-item:hover,
  .nav-item:active {
    color: ${({theme}) => theme.text3};
    background: ${({theme}) => theme.nav_background};
  }
  .browseItem {
    height: 40px;
    vertical-align: middle;
    line-height: 40px;
    width: 160px;
    margin: 0em;
    padding: 0em 1em;
  }
  .browseItem .active,
  .browseItem:hover {
    color: ${({theme}) => theme.text3};
    background: ${({theme}) => theme.nav_background};
  }
  .fa-chevron-right {
    position: absolute;
    right: 10px;
    transform: translateY(12px);
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 2em;
    vertical-align: 0em;
    content: '';
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .custom-dropdown:hover {
    > div > ${Content}, > ${SpecialtyBrowse} {
      padding-top: 1rem;
      opacity: 1;
      visibility: visible;
      z-index: 1;
      height: calc(100% - 55px);
      .nav-link {
        padding: 0.3rem 1rem;
      }
    }
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const DeskSideBar = styled.div`
  display: none;
  @media screen and (min-width: 1201px) {
    z-index: 9999;
    display: block;
    position: sticky;
  }
  #sidebar_catalog {
    margin-top: 44px;
  }
  #sidebar_upload {
    margin-top: 44px;
  }
  .bottom-sidebar-btn {
    vertical-align: initial;
    line-height: 1;
  }

  .navbar-nav .nav-link {
    .myVideo {
      margin-left: 12px;
      width: 22px;
      height: 20px;
      filter: brightness(50%) invert(1);
    }
    .myVideoText {
      margin-left: 13px;
    }
  }
`;

export const TabletSidebar = styled.div`
  display: none;
  @media (min-width: 768px) and (max-width: 1200px) {
    z-index: 9999;
    display: block;
    position: fixed;
  }
  #sidebar_catalog {
    margin-top: 44px;
  }
  #sidebar_upload {
    margin-top: 44px;
  }
`;

export const LinkText = styled(Body2)`
  line-height: 15px;
  margin: 8px 0px;
`;

export const Side = styled.div`
  background: ${darkTheme.surface};
  color: ${({theme}) => theme.text3};
  height: 100vh;
  position: sticky;
  top: 0px;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  box-shadow: -1px 0px 0px #383838;
  .custom-dropdown:hover {
    > div > ${Content}, > ${SpecialtyBrowse} {
      padding-top: 1rem;
      opacity: 1;
      visibility: visible;
      z-index: 1;
      height: calc(100% - 55px);
      .nav-link {
        padding: 0.3rem 1rem;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .navbar {
    padding: 0;
  }
  .nav-item {
    min-width: 100%;
    font-size: 18px;
    cursor: pointer;
  }
  .under-menu {
    padding-left: 12px;
  }

  .expanded {
    width: 200px;
    .svg-inline--fa {
      margin-right: 10px;
      margin-left: 10px;
    }
    #open-browse-sidebar {
      padding: 0.5em 1em;
      .svg-inline--fa {
        width: 1.25em;
      }
      .fa-chevron-right {
        display: inline;
        position: absolute;
        right: 10px;
        transform: translateY(4px);
      }
    }
  }
  .collapsed {
    .links {
      display: none;
    }
    .svg-inline--fa {
      margin: 0 10px;
    }
    .collapseText,
    .fa-chevron-right {
      display: none;
    }
    .dropdown-toggle::after {
      display: none;
    }
    #open-browse-sidebar {
      padding: 0.5em 1em;
      .svg-inline--fa {
        width: 1.25em;
      }
    }
  }
  .navbar-nav {
    height: 100vh;
  }
  .bottom-sidebar {
    text-align: left;
    margin: 1rem;
    bottom: 35px;
    position: absolute;
    left: 0;
    right: 0;
    a,
    .link-text {
      color: ${({theme}) => theme.text2};
      font-size: 12px;
      display: inline-block;
      font-weight: bold;
    }
    .tos {
      padding: 0em 1em;
    }
    .social-links .svg-inline--fa {
      display: inline-block;
      margin: 10px;
      border-radius: 100%;
      border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #4e505a' : '1px solid #b8bbbe')};
      height: 25px;
      width: 25px;
      padding: 5px;
    }
  }
  .navbar-nav .nav-link {
    color: ${({theme}) => theme.text3};
    padding-right: 1rem;
    padding-left: 1rem;
    .svg-inline--fa {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
      color: white;
      width: 24px !important;
    }
    .fa-lock-alt {
      width: 11px !important;
      margin-right: 0;
    }
    .fa-home,
    .fa-globe,
    .fa-bookmark,
    .fa-books-medical,
    .fa-list-ul {
      display: inline;
    }
    .fa-home-lg-alt,
    .fa-globe-solid,
    .fa-bookmark-solid,
    .fa-books-medical-solid,
    .fa-list-ul-solid {
      display: none;
    }
  }
  .lock:not(:hover) {
    color: #4e505a !important;
  }
  .navbar-nav .nav-link.active,
  .navbar-nav .show .nav-link {
    color: ${({theme}) => theme.text2};
  }
  .a_openBrowseSidebarButton:hover {
    background: ${({theme}) => theme.nav_background};
    .svg-inline--fa {
      color: #0677d8;
    }
    .collapseText {
      color: ${({theme}) => theme.text3};
    }
  }
  .browse-active {
    background: ${({theme}) => theme.nav_background};
    .svg-inline--fa {
      color: #0677d8;
    }
  }
  .active {
    background: linear-gradient(90deg, #008aff 0%, #9043ff 100%);
    .svg-inline--fa {
      color: #ffffff;
    }
    .collapseText {
      color: ${({theme}) => theme.text3};
    }
  }
  #sidebar_purchases {
    background: linear-gradient(90deg, #008aff 0%, #9043ff 100%);
  }
  #sidebar_purchases.active,
  #sidebar_purchases:hover {
    background: #008aff;
    .svg-inline--fa {
      color: ${({theme}) => theme.text3};
    }
  }
  #sidebar_certificate {
    background: linear-gradient(90deg, #008aff 0%, #9043ff 100%);
  }
  #sidebar_certificate.active,
  #sidebar_certificate:hover {
    background: #008aff;
    .svg-inline--fa {
      color: ${({theme}) => theme.text3};
    }
  }
  .navbar-nav .nav-link:hover {
    color: ${({theme}) => theme.text3};
    background: ${({theme}) => theme.nav_background};
    .svg-inline--fa {
      color: #0677d8;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
    }
    .fa-home,
    .fa-globe,
    .fa-bookmark,
    .fa-books-medical,
    .fa-list-ul {
      display: none;
    }
    .fa-home-lg-alt,
    .fa-globe-solid,
    .fa-bookmark-solid,
    .fa-books-medical-solid,
    .fa-list-ul-solid,
    .fa-chevron-right {
      display: inline;
    }
  }
  .collapseText {
    margin-left: 5px;
  }
  .msgStatus {
    height: 8px;
    width: 8px;
    background-color: #007bff;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 149px;
  }
  .msgStatusCollapse {
    height: 8px;
    width: 8px;
    background-color: #007bff;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 24px;
  }
  .a_collapseIcon {
    margin: 15px 0 15px 22px;
    padding: 0 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }
  .icon {
    margin: 5px 0 15px 22px;
    padding: 0 0.5rem;
    @media (min-width: 768px) and (max-width: 1200px) {
      margin: 20px 0 10px 25px;
    }
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 2em;
    vertical-align: 0em;
    content: '';
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .sub-menu .dropdown-menu {
    position: absolute;
    height: 100vh;
    width: 202px;
    margin-left: 100% !important;
    margin-top: -132px !important;
    border-radius: 0 !important;
    z-index: 999999;
    background: ${({theme}) => theme.content_basic_bg};
    @media (min-width: 768px) and (max-width: 1200px) {
      margin-top: -141px !important;
    }
  }
  .sub-menu .dropdown-menu .dropdown-item {
    color: ${({theme}) => theme.dropdown_item};
  }
  .custom-dropdown.has-children {
    &::after {
      display: block;
      position: absolute;
      right: 1em;
      content: '';
      margin-top: -22px;
      border-top: 0.3em solid transparent;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
    }
  }
  .navbar-nav .nav-link:focus {
    color: ${({theme}) => theme.text2};
  }
  .browse:focus-within > dropdown-menu,
  .browse:hover > dropdown-menu,
  .browse:hover {
    color: ${({theme}) => theme.text2} !important;
    background: ${({theme}) => theme.nav_background};
    .svg-inline--fa {
      color: #0677d8;
    }
  }
  .dropdown-item:hover {
    color: ${({theme}) => theme.text3};
    background: ${({theme}) => theme.nav_background};
  }
`;

export const Logo = styled.img`
  height: 16px;
  margin: 10px 0 10px 28px;
  @media (min-width: 768px) and (max-width: 1200px) {
    margin: 20px 0 10px 28px;
  }
`;

export const CustomNavItem = styled.div`
  color: ${({theme}) => theme.dropdown_item};
  padding: 0.3em 1em;
`;
