// @flow
import React from 'react';
import {Nav} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {WidgetContainer} from '../elements/profile';

type Props = {
  data: {
    name: string,
    icon: string,
    color: string,
    primaryText: string,
    secondaryText: string,
    hoverText: string,
    link: String,
  },
};

const Widget = ({data}: Props) => {
  return (
    <WidgetContainer>
      <div className={`icon icon-${data.color} mb-3`} data-tip={data.hoverText} data-for="profileStatistics">
        <Nav.Link exact as={NavLink} to={data?.link} disabled={data?.link === ''}>
          <i className={data.icon} alt={data.secondaryText} />
        </Nav.Link>
      </div>
      <div className="stat mt-1 mb-2" id={data.name}>
        {data.primaryText}
      </div>
      <div className="title">{data.secondaryText}</div>
    </WidgetContainer>
  );
};

export default Widget;
