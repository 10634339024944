import {observer} from 'mobx-react-lite';
import React from 'react';
import {Nav} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {SubTitle1} from '../../utils/styles/typography';

export const Tab = styled.nav`
  background-color: transparent;
  margin-bottom: 16px;
  .nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: 576px) {
    margin-top: 41px;
  }
`;

export const Link = styled(NavLink)`
  color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#DCDEE1')};
  letter-spacing: -0.17px;
  text-align: center;
  min-width: 112px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  &.active {
    font-weight: bold;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-image: linear-gradient(90deg, #0dfff3 0%, #008aff 100%) 1 !important;
    border-image-slice: 1;
  }
  :hover {
    border-bottom: 4px solid rgba(255, 255, 255, 0.4);
    color: white;
  }
`;

export const NavItem = styled.li`
  width: auto !important;
  span {
    color: #ffffff;
  }
`;

const PageTab = observer(({TabNavItems}) => (
  <div className="w-100 desk container">
    <Tab>
      <div>
        <Nav>
          {TabNavItems.map(item => (
            <NavItem className="nav-item">
              <Link className={`${item.className} nav-link`} activeClassName="active" to={item.linkTo}>
                <SubTitle1>{item.name}</SubTitle1>
              </Link>
            </NavItem>
          ))}
        </Nav>
      </div>
    </Tab>
  </div>
));

export default PageTab;
