import axios from './axios';

export const startCertificate = video_id => {
  return axios.post('certificate/start', {video_id});
};

export const setCertificateAnswer = answer => {
  return axios.post('certificate/answer', answer);
};

export const getCertificates = (type, sort, sortBy, page, count, start, end) =>
  axios.get('/certificate/list', {
    params: {
      type,
      sort,
      sortBy,
      page,
      limit: count,
      start,
      end,
    },
  });

export const downloadCertificate = id =>
  axios
    .get(`/certificate/${id}/download`, {
      responseType: 'blob',
    })
    .then(response => {
      const contentDisposition = response?.headers['content-disposition'];
      const match = contentDisposition?.match(/filename\s*=\s*"(.+)"/i);
      const filename = match[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

export const deleteCertificate = id => axios.delete(`/certificate/${id}`);
