import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';
import {DocumentsFieldStyle} from '../elements/profile';

type Props = {
  setFile: Function,
  file: any,
};

const DocumentsField = observer(({setFile, file}: Props) => {
  const inputRef = useRef(null);
  const handleClick = () => {
    //     👇️ open file input box on click of other element
    if (inputRef && inputRef.current) inputRef.current.click();
  };

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    // event.target.value = null;
    setFile(fileObj);
  };
  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      // setDragActive(true);
    } else if (e.type === 'dragleave') {
      // setDragActive(false);
    }
  };
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileObj = e.dataTransfer.files[0];
      if (!fileObj) {
        return;
      }
      e.target.value = null;
      setFile(fileObj);
    }
  };
  return (
    <div>
      <DocumentsFieldStyle>
        <div
          className="DocumentDiv TaskDiv"
          id="drag-file-element"
          onDragEnter={handleDrop}
          onDrop={handleDrop}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
        >
          <h6 className="dropText text-center ">{`${
            file?.name ? file.name : 'Drag and drop your file to upload*'
          }`}</h6>

          <input
            className=""
            style={{display: 'none'}}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
            accept="video/mp4,video/quicktime,video/x-msvideo,video/x-ms-wmv,video/x-flv,video/x-matroska,video/mpeg,video/webm,.avi,.wmv"
          />
          <div className="d-flex justify-content-center buttinsDiv">
            <button className="browseButton" type="button" onClick={handleClick}>
              Select files
            </button>
          </div>
        </div>
      </DocumentsFieldStyle>
    </div>
  );
});

export default DocumentsField;
