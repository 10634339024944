import React from 'react';
import {Button, Container} from 'react-bootstrap';
import {Wrapper, ReasonHeading, ReasonCaption, Textarea} from '../elements/cancelSubscription';
import {amplitude} from '../../../utils/Amplitude';

type Props = {
  feedback: String,
  setCancelFeedback: Function,
  onBack: Function,
  onContinue: Function,
  buttonClicked: Boolean,
};

const SubscriptionFeedback = ({feedback, setCancelFeedback, onBack, onContinue, buttonClicked}: Props) => {
  const onClickContinue = () => {
    onContinue();
    amplitude.getInstance().logEvent('cancellation-completed', feedback);
  };

  const onClickBack = () => {
    onBack();
  };

  const changeFeedbackText = value => {
    setCancelFeedback(value);
  };

  return (
    <Container className="min-vh-100 pt-5">
      <Wrapper>
        <div className="text-center text-white mb-4">
          <ReasonHeading>Tell us more.</ReasonHeading>
          <ReasonCaption>We appreciate your feedback!</ReasonCaption>
        </div>
        <div className="text-center a_feedbackText">
          <Textarea
            value={feedback}
            placeholder="Additional comments here"
            onChange={e => changeFeedbackText(e.target.value)}
          />
        </div>
        <div className="w-100 d-flex justify-content-center a_feedback_buttons">
          <Button
            variant="primary"
            type="button"
            onClick={onClickBack}
            id="back-to-reason-page"
            className="a_backToReasonPage mr-3 btn-bottom btn-back"
          >
            Back
          </Button>
          <Button
            variant={feedback ? 'primary' : 'dark'}
            disabled={!feedback || buttonClicked}
            id="Feedback-Next"
            className="a_goToCancelledPage btn-bottom"
            onClick={() => onClickContinue()}
          >
            Submit
          </Button>
        </div>
      </Wrapper>
    </Container>
  );
};

export default SubscriptionFeedback;
