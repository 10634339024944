import React, {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Helmet} from 'react-helmet';
import {Container, Nav} from 'react-bootstrap';
import _ from 'lodash';
import {Main, SpecialtyDiv, BrowseHeader, BrowseHeaderSub, SpecialtyLabel} from './elements/styles';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import AlertComponent from '../../components/AlertComponent';
import Footer from '../../components/Footer';
import {useStore} from '../../store';
import SpecialtyBrowseItem from './components/SpecialtyBrowseItem';
import stringToURLSlug from '../../utils/helper';
import {amplitude} from '../../utils/Amplitude';

// Borwse page
const Browse = observer(() => {
  const {alertStore} = useStore();
  const {message, status, resetAlert} = alertStore;
  const {authStore, videoStore, specialtyStore} = useStore();
  const {setSpecialtyPage} = videoStore;
  const {specialtyList, getSpecialtyList} = authStore;
  const {setParentSpecialty} = specialtyStore;
  const [activeSpecialty, setActiveSpecialty] = useState(null);
  const location = useHistory();
  const box = useRef(null);
  const children =
    activeSpecialty === null
      ? null
      : _.sortBy(
          (activeSpecialty.children || []).filter(s => s.type == 'common' && s.videoCount > 0),
          'name',
        );

  useEffect(() => {
    if (!specialtyList?.length) {
      getSpecialtyList();
    }
  }, [specialtyList, getSpecialtyList]);

  const goToSpecialtyPage = () => {
    setSpecialtyPage(activeSpecialty.display_name);
    setParentSpecialty(null);
    const slug = stringToURLSlug(activeSpecialty.display_name);
    location.push(`/specialty/${activeSpecialty.id}/${slug}`);
    const eventProperties = {
      'specialty name': `${activeSpecialty.display_name}`,
    };
    amplitude.getInstance().logEvent('browse-clicked', eventProperties);
  };

  const goToHomePage = () => {
    location.push('/');
  };
  return (
    <div id="browse" className="d-flex">
      <Helmet>
        <title>GIBLIB</title>
        <meta
          name="description"
          content="Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:image"
          content="https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png"
        />
        <meta
          name="image"
          property="og:image"
          content="https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png"
        />
      </Helmet>

      <Sidebar />
      <div className="w-100">
        <Navbar />
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
        <Container>
          <Main>
            {activeSpecialty === null ? (
              <SpecialtyDiv id="specialtyDiv">
                <Container className="container-fluid">
                  <div className="d-flex container" id="browseMenu">
                    <Nav.Item onClick={goToHomePage}>Home</Nav.Item>&nbsp;/&nbsp;
                    <Nav.Item onClick={() => setActiveSpecialty(null)}> Browse</Nav.Item>&nbsp;/&nbsp;
                    <Nav.Item className="browseHighlight" onClick={() => setActiveSpecialty(null)}>
                      {' '}
                      Specialty
                    </Nav.Item>
                  </div>
                  <BrowseHeader>Specialty</BrowseHeader>
                  <hr />
                  <div className="row browse flex-column" ref={box}>
                    <Nav.Item id="Specialties">
                      {specialtyList
                        .filter(s => s.type == 'common' && s.videoCount > 0)
                        .map(s => (
                          <SpecialtyBrowseItem
                            specialty={s}
                            setActiveSpecialty={setActiveSpecialty}
                            hasActiveSpecialty={!!activeSpecialty}
                          />
                        ))}
                    </Nav.Item>
                  </div>
                </Container>
              </SpecialtyDiv>
            ) : (
              <SpecialtyDiv id="specialtyDiv">
                <Container className="container-fluid">
                  <div className="d-flex container" id="browseMenu">
                    <Nav.Item onClick={goToHomePage}>Home</Nav.Item>&nbsp;/&nbsp;
                    <Nav.Item onClick={() => setActiveSpecialty(null)}> Browse</Nav.Item>&nbsp;/&nbsp;
                    <Nav.Item onClick={() => setActiveSpecialty(null)}> Specialty</Nav.Item>&nbsp;/&nbsp;
                    <Nav.Item className="browseHighlight"> {activeSpecialty.name}</Nav.Item>
                  </div>
                  <BrowseHeaderSub>
                    <span
                      onClick={() => setActiveSpecialty(null)}
                      onKeyDown={() => setActiveSpecialty(null)}
                      role="button"
                      tabIndex="0"
                      className="subspecialty-breadcrumb browseArrow"
                    >
                      <i className="fal fa-chevron-left subspecialty-breadcrumb" />
                      &nbsp;
                    </span>
                    {activeSpecialty.name}
                  </BrowseHeaderSub>
                  <hr />
                  <div className="row browse flex-column" ref={box}>
                    <Nav.Item>
                      <SpecialtyLabel onClick={() => goToSpecialtyPage()}>View All</SpecialtyLabel>
                    </Nav.Item>
                    <Nav.Item id="Specialties">
                      {children.map(subspecialty => (
                        <SpecialtyBrowseItem specialty={subspecialty} hasActiveSpecialty={!!activeSpecialty} />
                      ))}
                    </Nav.Item>
                  </div>
                </Container>
              </SpecialtyDiv>
            )}
          </Main>
        </Container>
        <Footer />
      </div>
    </div>
  );
});

export default Browse;
