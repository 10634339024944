import React, {useState} from 'react';
import {Col, Row, Container} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import Stepper from './stepper';
import PlanSelection from '../../Register/components/PlanSelection';
import OrderSummary from '../../Register/components/OrderSummary';
import PaymentDetail from '../../Register/components/PaymentDetail';
import {Wrapper} from '../../Register/style';
// import {useStore} from '../../../store';
import SubscriptionNavbar from '../../../components/SubscriptionNavbar';
import {SubscriptionUpgradeContainer} from '../elements/profile';

export const SubscriptionFlow = ({activeRegistrationStep}: Props) => {
  // const {authStore} = useStore();
  const history = useHistory();
  const [promoCode, setPromoCode] = useState(null);
  return (
    <div id="following" className="d-flex flex-column flex-fill min-vh-100">
      <SubscriptionNavbar />
      <SubscriptionUpgradeContainer>
        <Container fluid>
          <Stepper activeRegistrationStep={activeRegistrationStep} />
          <Wrapper>
            <Row className="justify-content-md-center">
              <Col>
                <PlanSelection
                  usecase="accountsettings"
                  activeRegistrationStep={activeRegistrationStep}
                  navigate={() => {
                    if (['account', 'payment'].includes(activeRegistrationStep)) {
                      history.push('/profile/plan-update');
                    }
                  }}
                />
                <PaymentDetail
                  setPromoCodeFlow={setPromoCode}
                  usecase="accountsettings"
                  activeRegistrationStep={activeRegistrationStep}
                />
              </Col>
              <Col>
                <OrderSummary promoCode={promoCode} />
              </Col>
            </Row>
          </Wrapper>
        </Container>
      </SubscriptionUpgradeContainer>
    </div>
  );
};
