import React from 'react';
import Moment from 'react-moment';
import {observer} from 'mobx-react-lite';

export default observer(({contentPackage}: any) => {
  return (
    <div>
      <p> - Under construction -</p>
      <p>
        <b>Name:&nbsp;</b>
        {contentPackage.name}
      </p>
      <p>
        <b>Videos:&nbsp;</b>
        {contentPackage.videos.length}
      </p>
      <p>
        <b>Purchased At:&nbsp;</b>
        <Moment format="MM/DD/YY">{contentPackage.created_at}</Moment>
      </p>
    </div>
  );
});
