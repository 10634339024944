import styled from 'styled-components';

export const Logo = styled.img`
  height: 16px;
  margin: 10px 0 10px 18px;
`;

export const Line = styled.div`
  width: 132px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  display: inline-block;
  vertical-align: middle;

  @media screen and (max-width: 414px) {
    width: 36%;
  }

  @media screen and (min-width: 414px) {
    min-width: 112px;
  }
`;
