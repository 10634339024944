/* eslint-disable react/prop-types */
import React from 'react';
import {observer} from 'mobx-react-lite';
// import {SkeletonTheme} from 'react-loading-skeleton';
import {ThemeProvider} from 'styled-components';
import {darkTheme} from '../utils/styles';

const ThemeContext = React.createContext(); // line A - creating the context

const ThemeStore = observer(({children}) => {
  return (
    <ThemeContext.Provider>
      <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
});

export {ThemeStore, ThemeContext};
