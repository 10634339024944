// add id attribute to the ref element while calling this hook
import {useEffect} from 'react';

export function useOnScreen(ref, ids, setIds) {
  useEffect(() => {
    ref?.map(el => {
      try {
        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting && ids.indexOf(String(el?.current?.id)) === -1) {
            setIds([...ids, el?.current?.id]);
          }
        });
        observer.observe(el.current);
        return () => {
          observer.disconnect();
        };
      } catch {
        return true;
      }
    });
    // eslint-disable-next-line
  }, [ref]);

  return ids;
}
