/* Pigma link: https://www.figma.com/file/n1xLyxYsnVM0AtbMYX4i5q/Design-System---Chang?node-id=0%3A1 */
import styled from 'styled-components';
import {Button} from 'react-bootstrap';

export const Heading1 = styled.h1`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 32px;
`;

export const Heading2 = styled.h2`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
`;

export const Title1 = styled.h5`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
`;

export const Title2 = styled.p`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
`;

export const SubTitle1 = styled.h2`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 17.5px;
`;

export const SubTitle2 = styled.div`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
`;

export const Body1 = styled.div`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 17.5px;
  color: #ffffff;
`;

export const Body2 = styled.div`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
`;

export const Body2WithStrike = styled.p`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: ${({theme}) => theme.highlight_mid};
`;

export const Caption = styled.div`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
`;

export const MenuRegular = styled.p`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: regular;
  font-size: 14px;
`;

export const MenuSmall = styled.p`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 10px;
`;

export const Link01 = styled.p`
  font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
`;

export const ButtonXLarge = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 15px;
  color: #ffffff;
  border: none;

  &:hover {
    color: #ffffff;
  }
`;

export const ButtonSmall = styled(Button)`
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 4px 8px;
  border: none;
  white-space: nowrap;
`;
