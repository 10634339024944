import styled from 'styled-components';

const StyledVideoTabContent = styled.div`
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  .content-wrapper {
    overflow: hidden auto;
    flex-grow: 1;
  }

  & .select-transcript {
    width: 105px;
    font-size: 12px;
    position: absolute;
    right: 14px;
    top: 20px;
    border: 1px solid ${({theme}) => theme.content_active_bg};
  }

  & .custom-select {
    background-image: ${({theme}) =>
      theme.name === 'light'
        ? 'linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)'
        : 'linear-gradient(45deg, transparent 50%, #dcdee1 50%), linear-gradient(135deg, #dcdee1 50%, transparent 50%)'};
    background-position: calc(100% - 15px) 1em, calc(100% - 10px) 1em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    background-color: ${({theme}) => theme.card_bg};
    cursor: pointer;
    color: ${({theme}) => theme.circle};
  }
  label {
    width: 100%;
    padding: 7px;
  }
  .form-check-input {
    display: none;
  }
  input:checked ~ label::after {
    content: '';
    left: 10px;
    bottom: 10px;
    width: 6px;
    height: 11px;
    border: solid white;
    position: absolute;
    transform: rotate(45deg);
    display: block;
    border-width: 0 2px 2px 0;
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  span {
    font-size: 12px;
  }
  .a_selectLanguage span {
    width: 3ch;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
  .a_selectLanguage {
    border: 1px solid ${({theme}) => theme.content_active_bg};
    width: 105px;
    text-align: left;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
    font-size: 12px;
    padding-left: 10px;
    padding-top: 6px;
    position: relative;
    padding-bottom: 0;
  }
  .a_selectLanguage:hover,
  .a_selectLanguage:active,
  .a_selectLanguage:focus {
    font-size: 12px;
    background-color: #252730;
    color: #dcdee1;
  }
  .dropdown-toggle::after {
    position: absolute;
    right: 8px;
    top: 13px;
  }
  .dropdown-menu {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 125px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 16px;
    min-width: 7rem;
  }
  .dropdown {
    display: inline-block;
    position: absolute;
    right: 14px;
    top: 20px;
  }
  .notes-wrap {
    overflow: unset;
  }
`;

export default StyledVideoTabContent;
