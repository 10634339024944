import React from 'react';
import {Container} from 'react-bootstrap';
import {ConferenceDescContainer} from '../elements/conferenceLanding';

type Props = {
  text: String,
};
const ConferenceDescription = ({text}: Props) => {
  const newText = text ? text.replace(/'/g, '').replace(/\\n/g, '').replace(/\+/g, '') : '';
  return (
    <Container>
      <ConferenceDescContainer dangerouslySetInnerHTML={{__html: newText}} />
    </Container>
  );
};

export default ConferenceDescription;
