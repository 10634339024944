import axios from './axios';

export const getManaged = async () => {
  const accesses = (await axios.get(`/access/managed`)).data;
  return accesses;
};

export const getIPBasedAccess = async () => {
  try {
    return (await axios.get(`/access/get-by-ip`)).data;
  } catch (error) {
    return null;
  }
};

export const getByAccess = async () => {
  try {
    return (await axios.get('/access/get-by-access')).data;
  } catch (error) {
    return null;
  }
};

export const getManagedDetail = (id, page, limit, q, sort, sortBy) =>
  axios.get(`/access/managed/${id}`, {
    params: {
      page,
      limit,
      q,
      sort,
      sortBy,
    },
  });

export const updateUnclaimedAccess = (id, newEmail) =>
  axios.post(`/access/managed/access-history/${id}`, {new_email: newEmail});

export const addAccessHistory = (id, newEmail) => axios.post(`/access/managed/${id}`, {new_email: newEmail});

export const deleteAccessHistory = ids => axios.delete('/access/managed/access-history', {data: {ids}});
