import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {Form} from 'react-bootstrap';

export const NavItem = styled.li`
  width: auto !important;
`;

export const RevokeCMELabel = styled(Form.Label)`
  h6 {
    font-weight: bold;
  }
  p {
    font-size: 0.875rem;
    letter-spacing: -0.00875rem;
    line-height: 1.625rem;
  }
`;

export const CarouselDiv = styled.div`
  margin-top: 8px;
  margin-left: 61px;

  .carousel-order {
    height: 20px;
    width: 106px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.16px;
    line-height: 20px;
    margin-bottom: 25px;
  }

  .carousel-box {
    box-sizing: border-box;
    height: 48px;
    width: 396px;
    border: 1px solid #b8bbbe;
    background-color: #181a21;
    margin-left: 18px;

    :hover {
      background-color: ${({theme}) => theme.card_bg};
      box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .carousel-name {
    height: 20px;
    width: 424px;
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 20px;
    margin-left: 20px;
    margin-top: 14px;
  }

  .carousel-card {
    display: flex;
    width: 424px;
    height: 48px;
    margin-left: 21px;
  }

  .order {
    height: 20px;
    width: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.16px;
    line-height: 20px;
    margin-top: 14px;
  }
`;

export const DataDiv = styled.div`
  margin-top: 15px;

  .form-label {
    height: 26px;
    width: 246px;
    color: ${({theme}) => theme.text};
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.16px;
    line-height: 26px;
    margin-top: 10px;
    margin-bottom: 12px;
  }

  p {
    height: 26px;
    width: auto;
    color: #35cb95;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: -0.14px;
    line-height: 26px;
    margin-bottom: 13px;
  }

  .a_error {
    color: #e04463;
  }

  .form-control-file {
    display: none;
  }

  .form_row {
    margin: 0px;
  }

  .a_upload {
    display: block;
    height: 32px;
    width: 170px;
    border-radius: 4px;

    span {
      height: 16px;
      width: 84px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.11px;
      line-height: 16px;
      text-align: center;
    }
  }
`;

export const Tab = styled.nav`
  margin-bottom: 30px;
  .nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  @media screen and (max-width: 768px) {
    .container {
      overflow: auto;
    }
    .container::-webkit-scrollbar {
      width: 0 !important;
    }
    .nav {
      width: 567px;
    }
  }
`;

export const Link = styled(NavLink)`
  color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#DCDEE1')};
  font-size: 14px;
  letter-spacing: -0.17px;
  line-height: 17px;
  text-align: center;
  padding: 0 18px 0 15px;
  width: 112px;
  height: 48px;
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
  &.active {
    background: linear-gradient(90deg, #08d1f8 0%, #008aff 100%) left bottom no-repeat;
    background-size: 100% 4px;
  }
`;

export const Header = styled.div`
  background: ${({theme}) => theme.body};
  @media screen and (max-width: 768px) {
    .d-flex {
      justify-content: center;
    }
  }

  h5 {
    height: 26px;
    color: ${({theme}) => theme.text};
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.2px;
    line-height: 25px;
    margin-bottom: 49px;
    img {
      width: 36px;
      margin-right: 16px;
    }
  }
`;

export const MobileScreen = styled.div`
  text-align: center;
  img {
    margin-bottom: 12px;
    width: 36px;
  }
  h5 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.4);
  }
`;

export const UserToolsDiv = styled.div`
  form {
    margin-bottom: 25px;
  }
  h6 {
    margin-bottom: 0.1rem;
  }
  span {
    display: block;
    font-size: 0.9em;
    margin-bottom: 0.7em;
  }
  input,
  .form-control {
    border: none;
  }
  input {
    height: 32px;
    width: 240px;
    margin-right: 0.8em;
    padding: 0 1em;
  }
  input[type='submit'] {
    display: block;
    height: 32px;
    width: 96px;
    background-color: #0677d8;
    color: #ffffff;
    margin: 0.7em 0;
  }
  .form-row {
    margin-left: 0;
  }
  .status-div {
    margin: auto 0.7em;
    font-size: 14px;
  }
  .user-tool-success {
    color: #35cb95;
  }
  .user-tool-error {
    color: #e04463;
  }
`;
