import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Container, Spinner} from 'react-bootstrap';
import {useHistory, useRouteMatch, Redirect, Route, Switch} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import {Main, NoCourseMessage, Body} from './elements/courseLanding';
import CourseDescription from './components/CourseDescription';
import {useStore} from '../../store';
import AlertComponent from '../../components/AlertComponent';
import {amplitude} from '../../utils/Amplitude';
import Breadcrumbs from '../../components/Breadcrumbs';
import LandingPageInfo from '../../components/LandingPageInfo';
import PageTab from '../../components/PageTab';
import LandingVideoCard from '../../components/LandingVideoCard';
import LandingCard from '../../components/LandingCard';
import CircleProgressBar from '../../components/CircleProgressBar';
import Footer from '../../components/Footer';

// CourseLanding page
const CourseLanding = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const match = useRouteMatch();
  const location = useHistory();
  const courseId = match.params?.id;
  const {courseLandingStore, alertStore, videoStore} = useStore();
  const {
    course,
    getCourseData,
    courseVideos,
    relatedCourses,
    onFollowRelatedCourses,
    onUnfollowRelatedCourses,
    onBookmarkCourseVideo,
    onDeleteBookmarkCourseVideo,
    onBookmarkRelatedCourses,
    onDeleteBookmarkRelatedCourses,
    playlist,
    getAllPlaylists,
  } = courseLandingStore;
  const {setReferrer} = videoStore;

  const {message, status, resetAlert} = alertStore;

  const TabNavItems = [
    {
      id: 1,
      className: 'a_courseList',
      linkTo: `${match.url}/videos`,
      name: 'Vidoes',
    },
    {
      id: 2,
      className: 'a_relatedCourses',
      linkTo: `${match.url}/related-courses`,
      name: 'Related Courses',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const amplitudeMediaEvent = title => {
    const eventProperties = {
      'course name': title,
    };
    amplitude.getInstance().logEvent('course-page-viewed', eventProperties);
  };

  useEffect(() => {
    setIsLoading(true);
    getCourseData(courseId, title => {
      setIsLoading(false);
      amplitudeMediaEvent(title);
    });
    // eslint-disable-next-line
  }, [courseId, getCourseData]);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  const onHandleFollow = item => {
    const user = localStorage.getItem('user');
    if (user) {
      if (item.follow?.length) {
        onUnfollowRelatedCourses(item.id);
        const eventProperties = {
          type: 'course',
          source: 'related course detail page',
          name: `${item.name}`,
        };
        amplitude.getInstance().logEvent('unfollow', eventProperties);
      } else {
        onFollowRelatedCourses(item.id);
        const eventProperties = {
          type: 'course',
          source: 'related course detail page',
          name: `${item.name}`,
        };
        amplitude.getInstance().logEvent('follow', eventProperties);
      }
    } else {
      location.push('/login');
    }
  };

  const onHandleVideoBookmark = item => {
    const user = localStorage.getItem('user');
    if (user) {
      if (item.bookmark) {
        onDeleteBookmarkCourseVideo(item.id);
      } else {
        onBookmarkCourseVideo(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  const onHandleBookmark = item => {
    const user = localStorage.getItem('user');
    if (user) {
      if (item.bookmark?.length) {
        onDeleteBookmarkRelatedCourses(item.id);
      } else {
        onBookmarkRelatedCourses(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  const gotoCourseeDetail = (id, order) => {
    setReferrer('course page');
    location.push(`/video/${id}?course_id=${courseId}&order=${order}`);
  };

  const bookmarkCourseVideo = (event, selectedItem) => {
    event.stopPropagation();
    onHandleVideoBookmark(selectedItem);
  };

  return (
    <div id="courseLanding" className="d-flex flex-column flex-fill">
      <Helmet
        title={`${course.title}`}
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content:
              course.thumbnail_url ||
              'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content:
              course.thumbnail_url ||
              'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <div>
          <div className="d-flex overflow-hidden">
            {!isLoading && course.id && (
              <Main>
                {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
                <div>
                  <Breadcrumbs
                    parentActivecrumb_name="Courses"
                    parentActivecrumb_url="/courses"
                    activeCrumb_name={course.title}
                  />
                </div>
                <CircleProgressBar item={course} videos={courseVideos} />
                <div className="d-flex mt-5">
                  <div>
                    <LandingPageInfo type="course" item={course} playlist={playlist} landingVideos={courseVideos} />
                  </div>
                  <Body className="w-100">
                    <PageTab TabNavItems={TabNavItems} />
                    <Container id="course-landing-contents">
                      <Switch>
                        <Route
                          path={`${match.path}/videos`}
                          render={() => (
                            <>
                              {(courseVideos || []).map(vList => (
                                <LandingVideoCard
                                  item={vList.video}
                                  goToVideoDetail={() => gotoCourseeDetail(vList.video.id, vList.order)}
                                  playlist={playlist}
                                  bookmarkLandingVideo={bookmarkCourseVideo}
                                />
                              ))}
                            </>
                          )}
                        />
                        <Route
                          exact
                          path={`${match.path}/description`}
                          render={() => <CourseDescription text={course ? course.description : ''} />}
                        />
                        <Route
                          exact
                          path={`${match.path}/related-courses`}
                          render={() => (
                            <>
                              {(relatedCourses || []).map(courselist => (
                                <LandingCard
                                  whichType="course"
                                  type={courselist}
                                  key={courselist.id}
                                  onFollow={onHandleFollow}
                                  onBookmark={onHandleBookmark}
                                  playlist={playlist}
                                  playlistStore="courseStore"
                                />
                              ))}
                            </>
                          )}
                        />
                        <Redirect to={`${match.path}/videos`} />
                      </Switch>
                    </Container>
                  </Body>
                </div>
              </Main>
            )}
            {!isLoading && !course.id && (
              <div className="m-auto justify-content-center">
                <NoCourseMessage>
                  Some content has been reorganized to make things easier to find. <br /> If accessing this page from an
                  old link, please use the browse menu to find your desired content.
                </NoCourseMessage>
              </div>
            )}

            {isLoading && (
              <div className="m-auto d-flex justify-content-center">
                <Spinner animation="grow" />
              </div>
            )}
          </div>
          {!isLoading && <Footer />}
        </div>
      </div>
    </div>
  );
});

export default CourseLanding;
