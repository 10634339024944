import React, {useState, useEffect} from 'react';
import {Button, Form, ProgressBar} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useStore} from '../../../../store';
import {QuestionDiv, Disclaimer} from './style';
import {amplitude} from '../../../../utils/Amplitude';

const CME = observer(({videoTitle, expanded}) => {
  const {cmeStore, accountStore} = useStore();
  const {
    currentQuestion,
    getNextQuestion,
    setAnswer,
    totalQuestion,
    getQuestionById,
    saveAnswer,
    setCurrentAnswer,
    currentAnswer,
    savePartialAnswer,
    setshowComplete,
    setshowQuestionBlock,
    questionchanged,
  } = cmeStore;
  const {updateAccountStat} = accountStore;

  const {register, handleSubmit} = useForm();
  const [checkedState, setCheckedState] = useState([]);
  const [choiceBlock, setChoiceBlock] = useState([]);
  const [calculateCheckedBoxes, setCalculateCheckedBoxes] = useState(false);
  const [drawChoices, setDrawChoices] = useState(false);
  // eslint-disable-next-line
  const [checked, setChecked] = useState([]);
  const [enableButton, setenableButton] = useState(false);
  const [inputAns, setInputAns] = useState();
  const [inputRemain, setInputRemain] = useState();
  const changeInput = value => {
    setInputAns(value);
    setCurrentAnswer(value);
    if (value.length < currentQuestion.widget.min_length) {
      setInputRemain(currentQuestion.widget.min_length - value.length);
      setenableButton(false);
    } else {
      setInputRemain(0);
      setenableButton(true);
    }
    if (inputRemain <= 1) {
      setenableButton(true);
    }
  };

  const total = (currentQuestion.id / totalQuestion) * 100;
  // eslint-disable-next-line
  const handleOnChange = (e, index) => {
    let updatedCheckedState;
    if (e.target.checked) {
      updatedCheckedState = checkedState?.length
        ? [...checkedState, {checkedChoice: currentQuestion.widget?.choices[index], index}]
        : [{checkedChoice: currentQuestion.widget?.choices[index], index}];
    } else {
      updatedCheckedState = checkedState?.filter(item => item.index != index);
    }
    setCheckedState(updatedCheckedState);
    setenableButton(true);
    setCurrentAnswer(updatedCheckedState);
    setCalculateCheckedBoxes(true);
  };

  useEffect(() => {
    getNextQuestion();
  }, [getNextQuestion]);

  const handleSubmitForm = data => {
    if (data.answer) {
      setCurrentAnswer(data.answer);
    }
    setAnswer();
    savePartialAnswer();
    setCheckedState([]);
    getNextQuestion();
    setenableButton(false);
  };

  useEffect(() => {
    if (currentQuestion?.widget?.choices?.length) {
      const currentCheckedState = currentAnswer?.answer?.map(item => {
        return {checkedChoice: item.checkedChoice, index: item.index};
      });
      setCheckedState(currentCheckedState);
      setCalculateCheckedBoxes(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionchanged]);

  useEffect(() => {
    if (calculateCheckedBoxes) {
      setChecked([]);
      if (checkedState?.length) {
        setChecked(checkedState?.map(item => item.index));
      }
      setDrawChoices(true);
      setCalculateCheckedBoxes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateCheckedBoxes]);

  const onValueChange = event => {
    setCurrentAnswer(event.target.value);
    setenableButton(true);
  };

  const save = () => {
    setAnswer();
    saveAnswer();
    updateAccountStat();
    setshowComplete(true);
    amplitude.getInstance().logEvent('cme-ended');
  };

  const saveAndQuit = () => {
    setAnswer();
    savePartialAnswer();
    setshowQuestionBlock(false);
  };

  useEffect(() => {
    if (drawChoices === true) {
      const choiceBlockBuilder = [];
      choiceBlockBuilder.push(
        <div>
          {currentQuestion.widget?.choices?.map((choice, index) => (
            <Form.Check
              className="choice"
              name="cme-options"
              type="checkbox"
              label={choice}
              value={choice}
              id={index}
              // reset chckedstate when you move back nd next answer is stored in currentAnswer - rebuild checked state from
              // checked={checkedState?.includes(index)}
              checked={checked?.includes(index)}
              onChange={e => handleOnChange(e, index)}
            />
          ))}
        </div>,
      );
      if (checked.length > 0) {
        setenableButton(true);
      } else {
        setenableButton(false);
      }
      setChoiceBlock(choiceBlockBuilder);
      setDrawChoices(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawChoices]);

  const radioBlock = [];
  for (let i = currentQuestion.widget?.min; i <= currentQuestion.widget?.max; i += 1) {
    radioBlock.push(
      <Form.Check
        custom
        name="cme-options"
        value={i}
        inline
        type="radio"
        label={i}
        id={i}
        checked={parseInt(currentAnswer.answer, 10) === i}
        onChange={e => onValueChange(e)}
      />,
    );
  }

  const getWidget = question => {
    switch (question.widget?.type) {
      case 'slider':
        return (
          <div className="question-block">
            <h6>Your progress</h6>
            <ProgressBar now={total} />
            <p>{question.question}</p>
            <small>Please rate your response by selecting a number</small>
            <Form.Group className="radio-form" controlId="radio" onChange={e => onValueChange(e)}>
              {radioBlock}
            </Form.Group>
          </div>
        );
      case 'text':
        return (
          <div className="question-block">
            <h6>Your progress</h6>
            <ProgressBar now={total} />
            <p>{question.question}</p>
            {!currentQuestion.last_question ? (
              <Disclaimer>
                Note: CME credits will be revoked if answers are later deemed unsatisfactory by our CME partner provider
              </Disclaimer>
            ) : (
              ''
            )}
            <Form.Group controlId="note">
              <Form.Control
                as="textarea"
                placeholder="Type your response here"
                value={currentAnswer.answer}
                onChange={e => changeInput(e.target.value)}
                minLength={question.widget.min_length}
                name="answer"
                ref={register({required: true})}
                className={classNames('a_textArea', {bgTextarea: inputAns})}
              />
              {question.widget.min_length && (
                <small className={inputRemain <= 0 ? 'd-none' : 'text-required'}>
                  Minimum&nbsp;
                  {currentAnswer.answer ? inputRemain : question.widget.min_length}
                  &nbsp;characters required
                </small>
              )}
            </Form.Group>
          </div>
        );
      case 'choice':
        return (
          <div className="question-block">
            <h6>Your progress</h6>
            <ProgressBar now={total} />
            <p>{question.question}</p>
            <small>Please check all that apply.</small>
            <Form.Group
              className="check-form"
              type="checkbox"
              controlId="checkbox"
              // onChange={index => handleOnChange(index)}
              onChange={() => {}}
            >
              {choiceBlock}
            </Form.Group>
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <QuestionDiv>
      <h5>CME Assessment</h5>
      {expanded && <h5 className="a_title">{videoTitle}</h5>}
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        {getWidget(currentQuestion)}
        <div className="d-flex mt-4">
          {!currentQuestion.last_question ? (
            <Button
              variant="link"
              type="button"
              className="a_save_quit py-0"
              disabled={!enableButton}
              onClick={() => saveAndQuit()}
            >
              Save and Quit
            </Button>
          ) : (
            ''
          )}

          <div className="ml-auto">
            {currentQuestion.id !== 1 && (
              <Button variant="outline-secondary" type="button" className="a_back" onClick={() => getQuestionById()}>
                Back
              </Button>
            )}

            {!currentQuestion.last_question ? (
              <Button
                variant={inputRemain <= 0 || enableButton ? 'primary' : 'secondary'}
                disabled={!enableButton}
                type="submit"
                className="a_next"
              >
                Next
              </Button>
            ) : (
              <Button variant="primary" type="button" className="a_save" onClick={() => save()}>
                Save
              </Button>
            )}
          </div>
        </div>
      </Form>
    </QuestionDiv>
  );
});

CME.propTypes = {
  expanded: PropTypes.bool,
};
CME.defaultProps = {
  expanded: false,
};

export default CME;
