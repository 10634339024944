import styled from 'styled-components';

export const Main = styled.main`
  z-index: 1000000;
  width: 100%;
  background: #050507;
  height: 100%;
  @media screen and (max-width: 576px) {
    display: block;
  }
  .nav {
    background: #181a21;
    padding: 13px 24px;
  }
`;

export const Content = styled.div`
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -112px;
  padding-top: 112px;
  .a_download-app {
    background: none;
    padding: 0;
    border: none;
    margin-bottom: 68px;
    margin-top: 9px;
  }
  .a_launch {
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    border-radius: 3px;
    background: none;
    width: 248px;
    padding: 11px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    &:hover,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0);
    }
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const ContinueButton = styled.button`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border: none;
  width: 100%;
  padding: 20px;
  &:hover {
    background: #008aff;
  }

  img {
    margin-left: 12.5px;
  }
`;
