import {createContext, useContext} from 'react';
import AuthStore from './auth/authStore';
import SubscriptionStore from './subscription/subscriptionStore';
import MediaStore from './media/mediaStore';
import CourseStore from './course/courseStore';
import CourseLandingStore from './courseLanding/courseLandingStore';
import AnnouncementStore from './notification/notificationStore';
import AccountStore from './account/accountStore';
import RegStore from './reg/regStore';
import OrganizationStore from './organization/organizationStore';
import ExpertStore from './expert/expertStore';
import VideoStore from './video/videoStore';
import BookmarkStore from './bookmark/bookmarkStore';
import SearchStore from './search/searchStore';
import PlaylistStore from './playlist/playlistStore';
import OrganizationCoursesStore from './organizationCourses/organizationCoursesStore';
import CmeStore from './cme/cmeStore';
import AdminStore from './admin/adminStore';
import SpecialtyStore from './specialty/specialtyStore';
import NoteStore from './note/noteStore';
import AlertStore from './alert/alertStore';
import RegistrationUIStore from './ui/RegistrationUIStore';
import CampaignStore from './campaign/CampaignStore';
import ErrorStore from './error/errorStore';
import ContentPackageStore from './contentPackage/ContentPackageStore';
import AccessStore from './access/AccessStore';
import SidebarUIStore from './ui/SidebarUIStore';
import ConferenceStore from './conference/conferenceStore';
import ConferenceLandingStore from './conferenceLanding/conferenceLandingStore';
import MessageStore from './message/messageStore';
import CommentStore from './comments/commentStore';
import AdsStore from './ads/adsStore';
import CertificateStore from './certificate/CertificateStore';

export const errorStore = new ErrorStore(); // exporting for non react-components
const mediaStore = new MediaStore({errorStore});
const expertStore = new ExpertStore({errorStore, mediaStore});
const regStore = new RegStore({errorStore});
const accountStore = new AccountStore({errorStore, mediaStore, expertStore, regStore});
const alertStore = new AlertStore({errorStore});
const authStore = new AuthStore({errorStore, accountStore});
const subscriptionStore = new SubscriptionStore({errorStore, authStore, accountStore});
const playlistStore = new PlaylistStore({errorStore, alertStore});
const courseStore = new CourseStore({errorStore, mediaStore, alertStore, playlistStore});
const courseLandingStore = new CourseLandingStore({errorStore, mediaStore, alertStore, playlistStore});
const announcementStore = new AnnouncementStore({errorStore});
const organizationStore = new OrganizationStore({errorStore, mediaStore});
const cmeStore = new CmeStore({errorStore});
const accessStore = new AccessStore();
const certificateStore = new CertificateStore({errorStore});
const videoStore = new VideoStore({
  errorStore,
  accountStore,
  alertStore,
  cmeStore,
  mediaStore,
  accessStore,
  certificateStore,
});
const commentStore = new CommentStore({errorStore});
const bookmarkStore = new BookmarkStore({errorStore, alertStore});
const searchStore = new SearchStore({errorStore, alertStore, mediaStore});
const organizationCoursesStore = new OrganizationCoursesStore({errorStore});
const adminStore = new AdminStore({errorStore});
const specialtyStore = new SpecialtyStore({errorStore});
const noteStore = new NoteStore({errorStore});
const campaignStore = new CampaignStore({errorStore});
const registrationUIStore = new RegistrationUIStore({errorStore, campaignStore, authStore});
const contentPackageStore = new ContentPackageStore();
const sidebarUIStore = new SidebarUIStore();
const conferenceStore = new ConferenceStore({errorStore, mediaStore, alertStore, playlistStore});
const conferenceLandingStore = new ConferenceLandingStore({errorStore, mediaStore, alertStore, playlistStore});
const messageStore = new MessageStore({errorStore});
const adsStore = new AdsStore({errorStore, accountStore});
export const store = {
  errorStore,
  authStore,
  subscriptionStore,
  mediaStore,
  courseStore,
  courseLandingStore,
  announcementStore,
  accountStore,
  regStore,
  organizationStore,
  expertStore,
  videoStore,
  commentStore,
  bookmarkStore,
  searchStore,
  playlistStore,
  organizationCoursesStore,
  cmeStore,
  adminStore,
  specialtyStore,
  noteStore,
  alertStore,
  registrationUIStore,
  campaignStore,
  contentPackageStore,
  accessStore,
  sidebarUIStore,
  conferenceStore,
  conferenceLandingStore,
  messageStore,
  adsStore,
  certificateStore,
};
export const StoreContext = createContext(store);
export const useStore = () => {
  return useContext(StoreContext);
};
