// @flow
import React, {useState, useEffect} from 'react';
import {Navbar, Nav, i} from 'react-bootstrap';
import {NavLink, useHistory} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {observer} from 'mobx-react-lite';
import {Side, Logo, DeskSideBar, TabletSidebar} from './sidebar';
import logo from '../../assets/images/GBLB_Logo_Horizontal_Notag_White_FullColor.png';
import icon from '../../assets/images/GBLB_Logo_Mark_FullColor.png';
import LocalStorageService from '../../utils/LocalStorageService';
import {useStore} from '../../store';
import HomeTutorial from '../../pages/Home/components/HomeTutorial';
import VideoTutorial from '../../pages/Video/components/VideoTutorial';
// import CmeTutorial from '../../pages/CME/Components/CmeTutorial';
import {ADMINS} from '../../utils/types/auth';
import IPNotAccessModal from '../IPNotAccessModal';
import BrowseDropdown from './elements/browseDropdown';
import MyVideos from '../../assets/icons/MyVideos.png';
import Videos from '../../assets/icons/UserVideos.png';

const Sidebar = observer(() => {
  const [expanded, setExpanded] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [showHomeTutorial, setShowHomeTutorial] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const [showCmeTutorial, setShowCmeTutorial] = useState(false);
  const [lockUser, setLockUser] = useState(false);
  const loggedInUser = LocalStorageService.getUser();
  const {accountStore, authStore, organizationStore, messageStore} = useStore();
  const {listOrganizations} = organizationStore;

  const {specialtyList, getAllLists, logout} = authStore;
  const {location} = useHistory();
  const {account, unauthorizedIP, setUnauthorizedIP, setFeaturePermission} = accountStore;
  const {fetchUsers, unreadMessage} = messageStore;
  // const [accounts, setAccount] = useState(account.access)
  const setCertificateAccess = () => {
    return setFeaturePermission('certificate');
  };
  useEffect(() => {
    const setFeatureLockUser = () => {
      return setFeaturePermission('sidebar');
    };

    if (setFeatureLockUser()) {
      setLockUser(true);
    }
  }, [loggedInUser, account, setFeaturePermission]);

  useEffect(() => {
    if (!specialtyList?.length) {
      getAllLists();
    }
  }, [specialtyList, getAllLists]);

  // useEffect(() => {
  //   if (carousels?.length) loadCarouselTopics();
  // }, [carousels, loadCarouselTopics]);

  useEffect(() => {
    listOrganizations('ASC', 'name', true, false);
    listOrganizations('ASC', 'name', true, true);
  }, [listOrganizations]);

  const ShowTutorial = () => {
    if (location?.pathname.includes('/video')) {
      setShowVideoTutorial(!showVideoTutorial);
    } else if (location?.pathname.includes('/cme')) {
      setShowCmeTutorial(!showCmeTutorial);
    } else setShowHomeTutorial(!showHomeTutorial);
  };

  const clickLogout = async () => {
    await logout();
    setUnauthorizedIP(false);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <IPNotAccessModal show={unauthorizedIP} onLogout={clickLogout} />

      <HomeTutorial
        isTutorialOpen={showHomeTutorial && !unauthorizedIP}
        isExpand={expanded}
        isCollapse={collapsed}
        setIsTourOpen={setShowHomeTutorial}
      />
      <VideoTutorial isTutorialOpen={showVideoTutorial && !unauthorizedIP} setIsTourOpen={setShowVideoTutorial} />
      {/* <CmeTutorial
        isTutorialOpen={(showCmeFirstTutorial || showCmeTutorial) && !unauthorizedIP}
        setIsTourOpen={setShowCmeTutorial}
      /> */}
      <DeskSideBar>
        <Side className="min-vh-100">
          <Navbar defaultExpanded className="flex-column flex-end" expand="lg">
            <Navbar.Brand as={NavLink} to="/" id="sidebar_GIBLIB">
              {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="d-md-block" activeKey="/home">
                {expanded ? (
                  <a href="/">
                    <Logo src={logo} alt="GIBLIB" className="logo" />
                  </a>
                ) : (
                  <a href="/">
                    <Logo src={icon} alt="GIBLIB" className="icon" />
                  </a>
                )}
                <Nav.Item onClick={() => setExpanded(!expanded)} className="a_collapseIcon">
                  <i id="collapse-icon" className="fak fa-bars" />
                </Nav.Item>
                {expanded ? (
                  <div className="expanded">
                    <Nav.Item>
                      <Nav.Link exact as={NavLink} to="/" id="sidebar_home" activeClassName="active">
                        <i className="fak fa-home" />
                        <i className="fas fa-home-lg-alt" />
                        <span className="collapseText">Home</span>
                      </Nav.Link>
                    </Nav.Item>
                    <BrowseDropdown data-tut="hometutorial__browse_desk_expand" />
                    <Nav.Item className="under-menu">
                      <Nav.Link exact as={NavLink} to="/browse/specialty" id="sidebar_specialty">
                        <i className="fal fa-user-md" />
                        <span className="collapseText">Specialty</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="under-menu">
                      <Nav.Link exact as={NavLink} to="/conferences" id="sidebar_conferences">
                        <i className="fal fa-desktop-alt" />
                        <span className="collapseText">Conferences</span>
                      </Nav.Link>
                    </Nav.Item>
                    {account?.access?.content_based ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="/purchased" id="sidebar_purchases" activeClassName="active">
                          <i className="fal fa-star" />

                          <span className="collapseText">Purchased</span>
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/bookmarks"
                        id="sidebar_bookmarks"
                        activeClassName="active"
                        data-tut="hometutorial__bookmark_desk_expand"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-bookmark" />
                        <i className="fak fa-bookmark-solid" />
                        {!lockUser ? (
                          <span className="collapseText">Bookmarks</span>
                        ) : (
                          <span className="collapseText lock">
                            Bookmarks
                            <i className="fas fa-lock-alt lock-icon" />
                          </span>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/cme/in-progress"
                        id="sidebar_CME"
                        activeClassName="active"
                        data-tut="hometutorial__cme_desk_expand"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-cme-1" />
                        {!lockUser ? (
                          <span className="collapseText">My CME</span>
                        ) : (
                          <span className="collapseText lock">
                            My CME
                            <i className="fas fa-lock-alt lock-icon" />
                          </span>
                        )}
                      </Nav.Link>
                    </Nav.Item>

                    {setCertificateAccess() && process.env.REACT_APP_QUIZ_CERTIFICATE === 'true' ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="/certificate" id="" activeClassName="active">
                          <i className="fak fa-graduation-cap-regular" />
                          <span className="collapseText">Certificate</span>
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to={loggedInUser ? '/playlist' : '/login'}
                        id="sidebar_playlist"
                        activeClassName="active"
                        data-tut="hometutorial__playlist_desk_expand"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-list-ul" />
                        <i className="fak fa-list-ul-solid" />
                        {!lockUser ? (
                          <span className="collapseText">Playlists</span>
                        ) : (
                          <span className="collapseText lock">
                            Playlists
                            <i className="fas fa-lock-alt lock-icon" />
                          </span>
                        )}
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/profile/upload_video"
                        id="sidebar_upload"
                        data-tip="Dashboard"
                        activeClassName="active"
                      >
                        <img src={Videos} className="myVideo" alt="Video" />

                        <span className="collapseText myVideoText">Upload Videos</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/profile/your_videos"
                        id="sidebar_yourVideo"
                        data-tip="Dashboard"
                        activeClassName="active"
                      >
                        <img src={MyVideos} alt="Video" className="myVideo" />

                        <span className="collapseText myVideoText">My Videos</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/profile/messages"
                        id="sidebar_messages"
                        data-tip="Dashboard"
                        activeClassName="active"
                      >
                        <span>
                          <i className="fa fa-envelope-o" />
                          <span className={`${unreadMessage.length ? 'msgStatus' : ''}`} />
                        </span>
                        <span className="collapseText">Messages</span>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/catalog/videos"
                        id="sidebar_catalog"
                        data-tip="Catalog"
                        activeClassName="active"
                      >
                        <i className="fal fa-book" />
                        <span className="collapseText">Catalog</span>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        onClick={ShowTutorial}
                        id="sidebar_tutorial"
                        data-tip="Tutorial"
                        activeClassName="active"
                      >
                        <i className="fal fa-chalkboard-teacher" />
                        <span className="collapseText">Tutorial</span>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        href="https://www.giblib.com/institutions/resources"
                        target="_blank"
                        id="sidebar_resources"
                        data-tip="Resources"
                        activeClassName="active"
                      >
                        <i className="fal fa-handshake" />
                        <span className="collapseText">Resources</span>
                      </Nav.Link>
                    </Nav.Item>

                    {ADMINS.includes(loggedInUser?.role) && (
                      <Nav.Item>
                        <Nav.Link
                          exact
                          as={NavLink}
                          to="/admin/data-upload"
                          id="sidebar_admin"
                          activeClassName="active"
                        >
                          <i className="fal fa-tools" />
                          <span className="collapseText">Admin</span>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </div>
                ) : (
                  <div className="collapsed">
                    <Nav.Item>
                      <Nav.Link exact as={NavLink} to="/" id="sidebar_home" activeClassName="active" data-tip="Home">
                        <i className="fak fa-home" />
                        <i className="fas fa-home-lg-alt" />
                      </Nav.Link>
                    </Nav.Item>
                    <BrowseDropdown data-tut="hometutorial__browse_desk_expand" />
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/browse/specialty"
                        id="sidebar_specialty"
                        activeClassName="active"
                        data-tip="Specialty"
                      >
                        <i className="fal fa-user-md" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/conferences"
                        id="sidebar_conferences"
                        activeClassName="active"
                        data-tip="Conferences"
                      >
                        <i className="fal fa-desktop-alt" />
                      </Nav.Link>
                    </Nav.Item>
                    {account?.access?.content_based ? (
                      <Nav.Item>
                        <Nav.Link
                          as={NavLink}
                          to="/purchased"
                          id="sidebar_purchases"
                          activeClassName="active"
                          data-tip="Purchased"
                        >
                          <i className="fal fa-star" />
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/bookmarks"
                        id="sidebar_bookmarks"
                        activeClassName="active"
                        data-tip="Bookmarks"
                        data-tut="hometutorial__bookmark_desk"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-bookmark" />
                        <i className="fak fa-bookmark-solid" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/cme"
                        id="sidebar_CME"
                        activeClassName="active"
                        data-tip="CME"
                        data-tut="hometutorial__cme_desk"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-cme-1" />
                      </Nav.Link>
                    </Nav.Item>

                    {setCertificateAccess() && process.env.REACT_APP_QUIZ_CERTIFICATE === 'true' ? (
                      <Nav.Item>
                        <Nav.Link
                          exact
                          as={NavLink}
                          to="/certificate"
                          id=""
                          data-tip="Certificate"
                          activeClassName="active"
                        >
                          <i className="fak fa-graduation-cap-regular" />
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/playlist"
                        id="sidebar_playlist"
                        activeClassName="active"
                        data-tip="Playlist"
                        data-tut="hometutorial__playlist_desk"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-list-ul" />
                        <i className="fak fa-list-ul-solid" />
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/profile/upload_video"
                        id="sidebar_upload"
                        data-tip="Upload video"
                        activeClassName="active"
                      >
                        <img src={Videos} className="myVideo" alt="Video" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/profile/your_videos"
                        id="sidebar_yourVideo"
                        data-tip="My videos"
                        activeClassName="active"
                      >
                        <img src={MyVideos} alt="Video" className="myVideo" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/profile/messages"
                        id="sidebar_messages"
                        data-tip="Messages"
                        activeClassName="active"
                      >
                        <span>
                          <i className="fa fa-envelope-o" />
                          <span
                            className={`${expanded && unreadMessage.length ? 'msgStatus' : ''}${
                              !expanded && unreadMessage.length ? 'msgStatusCollapse' : ''
                            }`}
                          />
                        </span>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/catalog/videos"
                        id="sidebar_catalog"
                        data-tip="Catalog"
                        activeClassName="active"
                      >
                        <i className="fal fa-book" />
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        onClick={ShowTutorial}
                        id="sidebar_tutorial"
                        data-tip="Tutorial"
                        activeClassName="active"
                      >
                        <i className="fal fa-chalkboard-teacher" />
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        href="https://www.giblib.com/institutions/resources"
                        target="_blank"
                        id="sidebar_resources"
                        data-tip="Resources"
                        activeClassName="active"
                      >
                        <i className="fal fa-handshake" />
                      </Nav.Link>
                    </Nav.Item>
                    {ADMINS.includes(loggedInUser?.role) ? (
                      <>
                        <Nav.Item>
                          <Nav.Link
                            exact
                            as={NavLink}
                            to="/admin_dashboard"
                            id="sidebar_dashboard"
                            data-tip="Dashboard"
                            activeClassName="active"
                          >
                            <i className="fal fa-chalkboard-teacher" />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            exact
                            as={NavLink}
                            to="/admin/data-upload"
                            id="sidebar_admin"
                            activeClassName="active"
                          >
                            <i className="fal fa-tools" />
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    ) : (
                      <Nav.Item>
                        <Nav.Link
                          exact
                          as={NavLink}
                          to="/dashboard"
                          id="sidebar_dashboard"
                          data-tip="Dashboard"
                          activeClassName="active"
                        >
                          <i className="fal fa-chalkboard-teacher" />
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    <ReactTooltip place="right" effect="solid" />
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Side>
      </DeskSideBar>
      <TabletSidebar>
        <Side className="min-vh-100">
          <Navbar defaultExpanded className="flex-column flex-end" expand="lg">
            <Navbar.Brand as={NavLink} to="/" id="sidebar_GIBLIB" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="d-md-block" activeKey="/home">
                {collapsed ? (
                  <a href="/">
                    <Logo src={icon} alt="GIBLIB" className="icon" />
                  </a>
                ) : (
                  <a href="/">
                    <Logo src={logo} alt="GIBLIB" className="logo" />
                  </a>
                )}
                <Nav.Item onClick={() => setCollapsed(!collapsed)} className="a_collapseIcon">
                  <i id="collapse-icon" className="fak fa-bars" />
                </Nav.Item>
                {collapsed ? (
                  <div className="collapsed">
                    <Nav.Item>
                      <Nav.Link exact as={NavLink} to="/" id="sidebar_home" activeClassName="active" data-tip="Home">
                        <i className="fak fa-home" />
                        <i className="fas fa-home-lg-alt" />
                      </Nav.Link>
                    </Nav.Item>
                    <BrowseDropdown data-tut="hometutorial__browse_tablet_collapse" />
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/browse/specialty"
                        id="sidebar_specialty"
                        activeClassName="active"
                        data-tip="Specialty"
                      >
                        <i className="fal fa-user-md" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/conferences"
                        id="sidebar_conferences"
                        mactiveClassName="active"
                        data-tip="Conferences"
                      >
                        <i className="fal fa-desktop-alt" />
                      </Nav.Link>
                    </Nav.Item>
                    {account?.access?.content_based ? (
                      <Nav.Item>
                        <Nav.Link
                          as={NavLink}
                          to="/purchased"
                          id="sidebar_purchases"
                          activeClassName="active"
                          data-tip="Purchased"
                        >
                          <i className="fal fa-star" />
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/bookmarks"
                        id="sidebar_bookmarks"
                        activeClassName="active"
                        data-tip="Bookmarks"
                        data-tut="hometutorial__bookmark_tablet_collapse"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-bookmark" />
                        <i className="fak fa-bookmark-solid" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/cme"
                        id="sidebar_CME"
                        activeClassName="active"
                        data-tip="CME"
                        data-tut="hometutorial__cme_tablet_collapse"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-cme-1" />
                      </Nav.Link>
                    </Nav.Item>

                    {setCertificateAccess() && process.env.REACT_APP_QUIZ_CERTIFICATE === 'true' ? (
                      <Nav.Item>
                        <Nav.Link
                          exact
                          as={NavLink}
                          to="/certificate"
                          id=""
                          activeClassName="active"
                          data-tip="Certificate"
                        >
                          <i className="fak fa-graduation-cap-regular" />
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/playlist"
                        id="sidebar_playlist"
                        activeClassName="active"
                        data-tip="Playlist"
                        data-tut="hometutorial__playlist_tablet_collapse"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-list-ul" />
                        <i className="fak fa-list-ul-solid" />
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/catalog/videos"
                        id="sidebar_catalog"
                        data-tip="Catalog"
                        activeClassName="active"
                      >
                        <i className="fal fa-book" />
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        onClick={ShowTutorial}
                        id="sidebar_tutorial"
                        data-tip="Tutorial"
                        activeClassName="active"
                      >
                        <i className="fal fa-chalkboard-teacher" />
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        href="https://www.giblib.com/institutions/resources"
                        target="_blank"
                        id="sidebar_resources"
                        data-tip="Resources"
                        activeClassName="active"
                      >
                        <i className="fal fa-handshake" />
                      </Nav.Link>
                    </Nav.Item>

                    {ADMINS.includes(loggedInUser?.role) ? (
                      <>
                        <Nav.Item>
                          <Nav.Link
                            exact
                            as={NavLink}
                            to="/admin_dashboard"
                            id="sidebar_dashboard"
                            data-tip="Dashboard"
                            activeClassName="active"
                          >
                            <i className="fal fa-chalkboard-teacher" />
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            exact
                            as={NavLink}
                            to="/admin/data-upload"
                            id="sidebar_admin"
                            activeClassName="active"
                          >
                            <i className="fal fa-tools" />
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    ) : (
                      <Nav.Item>
                        <Nav.Link
                          exact
                          as={NavLink}
                          to="/dashboard"
                          id="sidebar_dashboard"
                          data-tip="Dashboard"
                          activeClassName="active"
                        >
                          <i className="fal fa-chalkboard-teacher" />
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    <ReactTooltip place="right" effect="solid" />
                  </div>
                ) : (
                  <div className="expanded">
                    <Nav.Item>
                      <Nav.Link exact as={NavLink} to="/" id="sidebar_home" activeClassName="active">
                        <i className="fak fa-home" />
                        <i className="fas fa-home-lg-alt" />
                        <span className="collapseText">Home</span>
                      </Nav.Link>
                    </Nav.Item>
                    <BrowseDropdown data-tut="hometutorial__browse_tablet" />
                    <Nav.Item className="under-menu">
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/browse/specialty"
                        id="sidebar_specialty"
                        activeClassName="active"
                      >
                        <i className="fal fa-user-md" />
                        <span className="collapseText">Specialty</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="under-menu">
                      <Nav.Link exact as={NavLink} to="/conferences" id="sidebar_conferences" mactiveClassName="active">
                        <i className="fal fa-desktop-alt" />
                        <span className="collapseText">Conferences</span>
                      </Nav.Link>
                    </Nav.Item>
                    {account?.access?.content_based ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="/purchased" id="sidebar_purchases" activeClassName="active">
                          <i className="fal fa-star" />
                          <span className="collapseText">Purchased</span>
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/bookmarks"
                        id="sidebar_bookmarks"
                        activeClassName="active"
                        data-tut="hometutorial__bookmark_tablet"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-bookmark" />
                        <i className="fak fa-bookmark-solid" />
                        {!lockUser ? (
                          <span className="collapseText">Bookmarks</span>
                        ) : (
                          <span className="collapseText lock">
                            Bookmarks
                            <i className="fas fa-lock-alt lock-icon" />
                          </span>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/cme"
                        id="sidebar_CME"
                        activeClassName="active"
                        data-tut="hometutorial__cme_tablet"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-cme-1" />
                        {!lockUser ? (
                          <span className="collapseText">My CME</span>
                        ) : (
                          <span className="collapseText lock">
                            My CME
                            <i className="fas fa-lock-alt lock-icon" />
                          </span>
                        )}
                      </Nav.Link>
                    </Nav.Item>

                    {setCertificateAccess() && process.env.REACT_APP_QUIZ_CERTIFICATE === 'true' ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="/certificate" id="" activeClassName="active">
                          <i className="fak fa-graduation-cap-regular" />
                          <span className="collapseText">Certificate</span>
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ''
                    )}

                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/playlist"
                        id="sidebar_playlist"
                        activeClassName="active"
                        data-tut="hometutorial__playlist_tablet"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fak fa-list-ul" />
                        <i className="fak fa-list-ul-solid" />
                        {!lockUser ? (
                          <span className="collapseText">Playlists</span>
                        ) : (
                          <span className="collapseText lock">
                            Playlists
                            <i className="fas fa-lock-alt lock-icon" />
                          </span>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/catalog/videos"
                        id="sidebar_catalog"
                        data-tip="Catalog"
                        activeClassName="active"
                      >
                        <i className="fal fa-book" />
                        <span className="collapseText">Catalog</span>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        onClick={ShowTutorial}
                        id="sidebar_tutorial"
                        data-tip="Tutorial"
                        activeClassName="active"
                      >
                        <i className="fal fa-chalkboard-teacher" />
                        <span className="collapseText">Tutorial</span>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        href="https://www.giblib.com/institutions/resources"
                        target="_blank"
                        id="sidebar_resources"
                        data-tip="Resources"
                        activeClassName="active"
                      >
                        <i className="fal fa-handshake" />
                        <span className="collapseText">Resources</span>
                      </Nav.Link>
                    </Nav.Item>
                    {ADMINS.includes(loggedInUser?.role) ? (
                      <>
                        <Nav.Item>
                          <Nav.Link
                            exact
                            as={NavLink}
                            to="/admin_dashboard"
                            id="sidebar_dashboard"
                            data-tip="Dashboard"
                            activeClassName="active"
                          >
                            <i className="fal fa-chalkboard-teacher" />
                            <span className="collapseText">Dashboard</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            exact
                            as={NavLink}
                            to="/admin/data-upload"
                            id="sidebar_admin"
                            activeClassName="active"
                          >
                            <i className="fal fa-tools" />
                            <span className="collapseText">Admin</span>
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    ) : (
                      <Nav.Item>
                        <Nav.Link
                          exact
                          as={NavLink}
                          to="/dashboard"
                          id="sidebar_dashboard"
                          data-tip="Dashboard"
                          activeClassName="active"
                        >
                          <i className="fal fa-chalkboard-teacher" />
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Side>
      </TabletSidebar>
    </>
  );
});

export default Sidebar;
