import React, {useState} from 'react';
import {Col, Container, Button, Form, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {useForm} from 'react-hook-form';
import {revokeCMe} from '../../../../api/admin';
import {RevokeCMELabel} from '../../elements/styles';

const CMERevoke = () => {
  const {register, setError, handleSubmit, reset, errors} = useForm({
    mode: 'all',
  });
  const [revoking, setRevoking] = useState(false);
  const [revokeSuccessfull, setRevokeSuccessfull] = useState(false);

  const onInputChange = () => setRevokeSuccessfull(false);
  const revokeCMEs = async formData => {
    const cmeIds = formData?.cmeIds?.split(',').map(x => +x);
    setRevoking(true);
    setRevokeSuccessfull(false);
    try {
      const response = await revokeCMe(cmeIds);
      if (response?.data?.status) {
        setRevokeSuccessfull(true);
        reset();
      }
    } catch (error) {
      const responseData = {...error?.response?.data};
      const {notAuthorizedorInvalidIds} = responseData;

      if (responseData?.validationError) {
        setError('cmeIds', {
          type: 'validationError',
          message: 'Api validation error!',
        });
      } else if (responseData?.error && notAuthorizedorInvalidIds?.length) {
        const message = `${notAuthorizedorInvalidIds?.join(', ')} ${
          notAuthorizedorInvalidIds?.length > 1 ? 'are' : 'is'
        } not from your organization or not valid`;
        setError('cmeIds', {
          type: 'apiError',
          message,
        });
      } else if (responseData?.error) {
        setError('cmeIds', {
          type: 'apiError',
          message: responseData?.error,
        });
      } else {
        setError('cmeIds', {
          type: 'apiError',
          message: 'An error occured',
        });
      }
    }
    setRevoking(false);
  };
  return (
    <>
      <Helmet>
        <title>Admin - Revoke CME</title>
        <meta name="description" content="Revoke user cmes" />
      </Helmet>
      <Container className="bg-transparent">
        <Form onSubmit={handleSubmit(revokeCMEs)}>
          <Form.Row>
            <Form.Group as={Col} lg={5} md={8} sm={12} controlId="revoke-cmeIds-input">
              <RevokeCMELabel>
                <h6>Enter id(s) here to revoke CME</h6>
                <p className="mb-3">For multiple submission, please use comma after each ids</p>
              </RevokeCMELabel>
              <Form.Control
                hasValidation
                placeholder="Enter id here - ex) 1011,1012"
                type="text"
                name="cmeIds"
                isInvalid={errors?.cmeIds}
                isValid={!errors?.cmeIds && revokeSuccessfull}
                onChange={onInputChange}
                className="w-100 rounded-pill bg-light"
                ref={register({
                  required: 'This field is required.',
                  pattern: {
                    value: /^(([1-9]\d*)+, *)*([1-9]\d*)+ *$/,
                    message: 'Non number ids and ids with leading zeroes are not accepted',
                  },
                })}
              />
              <Form.Control.Feedback className="mt-2" type="invalid">
                {errors?.cmeIds?.message}
              </Form.Control.Feedback>
              <Form.Control.Feedback className="mt-2" type="valid">
                Successfully revoked cmes!
              </Form.Control.Feedback>
              <Button
                className="float-right mt-3"
                size="sm"
                variant={errors?.cmeIds ? 'secondary' : 'primary'}
                type="submit"
                disabled={revoking || errors?.cmeIds}
              >
                <span>Continue {revoking ? <Spinner animation="grow" size="sm" /> : null}</span>
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </Container>
    </>
  );
};

export default CMERevoke;
