import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Row, Col, Form, Container, Button, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Dropdown from '../../components/Multiselect';
import {useStore} from '../../store';
import close from './elements/close-chip.png';
import {
  PageTitle,
  SortSelect,
  LimitSelect,
  FormLabel,
  ChipText,
  CourseFilter,
  MobileFilter,
  Main,
  Line,
  PaginationContainer,
  EmptyList,
  PlaylistIcon,
} from './elements/notes';
import VideoCard from './components/VideoCard';
import UpgradeModal from '../../components/UpgradeModal';

// Notes page
const Notes = observer(() => {
  const [isLoading, setLoading] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const {videoStore, accountStore, noteStore} = useStore();
  const {account} = accountStore;
  const {
    notesCount,
    changeCount,
    count,
    changeSort,
    noteSortBy,
    page,
    pageIncrement,
    pageDecrement,
    fetchAllNotes,
    notes,
    specialtiesList,
    organizationsList,
    selectedOrganizationList,
    selectedSpecialtiesList,
    selectedOrganizationIds,
    selectedSpecialtyIds,
    setSelectedOrganizations,
    setSelectedSpecialties,
    listOrganizations,
    listSpecialties,
    onRemoveOrganization,
    onRemoveSpecialty,
    deleteNote,
  } = videoStore;
  const {downloadAll} = noteStore;

  const [isShowUpgradeModal, setShowUpgradeModal] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    listSpecialties();
    listOrganizations();
  }, [listSpecialties, listOrganizations]);

  useEffect(() => {
    setLoading(true);
    fetchAllNotes().then(() => setLoading(false));
  }, [selectedSpecialtyIds, selectedOrganizationIds, noteSortBy, count, page, fetchAllNotes]);

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedOrganizations(selectedIDs);
    } else {
      setSelectedSpecialties(selectedIDs);
    }
  };

  const removeOrganization = item => {
    onRemoveOrganization(item.id);
  };

  const removeSpecialty = item => {
    onRemoveSpecialty(item.id);
  };

  const onDownload = () => {
    if (!downloadLoader) {
      setDownloadLoader(true);
      downloadAll().then(() => {
        setDownloadLoader(false);
      });
    }
  };

  const renderNotes = () => {
    return notes?.length ? (
      <>
        <div className="d-flex container mt-2 flex-wrap">
          {selectedOrganizationList.map(item => (
            <ChipText>
              {item.name}
              <span>
                <button type="button" className="bg-transparent border-0" onClick={() => removeOrganization(item)}>
                  <img alt="close" src={close} />
                </button>
              </span>
            </ChipText>
          ))}
          {selectedSpecialtiesList.map(item => (
            <ChipText>
              {item.name}
              <span>
                <button type="button" className="bg-transparent border-0" onClick={() => removeSpecialty(item)}>
                  <img alt="close" src={close} />
                </button>
              </span>
            </ChipText>
          ))}
        </div>
        <Button variant="primary" className="a_downloadNote" onClick={onDownload} disabled={downloadLoader}>
          {downloadLoader ? 'Downloading Notes...' : 'Download All Notes'}
        </Button>
        <PaginationContainer className="text-right">
          <LimitSelect data-testid="limit" onChange={e => changeCount(e.target.value)}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </LimitSelect>
          <span>
            {notesCount === 0 ? <>0 </> : <>{page * count - (count - 1)} </>}
            -&nbsp;
            {page * count > notesCount ? <>{notesCount} </> : <>{page * count} </>}
            of {notesCount}
          </span>
          <Button
            onClick={pageDecrement}
            variant="basic"
            className="a_p_dec"
            disabled={page * count - (count - 1) === 1}
          >
            <i className="far fa-angle-left" />
          </Button>
          <Button onClick={pageIncrement} variant="basic" className="a_p_inc" disabled={page * count >= notesCount}>
            <i className="far fa-angle-right" />
          </Button>
        </PaginationContainer>
      </>
    ) : (
      <EmptyList className="text-center mt-5">
        <PlaylistIcon>
          <i className="fal fa-sticky-note" />
        </PlaylistIcon>
        <h4>Welcome to Notes</h4>
        <h6>Save and organize your notes.</h6>
      </EmptyList>
    );
  };

  return (
    <div id="notes" className="d-flex overflow-hidden">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Sidebar />
      <Main className="w-100">
        <Navbar />
        <div className="m-4 pt-5 w-100">
          <Container>
            <div className="mt-2">
              <Row>
                <PageTitle>Notes</PageTitle>
                <CourseFilter>
                  <div className="d-flex">
                    <Form.Group as={Col} controlId="filterSpecialty">
                      <div className="d-flex">
                        <FormLabel className="mt-3 mr-2">Filter</FormLabel>
                        <Dropdown
                          values={organizationsList}
                          selectedIds={selectedOrganizationIds}
                          title="Organization"
                          id="filterOrganization"
                          onSelectOptions={selectedIDs => selectOptions(selectedIDs, 1)}
                        />
                        <Row className="ml-4" />
                        <Dropdown
                          values={specialtiesList}
                          selectedIds={selectedSpecialtyIds}
                          id="filterSpecialty"
                          title="Specialty"
                          onSelectOptions={selectedIDs => selectOptions(selectedIDs, 2)}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} controlId="sort">
                      <div className="d-flex">
                        <FormLabel className="mt-3 mr-2">Sort</FormLabel>
                        <SortSelect data-testid="select" onChange={e => changeSort(e.target.value)}>
                          <option value="DESC">Newest</option>
                          <option value="ASC">Oldest</option>
                        </SortSelect>
                      </div>
                    </Form.Group>
                  </div>
                </CourseFilter>
              </Row>
            </div>
            <MobileFilter>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} xs={6} sm={8} controlId="filterSpecialty">
                    <div className="d-flex">
                      <FormLabel className="mt-3 mr-2">Filter</FormLabel>
                      <Dropdown
                        values={organizationsList}
                        selectedIds={selectedOrganizationIds}
                        title="Organization"
                        id="filterOrganization"
                        onSelectOptions={selectedIDs => selectOptions(selectedIDs, 1)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} sm={3} controlId="filterSpecialty">
                    <div className="d-flex" style={{marginLeft: '55px'}}>
                      <Dropdown
                        values={specialtiesList}
                        selectedIds={selectedSpecialtyIds}
                        id="filterSpecialty"
                        title="Specialty"
                        onSelectOptions={selectedIDs => selectOptions(selectedIDs, 2)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group as={Col} sm={3} controlId="sort">
                    <Line />
                    <div className="d-flex mobileSort">
                      <FormLabel className="mt-3 mr-2">Sort</FormLabel>
                      <SortSelect onChange={e => changeSort(e.target.value)}>
                        <option value="DESC">Newest</option>
                        <option value="ASC">Oldest</option>
                      </SortSelect>
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form>
            </MobileFilter>
            {isLoading ? (
              <div className="d-flex vh-100 justify-content-center align-items-center">
                <Spinner animation="grow" />
              </div>
            ) : (
              renderNotes()
            )}
            {(!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) ||
            (account?.access?.status === 'Active' && account?.access?.notes) ? (
              <div id="notes-container">
                {notes?.map(el => (
                  <VideoCard item={el} deleteNote={deleteNote} />
                ))}
              </div>
            ) : (
              <UpgradeModal access="note" isShow={isShowUpgradeModal} onHideModal={() => setShowUpgradeModal(false)} />
            )}
          </Container>
        </div>
        {!isLoading && <Footer />}
      </Main>
    </div>
  );
});

export default Notes;
