import {makeAutoObservable, runInAction} from 'mobx';
import {
  getProfessions,
  getSpecialties,
  getOrganizations,
  getConferenceHeros,
  getConferences,
  createBookmark,
  deleteBookmark,
  followItem,
  unfollowItem,
} from '../../api/conference';
// import type {Conference} from '../../utils/types';
import {getAllPlaylists} from '../../api/video';
import {addConferencePlaylist, addNewConferencePlaylist} from '../../api/playlist';
import {getSortedOrganizations} from '../../api/organization';
import ErrorStore from '../error/errorStore';
import MediaStore from '../media/mediaStore';
import AlertStore from '../alert/alertStore';
import PlaylistStore from '../playlist/playlistStore';

class ConferenceStore {
  errorStore: ErrorStore;

  mediaStore: MediaStore;

  alertStore: AlertStore;

  playlistStore: PlaylistStore;

  professionsList: Array = [];

  selectedProfessionList: Array = [];

  selectedProfessions: Array = [];

  selectedSpecialties: Array = [];

  specialtiesList: Array = [];

  conferenceSpecialtiesList: Array = [];

  selectedSpecialtiesList: Array = [];

  organizationsList: Array = [];

  selectedOrganizationList: Array = [];

  selectedOrganizations: Array = [];

  sortBy: String = 'created_at';

  sortOrder: String = 'DESC';

  isCme: Boolean = false;

  providersList: Array = [];

  conferencesList: Array = [];

  conferenceHeros: Array = [];

  playlist: Array = [];

  conferenceCount: Number = 0;

  count: Number = 25;

  page: Number = 1;

  loading: Boolean = false;

  heroLoading: Boolean = false;

  conference: Array = [];

  searchText: string = '';

  catalogLoading: Boolean = true;

  constructor({errorStore, mediaStore, alertStore, playlistStore}) {
    this.errorStore = errorStore;
    this.mediaStore = mediaStore;
    this.alertStore = alertStore;
    this.playlistStore = playlistStore;
    makeAutoObservable(this);
  }

  changeCount = option => {
    this.count = option;
    this.page = 1;
  };

  pageIncrement = () => {
    this.page += 1;
    this.listConferences();
  };

  pageDecrement = () => {
    this.page -= 1;
    this.listConferences();
  };

  setSearch = value => {
    this.page = 1;
    this.searchText = value;
  };

  resetSearch = () => {
    this.searchText = null;
  };

  setCatalogLoading = value => {
    this.catalogLoading = value;
  };

  addConferenceToPlaylist = async (conferenceId, data) => {
    try {
      const resp = await addConferencePlaylist(conferenceId, data);
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Playlist updated successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addConferenceToNewPlaylist = async (conferenceId, name) => {
    try {
      const resp = await addNewConferencePlaylist(conferenceId, name);
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Playlist created successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setPlaylistObservables = playlist => {
    this.playlist = playlist;
    this.playlistStore.setPlaylist(playlist);
  };

  getAllPlaylists = async () => {
    try {
      const res = await getAllPlaylists();
      if (res.data.status) {
        this.setPlaylistObservables(res.data.playlist);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All professions */
  listProfessions = async () => {
    try {
      const resp = await getProfessions('conference');
      const {professions} = resp.data;
      runInAction(() => {
        this.professionsList = professions;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All specialties */
  listSpecialties = async () => {
    try {
      const resp = await getSpecialties();
      const {specialties} = resp.data;
      runInAction(() => {
        this.specialtiesList = specialties;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All Conference Specialties */
  listConferenceSpecialties = async () => {
    try {
      const resp = await getSpecialties('conference');
      const {specialties} = resp.data;
      runInAction(() => {
        this.conferenceSpecialtiesList = specialties;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All conference providers */
  listProviders = async () => {
    try {
      const resp = await getOrganizations();
      const {organizations} = resp.data;
      runInAction(() => {
        this.providersList = organizations;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set conference organizations list observables
   * @param {array} organizations
   */
  setOrganizationsObservables = organizations => {
    this.organizationsList = organizations;
  };

  /** Fetch All organizations */
  listOrganizations = async () => {
    try {
      const res = await getSortedOrganizations('DESC');
      let {organizations} = res.data;
      organizations = organizations.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setOrganizationsObservables(organizations);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set conferenceHeros observable
   * @param {array} heros
   */
  setConferenceHeroObservable = heros => {
    this.conferenceHeros = heros;
  };

  /**
   * Fetch conference heros
   */
  fetchHeros = async () => {
    try {
      const resp = await getConferenceHeros();
      const {heros} = resp.data;
      this.setConferenceHeroObservable(heros);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set conferences and page list observables
   * @param {array} conferences
   * @param {array} page
   */
  setConferencesObservables = (conferences, count, reload) => {
    if (window.innerWidth <= 576 && !reload) {
      this.conferencesList = [...this.conferencesList, ...conferences];
    } else {
      this.conferencesList = conferences;
    }
    this.conferenceCount = count;
    this.loading = false;
  };

  /**
   * Fetch All conferences
   * @param {array} professions
   * @param {array} specialties
   * @param {array} organizations
   * @param {boolean} isCme
   * @param {string} sortValue
   * @param {string} sortByValue
   * @param {number} pageValue
   */
  listConferences = async (reload = false) => {
    this.loading = true;
    this.catalogLoading = true;
    try {
      if (reload) {
        this.conferencesList = [];
      }
      const resp = await getConferences(
        this.selectedSpecialties,
        this.selectedOrganizations,
        this.selectedProfessions,
        this.isCme ? this.isCme : null,
        this.sortBy,
        this.sortOrder,
        this.page || 1,
        'tile',
        this.count,
        this.searchText ? this.searchText : null,
      );
      const {conferences, count} = resp.data;
      this.setConferencesObservables(conferences, count, reload);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  scrollCatalog = () => {
    if (!this.loading && this.conferenceCount >= this.page * this.count) {
      this.pageIncrement();
    }
  };

  setSelectedProfessions = (Ids, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.selectedProfessions = Ids;
    this.selectedProfessionList = this.professionsList.filter(item => Ids.includes(item.id));
  };

  setSelectedSpecialities = (Ids, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.selectedSpecialties = Ids;
    this.selectedSpecialtiesList = this.conferenceSpecialtiesList.filter(item => Ids.includes(item.id));
  };

  setSelectedOrganizations = (Ids, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.selectedOrganizations = Ids;
    this.selectedOrganizationList = this.organizationsList.filter(item => Ids.includes(item.id));
  };

  setSort = selectedOption => {
    switch (selectedOption) {
      case 'Most Popular':
        this.sortBy = 'title';
        this.sortOrder = 'ASC';
        break;
      case 'Date Added (Newest)':
        this.sortBy = 'created_at';
        this.sortOrder = 'DESC';
        break;
      case 'Date Added (Oldest)':
        this.sortBy = 'created_at';
        this.sortOrder = 'ASC';
        break;
      case 'Name (Z-A)':
        this.sortBy = 'title';
        this.sortOrder = 'DESC';
        break;
      case 'Name (A-Z)':
        this.sortBy = 'title';
        this.sortOrder = 'ASC';
        break;
      case '# of Hrs Descending':
        this.sortBy = 'total_runtime';
        this.sortOrder = 'DESC';
        break;
      case '# of Hrs Ascending':
        this.sortBy = 'total_runtime';
        this.sortOrder = 'ASC';
        break;

      default:
        break;
    }
  };

  setCME = (value, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.isCme = value;
  };

  /** Create new bookmark */
  onCreateBookmark = async conferenceId => {
    const updatesConferences = [...this.conferencesList];
    try {
      const resp = await createBookmark(conferenceId, 'conference');
      const {status, bookmark} = resp.data;
      if (status) {
        const index = updatesConferences.findIndex(conference => conference.id === conferenceId);
        updatesConferences[index].bookmark = [bookmark];
        this.setConferencesObservables(updatesConferences, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onDeleteBookmark = async conference => {
    const {id} = conference;
    const updatesConferences = [...this.conferencesList];
    try {
      const resp = await deleteBookmark(id, 'conference');
      const {status} = resp.data;
      if (status) {
        const index = updatesConferences.findIndex(uConference => uConference.id === id);
        delete updatesConferences[index].bookmark;
        this.setConferencesObservables(updatesConferences, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Remove Profession */
  onRemoveProfession = id => {
    this.selectedProfessionList = this.selectedProfessionList.filter(item => item.id !== id);
    this.selectedProfessions = this.selectedProfessions.filter(item => item !== id);
  };

  onResetProfession = () => {
    this.selectedProfessions = [];
  };

  /** Remove Specialty */
  onRemoveSpecialty = id => {
    this.selectedSpecialtiesList = this.selectedSpecialtiesList.filter(item => item.id !== id);
    this.selectedSpecialties = this.selectedSpecialties.filter(item => item !== id);
  };

  /** Remove Organization */
  onRemoveOrganization = id => {
    this.selectedOrganizationList = this.selectedOrganizationList.filter(item => item.id !== id);
    this.selectedOrganizations = this.selectedOrganizations.filter(item => item !== id);
  };

  /** Follow conference */
  onFollowConference = async conferenceId => {
    const updatesConferences = [...this.conferencesList];
    try {
      const resp = await followItem(conferenceId, 'conference');
      const {status, follow} = resp.data;
      if (status) {
        this.mediaStore.updateCarouselFollow(conferenceId, true, 'conferences');
        const index = updatesConferences.findIndex(conference => conference.id === conferenceId);
        updatesConferences[index].follow = [follow];
        this.setConferencesObservables(updatesConferences, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow conference */
  onUnfollowConference = async conference => {
    const {id} = conference;
    const updatesConferences = [...this.conferencesList];
    try {
      const resp = await unfollowItem(id, 'conference');
      const {status} = resp.data;
      if (status) {
        this.mediaStore.updateCarouselFollow(id, false, 'conferences');
        const index = updatesConferences.findIndex(uConference => uConference.id === id);
        delete updatesConferences[index].follow;
        this.setConferencesObservables(updatesConferences, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default ConferenceStore;
