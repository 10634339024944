import styled from 'styled-components';

export const ResetPasswordModal = styled.div`
  .reset-password-card {
    background-color: #2e3038;
    height: 483px;
    width: 549px;
    border-radius: 16px;
  }
  .card-body {
    width: 340px;
    margin: auto;
  }
  .form-control {
    height: 40px;
    width: 300px;
    margin: auto;
  }
  h5 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.4px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 24px;
  }
  .form-label {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
`;

export const Logo = styled.img`
  filter: invert(1);
  margin: 25px;
`;

export const ResetPasswordButton = styled.button`
  height: 50px;
  width: 300px;
  border-radius: 10px;
  background-color: #0677d8;
  color: #ffffff;
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 23px;
  text-align: center;
  margin: auto;
  border-style: hidden;
  margin-top: 8px;
`;
