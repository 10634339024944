import React from 'react';
import {observer} from 'mobx-react-lite';
import {Container} from 'react-bootstrap';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {RegisterContainer} from './style';
import RegNavBar from './components/RegNavBar';
import RegisterProtectedRoute from './components/RegisterProtectedRoute';
import Specialty from './elements/Specialty';
import Profession from './elements/Profession';
import {RegisterFlow} from './components/RegisterFlow';

const Register = observer(() => {
  const {path} = useRouteMatch();
  return (
    <RegisterContainer>
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <RegNavBar />
      <Container className="py-4 py-sm-4" fluid>
        <Switch>
          <Route exact path={path}>
            <RegisterFlow activeRegistrationStep="plan" />
          </Route>
          <Route exact path={`${path}/account`}>
            <RegisterFlow activeRegistrationStep="account" />
          </Route>
          <Route exact path={`${path}/payment`}>
            <RegisterFlow activeRegistrationStep="payment" />
          </Route>
          <RegisterProtectedRoute exact path={`${path}/profession`}>
            <Profession />
          </RegisterProtectedRoute>
          <RegisterProtectedRoute exact path={`${path}/specialty`}>
            <Specialty />
          </RegisterProtectedRoute>
        </Switch>
      </Container>
    </RegisterContainer>
  );
});

export default Register;
