import styled from 'styled-components';

export const CardWrapper = styled.div`
  & .card {
    overflow: hidden;
    height: 278px;
    width: 248px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #181a21;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    @media (min-width: 768px) and (max-width: 1200px) {
      width: 208px;
    }
    @media screen and (max-width: 375px) {
      width: 160px;
    }
    @media screen and (max-width: 360px) {
      width: 150px;
    }
    @media screen and (max-width: 320px) {
      width: 132px;
    }
  }

  & .card-img {
    width: 103px;
    height: 104px;
    margin: 40px auto 8px;
  }

  & .card-body {
    padding: 0 24px;
    max-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PopoverDiv = styled.div`
  & .card {
    height: 377px;
    width: 336px;
    border: none !important;
    cursor: pointer;
    box-shadow: ${({theme}) =>
      theme.name === 'light' ? '0 8px 12px 0 rgba(0,0,0,0.2)' : '0 4px 10px 0 rgba(0,0,0,0.7)'};
    background-color: #181a21;
  }

  & .card-img {
    width: 160px;
    height: 160px;
    margin: 35px auto auto;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  color: ${({theme}) => (theme.name === 'light' ? '#454647' : '#f9f9f9')};
  letter-spacing: 0;
  line-height: 16px;
  margin: auto auto 4px;
  text-align: center;
  font-size: 14px;
  width: fit-content;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 32px;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
`;

export const Text = styled.div`
  font-size: 12px;
  color: ${({theme}) => (theme.name === 'light' ? '#616369' : '#f8f8f8')};
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  width: fit-content;
  margin: auto;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 54px;

  @media (max-width: 1200px) {
    font-size: 10px;
  }
`;

export default {CardWrapper, PopoverDiv, Title, Text};
