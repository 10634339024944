import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Dropdown, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {observer} from 'mobx-react-lite';
import Moment from 'react-moment';
import {
  DashboardMain,
  AccessLabel,
  SearchUser,
  CustomCheckbox,
  PaginationContainer,
  UpdateModal,
  Label,
  SelectContainer,
} from '../../elements/dashboardStyle';
import {useStore} from '../../../../store';
import EditIcon from '../../elements/edit.svg';
import EditIconWhite from '../../elements/pen-white.svg';
import DeleteIcon from '../../elements/delete.svg';
import SortArrow from '../../elements/sortArrow.svg';
import PlusIcon from '../../elements/follow.svg';

const Dashboard = observer(() => {
  const {accessStore} = useStore();

  const {
    accessUsers,
    getManagedDetail,
    managedAccesses,
    updateUnclaimedAccess,
    emailEdit,
    setEmailEdit,
    addAccessHistory,
    setAccessId,
    accessId,
    deleteAccessHistory,
    userPageIncrement,
    userPageDecrement,
    userPage,
    userCount,
    count,
    setCount,
    resetPage,
    setSearch,
    resetSearch,
    searchText,
    totalLicense,
    access,
    changeAccess,
    accessHistoryId,
    setAccessHistory,
    resetAccessHistory,
    addUserCompleted,
    addUserLoading,
    setAddUserCompleted,
    expire,
    setAddUserError,
    addUserError,
    sort,
    setSort,
  } = accessStore;

  const [edit, setEdit] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);
  const handleDeleteModalClose = () => setShowDeleteModal(false);

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const handleAddUserModalShow = () => setShowAddUserModal(true);
  const handleAddUserModalClose = () => {
    setShowAddUserModal(false);
    if (addUserError) {
      setAddUserError('');
      getManagedDetail();
    }
  };

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleSuccessModalShow = () => setShowSuccessModal(true);
  const handleSuccessModalClose = () => setShowSuccessModal(false);

  const [showExceedModal, setShowExceedModal] = useState(false);
  const handleExceedModalShow = () => setShowExceedModal(true);
  const handleExceedModalClose = () => setShowExceedModal(false);

  const [searchValue, setSearchValue] = useState('');

  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    accessStore.fetchManaged();
  }, [accessStore]);

  useEffect(() => {
    changeAccess(managedAccesses[0]?.id);
  }, [managedAccesses, changeAccess]);

  useEffect(() => {
    setAllChecked(accessUsers.every(item => accessHistoryId.includes(item.accessHistories[0].id)));
  }, [accessHistoryId, accessHistoryId.length, accessUsers]);

  const sortTable = value => {
    if (sort && sort[0] === value && sort[1] === 'asc') {
      setSort([value, 'desc']);
    } else {
      setSort([value, 'asc']);
    }
  };

  const onEnter = (e, id) => {
    if (e.key === 'Enter') {
      updateUnclaimedAccess(id, e.target.value);
    }
  };

  const {register, handleSubmit, watch, errors} = useForm({
    mode: 'onChange',
  });
  const watchField = watch('addmail');

  const addUser = data => {
    const emails = data.addmail.replace(/\s/g, '');
    addAccessHistory(emails);
    if (addUserError) setAddUserError('');
  };

  useEffect(() => {
    if (addUserCompleted) {
      handleSuccessModalShow();
      handleAddUserModalClose();
      setAddUserCompleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserCompleted]);

  const handleSubmitAddUser = () => {
    handleAddUserModalClose();
  };

  let access_name = null;
  let addUserAccess = null;

  if (managedAccesses) {
    access_name = managedAccesses?.find(item => item.id === parseInt(access, 10));
    if (accessId) {
      addUserAccess = managedAccesses?.find(item => item.id === parseInt(accessId, 10));
    }
  }

  const deleteUser = id => {
    handleDeleteModalShow();
    accessHistoryId.push(id);
  };

  const handleDelete = () => {
    deleteAccessHistory(accessHistoryId);
    handleDeleteModalClose();
  };

  const deleteMultipleUser = (e, id) => {
    if (e.target.checked) {
      setAccessHistory(id);
    } else {
      const index = accessHistoryId.indexOf(id);
      setAccessHistory(index, true);
      if (accessHistoryId.length < 1) {
      }
    }
  };

  const selectAll = e => {
    if (e.target.checked) {
      accessUsers.map(item => setAccessHistory(item.accessHistories[0].id));
    } else {
      resetAccessHistory();
    }
  };

  useEffect(() => {
    getManagedDetail(access);
  }, [getManagedDetail, access, userPage, count, searchText, sort]);

  const onSearch = event => {
    const value = event?.target?.value;
    setSearchValue(value);
    if (value) {
      setSearchValue(value);
    } else {
      resetSearch();
    }
  };

  const onSubmit = () => {
    if (searchValue) {
      setSearch(searchValue);
    }
  };

  const searchKeyUpHandler = event => {
    event.preventDefault();
    if (searchValue && searchValue.length <= 200 && event.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    if (totalLicense && userCount && totalLicense - userCount === 0) {
      handleExceedModalShow();
    }
  }, [totalLicense, userCount]);

  return (
    <DashboardMain>
      <Row>
        <Col md={3}>
          <AccessLabel>Select Access</AccessLabel>
          <SelectContainer className="sort-mobile">
            <Dropdown>
              <Dropdown.Toggle variant="basic" id="access" className="a_access">
                {access || access === 0 ? access_name?.access_name : '--'}
              </Dropdown.Toggle>

              <Dropdown.Menu
                onChange={e => {
                  changeAccess(e.target.value);
                  resetPage();
                }}
              >
                {managedAccesses?.map(
                  (value, index) =>
                    value?.AccessManager?.id && (
                      <Form.Check
                        type="radio"
                        label={value.access_name}
                        id={`${index}_${value.id}`}
                        value={value.id}
                        name="sort"
                        checked={parseInt(access, 10) === value.id}
                      />
                    ),
                )}
              </Dropdown.Menu>
            </Dropdown>
          </SelectContainer>
        </Col>
        <Col md={6}>
          <SearchUser>
            <Form.Row className="input-group form-inline">
              <div className="input-group-prepend">
                <button className="btn btn-outline-secondary search-button" type="button" onClick={onSubmit}>
                  <i className="far fa-search" />
                </button>
              </div>
              <input
                id="search"
                defaultValue={searchValue}
                className="form-control pl-0"
                type="search"
                data-testid="search-input"
                placeholder="Search Users"
                aria-label="Search Users"
                name="search"
                onChange={onSearch}
                onKeyUp={searchKeyUpHandler}
                autoComplete="off"
              />
            </Form.Row>
          </SearchUser>
        </Col>
        <Col className="licence">
          <table id="licence-table">
            <tr>
              <td>
                <h6>Total License</h6>
              </td>
              <td>
                <h6>{totalLicense == null ? '--' : totalLicense}</h6>
              </td>
            </tr>
            <tr>
              <td>
                <p>License Used</p>
              </td>
              <td>
                <p>{userCount}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>License Remaining</p>
              </td>
              <td>
                <p>{totalLicense == null ? '--' : totalLicense - userCount}</p>
              </td>
            </tr>
          </table>
        </Col>
      </Row>
      <Row>
        <table id="myTable">
          <tr>
            <th>
              <Label htmlFor="select-all" className="checkLabel">
                <input type="checkbox" id="select-all" checked={allChecked} onChange={e => selectAll(e)} />
                <CustomCheckbox id="selectall-icon" />
              </Label>
            </th>
            <th onClick={() => sortTable('first_name')}>
              First Name
              <img src={SortArrow} alt="" className="table-sort-arrow" />
            </th>
            <th onClick={() => sortTable('last_name')}>
              Last Name
              <img src={SortArrow} alt="" className="table-sort-arrow" />
            </th>
            <th onClick={() => sortTable('email')}>
              Email
              <img src={SortArrow} alt="" className="table-sort-arrow" />
            </th>
            <th>Access</th>
            <th>Date Added</th>
            <th>Expiration Date</th>
            <th onClick={() => sortTable('is_registration_completed')}>
              Status
              <img src={SortArrow} alt="" className="table-sort-arrow" />
            </th>
            <th colSpan="2">
              <button
                className="btn btn-primary a_addUser"
                type="button"
                onClick={() => {
                  if (totalLicense && userCount && totalLicense - userCount === 0) {
                    handleExceedModalShow();
                  } else {
                    handleAddUserModalShow();
                  }
                }}
              >
                <img src={PlusIcon} alt="" />
                Add user
              </button>
            </th>
          </tr>
          {accessUsers?.map(item => (
            <tr>
              <td>
                <Label htmlFor={item.id} className="checkLabel" key={item.id}>
                  <input
                    type="checkbox"
                    id={item.id}
                    checked={accessHistoryId.includes(item.accessHistories[0].id)}
                    onChange={e => deleteMultipleUser(e, item.accessHistories[0].id)}
                  />
                  <CustomCheckbox />
                </Label>
              </td>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>
                {edit === item.id && emailEdit
                  ? [
                      <input
                        type="text"
                        placeholder="Enter email"
                        defaultValue={item.email}
                        name="addmail"
                        ref={register({
                          pattern: {
                            value: /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,},?)+$/i,
                            message: 'Please enter a correct email.',
                          },
                        })}
                        className={`${errors.addmail ? 'error-text' : 'fill-text'}`}
                        watch={watchField}
                        onKeyUp={e => onEnter(e, item.accessHistories[0].id)}
                      />,
                      errors.addmail && <small className="text-red d-block">{errors.addmail.message}</small>,
                    ]
                  : item.email}
              </td>
              <td>{access_name?.access_name}</td>
              <td>
                <Moment format="MM/DD/YY">{item.created_at}</Moment>
              </td>
              <td>
                <Moment format="MM/DD/YY">{expire}</Moment>
              </td>
              <td>
                {item.is_registration_completed ? (
                  <span className="text-primary">Claimed</span>
                ) : (
                  <span className="text-unclaim">Unclaimed</span>
                )}
              </td>
              <td>
                <button
                  type="button"
                  className="btn a_edit"
                  onClick={() => {
                    setEdit(item.id);
                    setEmailEdit(true);
                  }}
                  disabled={item.is_registration_completed}
                >
                  <img src={item.is_registration_completed ? EditIcon : EditIconWhite} alt="edit" />
                </button>
              </td>
              <td>
                <button type="button" className="btn a_delete" onClick={() => deleteUser(item.accessHistories[0].id)}>
                  <img src={DeleteIcon} alt="delete" />
                </button>
              </td>
            </tr>
          ))}
        </table>
      </Row>
      <Row>
        <Col className="pagination-container">
          <Button className="a_mass-delete" disabled={!accessHistoryId.length} onClick={() => handleDeleteModalShow()}>
            <img src={DeleteIcon} alt="delete" />
            Remove selected
          </Button>
        </Col>
        <Col>
          <PaginationContainer>
            <span>
              {userCount === 0 ? <>0 </> : <>{userPage * count - (count - 1)} </>}-&nbsp;
              {userPage * count > userCount ? <>{userCount} </> : <>{userPage * count} </>}
              of {userCount}
            </span>
            <Button
              onClick={userPageDecrement}
              variant="basic"
              className="a_p_dec"
              disabled={userPage * count - (count - 1) === 1}
            >
              <i className="far fa-angle-left" />
            </Button>
            <Button
              onClick={userPageIncrement}
              variant="basic"
              className="a_p_inc"
              disabled={userPage * count >= userCount}
            >
              <i className="far fa-angle-right" />
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
                <i className="fas fa-ellipsis-v" />
              </Dropdown.Toggle>

              <Dropdown.Menu onChange={e => setCount(e.target.value)}>
                <Form.Check
                  type="radio"
                  label="10 per page"
                  id="limit_10"
                  value="10"
                  name="count"
                  checked={parseInt(count, 10) === 10}
                />
                <Form.Check
                  type="radio"
                  label="25 per page"
                  id="limit_25"
                  value="25"
                  name="count"
                  checked={parseInt(count, 10) === 25}
                />
                <Form.Check
                  type="radio"
                  label="50 per page"
                  id="limit_50"
                  value="50"
                  name="count"
                  checked={parseInt(count, 10) === 50}
                />
                <Form.Check
                  type="radio"
                  label="100 per page"
                  id="limit_100"
                  value="100"
                  name="count"
                  checked={parseInt(count, 10) === 100}
                />
              </Dropdown.Menu>
            </Dropdown>
          </PaginationContainer>
        </Col>
      </Row>

      {/* delete user modal */}
      <UpdateModal show={showDeleteModal} onHide={handleDeleteModalClose} className="userDeleteModal">
        <UpdateModal.Body>
          <Form>
            <p>Are you sure you would like to remove user?</p>
            <div className="w-100">
              <Button
                type="button"
                onClick={() => handleDeleteModalClose()}
                variant="outline-secondary"
                id="not-remove-user"
                className="a_deleteUserButton"
              >
                No
              </Button>
              <Button
                type="button"
                onClick={() => handleDelete()}
                variant="primary"
                id="remove-user-btn"
                className="a_deleteUserButton"
              >
                Yes
              </Button>
            </div>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>

      {/* Add user modal */}
      <UpdateModal show={showAddUserModal} onHide={handleAddUserModalClose} className="userAddModal">
        <UpdateModal.Header>Add new user</UpdateModal.Header>
        <UpdateModal.Body>
          <Form onSubmit={handleSubmit(addUser)}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Enter email here for new account:</Form.Label>
                <input
                  type="text"
                  name="addmail"
                  ref={register({
                    pattern: {
                      value: /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,},?(\s*?))+$/i,
                      message: 'Please enter a correct email.',
                    },
                  })}
                  className={`${errors.addmail ? 'error-text' : 'fill-text'}`}
                  watch={watchField}
                />
                {errors.addmail ? (
                  <small className="text-red">{errors.addmail.message}</small>
                ) : (
                  <small>For mulitiple emails, separate emails by comma.</small>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Select access</Form.Label>
                <SelectContainer id="add-user-access">
                  <Dropdown>
                    <Dropdown.Toggle variant="basic" id="access" className="a_userAccess">
                      {accessId ? addUserAccess?.access_name : '--'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu onChange={e => setAccessId(e.target.value)}>
                      {managedAccesses?.map(
                        (value, index) =>
                          value?.AccessManager?.id && (
                            <Form.Check
                              type="radio"
                              label={value.access_name}
                              id={`user_${index}_${value.id}`}
                              value={value.id}
                              name="sort"
                              checked={parseInt(accessId, 10) === value.id}
                            />
                          ),
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </SelectContainer>
              </Form.Group>
            </Form.Row>
            {addUserError && (
              <Form.Row>
                <Form.Group as={Col}>
                  <small className="text-red">{addUserError}</small>
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col} className="text-right">
                <Button
                  type="button"
                  variant="outline-secondary"
                  id="cancel-add-user"
                  onClick={() => handleSubmitAddUser()}
                  className={`${!watchField || errors.addmail ? 'a_cancelAddButton' : 'active-btn a_cancelAddButton'}`}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  id="submit-add-user"
                  className={`${
                    !watchField || errors.addmail ? 'a_submitUserButton' : 'active-btn a_submitUserButton'
                  }`}
                  disabled={!accessId || addUserLoading}
                >
                  Submit
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>

      {/* Success user modal */}
      <UpdateModal show={showSuccessModal} onHide={handleSuccessModalClose} className="successModal">
        <UpdateModal.Header>Success</UpdateModal.Header>
        <UpdateModal.Body>
          <Form>
            <p>New user has been added.</p>
            <div className="w-100">
              <Button
                type="button"
                variant="primary"
                id="success-user"
                className="a_successUserButton"
                onClick={handleSuccessModalClose}
              >
                Done
              </Button>
            </div>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>

      {/* Exceed licence modal */}
      <UpdateModal show={showExceedModal} onHide={handleExceedModalClose} className="exceedModal">
        <UpdateModal.Body>
          <Form>
            <p>
              <strong>
                You have exceeded your current
                <br />
                purchased licenses.
              </strong>
            </p>
            <p>
              please contact Customer Support at <a href="mailto:support@giblib.com">support@giblib.com</a>
            </p>
            <div className="w-100">
              <Button
                type="button"
                onClick={() => handleExceedModalClose()}
                variant="primary"
                id="remove-user-btn"
                className="a_purchaseButton"
              >
                close
              </Button>
            </div>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>
    </DashboardMain>
  );
});

export default Dashboard;
