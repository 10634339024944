import styled from 'styled-components';

export const SearchContainer = styled.div`
  z-index: 100 !important;
  position: absolute;
  width: 480px;
  max-height: calc(100vh - 60px);
  margin-left: 215px;
  margin-top: 13px;
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#252730')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
  @media (min-width: 768px) and (max-width: 1199px) {
    margin-left: 65px;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    max-width: 375px;
    margin-left: 0px;
    margin-top: 56px;
    padding: 24px;
    max-height: none;

    .a_searchDropdownVideoText {
      text-align: left;
      width: -webkit-fill-available;
    }
  }

  .restrict-search-text {
    float: right;
    margin-right: 10%;
    margin-top: 1px;
    letter-spacing: 0.1px;
    font-size: 12px;
    font-weight: 500;
  }

  .nodisplay {
    display: none;
  }

  .a_searchHeader {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#ffffff')};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
    padding: 16px 16px 8px;
    margin-bottom: 0;

    @media screen and (max-width: 576px) {
      padding: 0 0 18px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
  }

  .a_searchBody {
    @media screen and (max-width: 576px) {
      padding: 30px 0 18px;
    }
  }

  .seeMore-div {
    width: 100%;
    text-align: center;
  }
  .a_seeMore {
    width: 92px;
    text-align: center !important;
    color: #fff;
    margin-bottom: 0px;
    padding: 4px 16px;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    border: none;
    background: #008aff;
    opacity: 0.85;
    border-radius: 3px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    :hover {
      opacity: 1;
    }
  }
`;

export const SearchOptions = styled.button`
  padding: 8px;
  height: 99.24px;
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  box-shadow: inset 0 -1px 0 0 #4e505a;
  z-index: 5;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  width: 100%;

  .a_roundDiv {
    width: 48px;
    height: 48px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    background: #ffffff;
  }

  .a_rectangleDiv {
    width: 85px;
    height: 70px;
    background: grey;
    object-fit: cover;
  }

  @media screen and (max-width: 576px) {
    width: 100%;

    .a_rectangleDiv {
      width: 124px;
      margin-right: 5.5px;
    }
  }
`;

export const OptionText = styled.div`
  margin-bottom: 0px;
  margin-left: 6.5px;
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#ffffff')};
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 22px;
  height: 100%;
  @media screen and (max-width: 576px) {
    width: calc(100% - 147px);
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 36px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
`;

export const Type = styled.span`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: -0.16px;
  line-height: 22px;
`;

export const Description = styled.div`
  width: 350px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: -0.16px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 22px;
  img {
    margin-right: 10px;
  }
  span {
    p {
      display: inline;
    }
  }
  .fa-head-vr {
    color: #ffea64;
    width: 13px;
    margin-right: 10px;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;
