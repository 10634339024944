import styled from 'styled-components';

const Button = styled('button')`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  line-height: 1;

  .cme-download {
    color: #fff;
    font-size: 16px;
  }

  .video-download {
    color: ${({theme}) => theme.card_text};
    font-size: 16px;
  }

  .fa-download {
    color: rgb(184, 187, 190);
  }
`;

export default Button;
