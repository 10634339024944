import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Spinner} from 'react-bootstrap';
import {useHistory, useRouteMatch, Redirect, Route, Switch} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import {Main, NoConferenceMessage, Body} from './elements/conferenceLanding';
import ConferenceDescription from './components/ConferenceDescription';
import {useStore} from '../../store';
import AlertComponent from '../../components/AlertComponent';
import {amplitude} from '../../utils/Amplitude';
import LandingPageInfo from '../../components/LandingPageInfo';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageTab from '../../components/PageTab';
import LandingVideoCard from '../../components/LandingVideoCard';
import LocalStorageService from '../../utils/LocalStorageService';
import LandingCard from '../../components/LandingCard';
import CircleProgressBar from '../../components/CircleProgressBar';
import Footer from '../../components/Footer';

// ConferenceLanding page
const ConferenceLanding = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const match = useRouteMatch();
  const location = useHistory();
  const conferenceId = match.params?.id;
  const {conferenceLandingStore, alertStore, videoStore} = useStore();
  const {
    conference,
    getConferenceData,
    conferenceVideos,
    relatedConferences,
    onFollowRelatedConferences,
    onUnfollowRelatedConferences,
    onBookmarkConferenceVideo,
    onDeleteBookmarkConferenceVideo,
    onBookmarkRelatedConferences,
    onDeleteBookmarkRelatedConferences,
    playlist,
    getAllPlaylists,
  } = conferenceLandingStore;
  const {setReferrer} = videoStore;

  const {message, status, resetAlert} = alertStore;

  const loggedInUser = LocalStorageService.getUser();

  const TabNavItems = [
    {
      id: 1,
      className: 'a_conferenceList',
      linkTo: `${match.url}/videos`,
      name: 'Vidoes',
    },
    {
      id: 2,
      className: 'a_relatedConferences',
      linkTo: `${match.url}/related-conferences`,
      name: 'Related Conferences',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const amplitudeMediaEvent = title => {
    const eventProperties = {
      'conference name': title,
    };
    amplitude.getInstance().logEvent('conference-page-viewed', eventProperties);
  };

  useEffect(() => {
    setIsLoading(true);
    getConferenceData(conferenceId, title => {
      setIsLoading(false);
      amplitudeMediaEvent(title);
    });
    // eslint-disable-next-line
  }, [conferenceId, getConferenceData]);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  const onHandleFollow = item => {
    if (loggedInUser) {
      if (item.follow?.length) {
        onUnfollowRelatedConferences(item.id);
      } else {
        onFollowRelatedConferences(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  const onHandleBookmark = item => {
    if (loggedInUser) {
      if (item.bookmark?.length) {
        onDeleteBookmarkRelatedConferences(item.id);
      } else {
        onBookmarkRelatedConferences(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  const gotoConferenceDetail = (id, order) => {
    setReferrer('conference page');
    location.push(`/video/${id}?conference_id=${conferenceId}&order=${order}`);
  };

  const onHandleVideoBookmark = item => {
    if (loggedInUser) {
      if (item.bookmark && (item.bookmark.id || item.bookmark.length)) {
        onDeleteBookmarkConferenceVideo(item.id);
      } else {
        onBookmarkConferenceVideo(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  const bookmarkConferenceVideo = (event, selectedItem) => {
    event.stopPropagation();
    onHandleVideoBookmark(selectedItem);
  };

  return (
    <div id="conferenceLanding" className="d-flex flex-column flex-fill">
      <Helmet
        title={`${conference.title}`}
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: conference.organization?.thumbnail,
          },
          {
            name: 'image',
            property: 'og:image',
            content: conference.organization?.thumbnail,
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <div>
          <div className="d-flex overflow-hidden">
            {!isLoading && conference.id && (
              <Main>
                {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
                <div>
                  <Breadcrumbs
                    parentActivecrumb_name="Conferences"
                    parentActivecrumb_url="/conferences"
                    activeCrumb_name={conference.title}
                  />
                </div>
                <CircleProgressBar item={conference} videos={conferenceVideos} />
                <div className="d-sm-flex mt-sm-5">
                  <div>
                    <LandingPageInfo
                      type="conference"
                      item={conference}
                      playlist={playlist}
                      landingVideos={conferenceVideos}
                    />
                  </div>
                  <Body className="w-100">
                    <PageTab TabNavItems={TabNavItems} />
                    <div id="conference-landing-contents">
                      <Switch>
                        <Route
                          path={`${match.path}/videos`}
                          render={() => (
                            <>
                              {(conferenceVideos || []).map(vList => (
                                <LandingVideoCard
                                  item={vList.video}
                                  goToVideoDetail={() => gotoConferenceDetail(vList.video.id, vList.order)}
                                  playlist={playlist}
                                  bookmarkLandingVideo={bookmarkConferenceVideo}
                                />
                              ))}
                            </>
                          )}
                        />
                        <Route
                          exact
                          path={`${match.path}/description`}
                          render={() => <ConferenceDescription text={conference ? conference.description : ''} />}
                        />
                        <Route
                          exact
                          path={`${match.path}/related-conferences`}
                          render={() => (
                            <>
                              {(relatedConferences || []).map(conferencelist => (
                                <LandingCard
                                  whichType="conference"
                                  type={conferencelist}
                                  key={conferencelist.id}
                                  onFollow={onHandleFollow}
                                  onBookmark={onHandleBookmark}
                                  playlist={playlist}
                                  playlistStore="conferenceStore"
                                />
                              ))}
                            </>
                          )}
                        />
                        <Redirect to={`${match.path}/videos`} />
                      </Switch>
                    </div>
                  </Body>
                </div>
              </Main>
            )}{' '}
            {!isLoading && !conference.id && (
              <div className="m-auto justify-content-center">
                <NoConferenceMessage>
                  Some content has been reorganized to make things easier to find. <br /> If accessing this page from an
                  old link, please use the browse menu to find your desired content.
                </NoConferenceMessage>
              </div>
            )}
            {isLoading && (
              <div className="m-auto d-flex justify-content-center">
                <Spinner animation="grow" />
              </div>
            )}
          </div>
          {!isLoading && <Footer />}
        </div>
      </div>
    </div>
  );
});

export default ConferenceLanding;
