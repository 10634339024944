import styled from 'styled-components';
import {Modal} from 'react-bootstrap';

export const DashboardMain = styled.div`
  background: #181a21;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 23px 16px 0;
  margin-bottom: 50px;

  .text-red {
    color: #ff5964;
  }

  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-right: 10px;
  }

  #myTable {
    border-collapse: collapse;
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-size: 12px;
    line-height: 17px;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.1);
  }

  #myTable th {
    font-weight: bold;
    font-size: 14px;
  }

  #myTable th,
  #myTable td {
    text-align: left;
    padding: 4px 6px;

    &:nth-last-child(2),
    &:last-child {
      width: 6%;
      text-align: center;
    }
  }

  #myTable th {
    padding-bottom: 12px;
  }

  #myTable td img {
    width: 17px;
  }

  #myTable td {
    input[type="text"]{
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 3px;
      color: #fff;
      background: transparent;
      height: 27px;
      padding: 4px 16px;
      font-size: 14px;
      line-height: 17px;
      width: 155px;

      :focus,
      :focus-visible {
        border: 1px solid #008aff;
        outline: none !important;
      }
      &.error-text {
        border-color: #ff5964;
      }
    }
  }

  .text-unclaim {
    color: #ff5964;
  }

  #myTable > tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    position: relative;
  }

  #myTable > tr:first-child {
    background: #181a21;
  }

  #myTable > tr:not:first-child {
    background: rgba(24, 26, 33, 0.1);
  }

  #myTable tr {
    input[type='checkbox'] {
      visibility: hidden;
      display: none;
    }

    input:checked ~ span {
      background-color: #008aff;
      border-color: #008aff;
    }

    input:checked ~ span:after {
      display: block;
    }

    span:after {
      left: 5px;
      bottom: 3px;
      width: 7px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    #select-all ~ span:after {
      left: 5px;
      bottom: 3px;
      width: 7px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: #74767a;
      display: block;
      content: '';
    }
    #select-all:checked ~ span:after {
      background-color: #008aff;
    }
  }

  .table-sort-arrow {
    margin-left: 5px;
    width: 9px;
  }

  .licence p {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
  }
  .licence h6 {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
  }

  .licence {
    text-align: right;
    table {
      display: inline-block;
      text-align: left;
      min-width: 145px;
    }
  }

  .a_addUser {
    margin-top; 12px;
  }

  .a_mass-delete {
    width: 165px;
    height: 36px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    border: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;

    &:hover {
      background: #008aff;
    }

    img {
      width: 14px;
      margin-right: 9px;
      vertical-align: bottom;
    }
  }

  .pagination-container {
    padding: 16px 13px;
  }

  .a_addUser {
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    padding: 9px;
    background-color: #008aff;
    opacity: 0.85;
    &:hover {
      background-color: #008aff;
      opacity: 1;
    }
    img {
      margin-right: 9px;
      vertical-align: bottom;
    }
  }

  #licence-table tr td:first-child{
    padding-right: 32px;
  }
`;

export const Select = styled.select`
  width: 231px;
  height: 36px;

  border: 1px solid #008aff;
  box-sizing: border-box;
  border-radius: 3px;
  background: transparent;
  color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 9px 16px;
  font-size: 14px;
  line-height: 17px;

  option {
    font-size: 14px;
    line-height: 17px;
  }

  &#add-user-access {
    border: 1px solid rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 36px;

    :focus,
    :focus-visible {
      border: 1px solid #008aff;
      outline: none !important;
    }
  }
`;

export const AccessLabel = styled.label`
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
`;

export const SearchUser = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .input-group.form-inline {
    margin-bottom: 23px;
    width: 335px;
  }

  .search-button {
    border: none;
    background: rgba(255, 255, 255, 0.1);
  }

  input {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    border: none;
    width: 335px;
    height: 36px;
    padding: 11px 11px 11px 48px;
    color: #fff;
    margin-left: 1px;
    font-size: 14px;
    line-height: 14px;

    &:focus {
      color: #fff;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const CustomCheckbox = styled.span`
  position: absolute;
  top: calc(50% - 10px);
  left: 15px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #008aff;
  border-radius: 2px;

  &#selectall-icon {
    background-color: #74767a;
    border-color: #74767a;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const PaginationContainer = styled.div`
  justify-content: right;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  label {
    width: 100%;
    padding: 7px;
  }
  .form-check-input {
    display: none;
  }
  input:checked ~ label::after {
    content: '';
    left: 10px;
    bottom: 10px;
    width: 6px;
    height: 11px;
    border: solid white;
    position: absolute;
    transform: rotate(45deg);
    display: block;
    border-width: 0 2px 2px 0;
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  span {
    font-size: 12px;
  }
  .a_limit,
  .a_p_dec,
  .a_p_inc {
    padding: 0;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 16px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
  }
  .a_limit {
    font-size: 12px;
    padding-left: 4px;
    padding-top: 2px;
  }
  .a_limit:hover,
  .a_limit:active,
  .a_limit:focus {
    font-size: 12px;
    background-color: #252730;
    color: #dcdee1;
  }
  .dropdown-menu {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 125px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 16px;
    min-width: 7rem;
  }
  .dropdown {
    display: inline-block;
  }
  #limitMenu::after {
    border: none;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const UpdateModal = styled(Modal)`
  backdrop-filter: blur(20px);
  background: rgba(24, 26, 33, 0.4);

  .text-red {
    color: #ff5964;
  }

  .modal-dialog {
    max-width: 248px;
    margin: 0 auto !important;
    text-align: center;
    top: calc(50% - 73px);
  }
  .modal-content {
    border: none !important;
    background-color: #181a21;
    border-radius: 8px;
    padding-top: 24px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-footer {
      border: none !important;
    }
    input[type='text'] {
      background: #65686e;
      border: none;
      color: #fff;
      font-size: 14px;
      height: 40px;
      font-weight: 500;
      letter-spacing: -0.09px;
      line-height: 24px;
    }
    input[type='text']::placeholder {
      color: #fff;
    }
    label {
      font-weight: bold;
      margin-bottom: 18px;
      letter-spacing: -0.16px;
      line-height: 20px;
    }
    .close {
      color: ${({theme}) => theme.circle};
      opacity: 1;
      font-weight: 400;
    }
    .btn-outline-secondary {
      border-color: #4e505a;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.11px;
      padding: 3px 16px;
    }
  }

  &.userDeleteModal .modal-body {
    padding-top: 0;
  }
  &.userDeleteModal p {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
  }
  &.userDeleteModal .a_deleteUserButton {
    margin: 15px 6px;
    color: ${({theme}) => theme.circle};
    width: 55px;
    height: 36px;
  }

  &.exceedModal .modal-dialog {
    max-width: 336px;
    padding: 24px;

    .modal-body {
      padding-top: 0;
    }

    p {
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    button {
      width: 55px;
      height: 36px;
      font-size: 14px;
      line-height: 14px;
    }
    .a_purchaseButton {
      background: #008aff;
      opacity: 0.9;
      margin-left: 12px;
      &:hover {
        opacity: 1;
      }
    }
  }

  &.successModal .modal-dialog {
    padding: 24px 24px;
    text-align: center;
    max-width: 248px;

    .modal-header {
      padding-top: 0;
      padding-bottom: 16px;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      background: linear-gradient(to right, #0dfff3 0%, #008aff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      justify-content: center;
    }
    .modal-body {
      padding-top: 0;

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 20px;
      }

      button {
        width: 65px;
        height: 36px;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        opacity: 0.85;
        background-color: #008aff;
        &#success-user:hover {
          opacity: 1;
          background-color: #008aff;
        }
      }
    }
  }

  &.userAddModal .modal-dialog {
    max-width: 336px;
    padding: 24px 19px;
    text-align: left;

    .modal-header {
      padding-top: 0;
      padding-bottom: 24px;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      background: linear-gradient(to right, #0dfff3 0%, #008aff 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .modal-body {
      padding-top: 0;

      label {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 4px;
      }
      small {
        display: block;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        margin-top: 4px;
      }

      input {
        border: 1px solid rgba(255, 255, 255, 0.4);
        width: 100%;
        background: transparent;
        height: 36px;
        border-radius: 3px;
        color: #fff;
      }
      input:focus,
      input:focus-visible {
        border: 1px solid #008aff;
        outline: none !important;
      }

      button {
        width: 77px;
        height: 36px;
        border-radius: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        padding: 1px;
        background-image: none;
        opacity: 1;
        background: transparent;

        &:hover {
          border: none;
          background-clip: content-box, border-box;
          background-image: linear-gradient(#181a21, #181a21), linear-gradient(to right, #0dfff3, #008aff);
          background-origin: border-box;
        }
      }
      #submit-add-user {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        margin-left: 8px;
        border: none;
        &.active-btn,
        &:hover {
          background: #008aff;
        }
        &.active-btn {
          opacity: 0.9;
        }
        &.active-btn:hover {
          opacity: 1;
        }
      }
      .error-text {
        border-color: #ff5964;
      }

      .fill-text {
        border-color: #008aff;
      }
    }
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 15px !important;
  line-height: 19px;
  padding-top: 7px !important;
  padding-bottom: 7px;
  margin-bottom: 0;
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  position: relative;
  // padding-left: 20px;
  padding-left: 30px;
  cursor: pointer;
`;

export const SelectContainer = styled.div`
  .a_access,
  .a_userAccess {
    background: transparent;
    padding: 9px 30px 8px 16px;
    border-radius: 3px;
    position: relative;
    border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
    color: #fff;
    width: 231px;
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    ::after {
      right: 8px;
      position: absolute;
      top: calc(50% - 0.25em);
      border-top: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-bottom: 0;
      border-left: 0.5em solid transparent;
    }
    ~ .dropdown-menu {
      width: 231px;
      background: #2f3137;
    }
  }
  #access.a_userAccess {
    height: 36px;
    width: 100%;
    background-image: none;
    border: 1px solid #008aff;
    border-radius: 3px;
    font-weight: 300;
    font-size: 14px;
    padding-left: 10px;
  }
  &#add-user-access .dropdown-menu {
    width: 100%;
    background: #2f3137;
    a {
      color: #fff;
      &:hover,
      &:active {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
  position: relative;
  label {
    width: 100%;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }
  .form-check-input {
    display: none;
  }
  .form-check {
    padding: 5px 0px 4px 43px;
    border: 1px solid transparent;
  }
  input ~ label::after {
    content: '';
    left: 16px;
    bottom: 10px;
    width: 16px;
    height: 16px;
    border: 2px solid #008aff;
    position: absolute;
    display: block;
    border-radius: 50px;
    top: calc(50% - 8px);
  }
  input:checked ~ label::before {
    position: absolute;
    display: block;
    border-radius: 50px;
    content: '';
    left: 14px;
    bottom: 8px;
    border: 2px solid rgba(0, 138, 255, 0.5);
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
  }
  input:checked ~ label {
    color: #008aff;
  }
  input:checked ~ label::after {
    border: 4px solid #008aff;
  }
  .form-check:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .form-check:active {
    border: 1px solid #008aff;
  }
  span {
    font-size: 12px;
  }
`;
