import styled from 'styled-components';

const StyledTranscript = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .search-form {
    margin: 1em 0;
    font-size: 14px;
    .form-control {
      height: 32px;
      background: #65686e;
      color: #fff;
      opacity: 0.4;
      border: 0;

      &:focus {
        opacity: 1;
      }
    }
    .form-control::placeholder {
      color: #e9e9e9;
    }
  }
  .content {
    overflow: auto;
    flex: 1 1 1px;
    .transcript-div {
      display: flex;
      flex-flow: wrap;
      .transcript-line {
        margin-left: 3px;
        margin-bottom: 0;
      }
      .transcript-line:hover {
        background-color: #6495f050;
        color: #ffffff;
      }
    }
    .transcript-line {
      cursor: pointer;
      mark,
      .mark {
        padding: 0;
      }
    }
  }

  .a_iconDiv {
    height: 50px;
    width: 50px;
    background-color: #ff6800;
    border-radius: 30px;
    margin-top: 24px;
  }

  .a_transcriptUpgradeText {
    color: ${({theme}) => theme.text};
    width: 276px;
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
    text-align: center;
    margin-top: 11px;
  }

  .a_upgradeButton {
    height: 32px;
    width: 105px;
  }
`;

export default StyledTranscript;
