import {makeAutoObservable, runInAction} from 'mobx';
import {createBookmark, deleteBookmark} from '../../api/course';
import {deletePlaylist, updatePlaylistOrder, getDeleteConfirmation, updateDeleteConfirmation} from '../../api/playlist';
import {getAllPlaylists, addToPlaylist, massUpdatePlaylists, updatePlaylist} from '../../api/video';
import AlertStore from '../alert/alertStore';
import ErrorStore from '../error/errorStore';
import {amplitude} from '../../utils/Amplitude';

class PlaylistStore {
  errorStore: ErrorStore;

  playlist = [];

  alertStore: AlertStore;

  deleteConfirmation = true;

  sort: string = 'DESC';

  constructor({errorStore, alertStore}: {alertStore: AlertStore, errorStore: ErrorStore}) {
    this.alertStore = alertStore;
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  changeSort = option => {
    this.sort = option;
  };

  getAllPlaylists = async (sortBy, sortType) => {
    try {
      const res = await getAllPlaylists(sortBy, sortType);
      if (res.status) {
        const playlist = res?.data?.playlist || null;
        runInAction(() => {
          this.playlist = playlist;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  findPlaylist = (id: number) => {
    return this.playlist.find(p => p.id == id);
  };

  addToPlaylist = async (videoId: number, name: string, access_ids: number[] = []) => {
    try {
      const res = await addToPlaylist(videoId, name, access_ids);
      const {status} = res.data;
      if (status) {
        this.getAllPlaylists();
        this.alertStore.setMessage('Playlist created successfully', 'success');
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  massUpdatePlaylists = async (videoId: number, data: [{}]) => {
    try {
      const res = await massUpdatePlaylists(videoId, data);
      const {status} = res.data;
      if (status) {
        this.getAllPlaylists();
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updatePlaylist = async (id: number, data: any) => {
    try {
      await updatePlaylist(id, data);
      this.getAllPlaylists();
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  createBookmark = async id => {
    try {
      const res = await createBookmark(id, 'video');
      const {status} = res.data;
      if (status) {
        this.getAllPlaylists();
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deleteBookmark = async video => {
    const {id} = video;
    try {
      const res = await deleteBookmark(id, 'video');
      const {status} = res.data;
      if (status) {
        this.getAllPlaylists();
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deletePlaylist = async id => {
    amplitude.getInstance().logEvent('playlist-deleted');
    try {
      const res = await deletePlaylist(id);
      const {status} = res.data;
      if (status) {
        this.getAllPlaylists();
        this.alertStore.setMessage('Playlist deleted successfully', 'success');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updatePlylistOrder = async (playlistId, data) => {
    try {
      const res = await updatePlaylistOrder(playlistId, data, 'order');
      const {status} = res.data;
      if (status) {
        this.getAllPlaylists('ASC', 'order');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updatePlaylistname = async (playlistId, data) => {
    try {
      const res = await updatePlaylistOrder(playlistId, data, 'name');
      const {status} = res.data;
      if (status) {
        this.getAllPlaylists();
        this.alertStore.setMessage('Playlist name updated successfully', 'success');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getDeleteConfirmation = async () => {
    try {
      const res = await getDeleteConfirmation();
      if (res.status) {
        const confirmValue = res?.data?.confirmation?.show_playlist_delete_confirmation;
        this.deleteConfirmation = confirmValue;
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updateDeleteConfirmation = async data => {
    try {
      const res = await updateDeleteConfirmation(data);
      if (res.status) {
        this.getDeleteConfirmation();
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setPlaylist = item => {
    this.playlist = item;
  };
}

export default PlaylistStore;
