import React, {useState} from 'react';
import {Col, Row, Nav} from 'react-bootstrap';
import Stepper from './Stepper';
import PlanSelection from './PlanSelection';
import AccountCreation from './AccountCreation';
import OrderSummary from './OrderSummary';
import PaymentDetail from './PaymentDetail';
import {Wrapper} from '../style';
import {useStore} from '../../../store';

export const RegisterFlow = ({activeRegistrationStep}: Props) => {
  const {authStore} = useStore();
  const {setActiveStep, selectedProduct} = authStore;
  // Use regular react hook to pass promocode from payment details component
  // to order summary component. For some reason, updating the store wasn't
  // triggering a re-render...
  const [promoCode, setPromoCode] = useState(null);
  return (
    <>
      <div className="numberStepContainer d-flex  align-items-center justify-content-md-center w-100">
        <div className="nav-div">
          <Stepper activeRegistrationStep={activeRegistrationStep} />
        </div>
        <div className="nav-div">
          <Nav.Item>
            <Nav.Link href="https://www.giblib.com/pricing">
              <i className="fal fa-chevron-left" />
              <span className="collapseText">Pricing Details</span>
            </Nav.Link>
          </Nav.Item>
        </div>
      </div>
      <Wrapper>
        <Row className="justify-content-md-center">
          <Col>
            <PlanSelection
              usecase="registration"
              activeRegistrationStep={activeRegistrationStep}
              navigate={() => {
                if (['account', 'payment'].includes(activeRegistrationStep)) {
                  setActiveStep('plan');
                }
              }}
            />
            <AccountCreation
              activeRegistrationStep={activeRegistrationStep}
              navigate={() => {
                if (activeRegistrationStep === 'payment') {
                  setActiveStep('account');
                }
              }}
            />
            {selectedProduct?.name !== 'Free Plan' && (
              <PaymentDetail
                usecase="registration"
                setPromoCodeFlow={setPromoCode}
                activeRegistrationStep={activeRegistrationStep}
              />
            )}
          </Col>
          <Col>
            <OrderSummary promoCode={promoCode} />
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};
