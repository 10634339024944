import styled from 'styled-components';

const AlertContainer = styled.div`
  position: fixed;
  margin-top: 10px;
  top: 3rem;
  right: 0;
  left: 0;
  z-index: 1020;
  display: flex;
  padding: 0.25em 0;
  align-items: center;
  font-size: 0.9rem;
  color: #181a21;
  min-height: 32px;
  width: 100%;
  p {
    flex: 1;
    margin: auto;
    text-align: center;
  }
  button {
    background: none;
    border: none;
  }
  &.success {
    background-color: #35cb95;
  }
  &.failure {
    background-color: #e04463;
  }
`;

export default AlertContainer;
