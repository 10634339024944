import styled from 'styled-components';

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-size: 15px;
  margin-top: 83px;

  .courseBorder-top {
    border-top: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  }
  /* Adapt the colors based on primary prop */
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 15px;
  &:hover {
    cursor: pointer;
  }
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 30px 8px 16px;
  border-radius: 30px;
  width: 200px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  font-weight: 500;

  &:active,
  &:focus {
    outline: 0;
  }
`;

export const Title = styled.h3`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 26px;
`;

export const Name = styled.h5`
  font-size: 17px;
  letter-spacing: -0.11px;
  line-height: 24px;
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  &:hover {
    cursor: pointer;
  }
`;

export const TimeStamp = styled.p`
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 11px;
  margin-bottom: 4px;
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
`;

export const FollowCard = styled.div`
  box-shadow: inset 0 -1px 0 0 #4e505a;
  padding: 16px 25px;

  #specialty-avatar {
    margin-right: 15px;
    width: 47px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0677d8;
    color: #ffffff;
    letter-spacing: -1px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
  }
`;

export const OfVideos = styled.p`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.13px;
  line-height: 15px;
  margin: 5px 0;
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
`;
