// @flow

import {makeAutoObservable} from 'mobx';

type UnhandledError = {
  message: string,
};
type ApiError = UnhandledError & {
  statusCode: number,
};

class ErrorStore {
  apiError: ApiError | null = null;

  unhandledError: UnhandledError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setApiError(error: ApiError) {
    this.apiError = {...error};
  }

  setUnhandledError(error: UnhandledError) {
    this.unhandledError = {...error};
  }

  setError(error: any) {
    if (error?.response) {
      // if ([503, 403, 401, 400].includes(error?.response?.status)) {
      //   // discard theses errors as those where handled in the app either in axios or other
      //   return;
      // }
      // this.setApiError({
      //   message: error?.response?.data.error,
      //   statusCode: error?.response?.status,
      // });
      this.apiError = null;
    } else {
      this.unhandledError = null;
      // this.setUnhandledError(error);
    }
  }

  resetError() {
    this.apiError = null;
    this.unhandledError = null;
  }
}

export default ErrorStore;
