const purchaseConversionEvent = () => {
  // @ts-ignore
  window.gtag_report_purchase_conversion();
};

const registerEmailConversionEvent = () => {
  // @ts-ignore
  window.gtag_report_register_email_conversion();
};

const bingSubmitLeadFormEvent = () => {
  // @ts-ignore
  window.bing_submit_lead_form();
};

const bingStartSubscriptionEvent = () => {
  // @ts-ignore
  window.bing_start_subscription();
};

const Analytics = {
  purchaseConversionEvent,
  registerEmailConversionEvent,
  bingSubmitLeadFormEvent,
  bingStartSubscriptionEvent,
};

export default Analytics;
