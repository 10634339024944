import React from 'react';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {UpdateModal} from '../elements/cme';

export const DeleteModal = ({showDeleteModal, handleDeleteModalClose, handleSubmitForm, videoTitle, tabName}: any) => {
  const {handleSubmit} = useForm();
  return (
    <UpdateModal show={showDeleteModal} onHide={handleDeleteModalClose} className="deletePlaylistModal">
      <UpdateModal.Header closeButton>Delete CME In-Progress</UpdateModal.Header>
      <UpdateModal.Body>
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
          <p>
            Are you sure you want to delete the {videoTitle} from the CME {tabName}?
          </p>
          <div id="deleteCheckbox" className="text-right">
            <Button type="submit" variant="outline-secondary" id="remove-cme-video" className="a_removeVideoButton">
              Yes, Delete
            </Button>
          </div>
        </Form>
      </UpdateModal.Body>
    </UpdateModal>
  );
};

export default DeleteModal;
