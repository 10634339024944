import styled from 'styled-components';
import {Modal} from 'react-bootstrap';

const size = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};
export const Main = styled.main`
  display: flex;
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  position: relative;
  width: 100%;
  margin-right: 0;
  transition: margin-right 0.8s ease-in-out;
  height: calc(100vh - 3.25rem);
  .action-icon-button {
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    color: ${({theme}) => theme.text1};
  }
  .commentbtn {
    position: relative;
  }
  .commentCount {
    position: absolute;
    background: #0677d8;
    width: 11px;
    height: 11px;
    display: flex;
    justify-content: center;
    font-weight: 800;
    font-size: 8px;
    top: 0;
    right: -5px;
    border-radius: 50%;
  }
  hr {
    border-top: 2px solid #4e505a;
  }
  &.sidetab-expanded {
    margin-right: 0;
  }
  .description-panel {
    flex: 1;
    overflow: auto;
    margin-bottom: 2.5rem;
  }
  .cme-disclosure {
    .subtitle {
      margin-top: 15px !important;
    }
    .description {
      margin-top: 10px;
    }
  }
  .accreditation {
    height: 82px;
    width: auto;
    max-width: 120px;
    display: block;
  }
  .expert {
    margin-bottom: 0;
  }
  .bibliography,
  .learning-objectives {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 1.5em;
    text-indent: -1em;
    &:last-child {
      margin-bottom: 1em;
    }
  }
  .disclosure {
    text-decoration: underline;
  }
  .date {
    margin-bottom: 0rem;
  }
  .last {
    padding-bottom: 8px;
  }
  .mobile {
    display: none;
  }
  .header {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .desktop .table {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 8px;
    margin-bottom: 8px;
    &:last-child {
      border-bottom: 0px;
    }
  }

  td {
    overflow-wrap: break-word;
  }

  .table-bordered thead td {
    border-bottom-width: 0px;
    padding: 0.5rem !important;

    h2 {
      // line-height: 14px !important;
      margin-bottom: 0px !important;
    }
  }
  .validationButton {
    color: #fff;
    min-width: 100px;
    margin: 0 5px;
    border: 2px solid #0677d8;
    height: 40px;
    :hover {
      background: #0677d8;
    }
    &:disabled {
      color: #000;
    }
  }
  .approve {
    color: #fff;
    border: 2px solid #0677d8;
    :hover {
      background: #0677d8;
    }
  }
  .reject {
    color: #9d0a0a;
    border: 2px solid #9d0a0a;
    :hover {
      background: #9d0a0a;
      color: #fff;
    }
  }
  @media screen and (max-width: ${size.sm}) {
    .mobile {
      display: block;
    }
    .table tr {
      td {
        padding: 0px 0px 0px 00px;
      }
      &:nth-child(4) {
        td {
          padding: 0px 0px 10px 0px;
        }
      }
    }
    .table td {
      padding: 0px;
    }
    .desktop {
      display: none;
    }
  }
  @media screen and (min-width: ${size.sm}) {
    .table td {
      // padding: 0px;
      max-width: 72px;
    }
  }
  .table {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 8px;
    margin-bottom: 8px;
    &:last-child {
      border-bottom: 0px;
    }
  }
  .section {
    background-color: #181a21;
    padding: 32px 16px;
  }
  .row {
    margin-top: 8px;
  }

  @media (min-width: ${size.lg}) {
    margin-right: 3.175rem;
    height: auto;
    &.sidetab-expanded {
      margin-right: 28.175rem;
    }
    .description-panel {
      overflow: visible;
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
    &.sidetab-expanded {
      .title-div {
        max-width: 365px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        min-height: 24px;
      }
      .video-description {
        width: 355px;
      }
    }
  }
  @media (min-width: 768px) {
    margin-top: 58px;
  }
`;

export const VideoRectangle = styled.div`
  height: 518px;
  background-color: #e04463;
`;

export const TitleDiv = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: -0.3px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 22px;
  min-height: 40px;
  max-height: 300px;

  .beta-tag {
    padding: 2px 8px;
    background: #4e505a;
    border-radius: 5px;
    font-size: 10px;
    margin-left: 10px;
    font-weight: 100;
  }
`;

export const SubTitleDiv = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.16px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const DescriptionText = styled.div`
  margin: 0.5rem 0;
  color: ${({theme}) => theme.text1};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  text-align: justify;
  .video-short-description {
    white-space: pre-wrap;
  }
`;

export const CitationText = styled.div`
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 20px;
  & :hover {
    cursor: pointer;
  }
`;

export const CitationSpan = styled.span`
  cursor: ${props => (props.clickable ? 'pointer' : 'initial')};
  &:hover {
    text-decoration: ${props => (props.clickable ? 'underline' : 'none')};
  }
`;

export const CopyText = styled.div`
  margin: 0.7rem 0;
  font-size: 12px;
  letter-spacing: -0.27px;
  line-height: 16px;

  .a_disclaimer {
    color: ${({theme}) => theme.card_text};
  }
`;

export const Credit = styled.div`
  margin: 0.5rem 0;
  font-size: 12px;
  letter-spacing: -0.27px;
  line-height: 10px;
`;

export const VideoAssociations = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  .avatar {
    height: 3.1rem;
    width: 3.1rem;
    border-radius: 50%;
  }
  .organization,
  .expert,
  .specialty {
    cursor: pointer;
  }
  #specialty-avatar {
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0677d8;
    color: #ffffff;
    letter-spacing: -1px;
    font-weight: 400;
    vertical-align: middle;
    font-size: 20px;
    line-height: 1.5;
    padding-right: 1.5px;
  }
  h5 {
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
    font-weight: normal;
  }
  .fa-check,
  .fa-times {
    width: 12px;
  }
  button:not(.a_followed) {
    width: 81px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 16px;
    height: 24px;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 8px;
  }
`;
export const CMESection = styled.div`
  margin-top: 0.5rem;
  .credits {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    .icon-cme {
      width: 2rem;
      margin-right: 0.4rem;
    }
  }
`;

export const Details = styled.p`
  color: ${({theme}) => theme.text1};
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
`;

export const Badge = styled.div`
  display: flex;
  .icon-new {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;
  }
  .icon-cme {
    margin-top: auto;
    margin-bottom: auto;
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
  .icon-vr {
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
    margin-right: 8px;
  }
`;

export const ThumbnailImg = styled.img`
  width: 160px;
  height: 90px;
  mix-blend-mode: ${props => (props.lock ? 'luminosity' : 'normal')};
`;

export const ThumbnailDiv = styled.div`
  margin-right: 20px;
  position: relative;
  background: transparent;
  .lock-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.4;
      z-index: 0;
    }
  }
  .lock-link {
    position: relative;
    z-index: 1;
    height: 2rem;
    width: 2rem;
    border: 3px solid #fff;
    border-radius: 2rem;
    background-color: #a7a7a791;
    display: flex;
    align-items: center;
    justify-content: center;
    .lock-icon {
      color: #fff;
      font-size: 1rem;
    }
  }
  .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }
`;

export const Unlock = styled.div`
  float: right;
  padding-right: 10px;
`;

export const CourseTitle = styled.div`
  h5 {
    margin-bottom: 1px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const Line = styled.hr`
    background-color: ${({theme}) => (theme.name === 'light' ? '#25273041' : '#F9F9F941')};
    margin-top: 0px;
    margin-bottom: 0px;
    width: 390px;
}
`;

export const WatchNextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .content {
    overflow: hidden auto !important;
    flex: 1 1 1px;
  }
  .content {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.4) !important;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #008aff !important;
      border-radius: 4px;
    }
  }
`;

export const Cast = styled.li`
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;
`;

export const Tab = styled.nav`
  background-color: #inherit;
  box-shadow: inset 0 -1px 0 0 #4e505a;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    & .nav {
      justify-content: center;
    }
  }
`;

export const DescriptionItem = styled.button`
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 18px 18px 15px 15px;
  background: transparent;
  border: 0;
  &.active {
    font-weight: bold;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-image: linear-gradient(90deg, #0dfff3 0%, #008aff 100%) 1 !important;
    border-image-slice: 1;
  }
  &:hover {
    color: #ffffff;
    opacity: 0.6;
    font-weight: bold;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: rgb(255, 255, 255, 0.5);
  }
`;

export const NavItem = styled.li`
  width: auto !important;
`;

export const VideoOutline = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #008aff;
  }

  li {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  strong,
  b {
    font-weight: bold;
  }
`;

export const MessageModal = styled(Modal)`
  .modal-dialog {
    width: 800px;
    position: fixed;
    top: 30%;
    left: 35%;
  }
  .modal-body {
    .modalBody {
      padding: 3rem;
    }
    .formDiv {
      padding-top: 25px;
    }
  }
  .modal-content {
    padding: 0;
    top: 0;
    border: none !important;
    background-color: #000;
    border-radius: 8px;

    .modal-header {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-footer {
      border: none !important;
    }
    .close {
      color: ${({theme}) => theme.circle};
      top: 0;
      right: 12px;
      position: absolute;
    }
  }
  .messageHead {
    height: 28px;
    width: 100%;
    background: #a3a0a0a6;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 8px;
  }
  .inputField {
    background: #000;
    border: none;
    color: #fff;
    :focus {
      outline: none;
    }
  }
  .inputFieldMsg {
    background: #fff;
    border-radius: 8px;
    border: none;
    color: #000;
    :focus {
      outline: none;
    }
  }
  .borderDiv {
    border-bottom: 1px solid #808080;
    padding: 0px 0px 10px 0;
  }
  .textField {
    margin-top: 30px;
    textArea {
      width: 100%;
      color: #fff;
      background: #000;
      border: none;
      :focus {
        outline: none;
        border: none;
      }
    }
  }

  .sendBtn {
    border-radius: 8px;
    width: 80px;
    height: 25px;
    padding: 0px;
  }
  .iconBtn {
    background: none;
    border: none;
    padding: 0px;
    margin-right: 12px;
    cursor: pointer;
  }
  .filterIcon {
    img {
      height: 24px;
      filter: brightness(50%) invert(1);
    }
  }
  .minimiseIcon {
    top: 0;
    right: 56px;
    position: absolute;
    cursor: pointer;
  }
  .toField {
    background: #a3a0a0a6;
    border-radius: 8px;
    padding: 4px;
    margin-left: 5px;
  }
  .inputField {
    border-radius: 8px;
  }
  .inputDiv {
    padding: 8px;
  }
  .submitBtn {
    width: 100px;
    height: 30px;
    margin-bottom: 8px;
    padding: 2px;
  }
  .messageDiv {
    margin: 8px;
    width: 200px;
    height: 30px;
    background: #1972f5;
    border-radius: 8px;
    margin-left: auto;
    right: 0;
  }
  .messageText {
    padding: 4px;
    color: #fff;
    font-size: 14px;
  }
  .receiverDiv {
    margin-right: auto;
    margin-left: 8px;
    width: 200px;
    height: 30px;
    background: #1972f5;
    border-radius: 8px;
    margin-bottom: 30px;
  }
`;
