// @flow
import React from 'react';
import {Row} from 'react-bootstrap';

type Props = {
  data: {
    icon: string,
    primaryText: string,
    tooltip: string,
    show: boolean,
  },
};

const userStatistics = ({data}: Props) => {
  return (
    <Row className="mx-4" style={{cursor: 'pointer', display: data.show ? 'flex' : 'none'}}>
      <div className="icon mb-1" data-tip={data.tooltip} data-for="userStatistics">
        <i className={data.icon} alt="medical icon" />
      </div>
      <div className="stat px-1">{data.primaryText}</div>
    </Row>
  );
};

export default userStatistics;
