import React, {useState, useEffect} from 'react';
import {Form, Button} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {PastNotesTitle, Wrap} from './style';
import PastNotes from './pastNotesCard';
import {useStore} from '../../../../store';
import {amplitude} from '../../../../utils/Amplitude';

const Notes = observer(() => {
  const location = useHistory();
  const [inputNote, setInputNote] = useState(null);
  const [timeCode, setTimeCode] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const {videoStore, accountStore, noteStore} = useStore();
  const {video, videoNotes, fetchVideoNotes, saveNote, videoNoteSaving, videoPlayer} = videoStore;
  const {account} = accountStore;
  const {downloadVideoNote} = noteStore;

  useEffect(() => {
    if (video?.id) fetchVideoNotes(video.id);
    if (!videoNoteSaving) {
      setInputNote('');
    }
  }, [video, fetchVideoNotes, videoNoteSaving]);

  useEffect(() => {
    if (
      (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) ||
      (account?.access?.status === 'Active' && account?.access?.notes)
    ) {
      setUserStatus('active');
    } else if (!account.access) setUserStatus('subscriptionInactive');
    else setUserStatus('accessInactive');
  }, [account]);

  const changeInput = event => {
    event.preventDefault();
    if (!timeCode || !inputNote) {
      // eslint-disable-next-line no-underscore-dangle
      let currentTime = 0;
      if (videoPlayer?.currentTime()) {
        currentTime = Math.floor(videoPlayer?.currentTime());
      }
      const calculatedTime = new Date(currentTime * 1000)?.toISOString()?.substr(11, 8);
      setTimeCode(calculatedTime);
    }
    setInputNote(event.target.value);
  };

  const onSaveNote = event => {
    saveNote(video.id, inputNote, timeCode);
    setTimeCode(null);
    event.preventDefault();
    amplitude.getInstance().logEvent('note-saved');
  };

  const onDownloadNote = () => {
    if (!downloadLoader) {
      setDownloadLoader(true);
      const noteIds = videoNotes.map(note => note.id);
      downloadVideoNote(noteIds).then(() => {
        setDownloadLoader(false);
      });
    }
  };

  const onClickUpgrade = () => {
    location.push('/profile/subscription');
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Wrap className="notes-wrap-on-ad">
      <ReactTooltip place="left" effect="solid" id="notes" />
      {userStatus === 'active' ? (
        <>
          <Form>
            <Form.Group controlId="note">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Add a note"
                value={inputNote}
                onChange={changeInput}
                className={classNames('a_textArea', {bgTextarea: inputNote})}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                variant={inputNote ? 'primary' : 'secondary'}
                type="submit"
                disabled={!inputNote || videoNoteSaving}
                className={classNames('a_saveNote py-0', {
                  a_saveNoteDisabled: !inputNote,
                })}
                onClick={e => onSaveNote(e)}
              >
                {!videoNoteSaving ? 'Save Note' : 'Saving ...'}
              </Button>
            </div>
          </Form>
          <PastNotesTitle>
            Past Notes
            {videoNotes?.length ? (
              <Button
                variant="basic"
                className="float-right py-0 a_downloadNote"
                onClick={onDownloadNote}
                disabled={downloadLoader}
              >
                {downloadLoader ? (
                  <span key="download-note-loading">
                    <i className="fad fa-spinner-third fa-spin" />
                  </span>
                ) : (
                  <span key="download-note" data-tip="Download notes" data-for="notes">
                    <i className="far fa-download" />
                  </span>
                )}
              </Button>
            ) : (
              <></>
            )}
          </PastNotesTitle>
          <div id="a_notesListDiv" className="a_notesListDiv a_notesListScroll">
            {videoNotes.map(note => (
              <PastNotes
                id={note.id}
                note={note.note}
                videoTimeCode={note.video_time_code ?? '00:00:00'}
                createdAt={note.created_at}
              />
            ))}
          </div>
        </>
      ) : (
        userStatus === 'subscriptionInactive' && (
          <div className="d-flex flex-column align-items-center">
            <div className="a_iconDiv d-flex align-items-center justify-content-center">
              <i className="fas fa-sticky-note" />
            </div>
            <p className="a_notesUpgradeText">Upgrade to Standard or Premium Account to start taking notes.</p>
            <Button variant="primary" type="button" className="a_upgradeButton py-0" onClick={() => onClickUpgrade()}>
              Upgrade
            </Button>
          </div>
        )
      )}
      {userStatus === 'accessInactive' ? (
        <div className="d-flex flex-column align-items-center">
          <div className="a_iconDiv d-flex align-items-center justify-content-center">
            <i className="fas fa-sticky-note" />
          </div>
          <p className="a_notesUpgradeText">
            <big>
              <b>Not Available</b>
            </big>
            <br />
            This feature is not available with your current plan. Please ask your administrator to contact GIBLIB to
            potentially add this feature.
          </p>
          {/* <Button
            variant="primary"
            type="button"
            className="a_upgradeButton py-0"
            onClick={() => onClickUpgrade()}
          >
            Upgrade
          </Button> */}
        </div>
      ) : null}
    </Wrap>
  );
});

export default Notes;
