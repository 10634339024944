import styled from 'styled-components';

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-size: 15px;
  overflow-y: hidden;

  .slick-arrow:hover {
    ~ .slick-dots {
      display: block !important;
    }
  }

  & .slick-dots {
    display: none !important;
    bottom: 0;
    width: auto;
    top: -22px;
    right: 10px;

    @media screen and (max-width: 576px) {
      top: -19px;
    }

    & li,
    & li button:before {
      width: 4px;
      height: 4px;
    }

    & li.slick-active button:before,
    & li button:before {
      color: ${({theme}) => theme.circle};
    }
  }

  .nav {
    // padding: 0 4em;

    @media screen and (max-width: 767px) {
      padding: 0 1.5em;
    }
  }
  .nav-tabs {
    background: ${({theme}) => (theme.name === 'light' ? '#F9F9F9' : '#252730')};
    border-bottom: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  }

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: transparent;
  }
  .nav-tabs .nav-link.active {
    border: none;
    border-bottom: 3px solid #008aff;
    background-color: transparent;
    font-weight: bold;
  }

  .nav-tabs .nav-link {
    color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#DCDEE1')};
    padding: 20px 18px;
    font-family: Roboto;
    font-size: 17px;
    letter-spacing: -0.17px;
    line-height: 23px;
  }

  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }

  /* Adapt the colors based on primary prop */
`;

export const OrganizationHeader = styled.div`
  min-height: 240px;
  padding: 3em 5em;
  display: flex;
  align-items: center;
  background: ${({theme}) => (theme.name === 'light' ? '#F9F9F9' : '#252730')};
  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};

  @media screen and (max-width: 767px) {
    padding-top: 21px;
    padding-bottom: 26px;
  }

  .row {
    @media screen and (max-width: 767px) {
      margin: auto;
    }
  }

  .org-col {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }
  .org-col2 {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  img {
    width: 110px;
    height: 110px;
    border-radius: 80px;

    @media screen and (max-width: 767px) {
      margin: auto;
      margin-bottom: 11px;
    }
  }

  div.org-name {
    max-width: 231px;
    margin-top: 8px;
    margin-bottom: 1em;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.33px;
    line-height: 21px;
  }
  div.org-specialty {
    width: 74px;
    color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#dcdee1')};
    font-family: Roboto;
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;

    @media screen and (max-width: 767px) {
      margin: auto;
    }
  }

  .a_followed,
  .a_follow {
    margin-top: 1em;
  }

  button:not(.a_followed, .a_follow) {
    margin-top: 1em;
    box-sizing: border-box;
    padding: 0.3em 0.6em;
    color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#ffffff')};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 22px;
  }
  div.org-description {
    margin-top: 8px;
    font-size: 15px;
    max-width: 648px;
    text-align: justify;
    color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#dcdee1')};
  }
`;
export const Section = styled.div``;
