/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import {Button, OverlayTrigger, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {CourseTitle, ButtonGroup} from '../elements/catalog';
import PlaylistPopover from '../../../components/PlaylistPopover';
import AlertComponent from '../../../components/AlertComponent';
import LocalStorageService from '../../../utils/LocalStorageService';
import CMEIcon from '../../../assets/icons/icon_CME.svg';
import {useStore} from '../../../store';
import UpgradeModal from '../../../components/UpgradeModal';

type Props = {
  item: {
    id: number,
    title: string,
    cme: boolean,
    tags: string,
    published_at: Date,
    release_date: string,
    filmed_at: Date,
    active: boolean,
    video_type: string,
    brightcove_id: string,
    specialty: {
      id: number,
      display_name: string,
    },
    playlist: any[],
    bookmark: {id: string} | null,
    organizationCME: {
      id: number,
      credit_count: number,
    },
  },
};
// Videos List
const VideoCard = observer(({item}: Props) => {
  const {videoStore, accountStore, alertStore} = useStore();
  const location = useHistory();
  const loggedInUser = LocalStorageService.getUser();
  const {message, status, resetAlert} = alertStore;
  const [updating, setUpdating] = useState(false);
  const [bookmarkIcon, setBookmarkIcon] = useState(false);
  const {
    // fetchItem,
    playlist,
    addToPlaylist,
    updatePlaylist,
    toggleVideoTileBookmark,
  } = videoStore;
  const {account} = accountStore;

  const [userState, setUserState] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  const openVideo = video => {
    location.push(`/video/${video.id}`);
  };

  const createAndAddToPlaylist = async data => {
    setUpdating(true);
    await addToPlaylist(item.id, data.listname, false);
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    await updatePlaylist(item.id, data, false);
    setUpdating(false);
  };

  const renderOverlayTrigger = () => {
    return !updating ? (
      <OverlayTrigger
        trigger="click"
        placement="auto"
        overlay={props => (
          <PlaylistPopover
            props={props}
            allPlaylist={playlist}
            videoPlaylist={item.playlist}
            onExisting={updateExistingPlaylist}
            onNew={createAndAddToPlaylist}
            userState={userState}
            showUpgradeModal={isShowPlaylistUpgradeModal}
            onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
          />
        )}
        rootClose
      >
        <Button variant="basic" className="a_playlist py-0 px-3" onClick={() => setShowPlaylistUpgradeModal(true)}>
          <i className="fak fa-list-ul" />
        </Button>
      </OverlayTrigger>
    ) : (
      <Spinner animation="border" size="sm" variant="primary" />
    );
  };

  useEffect(() => {
    if (item?.bookmark?.length) setBookmarkIcon(true);
    else setBookmarkIcon(false);
  }, [item]);

  const toggleBookmark = () => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic')) {
        toggleVideoTileBookmark(item, bookmarkIcon).then(() => {
          setBookmarkIcon(!bookmarkIcon);
        });
        setShowBookmarkUpgradeModal(false);
      } else setShowBookmarkUpgradeModal(true);
    } else location.push('/login');
  };
  return (
    <div>
      {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <div
        className="d-flex"
        // id={item.id}
        data-tip={`Filmed: ${item?.release_date}`}
      >
        <div
          className="w-100"
          role="button"
          tabIndex="0"
          onClick={() => openVideo(item)}
          onKeyDown={() => openVideo(item)}
        >
          <CourseTitle>
            {item?.organizationCME ? (
              <span style={{display: 'flex'}}>
                <img src={CMEIcon} className="icon-cme mx-1" alt="cme" />
                <p>{item?.title}</p>
              </span>
            ) : item?.video_type?.includes('360vr') ? (
              <span style={{display: 'flex'}}>
                <i className="fas fa-head-vr mx-1" />
                <p>{item?.title}</p>
              </span>
            ) : (
              <p className="ml-3">&nbsp;&nbsp;{item?.title}</p>
            )}
          </CourseTitle>
        </div>
        <ButtonGroup className="text-right">
          {loggedInUser ? (
            renderOverlayTrigger()
          ) : (
            <Button variant="basic" className="a_playlist pl-0 py-0 px-3" onClick={() => location.push('/login')}>
              <i className="fak fa-list-ul" />
            </Button>
          )}
          <Button
            variant="basic"
            onClick={() => toggleBookmark()}
            className="a_bookmark px-0 py-0"
            data-tip="Bookmark"
            data-for="videoActions"
          >
            {bookmarkIcon ? (
              <span key="video_bookmark">
                <i className="fas fa-bookmark" />
              </span>
            ) : (
              <span key="video_bookmark_active">
                <i className="fal fa-bookmark" />
              </span>
            )}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
});

export default VideoCard;
