import React from 'react';
import {useStore} from '../../../store';
import {PlaylistCard} from '../elements/playlist';

type Props = {
  title: String,
  videoCount: number,
  thumbnail: String,
  accessCount: number,
};

const Playlist = ({title, videoCount, thumbnail, accessCount}: Props) => {
  const {account} = useStore().accountStore;
  return (
    <PlaylistCard>
      <div style={{backgroundImage: `url(${thumbnail})`}} />
      <h4>{title}</h4>
      <p>{videoCount} Videos</p>
      {accessCount && account.role == 'institution_manager' ? <p>{accessCount} Cohorts</p> : null}
      {accessCount && account.role != 'institution_manager' ? <p>Institutional playlist</p> : null}
    </PlaylistCard>
  );
};

export default Playlist;
