import axios from './axios';

/** calls the get course details api
 * @return res.data if success or err.response.data on failure
 */
export const getCourse = (id, params = null, cancelToken = null) =>
  axios.get(`/course/${id}`, {params, cancelToken}).then(
    res => res,
    err => err?.response.data,
  );
/** calls the get course videos api
 * @return res.data if success or err.response.data on failure
 */
export const getCourseVideos = id => axios.get(`/course/${id}/course-videos`);

/** calls the get related courses api
 * @return res.data if success or err.response.data on failure
 */
export const getRelatedCourses = id => axios.get(`/course/${id}/related-courses`);

export const getNextCourseVideos = (id, order) => axios.get(`/course/${id}/up-next-videos/${order}`);
