// @flow
import React, {useEffect, useState} from 'react';
import {Button, Spinner} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {Configuration, OpenAIApi} from 'openai';
import StyledChatGpt from './styles';
import AnswerSection from './answer';
import Arrow from '../../../../assets/icons/arrow-icon.png';
import {useStore} from '../../../../store';
import {chatgptHistory} from '../../../../api/video';

const Transcript = observer(() => {
  const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    organization: process.env.REACT_APP_OPENAI_ORGANIZATION,
  });

  const openai = new OpenAIApi(configuration);

  const {videoStore} = useStore();
  const [newQuestion, setNewQuestion] = useState('');
  const [regenerate, setRegenerate] = useState('');
  const [storedValues, setStoredValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [text, setText] = useState([]);
  const {transcript, video} = videoStore;

  useEffect(() => {
    const transcriptText = [];
    transcript.cues?.map(cue => transcriptText.push(cue.text));
    setText(transcriptText.join(' '));
    // eslint-disable-next-line
  }, [transcript]);

  const generateResponse = async question => {
    setIsLoading(true);
    setError(false);
    let system_message_content = '';
    if (text) {
      system_message_content = `Answer in the context of the following transcript of a medical video only. Transcript: ${text}`;
    } else {
      system_message_content = `Answer medical related questions only.`;
    }

    const system_message = [
      {
        role: 'system',
        content: system_message_content,
      },
    ];

    const user_query = [
      {
        role: 'user',
        content: question,
      },
    ];
    const query = [...system_message, ...user_query];
    try {
      const response = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo-16k',
        messages: query,
      });
      if (response.data.choices) {
        setStoredValues([
          {
            question,
            answer: response.data?.choices[0]?.message?.content,
          },
          ...storedValues,
        ]);
        setRegenerate(question);
        setNewQuestion('');
        chatgptHistory(video.id, question, response.data?.choices[0]?.message?.content);
        setIsLoading(false);
      }
    } catch {
      setError(true);
      setIsLoading(false);
      chatgptHistory(video.id, question, null);
    }
  };

  return (
    <StyledChatGpt>
      <div className="search-form input-group">
        <input
          id="search-chatgpt"
          className="form-control rounded-pill"
          type="text"
          placeholder="Type your question here"
          name="search"
          value={newQuestion}
          onKeyDown={e => (e.key === 'Enter' ? generateResponse(newQuestion) : '')}
          onChange={e => setNewQuestion(e.target.value)}
        />
        <div className="input-group-append">
          <button className="botSubmit" type="submit" onClick={() => generateResponse(newQuestion)}>
            <img src={Arrow} alt="" />
          </button>
        </div>
      </div>
      {error ? <small className="my-4 text-danger">Something went wrong. Please try again later.</small> : ''}
      <AnswerSection storedValues={storedValues} />
      <div className="text-center mb-2">
        {isLoading ? (
          <Spinner animation="grow" role="status" variant="secondary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          ''
        )}
      </div>

      <Button onClick={() => generateResponse(regenerate)} className="a_regenerate" disabled={!regenerate}>
        Regenerate response
      </Button>
    </StyledChatGpt>
  );
});
export default Transcript;
