import styled from 'styled-components';

export const Main = styled.div``;

export const PaginationContainer = styled.div`
  text-align: right;
  position: relative;
  span {
    font-size: 12px;
  }
  label {
    width: 100%;
    padding: 7px;
    padding-left: 18px;
  }
  .form-check-input {
    margin-left: -0.5rem;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #008aff;
    border-radius: 50%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    :checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='4e505a'/%3E%3C/svg%3E");
      border: 2px solid rgba(0, 138, 255, 0.5);
      background-color: #008aff;
    }
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  .a_limit,
  .a_p_dec,
  .a_p_inc {
    padding: 0;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 16px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
  }
  .a_limit {
    font-size: 12px;
    padding-left: 4px;
    padding-top: 2px;
  }
  .a_limit:hover,
  .a_limit:active,
  .a_limit:focus {
    font-size: 12px;
    background-color: #008aff;
    color: #dcdee1;
    border-radius: 3px;
  }
  .dropdown-menu {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 125px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 16px;
    min-width: 7rem;
  }
  .dropdown {
    display: inline-block;
  }
  #limitMenu::after {
    border: none;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;
