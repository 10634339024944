import React, {useState, useEffect} from 'react';
import {useLocalStore} from 'mobx-react-lite';
import {Button, OverlayTrigger, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import Moment from 'react-moment';
import {useStore} from '../../../store';
import {
  Badge,
  BadgeNew,
  Organization,
  CourseTitle,
  ReleaseDate,
  ButtonGroup,
  VideoImgCard,
  VideosCnt,
  ThumbnailImg,
  DeskFilter,
  MobFilter,
} from '../elements/courses';
import '../elements/courses.css';
import stringToURLSlug from '../../../utils/helper';
import CoursePlaylistPopover from '../../../components/CoursePlaylistPopover';
import LocalStorageService from '../../../utils/LocalStorageService';
import UpgradeModal from '../../../components/UpgradeModal';
import {ActiveSmallBtn, DefaultSmallBtn} from '../../../components/FollowButtons';
import {amplitude} from '../../../utils/Amplitude';
import GiblibIcon from '../../../assets/images/GBLB_Logo_Mark_FullColor.png';
import CMEIcon from '../../../assets/icons/CME.svg';
import VRIcon from '../../../assets/icons/360vr.svg';

type Props = {
  course: {
    id: number,
    title: string,
    description: string,
    number_of_videos: number,
    total_runtime: {
      hours?: number,
      minutes?: number,
      seconds?: number,
    },
    is_giblib_produced: Boolean,
    is_360vr: Boolean,
    is_cme: Boolean,
    earliest_date: Date,
    latest_date: Date,
    credit_count: number,
    thumbnail_url: String,
    bookmark: Array,
    follow: Array,
    specialty: Array,
    organization: {
      name: string,
    },
    videos: [{id: number}],
  },
  onFollow: Function,
  onBookmark: Function,
  playlist: {
    id: number,
    name: string,
  },
  playlistStore: string,
};
// Courses List
const CourseCard = ({course, onFollow, onBookmark, playlist, playlistStore}: Props) => {
  const location = useHistory();

  const loggedInUser = LocalStorageService.getUser();

  const store = useStore();
  const {account} = store.accountStore;
  const {addCourseToNewPlaylist, addCourseToPlaylist} = store[playlistStore];

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const [playlistUpdating, setUpdating] = useState(false);

  const state = useLocalStore(() => ({
    isNew: false,
    sameDate: false,
  }));

  // new badge (displays for 30 days from release date)
  useEffect(() => {
    const createdVideo = new Date(course.latest_date);
    const after30Video = new Date(createdVideo.getFullYear(), createdVideo.getMonth(), createdVideo.getDate() + 30);
    const today = new Date();
    if (after30Video > today) {
      state.isNew = true;
    }
  }, [state, course]);

  // release_date
  useEffect(() => {
    const earl_date = new Date(course.earliest_date);
    const earl_year = earl_date.getFullYear();
    const earl_month = `0${earl_date.getMonth() + 1}`.slice(-2);

    const lat_date = new Date(course.latest_date);
    const lat_year = lat_date.getFullYear();
    const lat_month = `0${lat_date.getMonth() + 1}`.slice(-2);

    if (earl_year == lat_year && earl_month == lat_month) {
      state.sameDate = true;
    }
  }, [state, course]);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  const bookmarkCourse = (e, selectedItem) => {
    e.stopPropagation();
    onBookmark(selectedItem);
  };

  const gotoCourseDetail = id => {
    const slug = stringToURLSlug(course.title);
    location.push(`/courses/${id}/${slug}`);
  };

  const followCourse = (e, selectedItem) => {
    e.stopPropagation();
    if (!account?.subscription?.name?.includes('Basic')) onFollow(selectedItem);
    else setShowFollowUpgradeModal(true);
  };

  const createAndAddToPlaylist = async data => {
    amplitude.getInstance().logEvent('playlist-created');
    setUpdating(true);
    await addCourseToNewPlaylist(course.id, data.listname);
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    await addCourseToPlaylist(course.id, data);
    setUpdating(false);
  };

  return (
    <div className="courseCard p-3 mb-3">
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <DeskFilter>
        <div className="d-flex" id={course.id}>
          <VideoImgCard>
            <VideosCnt>{course.number_of_videos} Videos</VideosCnt>
            <ThumbnailImg
              className="mr-4"
              src={course.thumbnail_url}
              alt={course.title}
              onClick={() => gotoCourseDetail(course.id)}
            />
          </VideoImgCard>
          <div
            className="w-100"
            role="button"
            tabIndex="0"
            onClick={() => gotoCourseDetail(course.id)}
            onKeyDown={() => gotoCourseDetail(course.id)}
          >
            <Badge className="d-flex align-items-center">
              {course.is_giblib_produced ? <img className="icon-giblib" src={GiblibIcon} alt="giblib" /> : ''}
              {state.isNew ? <BadgeNew>NEW</BadgeNew> : ''}
              {course.is_360vr ? <img src={VRIcon} className="icon-vr" alt="vr" /> : ''}
              {course.is_cme ? (
                <>
                  <object data={CMEIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />
                  <div className="credit">{course?.credit_count} Credits</div>
                </>
              ) : (
                ''
              )}
            </Badge>
            <CourseTitle>
              <h5>{course.title}</h5>
            </CourseTitle>
            <Organization>
              {course.organization ? course.organization.name : ''}
              {course.organization && course.specialty.length > 0 ? ' | ' : ''}
              {course.specialty?.length > 0
                ? `${course?.specialty
                    .slice(0, 2)
                    ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                : ''}
            </Organization>
            {course.earliest_date && course.latest_date ? (
              <ReleaseDate>
                <Moment format="MM/YYYY">{course?.earliest_date}</Moment>
                {state.sameDate ? (
                  ''
                ) : (
                  <>
                    <span> - </span>
                    <Moment format="MM/YYYY">{course?.latest_date}</Moment>
                  </>
                )}
              </ReleaseDate>
            ) : (
              ''
            )}
          </div>
          <ButtonGroup className="text-right">
            {course.follow?.length ? (
              <ActiveSmallBtn className="a_followed" onClick={e => followCourse(e, course)}>
                <span className="following">
                  <i className="fas fa-check mr-2" />
                  Following
                </span>
                <span className="unfollow">
                  <i className="fas fa-times mr-2" />
                  Unfollow
                </span>
              </ActiveSmallBtn>
            ) : (
              <DefaultSmallBtn className="a_follow" onClick={e => followCourse(e, course)}>
                <i className="fas fa-plus mr-2" />
                Follow
              </DefaultSmallBtn>
            )}
            {loggedInUser ? (
              [
                !playlistUpdating ? (
                  <OverlayTrigger
                    trigger="click"
                    placement="auto-start"
                    overlay={props => (
                      <CoursePlaylistPopover
                        props={props}
                        allPlaylist={playlist}
                        onNew={createAndAddToPlaylist}
                        onExisting={updateExistingPlaylist}
                        courseVideos={course.videos}
                        userState={userState}
                        showUpgradeModal={isShowPlaylistUpgradeModal}
                        onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                      />
                    )}
                    rootClose
                  >
                    <Button variant="basic" className="a_playlist" onClick={() => setShowPlaylistUpgradeModal(true)}>
                      <i className="fak fa-list-ul" />
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <div className="p-0 mr-3 btn">
                    <Spinner animation="border" size="sm" variant="primary" />
                  </div>
                ),
              ]
            ) : (
              <Button variant="basic" className="a_playlist" onClick={() => location.push('/login')}>
                <i className="fak fa-list-ul" />
              </Button>
            )}
            {account?.subscription?.name?.includes('Basic') ? (
              <Button
                variant="basic"
                className="px-0 py-0 a_bookmark mr-0"
                onClick={() => setShowBookmarkUpgradeModal(true)}
              >
                <i className="far fa-bookmark" />
              </Button>
            ) : (
              <>
                <Button
                  variant="basic"
                  className={`a_bookmark ${course.bookmark?.length ? 'd-none' : ''} mr-0`}
                  onClick={e => bookmarkCourse(e, course)}
                >
                  <i className="far fa-bookmark" />
                </Button>
                <Button
                  variant="basic"
                  className={`a_bookmark ${course.bookmark?.length ? 'bookmark-fill' : 'd-none'} mr-0`}
                  onClick={e => bookmarkCourse(e, course)}
                >
                  <i className="fas fa-bookmark" />
                </Button>
              </>
            )}
          </ButtonGroup>
        </div>
      </DeskFilter>
      <MobFilter>
        <div id={course.id}>
          <VideoImgCard>
            <VideosCnt>{course.number_of_videos} Videos</VideosCnt>
            <ThumbnailImg
              className="w-100"
              src={course.thumbnail_url}
              alt={course.title}
              onClick={() => gotoCourseDetail(course.id)}
            />
          </VideoImgCard>
          <div
            className="w-100"
            role="button"
            tabIndex="0"
            onClick={() => gotoCourseDetail(course.id)}
            onKeyDown={() => gotoCourseDetail(course.id)}
          >
            <div className="d-flex mt-3">
              <Badge className="d-flex align-items-center">
                {course.is_giblib_produced ? <img className="icon-giblib" src={GiblibIcon} alt="giblib" /> : ''}
                {state.isNew ? <BadgeNew>NEW</BadgeNew> : ''}
                {course.is_360vr ? <img src={VRIcon} className="icon-vr" alt="vr" /> : ''}
                {course.is_cme ? (
                  <object data={CMEIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />
                ) : (
                  ''
                )}
              </Badge>
              <ButtonGroup className="text-right">
                {course.follow?.length ? (
                  <ActiveSmallBtn className="a_followed" onClick={e => followCourse(e, course)}>
                    <span className="following">
                      <i className="fas fa-check mr-2" />
                      Following
                    </span>
                    <span className="unfollow">
                      <i className="fas fa-times mr-2" />
                      Unfollow
                    </span>
                  </ActiveSmallBtn>
                ) : (
                  <DefaultSmallBtn className="a_follow" onClick={e => followCourse(e, course)}>
                    <i className="fas fa-plus mr-2" />
                    Follow
                  </DefaultSmallBtn>
                )}
                {loggedInUser ? (
                  [
                    !playlistUpdating ? (
                      <OverlayTrigger
                        trigger="click"
                        placement="auto-start"
                        overlay={props => (
                          <CoursePlaylistPopover
                            props={props}
                            allPlaylist={playlist}
                            onNew={createAndAddToPlaylist}
                            onExisting={updateExistingPlaylist}
                            courseVideos={course.videos}
                            userState={userState}
                            showUpgradeModal={isShowPlaylistUpgradeModal}
                            onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                          />
                        )}
                        rootClose
                      >
                        <Button
                          variant="basic"
                          className="a_playlist"
                          onClick={() => setShowPlaylistUpgradeModal(true)}
                        >
                          <i className="fak fa-list-ul" />
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <div className="p-0 mr-3 btn">
                        <Spinner animation="border" size="sm" variant="primary" />
                      </div>
                    ),
                  ]
                ) : (
                  <Button variant="basic" className="a_playlist" onClick={() => location.push('/login')}>
                    <i className="fak fa-list-ul" />
                  </Button>
                )}
                {account?.subscription?.name?.includes('Basic') ? (
                  <Button
                    variant="basic"
                    className="px-0 py-0 a_bookmark mr-0"
                    onClick={() => setShowBookmarkUpgradeModal(true)}
                  >
                    <i className="far fa-bookmark" />
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="basic"
                      className={`a_bookmark ${course.bookmark?.length ? 'd-none' : ''} mr-0`}
                      onClick={e => bookmarkCourse(e, course)}
                    >
                      <i className="far fa-bookmark" />
                    </Button>
                    <Button
                      variant="basic"
                      className={`a_bookmark ${course.bookmark?.length ? 'bookmark-fill' : 'd-none'} mr-0`}
                      onClick={e => bookmarkCourse(e, course)}
                    >
                      <i className="fas fa-bookmark" />
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </div>
            <CourseTitle>
              <h5>{course.title}</h5>
            </CourseTitle>
            <Organization>
              {course.organization ? course.organization.name : ''}
              {course.organization && course.specialty.length > 0 ? ' | ' : ''}
              {course.specialty?.length > 0
                ? `${course?.specialty
                    .slice(0, 2)
                    ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                : ''}
            </Organization>
            {course.earliest_date && course.latest_date ? (
              <ReleaseDate>
                <Moment format="MM/YYYY">{course?.earliest_date}</Moment>
                {state.sameDate ? (
                  ''
                ) : (
                  <>
                    <span> - </span>
                    <Moment format="MM/YYYY">{course?.latest_date}</Moment>
                  </>
                )}
              </ReleaseDate>
            ) : (
              ''
            )}
          </div>
        </div>
      </MobFilter>
    </div>
  );
};

export default CourseCard;
