import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Container, Row, Col, Button, Form, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useStore} from '../../store';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import AlertComponent from '../../components/AlertComponent';
import PlaylistCard from './components/playlistCard';
import {Main, Title, PlaylistIcon, EmptyList, SortSelect} from './elements/playlist';
import PlaylistModal from './components/PlaylistModal';
import PlaylistForm from './components/PlaylistForm';
import UpgradeModal from '../../components/UpgradeModal';

// Playlist page
const Playlist = observer(() => {
  const [isLoading, setLoading] = useState(false);
  const {playlistStore, alertStore, accountStore} = useStore();
  const {playlist, getAllPlaylists, sort, changeSort} = playlistStore;
  const {message, status, resetAlert} = alertStore;
  const {account} = accountStore;
  const location = useHistory();
  const gotoPlaylistDetail = id => {
    location.push(`/playlist/${id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllPlaylists(sort, 'created_at').then(() => setLoading(false));
  }, [getAllPlaylists, sort]);

  /* Create Playlist modal state */
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(true);

  const renderPlaylist = () => {
    return playlist?.length ? (
      <>
        <div className="py-4 sortDiv">
          <Form className="text-right pb-4">
            <Form.Label className="mr-2" htmlFor="sort-video">
              Sort
            </Form.Label>
            <SortSelect id="sort-video" value={sort} onChange={e => changeSort(e.target.value)}>
              <option value="DESC">Newest</option>
              <option value="ASC">Oldest</option>
            </SortSelect>
          </Form>
        </div>
        <Row id="playlists">
          {playlist?.map(item => (
            <Col
              lg={3}
              md={4}
              sm={6}
              className="mb-4"
              role="button"
              tabIndex="0"
              onClick={() => gotoPlaylistDetail(item.id)}
              onKeyDown={() => gotoPlaylistDetail(item.id)}
              key={item.id}
            >
              <PlaylistCard
                title={item.name}
                videoCount={item.videos.length}
                thumbnail={item.videos[0]?.thumbnail}
                accessCount={item.accesses.length}
              />
            </Col>
          ))}
        </Row>
      </>
    ) : (
      <EmptyList className="text-center">
        <PlaylistIcon>
          <i className="fak fa-list-ul" />
        </PlaylistIcon>
        <h4>Welcome to Playlists</h4>
        <h6>Save and organize your content to watch at a later date.</h6>
      </EmptyList>
    );
  };

  return (
    <>
      <div id="following" className="d-flex flex-column flex-fill overflow-hidden">
        <Helmet
          title="GIBLIB"
          meta={[
            {
              name: 'description',
              content:
                "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
            },
            {
              property: 'og:title',
              content: 'GIBLIB',
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:image',
              content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
            },
            {
              name: 'image',
              property: 'og:image',
              content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
            },
            {
              property: 'og:url',
              content: window.location.origin + window.location.pathname,
            },
          ]}
        />
        <Navbar />
        <div className="d-flex">
          <Sidebar />
          <Main className="px-4 w-100">
            {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
            <Container>
              <Row style={{marginBottom: '16px'}} className="pt-4">
                <Col>
                  <Title>Playlists</Title>
                </Col>
                <Col className="text-right">
                  <Button
                    variant="primary"
                    className="a_create"
                    onClick={() => {
                      setShowCreateModal(true);
                    }}
                  >
                    {' '}
                    Create{' '}
                  </Button>
                </Col>
              </Row>
              {account?.subscription?.name?.includes('Basic') || account?.subscription?.name?.includes('Free') ? (
                <UpgradeModal
                  access="playlist"
                  isShow={isShowUpgradeModal}
                  onHideModal={() => setShowUpgradeModal(false)}
                />
              ) : null}
              {isLoading ? (
                <div className="d-flex vh-100 justify-content-center align-items-center">
                  <Spinner animation="grow" />
                </div>
              ) : (
                renderPlaylist()
              )}
            </Container>
            {!isLoading && <Footer />}
          </Main>
        </div>
      </div>
      {showCreateModal ? (
        <PlaylistModal
          handleModalClose={() => {
            setShowCreateModal(false);
          }}
        >
          <PlaylistForm onFinish={() => setShowCreateModal(false)} />
        </PlaylistModal>
      ) : null}
    </>
  );
});

export default Playlist;
