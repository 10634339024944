import styled from 'styled-components';
import {Modal} from 'react-bootstrap';

export const PastNotesTitle = styled.h5`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-top: 20px;
`;

export const Wrap = styled.div`
  .a_textArea {
    margin-top: 11px;
    padding: 12px;
    background: ${({theme}) => theme.content_basic_bg};
    border-color: ${({theme}) => theme.content_border};
    min-height: 126px;
    margin-bottom: 16px;
  }

  &.notes-wrap-on-ad {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .a_textArea:focus,
  .bgTextarea {
    background: #eaecf1;
    font-size: 14px;
    color: #4e505a;
    min-height: 126px;
    border: none;
    outline: none;
    margin-bottom: 16px;
  }

  .a_textArea::placeholder {
    font-size: 14px;
    color: #b8bbbe;
  }

  .a_saveNote {
    height: 32px;
    width: 105px;
    border-radius: 4px;
    font-weight: 500;
    letter-spacing: -0.11px;
    line-height: 16px;
  }

  .a_saveNoteDisabled {
    background-color: ${({theme}) => theme.content_active_bg};
    color: ${({theme}) => theme.text};
    border: none;
  }

  .a_iconDiv {
    height: 50px;
    width: 50px;
    background-color: #e04463;
    border-radius: 30px;
    margin-top: 24px;
  }

  .a_notesUpgradeText {
    color: ${({theme}) => theme.text};
    width: 276px;
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
    text-align: center;
    margin-top: 11px;
  }

  .a_upgradeButton {
    height: 32px;
    width: 105px;
  }

  .a_notesListDiv {
    overflow-y: auto;
    max-height: calc(100% - 315px);

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: none;
    }

    &::-webkit-scrollbar-thumb {
      background: none;
    }
  }
  .a_downloadNote {
    color: ${({theme}) => theme.text};

    span {
      font-size: 15px;
    }
  }
  .a_notesListScroll {
    margin-right: -14px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #4e505a;
    }

    &::-webkit-scrollbar-thumb {
      height: 31px;
      background: #008aff;
      border-radius: 50px;
    }
  }
`;

export const TimeStamp = styled.div`
  min-width: 60px;
  font-size: 12px;
  margin-bottom: 5px;
  display: inline-block;
  padding-right: 22px;

  i {
    margin-right: 2px;
  }
`;

export const CardWrap = styled.div`
  box-shadow: inset 0 -1px 0 0 #4e505a;
  padding: 1px;
  margin: 15px 0;
  padding-bottom: 15px;

  &:focus {
    outline: none;
  }

  .a_note {
    font-size: 15px;
  }

  .a_actionDiv {
    color: ${({theme}) => theme.text};
    font-size: 12px;
    letter-spacing: -0.08px;
    line-height: 20px;
  }
  .a_editNoteButton,
  .a_deleteNoteButton {
    &:focus {
      outline: none;
    }
  }
`;

export const DeleteModal = styled(Modal)`
  .modal-dialog {
    max-width: 459px !important;
    min-height: 225px !important;
    margin: 7.75rem auto !important;
  }
  .modal-content {
    width: 425px;
    border: none !important;
    background-color: ${({theme}) => theme.content_basic_bg};
    border-radius: 8px;

    .a_DeleteModalContent {
      color: #ffffff;
      font-size: 12px;
      letter-spacing: -0.2px;
      line-height: 24px;
      .a_upgradePlanEmail {
        color: #0677d8;
      }
    }
    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-body {
      padding-top: 0px;
      padding-bottom: 22px;
    }
    .modal-footer {
      border: none !important;
    }
  }
  &.paymentInfoModal .modal-dialog {
    max-width: 636px;
  }
`;
