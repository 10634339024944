import axios from './axios';

export const getAds = type => axios.get(`/ads/${type}`);
export const trackAds = (ad_id, impression_type, impression_source, device, ad_type, is_answered, survey_id) =>
  axios.post(`/ads/track`, {ad_id, impression_type, impression_source, device, ad_type, is_answered, survey_id});

export const updateTrackAds = (id, is_bumper_skipped, ad_type, is_answered) =>
  axios.put(`/ads/${id}`, {is_bumper_skipped, ad_type, is_answered});

export const surveyResponse = (survey_id, answers) => axios.post(`ads/survey/response`, {survey_id, answers});
