import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Container, Spinner, Button, Form, Dropdown} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ConferenceCard from './Components/ConferenceCard';
import Filter from './Components/ConferenceFilter';
import ConferenceCarousel from './Components/ConferenceCarousel';
import {Main, ConferenceFilter, ChipText} from './elements/conferences';
import {PaginationContainer} from '../Bookmarks/elements/styles';
import close from './elements/close-chip.png';
import {useStore} from '../../store';
import AlertComponent from '../../components/AlertComponent';
import {amplitude} from '../../utils/Amplitude';
import PageBottom from '../../components/PageBottom';

// Conferences page
const Conferences = observer(() => {
  const {conferenceStore, alertStore} = useStore();
  const location = useHistory();
  const {
    professionsList,
    listProfessions,
    conferenceSpecialtiesList,
    listConferenceSpecialties,
    conferenceHeros,
    fetchHeros,
    conferencesList,
    loading,
    selectedProfessionList,
    onRemoveProfession,
    selectedSpecialtiesList,
    selectedProfessions,
    selectedSpecialties,
    sortBy,
    sortOrder,
    isCme,
    conferenceCount,
    page,
    count,
    pageIncrement,
    pageDecrement,
    changeCount,
    listConferences,
    onRemoveSpecialty,
    onCreateBookmark,
    onDeleteBookmark,
    onFollowConference,
    onUnfollowConference,
    playlist,
    getAllPlaylists,
  } = conferenceStore;

  const {message, status, resetAlert} = alertStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  useEffect(() => {
    listProfessions();
    listConferenceSpecialties();
    amplitude.getInstance().logEvent('conference-list-page-viewed');
  }, [listProfessions, listConferenceSpecialties]);

  useEffect(() => {
    fetchHeros();
  }, [fetchHeros]);

  useEffect(() => {
    listConferences();
  }, [selectedProfessions, selectedSpecialties, sortBy, sortOrder, isCme, page, count, listConferences]);

  const removeProfession = item => {
    onRemoveProfession(item.id);
  };

  const removeSpecialty = item => {
    onRemoveSpecialty(item.id);
  };

  const onHandleFollow = item => {
    const user = localStorage.getItem('user');
    if (user) {
      if (item.follow?.length) {
        onUnfollowConference(item);
      } else {
        onFollowConference(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  const onHandleBookmark = item => {
    const user = localStorage.getItem('user');
    if (user) {
      if (item.bookmark?.length) {
        onDeleteBookmark(item);
      } else {
        onCreateBookmark(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  return (
    <div id="conferences" className="d-flex flex-column flex-fill">
      <Helmet
        title="GIBLIB | Conferences"
        meta={[
          {
            name: 'description',
            content:
              'Access curated medical conferences from the leading academic medical institutions of all topics and specialties.',
          },
          {
            property: 'og:title',
            content: 'GIBLIB | Conferences',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
        <Main role="main" className="w-100">
          <ConferenceCarousel conference={conferenceHeros} />
          <ConferenceFilter>
            <Filter professionFilter={professionsList} specialityFilter={conferenceSpecialtiesList} />
          </ConferenceFilter>
          <div className="conferenceBorder-top">
            <div className="d-flex container mt-2 flex-wrap">
              {selectedProfessionList.map(item => (
                <ChipText>
                  {item.name}
                  <span>
                    <button type="button" className="bg-transparent border-0" onClick={() => removeProfession(item)}>
                      <img alt="close" src={close} />
                    </button>
                  </span>
                </ChipText>
              ))}
              {selectedSpecialtiesList.map(item => (
                <ChipText>
                  {item.name}
                  <span>
                    <button type="button" className="bg-transparent border-0" onClick={() => removeSpecialty(item)}>
                      <img alt="close" src={close} />
                    </button>
                  </span>
                </ChipText>
              ))}
            </div>
            <Container id="conference_container">
              <div className="py-3 conferenceBorder-bottom d-flex justify-content-between">
                <span>
                  {conferenceCount} {conferenceCount === 1 ? 'Result' : 'Results'}
                </span>
                <PaginationContainer className="text-right">
                  <span>
                    {conferenceCount === 0 ? <>0 </> : <>{page * count - (count - 1)} </>}-{' '}
                    {page * count > conferenceCount ? <>{conferenceCount} </> : <>{page * count} </>}
                    of {conferenceCount}
                  </span>
                  <Button
                    onClick={pageDecrement}
                    variant="basic"
                    className="a_p_dec"
                    disabled={page * count - (count - 1) === 1}
                  >
                    <i className="far fa-angle-left" />
                  </Button>
                  <Button
                    onClick={pageIncrement}
                    variant="basic"
                    className="a_p_inc"
                    disabled={page * count >= conferenceCount}
                  >
                    <i className="far fa-angle-right" />
                  </Button>
                  <Dropdown>
                    <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
                      <i className="fas fa-ellipsis-v" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu onChange={e => changeCount(e.target.value)}>
                      <Form.Check
                        type="radio"
                        label="10 per page"
                        id="limit_10"
                        value="10"
                        name="count"
                        checked={parseInt(count, 10) === 10}
                      />
                      <Form.Check
                        type="radio"
                        label="25 per page"
                        id="limit_25"
                        value="25"
                        name="count"
                        checked={parseInt(count, 10) === 25}
                      />
                      <Form.Check
                        type="radio"
                        label="50 per page"
                        id="limit_50"
                        value="50"
                        name="count"
                        checked={parseInt(count, 10) === 50}
                      />
                      <Form.Check
                        type="radio"
                        label="100 per page"
                        id="limit_100"
                        value="100"
                        name="count"
                        checked={parseInt(count, 10) === 100}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </PaginationContainer>
              </div>
              {loading ? (
                <div className="d-flex justify-content-center mb-5">
                  <Spinner animation="grow" />
                </div>
              ) : (
                conferencesList.map(conference => (
                  <ConferenceCard
                    key={conference.id}
                    conference={conference}
                    playlist={playlist}
                    onFollow={onHandleFollow}
                    onBookmark={onHandleBookmark}
                    playlistStore="conferenceStore"
                  />
                ))
              )}
            </Container>
          </div>
          {!loading && <Footer />}
        </Main>
      </div>
      <PageBottom />
    </div>
  );
});

export default Conferences;
