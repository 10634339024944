import {observer} from 'mobx-react-lite';
import {React, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import {useStore} from '../../store';
import {EmptyList, PlaylistIcon} from '../Playlist/elements/playlist';
import {DashboardStyles} from './elements/styles';
import VideoList from './components/videoList';

const AdminDashBoard = observer(() => {
  const {videoStore} = useStore();
  const {userVideos, fetchUserUploadVideos, videoModeration} = videoStore;

  useEffect(() => {
    fetchUserUploadVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateModeration = (id, data) => {
    videoModeration(id, data);
  };
  return (
    <div id="dashboard" className="d-flex flex-column">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <DashboardStyles>
          <div className="containerTab d-flex w-100">
            {userVideos?.length ? (
              <div className="w-100">
                {userVideos?.map(item => (
                  <VideoList data={item} updateModeration={updateModeration} />
                ))}
              </div>
            ) : (
              <EmptyList className="text-center w-100">
                <div>
                  <PlaylistIcon>
                    <i className="fak fa-list-ul" />
                  </PlaylistIcon>
                  <h4>No videos available</h4>
                  <h6>Didnt find any user uploaded videos</h6>
                </div>
              </EmptyList>
            )}
          </div>
        </DashboardStyles>
      </div>
    </div>
  );
});

export default AdminDashBoard;
