// @flow
import React, {useState, useRef} from 'react';
import {Overlay, Nav} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {BrowseDiv} from '../sidebar';
import OrganizationBrowse from './OrganizationBrowse';
/* import SponsoredBrowse from './SponsoredBrowse'; */
import {amplitude} from '../../../utils/Amplitude';

const BrowseDropdown = observer(() => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const wrapperRef = useRef(null);

  const onHoverLeave = () => {
    setShow(false);
  };

  const BrowseClicked = () => {
    amplitude.getInstance().logEvent('browse-clicked');
  };

  return (
    <div ref={wrapperRef} onMouseLeave={onHoverLeave}>
      <Nav.Item
        id="open-browse-sidebar"
        data-testid="open-browse-sidebar"
        className={`a_openBrowseSidebarButton ${show ? 'browse-active' : 'not-active'}`}
        ref={target}
        onClick={() => setShow(!show)}
      >
        <i className="fak fa-globe" />
        <span className="collapseText pl-1">Browse</span>
        <i className="fal fa-chevron-right" />
      </Nav.Item>
      <Overlay target={target.current} show={show} placement="right">
        {({placement, arrowProps, show: _show, popper, ...props}) => (
          <BrowseDiv {...props}>
            <div className="row flex-column ml-3">
              <Nav.Item onClick={BrowseClicked}>
                <Nav.Link exact as={NavLink} to="/browse/specialty" id="sidebar_specialty">
                  <span className="collapseText">Specialty</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={BrowseClicked}>
                <Nav.Link exact as={NavLink} to="/conferences" id="sidebar_conferences">
                  <span className="collapseText">Conferences</span>
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item onClick={BrowseClicked}>
                <Nav.Link exact as={NavLink} to="/courses" id="sidebar_courses">
                  <span className="collapseText">Courses</span>
                </Nav.Link>
              </Nav.Item> */}
              <OrganizationBrowse />
              <Nav.Item className="browse-item" onClick={BrowseClicked}>
                <Nav.Link exact as={NavLink} id="Experts" to="/experts">
                  <span className="collapseText">Experts</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={BrowseClicked}>
                <Nav.Link exact as={NavLink} id="360VR" to="/360VR">
                  <span className="collapseText">360VR</span>
                </Nav.Link>
              </Nav.Item>
              {/* <SponsoredBrowse /> */}
            </div>
          </BrowseDiv>
        )}
      </Overlay>
    </div>
  );
});

export default BrowseDropdown;
