import {Modal} from 'react-bootstrap';
import styled from 'styled-components';

const NotAvailableModal = styled(Modal)`
  text-align: center;
  z-index: 999999;
  .modal-dialog {
    width: 459px;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;
    height: 225px;

    .a_notAccessModalHeader {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
      width: 116px;
    }
    .a_notAccessModalContent {
      font-size: 12px;
      letter-spacing: -0.2px;
      line-height: 24px;
      margin-top: 30px;
      margin-right: 44px;
      width: 391px;
    }
    .modal-body {
      padding-left: 24px;
    }
    .a_logoutButton {
      margin-top: 48px;
      margin-left: 328px;
    }
  }
`;

export default NotAvailableModal;
