import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Container, Nav} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
// import Image from 'react-bootstrap/Image';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ProfileDashboard from './components/dashboard';
import ProfileSettings from './components/settings';
import {useStore} from '../../store';
import './profile.css';
import {AccountDiv, AccountHeader, ProfileTab, NavItem, Link} from './elements/profile';
import YourVideos from './components/yourVideos';
import Messages from './components/messages';
import UploadVideos from './components/uploadVideos';

const Profile = observer(() => {
  const {accountStore, authStore} = useStore();
  const match = useRouteMatch();

  const {account, isAccountLoading} = accountStore;
  const {professionList, specialtyList} = authStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getProfessionName = () => {
    const profession = professionList?.find(p => p.id === Number(account?.profession_id));
    let displayName = profession?.display_name;
    if (profession?.specialty_type === 'other') {
      displayName = account?.extra_data?.other_profession;
    }
    return displayName;
  };

  const getSpecialtyName = () => {
    const specialty = specialtyList?.find(p => p.id === Number(account?.specialty_id));
    let displayName = specialty?.display_name;
    if (specialty?.type === 'other') {
      displayName = account?.extra_data?.other_specialty;
    }
    return displayName;
  };

  return (
    <div id="profile" className="d-flex flex-column">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <AccountDiv id="account-Settings" className="flex-fill">
          <AccountHeader className="py-1">
            <Container className="py-5 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div>
                  {/* <Image src={image} style={{height: '72px'}} roundedCircle /> */}
                  <div className="align-items-center d-flex justify-content-center profile-image">
                    {!isAccountLoading
                      ? [
                          account?.first_name?.substring(0, 1).toUpperCase(),
                          account?.last_name?.substring(0, 1).toUpperCase(),
                        ].join('')
                      : null}
                  </div>
                </div>
                <div className="ml-3">
                  <h5 className="mb-2 font-weight-bold">
                    {!isAccountLoading ? [account?.first_name, account?.last_name].join(' ') : <Skeleton width={100} />}
                  </h5>
                  <p className="mb-0">
                    {!isAccountLoading ? getProfessionName() : <Skeleton width={200} />}
                    <br />
                    {!isAccountLoading ? getSpecialtyName() : <Skeleton width={200} />}
                  </p>
                </div>
              </div>
              <div>
                <button type="button" className="a_inviteAColleague">
                  <a
                    href="https://www.giblib.com/referral"
                    className="inviteAColleague"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fal fa-user-plus" alt="user plus icon" />
                    &nbsp;&nbsp;Invite a Colleague
                  </a>
                </button>
              </div>
            </Container>
          </AccountHeader>
          <div>
            <ProfileTab>
              <Container>
                <Nav>
                  <NavItem className="nav-item">
                    <Link className="nav-link a_showDashboard" activeClassName="active" to={`${match.url}/dashboard`}>
                      Dashboard
                    </Link>
                  </NavItem>

                  <NavItem className="nav-item">
                    <Link
                      className="nav-link a_showAccountSettings"
                      id="account-settings"
                      activeClassName="active"
                      to={`${match.url}/upload_video`}
                    >
                      Upload Video
                    </Link>
                  </NavItem>
                  <NavItem className="nav-item">
                    <Link
                      className="nav-link a_showAccountSettings"
                      id="account-settings"
                      activeClassName="active"
                      to={`${match.url}/your_videos`}
                    >
                      My Videos
                    </Link>
                  </NavItem>
                  <NavItem className="nav-item">
                    <Link
                      className="nav-link a_showAccountSettings"
                      id="account-settings"
                      activeClassName="active"
                      to={`${match.url}/messages`}
                    >
                      Messages
                    </Link>
                  </NavItem>
                  <NavItem className="nav-item">
                    <Link
                      className="nav-link a_showAccountSettings"
                      id="account-settings"
                      activeClassName="active"
                      to={`${match.url}/settings`}
                    >
                      Account Settings
                    </Link>
                  </NavItem>
                </Nav>
              </Container>
            </ProfileTab>
            <Container className="pt-3 account-body">
              <Switch>
                <Route path={`${match.path}/dashboard`} render={() => <ProfileDashboard />} />
                <Route exact path={`${match.path}/settings`} render={() => <ProfileSettings />} />
                <Route path={`${match.path}/upload_video`} render={() => <UploadVideos />} />
                <Route path={`${match.path}/your_videos`} render={() => <YourVideos />} />
                <Route path={`${match.path}/messages`} render={() => <Messages />} />
                <Route path={`${match.path}/public_profile`} render={() => <ProfileDashboard />} />
                <Redirect to={`${match.path}/dashboard`} />
              </Switch>
            </Container>
          </div>
          {!isAccountLoading && <Footer />}
        </AccountDiv>
      </div>
    </div>
  );
});

export default Profile;
