import {makeAutoObservable} from 'mobx';
import {getAll} from '../../api/contentPackage';

type ContentPackage = {
  name: string,
  videos: {id: string}[],
};

export default class ContentPackageStore {
  contentPackages: ContentPackage[] = [];

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchAll() {
    this.loading = true;
    this.contentPackages = await getAll();
    this.loading = false;
  }

  getById(id) {
    return this.contentPackages.find(cp => cp.id == id);
  }
}
