import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Button, Container, ProgressBar, Form, Row, Col} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {
  ThumbnailDiv,
  ThumbnailImg,
  ButtonGroup,
  VideoTitle,
  Organization,
  Details,
  VideoCard,
  UpdateModal,
  Label,
  CustomCheckbox,
  Specialty,
  DetailDiv,
} from '../elements/playlist';
import {secondsToTime} from '../../../utils/helper';
import {useStore} from '../../../store';

type Props = {
  item: {
    id: number,
    title: string,
    watchProgress: number,
    duration: number,
    brightcove_id: string,
    cme: boolean,
    organizationCME: any,
    thumbnail: string,
    bookmark: Array,
    organization: {
      name: string,
    },
    audience_specialty: {
      name: string,
    },
    order: number,
    PlaylistVideo: Array,
  },
  handleDrag: Function,
  handleDrop: Function,
  updateExistingPlaylist: Function,
  deleteConfirmation: boolean,
  updateDeleteConfirmation: Function,
  editable: Boolean,
};

const PlaylistVideo = observer(
  ({
    item,
    handleDrag,
    handleDrop,
    updateExistingPlaylist,
    deleteConfirmation,
    updateDeleteConfirmation,
    editable,
  }: Props) => {
    /* Delete Playlist modal state */
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDeleteModalShow = () => setShowDeleteModal(true);
    const handleDeleteModalClose = () => setShowDeleteModal(false);
    const {register, handleSubmit} = useForm();
    const match = useRouteMatch();
    const location = useHistory();
    const playlistId = match.params?.id;
    const {createBookmark, deleteBookmark} = useStore().playlistStore;
    const {setReferrer} = useStore().videoStore;
    const handleSubmitForm = data => {
      if (data.deleteCheck) {
        updateDeleteConfirmation(false);
      }
      updateExistingPlaylist(item.id);
      setShowDeleteModal(false);
    };

    const handleDeleteModal = e => {
      e.stopPropagation();
      if (deleteConfirmation) {
        handleDeleteModalShow();
      } else {
        updateExistingPlaylist(item.id);
      }
    };

    const [bookmarkIcon, setBookmarkIcon] = useState(false);

    useEffect(() => {
      if (item.bookmark?.length) setBookmarkIcon(true);
    }, [item]);

    const onHandleBookmark = (e, selectedItem) => {
      e.stopPropagation();
      const user = localStorage.getItem('user');
      if (user) {
        if (selectedItem.bookmark?.length) {
          deleteBookmark(selectedItem).then(() => {
            setBookmarkIcon(false);
          });
        } else {
          createBookmark(selectedItem.id).then(() => {
            setBookmarkIcon(true);
          });
        }
      } else {
        location.push('/login');
      }
    };

    const openVideo = () => {
      setReferrer('playlist page');
      location.push(`/video/${item.id}?playlist_id=${playlistId}&order=${item.PlaylistVideo.order}`);
    };

    const renderButtons = () => {
      return (
        <ButtonGroup className="text-right">
          <Button
            variant="basic"
            className={`a_bookmark ${bookmarkIcon ? 'd-none' : ''}`}
            onClick={e => onHandleBookmark(e, item)}
          >
            <i className="far fa-bookmark" />
          </Button>
          <Button
            variant="basic"
            className={`a_bookmark ${bookmarkIcon ? 'bookmark-fill' : 'd-none'}`}
            onClick={e => onHandleBookmark(e, item)}
          >
            <i className="fas fa-bookmark" />
          </Button>
          <Button variant="basic" className="a_remove" onClick={e => handleDeleteModal(e)}>
            <i className="fal fa-trash-alt" />
          </Button>
        </ButtonGroup>
      );
    };

    return (
      <>
        <VideoCard
          draggable={editable}
          onDragOver={ev => ev.preventDefault()}
          onDragStart={() => handleDrag(item.id)}
          onDrop={handleDrop}
          id={item.id}
        >
          <Container>
            {editable ? (
              <div className="drag-icon">
                <i className="far fa-bars" />
              </div>
            ) : null}

            <div
              className="card-border-bottom d-flex card-padding"
              id={item.id}
              role="button"
              tabIndex="0"
              onKeyDown={() => openVideo()}
              onClick={() => openVideo()}
            >
              <ThumbnailDiv>
                <ThumbnailImg src={item.thumbnail} alt={item.title} />
                <ProgressBar now={item.watchProgress} />
              </ThumbnailDiv>
              <DetailDiv className="w-100">
                <VideoTitle>
                  <h5>{item.title}</h5>
                </VideoTitle>
                <Specialty>{item?.audience_specialty?.name}</Specialty>
                <Organization>{item?.organization?.name}</Organization>
                <Details>
                  {secondsToTime(item.duration)}
                  {item?.organizationCME?.CMEs?.length ? (
                    <>
                      <span> &bull;</span> <span className="text-success">CME Completed</span>
                    </>
                  ) : (
                    item?.organizationCME && <span> &bull; CME Credits Offered</span>
                  )}
                </Details>
              </DetailDiv>
              {editable ? renderButtons() : null}
            </div>
          </Container>
        </VideoCard>
        {/* Update Modal to delete video from Playlist */}
        <UpdateModal show={showDeleteModal} onHide={handleDeleteModalClose} className="deletePlaylistModal">
          <UpdateModal.Header closeButton>Remove From Playlist</UpdateModal.Header>
          <UpdateModal.Body>
            <Form onSubmit={handleSubmit(handleSubmitForm)}>
              <p>Are you sure you want to delete {item.title} from this playlist?</p>
              <Row id="deleteCheckbox">
                <Col sm={7}>
                  <Form.Group controlId="formBasicCheckbox" className="mb-0">
                    <Label htmlFor="checkboxConfirm" className="checkLabel">
                      <input type="checkbox" id="checkboxConfirm" name="deleteCheck" ref={register()} />
                      Don&apos;t show this again
                      <CustomCheckbox />
                    </Label>
                  </Form.Group>
                </Col>
                <Col sm={5} className="text-right">
                  <Button
                    type="submit"
                    variant="outline-secondary"
                    id="remove-playlist-video"
                    className="a_removeVideoButton"
                  >
                    Yes, Remove
                  </Button>
                </Col>
              </Row>
            </Form>
          </UpdateModal.Body>
        </UpdateModal>
      </>
    );
  },
);

export default PlaylistVideo;
