import React, {useState} from 'react';
import {Breadcrumb, Container, Row, Col, Dropdown, Button, Form} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {InformationDiv, PlaylistTitle, UpdateModal} from '../elements/playlist';
import PlaylistModal from './PlaylistModal';
import PlaylistForm from './PlaylistForm';
import {useStore} from '../../../store';

type Props = {
  playlist: any,
  handledeletePlayList: Function,
  renamePlaylist: Function,
};

const PlaylistInformation = observer(({playlist, handledeletePlayList}: Props) => {
  const handlePlaylistDelete = e => {
    e.stopPropagation();
    handledeletePlayList();
  };

  const isInstitutional = !!playlist?.accesses?.length;
  const {account} = useStore().accountStore;
  const editable = playlist?.user_id == account?.id;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);
  const handleDeleteModalClose = () => setShowDeleteModal(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditModalShow = () => setShowEditModal(true);
  return playlist ? (
    <>
      <InformationDiv>
        <Container>
          <Row>
            <Col sm={9}>
              <Breadcrumb>
                <Breadcrumb.Item href="/playlist">Playlist</Breadcrumb.Item>
                <Breadcrumb.Item active>{playlist.name}</Breadcrumb.Item>
              </Breadcrumb>
              <PlaylistTitle className="mb-0">{playlist.name}</PlaylistTitle>
              <p>{playlist.videos.length} Videos in Playlist</p>
              {isInstitutional && account.role == 'institution_manager' ? (
                <>
                  <div>Cohorts</div>
                  <ul>
                    {playlist.accesses.map(access => (
                      <li>{access.access_name}</li>
                    ))}
                  </ul>
                </>
              ) : null}
            </Col>
            <Col sm={3}>
              {editable ? (
                <Dropdown>
                  <Dropdown.Toggle variant="basic" id="playlistMenu" className="a_playlist-menu">
                    <i className="fas fa-ellipsis-h" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleEditModalShow}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={handleDeleteModalShow}>Delete Playlist</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </Col>
          </Row>
        </Container>
      </InformationDiv>
      {/* Update Modal to delete Playlist */}
      <UpdateModal show={showDeleteModal} onHide={handleDeleteModalClose} className="deletePlaylistModal">
        <UpdateModal.Header closeButton>Delete Playlist</UpdateModal.Header>
        <UpdateModal.Body>
          <Form onSubmit={e => handlePlaylistDelete(e)}>
            <p>
              Are you sure you want to delete the `{playlist.name}` playlist? All the videos you have added to the
              playlist will be deleted.
            </p>
            <div className="w-100 d-flex flex-row-reverse">
              <Button
                type="submit"
                variant="outline-secondary"
                id="save-account-info"
                className="a_deletePlaylistButton"
              >
                Yes, Delete
              </Button>
            </div>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>
      {showEditModal ? (
        <PlaylistModal handleModalClose={() => setShowEditModal(false)}>
          <PlaylistForm playlist={playlist} onFinish={() => setShowEditModal(false)} />
        </PlaylistModal>
      ) : null}
    </>
  ) : null;
});

export default PlaylistInformation;
