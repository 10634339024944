import styled from 'styled-components';

export const DashboardStyles = styled.div`
  width: 100vw;
  overflow: hidden;
  .containerTab {
    padding: 80px 20px 0 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 95vh;
    justify-content: space-between;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 0px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .text-center {
    display: grid;
    place-items: center;
  }
`;
export const VideoListStyles = styled.div`
  .listContainer {
    width: 100%;
    border-bottom: 1px solid #4e505a;
    :hover {
      border: 1px solid #30a0ff;
      background-color: #2e3038;
      box-shadow: 0 8px 10px 0 rgb(0 0 0 / 50%);
    }
    cursor: pointer;
    .listcontent {
      position: relative;
      padding: 15px 0;
      margin: 0 auto;
      justify-content: center;
    }
    .shortDescription {
      text-transform: capitalize;
      font-weight: 600;
    }
    .longDescription {
      max-width: 680px;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .text-wrong {
      color: #cc1c1c;
    }
    .a_remove,
    .a_check {
      padding: 0;
      width: 24px;
      height: 24px;
      text-align: center;
      margin-right: 15px;
      color: #b8bbbe;
    }
    .a_remove :hover {
      filter: invert(34%) sepia(79%) saturate(6056%) hue-rotate(351deg) brightness(100%) contrast(103%);
    }
    .a_check :hover {
      filter: invert(19%) sepia(40%) saturate(4747%) hue-rotate(123deg) brightness(102%) contrast(97%);
    }

    .videothumbnail {
      width: 300px;
      height: 200px;
      background: red;
      margin-bottom: 10px;
    }
    .text-right {
      width: 100px;
    }
  }
`;
