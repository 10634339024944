import {action, makeObservable, observable, runInAction} from 'mobx';
import {getConference, getConferenceVideos, getRelatedConferences} from '../../api/conferenceLanding';
import {createBookmark, deleteBookmark, followItem, unfollowItem} from '../../api/conference';
import {getAllPlaylists, addToPlaylist, massUpdatePlaylists} from '../../api/video';
import {addConferencePlaylist, addNewConferencePlaylist} from '../../api/playlist';
import ErrorStore from '../error/errorStore';
import MediaStore from '../media/mediaStore';
import AlertStore from '../alert/alertStore';
import PlaylistStore from '../playlist/playlistStore';

class conferenceLandingStore {
  errorStore: ErrorStore;

  mediaStore: MediaStore;

  alertStore: AlertStore;

  playlistStore: PlaylistStore;

  conference: Array = [];

  conferenceVideos: Array = [];

  relatedConferences: Array = [];

  playlist: Array = [];

  constructor({errorStore, mediaStore, alertStore, playlistStore}) {
    this.errorStore = errorStore;
    this.mediaStore = mediaStore;
    this.alertStore = alertStore;
    this.playlistStore = playlistStore;
    makeObservable(this, {
      conference: observable,
      conferenceVideos: observable,
      relatedConferences: observable,
      playlist: observable,
      onCreateBookmarkConference: action,
      onDeleteBookmarkConference: action,
      onFollowConference: action,
      onUnfollowConference: action,
      onBookmarkConferenceVideo: action,
      onDeleteBookmarkConferenceVideo: action,
      onBookmarkRelatedConferences: action,
      onDeleteBookmarkRelatedConferences: action,
      onFollowRelatedConferences: action,
      onUnfollowRelatedConferences: action,
      setPlaylistObservables: action,
      getAllPlaylists: action,
      addToNewPlaylist: action,
      addToPlaylist: action,
      addConferenceToPlaylist: action,
      addConferenceToNewPlaylist: action,
    });
  }

  setPlaylistObservables = playlist => {
    this.playlist = playlist;
    this.playlistStore.setPlaylist(playlist);
  };

  getAllPlaylists = async () => {
    try {
      const res = await getAllPlaylists();
      if (res.data.status) {
        this.setPlaylistObservables(res.data.playlist);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addToNewPlaylist = async (videoId: number, name: string) => {
    const updatedVideos = [...this.conferenceVideos];
    try {
      const res = await addToPlaylist(videoId, name);
      const {status} = res.data;
      if (status) {
        this.alertStore.setMessage('Playlist created successfully', 'success');
        const index = this.conferenceVideos.findIndex(video => video.video_id === videoId);
        updatedVideos[index].video.playlist.push(res.data.playlist);
        this.setConferenceVideosObservables(updatedVideos);
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addToPlaylist = async (videoId: number, data: [{}]) => {
    const updatedVideos = [...this.conferenceVideos];
    try {
      const res = await massUpdatePlaylists(videoId, data);
      const {status} = res.data;
      if (status) {
        this.alertStore.setMessage('Playlist updated successfully', 'success');
        const index = this.conferenceVideos.findIndex(video => +video.video_id === +videoId);
        updatedVideos[index].video.playlist = res.data.playlist;
        this.setConferenceVideosObservables(updatedVideos);
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addConferenceToPlaylist = async (conferenceId, data) => {
    try {
      const resp = await addConferencePlaylist(conferenceId, data);
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Playlist updated successfully', 'success');
        this.getAllPlaylists();
        const respVideo = resp.data.videos;
        this.conferenceVideos.map(cVideo => {
          const item = respVideo.find(el => el.id === cVideo.id);
          if (item) {
            cVideo.video.playlist = item.video.playlist; // eslint-disable-line no-param-reassign
          }
          return cVideo;
        });
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addConferenceToNewPlaylist = async (conferenceId, name) => {
    try {
      const resp = await addNewConferencePlaylist(conferenceId, name);
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Playlist created successfully', 'success');
        this.getAllPlaylists();
        const respVideo = resp.data.videos;
        this.conferenceVideos.map(cVideo => {
          const item = respVideo.find(el => el.id === cVideo.id);
          if (item) {
            cVideo.video.playlist = item.video.playlist; // eslint-disable-line no-param-reassign
          }
          return cVideo;
        });
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getConference = async id => {
    try {
      const resp = await getConference(id);
      const {status} = resp.data;
      if (status) {
        runInAction(() => {
          this.conference = resp.data.conference;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getConferenceData = async (id, cb: () => {}) => {
    try {
      const [conferenceResp, conferenceVideoResp, relatedConferenceResp] = await Promise.all([
        getConference(id),
        getConferenceVideos(id),
        getRelatedConferences(id),
      ]);
      let conference = {};
      if (conferenceResp?.data) {
        conference = conferenceResp?.data?.conference;
      }
      let {videos} = conferenceVideoResp?.data;
      videos = videos.map(item => {
        const newVideo = {...item};
        if (!item.video?.watchHistories) {
          newVideo.video.watchProgress = 0;
        } else {
          const watchTime = Math.max(...item.video?.watchHistories.map(o => o.time_watched), 0);
          const videoDuration = item.video?.duration || 0;
          const watchProgress = (watchTime / videoDuration) * 100;
          newVideo.video.watchProgress = Math.round(watchProgress);
        }
        return newVideo;
      });
      const {conferences} = relatedConferenceResp?.data;
      runInAction(() => {
        this.conference = conference;
        this.setConferenceVideosObservables(videos);
        this.setRelatedConferencesObservables(conferences);
        if (cb) cb(conference?.title);
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set conference video list observables
   * @param {array} professions
   */
  setConferenceVideosObservables = videos => {
    this.conferenceVideos = videos;
  };

  /**
   * set conference video list observables
   * @param {array} professions
   */
  setRelatedConferencesObservables = conferences => {
    this.relatedConferences = conferences;
  };

  /** Create new bookmark for conference */
  onCreateBookmarkConference = async conferenceId => {
    try {
      const resp = await createBookmark(conferenceId, 'conference');
      const {status, bookmark} = resp.data;
      const updatesConference = {...this.conference};
      if (status) {
        runInAction(() => {
          updatesConference.bookmark = [bookmark];
          this.conference = updatesConference;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onDeleteBookmarkConference = async selectedConferenceId => {
    const updatesConference = {...this.conference};
    try {
      const resp = await deleteBookmark(selectedConferenceId, 'conference');
      const {status} = resp.data;
      if (status) {
        runInAction(() => {
          delete updatesConference.bookmark;
          this.conference = updatesConference;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow conference */
  onFollowConference = async conferenceId => {
    const updatesConference = {...this.conference};
    try {
      const resp = await followItem(conferenceId, 'conference');
      const {status, follow} = resp.data;
      if (status) {
        runInAction(() => {
          this.mediaStore.updateCarouselFollow(conferenceId, true, 'conferences');
          updatesConference.follow = [follow];
          this.conference = updatesConference;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow conference */
  onUnfollowConference = async selectedConferenceId => {
    const updatesConference = {...this.conference};
    try {
      const resp = await unfollowItem(selectedConferenceId, 'conference');
      const {status} = resp.data;
      if (status) {
        runInAction(() => {
          this.mediaStore.updateCarouselFollow(selectedConferenceId, false, 'conferences');
          delete updatesConference.follow;
          this.conference = updatesConference;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create new bookmark for conference video */
  onBookmarkConferenceVideo = async videoId => {
    const updatedVideos = [...this.conferenceVideos];
    try {
      const resp = await createBookmark(videoId, 'video');
      const {status, bookmark} = resp.data;
      if (status) {
        const index = updatedVideos.findIndex(video => String(video.video_id) === String(videoId));
        if (index !== -1) {
          updatedVideos[index].video.bookmark = bookmark;
          this.setConferenceVideosObservables(updatedVideos);
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark of conference video */
  onDeleteBookmarkConferenceVideo = async videoId => {
    const updatedVideos = [...this.conferenceVideos];
    try {
      const resp = await deleteBookmark(videoId, 'video');
      const {status} = resp.data;
      if (status) {
        const index = updatedVideos.findIndex(video => String(video.video_id) === String(videoId));
        if (index !== -1) {
          delete updatedVideos[index].video.bookmark;
          this.setConferenceVideosObservables(updatedVideos);
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create new bookmark for conference */
  onBookmarkRelatedConferences = async conferenceId => {
    const updatesConferences = [...this.relatedConferences];
    try {
      const resp = await createBookmark(conferenceId, 'conference');
      const {status, bookmark} = resp.data;
      if (status) {
        const index = updatesConferences.findIndex(conference => conference.id === conferenceId);
        updatesConferences[index].bookmark = [bookmark];
        this.setRelatedConferencesObservables(updatesConferences);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onDeleteBookmarkRelatedConferences = async conferenceId => {
    const updatesConferences = [...this.relatedConferences];
    try {
      const resp = await deleteBookmark(conferenceId, 'conference');
      const {status} = resp.data;
      if (status) {
        const index = updatesConferences.findIndex(uConference => uConference.id === conferenceId);
        delete updatesConferences[index].bookmark;
        this.setRelatedConferencesObservables(updatesConferences);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create new bookmark for conference */
  onFollowRelatedConferences = async conferenceId => {
    const updatesConferences = [...this.relatedConferences];
    try {
      const resp = await followItem(conferenceId, 'conference');
      const {status, follow} = resp.data;
      if (status) {
        const index = updatesConferences.findIndex(conference => conference.id === conferenceId);
        updatesConferences[index].follow = [follow];
        this.setRelatedConferencesObservables(updatesConferences);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onUnfollowRelatedConferences = async conferenceId => {
    const updatesConferences = [...this.relatedConferences];
    try {
      const resp = await unfollowItem(conferenceId, 'conference');
      const {status} = resp.data;
      if (status) {
        const index = updatesConferences.findIndex(uConference => uConference.id === conferenceId);
        delete updatesConferences[index].follow;
        this.setRelatedConferencesObservables(updatesConferences);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default conferenceLandingStore;
