// @flow
import React, {useEffect, useRef} from 'react';
import Image from 'react-bootstrap/Image';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import {Spinner} from 'react-bootstrap';
import {SearchContainer, SearchOptions, OptionText, Type, Description} from './searchDropdown';
import diploma from '../../assets/icons/diploma-fill.png';
import video from '../../assets/icons/video-fill.png';
import {useStore} from '../../store';
import Thumbnail from '../../pages/Courses/elements/video-thumbnail.png';
import stringToURLSlug from '../../utils/helper';
import {amplitude} from '../../utils/Amplitude';
import {ButtonSmall, Title1} from '../../utils/styles/typography';

type Props = {
  clickSeeMore: Function,
  clickOutside: Function,
};

const SearchDropdown = observer(({clickSeeMore, clickOutside}: Props) => {
  const {searchStore, videoStore} = useStore();
  const {isRestrictSearchText, suggestions, isSuggestionLoading, fetchSearchResults, suggestedSearchTerm} = searchStore;
  const {setReferrer} = videoStore;

  const location = useHistory();

  const wrapperRefSearch = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (wrapperRefSearch && wrapperRefSearch.current && !wrapperRefSearch.current.contains(event.target)) {
        clickOutside(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
  }, [wrapperRefSearch, clickOutside, suggestions]);

  const navigate = item => {
    clickOutside(false);
    // The suggestion endpoint is reused here without the suggestion argument so that the search will be logged.
    fetchSearchResults(suggestedSearchTerm);
    if (item.model === 'transcript') {
      setReferrer('search dropdown');
      const firstTranscript = item?.transcripts?.length ? item.transcripts[0] : null;
      location.push({
        pathname: `/video/${item.video.id}`,
        state: {
          start_time: firstTranscript?.start_time,
        },
      });
      const eventProperties = {
        'video id': `${item.video.id}`,
        'video title': `${item.video.title}`,
      };
      amplitude.getInstance().logEvent('search-result-clicked', eventProperties);
    } else if (item.model === 'video') {
      setReferrer('search dropdown');
      location.push(`/video/${item.id}`);
      const eventProperties = {
        'video id': `${item.id}`,
        'video title': `${item.title}`,
      };
      amplitude.getInstance().logEvent('search-result-clicked', eventProperties);
    } else if (item.model === 'organization') {
      const slug = stringToURLSlug(item.name);
      location.push(`/organization/${item.id}/${slug}`);
      const eventProperties = {
        'organization id': `${item.id}`,
        organization: `${item.name}`,
      };
      amplitude.getInstance().logEvent('search-result-clicked', eventProperties);
    } else if (item.model === 'course') {
      const slug = stringToURLSlug(item.title);
      location.push(`/courses/${item.id}/${slug}`);
      const eventProperties = {
        'course id': `${item.id}`,
        course: `${item.title}`,
      };
      amplitude.getInstance().logEvent('search-result-clicked', eventProperties);
    } else if (item.model === 'conference') {
      const slug = stringToURLSlug(item.title);
      location.push(`/conferences/${item.id}/${slug}`);
      const eventProperties = {
        'conference id': `${item.id}`,
        conference: `${item.title}`,
      };
      amplitude.getInstance().logEvent('search-result-clicked', eventProperties);
    } else if (item.model === 'expert') {
      const slug = stringToURLSlug(item.name);
      location.push(`/experts/${item.id}/${slug}`);
      const eventProperties = {
        'expert id': `${item.id}`,
        expert: `${item.name}`,
      };
      amplitude.getInstance().logEvent('search-result-clicked', eventProperties);
    }
  };

  return (
    <SearchContainer ref={wrapperRefSearch}>
      <div className="a_searchHeader mb-0">
        <Title1>
          Suggestions
          {isRestrictSearchText ? (
            <span className="restrict-search-text text-danger">Search length should be less than 200</span>
          ) : null}
        </Title1>
      </div>
      <div className="a_searchBody">
        {isSuggestionLoading ? (
          <div className="text-center">
            <Spinner animation="grow" />
          </div>
        ) : (
          !isRestrictSearchText && (
            <>
              {suggestions?.map(item => (
                <SearchOptions
                  onClick={() => navigate(item)}
                  className={classNames({
                    nodisplay: item.model === 'transcript' && !item.video,
                  })}
                >
                  {item.model === 'course' || item.model === 'conference' ? (
                    <Image src={item.thumbnail_url || Thumbnail} alt="organization" className="a_rectangleDiv" />
                  ) : (
                    <Image
                      src={item.model === 'transcript' ? item.video?.thumbnail : item.thumbnail}
                      alt=" "
                      className={classNames({
                        a_roundDiv: item.model === 'organization' || item.model === 'expert',
                        a_rectangleDiv: item.model === 'video' || item.model === 'transcript',
                      })}
                    />
                  )}
                  {item.model === 'course' ||
                  item.model === 'conference' ||
                  item.model === 'video' ||
                  (item.model === 'transcript' && item.video) ? (
                    <OptionText>
                      <div className="d-flex flex-column a_searchDropdownVideoText text-left">
                        <Type>{item.model}</Type>
                        <div className="title">{item.model === 'transcript' ? item.video?.title : item.title}</div>
                        <Description>
                          <Image src={item.model === 'course' ? diploma : video} alt="video" width="13" />
                          {item.model === 'video' && item.video_type?.includes('360vr') ? (
                            <span className="head-vr">
                              <i className="fas fa-head-vr" />
                            </span>
                          ) : null}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.long_description || item.video?.short_description || item.description,
                            }}
                          />
                        </Description>
                      </div>
                    </OptionText>
                  ) : (
                    <OptionText>{item.name || item.title}</OptionText>
                  )}
                </SearchOptions>
              ))}
            </>
          )
        )}
        {(!suggestions.length || isRestrictSearchText) && !isSuggestionLoading && (
          <p className="container my-1">No results</p>
        )}
      </div>
      <div className="seeMore-div">
        <ButtonSmall type="button" className="a_seeMore" onClick={clickSeeMore}>
          {!isRestrictSearchText && 'See More'}
        </ButtonSmall>
      </div>
    </SearchContainer>
  );
});

export default SearchDropdown;
