import styled from 'styled-components';

const ToastContainer = styled.div`
  position: relative;
  margin-top: 10px;
  top: 3rem;
  right: 0;
  left: 0;
  z-index: 200;
  display: flex;
  padding: 0.25em 0;
  align-items: center;
  font-size: 0.9rem;
  color: #181a21;
  min-height: 32px;
  width: 100%;
  background-color: #35cb95;
  div {
    flex: 1;
    margin: auto;
    text-align: center;
  }
  button {
    background: none;
    border: none;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
  @media screen and (max-width: 1024px) {
    position: fixed;
  }
`;

export default ToastContainer;
