import React, {useEffect, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {CommentSectionStyles} from './style';
import {useStore} from '../../store';

const CommentSection = ({loggedInUser}: any) => {
  const store = useStore();
  const {addComment} = store.commentStore;
  const match = useRouteMatch();
  const {id} = match.params;
  const [comment, setComment] = useState('');
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  };

  useEffect(resizeTextArea, [comment]);

  const onChange = e => {
    setComment(e.target.value);
  };

  const createComment = () => {
    if (comment.trim()) {
      addComment(
        {
          videoId: id,
          comment,
        },
        () => {
          setComment('');
        },
      );
    }
  };
  return (
    <CommentSectionStyles>
      <div className="submitted-Comment-Container d-flex justify-content-between">
        <div className="profile-image d-flex justify-content-center align-items-center mr-4">
          {loggedInUser &&
            [
              loggedInUser?.first_name?.substring(0, 1).toUpperCase(),
              loggedInUser?.last_name?.substring(0, 1).toUpperCase(),
            ].join('')}
        </div>
        <div className="w-100">
          <textarea type="text" className="commentText" ref={textAreaRef} value={comment} onChange={e => onChange(e)} />
        </div>
        <div className="d-flex align-items-end">
          <button type="button" className="createButton " disabled={!comment} onClick={createComment}>
            Post
          </button>
        </div>
      </div>
    </CommentSectionStyles>
  );
};

export default CommentSection;
