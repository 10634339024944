import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {Row, Col, Container, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import AlertComponent from '../../components/AlertComponent';
import {PageTitle, ViewAllDiv, Line} from '../Home/elements/home';
import VideoTile from '../../components/VideoTile';
import {useStore} from '../../store';
import UpgradeModal from '../../components/UpgradeModal';
import {amplitude} from '../../utils/Amplitude';
import Sort from '../../components/Sort';
import Pagination from '../../components/Pagination';
import Filter from '../../components/Filter';
import PageBottom from '../../components/PageBottom';
import {ActiveSmallBtn, DefaultSmallBtn} from '../../components/FollowButtons';
import {Breadcrumb} from '../../components/Breadcrumbs';

const SpecialtyShowAll = observer(() => {
  const {videoStore, specialtyStore, alertStore, accountStore} = useStore();
  const match = useRouteMatch();
  const location = useHistory();
  const {
    onFetchAllSpecialtyVideos,
    allSpecialtyVideos,
    isLoading,
    resetPageCount,
    specialtyId,
    setSpecialtyId,
    listContentTypes,
    contenttypeList,
    sortBy,
    sortOrder: sort,
    changeSort,
    setSortType,
    pageDecrement,
    pageIncrement,
    page,
    changeCount,
    count,
    videoCount,
    resetPage,
    selectedSubSpecialtyIds,
    selectedOrganizationIds,
    setSelectedSpecialties,
    setSelectedOrganizations,
    setSelectedContenttype,
    setSelectedSubSpecialties,
    setCME,
    setVR,
    isCME,
    isVR,
    selectedContenttypeList,
    selectedOrganizationList,
    selectedSubSpecialtiesList,
    onRemoveContenttype,
    onRemoveOrganization,
    setSpecialtyPageSubspecialty,
    onRemoveSubSpecialty,
    organizationsList,
    selectedContenttypes,
    listOrganizations,
    listVideos,
    setSearch,
    searchText,
    resetSearch,
    resetspecialtiesList,
    subSpecialtiesList,
    listSpecialties,
    specialtiesList,
  } = videoStore;

  const {
    specialty,
    parentSpecialty,
    specialtyLoading,
    followSpecialty,
    unFollowSpecialty,
    getBrowseSpecialty,
  } = specialtyStore;

  const {message, status, resetAlert} = alertStore;

  const {account} = accountStore;

  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);

  useEffect(() => {
    listContentTypes();
    listSpecialties();
    listOrganizations();
  }, [listContentTypes, listSpecialties, listOrganizations]);

  useEffect(() => {
    setSelectedOrganizations([], false);
    setSelectedContenttype([], false);
    if (subSpecialtiesList?.length > 0) {
      setSelectedSubSpecialties([], false);
    }
    setCME(false);
    setVR(false);
    resetSearch();
    // eslint-disable-next-line
  }, [match.params?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBrowseSpecialty(match.params?.id);
    setSpecialtyId(match.params?.id);
    changeCount(25);
  }, [match.params?.id, getBrowseSpecialty, changeCount, setSpecialtyId, onFetchAllSpecialtyVideos, resetPageCount]);

  useEffect(() => {
    if (specialtiesList.length) setSelectedSpecialties([parseInt(match.params?.id, 10)]);
  }, [match.params?.id, specialtiesList, setSelectedSpecialties]);

  useEffect(() => {
    const eventProperties = {
      'specialty name': `${specialty.name}`,
    };
    if (specialty.level === 'subspecialty') {
      amplitude.getInstance().logEvent('subspecialty-page-viewed', eventProperties);
    } else {
      amplitude.getInstance().logEvent('specialty-page-viewed', eventProperties);
    }
  }, [specialty]);

  useEffect(() => {
    resetspecialtiesList();
    if (specialtiesList.length) listVideos();
  }, [
    match.params?.id,
    onFetchAllSpecialtyVideos,
    resetPageCount,
    listVideos,
    specialtiesList,
    resetspecialtiesList,
    sortBy,
    sort,
    page,
    count,
    isCME,
    isVR,
    searchText,
    selectedContenttypes,
    selectedSubSpecialtyIds,
    selectedOrganizationIds,
  ]);

  const specialtyFollowHandler = () => {
    const user = localStorage.getItem('user');
    if (user) {
      if (!account?.subscription?.name?.includes('Basic')) {
        if (specialty?.is_following) {
          unFollowSpecialty(specialtyId);
        } else {
          followSpecialty(specialtyId);
        }
      } else setShowFollowUpgradeModal(true);
    } else {
      location.push('/login');
    }
  };

  const sortValues = [
    {text: 'Name (A-Z)', id: 'name_asc', order: 'ASC', sort: 'title'},
    {text: 'Name (Z-A)', id: 'name_desc', order: 'DESC', sort: 'title'},
    {text: 'Date added (Newest)', id: 'date_desc', order: 'DESC', sort: 'release_date'},
    {text: 'Date added (Oldest)', id: 'date_asc', order: 'ASC', sort: 'release_date'},
  ];

  const changeSortby = option => {
    resetPage();
    switch (option) {
      case 'date_desc':
        setSortType('release_date');
        changeSort('DESC');
        break;
      case 'date_asc':
        setSortType('release_date');
        changeSort('ASC');
        break;
      case 'name_asc':
        setSortType('title');
        changeSort('ASC');
        break;
      case 'name_desc':
        setSortType('title');
        changeSort('DESC');
        break;
      default:
        break;
    }
  };

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedOrganizations(selectedIDs, false);
    } else if (type === 3) {
      setSelectedSubSpecialties(selectedIDs, false);
    } else {
      setSelectedContenttype(selectedIDs, false);
    }
  };

  const removeOrganization = item => {
    onRemoveOrganization(item.id);
  };

  const removeContenttype = item => {
    onRemoveContenttype(item.id);
  };

  const removeSubSpecialty = item => {
    onRemoveSubSpecialty(item.id);
  };

  useEffect(() => {
    setSpecialtyPageSubspecialty(specialty.children?.filter(item => item.videoCount > 0));
  }, [setSpecialtyPageSubspecialty, specialty]);

  const organization = {
    selectedOrganizationList,
    selectedOrganizations: selectedOrganizationIds,
    organizationFilter: organizationsList,
    removeOrganization,
  };

  const subSpecialties = {
    selectedSubSpecialtiesList,
    selectedSubSpecialties: selectedSubSpecialtyIds,
    subSpecialityFilter: subSpecialtiesList,
    removeSubSpecialty,
  };

  const contenttype = {
    contenttypeFilter: contenttypeList,
    selectedContenttypes,
    selectedContenttypeList,
    removeContenttype,
  };

  const cme = {
    isCme: isCME,
    setCME,
  };

  const vr = {
    isVR,
    setVR,
  };

  const search = {
    submitSearch: setSearch,
    searchText,
    resetSearch,
  };

  const clearFunc = () => {
    selectOptions([], 0);
    selectOptions([], 1);
    if (subSpecialties.subSpecialityFilter?.length > 0) {
      selectOptions([], 3);
    }
    setCME(false);
    setVR(false);
    resetSearch();
  };

  const renderSpecialty = () => {
    return (
      specialty && (
        <ViewAllDiv>
          <div className="d-flex container">
            <Breadcrumb
              parentActivecrumb_name={parentSpecialty}
              parentActivecrumb_url="#"
              activeCrumb_name={specialty.name}
            />
          </div>
          <Container>
            <div className="a_filterHeadDiv">
              <div className="d-flex title">
                <PageTitle className="a_videoHead">{specialty.name}</PageTitle>
                {specialty?.is_following ? (
                  <ActiveSmallBtn className="a_specialtyFollowed_btn a_followed" onClick={specialtyFollowHandler}>
                    <span className="following">
                      <i className="fas fa-check mr-2" />
                      Following
                    </span>
                    <span className="unfollow">
                      <i className="fas fa-times mr-2" />
                      Unfollow
                    </span>
                  </ActiveSmallBtn>
                ) : (
                  <DefaultSmallBtn className="a_follow" onClick={specialtyFollowHandler}>
                    <i className="fas fa-plus mr-2" />
                    Follow
                  </DefaultSmallBtn>
                )}
              </div>
              <Line />
              <div className="d-flex mt-2 flex-wrap justify-content-between">
                <Filter
                  count={videoCount}
                  organization={organization}
                  selectOptions={selectOptions}
                  subSpecialties={subSpecialties.subSpecialityFilter?.length > 0 ? subSpecialties : false}
                  contenttype={contenttype}
                  cme={cme}
                  vr={vr}
                  search={search}
                  clearFunc={clearFunc}
                />
                <div className="d-flex">
                  <Sort change={changeSortby} sort={sortValues} sortOrder={sortBy} sortBy={sort || 'title'} />
                  <Pagination
                    totalCount={videoCount}
                    page={page}
                    countPerPage={count}
                    pageIncrement={pageIncrement}
                    pageDecrement={pageDecrement}
                    changeCount={changeCount}
                  />
                </div>
              </div>
            </div>
            <Row>
              {allSpecialtyVideos?.map(el => (
                <Col key={el.id} id={el.id} md={4} lg={3} xl={3} className="mb-3">
                  <VideoTile item={el} storeName="videoStore" storeVar="allSpecialtyVideos" referrer="specialty page" />
                </Col>
              ))}
              {!allSpecialtyVideos?.length && !isLoading && <p className="px-3 pt-5">No results</p>}
            </Row>
            {isLoading ? (
              <div className="text-center my-3">
                <Spinner animation="grow" />
              </div>
            ) : (
              <></>
            )}
          </Container>
          {!isLoading && <Footer />}
        </ViewAllDiv>
      )
    );
  };

  return (
    <div id="SpecialtyShowAll" className="d-flex">
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <Helmet>
        <title>{`${specialty.name}`}</title>
        <meta
          name="description"
          content="Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:image"
          content="https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png"
        />
        <meta
          name="image"
          property="og:image"
          content="https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png"
        />
        <meta name="og:title" content={`${specialty.name}`} />
        <meta name="og:url" content={window.location.origin + window.location.pathname} />
      </Helmet>
      <Sidebar />
      <div className="w-100 pb-4">
        <Navbar />
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
        {specialtyLoading ? (
          <div className="spinner-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
            <Spinner animation="grow" role="status" variant="secondary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          renderSpecialty()
        )}
      </div>
      <PageBottom />
    </div>
  );
});

export default SpecialtyShowAll;
