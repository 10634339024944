import {makeAutoObservable, runInAction} from 'mobx';
import {createAComment, getComments, deleteComment} from '../../api/comments';
import ErrorStore from '../error/errorStore';

class CommentStore {
  videoComments = [];

  commentCount = 0;

  errorStore: ErrorStore;

  constructor({errorStore}: {errorStore: ErrorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  addComment = async (data, successCB) => {
    try {
      const response = await createAComment(data);
      if (response.status) {
        runInAction(() => {
          this.videoComments.unshift(response.data);
          this.commentCount = Number(this.commentCount) + 1;
          successCB(response.data);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  listComments = async (videoId, successCB) => {
    try {
      const response = await getComments(videoId);
      if (response.status) {
        runInAction(() => {
          this.videoComments = response.data.CommentsList;
          this.commentCount = response.data.commentCount;
          successCB(response.data);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  DeleteAComments = async (id, successCB) => {
    try {
      const response = await deleteComment(id);
      if (response.status) {
        runInAction(() => {
          const index = this.videoComments.findIndex(element => element.id === id);
          this.videoComments.splice(index, 1);
          this.commentCount = Number(this.commentCount) - 1;
          successCB(response.data);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default CommentStore;
