import styled from 'styled-components';

export const CommentsStyles = styled.div`
  width: 100%;
  background: #181a21;
  padding: 5px;
  border-radius: 18px;
  .commentContainer {
    width: 100%;
    max-height: 350px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 2px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #000;
    }
  }
  .profile-image {
    color: #ffffff;
    border-radius: 40px 45px 8px 40px;
    height: 40px;
    width: 43px;
    font-weight: 600;
  }
  .usersComments {
    background: #000;
    padding: 10px;
    min-height: 40px;
    align-items: center;
    margin-bottom: 12px;
    position: relative;
    border-radius: 12px;
    // :hover {
    //   border: 0.1px solid #a7a0a057;
    //   ::before {
    //     border-right: 0.1px solid #a7a0a057;
    //     border-bottom: 0.1px solid #a7a0a057;
    //   }
    // }
    pre {
      color: #fff;
      font-family: inherit;
      font-size: 15px;
    }
    ::before {
      content: '';
      position: absolute;
      left: -9px;
      top: 16%;
      width: 16px;
      height: 14px;
      transform: rotate(145deg);
      background: #000;
      border-radius: 0px 16px 2px 0px;
    }
  }
  .deleteButton {
    background: none;
    border: none;
    position: relative;
    color: #fff;
  }
  .deleteContainer {
    right: 0;
    bottom: -90%;
    width: 80px;
    background: #fff;
    padding: 5px;
    z-index: 2;
    color: #000;
    border: none;
    position: absolute;
    border-radius: 8px;
    ::before {
      content: '';
      position: absolute;
      right: 7px;
      top: -8%;
      width: 16px;
      height: 14px;
      transform: rotate(145deg);
      background: #fff;
      border-radius: 0px 16px 2px 0px;
    }
    :hover {
      background: #4b6072;
      color: #fff;
      ::before {
        background: #4b6072;
      }
    }
  }
  .dateContent {
    font-size: 10px;
    color: #4b6072;
  }
`;
export const CommentSectionStyles = styled.div`
  margin-top: 20px;

  .profile-image {
    background-color: #0677d8;
    color: #ffffff;
    border-radius: 40px 45px 8px 40px;
    height: 40px;
    width: 50px;
    font-weight: 600;
  }
  .submitted-Comment-Container {
    border-radius: 12px;
    width: 100%;
    padding: 10px 14px;
    background: #000;
  }
  .commentText {
    min-height: 40px;
    max-height: 100px;
    overflow: auto;
    width: 98%;
    outline: none;
    border: none;
    border-radius: 12px;
    background: #000;
    color: #fff;
    &::-webkit-scrollbar {
      width: 0px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .createButton {
    padding: 2px;
    width: 100px;
    height: 38px;
    color: #fff;
    overflow: hidden;
    border: none;
    border-radius: 8px;
    background: #0677d8;
    margin-bottom: 8px;
  }
`;
