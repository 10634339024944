import React, {useEffect, useState} from 'react';
import {ProgressBar, Row} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import Moment from 'react-moment';
import {observer} from 'mobx-react-lite';
import {
  Organization,
  Details,
  CardTitle,
  ThumbnailImg,
  ThumbnailDiv,
  VideoCardHolder,
  AlertSpan,
} from './VideoListCardStyle';
import {useStore} from '../../store';
import {secondsToTime} from '../../utils/helper';

type Props = {
  video: {
    id: number,
    title: string,
    description: string,
    number_of_videos: number,
    watchProgress: number,
    duration: number,
    brightcove_id: string,
    cme: Boolean,
    organizationCME: any,
    thumbnail: String,
    bookmark: Array,
    follow: Array,
    created_at: Date,
    organization: {
      name: string,
    },
    playlist: {
      id: number,
      name: string,
    },
    videos: Array,
    type: String,
  },
  playlist: {
    id: number,
    name: string,
  },
  referrer: string,
};

export default observer(({video, referrer}: Props) => {
  const location = useHistory();
  const {videoStore} = useStore();
  const [expiry, setExpiry] = useState(1000);
  const [isExpired, setExpired] = useState(false);

  const {setReferrer} = videoStore;

  const gotoVideoDetail = id => {
    setReferrer(referrer || 'no referrer');
    location.push(`/video/${id}`);
  };

  const getRemainingDays = (date: Date) =>
    date ? Math.floor((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : 0;

  useEffect(() => {
    let exp = video?.organizationCME?.withdraw_at;
    if (video.organizationCME?.withdraw_at) {
      if (video.organizationCME?.withdraw_at < video.organizationCME?.cme_expire_at) {
        exp = video.organizationCME?.withdraw_at;
        const days = getRemainingDays(exp);
        setExpiry(days);
        if (days < 0) setExpired(true);
      } else {
        exp = video.organizationCME?.cme_expire_at;
        const days = getRemainingDays(exp);
        setExpiry(days);
        if (days < 0) setExpired(true);
      }
    }
  }, [video]);

  return (
    <VideoCardHolder className="py-3">
      <div className="d-flex">
        <ThumbnailDiv onClick={() => gotoVideoDetail(video.id)}>
          <ThumbnailImg src={video.thumbnail} alt={video.title} />
          <ProgressBar now={video.watchProgress} />
        </ThumbnailDiv>
        <div className="w-100">
          {!isExpired && expiry <= 20 ? <AlertSpan>{`Expires in ${expiry} days`}</AlertSpan> : null}
          <CardTitle data-testid="go-to-detail" onClick={() => gotoVideoDetail(video.id)}>
            <h5>{video.title}</h5>
          </CardTitle>

          {video?.organization?.name ? <Organization>{video?.organization?.name}</Organization> : <Row />}
          <Details>
            {secondsToTime(video.duration)}
            {video?.organizationCME?.CMEs?.length && video?.organizationCME?.CMEs[0]?.status === 'Completed' ? (
              <>
                <span> &bull;</span> <span className="text-success">CME Completed</span>
              </>
            ) : (
              video?.organizationCME && <span> &bull; CME Credits Offered</span>
            )}
            <span>
              &nbsp;&bull; <Moment format="MM/DD/YY">{video.created_at}</Moment>
            </span>
          </Details>
        </div>
      </div>
    </VideoCardHolder>
  );
});
