import styled from 'styled-components';

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;

  .a_downloadNote {
    font-size: 16px;
    font-weight: 500;
    padding: 3px 15px;
  }
`;

export const PageTitle = styled.h5`
  font-weight: 600;
  margin-top: 1em;
  font-size: 20px;
  line-height: 26px;
  margin-left: 15px;
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 30px 8px 16px;
  border-radius: 30px;
  margin-top: 0.1em;
  width: 191px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => theme.text4};
  @media screen and (min-width: 576px) and (max-width: 1200px) {
    width: 170px;
  }
  @media screen and (max-width: 500px) {
    width: 175px;
  }
  @media screen and (max-width: 414px) {
    width: 158px;
  }
  @media screen and (max-width: 320px) {
    width: 130px;
  }
`;

export const LimitSelect = styled.select`
  background: transparent;
  padding-right: 12px;
  border-radius: 30px;
  margin-top: 0.1em;
  margin-right: 10px;
  width: 45px;
  position: relative;
  font-size: 12px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  color: ${({theme}) => theme.text};

  option {
    background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  }
  @media screen and (min-width: 576px) and (max-width: 1200px) {
    width: 170px;
  }
  @media screen and (max-width: 500px) {
    width: 175px;
  }
  @media screen and (max-width: 414px) {
    width: 158px;
  }
  @media screen and (max-width: 320px) {
    width: 130px;
  }
`;

export const FormLabel = styled.h5`
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
`;

export const ChipText = styled.p`
  color: #0677d8;
  margin-bottom: 8px;
  margin-right: 8px;
  padding-left: 4px;
  height: 24px;
  border-radius: 8px;
  background-color: #cce8ff;
`;

export const CourseFilter = styled.div`
  margin-left: auto;

  .form-group {
    margin-bottom: 8px;
    margin-top: 8px;
    padding-right: 0px;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const MobileFilter = styled.div`
  display: none;
  @media screen and (max-width: 576px) {
    display: block;
    .mobileSort {
      margin-left: 176px;
    }
  }
  @media screen and (max-width: 375px) {
    .mobileSort {
      margin-left: 136px;
    }
  }
  @media screen and (max-width: 360px) {
    .mobileSort {
      margin-left: 122px;
    }
  }
  @media screen and (max-width: 320px) {
    .mobileSort {
      margin-left: 110px;
    }
  }
`;

export const Line = styled.div`
  @media screen and (max-width: 576px) {
    height: 24px;
    border-bottom: 1px solid #4e505a;
    margin-bottom: 25px;
  }
`;

export const LimitDropdown = styled.div`
  position: absolute;
  right: 110px;
  background-color: #2e3038;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
  width: 120px;
  text-align: left;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.18px;
  line-height: 16px;
`;

export const PaginationContainer = styled.div`
  position: relative;
  // margin-top: 25px;
  margin-bottom: 10px;
  label {
    width: 100%;
    padding: 7px;
  }
  .form-check-input {
    display: none;
  }
  input:checked ~ label::after {
    content: '';
    left: 10px;
    bottom: 10px;
    width: 6px;
    height: 11px;
    border: solid white;
    position: absolute;
    transform: rotate(45deg);
    display: block;
    border-width: 0 2px 2px 0;
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  span {
    font-size: 12px;
  }
  .a_limit,
  .a_p_dec,
  .a_p_inc {
    padding: 0;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 12px;
    color: ${({theme}) => theme.text};
  }
  .a_p_dec,
  .a_p_inc {
    font-size: 16px;
  }
  .a_limit {
    width: auto;
  }
`;

export const ThumbnailImg = styled.img`
  width: 168px;
  height: 95px;
  border-radius: 8px;
`;

export const ThumbnailDiv = styled.div`
  margin-right: 20px;
  width: 245px;
  height: 95px;
  .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }
  :hover {
    cursor: pointer;
  }
`;

export const ButtonGroup = styled.div`
  width: 230px;

  .a_downloadNote {
    padding: 0;
    margin-right: 12px;
    width: 24px;
    height: 24px;
    text-align: center;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  }

  .a_delete {
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-right: 15px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  }
`;

export const VideoTitle = styled.div`
  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 2px;
  }
  :hover {
    cursor: pointer;
  }
`;

export const VideoCardHolder = styled.div`
  border-bottom: 1px solid #4e505a;
  .row {
    margin-top: 10px;
  }
`;

export const Organization = styled.h6`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 4px;
`;

export const Details = styled.p`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-bottom: 0;
  span {
    font-size: 10px;
    letter-spacing: 0;
    line-height: 13px;
  }
  p {
    margin-bottom: 0;
  }
`;

export const TimeStamp = styled.p`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 13px;
  margin-bottom: 5px;
`;

export const Note = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PlaylistIcon = styled.div`
  width: 60px;
  height: 60px;
  background: ${({theme}) => theme.content_active_bg};
  border-radius: 50px;
  margin: auto;
  font-size: 32px;
  text-align: center;
  padding: 7px 0;
`;

export const EmptyList = styled.div`
  h4 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.33px;
    line-height: 24px;
    margin-top: 35px;
  }

  h6 {
    font-size: 18px;
    letter-spacing: -0.4px;
    line-height: 24px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
    font-weight: normal;
  }
`;
