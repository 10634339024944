import {observer, useLocalStore} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';
import {useHistory} from 'react-router-dom';
import {Button, OverlayTrigger, Spinner, Image} from 'react-bootstrap';
import {ReactComponent as GiblibIcon} from '../../assets/icons/Giblib_icon.svg';
import {ReactComponent as NewIcon} from '../../assets/icons/new.svg';
import {ReactComponent as CMEIcon} from '../../assets/icons/CME.svg';
import {ReactComponent as CarrerDown} from '../../assets/icons/carrer-down.svg';
import {ReactComponent as CarrerUp} from '../../assets/icons/carrer-up.svg';
import {Body1, Body2, SubTitle1, Title1, Title2} from '../../utils/styles/typography';
import {ActiveMediumBtn, DefaultLargeBtn} from '../FollowButtons';
import {useStore} from '../../store';
import LocalStorageService from '../../utils/LocalStorageService';
import ConferencePlaylistPopover from '../CoursePlaylistPopover';
import UpgradeModal from '../UpgradeModal';

export const DeskDiv = styled.div`
  width: 228px;
  display: flex;
  flex-direction: column;
  margin-right: 36px;

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const MobileDiv = styled.div`
  display: none;
  @media screen and (max-width: 576px) {
    display: block;
  }
  width: 100%;
  margin-top: 20px;

  .thumbnail {
    height: 90px;
  }
  .info-card {
    margin-top: 16px;
    display: flex;
  }
`;

export const IconDiv = styled.div`
  margin: 18px 0 12px;
  display: block;
  svg {
    margin-right: 8px;
  }
  @media screen and (max-width: 576px) {
    margin: 0 0 8px;
  }
`;

export const Title = styled(Title1)`
  line-height: 25px;
  max-height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
  @media screen and (max-width: 576px) {
    -webkit-line-clamp: 2;
    max-height: 50px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  margin-top: 32px;
  button {
    margin-right: 24px;
  }
  .a_playlist,
  .a_bookmark {
    font-size: 18px;
    padding-left: 0;
    padding-right: 0;
    svg {
      width: 18px;
      color: white;
    }
  }
  @media screen and (max-width: 576px) {
    justify-content: space-between;
    margin-top: 16px;
  }
`;

export const OrganizationDiv = styled.div`
  margin: 32px 0;
  display: flex;
  align-items: center;
  img {
    width: 44px;
    height: 44px;
    margin-right: 16px;
  }
`;

export const DetailsDiv = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LandingPageInfo = observer(({type, item, playlist, landingVideos}) => {
  const location = useHistory();
  const {courseLandingStore, conferenceLandingStore, accountStore} = useStore();
  const {
    onCreateBookmarkConference,
    onDeleteBookmarkConference,
    onFollowConference,
    onUnfollowConference,
    addConferenceToPlaylist,
    addConferenceToNewPlaylist,
  } = conferenceLandingStore;
  const {onCreateBookmarkCourse, onDeleteBookmarkCourse} = courseLandingStore;
  const {account} = accountStore;

  const loggedInUser = LocalStorageService.getUser();

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const [playlistUpdating, setUpdating] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const state = useLocalStore(() => ({
    isNew: false,
  }));

  const videoList = landingVideos.map(video => {
    return {id: video.video_id};
  });

  useEffect(() => {
    const createdVideo = new Date(item.latest_date);
    const after30Video = new Date(createdVideo.getFullYear(), createdVideo.getMonth(), createdVideo.getDate() + 30);
    const today = new Date();
    if (after30Video > today) state.isNew = true;
  }, [state, item]);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  const followLandingPage = selectedItem => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic')) {
        if (type === 'conference') {
          if (selectedItem?.follow?.length) {
            onUnfollowConference(selectedItem.id);
          } else {
            onFollowConference(selectedItem.id);
          }
        }
      } else setShowFollowUpgradeModal(true);
    } else {
      location.push('/login');
    }
  };

  const bookmarkLandingPage = selectedItem => {
    if (loggedInUser) {
      if (type === 'conference') {
        if (selectedItem.bookmark?.length) {
          onDeleteBookmarkConference(selectedItem.id);
        } else {
          onCreateBookmarkConference(selectedItem.id);
        }
      }
      // course landing card bookmark
      if (selectedItem.bookmark?.length) {
        onDeleteBookmarkCourse(selectedItem.id);
      } else {
        onCreateBookmarkCourse(selectedItem.id);
      }
    } else {
      location.push('/login');
    }
  };

  const createAndAddToPlaylist = async data => {
    setUpdating(true);
    await addConferenceToNewPlaylist(item.id, data.listname);
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    await addConferenceToPlaylist(item.id, data);
    setUpdating(false);
  };

  const ButtonGroup = () => (
    <ButtonDiv>
      <div>
        {item.follow?.length ? (
          <ActiveMediumBtn className="a_followed" onClick={() => followLandingPage(item)}>
            <span className="following">
              <i className="fas fa-check mr-2" />
              Following
            </span>
            <span className="unfollow">
              <i className="fas fa-times mr-2" />
              Unfollow
            </span>
          </ActiveMediumBtn>
        ) : (
          <DefaultLargeBtn className="a_follow" onClick={() => followLandingPage(item)}>
            <i className="fas fa-plus mr-2" />
            Follow
          </DefaultLargeBtn>
        )}
      </div>
      <div>
        {loggedInUser ? (
          [
            !playlistUpdating ? (
              <OverlayTrigger
                trigger="click"
                placement="auto-start"
                overlay={props => (
                  <ConferencePlaylistPopover
                    props={props}
                    allPlaylist={playlist}
                    courseVideos={videoList}
                    onNew={createAndAddToPlaylist}
                    onExisting={updateExistingPlaylist}
                    userState={userState}
                    showUpgradeModal={isShowPlaylistUpgradeModal}
                    onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                  />
                )}
                rootClose
              >
                <Button variant="basic" className="a_playlist" onClick={() => setShowPlaylistUpgradeModal(true)}>
                  <i className="fak fa-list-ul" />
                </Button>
              </OverlayTrigger>
            ) : (
              <div className="p-0 mr-3 btn">
                <Spinner animation="border" size="sm" variant="primary" />
              </div>
            ),
          ]
        ) : (
          <Button variant="basic" className="a_playlist" onClick={() => location.push('/login')}>
            <i className="fak fa-list-ul" />
          </Button>
        )}
        {account?.subscription?.name?.includes('Basic') ? (
          <Button variant="basic" className="px-0 py-0 a_bookmark" onClick={() => setShowBookmarkUpgradeModal(true)}>
            <i className="far fa-bookmark" />
          </Button>
        ) : (
          <>
            <Button
              variant="basic"
              className={`a_bookmark ${item.bookmark?.length ? 'd-none' : ''}`}
              onClick={() => bookmarkLandingPage(item)}
            >
              <i className="far fa-bookmark" />
            </Button>
            <Button
              variant="basic"
              className={`a_bookmark ${item.bookmark?.length ? 'bookmark-fill' : 'd-none'}`}
              onClick={() => bookmarkLandingPage(item)}
            >
              <i className="fas fa-bookmark" />
            </Button>
          </>
        )}
      </div>
    </ButtonDiv>
  );

  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <DeskDiv>
        <Image src={item?.thumbnail_url} className="w-100" />
        <IconDiv>
          <GiblibIcon alt="giblib" width={20} />
          {state.isNew ? <NewIcon alt="new" /> : null}
          {item?.is_cme ? <CMEIcon className="cme-icon" alt="cme" /> : null}
        </IconDiv>
        <Title>{item.title}</Title>
        <Body2 className="mb-2">
          {item?.specialty
            .filter(spec => !spec.display_name.includes('General'))
            .map((value, index) =>
              index !== item?.specialty.filter(spec => !spec.display_name.includes('General')).length - 1
                ? `${value.display_name}, `
                : value.display_name,
            )}
        </Body2>
        {item?.earliest_date && item?.latest_date ? (
          <div className="d-flex mb-2">
            <Body2>
              <Moment format="MM/YYYY">{item?.earliest_date}</Moment>
              &nbsp;-&nbsp;
              <Moment format="MM/YYYY">{item?.latest_date}</Moment>
            </Body2>
          </div>
        ) : (
          ''
        )}
        <SubTitle1>{`${item?.number_of_videos} Videos`}</SubTitle1>
        <ButtonGroup />
        {item.organization ? (
          <OrganizationDiv>
            <Image src={item.organization.thumbnail} />
            <Body1>{item.organization.name}</Body1>
          </OrganizationDiv>
        ) : null}
        <Body1 dangerouslySetInnerHTML={{__html: item.description}} />
      </DeskDiv>
      <MobileDiv>
        <Title>{item.title}</Title>
        <div className="info-card">
          <Image src={item?.thumbnail_url} className="thumbnail" />
          <div className="d-flex flex-column ml-3">
            <IconDiv>
              <GiblibIcon alt="giblib" width={20} />
              {state.isNew ? <NewIcon alt="new" /> : null}
              {item?.is_cme ? <CMEIcon className="cme-icon" alt="cme" /> : null}
            </IconDiv>
            <Body2 className="mb-1 mb-sm-2">
              {item?.specialty
                .filter(spec => !spec.display_name.includes('General'))
                .map((value, index) =>
                  index !== item?.specialty.filter(spec => !spec.display_name.includes('General')).length - 1
                    ? `${value.display_name}, `
                    : value.display_name,
                )}
            </Body2>
            {item?.earliest_date && item?.latest_date ? (
              <div className="d-flex mb-1 mb-sm-2">
                <Body2>
                  <Moment format="MM/YYYY">{item?.earliest_date}</Moment>
                  &nbsp;-&nbsp;
                  <Moment format="MM/YYYY">{item?.latest_date}</Moment>
                </Body2>
              </div>
            ) : (
              ''
            )}
            <SubTitle1>{`${item?.number_of_videos} Videos`}</SubTitle1>
          </div>
        </div>
        <ButtonGroup />
        {item.description ? (
          <>
            <DetailsDiv>
              <Title2 className="mb-0">More Details</Title2>
              <Button variant="basic" onClick={() => setShowDetails(!showDetails)}>
                {showDetails ? <CarrerUp /> : <CarrerDown />}
              </Button>
            </DetailsDiv>
            {showDetails ? <Body1 dangerouslySetInnerHTML={{__html: item.description}} /> : null}
          </>
        ) : null}
      </MobileDiv>
    </>
  );
});

export default LandingPageInfo;
