import styled from 'styled-components';

export const ChipText = styled.p`
  color: #fff;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 2px 2px 2px 8px;
  height: 28px;
  border-radius: 5px;
  background-color: #008aff;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;

  img {
    height: 12px;
  }

  :hover {
    background-color: #009994;
  }
  :focus {
    border: 1px solid #cce8ff;
  }
`;

export const FilterContainer = styled.div`
  background: rgba(24, 26, 33, 0.85);
  backdrop-filter: blur(20px);
  border-radius: 3px;
  padding: 16px 5px;
  margin-left: 1em;
  top: 40px;

  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
  .show-mobile {
    display: none;

    .filter-btn {
      background: #008aff;
    }

    h5 {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-left: 0;
    }
    @media screen and (max-width: 576px) {
      display: flex;
    }
  }

  margin-left: 0;
  position: absolute;
  width: 336px;
  left: 0;
  z-index: 6;
  background: rgba(24, 26, 33, 0.8);

  .closeFilter {
    background: none;
    border: none;
    padding: 0;
    height: 35px;

    img {
      width: 21px;
    }
  }

  .hide-mobile {
    display: flex;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  @media screen and (max-width: 576px) {
    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
  }

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-left: 16px;
    margin-right: 16px;
  }

  .result-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const Filter = styled.div`
  float: left;
  position: relative;

  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;

    .filter-btn {
      padding: 6px 11px;
      background: rgba(255, 255, 255, 0.1);
      border: none;
      margin-right: 12px;
      display: inline-block;
      &.show {
        display: block;
      }
      img {
        width: 18px;
        margin-right: 15px;
      }
      @media screen and (max-width: 576px) {
        display: inline-block;
        &.show {
          display: block;
        }
        &.hide {
          display: none;
        }
      }
    }

    .filter-img {
      img {
        margin-right: 15px;
      }

      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }

  label {
    font-size: 15px;
  }

  .form-row {
    padding: 0.2rem 0rem;
  }

  .search::placeholder {
    color: #fff;
    font-family: Roboto;
  }

  button.search-button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 36px;
  }

  .form-control {
    background: rgba(255, 255, 255, 0.1);
    border-left: none;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    height: 36px;
    color: #fff !important;
    font-size: 14px;
    box-shadow: none;
    @media only screen and (device-width: 1024px) {
      min-width: 175px;
    }
    @media only screen and (device-width: 768px) {
      min-width: 175px;
    }
  }

  .input-group-prepend {
    margin-right: 0px;
    z-index: 0;
  }

  .input-group {
    padding: 0 1rem 1rem 1rem;
    @media only screen and (device-width: 1024px) {
      flex-wrap: nowrap;
    }
    @media only screen and (device-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  .search-button {
    height: 32px;
    padding: 0.4rem;
    padding-right: 12px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-right: none;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    z-index: 9998;
  }

  .fa-search {
    background-color: none !important;
    margin-left: 4px !important;
    margin-right: -15px !important;
    font-size: 18px !important;
  }

  .clear-btn {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    letter-spacing: 0;
    width: 69px;
    height: 22px;
    background: #2b2d33;
    backdrop-filter: blur(20px);
    border-radius: 3px;
    border: none;
    padding: 0;
    @media screen and (max-width: 576px) {
      height: 36px;
      margin-left: 40px;
    }
  }

  .form-group {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .catalog-page {
    display: block;
  }

  .switchFilter {
    background: #181a21;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 0 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 48px;

    .custom-control {
      min-width: 48px;
    }

    .custom-switch .custom-control-label::before {
      width: 48px;
      height: 24px;
      border-radius: 50px;
      background: #a3a3a6;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: #0988fa;
    }

    .custom-switch .custom-control-label::after {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #fff;
    }

    .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(24px);
    }

    label {
      margin-bottom: 0;
      line-height: 33px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 40%;
  }

  @media screen and (max-width: 576px) {
    float: none;
    width: 100%;
    padding-left: 1rem;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 0rem;
    .catalog-page,
    .switchFilter {
      display: none;
    }
    .dropdown,
    #filterSpecialty,
    #filterOrganization {
      width: 100% !important;
    }
  }
`;

export const FilterDropContainer = styled.div`
  .text-nobreak {
    white-space: nowrap;
  }
  .a_filter-btn {
    width: 100%;
    background: #008aff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-weight: bold;
    margin-top: 10px;
  }
`;

export const PaginationContainer = styled.div`
  margin-left: 32px;
  .a_sort,
  .a_profession {
    background: transparent;
    padding: 5px 30px 5px 16px;
    border-radius: 3px;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
    color: #fff;
    width: 190px;
    text-align: left;

    ::after {
      right: 8px;
      position: absolute;
      top: calc(50% - 0.25em);
      border-top: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-bottom: 0;
      border-left: 0.5em solid transparent;
    }
    ~ .dropdown-menu {
      width: 190px;
    }
  }
  .a_profession {
    width: 100%;
    padding: 8px 30px 8px 16px;

    ~ .dropdown-menu {
      width: 100%;
    }
  }
  text-align: right;
  position: relative;
  label {
    width: 100%;
    padding: 8px 0;
  }
  .form-check-input {
    display: none;
  }
  .form-check {
    padding-left: 44px;
  }
  input ~ label::after {
    content: '';
    left: 16px;
    bottom: 10px;
    width: 16px;
    height: 16px;
    border: 2px solid #008aff;
    position: absolute;
    display: block;
    border-radius: 50px;
    top: calc(50% - 8px);
  }
  input:checked ~ label::before {
    position: absolute;
    display: block;
    border-radius: 50px;
    content: '';
    left: 14px;
    bottom: 8px;
    border: 2px solid rgba(0, 138, 255, 0.5);
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
  }
  input:checked ~ label {
    color: #008aff;
  }
  input:checked ~ label::after {
    border: 4px solid #008aff;
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  span {
    font-size: 12px;
  }
  .a_limit,
  .a_p_dec,
  .a_p_inc {
    padding: 0;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 16px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin-left: 8px;
  }
  .a_limit {
    font-size: 12px;
    padding-left: 4px;
    padding-top: 2px;
  }
  .a_limit:hover,
  .a_limit:active,
  .a_limit:focus {
    font-size: 12px;
    background-color: #252730;
    color: #dcdee1;
  }
  .dropdown-menu {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 140px;
    text-align: left;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.18px;
    line-height: 16px;
    min-width: 7rem;
  }
  .dropdown {
    display: inline-block;
  }
  &.professionDrop .dropdown {
    display: block;
  }
  &.professionDrop {
    margin-left: 0;

    .dropdown-menu {
      position: relative !important;
      transform: none !important;
      border-radius: 8px;
    }
  }
  #limitMenu::after {
    border: none;
  }

  &.sort-mobile {
    display: none;
  }
  @media screen and (max-width: 576px) {
    display: none;

    &.sort-mobile {
      display: inline-block;
      float: right;
    }
    &.professionDrop {
      display: block;
    }
  }
`;
