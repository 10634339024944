import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Row, Col, Container, Spinner} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {Breadcrumbs, PageTitle, ActiveCrumb, Crumb, SearchVideoDiv} from '../elements/search';
import VideoTile from '../../../components/VideoTile';
import {useStore} from '../../../store';
import AlertComponent from '../../../components/AlertComponent';

// Search Transcript page
const SearchTranscript = observer(() => {
  const params = useLocation();
  const text = new URLSearchParams(params.search).get('q');
  const {searchStore, alertStore} = useStore();
  const {isLoading, getTranscriptResults, transcriptResults, transcriptCount} = searchStore;

  const {message, status, resetAlert} = alertStore;

  useEffect(() => {
    getTranscriptResults(text);
  }, [getTranscriptResults, text]);

  const scroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && !isLoading) {
      getTranscriptResults(text, transcriptCount);
    }
  };

  return (
    <SearchVideoDiv onScroll={scroll}>
      <div className="d-flex container">
        <Breadcrumbs className="a_searchVideoCrumb">
          <Crumb href="/home">Search</Crumb>
          <ActiveCrumb href="#">{text}</ActiveCrumb>
        </Breadcrumbs>
      </div>
      {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}

      <Container>
        <div className="d-flex align-items-center a_filterHeadDiv">
          <PageTitle className="mr-auto a_searchVideoHead col-4">
            &apos;{text || ' '}&apos; mentioned in video
          </PageTitle>
        </div>
        <Row className="transcriptContainer">
          {transcriptResults?.map(el => (
            <Col key={el.video.id} id={el.video.id} md={3} lg={4} xl={3}>
              <VideoTile
                key={el.video.title}
                item={el.video}
                storeName="searchStore"
                storeVar="transcriptResults"
                searchTerm={text}
                transcript={el.transcripts}
                mentionCount={el.total_transcripts}
              />
            </Col>
          ))}
        </Row>
      </Container>
      {transcriptResults?.length && isLoading && (
        <div className="text-center my-3">
          <Spinner animation="grow" />
        </div>
      )}
    </SearchVideoDiv>
  );
});

export default SearchTranscript;
