// @flow
import * as React from 'react';
import {NextDiv, PrevDiv, CustomSlider} from './style';

export const NextArrow = (props: React.ElementProps<any>) => {
  const {className, style, onClick} = props;
  return (
    <NextDiv
      role="button"
      tabIndex={0}
      className={className}
      style={{...style, display: 'block'}}
      onClick={onClick}
      onKeyDown={onClick}
    />
  );
};

export const PreviousArrow = (props: React.ElementProps<any>) => {
  const {className, style, onClick} = props;
  return (
    <PrevDiv
      role="button"
      tabIndex={0}
      className={className}
      style={{...style, display: 'block'}}
      onClick={onClick}
      onKeyDown={onClick}
    />
  );
};

type Props = {
  card: string,
  children: React.Node,
};

const SlickSlider = ({card, children}: Props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 350,
    slidesToShow: card === 'course' ? 3 : 4,
    slidesToScroll: card === 'course' ? 3 : 4,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: card === 'course' ? 3 : 4,
          slidesToScroll: card === 'course' ? 3 : 4,
          arrows: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  return <CustomSlider {...settings}>{children}</CustomSlider>;
};

export default SlickSlider;
