import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Form, Button, Dropdown} from 'react-bootstrap';
import cmeIcon from '../../../assets/images/icon_CME.svg';
import {Logo, NoCME, SortSelect, SortLabel} from '../elements/cme';
import {PaginationContainer} from '../../Bookmarks/elements/styles';
import InProgressCard from './InProgressCard';
import ScreenLoader from '../../../components/ScreenLoader';
import {useStore} from '../../../store';

const CMEProgress = observer(() => {
  const {cmeStore} = useStore();
  const {
    cmeInprogress,
    cmeInprogressLoading,
    progressSortBy,
    changeProgressSort,
    inProgressPage,
    inProgressPageDecrement,
    inProgressPageIncrement,
    inProgressRecordCount,
    inProgressTotalCount,
    changeInProgressRecordCount,
    showQuestionBlock,
    cmeLoading,
    question,
    scrollInProgress,
  } = cmeStore;

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const resizeWindow = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    if (windowSize <= 768) {
      window.addEventListener('scroll', e => {
        const {scrollTop, clientHeight, scrollHeight} = e.target.scrollingElement;
        if (Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight)) {
          scrollInProgress();
        }
      });
    }
  }, [scrollInProgress, windowSize]);

  const renderProgressList = () => {
    return (
      <>
        {cmeInprogress && cmeInprogress.length
          ? cmeInprogress.map(item => <InProgressCard item={item} key={item.id} />)
          : !cmeInprogressLoading && (
              <div className="text-center mx-auto pt-5">
                <Logo>
                  <img src={cmeIcon} className="a_cmeIcon" alt="cme-icon" />
                </Logo>
                <NoCME>No CME’s are in Progress</NoCME>
              </div>
            )}
      </>
    );
  };

  return (
    <div
      className={`px-3 ${showQuestionBlock && !cmeLoading && question && window.innerWidth < 768 ? 'q_expand' : ''}`}
    >
      {cmeInprogress && cmeInprogress.length ? (
        <div className="d-flex flex-row-reverse container">
          <PaginationContainer className="py-2 ml-3 pagination-div">
            <span>
              {inProgressRecordCount === 0 ? (
                <>0 </>
              ) : (
                <>{inProgressPage * inProgressRecordCount - (inProgressRecordCount - 1)} </>
              )}
              -
              {inProgressPage * inProgressRecordCount > inProgressTotalCount ? (
                <>{inProgressTotalCount} </>
              ) : (
                <>{inProgressPage * inProgressRecordCount} </>
              )}
              of {inProgressTotalCount}
            </span>
            <Button
              onClick={inProgressPageDecrement}
              variant="basic"
              className="a_p_dec"
              disabled={inProgressPage * inProgressRecordCount - (inProgressRecordCount - 1) === 1}
            >
              <i className="far fa-angle-left" />
            </Button>
            <Button
              onClick={inProgressPageIncrement}
              variant="basic"
              className="a_p_inc"
              disabled={inProgressPage * inProgressRecordCount >= inProgressTotalCount}
            >
              <i className="far fa-angle-right" />
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
                <i className="fas fa-ellipsis-v" />
              </Dropdown.Toggle>

              <Dropdown.Menu onChange={e => changeInProgressRecordCount(e.target.value)}>
                <Form.Check
                  type="radio"
                  label="10 per page"
                  id="limit_10"
                  value="10"
                  name="count"
                  checked={parseInt(inProgressRecordCount, 10) === 10}
                />
                <Form.Check
                  type="radio"
                  label="25 per page"
                  id="limit_25"
                  value="25"
                  name="count"
                  checked={parseInt(inProgressRecordCount, 10) === 25}
                />
                <Form.Check
                  type="radio"
                  label="50 per page"
                  id="limit_50"
                  value="50"
                  name="count"
                  checked={parseInt(inProgressRecordCount, 10) === 50}
                />
                <Form.Check
                  type="radio"
                  label="100 per page"
                  id="limit_100"
                  value="100"
                  name="count"
                  checked={parseInt(inProgressRecordCount, 10) === 100}
                />
              </Dropdown.Menu>
            </Dropdown>
          </PaginationContainer>
          <Form>
            <Form.Group controlId="sort">
              <SortLabel className="mr-2">Sort</SortLabel>
              <SortSelect value={progressSortBy} onChange={e => changeProgressSort(e.target.value)}>
                <option value="DESC">Newest</option>
                <option value="ASC">Oldest</option>
              </SortSelect>
            </Form.Group>
          </Form>
        </div>
      ) : null}
      {!cmeInprogressLoading || windowSize <= 768 ? (
        renderProgressList()
      ) : (
        <ScreenLoader containerHeight="calc(50vh)" />
      )}
      {cmeInprogressLoading && windowSize <= 768 && <ScreenLoader containerHeight="calc(50px)" />}
    </div>
  );
});

export default CMEProgress;
