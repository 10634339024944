import React from 'react';
import {Navbar, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import SubscriptionNavbarContainer from './style';

const SubscriptionNavbar = () => {
  return (
    <SubscriptionNavbarContainer>
      <Navbar as={Row} className="nav mx-0 justify-content-between align-items-center">
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img alt="logo" src={logo} height="20" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Brand as={Link} to="/profile/settings" className="d-flex align-items-center">
          <p className="mb-0 a_subscription_exit">Exit</p>
        </Navbar.Brand>
      </Navbar>
    </SubscriptionNavbarContainer>
  );
};

export default SubscriptionNavbar;
