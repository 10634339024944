import {makeAutoObservable} from 'mobx';
import {
  getIPBasedAccess,
  getManaged,
  getManagedDetail,
  updateUnclaimedAccess,
  addAccessHistory,
  deleteAccessHistory,
  getByAccess,
} from '../../api/access';
import Access from './Access';
import {amplitude} from '../../utils/Amplitude';

export default class AccessStore {
  managedAccesses: Access[] = [];

  loading: boolean = false;

  ipBasedAccess: Access = null;

  oauthBasedAccess: Access = null;

  anonymousAccess: Access = null;

  accessUsers = [];

  emailEdit: boolean = false;

  accessId = null;

  userPage = 1;

  count = 10;

  userCount = null;

  searchText = '';

  totalLicense = '';

  licenseUsed = '';

  access = null;

  accessHistoryId = [];

  prevEmail = [];

  addUserCompleted: boolean = false;

  addUserLoading: boolean = false;

  expire = null;

  addUserError = '';

  sort = [];

  constructor() {
    makeAutoObservable(this);
  }

  userPageIncrement = () => {
    this.userPage += 1;
  };

  userPageDecrement = () => {
    this.userPage -= 1;
  };

  setCount = value => {
    this.userPage = 1;
    this.count = value;
  };

  resetPage = () => {
    this.userPage = 1;
  };

  async fetchManaged() {
    this.loading = true;
    this.managedAccesses = await getManaged();
    this.loading = false;
  }

  async fetchIPBasedAccess() {
    this.ipBasedAccess = await getIPBasedAccess();
    if (this.ipBasedAccess) {
      this.anonymousAccess = this.ipBasedAccess;
    }
  }

  async fetchOAuthBasedAccess() {
    this.oauthBasedAccess = await getByAccess();
    if (this.oauthBasedAccess) {
      this.anonymousAccess = this.oauthBasedAccess;
    }
  }

  setOAuthBasedAccess(access) {
    this.oauthBasedAccess = access;
    this.anonymousAccess = access;
  }

  setSort = value => {
    this.sort = value;
  };

  getManagedDetail = async () => {
    const response = await getManagedDetail(
      this.access,
      this.userPage,
      this.count,
      this.searchText,
      this.sort[0],
      this.sort[1],
    );
    this.accessUsers = response?.data?.accessUsers;
    this.userCount = response?.data?.totalUsers;
    this.totalLicense = response?.data?.access_limit;
    this.expire = response?.data?.end_date;
    // this.licenseUsed = response?.data?.licenseUsed.count;
  };

  resetSearch = () => {
    this.searchText = '';
  };

  setSearch = value => {
    this.searchText = value;
  };

  setEmailEdit = value => {
    this.emailEdit = value;
  };

  setAddUserCompleted = value => {
    this.addUserCompleted = value;
  };

  updateUnclaimedAccess = async (id, newEmail) => {
    // Filter user to get previous email
    const filteredUser = this.accessUsers.filter(item => {
      return item.accessHistories[0].id === id;
    });
    this.prevEmail.unshift(filteredUser[0].email);
    // Filter access to get access name
    const accessName = this.managedAccesses?.filter(item => {
      return item.id === filteredUser[0].accessHistories[0].access_id;
    });

    const result = await updateUnclaimedAccess(id, newEmail);
    if (result.status === 200) {
      this.emailEdit = false;
      const index = this.accessUsers.findIndex(item => item.accessHistories[0].id === id);
      this.accessUsers[index].email = newEmail;
      // Amplitude Event
      const eventProperties = {
        'access code': `${accessName[0].access_name}`,
        'previous email': `${this.prevEmail[0]}`,
        'new email': `${newEmail}`,
      };
      amplitude.getInstance().logEvent('updated-access-history', eventProperties);
    }
  };

  setAccessId = id => {
    this.accessId = id;
  };

  setAddUserError = value => {
    this.addUserError = value;
  };

  addAccessHistory = async newEmail => {
    this.addUserLoading = true;
    try {
      const result = await addAccessHistory(this.accessId, newEmail);
      if (result.status === 200) {
        this.userCount += result?.data.length;
        this.addUserLoading = false;
        this.addUserCompleted = true;
        // Filter access to get access name
        const accessName = this.managedAccesses?.filter(item => {
          return item.id === result.data.access_id;
        });
        this.accessUsers = result?.data.concat(this.accessUsers);
        // Amplitude Event
        const eventProperties = {
          'access code': `${accessName[0].access_name}`,
          'added email': `${newEmail}`,
        };
        amplitude.getInstance().logEvent('added-to-access-code', eventProperties);
      }
    } catch (error) {
      this.setAddUserError(error?.response?.data);
      this.addUserLoading = false;
    }
  };

  deleteAccessHistory = async () => {
    const result = await deleteAccessHistory(this.accessHistoryId);
    if (result.status) {
      this.getManagedDetail();
      this.accessHistoryId = [];
      // Amplitude Event
      const eventProperties = {
        'access code': `${result.data.deleteAccess[0].access.access_name}`,
        'removed email': `${result.data.deleteAccess[0].accessUser.email}`,
      };
      amplitude.getInstance().logEvent('removed-from-access-code', eventProperties);
    }
  };

  setAccessHistory = (value, splice = false) => {
    if (splice) {
      this.accessHistoryId.splice(value, 1);
    } else {
      this.accessHistoryId = [...this.accessHistoryId, value];
    }
  };

  resetAccessHistory = () => {
    this.accessHistoryId = [];
  };

  changeAccess = id => {
    this.access = id;
  };
}
