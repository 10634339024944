import axios from './axios';

/**
 * calls the carousel GET api and get the carousel data
 */
export const getCarousel = () => {
  return axios.get('/carousel');
};

export const getCarouselVideos = (
  id,
  sortBy,
  sort,
  content_subtype,
  limit,
  page,
  organization,
  specialty,
  q,
  is_cme,
  video_type,
) => {
  return axios.get(`/carousel/carousel-video/${id}?sortBy=${sortBy}&sort=${sort}`, {
    params: {
      content_subtype,
      limit,
      page,
      organization_id: organization,
      specialty,
      q,
      is_cme,
      video_type,
    },
  });
};

export const getLogicCarouselVideos = (
  slug,
  sortBy,
  sort,
  limit,
  page,
  content_subtype,
  organization,
  specialty,
  q,
  is_cme,
  video_type,
) => {
  return axios.get(`/carousel/carousel-video/logic/${slug}`, {
    params: {
      limit,
      page,
      sortBy,
      sort,
      content_subtype,
      organization_id: organization,
      specialty,
      q,
      is_cme,
      video_type,
    },
  });
};

export const getCarouselTypes = () => {
  return axios.get('/carousel/type');
};

export const getCarouselTopics = () => {
  return axios.get('/carousel/type', {
    params: {
      tags: ['topic'],
    },
  });
};

export const getNewWatches = () => {
  return axios.get('/carousel/resume-videos');
};
