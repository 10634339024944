import styled from 'styled-components';

const SubscriptionNavbarContainer = styled.div`
  & .nav {
    background-color: #181a21;
    padding: 13px 24px;
    width: 100vw;
  }

  & .a_subscription_exit {
    margin-right: 8px;
    color: #30a0ff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-align: right;
  }
`;

export default SubscriptionNavbarContainer;
