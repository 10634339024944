import {makeAutoObservable} from 'mobx';
import {getByCode} from '../../api/campaign';
import ErrorStore from '../error/errorStore';

export default class CampaignStore {
  errorStore: ErrorStore;

  campaigns = {};

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  async getCampaignByCode(code) {
    let campaign;
    try {
      if (this.campaigns[code]) {
        campaign = this.campaigns[code];
      }
      campaign = await getByCode(code);
      this.campaigns[code] = campaign;
    } catch (error) {}
    return campaign;
  }
}
