// @flow
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Container, ProgressBar, OverlayTrigger} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import Moment from 'react-moment';
import {amplitude} from '../../../utils/Amplitude';
import LocalStorageService from '../../../utils/LocalStorageService';
import {RetakeButton} from '../../../components/AssessmentButton';
import {DeleteModal} from './DeleteModal';
import AlertComponent from '../../../components/AlertComponent';
import PlaylistPopover from '../../../components/PlaylistPopover';
import UpgradeModal from '../../../components/UpgradeModal';
import {useStore} from '../../../store';
import Thumbnail from '../elements/video-thumbnail.png';
import {
  ThumbnailDiv,
  ThumbnailImg,
  ButtonGroup,
  VideoTitle,
  Organization,
  Details,
  VideoCardHeader,
  VideoCard,
  DetailDiv,
} from '../elements/cme';
import {secondsToTime} from '../../../utils/helper';

type Props = {
  item: {
    id: number,
    cme: boolean,
    revoked_at: Date,
    organization: {
      name: string,
    },
    organizationCME: {
      id: number,
      release_date: Date,
      cme_expire_at: Date,
      withdraw_at: Date,
      credit_count: number,
      video: {
        id: number,
        title: string,
        thumbnail: string,
        organization: {
          name: string,
        },
        duration: number,
        watchTime: number,
      },
    },
  },
};

const RevokedCard = observer(({item}: Props) => {
  const location = useHistory();
  const [watchProgress, setWatchProgress] = useState(0);
  const {videoStore, accountStore, cmeStore, alertStore} = useStore();
  const {retakeAssessment, deleteRevokedCme} = cmeStore;
  const {account} = accountStore;
  const {message, status, resetAlert} = alertStore;
  const {playlist, getAllPlaylists, addToPlaylist, updatePlaylist, toggleVideoBookmark} = videoStore;

  /* Delete cme modal state */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);
  const handleDeleteModalClose = () => setShowDeleteModal(false);

  const getRemainingDays = (date: Date) =>
    date ? Math.floor((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : 0;

  useEffect(() => {
    const wTime = item?.organizationCME?.video.watchTime || 0;
    const duration = item?.organizationCME?.video.duration || 0;
    const progress = Math.round((wTime / duration) * 100);
    setWatchProgress(progress);
  }, [item]);

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const loggedInUser = LocalStorageService.getUser();

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) {
      setUserState(true);
      getAllPlaylists();
    }
  }, [account, getAllPlaylists]);

  const createAndAddToPlaylist = data => {
    addToPlaylist(item?.organizationCME?.video?.id, data.listname, true);
  };

  const updateExistingPlaylist = data => {
    updatePlaylist(item?.organizationCME?.video?.id, data, true);
  };

  const handleSubmitForm = () => {
    deleteRevokedCme(item.id);
    setShowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    handleDeleteModalShow();
  };

  const openVideo = video => {
    location.push(`/video/${video.id}`);
  };

  const [bookmarkIcon, setBookmarkIcon] = useState(false);

  useEffect(() => {
    if (item?.organizationCME?.video?.bookmark?.length) setBookmarkIcon(true);
  }, [item]);

  const toggleBookmark = () => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic')) {
        toggleVideoBookmark(bookmarkIcon, item?.organizationCME?.video?.id).then(() => {
          setBookmarkIcon(!bookmarkIcon);
        });
        amplitude.getInstance().logEvent('bookmarked');
      } else setShowBookmarkUpgradeModal(true);
    } else location.push('/login');
  };

  const getActionButtonAttributes = () => {
    if (getRemainingDays(item?.organizationCME?.withdraw_at) < 0) {
      return {
        disabled: true,
        className: 'a_notAvailabe disabled-action-button',
        text: 'Not Available',
        onClick: undefined,
      };
    }
    if (!(new Date(item?.organizationCME.cme_expire_at) >= new Date())) {
      return {
        disabled: false,
        className: 'a_expired disabled-action-button',
        text: 'CME Expired',
        onClick: undefined,
      };
    }
    return {
      disabled: false,
      className: 'a_finish_assessment',
      onClick: () => retakeAssessment(item?.id, item?.organizationCME?.id),
      text: 'Retake Assessment',
    };
  };

  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
      <VideoCard>
        <Container>
          <div className="card-border-bottom d-md-flex card-padding" id={item.id}>
            <div className="detail-container w-100 d-flex">
              <ThumbnailDiv>
                <ThumbnailImg
                  src={item?.organizationCME?.video.thumbnail || Thumbnail}
                  alt={item?.organizationCME?.video.title}
                />
                <ProgressBar now={watchProgress} />
              </ThumbnailDiv>
              <DetailDiv className="w-100">
                <VideoCardHeader className="d-flex">
                  <div className="d-flex">
                    <div className="progress-badge revoked-badge">Revoked</div>
                    {item?.organizationCME ? (
                      <span className="credit">
                        {item?.organizationCME?.credit_count} Credit
                        {item?.organizationCME?.credit_count > 1 ? 's' : ''}
                      </span>
                    ) : null}
                  </div>
                </VideoCardHeader>
                <VideoTitle onClick={() => openVideo(item?.organizationCME?.video)}>
                  <h5>{item?.organizationCME?.video?.title}</h5>
                </VideoTitle>
                <Organization>
                  {item?.organizationCME?.video.organization?.name}
                  {item?.organizationCME?.video?.organization?.name &&
                  item?.organizationCME?.video?.specialties.length > 0
                    ? ' | '
                    : ''}
                  {item?.organizationCME?.video?.specialties.length > 0
                    ? `${item?.organizationCME?.video?.specialties
                        .slice(0, 2)
                        ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                    : ''}
                </Organization>
                <Details>
                  {secondsToTime(item?.organizationCME?.video?.duration || 0)}
                  &nbsp;|&nbsp;
                  <Moment format="MM/YYYY">{item?.organizationCME?.release_date}</Moment>
                  &nbsp;|&nbsp;
                  <span className="revoked_span">
                    Revoked <Moment format="MM/DD/YYYY">{item?.revoked_at}</Moment>
                  </span>
                </Details>
              </DetailDiv>
            </div>
            <ButtonGroup className="button-alignment">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={props => (
                  <PlaylistPopover
                    props={props}
                    videoPlaylist={item?.organizationCME?.video?.playlist}
                    allPlaylist={playlist}
                    onExisting={updateExistingPlaylist}
                    onNew={createAndAddToPlaylist}
                    userState={userState}
                    showUpgradeModal={isShowPlaylistUpgradeModal}
                    onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                  />
                )}
                rootClose
              >
                <Button
                  variant="basic"
                  className="a_playlist action-icon-button mr-3"
                  data-tip="Playlist"
                  data-for="videoActions"
                  onClick={() => setShowPlaylistUpgradeModal(true)}
                >
                  <i className="fak fa-list-ul" />
                </Button>
              </OverlayTrigger>
              <Button
                variant="basic"
                onClick={() => toggleBookmark()}
                className="a_bookmark action-icon-button mr-3"
                data-tip="Bookmark"
                data-for="videoActions"
              >
                {bookmarkIcon ? (
                  <span key="video_bookmark">
                    <i className="fas fa-bookmark" />
                  </span>
                ) : (
                  <span key="video_bookmark_active">
                    <i className="far fa-bookmark" />
                  </span>
                )}
              </Button>
              <Button variant="basic" className="action-icon-button a_remove" onClick={handleDeleteModal}>
                <i className="fa fa-trash" />
              </Button>
              <RetakeButton onRetake={getActionButtonAttributes()?.onClick} />
            </ButtonGroup>
          </div>
        </Container>
      </VideoCard>
      {/* Update Modal to delete cme */}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteModalClose={handleDeleteModalClose}
        handleSubmitForm={handleSubmitForm}
        videoTitle={item?.organizationCME?.video?.title}
        tabName="Revoked"
      />
    </>
  );
});

export default RevokedCard;
