import React, {useEffect, useState} from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Spinner} from 'react-bootstrap';
import {Style} from './utils/styles';
import Home from './pages';
import {ThemeStore} from './context/ThemeStore';
import Register from './pages/Register';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Courses from './pages/Courses';
import Bookmarks from './pages/Bookmarks';
import PurchasedContent from './pages/PurchasedContent';
import CME from './pages/CME';
import Profile from './pages/Profile';
import {SubscriptionFlow} from './pages/Profile/components/subscription';
import {PrivateRoute, LoginRoute, AdminRoute, RegisterRoute} from './PrivateRoute';
import ResetPassword from './pages/ResetPassword';
import VerifyEmail from './pages/VerifyEmail';
import Following from './pages/Following';
import CoursesLanding from './pages/CoursesLanding';
import OrganizationDetail from './pages/OrganizationDetail';
import ExpertDetails from './pages/ExpertDetails';
import {useStore} from './store';
import OrganizationExperts from './pages/OrganizationExperts';
import CancelSubscription from './pages/CancelSubscription';
import Video from './pages/Video';
import SocketClient from './socketClient';
import Experts from './pages/Experts';
import history from './history';
import Playlist from './pages/Playlist';
import PlaylistDetail from './pages/Playlist/components/playlistDetail';
import History from './pages/History';
import LikedVideos from './pages/LikedVideos';
import Search from './pages/Search';
import Organizations from './pages/Organizations';
import {loadHubspot} from './hubspot';
import ViewAll from './pages/Home/components/VideoViewAll';
import SpecialtyAll from './pages/Specialty/SpecialtyShowAll';
import Notes from './pages/Notes';
import AccessVerify from './pages/AccessVerify';
import OrganizationVideos from './pages/OrganizationVideos';
import OrganizationCourses from './pages/OrganizationCourses';
import VR360 from './pages/VR360';
import AdminPanel from './pages/AdminPanel';
import ContentRequest from './pages/ContentRequest';
import CourseCatalog from './pages/Catalog/components/CourseCatalog';
import VideoCatalog from './pages/Catalog/components/VideoCatalog';
import ConferenceCatalog from './pages/Catalog/components/ConferenceCatalog';
import Browse from './pages/Browse';
import ErrorHandler from './components/ErrorHandler/ErrorHandler';
import UpgradeModal from './components/UpgradeModal';
import {trackPageView} from './api/tracker';
import Conference from './pages/Conference';
import ConferenceLanding from './pages/ConferenceLanding';
import BrowserAlert from './components/BrowserAlert';
import Sponsored from './pages/Sponsored';
import LaunchApp from './components/LaunchApp';
import AccountDetails from './pages/Register/elements/AccountDetails';
import {getAccessToken as getOAuthAccessToken} from './api/oauth';
import LocalStorageService from './utils/LocalStorageService';
import VideoErrorBoundary from './pages/Video/components/PlayerErrorBoundary';
import OAuthRedirect from './pages/OAuth/components/EBSCO';
import AdminDashBoard from './pages/AdminDashboard';
import Certificate from './pages/Certificate';

const App = () => {
  const {accountStore, authStore, announcementStore, mediaStore, accessStore, regStore, messageStore} = useStore();
  const {account, getAccount, isAccountLoading} = accountStore;
  const {addAnnouncement, addNotification} = announcementStore;
  const {user, userId, logUserSession, showUpdateModal, setShowUpdateModal} = authStore;
  const {addMessage} = messageStore;
  const {last_login_date} = user;
  const {updateCarousels} = mediaStore;
  const [isShow, setShowModal] = useState(false);
  const [showBrowserModal, setShowBrowserModal] = useState(false);
  const [showOnMobile, setShowOnMobile] = useState(true);
  const {showLaunchScreen} = regStore;
  const [onIosDevice, setOnIos] = useState(false);
  // Get the user-agent string
  const userAgentString = navigator.userAgent;

  const device = userAgentString.match(/iPhone|iPad|iPod/i);

  let browser = null;
  // Detect Chrome
  let chromeAgent = userAgentString.indexOf('Chrome') > -1;

  // Detect Internet Explorer
  if (userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1) {
    browser = 'IE';
  }

  // Detect Firefox
  if (userAgentString.indexOf('Firefox') > -1) {
    browser = 'Firefox';
  }

  // Detect Safari
  let safariAgent = userAgentString.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  // Detect Opera
  const operaAgent = userAgentString.indexOf('OP') > -1;

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false;

  if (chromeAgent) {
    browser = 'Chrome';
  } else if (safariAgent) {
    browser = 'Safari';
  } else if (operaAgent) {
    browser = 'OP';
  }

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const params = new URLSearchParams(window.location.search);

        const original_state = LocalStorageService.getStateToken();
        const recieved_state = params.get('state');
        if (original_state && recieved_state && recieved_state !== original_state) {
          history.push('/login?error=oauth_error');
          return;
        }

        const error = params.get('error');
        if (error) {
          history.push('/login?error=oauth_error');
        }

        const code = params.get('code');
        if (!code) return;

        const response = await getOAuthAccessToken(code, 'EBSCO');
        history.push('/');
        accessStore.setOAuthBasedAccess(response.data.access);
        const {access_token, refresh_token} = response.data;
        LocalStorageService.setToken({access_token, refresh_token});
      } catch (error) {
        history.push('/login?error=oauth_error');
      } finally {
        LocalStorageService.clearStateToken();
      }
    };
    getAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (device && safariAgent) {
      setShowOnMobile(false);
    }
    if (device) {
      setOnIos(true);
    }
  }, [device, safariAgent]);

  useEffect(() => {
    const browserPopUpLocalStorage = localStorage.getItem('ShowBrowserPopUp');

    if (browserPopUpLocalStorage === null) {
      localStorage.setItem('ShowBrowserPopUp', true);
      setShowBrowserModal(true);
    }
    if (browserPopUpLocalStorage === 'true') {
      setShowBrowserModal(true);
    } else if (browserPopUpLocalStorage === 'false') {
      setShowBrowserModal(false);
    }
  }, []);

  const closeBrowserModal = () => {
    setShowBrowserModal(false);
    localStorage.setItem('ShowBrowserPopUp', false);
  };
  const {pathname} = window.location;
  const isRegistering = pathname.startsWith('/register');

  useEffect(() => {
    if (process.env.REACT_APP_DISABLE_HUBSPOT !== 'true') {
      loadHubspot(process.env.REACT_APP_HUBSPOT_ID);
    }
    let isAccountFetching = true;
    if (isAccountFetching && !isRegistering) {
      getAccount();
    }
    return () => {
      isAccountFetching = false;
    };
  }, [userId, getAccount, isRegistering, last_login_date]);

  useEffect(() => {
    if (showUpdateModal) setShowModal(showUpdateModal);
  }, [showUpdateModal]);

  useEffect(() => {
    if (account?.id && account?.is_registration_completed) {
      if (!account.profession_specialty) {
        if (account.profession_id && (account.specialty_id || account.specialties)) {
          setShowModal(true);
        } else {
          history.push('/register/profession');
        }
      }
    }
  }, [account]);

  useEffect(() => {
    // the page was opened in a new window or tab and accessed directly
    if (history.action === 'POP') {
      if (account?.id || accessStore.anonymousAccess)
        trackPageView(history.location.pathname + history.location.search);
    }
    // will not run if above condition is true
    return history.listen(location => {
      if (account?.id || accessStore.anonymousAccess) trackPageView(location.pathname + location.search);
    });
  });

  useEffect(() => {
    const listenSocket = () => {
      SocketClient?.socket?.on('message', message => {
        switch (message.type) {
          case 'announcement':
            addAnnouncement(message.data);
            break;
          case 'notification':
            addNotification(message.data);
            break;
          case 'carousel-update':
            updateCarousels(message?.data?.carousels || []);
            break;
          case 'send-message':
            addMessage(message?.data);
            break;
          default:
            break;
        }
      });
    };
    listenSocket();
    return () => {
      SocketClient.disconnect();
    };
  }, [addAnnouncement, addNotification, updateCarousels, addMessage]);

  // Fetch ip-based access entry
  useEffect(() => {
    accessStore.fetchIPBasedAccess();
  }, [accessStore]);

  // Fetch anonymous access entry
  useEffect(() => {
    accessStore.fetchOAuthBasedAccess();
  }, [accessStore]);

  useEffect(() => {
    logUserSession();
  }, [logUserSession, user]);

  const onHide = () => {
    setShowModal(false);
    setShowUpdateModal(false);
  };

  return (
    <Router history={history}>
      <UpgradeModal access="" isShow={isShow} onHideModal={onHide} />
      {showOnMobile && browser !== 'Chrome' && !isAccountLoading ? (
        <BrowserAlert show={showBrowserModal} onHide={closeBrowserModal} />
      ) : (
        ''
      )}
      <ThemeStore>
        <ErrorHandler />
        <Style />
        {isAccountLoading && (
          <div data-testid="app-loader" className="d-flex vh-100 justify-content-center align-items-center">
            <Spinner animation="grow" size="lg" />
          </div>
        )}
        {showLaunchScreen && onIosDevice && !account.access?.content_based ? (
          <LaunchApp />
        ) : (
          <Switch>
            <Route path="/register/v2/email">
              <Redirect to="/register" />
            </Route>
            <RegisterRoute path="/register" component={Register} />
            <AdminRoute path="/admin" component={AdminPanel} />
            <AdminRoute exact path="/admin_dashboard" component={AdminDashBoard} />
            <LoginRoute exact path="/login" component={Login} />
            <LoginRoute exact path="/access" component={AccessVerify} />
            <LoginRoute exact path="/access/account" component={AccountDetails} />
            <LoginRoute exact path="/forgot_password" component={ForgotPassword} />
            <LoginRoute exact path="/reset_password" component={ResetPassword} />
            <Route exact path="/" component={Home} />
            <Route exact path="/browse/:slug" component={Browse} />
            <PrivateRoute exact path="/profile/plan-update">
              <SubscriptionFlow activeRegistrationStep="plan" />
            </PrivateRoute>
            <PrivateRoute exact path="/profile/payment">
              <SubscriptionFlow activeRegistrationStep="payment" />
            </PrivateRoute>
            <PrivateRoute path="/profile" component={Profile} />
            <Route exact path="/courses" component={Courses} />
            <Route path="/courses/:id/:slug" component={CoursesLanding} />
            <PrivateRoute exact path="/bookmarks" component={Bookmarks} />
            <Route exact path="/playlist" component={Playlist} />
            <Route exact path="/playlist/:id" component={PlaylistDetail} />
            <Route path="/cme" component={CME} />
            <Route path="/certificate" component={Certificate} />

            <Route exact path="/verify-email" component={VerifyEmail} />
            <Route exact path="/experts/:id/:slug" component={ExpertDetails} />
            <Route path="/cancel" component={CancelSubscription} />
            <Route
              exact
              path="/video/:id"
              render={props => (
                <VideoErrorBoundary>
                  <Video {...props} />
                </VideoErrorBoundary>
              )}
            />
            <Route exact path="/experts" component={Experts} />
            <Route exact path="/content-request" component={ContentRequest} />
            <PrivateRoute exact path="/history" component={History} />
            <PrivateRoute exact path="/likes" component={LikedVideos} />
            <PrivateRoute exact path="/following" component={Following} />
            <Route exact path="/organization/" component={Organizations} />
            <Route exact path="/organization/:id/experts" component={OrganizationExperts} />
            <Route exact path="/organization/:id/:slug/all-videos" component={OrganizationVideos} />
            <Route exact path="/organization/:id/:slug/all-courses" component={OrganizationCourses} />
            <Route exact path="/sponsored/:id/experts" component={OrganizationExperts} />
            <Route exact path="/sponsored/:id/:slug/all-videos" component={OrganizationVideos} />
            <Route exact path="/sponsored/:id/:slug/all-courses" component={OrganizationCourses} />
            <Route path="/organization/:id/:slug" component={OrganizationDetail} />
            <Route path="/sponsored/:id/:slug" component={OrganizationDetail} />
            <Route exact path="/all/:id" component={ViewAll} />
            <Route exact path="/specialty/:id/:slug" component={SpecialtyAll} />
            <Route path="/search" component={Search} />
            <Route path="/notes" component={Notes} />
            <Route exact path="/360VR" component={VR360} />
            <Route exact path="/catalog/courses" component={CourseCatalog} />
            <Route exact path="/catalog/videos" component={VideoCatalog} />
            <Route exact path="/catalog/conferences" component={ConferenceCatalog} />
            <PrivateRoute exact path="/purchased" component={PurchasedContent} />
            <PrivateRoute exact path="/purchased/:id/:action" component={PurchasedContent} />
            <Route exact path="/conferences" component={Conference} />
            <Route path="/conferences/:id/:slug" component={ConferenceLanding} />
            <Route exact path="/sponsored/" component={Sponsored} />
            <Route path="/openid/third-party" component={OAuthRedirect} />
            <Redirect to="/" />
          </Switch>
        )}
      </ThemeStore>
    </Router>
  );
};

export default observer(App);
