import styled from 'styled-components';

export const PopoverWrapper = styled.div`
  & .popover {
    border-radius: 0.6rem !important;
    z-index: 11000;
  }
  & .popover .arrow {
    display: none;
  }
`;

export const MobilePopoverDiv = styled.div`
  width: 286px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.card_bg};
  box-shadow: ${({theme}) => theme.card_boxshadow};
  padding: 18px 27px;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;

  .mobile-playlist {
    width: 181px;
  }

  .fa-trash-alt {
    margin-right: 7px;
  }

  .updateplylist,
  .remove {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.11px;
    font-weight: 500;
    padding: 8px 16px;
    margin-top: 15px;
  }

  .remove {
    color: white;
  }

  .title {
    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c4c4c4 !important;
  }

  .plus {
    font-size: 22px;
    margin-right: 2px;
  }

  ul {
    list-style-type: none;
    margin: 1em 0;
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  label {
    margin: 0 0.5em;
    color: ${({theme}) => theme.card_text};
    font-size: 0.9rem;
  }
  input[type='checkbox'] {
    width: 14px;
    height: 16px;
  }
  button:not(.updateplylist, .remove) {
    background-color: transparent;
    border: none;
    color: white;
    padding: 0;
    margin-bottom: 12px;
  }
  display: none;
  @media screen and (max-width: 576px) {
    display: block;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #4e505a;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: ${({theme}) => theme.title};
  margin-top: 10px;
`;

export const Input = styled.input`
  height: 40px;
  margin: 1rem 0;
  opacity: ${props => (props.watch ? '1' : '0.4')};
  border-radius: 20px;
  padding-left: 1rem;
  font-size: 0.9rem;

  ::placeholder {
    font-weight: 500;
    color: white;
  }
  &:focus {
    outline: none;
  }
`;

export const DeskPopoverDiv = styled.div`
  width: 376px;
  border-radius: 0.5rem;
  background-color: ${({theme}) => theme.card_bg};
  box-shadow: ${({theme}) => theme.card_boxshadow};

  ul {
    list-style-type: none;
    margin: 1em 0;
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
  }
  label {
    margin: 0 0.5em;
    color: ${({theme}) => theme.card_text};
    font-size: 0.9rem;
  }
  input[type='checkbox'] {
    width: 14px;
    height: 16px;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const CreateButton = styled.button`
  border: none;
  background: transparent;
  color: #30a0ff;
  margin-top: 10px;
  font-size: 0.8rem;
  font-weight: 500;
`;

export const AddButton = styled.button`
  opacity: ${props => (props.watch ? '1' : '0.5')};
  border: none;
  border-radius: 0.25rem;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;

  ::disabled {
    color: #b8bbbe;
    cursor: initial !important;
  }
`;
