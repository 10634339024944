import React from 'react';
import Tour from 'reactour';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import './style.css';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../../../store';

const VideoTutorial = observer(({isTutorialOpen, setIsTourOpen}: boolean) => {
  const {accountStore} = useStore();
  const {userTutorialChanged} = accountStore;

  const tutorialConfig = [
    {
      selector: '[data-tut="videotutorial__videotab"]',
      content: () => (
        <div className="tutorial videotutorial">
          <div className="name cme-title">
            <div className="cme-title">
              <span className="icon cme">
                <i className="fak fa-cme-1" />
              </span>
              <span className="icon multiple-icons note">
                <i className="fas fa-sticky-note" />
              </span>
              <span className="icon multiple-icons file">
                <i className="fas fa-file-alt" />
              </span>
              <span>Premium Features</span>
            </div>
            <div className="cme-premium">Premium Plans Only</div>
          </div>
          <p>
            Check out these new features in each video page. As a premium user, you can earn unlimited CME, take notes,
            and view transcripts in multiple lanuages for every videos.
          </p>
        </div>
      ),
    },
  ];

  const accentColor = '#5cb7b7';
  const closeTour = () => {
    setIsTourOpen(false);
    userTutorialChanged('show_videopage_tutorial', true);
  };
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  return (
    <div>
      <Tour
        onRequestClose={closeTour}
        steps={tutorialConfig}
        isOpen={isTutorialOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        showNumber={false}
        showButtons={false}
      />
    </div>
  );
});

export default VideoTutorial;
