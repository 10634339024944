import React from 'react';
import {observer} from 'mobx-react-lite';
import {Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {UserToolsDiv} from '../../elements/styles';
import {useStore} from '../../../../store';

const UserTools = observer(() => {
  const {register, handleSubmit, reset, errors} = useForm();
  const {register: ignoreRegister, handleSubmit: ignoreSubmit, reset: ignoreReset, errors: ignoreErrors} = useForm();
  const {register: pwdRegister, handleSubmit: pwdSubmit, reset: pwdReset, errors: pwdErrors} = useForm();

  const {adminStore} = useStore();
  const {
    setBanUser,
    banResp,
    setBanResponse,
    setIgnoreUser,
    ignoreResp,
    setIgnoreResponse,
    setNewUserPwd,
    updatePwdResp,
    setUpdatePwdResponse,
  } = adminStore;

  const onInputChange = () => {
    setBanResponse({});
  };
  const onBanUser = data => {
    setBanUser(data.banEmail).then(() => reset());
  };

  const onIgnoreInputChange = () => {
    setIgnoreResponse({});
  };
  const onIgnoreUser = data => {
    setIgnoreUser(data.ignoreEmail).then(() => ignoreReset());
  };

  const onPwdEmailInputChange = () => {
    setUpdatePwdResponse({});
  };
  const onPwdInputChange = () => {
    setUpdatePwdResponse({});
  };
  const onUpdateUserPwd = data => {
    setNewUserPwd(data.pwdEmail, data.password).then(() => pwdReset());
  };

  return (
    <UserToolsDiv>
      <Form onSubmit={handleSubmit(onBanUser)}>
        <h6>User ban</h6>
        <span>Ban a user from GIBLIB</span>
        <input
          name="banEmail"
          onChange={onInputChange}
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email format',
            },
          })}
          placeholder="Enter email here"
        />
        <Form.Row>
          <input type="submit" disabled={errors?.banEmail} />
          {banResp?.type === 'success' && (
            <div className="status-div user-tool-success" key="user-ban-success">
              {banResp?.email} has been successfully updated.
            </div>
          )}
          {banResp?.type === 'error' && (
            <div className="status-div user-tool-error" key="user-ban-error">
              {banResp?.email} does not exist.
            </div>
          )}
        </Form.Row>
      </Form>
      <Form onSubmit={ignoreSubmit(onIgnoreUser)}>
        <h6>Ignore user data</h6>
        <span>This user’s activity data will be ignored for ML</span>
        <input
          name="ignoreEmail"
          onChange={onIgnoreInputChange}
          ref={ignoreRegister({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email format',
            },
          })}
          placeholder="Enter email here"
        />
        <Form.Row>
          <input type="submit" disabled={ignoreErrors?.ignoreEmail} />
          {ignoreResp?.type === 'success' && (
            <div className="status-div user-tool-success" key="user-ignore-success">
              {ignoreResp?.email} has been successfully updated.
            </div>
          )}
          {ignoreResp?.type === 'error' && (
            <div className="status-div user-tool-error" key="user-ignore-error">
              {ignoreResp?.email} does not exist.
            </div>
          )}
        </Form.Row>
      </Form>
      <Form onSubmit={pwdSubmit(onUpdateUserPwd)}>
        <h6>Reset Password</h6>
        <span>Reset a user’s password</span>
        <input
          name="pwdEmail"
          onChange={onPwdEmailInputChange}
          ref={pwdRegister({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email format',
            },
          })}
          placeholder="Enter email here"
        />
        <input
          type="text"
          name="password"
          onChange={onPwdInputChange}
          ref={pwdRegister({required: true})}
          placeholder="Enter new password here"
        />
        <Form.Row>
          <input type="submit" disabled={pwdErrors?.pwdEmail || pwdErrors?.password} />
          {updatePwdResp?.type === 'success' && (
            <div className="status-div user-tool-success" key="user-pwdupdate-success">
              {updatePwdResp?.email} password has been successfully updated.
            </div>
          )}
          {updatePwdResp?.type === 'error' && (
            <div className="status-div user-tool-error" key="user-pwdupdate-error">
              {updatePwdResp?.email} does not exist.
            </div>
          )}
        </Form.Row>
      </Form>
    </UserToolsDiv>
  );
});

export default UserTools;
