// @flow
import axios from 'axios';
import {observable, makeAutoObservable, runInAction} from 'mobx';
import brightcovePlayerLoader from '@brightcove/player-loader';
import {
  getVideo,
  getVideos,
  getVideoNotes,
  addVideoNote,
  editVideoNote,
  deleteVideoNote,
  getAllPlaylists,
  addToPlaylist,
  massUpdatePlaylists,
  updateThumbsUp,
  updateWatchHistory,
  getMLRecommendedVideos,
  getRecommendedVideos,
  getNextPlaylistVideos,
  getAllNotes,
  getSpecialtyVideos,
  get360Videos,
  getContentTypeList,
  getEachSpecialtyContentSubtypeList,
  getUserUploadVideos,
  moderateVideo,
  uploadVideo,
  uploadVideoWithS3,
  getSignedS3Url,
  getUserUploadedVideo,
  publishVideo,
  getAllUsersVideos,
  deleteUserVideo,
} from '../../api/video';
import {createBookmark, deleteBookmark, followItem, unfollowItem, getSpecialties} from '../../api/course';
import {getNextConferenceVideos} from '../../api/conferenceLanding';

import {getNextCourseVideos} from '../../api/courseLanding';
import AccountStore from '../account/accountStore';
import AlertStore from '../alert/alertStore';
import type {Video, WatchHistory, Transcript, Playlist} from '../../utils/types';
import {getSortedOrganizations} from '../../api/organization';
import CmeStore from '../cme/cmeStore';
import ErrorStore from '../error/errorStore';
import {getRemoteIP} from '../../api/account';
import MediaStore from '../media/mediaStore';
import AccessStore from '../access/AccessStore';
import CertificateStore from '../certificate/CertificateStore';

const {CancelToken} = axios;

type ListVideoParams = {
  include: ('bookmark' | 'playlist')[] | null | undefined,
};

class VideoStore {
  errorStore: ErrorStore;

  mediaStore: MediaStore;

  sortOrder: string = 'release_date';

  accountStore: AccountStore;

  cmeStore: CmeStore;

  certificateStore: CertificateStore;

  alertStore: AlertStore;

  accessStore: AccessStore;

  name: string;

  source;

  videoTileBookmark: number;

  video: Video = {};

  videoLoading: boolean = false;

  videoNoteSaving: boolean = false;

  watchNextLoading: boolean = false;

  videoPlaying: boolean = false;

  videoNotes = [];

  videoPlayer: any;

  playlist = [];

  videotilePlaylist = [];

  courseVideos = [];

  playlistVideos = [];

  userVideos = [];

  allUsersVideos = [];

  recommendedVideos = [];

  watchHistory: WatchHistory = {};

  notesCount: number = 0;

  videoCount: number = 0;

  count: number = 100;

  sortBy: string = 'DESC';

  noteSortBy: string = 'DESC';

  page: number = 1;

  notes: Array = [];

  selectedTranscript: Transcript = [];

  transcript: Transcript = [];

  organizationsList: Array = [];

  selectedContenttypeList: Array = [];

  selectedOrganizationList: Array = [];

  specialtiesList: Array = [];

  subSpecialtiesList: Array = [];

  vrSpecialtiesList: Array = [];

  selectedSpecialtiesList: Array = [];

  selectedSubSpecialtiesList: Array = [];

  specialtyList: Array = [];

  selectedVRSpecialtiesList: Array = [];

  selectedContenttypes: Array = [];

  selectedOrganizationIds: Array = [];

  selectedSpecialtyIds: Array = [];

  selectedSubSpecialtyIds: Array = [];

  allSpecialtyVideos: Array = [];

  allSpecialtyVideosFilter: Array = [];

  selectedSpecialtyFilter: string = '';

  all360Videos: Array = [];

  videos: Array = [];

  videosList: Array = [];

  contenttypeList: Array = [];

  specialtyContentsubtypeList: Array = [];

  displaySpecialty: string = '';

  isLoading: boolean = false;

  hasMoreVideos: boolean = true;

  hasMore360Videos: boolean = true;

  watched: boolean = false;

  specialtyPage: number = 2;

  video360Page: number = 2;

  specialtyId: number = null;

  newWatches: boolean = false;

  isCME: boolean = false;

  isVR: boolean = false;

  referrer: string = 'direct_url_access';

  searchText: string = '';

  catalogLoading: boolean = true;

  videoTimeExceeded: boolean = false;

  videoPause: boolean = false;

  videoSeek: boolean = false;

  rewatch: boolean = false;

  noFreeMin: boolean = false;

  uploadedVideo: Video = {};

  hasWatched: boolen = false;

  submitted: boolean = false;

  percentage: number = 0;

  showBumperSkip = false;

  bumperEnded = false;

  showAd = true;

  bumperCounter = 10;

  constructor({
    accountStore,
    alertStore,
    cmeStore,
    errorStore,
    mediaStore,
    accessStore,
    certificateStore,
  }: {
    errorStore: ErrorStore,
    accountStore: AccountStore,
    alertStore: AlertStore,
    cmeStore: CmeStore,
    accessStore: AccessStore,
    certificateStore: CertificateStore,
  }) {
    this.errorStore = errorStore;
    this.mediaStore = mediaStore;
    makeAutoObservable(this);
    this.accountStore = accountStore;
    this.alertStore = alertStore;
    this.cmeStore = cmeStore;
    this.certificateStore = certificateStore;
    this.accessStore = accessStore;
    this.videoCount = observable;
  }

  setWatched = value => {
    this.watched = value;
  };

  setNewWatches = value => {
    this.newWatches = value;
  };

  setIsSubmitted = value => {
    this.submitted = value;
  };

  setPercentage = value => {
    this.percentage = value;
  };

  setReferrer = value => {
    this.referrer = value;
  };

  setSpecialtyPage = name => {
    this.displaySpecialty = name;
  };

  setSpecialtyId = id => {
    this.selectedSubSpecialtyIds = [];
    this.selectedSubSpecialtiesList = [];
    this.specialtyId = id;
  };

  resetPage = () => {
    this.page = 1;
  };

  setCME = (value, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.isCME = value;
  };

  setVR = (value, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.isVR = value;
  };

  setCatalogLoading = value => {
    this.catalogLoading = value;
  };

  setSearch = value => {
    this.page = 1;
    this.searchText = value;
  };

  resetSearch = () => {
    this.searchText = null;
  };

  changeCount = option => {
    this.count = option;
    this.page = 1;
  };

  changeItem = option => {
    this.sortOrder = option;
  };

  changeSort = option => {
    this.sortBy = option;
    this.noteSortBy = option;
    this.all360Videos = [];
    this.videosList = [];
    this.page = 1;
  };

  resetspecialtiesList = () => {
    this.allSpecialtyVideos = [];
  };

  setSortType = option => {
    this.sortOrder = option;
  };

  pageIncrement = () => {
    this.page += 1;
    this.listVideos({include: ['bookmark', 'playlist']});
  };

  pageDecrement = () => {
    this.page -= 1;
    this.listVideos({include: ['bookmark', 'playlist']});
  };

  resetPage = () => {
    this.page = 1;
    this.videoCount = 0;
  };

  setBumperCounter = value => {
    this.bumperCounter = value;
  };

  // remove duplicates from subspecialties
  removeDuplicates = () => {
    this.subSpecialtiesList = [...new Map(this.subSpecialtiesList.map(item => [item.id, item])).values()];
  };

  setSpecialtyPageSubspecialty = subspecialty => {
    this.subSpecialtiesList = subspecialty;
  };

  setSelectedContenttype = (Ids, reload = true) => {
    this.selectedContenttypes = Ids;
    this.selectedContenttypeList = this.contenttypeList.filter(item => Ids.includes(item.id));
    if (reload) {
      this.page = 1;
    }
  };

  setSelectedOrganizations = (Ids, reload = true) => {
    this.selectedOrganizationIds = Ids;
    this.selectedOrganizationList = this.organizationsList.filter(item => Ids.includes(item.id));
    if (reload) {
      this.page = 1;
      this.experts = [];
      this.videos = [];
      this.videosList = [];
    }
  };

  setSelectedSpecialties = (Ids, reload = true) => {
    this.selectedSpecialtiesList = this.specialtiesList?.filter(item => Ids.includes(item.id));
    if (Ids.length === 0 || this.selectedSpecialtiesList.length) {
      this.selectedSpecialtyIds = Ids;
      if (Ids.length === 0) {
        this.selectedSpecialtiesList = [];
      }
    } else {
      this.selectedSubSpecialtyIds = Ids;
      this.selectedSubSpecialtiesList = this.specialtiesList?.filter(({children}) => Ids.includes(children.id));
    }
    this.mediaStore.setSubSpecialtyList(this.selectedSpecialtiesList, this.selectedSubSpecialtiesList);
    this.subSpecialtiesList = this.mediaStore.subSpecialtiesList;
    if (reload) {
      this.page = 1;
      this.specialtyPage = 2;
      this.video360Page = 2;
      this.videos = [];
      this.videosList = [];
      this.all360Videos = [];
    }
  };

  // remove related specialty when sub specialty is selected
  removeSpecialtyOnSelectSubspecialty = () => {
    const sp = this.selectedSpecialtiesList.filter(x =>
      x.children.every(item => !this.selectedSubSpecialtiesList?.find(sub => sub.id == item.id)),
    );
    this.specialtyList = sp.map(item => item.id);
  };

  setSelectedSubSpecialties = (Ids, reload = true) => {
    this.selectedSubSpecialtyIds = Ids;
    this.selectedSubSpecialtiesList = this.subSpecialtiesList?.filter(item => Ids.includes(item.id));
    this.removeSpecialtyOnSelectSubspecialty();
    if (reload) {
      this.page = 1;
      this.specialtyPage = 2;
      this.video360Page = 2;
      this.videos = [];
      this.videosList = [];
      this.all360Videos = [];
    }
  };

  setSelectedVRSpecialties = (Ids, reload = true) => {
    this.selectedSpecialtyIds = Ids;
    this.selectedVRSpecialtiesList = this.vrSpecialtiesList.filter(item => Ids.includes(item.id));
    if (reload) {
      this.all360Videos = [];
    }
  };
  /**
   * set specialties list observables
   * @param {array} specialties
   */

  resetPageCount = count => {
    this.specialtyPage = count;
    this.allSpecialtyVideos = [];
  };

  setSpecialtiesObservables = specialties => {
    this.specialtiesList = specialties;
  };

  setVRSpecialtiesObservables = specialties => {
    this.vrSpecialtiesList = specialties;
  };

  setAllSpecialtyVideosObservables = videos => {
    this.allSpecialtyVideos = videos;
  };

  setAll360VideosObservables = videos => {
    this.all360Videos = videos;
  };

  setVideoTimeExceeded = value => {
    this.videoTimeExceeded = value;
  };

  setVideoPause = value => {
    this.videoPause = value;
  };

  setRewatch = value => {
    this.rewatch = value;
  };

  /** Fetch All specialties */
  listSpecialties = async () => {
    try {
      const resp = await getSpecialties();
      let {specialties} = resp.data;
      specialties = specialties.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setSpecialtiesObservables(specialties);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All specialties */
  listVRSpecialties = async () => {
    try {
      const resp = await getSpecialties('video', '360vr');
      let {specialties} = resp.data;
      specialties = specialties.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setVRSpecialtiesObservables(specialties);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set course providers list observables
   * @param {array} organizations
   */
  setOrganizationsObservables = organizations => {
    this.organizationsList = organizations;
  };

  /** Fetch All organizations */
  listOrganizations = async () => {
    try {
      const resp = await getSortedOrganizations('DESC');
      let {organizations} = resp.data;
      organizations = organizations.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setOrganizationsObservables(organizations);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onRemoveContenttype = (id, reload = true) => {
    this.selectedContenttypeList = this.selectedContenttypeList.filter(item => item.id !== id);
    this.selectedContenttypes = this.selectedContenttypes.filter(item => item !== id);
    if (reload) {
      this.notes = [];
      this.videosList = [];
    }
  };

  onRemoveOrganization = (id, reload = true) => {
    this.selectedOrganizationList = this.selectedOrganizationList.filter(item => item.id !== id);
    this.selectedOrganizationIds = this.selectedOrganizationIds.filter(item => item !== id);
    if (reload) {
      this.notes = [];
      this.videosList = [];
    }
  };

  /** Remove Specialty */
  onRemoveSpecialty = (id, reload = true) => {
    this.selectedSpecialtiesList = this.selectedSpecialtiesList.filter(item => item.id !== id);
    this.selectedSpecialtyIds = this.selectedSpecialtyIds.filter(item => item !== id);
    this.mediaStore.setSubSpecialtyList(this.selectedSpecialtiesList, this.selectedSubSpecialtiesList);
    this.subSpecialtiesList = this.mediaStore.subSpecialtiesList;
    if (reload) {
      this.notes = [];
      this.specialtyPage = 2;
      this.video360Page = 2;
      this.all360Videos = [];
      this.videosList = [];
    }
  };

  onRemoveSubSpecialty = (id, reload = true) => {
    this.selectedSubSpecialtiesList = this.selectedSubSpecialtiesList?.filter(item => item.id !== id);
    this.selectedSubSpecialtyIds = this.selectedSubSpecialtyIds?.filter(item => item !== id);
    if (reload) {
      this.notes = [];
      this.specialtyPage = 2;
      this.video360Page = 2;
      this.all360Videos = [];
      this.videosList = [];
    }
  };

  /**
   * set catalog video list observables
   * @param {array} videos
   * @param {array} page
   */
  setCatalogVideosObservables = (videos, count, reload) => {
    if (window.innerWidth <= 576 && !reload) {
      this.videosList = [...this.videosList, ...videos];
    } else {
      this.videosList = videos;
    }
    this.videoCount = count;
    this.isLoading = false;
  };

  listContentTypes = async () => {
    this.contenttypeList = [];
    const res = await getContentTypeList();
    const content_subtype = res?.data?.content_subtype;
    if (content_subtype.length) {
      content_subtype.forEach(
        (item, index) =>
          item.content_subtype !== null &&
          item.content_subtype !== '' &&
          this.contenttypeList.push({id: index, name: item}),
      );
    }
  };

  listEachSpecialtyContentSubTypes = async id => {
    this.specialtyContentsubtypeList = [];
    const res = await getEachSpecialtyContentSubtypeList(id);
    const content_subtype = res?.data?.content_subtype;
    if (content_subtype.length) {
      content_subtype.forEach((item, index) => this.specialtyContentsubtypeList.push({id: index, name: item}));
    }
  };

  /**
   * Fetch All videos
   * @param {Array} include  include extra associations
   */

  listVideos = async ({include, ids}: ListVideoParams = {}, reload = false) => {
    this.removeSpecialtyOnSelectSubspecialty();
    try {
      this.isLoading = true;
      if (reload) {
        this.videosList = [];
      }
      // this.catalogLoading = true;
      const res = await getVideos({
        specialty: this.specialtyList.concat(this.selectedSubSpecialtyIds),
        organization: this.selectedOrganizationIds,
        content_subtype: this.selectedContenttypeList.map(item => item.name),
        cme: this.isCME ? true : null,
        videoType: this.isVR ? '360vr' : null,
        sort: this.sortOrder || 'title',
        sortBy: this.sortBy || 'ASC',
        page: this.page || 1,
        limit: this.count,
        q: this.searchText ? this.searchText : null,
        include,
        ids,
      });
      this.videoCount = res?.count;
      this.isLoading = false;
      // this.catalogLoading = false;
      const {videos, count} = res.data;
      this.setCatalogVideosObservables(videos, count, reload);
      this.setAllSpecialtyVideosObservables(videos);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  scrollCatalog = () => {
    if (!this.isLoading && this.count >= this.page * 10) {
      this.pageIncrement();
    }
  };

  setVideoTilePlaylist = (plylist: any[]) => {
    this.videotilePlaylist = plylist;
  };

  updateVideoListPlaylist = (videoId: number, playlist: Playlist[]) => {
    this.videosList = [
      ...this.videosList.map(video => {
        if (video.id === videoId) {
          return {
            ...video,
            playlist,
          };
        }
        return video;
      }),
    ];
  };

  setCourseVideosObservables = videos => {
    this.courseVideos = videos;
  };

  setPlaylistVideosObservable = videos => {
    this.playlistVideos = videos;
  };

  setRecommendedVideosObservable = videos => {
    this.watchNextLoading = false;
    this.recommendedVideos = videos;
  };

  onFetchCourseVideos = async (id, order) => {
    try {
      const resp = await getNextCourseVideos(id, order);
      let {videos} = resp.data;
      videos = videos.map(item => {
        const newVideo = {...item};
        if (!item.video?.watchHistories) {
          newVideo.video.watchProgress = 0;
        } else {
          const watchTime = Math.max(...item.video?.watchHistories.map(o => o.time_watched), 0);
          const videoDuration = item.video?.duration || 0;
          const watchProgress = (watchTime / videoDuration) * 100;
          newVideo.video.watchProgress = Math.round(watchProgress);
        }
        return newVideo;
      });
      this.setCourseVideosObservables(videos);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onFetchConferenceVideos = async (id, order) => {
    try {
      const resp = await getNextConferenceVideos(id, order);
      let {videos} = resp.data;
      videos = videos.map(item => {
        const newVideo = {...item};
        if (!item.video?.watchHistories) {
          newVideo.video.watchProgress = 0;
        } else {
          const watchTime = Math.max(...item.video?.watchHistories.map(o => o.time_watched), 0);
          const videoDuration = item.video?.duration || 0;
          const watchProgress = (watchTime / videoDuration) * 100;
          newVideo.video.watchProgress = Math.round(watchProgress);
        }
        return newVideo;
      });
      this.setCourseVideosObservables(videos);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onFetchPlaylistVideos = async (id: number, order: number) => {
    try {
      const resp = await getNextPlaylistVideos(id, order);
      let {videos} = resp.data;
      videos = videos.map(item => {
        const newVideo = {...item};
        if (!item.watchHistories) {
          newVideo.watchProgress = 0;
        } else {
          const watchTime = Math.max(...item.watchHistories.map(o => o.time_watched), 0);
          const videoDuration = item.duration || 0;
          const watchProgress = (watchTime / videoDuration) * 100;
          newVideo.watchProgress = Math.round(watchProgress);
        }
        return newVideo;
      });
      this.setPlaylistVideosObservable(videos);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onFetchRecommendedVideos = async () => {
    this.watchNextLoading = true;
    try {
      const resp = await getMLRecommendedVideos(this.video?.id);
      const {status, videos: mlVideos} = resp.data;
      let videos: any[] = [];
      if (status && mlVideos.length) {
        videos = mlVideos;
      } else {
        const recommended = await getRecommendedVideos(this.video?.id);
        const {status: tagStatus} = recommended.data;
        if (tagStatus) {
          videos = recommended.data.videos;
        }
      }
      const mappedVideos: any[] = videos.map(item => {
        const newVideo = {...item};
        const videoDuration = item.duration || 0;
        const watchProgress = item?.watch_history_max ? (item?.watch_history_max / videoDuration) * 100 : 0;
        newVideo.watchProgress = Math.round(watchProgress);
        return newVideo;
      });
      this.setRecommendedVideosObservable(mappedVideos);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setTranscriptLanguage = language => {
    const result = this.video.transcripts.find(transcript => transcript.language == language);
    if (result) {
      this.transcript = result;
    }
  };

  getTranscript = () => {
    this.selectedTranscript = this.transcript;
  };

  hasAccess = (video: Video) => {
    const {account, purchasedVideoIds} = this.accountStore;
    if (this.accessStore.anonymousAccess?.id) {
      return true;
    }
    if (account?.id && account?.subscription?.status === 'active') {
      return true;
    }
    if (account?.id && account?.subscription?.status === 'trialing') {
      return true;
    }
    if (account?.access?.content_based) {
      // Only allow access users to watch full video if they purchased it
      return (purchasedVideoIds || []).includes(video?.id);
    }
    if (
      account?.id &&
      account?.access?.free_or_paid !== undefined &&
      new Date(account?.access?.start_date) <= new Date() &&
      new Date(account?.access?.end_date) >= new Date()
    ) {
      return true;
    }
    return false;
  };

  setVideo = async (video: Video, hasWatchHistory: boolean) => {
    this.video = video;
    const active = this.hasAccess(video);
    this.noFreeMin = false;
    if (!active) {
      this.videoTimeExceeded = false;
      this.setRewatch(false);
      this.videoPause = false;
      if (
        video.access_level !== 'Free' &&
        (video?.access_level === 'No Free Min' || video?.duration <= process.env.REACT_APP_FREE_MIN)
      ) {
        this.noFreeMin = true;
      }
    }
    this.showAd =
      video?.include_bumper &&
      (this.accountStore?.account?.access?.id
        ? this.accountStore?.account?.access?.bumper_ads === true
        : !this.account?.subscription?.is_premium);
    this.videoLoading = false;
    this.watchHistory = {};
    this.updateOrganizationFollow(video?.organization?.follow?.length);
    video?.experts?.map((ex, i) => ex.follow?.length && this.updateExpertsFollow(true, i));
    video?.specialties?.map((sp, i) => sp.follow?.length && this.updateSpecialtiesFollow(true, i));
    this.setWatched(hasWatchHistory);
  };

  setVideoData = (field: any, data: any) => {
    this.video[field] = data;
  };

  setAccessCertificate = () => {
    return this.accountStore.setFeaturePermission('certificate');
  };

  getVideo = async (id: number, cb: () => {}) => {
    this.video = {};
    this.watched = false;
    this.videoLoading = true;
    try {
      const res = await getVideo(id);
      const video = res?.data?.video || null;
      const hasWatchHistory = !!res?.data?.hasWatchHistory;

      if (this.setAccessCertificate() && process.env.REACT_APP_QUIZ_CERTIFICATE === 'true') {
        this.certificateStore.getCertificateQuestions();
        this.certificateStore.getNextQuestion();
      } else {
        this.certificateStore.resetQuestionAndAnswers();
      }

      if (video?.organizationCME) {
        this.cmeStore.getQuestions();
        this.cmeStore.getNextQuestion();
      } else {
        // reset question and answers on new video data
        this.cmeStore.resetQuestionAndAnswers();
      }
      this.hasWatched = res?.data?.hasWatched || false;
      if (video) {
        this.setVideo(video, hasWatchHistory);
      }
      if (cb) cb(video?.title);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onFetchAllSpecialtyVideos = async (id, filter: string) => {
    this.isLoading = true;
    this.selectedSpecialtyFilter = filter;
    this.allSpecialtyVideos = [];
    try {
      const res = await getSpecialtyVideos(
        id,
        this.page,
        this.count || 16,
        filter,
        this.sortOrder || 'title',
        this.sortBy,
      );
      const {videos, count} = res.data;
      this.videoCount = count;
      // const videoExtend = this.page > 1 ? [...this.allSpecialtyVideos, ...videos] : videos;
      this.setAllSpecialtyVideosObservables(videos);
      runInAction(() => {
        this.isLoading = false;

        if (videos.length < this.limit) {
          this.hasMoreVideos = false;
        } else {
          this.hasMoreVideos = true;
        }
        this.name = res?.data?.name;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onSpecialtyScroll = async () => {
    if (!this.isLoading && this.hasMoreVideos) {
      this.onFetchAllSpecialtyVideos(this.specialtyId, this.specialtyPage, 16);
      this.specialtyPage += 1;
    }
  };

  // Get all 360vr videos
  onFetchAll360Videos = async (id, page, limit) => {
    this.isLoading = true;
    try {
      const res = await get360Videos(id, page, limit, this.sortBy, this.selectedSpecialtyIds);
      const {videos} = res.data;
      runInAction(() => {
        this.isLoading = false;
        if (videos.length) {
          const videoExtend = page > 1 ? [...this.all360Videos, ...videos] : videos;
          this.setAll360VideosObservables(videoExtend);
          this.hasMore360Videos = true;
        } else {
          this.hasMore360Videos = false;
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onScroll360 = async () => {
    if (!this.isLoading && this.hasMore360Videos) {
      this.onFetchAll360Videos(this.video360Page, 16, this.selectedSpecialtyIds);
      this.video360Page += 1;
    }
  };

  // fetchItem on hover
  fetchItem = async (type: string, carouselId: number, itemId: number, storeVar: string, brightcove_id: string) => {
    try {
      this.source?.cancel('Operation canceled by nextItem.');
      this.source = CancelToken.source();
      let item = null;
      switch (type) {
        case 'videos':
          item = await getVideo(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.video || null);
          this.updateItem(this[storeVar], item);
          break;
        case 'userVideo': {
          item = await getUserUploadedVideo(brightcove_id);
          const index = this.userVideos.findIndex(ele => ele.id === item?.data.id);
          runInAction(() => {
            const video = this.userVideos;
            const img = new Image();
            img.onload = function load() {
              video[index] = item.data;
            };
            img.onerror = function error() {
              video[index] = {...item.data, thumbnail: video[index].thumbnail};
            };
            img.src = item.data?.thumbnail;
            this.userVideos = video;
          });
          break;
        }
        default:
          break;
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // hover update
  updateItem = (itemList: any[], item: any) => {
    const itemIndex = itemList.findIndex(listItem => +listItem?.id === +item?.id);
    if (itemIndex !== -1) {
      /* eslint-disable no-param-reassign */
      itemList[itemIndex] = {
        isExtraDataLoaded: true,
        ...item,
      };
    }
    this.allSpecialtyVideos = [...this.allSpecialtyVideos];
  };

  updateOrganizationFollow = (follow: boolean) => {
    if (this.video?.organization) this.video.organization.is_following = follow;
  };

  /** Follow organization */
  followOrganization = async (id: number) => {
    try {
      const resp = await followItem(id, 'organization');
      const {status} = resp.data;
      if (status) {
        this.mediaStore.updateCarouselFollow(id, true, 'organization');
        this.updateOrganizationFollow(true);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow organization */
  unFollowOrganization = async (id: number) => {
    try {
      const resp = await unfollowItem(id, 'organization');
      const {status} = resp.data;

      if (status) {
        this.mediaStore.updateCarouselFollow(id, false, 'organization');
        this.updateOrganizationFollow(false);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updateExpertsFollow = (follow: boolean, index: number) => {
    this.video.experts[index].is_following = follow;
  };

  updateSpecialtiesFollow = (follow: boolean, index: number) => {
    if (this.video) this.video.specialties[index].is_following = follow;
  };

  /** Follow specialty */
  followSpecialty = async (id: number) => {
    try {
      const resp = await followItem(id, 'specialty');
      const {status} = resp.data;
      if (status) {
        const index = this.video.specialties.findIndex(e => e.id === id);
        this.updateSpecialtiesFollow(true, index);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow specialty */
  unFollowSpecialty = async (id: number) => {
    try {
      const resp = await unfollowItem(id, 'specialty');
      const {status} = resp.data;

      if (status) {
        const index = this.video.specialties.findIndex(e => e.id === id);
        this.updateSpecialtiesFollow(false, index);
      }
    } catch (error) {}
  };

  fetchVideoNotes = async (id: number) => {
    try {
      const res = await getVideoNotes(id);
      if (res.status) {
        const notes = res?.data?.notes || null;
        runInAction(() => {
          this.videoNotes = notes;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  fetchAllNotes = async () => {
    try {
      const res = await getAllNotes(
        this.noteSortBy,
        this.page,
        this.count,
        this.selectedSpecialtyIds,
        this.selectedOrganizationIds,
      );
      if (res.status) {
        const notes = res?.data?.notes?.rows || null;
        runInAction(() => {
          this.notes = notes;
          this.notesCount = res?.data?.notes?.count;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  saveNote = async (id: number, note: string, videoTimeCode: string) => {
    this.videoNoteSaving = true;
    try {
      const res = await addVideoNote(id, note, videoTimeCode);
      if (res.status) {
        const videoNote = res?.data?.video_note || null;
        runInAction(() => {
          this.videoNoteSaving = false;
          this.videoNotes.push(videoNote);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  editNote = async (id: number, note: string) => {
    const notes = [...this.videoNotes];
    try {
      await editVideoNote(id, note);
      const index = notes.findIndex(vNote => vNote.id === id);
      notes[index].note = note;
      runInAction(() => {
        this.videoNotes = notes;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deleteNote = async (id: number) => {
    const notes = [...this.videoNotes];
    try {
      const resp = await deleteVideoNote(id);
      const index = notes.findIndex(vNote => vNote.id === id);
      notes.splice(index, 1);
      runInAction(() => {
        this.videoNotes = notes;
        const {status} = resp.data;
        if (status) {
          this.notes = this.notes.filter(item => item?.id !== id);
          this.notesCount -= 1;
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getAllPlaylists = async () => {
    try {
      const res = await getAllPlaylists();
      if (res.status) {
        const playlist = res?.data?.playlist || null;
        this.playlist = playlist;
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addToPlaylist = async (videoId: number, name: string, videoPage: boolean) => {
    try {
      const res = await addToPlaylist(videoId, name);
      const {status} = res.data;
      if (status) {
        const {playlist} = res.data;
        if (!this.playlist.find(el => el.id === playlist.id)) this.playlist = [...this.playlist, playlist];
        if (videoPage && videoId) {
          this.setVideoData('playlist', [...this.video.playlist, playlist]);
        } else if (!videoPage) {
          const updatevideoPlaylist = [...this.videotilePlaylist, playlist];
          this.setVideoTilePlaylist(updatevideoPlaylist);
          this.updateVideoListPlaylist(videoId, updatevideoPlaylist);
        }
        this.alertStore.setMessage('Playlist created successfully', 'success');
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updatePlaylist = async (videoId: number, data: [{}], videoPage: boolean) => {
    try {
      const res = await massUpdatePlaylists(videoId, data);
      const {status} = res.data;
      if (status) {
        const {playlist} = res.data;
        if (videoPage && videoId) {
          this.setVideoData('playlist', playlist);
        } else {
          this.setVideoTilePlaylist(playlist);
          this.updateVideoListPlaylist(videoId, playlist);
        }
        this.alertStore.setMessage('Playlist updated successfully', 'success');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  removeStaleVideoPlayers = () => {
    // Remove all previously loaded players.
    // If the id of a player in the DOM does not match the id on the
    // current videoPlayer ref, then the player is removed.
    const players = window.videojs?.getAllPlayers() || [];
    if (players?.length && this.videoPlayer) {
      for (let i = 0; i < players?.length; i += 1) {
        if (players[i]?.id_ !== undefined && players[i].id_ !== this.videoPlayer?.id_) {
          players[i].dispose();
        }
      }
    }
  };

  setVideoPlayer = async (ref: any, videoId: string, bumper = false) => {
    try {
      const loader = await brightcovePlayerLoader({
        refNode: ref.current,
        accountId: process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID,
        playerId: process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID,
        videoId,
        options: {
          aspectRatio: '16:9',
          maxWidth: '100%',
          manualCleanup: true,
        },
      });
      runInAction(() => {
        this.videoPlayer = loader?.ref;
        this.videoPlayer.play();
        // Push the removal of stale video players into the event queue so that any changes
        // made to the DOM or in the third-party brightcove scripts have finished first.
        if (!this.videoTimeExceeded) setTimeout(this.removeStaleVideoPlayers, 0);
      });
      if (!bumper) {
        this.listenPlayer();
      } else {
        this.videoPlayer.controlBar.progressControl.disable();
        this.videoPlaying = true;
        // this.bumperEnded = false;
        this.listenBumperPlayer();
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setBumperEnded = value => {
    this.bumperEnded = value;
  };

  setShowBumperSkip = value => {
    this.showBumperSkip = value;
  };

  listenBumperPlayer = () => {
    this.videoPlayer?.on('play', () => {
      setInterval(() => {
        if (Math.floor(this.videoPlayer?.currentTime()) >= 5) {
          this.showBumperSkip = true;
        }
        if (this.bumperCounter > 0) {
          this.setBumperCounter(this.bumperCounter - 1);
        }
      }, 1000);
    });
    this.videoPlayer?.on('ended', () => {
      this.bumperEnded = true;
    });
  };

  listenPlayer = () => {
    this.videoPlaying = !this.videoPlaying;
    this.watchHistory.video_id = this.video.id;
    this.watchHistory.source = this.referrer;
    let interval;
    let currentTime;

    this.videoPlayer?.on('audience:ready', () => {
      const {account} = this.accountStore;
      // Track user id on audience
      if (account?.id) {
        this.videoPlayer?.audience?.track('event2', String(account?.id));
      }
      // Track user IP on audience
      getRemoteIP().then(({data: ip}) => {
        this.videoPlayer?.audience?.track('event1', ip);
      });
    });
    const active = this.hasAccess(this.video);

    this.videoPlayer?.on('firstplay', () => {
      if (this.cmeStore?.cmeDetails) {
        this.cmeStore?.startCMEVideo(this.video?.organizationCME?.id);
      }
      if (this.certificateStore?.certificateDetails && process.env.REACT_APP_QUIZ_CERTIFICATE === 'true') {
        this.certificateStore?.startCertificateVideo(this.video.id);
      }

      if (!this.watched) {
        this.setWatched(true);
      }
      currentTime = Math.floor(this.videoPlayer?.currentTime()) || 0;
      this.watchHistory.start_point = currentTime;
      if (this.rewatch) {
        this.setRewatch(false);
      }
    });

    this.videoPlayer?.on('play', () => {
      interval = setInterval(() => {
        if (this.rewatch) {
          this.setRewatch(false);
        }
        currentTime = Math.floor(this.videoPlayer?.currentTime()) || 0;
        this.watchHistory.time_watched = currentTime;
        this.watchHistory.end_point = currentTime;
        this.videoTimeExceeded = false;
        if (!this.videoPlayer.seeking()) this.updateWatchHistory(this.watchHistory);
        if (!active && this.video?.access_level !== 'Free' && currentTime >= process.env.REACT_APP_FREE_MIN) {
          this.videoTimeExceeded = true;
          this.videoPlayer?.currentTime(0);
          this.video.lastWatchPoint = null;
          this.videoPlayer?.pause();
        }
      }, 10000);
    });
    this.videoPlayer?.on('pause', () => {
      clearInterval(interval);
      setTimeout(() => {
        if (!active && !this.videoSeek && this.video?.access_level !== 'Free') {
          this.videoPause = true;
          if (this.videoPlayer.isFullscreen()) {
            this.videoPlayer.exitFullscreen();
          }
        }
      }, 100);
    });
    this.videoPlayer?.on('seeking', () => {
      this.videoSeek = true;
      if (!active && this.videoSeek) {
        setTimeout(() => {
          this.videoSeek = false;
        }, 1000);
      }
    });
    this.videoPlayer?.on('seeked', () => {
      this.videoSeek = true;
      currentTime = Math.floor(this.videoPlayer?.currentTime());
      if (currentTime != 0) {
        this.videoTimeExceeded = false;
      } else if (currentTime === 0) {
        this.setRewatch(false);
      }
      if (!active && this.video?.access_level !== 'Free' && currentTime >= process.env.REACT_APP_FREE_MIN) {
        this.videoPlayer?.currentTime(0);
        this.video.lastWatchPoint = null;
        this.videoPlayer?.pause();
        if (!this.rewatch) {
          this.videoTimeExceeded = true;
        }
      } else {
        this.watchHistory = {};
        this.watchHistory.video_id = this.video.id;
        this.watchHistory.source = this.referrer;
        this.watchHistory.time_watched = currentTime;
        this.watchHistory.start_point = currentTime;
      }
      if (!active && this.videoSeek) {
        setTimeout(() => {
          this.videoSeek = false;
        }, 1000);
      }
    });
    this.videoPlayer?.on('ended', () => {
      currentTime = Math.floor(this.videoPlayer?.currentTime()) || 0;
      this.watchHistory.time_watched = currentTime;
      this.watchHistory.end_point = currentTime;
      this.updateWatchHistory(this.watchHistory);
    });
  };

  updateWatchHistory = async (history: WatchHistory) => {
    if (!history?.video_id) {
      return;
    }

    try {
      const res = await updateWatchHistory(history);
      const {status, watchHistory, metrics} = res.data;
      if (status) {
        runInAction(() => {
          this.watchHistory = watchHistory;
          this.setNewWatches(true);
          if (metrics) {
            const totalTimeWatched = metrics.watch_history_total;
            this.accountStore.stats.total_time_watched = +((totalTimeWatched || 0) / 3600).toFixed(1);
            this.accountStore.stats.videos_watched = metrics.watch_history_count;
          }
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updateThumbsUp = async (videoId: number, type: string) => {
    try {
      const res = await updateThumbsUp(videoId, type);
      const {status, like} = res.data;
      if (status && like)
        this.setVideoData('like', {
          id: like.id,
          thumbs_up_down: like.thumbs_up_down,
        });
      else this.setVideoData('like', null);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  toggleVideoBookmark = async (bookmarkStatus, videoId = this.video?.id) => {
    try {
      if (!bookmarkStatus) {
        const res = await createBookmark(videoId, 'video');
        const {status, bookmark} = res.data;
        if (status) this.setVideoData('bookmark', {id: bookmark?.id});
      } else {
        const res = await deleteBookmark(videoId, 'video');
        const {status} = res.data;
        if (status) this.setVideoData('bookmark', null);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  toggleVideoTileBookmark = async (item, bookmarkStatus) => {
    try {
      if (!bookmarkStatus) {
        const res = await createBookmark(item?.id, 'video');
        const {status, bookmark} = res.data;
        if (status) {
          this.setVideoData('bookmark', {id: bookmark?.id});
          runInAction(() => {
            this.videoTileBookmark = bookmark?.id;
          });
        }
      } else {
        const res = await deleteBookmark(item?.id, 'video');
        const {status} = res.data;
        if (status) {
          this.setVideoData('bookmark', null);
          runInAction(() => {
            this.videoTileBookmark = 0;
          });
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  fetchUserUploadVideos = async () => {
    try {
      const res = await getUserUploadVideos();
      if (res.status) {
        const userVideos = res?.data?.userVideos || null;
        runInAction(() => {
          this.userVideos = userVideos;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  videoUpload = async (formData, successCB) => {
    try {
      const response = await uploadVideo(formData);
      if (response.status) {
        const userVideo = response?.data || null;
        runInAction(() => {
          this.uploadedVideo = userVideo;
          successCB(userVideo);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getS3urlForVideo = async (name, putOrGet, successCB) => {
    try {
      const res = await getSignedS3Url(name, putOrGet);
      if (res.status) {
        const url = res.data || null;
        runInAction(() => {
          successCB(url);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  uploadVideoURL = async (data, successCB, errorCB) => {
    try {
      const response = await uploadVideoWithS3(data);
      if (response.status) {
        runInAction(() => {
          successCB(response.data);
        });
      }
    } catch (error) {
      errorCB(error);
      this.errorStore.setError(error);
    }
  };

  fetchAllUserVideos = async () => {
    try {
      const res = await getAllUsersVideos();
      if (res.status) {
        const allVideos = res?.data?.allVideos || null;
        runInAction(() => {
          this.allUsersVideos = allVideos;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  videoModeration = async (id, data, successCB) => {
    try {
      const res = await moderateVideo(id, data);
      const {updatedVideo} = res.data;
      if (updatedVideo) {
        runInAction(() => {
          const index = this.allUsersVideos.findIndex(element => element.id === updatedVideo.id);
          this.allUsersVideos[index] = updatedVideo;
          this.video = updatedVideo;
          successCB();
          this.alertStore.setMessage('Video Moderated successfully', 'success');
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deleteVideo = async (id, successCB) => {
    try {
      const response = await deleteUserVideo(id);
      const deletedVideo = response?.data;
      if (deletedVideo) {
        runInAction(() => {
          successCB(deletedVideo);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  videoPublishOrNot = async (id, isPublish, successCB) => {
    try {
      const res = await publishVideo(id, isPublish);
      const updatedVideo = res.data.video;
      if (updatedVideo) {
        runInAction(() => {
          const index = this.allUsersVideos.findIndex(element => element.id === updatedVideo.id);
          this.allUsersVideos[index] = updatedVideo;
          this.video = updatedVideo;
          successCB(updatedVideo);
          this.alertStore.setMessage('Video Updated successfully', 'success');
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default VideoStore;
