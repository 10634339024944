import React from 'react';
import {Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import ErrorModal from './ErrorModal';
import {useStore} from '../../store/index';

const ErrorHandler = observer(() => {
  const {errorStore} = useStore();
  const error = errorStore?.apiError || errorStore?.unhandledError;

  const resetError = () => errorStore.resetError();

  return (
    <ErrorModal show={error} onHide={resetError} centered>
      <ErrorModal.Body>
        <div className="d-flex flex-column">
          <h5 className="modal-title-text mb-2">There was an error</h5>
          <p className="modal-text mb-3">{error?.message}</p>
          <Button
            type="submit"
            variant="primary"
            data-testid="tbdModalClose"
            id="close-tbd-modal"
            className="a_closeTbdModal py-1 ml-auto mb-1"
            onClick={resetError}
          >
            Close
          </Button>
          {error?.statusCode ? <p className="modal-small-text m-0">Network error code:{error?.statusCode}</p> : null}
        </div>
      </ErrorModal.Body>
    </ErrorModal>
  );
});

export default ErrorHandler;
