// @flow
import React, {useState, useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import {Button, OverlayTrigger, Spinner} from 'react-bootstrap';
import './style.css';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../store';
import Thumbnail from '../../pages/Courses/elements/video-thumbnail.png';
import CMEIcon from '../../assets/icons/icon_CME.svg';
import stringToURLSlug from '../../utils/helper';
import {CardWrapper, Badge, Title, Text, PopoverDiv, Description} from './style';
import CoursePlaylistPopover from '../CoursePlaylistPopover';
import LocalStorageService from '../../utils/LocalStorageService';
import TileOverlay from '../TileOverlay';
import UpgradeModal from '../UpgradeModal';
import {amplitude} from '../../utils/Amplitude';
import {ActiveSmallBtn, DefaultSmallBtn} from '../FollowButtons';

const POPOVER_WIDTH = 386;
const POPOVER_HEIGHT = 420;

type Props = {
  item: {
    id: number,
    title: string,
    description: string,
    thumbnail: string,
    number_of_videos: number,
    active: boolean,
    is_following: boolean,
    is_bookmark: boolean,
    is_cme: boolean,
    credit_count: number,
    thumbnail_url: String,
    videos: [{id: number}],
    bookmark: [
      {
        id: number,
      },
    ],
    organization: {
      name: string,
    },
    specialty: [],
    isExtraDataLoaded: boolean,
  },
  playlist: {
    id: number,
    name: string,
  },
  type: number,
  onFollow: Function,
  onBookmark: Function,
  storeName: string,
  playlist: {
    id: number,
    name: string,
  },
};
// ConferenceTile component
const ConferenceTile = observer(({item, type, onFollow, storeName, onBookmark, playlist}: Props) => {
  const triggerRef = useRef(null);
  const location = useHistory();
  const {conferenceLandingStore, conferenceStore} = useStore();
  const store = useStore();
  const {fetchItem} = store[storeName];
  const {account} = store.accountStore;
  const [popoverOpen, togglePopover] = useState(false);
  const [target, setTarget] = useState(null);
  const timer = useRef(null);
  const ref = useRef(null);
  const {onCreateBookmark, onDeleteBookmark, onFollowConference, onUnfollowConference} = conferenceStore;
  const {addConferenceToNewPlaylist, addConferenceToPlaylist} = conferenceLandingStore;
  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const [playlistUpdating, setUpdating] = useState(false);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  /* istanbul ignore next */
  const onHover = async event => {
    setTarget(event.currentTarget);
    if (window.outerWidth > 890) {
      timer.current = setTimeout(() => {
        togglePopover(true);
      }, 500);
    }
    if (!item?.isExtraDataLoaded) {
      await fetchItem('conferences', type, item?.id);
    }
  };

  const loggedInUser = LocalStorageService.getUser();

  /* istanbul ignore next */
  const onHoverLeave = () => {
    togglePopover(false);
    clearTimeout(timer.current);
  };

  const bookmarkConference = selectedItem => {
    const user = localStorage.getItem('user');
    if (user) {
      if (selectedItem.bookmark.length) {
        onDeleteBookmark(selectedItem, type);
      } else {
        onCreateBookmark(selectedItem, type);
      }
    } else {
      location.push('/login');
    }
  };

  const follow = selectedItem => {
    const user = localStorage.getItem('user');
    if (user) {
      if (selectedItem.is_following) {
        onUnfollowConference(selectedItem, type);
        const eventProperties = {
          type: 'conference',
          source: 'conference tile',
          name: `${selectedItem.title}`,
        };
        amplitude.getInstance().logEvent('unfollow', eventProperties);
      } else {
        onFollowConference(selectedItem, type);
        const eventProperties = {
          type: 'conference',
          source: 'conference tile',
          name: `${selectedItem.title}`,
        };
        amplitude.getInstance().logEvent('follow', eventProperties);
      }
    } else {
      location.push('/login');
    }
  };

  const createAndAddToPlaylist = async data => {
    setUpdating(true);
    await addConferenceToNewPlaylist(item.id, data.listname);
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    await addConferenceToPlaylist(item.id, data);
    setUpdating(false);
  };

  const gotoConferenceDetail = id => {
    const slug = stringToURLSlug(item.title);
    location.push(`/conferences/${id}/${slug}`);
  };
  const getSpecialtiesStr = () => item?.specialty?.map(s => s.name).join(', ');

  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <CardWrapper onMouseLeave={onHoverLeave}>
        <Card
          className="mx-2 my-2 hover-card border-0"
          onMouseEnter={onHover}
          onClick={() => gotoConferenceDetail(item.id)}
          ref={triggerRef}
        >
          <Card.Img src={item.thumbnail_url || Thumbnail} alt={item.title} />
          <Card.ImgOverlay>
            <Badge>
              {item.is_cme === true && (
                <span className="fa-layers">
                  <img src={CMEIcon} className="icon-cme" alt="cme" />
                </span>
              )}
            </Badge>
          </Card.ImgOverlay>
          <Card.Body>
            <Text>{getSpecialtiesStr()}</Text>
            <Title className="my-2">{item.title}</Title>
            {item.number_of_videos && (
              <Description className="mt-auto mb-1">{`${item.number_of_videos} videos`}</Description>
            )}
          </Card.Body>
        </Card>
        <TileOverlay
          popoverOpen={popoverOpen}
          target={target}
          container={ref.current}
          containerPadding={20}
          placement="top-end"
          popoverClassName="conference-popover"
          popoverHeight={POPOVER_HEIGHT}
          popoverWidth={POPOVER_WIDTH}
          triggerRef={triggerRef}
        >
          <PopoverDiv width={POPOVER_WIDTH} height={POPOVER_HEIGHT}>
            <Card className="popover-card">
              <Card.Img
                src={item.thumbnail_url || Thumbnail}
                alt={item.title}
                onClick={() => gotoConferenceDetail(item.id)}
              />
              <Card.Body>
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex align-items-center popover-top">
                    {account?.subscription?.name?.includes('Basic') ? (
                      <Button
                        variant="basic"
                        className="px-0 py-0 a_bookmark"
                        onClick={() => setShowBookmarkUpgradeModal(true)}
                      >
                        <i className="far fa-bookmark" />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="basic"
                          className={`a_bookmark ${item.bookmark?.length ? 'd-none' : ''}`}
                          onClick={() => (onBookmark ? onBookmark(item) : bookmarkConference(item))}
                        >
                          <i className="far fa-bookmark" />
                        </Button>
                        <Button
                          variant="basic"
                          className={`a_bookmark ${item.bookmark?.length ? 'bookmark-fill' : 'd-none'}`}
                          onClick={() => (onBookmark ? onBookmark(item) : bookmarkConference(item))}
                        >
                          <i className="fas fa-bookmark" />
                        </Button>
                      </>
                    )}
                    {loggedInUser ? (
                      [
                        !playlistUpdating ? (
                          <OverlayTrigger
                            trigger="click"
                            placement="auto-start"
                            overlay={props => (
                              <CoursePlaylistPopover
                                props={props}
                                allPlaylist={playlist}
                                courseVideos={item.videos}
                                onNew={createAndAddToPlaylist}
                                onExisting={updateExistingPlaylist}
                                userState={userState}
                                showUpgradeModal={isShowPlaylistUpgradeModal}
                                onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                              />
                            )}
                            rootClose
                          >
                            <Button
                              variant="basic"
                              className="text-white"
                              onClick={() => setShowPlaylistUpgradeModal(true)}
                            >
                              <i className="fak fa-list-ul" />
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <div className="px-3">
                            <Spinner animation="border" size="sm" variant="primary" />
                          </div>
                        ),
                      ]
                    ) : (
                      <Button
                        variant="basic"
                        className="text-white pl-0 py-0 px-3"
                        onClick={() => location.push('/login')}
                      >
                        <i className="fak fa-list-ul" />
                      </Button>
                    )}
                    <div className="py-1">
                      <div className="py-1">
                        {account?.subscription?.name?.includes('Basic') ? (
                          <DefaultSmallBtn className="a_follow" onClick={() => setShowFollowUpgradeModal(true)}>
                            <i className="fas fa-plus mr-2" />
                            Follow
                          </DefaultSmallBtn>
                        ) : null}
                        {!account?.subscription?.name?.includes('Basic') && item.is_following ? (
                          <ActiveSmallBtn
                            className="a_followed"
                            onClick={() => (onFollow ? onFollow(item) : follow(item))}
                          >
                            <span className="following">
                              <i className="fas fa-check mr-2" />
                              Following
                            </span>
                            <span className="unfollow">
                              <i className="fas fa-times mr-2" />
                              Unfollow
                            </span>
                          </ActiveSmallBtn>
                        ) : (
                          !account?.subscription?.name?.includes('Basic') && (
                            <DefaultSmallBtn
                              className="a_follow"
                              onClick={() => (onFollow ? onFollow(item) : follow(item))}
                            >
                              <i className="fas fa-plus mr-2" />
                              Follow
                            </DefaultSmallBtn>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    {item.is_cme === true && <img src={CMEIcon} className="icon-cme mr-2" alt="cme" />}
                    {item.is_cme === true && <div className="credit">{item.credit_count} Credits</div>}
                  </div>
                </div>
                <Text>{getSpecialtiesStr()}</Text>
                <Title onClick={() => gotoConferenceDetail(item.id)}>{item.title}</Title>
                <Description>{item?.organization?.name}</Description>
                <div className="d-flex align-items-center" />
              </Card.Body>
            </Card>
          </PopoverDiv>
        </TileOverlay>
      </CardWrapper>
    </>
  );
});
export default ConferenceTile;
