// @flow
import React, {useState, useRef} from 'react';
import {Overlay, Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {StyledDiv, Badge, SearchBar} from './mobileSearch';

type Props = {
  search: Function,
  keyupHandler: Function,
  searchButtonClick: Function,
};

const MobileSearch = observer(({search, keyupHandler, searchButtonClick}: Props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Badge
        variant="link"
        id="open-mobile-search"
        data-testid="open-mobile-search"
        className="a_openMobileSearchButton mr-2"
        data-tut="hometutorial__search_mobile"
        ref={target}
        onClick={() => setShow(!show)}
      >
        <i className="far fa-search" />
      </Badge>
      <Overlay target={target.current} show={show} placement="left">
        {({placement, arrowProps, show: _show, popper, ...props}) => (
          <StyledDiv {...props}>
            <div className="row">
              <SearchBar>
                <div className="input-group form-inline">
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-secondary search-button"
                      type="button"
                      onClick={searchButtonClick}
                    >
                      <i className="far fa-search" />
                    </button>
                  </div>
                  <input
                    type="search"
                    id="search"
                    data-testid="search-input"
                    className="form-control"
                    placeholder="Search topics, proceedures, experts ..."
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    onKeyUp={keyupHandler}
                    onChange={search}
                  />
                </div>
              </SearchBar>
              <Button className="close" aria-label="Close" data-testid="close-panel" onClick={() => setShow(!show)}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </div>
          </StyledDiv>
        )}
      </Overlay>
    </>
  );
});

export default MobileSearch;
