import React, {useState, useEffect} from 'react';
import {Nav, Container} from 'react-bootstrap';
import LocalStorageService from '../../../../utils/LocalStorageService';
import {NavItem, Link, Tab} from '../../elements/styles';
import {adminRoutes, orgMgrRoutes, instnMgrRoutes} from '../../routes';

const AdminTab = () => {
  const [routes, setRoutes] = useState([]);
  const user = LocalStorageService.getUser();

  useEffect(() => {
    switch (user?.role) {
      case 'admin':
        setRoutes(adminRoutes);
        break;
      case 'organization_manager':
        setRoutes(orgMgrRoutes);
        break;
      case 'institution_manager':
        setRoutes(instnMgrRoutes);
        break;
      default:
        setRoutes([]);
        break;
    }
  }, [user]);
  return (
    <Tab>
      <Container>
        <Nav>
          {routes.map(route => (
            <NavItem key={`${route.path}-link`} className="nav-item">
              <Link className="nav-link" activeClassName="active" to={`/admin/${route.path}`}>
                {route.title}
              </Link>
            </NavItem>
          ))}
        </Nav>
      </Container>
    </Tab>
  );
};

export default AdminTab;
