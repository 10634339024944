import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form, Dropdown} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import certificateIcon from '../../../assets/icons/graduation-cap-sharp-solid.svg';

import {Logo, NoCertificate, SortSelect, Label, DateFilter} from '../elements/style';
import {PaginationContainer} from '../../Bookmarks/elements/styles';
import ScreenLoader from '../../../components/ScreenLoader';
import {useStore} from '../../../store';
// import TBDButton from '../../../components/TBDButton';
// import ErrorModal from './ErrorModal';
import CertificateCompletedCards from './CompletedCards';

const CertificateCompleted = observer(() => {
  const [canDownload, setCanDownload] = useState(true);
  const {accountStore, certificateStore} = useStore();
  const {account} = accountStore;
  const {
    certificateCompleted,
    certificateCompletedLoading,
    completedSortBy,
    changeCompletedSort,
    completedStartDate,
    completedEndDate,
    changeStartDate,
    changeEndDate,
    completedPage,
    completedRecordCount,
    completedTotalCount,
    changeCompletedRecordCount,
    completedPageIncrement,
    completedPageDecrement,
    scrollCompleted,
    resetDownloadMessage,
  } = certificateStore;

  // const [dowloadLoader, setDowloadLoader] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  // const [isTrialUser, setIsTrialUser] = useState(false);
  useEffect(() => {
    return () => resetDownloadMessage();
  }, [resetDownloadMessage]);

  useEffect(() => {
    if (account?.subscription?.status === 'trialing' && !account.access) setCanDownload(false);
    else setCanDownload(true);
  }, [account]);

  const onStartDateChange = date => {
    changeStartDate(date);
  };

  const onEndDateChange = date => {
    changeEndDate(date);
  };

  const resizeWindow = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    if (windowSize <= 768) {
      window.addEventListener('scroll', e => {
        const {scrollTop, clientHeight, scrollHeight} = e.target.scrollingElement;
        if (Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight)) {
          scrollCompleted();
        }
      });
    }
  }, [scrollCompleted, windowSize]);

  const renderCompletedList = () => {
    return (
      <>
        {certificateCompleted && certificateCompleted.length
          ? certificateCompleted.map(item => (
              <Label htmlFor={item?.id} className="checkLabel" key={item?.id}>
                <CertificateCompletedCards item={item} key={item?.id} isDownload={canDownload} />
              </Label>
            ))
          : !certificateCompletedLoading && (
              <div className="text-center my-5 mx-auto">
                <Logo>
                  <img src={certificateIcon} className="a_cmeIcon" alt="cme-icon" />
                </Logo>
                <NoCertificate>No Certificate’s are Completed</NoCertificate>
              </div>
            )}
      </>
    );
  };
  return (
    <div className="px-3 container">
      <div className="d-flex flex-wrap justify-content-space-between">
        <div className="d-flex ml-auto picker-select">
          <div>
            <DateFilter>
              <Label>Filter</Label>
              <DatePicker
                selected={completedStartDate}
                className="rounded-pill mx-sm-2"
                name="start_date"
                dateFormat="MM/dd/yyyy"
                placeholderText="mm/dd/yyyy"
                onChange={date => onStartDateChange(date)}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
              />
              <DatePicker
                selected={completedEndDate}
                className="rounded-pill mx-sm-2"
                name="end_date"
                dateFormat="MM/dd/yyyy"
                placeholderText="mm/dd/yyyy"
                onChange={date => onEndDateChange(date)}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
              />
            </DateFilter>
          </div>
          <div className="d-flex">
            <Label>Sort</Label>
            <SortSelect
              className="ml-sm-3 completed-sort"
              value={completedSortBy}
              onChange={e => changeCompletedSort(e.target.value)}
            >
              <option value="DESC">Newest</option>
              <option value="ASC">Oldest</option>
            </SortSelect>
          </div>
        </div>
      </div>
      <PaginationContainer className="pt-3 mx-3 pagination-div">
        <span>
          {completedRecordCount === 0 ? (
            <>0 </>
          ) : (
            <>{completedPage * completedRecordCount - (completedRecordCount - 1)} </>
          )}
          -
          {completedPage * completedRecordCount > completedTotalCount ? (
            <>{completedTotalCount} </>
          ) : (
            <>{completedPage * completedRecordCount} </>
          )}
          of {completedTotalCount}
        </span>
        <Button
          onClick={completedPageDecrement}
          variant="basic"
          className="a_p_dec"
          disabled={completedPage * completedRecordCount - (completedRecordCount - 1) === 1}
        >
          <i className="far fa-angle-left" />
        </Button>
        <Button
          onClick={completedPageIncrement}
          variant="basic"
          className="a_p_inc"
          disabled={completedPage * completedRecordCount >= completedTotalCount}
        >
          <i className="far fa-angle-right" />
        </Button>
        <Dropdown>
          <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
            <i className="fas fa-ellipsis-v" />
          </Dropdown.Toggle>
          <Dropdown.Menu onChange={e => changeCompletedRecordCount(e.target.value)}>
            <Form.Check
              type="radio"
              label="10 per page"
              id="limit_10"
              value="10"
              name="count"
              checked={parseInt(completedRecordCount, 10) === 10}
            />
            <Form.Check
              type="radio"
              label="25 per page"
              id="limit_25"
              value="25"
              name="count"
              checked={parseInt(completedRecordCount, 10) === 25}
            />
            <Form.Check
              type="radio"
              label="50 per page"
              id="limit_50"
              value="50"
              name="count"
              checked={parseInt(completedRecordCount, 10) === 50}
            />
            <Form.Check
              type="radio"
              label="100 per page"
              id="limit_100"
              value="100"
              name="count"
              checked={parseInt(completedRecordCount, 10) === 100}
            />
          </Dropdown.Menu>
        </Dropdown>
      </PaginationContainer>
      {!certificateCompletedLoading || windowSize <= 768 ? (
        renderCompletedList()
      ) : (
        <ScreenLoader containerHeight="calc(50vh)" />
      )}
      {certificateCompletedLoading && windowSize <= 768 && <ScreenLoader containerHeight="calc(50px)" />}
    </div>
  );
});

export default CertificateCompleted;
