import React from 'react';
import {observer} from 'mobx-react-lite';
import {Container, Form} from 'react-bootstrap';
import {SortSelect, SortLabel} from '../elements/cme';
import RevokedCard from './RevokedCard';
import {useStore} from '../../../store';
import ScreenLoader from '../../../components/ScreenLoader';

const RevokedCME = observer(() => {
  const {cmeStore} = useStore();

  const {cmeRevoked, revokedSortBy, changeRevokedSort, cmeRevokedLoading} = cmeStore;

  return (
    <>
      <Container>
        <div className="d-flex flex-row-reverse">
          <Form>
            <Form.Group controlId="sort">
              <div className="text-right">
                <SortLabel className="mr-2">Sort</SortLabel>
                <SortSelect value={revokedSortBy} onChange={e => changeRevokedSort(e.target.value)}>
                  <option value="DESC">Newest</option>
                  <option value="ASC">Oldest</option>
                </SortSelect>
              </div>
            </Form.Group>
          </Form>
        </div>
        {!cmeRevokedLoading ? (
          cmeRevoked.map(item => <RevokedCard item={item} />)
        ) : (
          <ScreenLoader containerHeight="calc(50vh)" />
        )}
      </Container>
    </>
  );
});

export default RevokedCME;
