import React from 'react';

const AnswerSection = storedValues => {
  return (
    <>
      <div className="answer-container">
        {storedValues?.storedValues?.map(value => {
          return (
            <div className="answer-section">
              <p className="question">
                <span>You: </span>
                {value?.question}
              </p>
              <p className="answer">
                <span>Chat GPT: </span>
                {value?.answer}
              </p>
              <div className="copy-icon">
                <i className="fa-solid fa-copy" />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AnswerSection;
