// @flow
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Nav} from 'react-bootstrap';
import stringToURLSlug from '../../../utils/helper';
import {amplitude} from '../../../utils/Amplitude';

type Props = {
  org: {
    id: number,
    name: string,
    thumbnail: string,
    videoCount: number,
    is_following: boolean,
    isExtraDataLoaded: boolean,
  },
  sponsored: Boolean,
};

const OrganizationList = ({org, sponsored}: Props) => {
  const location = useHistory();

  const organizationDetail = id => {
    const slug = stringToURLSlug(org.name);
    if (sponsored) {
      location.push(`/sponsored/${id}/${slug}`);
    } else {
      location.push(`/organization/${id}/${slug}`);
    }
    amplitude.getInstance().logEvent('browse-clicked');
  };

  return (
    <div>
      <div className="a_orgBrowseList">
        <Nav.Link
          className="a_orgBrowseItem"
          onClick={() => organizationDetail(org.id)}
          onKeyDown={() => organizationDetail(org.id)}
        >
          {org.name}
        </Nav.Link>
      </div>
    </div>
  );
};

export default OrganizationList;
