import styled from 'styled-components';

export const CardWrapper = styled.div`
  height: 100%;
  & .card-img-overlay {
    padding: 8px 6px;

    @media screen and (max-width: 576px) {
      padding: 4px 3px;
    }
  }

  & .card-img {
    @media screen and (max-width: 576px) {
      height: 96px;
      object-fit: cover;
    }
  }

  & .card-body {
    padding: 8px 1.25rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;

    @media screen and (max-width: 576px) {
      height: 90px;
      padding: 6px;
    }
  }

  & .hover-card {
    overflow: hidden;
    height: calc(100% - 1rem);
    cursor: pointer;
    background-color: ${({theme}) => theme.card_bg};
    box-shadow: ${({theme}) => theme.card_boxshadow};
  }
`;

export const Badge = styled.div`
  display: flex;

  & img {
    @media screen and (max-width: 576px) {
      height: 17px;
    }
  }
  .icon-cme {
    width: 32px;
  }
  & .fa-layers {
    // top: 4px;
    width: 32px;

    @media screen and (max-width: 576px) {
      top: 0;
      width: 17px;
    }
  }

  & .fa-certificate {
    color: #35cb95;
    height: 32px;
    width: 32px;

    @media screen and (max-width: 576px) {
      height: 17px;
      width: 17px;
    }
  }

  & .fa-diploma {
    font-size: 16px;

    @media screen and (max-width: 576px) {
      font-size: 9px;
    }
  }
`;

export const BadgeNew = styled.div`
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #e04463;
  margin: auto 8px;

  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 8px;
    letter-spacing: 0.5px;
    padding: 4px 2.5px 3.25px;
    border-radius: 2px;
    margin: auto 7px;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  color: ${({theme}) => theme.title};
  letter-spacing: -0.2px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 1px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 44px;

  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 1.1em;
    height: 2.4em;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 4px 0;
  }
`;

export const Text = styled.span`
  font-size: 13px;
  color: ${({theme}) => theme.descript1};
  letter-spacing: -0.13px;
  line-height: 18px;
  height: 18px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;

  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 9px;
    text-overflow: ellipsis;
    height: 9px;
  }
`;

export const Description = styled.span`
  font-size: 13px;
  color: ${({theme}) => theme.descript};
  letter-spacing: -0.13px;
  line-height: 18px;

  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 9px;
  }
`;

export const PopoverDiv = styled.div`
  & .popover-body {
    padding: 0;
    border-radius: 8px;
  }

  & .popover-card {
    width: ${({width}) => `${width}px`};
    height: ${({height}) => `${height}px`};
    box-shadow: ${({theme}) => theme.card_boxshadow};
    background-color: ${({theme}) => theme.card_bg};
    border-radius: 8px;
    border: none !important;

    & .card-body {
      padding: 0.5rem 1.25rem;
    }
  }

  & .fa-play {
    color: #ffffff;
    font-size: 36px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
  }

  & .popover-top {
    & svg,
    & img {
      color: ${({theme}) => theme.card_img};
      font-size: 16px;
    }
  }

  & .new-badge {
    border: 1px solid #4e505a;
    border-radius: 4px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#ffffff')};
    font-size: 14px;
  }
  & .icon-cme {
    width: 32px;
  }
  & .fa-layers {
    width: 32px;
  }

  & .fa-certificate {
    color: #4e505a;
    height: 32px;
    width: 32px;
  }

  & .fa-diploma {
    color: ${({theme}) => (theme.name === 'light' ? '#888888' : '#b8bbbe')};
  }

  & .credit {
    color: ${({theme}) => (theme.name === 'light' ? '#2e3038' : '#eaecf1')};
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  & .popover-bottom {
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#e9e9e9')};
    font-size: 13px;
    letter-spacing: -0.13px;
    line-height: 18px;
    margin-top: 9px;

    & .fa-head-vr {
      margin: auto 8px;
    }
  }
`;

export default {
  CardWrapper,
  Badge,
  BadgeNew,
  Title,
  Text,
  Description,
  PopoverDiv,
};
