// @flow
import React, {useState, useRef, useEffect} from 'react';
import {Overlay, Nav, Button} from 'react-bootstrap';
import {NavLink, useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {StyledDiv, Badge, LinkText, OuterToggleSideBar} from './mobileSidebar';
import BrowseSidebar from './browseSidebar';
import LocalStorageService from '../../utils/LocalStorageService';
import HomeTutorial from '../../pages/Home/components/HomeTutorial';
import VideoTutorial from '../../pages/Video/components/VideoTutorial';
// import CmeTutorial from '../../pages/CME/Components/CmeTutorial';
import {useStore} from '../../store';

const MobileSidebar = observer(() => {
  const {sidebarUIStore} = useStore();
  const {show} = sidebarUIStore;
  const location = useHistory();
  const target = useRef(null);
  const loggedInUser = LocalStorageService.getUser();
  const [showHomepageTutorial, setShowHomepageTutorial] = useState(false);
  const [showVideopageTutorial, setShowVideopageTutorial] = useState(false);
  const [showCmepageTutorial, setShowCmepageTutorial] = useState(false);
  const [lockUser, setLockUser] = useState(false);

  const {accountStore} = useStore();
  const {account, setFeaturePermission} = accountStore;

  const ShowTutorial = () => {
    if (location.location.pathname.includes('/video')) {
      setShowVideopageTutorial(!showVideopageTutorial);
    } else if (location.location.pathname.includes('/cme')) {
      setShowCmepageTutorial(!showCmepageTutorial);
    } else setShowHomepageTutorial(!showHomepageTutorial);
  };

  useEffect(() => {
    const setFeatureLockUser = () => {
      return setFeaturePermission('sidebar');
    };

    if (setFeatureLockUser()) {
      setLockUser(true);
    }
  }, [loggedInUser, account, setFeaturePermission]);

  const ClickEmptySpace = () => {
    sidebarUIStore.setShow(false);
  };

  return (
    <>
      <HomeTutorial isTutorialOpen={showHomepageTutorial} setIsTourOpen={setShowHomepageTutorial} />
      <VideoTutorial isTutorialOpen={showVideopageTutorial} setIsTourOpen={setShowVideopageTutorial} />
      {/* <CmeTutorial isTutorialOpen={showCmepageTutorial} setIsTourOpen={setShowCmepageTutorial} /> */}
      <Badge
        variant="link"
        id="open-mobile-sidebar"
        data-testid="open-mobile-sidebar"
        className="a_openMobileSidebarButton mr-2"
        ref={target}
        onClick={() => sidebarUIStore.setShow(!show)}
      >
        <i className="fak fa-bars" />
      </Badge>
      <Overlay target={target.current} show={show} placement="bottom">
        {({placement, arrowProps, show: _show, popper, ...props}) => (
          <>
            <OuterToggleSideBar onClick={ClickEmptySpace} />
            <StyledDiv {...props}>
              <div className="row">
                <Nav.Item>
                  <Nav.Link exact as={NavLink} to="/" id="sidebar_home" activeClassName="active">
                    <i className="fak fa-home" />
                    <span className="collapseText">Home</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link data-tut="hometutorial__browse_mobile">
                    <BrowseSidebar />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="under-menu">
                  <Nav.Link exact as={NavLink} to="/browse/specialty" id="sidebar_specialty" activeClassName="active">
                    <i className="fal fa-user-md" />
                    <span className="collapseText">Specialty</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="under-menu">
                  <Nav.Link exact as={NavLink} to="/conferences" id="sidebar_conferences" activeClassName="active">
                    <i className="fal fa-desktop-alt" />
                    <span className="collapseText">Conferences</span>
                  </Nav.Link>
                </Nav.Item>
                {account?.access?.content_based ? (
                  <>
                    <Nav.Item>
                      <Nav.Link as={NavLink} to="/purchased" id="sidebar_purchases" activeClassName="active">
                        <i className="fal fa-star" />
                        <span className="collapseText">Purchased</span>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                ) : (
                  ''
                )}

                <Nav.Item>
                  <Nav.Link
                    exact
                    as={NavLink}
                    id="Bookmark"
                    to="/bookmarks"
                    activeClassName="active"
                    className={lockUser && 'lock'}
                    disabled={lockUser}
                    data-tut="hometutorial__bookmark_mobile"
                  >
                    <i className="fak fa-bookmark" />
                    {!lockUser ? (
                      <span className="collapseText">Bookmark</span>
                    ) : (
                      <span className="collapseText lock">
                        Bookmark
                        <i className="fas fa-lock-alt lock-icon" />
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    exact
                    as={NavLink}
                    to="/cme"
                    id="sidebar_CME"
                    activeClassName="active"
                    data-tut="hometutorial__cme_mobile"
                    className={!loggedInUser && 'lock'}
                    disabled={!loggedInUser}
                  >
                    <i className="fak fa-cme-1" />
                    {loggedInUser ? (
                      <span className="collapseText">My CME</span>
                    ) : (
                      <span className="collapseText lock">
                        My CME
                        <i className="fas fa-lock-alt lock-icon" />
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>

                {account?.access?.certificate && process.env.REACT_APP_QUIZ_CERTIFICATE === 'true' ? (
                  <Nav.Item>
                    <Nav.Link
                      exact
                      as={NavLink}
                      to="/certificate"
                      id=""
                      activeClassName="active"
                      data-tip="Certificate"
                    >
                      <i className="fak fa-graduation-cap-regular" />
                      <span className="collapseText">Certificate</span>
                    </Nav.Link>
                  </Nav.Item>
                ) : (
                  ''
                )}

                <Nav.Item>
                  <Nav.Link
                    exact
                    as={NavLink}
                    to="/playlist"
                    id="sidebar_playlist"
                    activeClassName="active"
                    data-tut="hometutorial__playlist_mobile"
                    className={lockUser && 'lock'}
                    disabled={lockUser}
                  >
                    <i className="fak fa-list-ul" />
                    <i className="fak fa-list-ul-solid" />
                    {!lockUser ? (
                      <span className="collapseText">Playlists</span>
                    ) : (
                      <span className="collapseText lock">
                        Playlists
                        <i className="fas fa-lock-alt lock-icon" />
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
                {loggedInUser?.role === 'admin' ? (
                  <>
                    <Nav.Item>
                      <Nav.Link
                        exact
                        as={NavLink}
                        to="/admin_dashboard"
                        id="sidebar_dashboard"
                        activeClassName="active"
                        data-tut="hometutorial__dashboard_mobile"
                        className={lockUser && 'lock'}
                        disabled={lockUser}
                      >
                        <i className="fas fa-lock-alt lock-icon" />
                        {!lockUser ? (
                          <span className="collapseText">Dashboard</span>
                        ) : (
                          <span className="collapseText lock">
                            DashBoard
                            <i className="fas fa-lock-alt lock-icon" />
                          </span>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link exact as={NavLink} to="/admin" id="sidebar_admin" activeClassName="active">
                        <i className="fal fa-tools" />
                        <span className="collapseText">Admin</span>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                ) : (
                  <Nav.Item>
                    <Nav.Link
                      exact
                      as={NavLink}
                      to="/dashboard"
                      id="sidebar_dashboard"
                      activeClassName="active"
                      data-tut="hometutorial__dashboard_mobile"
                      className={lockUser && 'lock'}
                      disabled={lockUser}
                    >
                      <i className="fas fa-lock-alt lock-icon" />
                      {!lockUser ? (
                        <span className="collapseText">Dashboard</span>
                      ) : (
                        <span className="collapseText lock">
                          DashBoard
                          <i className="fas fa-lock-alt lock-icon" />
                        </span>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                )}
                <div className="mobile-login pt-2">
                  {!loggedInUser?.id ? (
                    <Button id="login-button" onClick={() => location.push('/login')}>
                      Login
                    </Button>
                  ) : null}
                </div>
                <div className="bottom-sidebar mb-auto">
                  <Nav.Item>
                    <Nav.Link
                      exact
                      as={NavLink}
                      to="/catalog/videos"
                      id="sidebar_catalog"
                      data-tip="Catalog"
                      activeClassName="active"
                    >
                      <i className="fal fa-book" />
                      <span className="collapseText">Catalog</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={ShowTutorial} id="sidebar_tutorial" data-tip="Tutorial" activeClassName="active">
                      <i className="fal fa-chalkboard-teacher" />
                      <span className="collapseText">Tutorial</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="https://www.giblib.com/institutions/resources"
                      target="_blank"
                      id="sidebar_resources"
                      data-tip="Resources"
                      activeClassName="active"
                    >
                      <i className="fal fa-handshake" />
                      <span className="collapseText">Resources</span>
                    </Nav.Link>
                  </Nav.Item>
                  <div className="links">
                    <div className="tos">
                      <LinkText>
                        <a href="https://www.giblib.com/support/terms-of-service" target="_blank" rel="noreferrer">
                          Terms of Services
                        </a>
                      </LinkText>
                      <LinkText>
                        <a href="https://www.giblib.com/support/privacy-policy" target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                      </LinkText>
                    </div>
                  </div>
                  <div className="links-tablet">
                    <LinkText>
                      <a href="https://www.giblib.com/support/terms-of-service" target="_blank" rel="noreferrer">
                        Terms of Services
                      </a>
                    </LinkText>
                    <LinkText>
                      <a href="https://www.giblib.com/support/privacy-policy" target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                    </LinkText>
                  </div>
                  {/* <div className="social-links">
                    <a href="https://www.facebook.com/thegiblibapp/">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="https://twitter.com/theGIBLIBapp">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCPpRfYLhUU2gi3B2P8cdt0Q">
                      <i className="fab fa-youtube" />
                    </a>
                  </div> */}
                </div>
              </div>
            </StyledDiv>
          </>
        )}
      </Overlay>
    </>
  );
});

export default MobileSidebar;
