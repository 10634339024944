import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useHistory, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {Row, Col, Form, Container, Spinner} from 'react-bootstrap';
import {
  Breadcrumbs,
  PageTitle,
  SortSelect,
  FormLabel,
  ActiveCrumb,
  Crumb,
  CourseFilter,
  SearchExpertDiv,
} from '../elements/search';
import ExpertTile from '../../../components/ExpertTile';
import {useStore} from '../../../store';

// Search Experts page
const SearchExperts = observer(() => {
  const params = useLocation();
  const text = new URLSearchParams(params.search).get('q');
  const {expertStore, searchStore} = useStore();
  const {expertResults, getExpertResults, isLoading} = searchStore;
  const {onUnfollowExpert, onFollowExpert} = expertStore;

  const [sortValue, setSortValue] = useState('default');
  const location = useHistory();

  const changeSort = option => {
    setSortValue(option.value);
    getExpertResults(text, 0, option.value);
  };

  const scroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && !isLoading) {
      const sortBy = sortValue === 'default' ? null : sortValue;
      getExpertResults(text, expertResults.length, sortBy);
    }
  };

  useEffect(() => {
    getExpertResults(text);
  }, [getExpertResults, text]);

  const onHandleFollow = item => {
    const user = localStorage.getItem('user');
    if (user) {
      if (item.is_following) {
        onUnfollowExpert(item);
      } else {
        onFollowExpert(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  return (
    <>
      <SearchExpertDiv
        onScroll={scroll}
        className={classNames({
          a_resultDiv: isLoading,
        })}
      >
        <div className="d-flex container">
          <Breadcrumbs>
            <Crumb href="/home">Search</Crumb>
            <ActiveCrumb href="#">{text}</ActiveCrumb>
          </Breadcrumbs>
        </div>

        <Container>
          <div className="d-flex align-items-center a_filterHeadDiv">
            <PageTitle className="mr-auto">Experts with &apos;{text || ' '}&apos;</PageTitle>
            <CourseFilter>
              <Form>
                <Form.Row>
                  <Form.Group controlId="sort" className="my-0 a_searchExpertSortBy">
                    <div className="d-flex">
                      <FormLabel className="mt-3 mr-2">Sort</FormLabel>
                      <SortSelect data-testid="select" value={sortValue} onChange={e => changeSort(e.target)}>
                        <option value="default">Select</option>
                        <option value="asc">Name(A-Z)</option>
                        <option value="desc">Name(Z-A)</option>
                      </SortSelect>
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form>
            </CourseFilter>
          </div>
          <Row>
            {expertResults?.map(el => (
              <Col key={el.id} id={el.id} md={4} lg={3} xl={2}>
                <ExpertTile expert={el} type="expert" storeName="searchStore" onFollow={onHandleFollow} />
              </Col>
            ))}
            {!expertResults?.length && !isLoading && <p className="px-3 pt-5">No results</p>}
          </Row>
          {!expertResults?.length && isLoading && (
            <div className="text-center my-3 mt-5 pt-5">
              <Spinner animation="grow" className="mt-5" />
            </div>
          )}
        </Container>
      </SearchExpertDiv>
      {expertResults?.length && isLoading ? (
        <div className="text-center my-3">
          <Spinner animation="grow" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default SearchExperts;
