import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Container, Row, Col, Form} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {Main, SortSelect, Title} from './elements/following';
import FollowCard from './components/FollowCard';
import {useStore} from '../../store';

// Following page
const Following = observer(() => {
  const {accountStore} = useStore();
  const {followList, fetchUserFollows} = accountStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchUserFollows('DESC');
  }, [fetchUserFollows]);

  const changeSort = value => {
    fetchUserFollows(value);
  };

  return (
    <div id="following" className="d-flex flex-column flex-fill">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <Main className="w-100">
          <Container>
            <Row style={{marginBottom: '16px'}}>
              <Col>
                <Title>Following</Title>
              </Col>
              <Col className="text-right">
                <Form>
                  <Form.Label style={{fontSize: '13px'}} className="mr-2" htmlFor="sort-follow">
                    Sort
                  </Form.Label>
                  <SortSelect id="sort-follow" onChange={e => changeSort(e.target.value)}>
                    <option value="DESC">Newest</option>
                    <option value="ASC">Oldest</option>
                  </SortSelect>
                </Form>
              </Col>
            </Row>
            {(followList || []).map(item => (
              <FollowCard item={item} />
            ))}
          </Container>
          <Footer />
        </Main>
      </div>
    </div>
  );
});

export default Following;
