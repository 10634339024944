// @flow
import React, {useState, useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Card, NavLink, Row, Col} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import Moment from 'react-moment';
import {useStore} from '../../store';
import {
  Title,
  Line,
  NotificationCard,
  ReadCard,
  CardText,
  CardImage,
  Badge,
  TopLine,
  DeskPanel,
  MobilePanel,
  SpecialtyAvatar,
  CcAvatar,
} from './elements/notificationpanel';
import icon from '../../assets/images/icon_circle.jpeg';
import cmeIcon from '../../assets/images/icon_CME.svg';
import ccIcon from '../../assets/icons/credit-card-solid.svg';

const NotificationsPanel = observer(() => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const location = useHistory();
  const {announcementStore} = useStore();
  const {notificationList, loadNotification, readNotifications, markNotificationasRead} = announcementStore;

  useEffect(() => {
    loadNotification();
  }, [loadNotification]);

  return (
    <>
      <Badge
        variant="link"
        id="open-notification-panel"
        data-testid="open-notification-panel"
        className="a_openNotificationPanelButton mr-3"
        ref={target}
        onClick={() => setShow(!show)}
      >
        {notificationList && notificationList.filter(notification => !notification.read).length > 0 ? (
          <div>
            <span className="fa-layers">
              <i className="fas fa-bell" />
              <span>
                <i className="fas fa-circle" />
              </span>
            </span>
          </div>
        ) : (
          <div>
            <span>
              <i className="fas fa-bell" />
            </span>
          </div>
        )}
      </Badge>
      {show ? (
        <>
          <DeskPanel>
            <Row className="header">
              <Button className="close" aria-label="Close" data-testid="close-panel" onClick={() => setShow(!show)}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Row>
            <Row className="header">
              <div className="col-md-6">
                <Title>Notifications</Title>
              </div>
              <div className="col-md-6">
                <NavLink
                  className="link font-weight-bold"
                  data-testid="read-notification"
                  onClick={() => readNotifications()}
                >
                  Mark all as read
                </NavLink>
              </div>
            </Row>

            <TopLine />
            <div>
              {notificationList?.length ? (
                notificationList.map(item => (
                  <div key={item.id}>
                    {item.read ? (
                      <ReadCard onClick={() => item.target_url && location.push(item.target_url)}>
                        <Card.Body>
                          <div className="row">
                            <div className="col-md-3">
                              {/* <CardImage
                                src={item.target ? item.target : icon}
                                alt="notification"
                              /> */}
                              {item.target === 'cme' ? (
                                <CardImage src={cmeIcon} alt="notification" />
                              ) : (
                                [
                                  item.target === 'organization' || item.target === 'expert' ? (
                                    <CardImage
                                      src={item.organization?.thumbnail || item.expert?.thumbnail}
                                      alt="notification"
                                    />
                                  ) : (
                                    [
                                      item.target === 'course' || item.target === 'conference' ? (
                                        <CardImage
                                          src={item.course?.thumbnail_url || item.conference?.thumbnail_url}
                                          alt="notification"
                                        />
                                      ) : (
                                        [
                                          item.target === 'specialty' ? (
                                            <SpecialtyAvatar>{item.specialty?.name.slice(0, 2)}</SpecialtyAvatar>
                                          ) : (
                                            [
                                              item.target === 'card' ? (
                                                <CcAvatar>
                                                  <img src={ccIcon} alt="notification" className="w-100" />
                                                </CcAvatar>
                                              ) : (
                                                <CardImage src={icon} alt="notification" />
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )}
                            </div>
                            <div className="col-md-9 noticard">
                              <CardText className="time">
                                <Moment fromNow>{item.created_at}</Moment>
                              </CardText>
                              <CardText>{item.text}</CardText>
                            </div>
                          </div>
                        </Card.Body>
                      </ReadCard>
                    ) : (
                      <NotificationCard
                        onClick={() => {
                          markNotificationasRead(item.id);
                          if (item.target_url) {
                            location.push(item.target_url);
                          }
                        }}
                        data-testid="new-notification-card"
                      >
                        <Card.Body>
                          <div className="row">
                            <div className="col-md-3">
                              {/* <CardImage
                                src={item.target ? item.target : icon}
                                alt="notification"
                              /> */}
                              {item.target === 'cme' ? (
                                <CardImage src={cmeIcon} alt="notification" />
                              ) : (
                                [
                                  item.target === 'organization' || item.target === 'expert' ? (
                                    <CardImage
                                      src={item.organization?.thumbnail || item.expert?.thumbnail}
                                      alt="notification"
                                    />
                                  ) : (
                                    [
                                      item.target === 'course' || item.target === 'conference' ? (
                                        <CardImage
                                          src={item.course?.thumbnail_url || item.conference?.thumbnail_url}
                                          alt="notification"
                                        />
                                      ) : (
                                        [
                                          item.target === 'specialty' ? (
                                            <SpecialtyAvatar>{item.specialty?.name.slice(0, 2)}</SpecialtyAvatar>
                                          ) : (
                                            [
                                              item.target === 'card' ? (
                                                <CcAvatar>
                                                  <img src={ccIcon} alt="notification" className="w-100" />
                                                </CcAvatar>
                                              ) : (
                                                <CardImage src={icon} alt="notification" />
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )}
                            </div>
                            <div className="col-md-9 noticard">
                              <Card.Text className="time">
                                <Moment fromNow>{item.created_at}</Moment>
                              </Card.Text>
                              <Card.Text className="text-label">{item.text}</Card.Text>
                            </div>
                          </div>
                        </Card.Body>
                      </NotificationCard>
                    )}
                    <Line />
                  </div>
                ))
              ) : (
                <p className="no-notification">No notifications</p>
              )}
            </div>
          </DeskPanel>
          <MobilePanel>
            <Row className="header">
              <Button className="close" aria-label="Close" data-testid="close-panel" onClick={() => setShow(!show)}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Row>
            <Row className="header">
              <Col>
                <Title>Notifications</Title>
              </Col>
              <Col>
                <NavLink
                  className="link font-weight-bold"
                  data-testid="read-notification"
                  onClick={() => readNotifications()}
                >
                  Mark all as read
                </NavLink>
              </Col>
            </Row>

            <TopLine />
            <div>
              {notificationList?.length ? (
                notificationList.map(item => (
                  <div key={item.id}>
                    {item.read ? (
                      <ReadCard onClick={() => item.target_url && location.push(item.target_url)}>
                        <Card.Body>
                          <div className="row">
                            <div>
                              {item.target === 'cme' ? (
                                <CardImage src={cmeIcon} alt="notification" />
                              ) : (
                                [
                                  item.target === 'organization' || item.target === 'expert' ? (
                                    <CardImage
                                      src={item.organization?.thumbnail || item.expert?.thumbnail}
                                      alt="notification"
                                    />
                                  ) : (
                                    [
                                      item.target === 'course' ? (
                                        <CardImage src={item.course?.thumbnail_url} alt="notification" />
                                      ) : (
                                        [
                                          item.target === 'specialty' ? (
                                            <SpecialtyAvatar>{item.specialty?.name.slice(0, 2)}</SpecialtyAvatar>
                                          ) : (
                                            [
                                              item.target === 'card' ? (
                                                <CcAvatar>
                                                  <img src={ccIcon} alt="notification" className="w-100" />
                                                </CcAvatar>
                                              ) : (
                                                <CardImage src={icon} alt="notification" />
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )}
                            </div>
                            <div>
                              <CardText className="time">
                                <Moment fromNow>{item.created_at}</Moment>
                              </CardText>
                              <CardText>{item.text}</CardText>
                            </div>
                          </div>
                        </Card.Body>
                      </ReadCard>
                    ) : (
                      <NotificationCard
                        onClick={() => {
                          markNotificationasRead(item.id);
                          if (item.target_url) {
                            location.push(item.target_url);
                          }
                        }}
                        data-testid="new-notification-card"
                      >
                        <Card.Body>
                          <div className="row">
                            <div className="col-md-3">
                              {item.target === 'cme' ? (
                                <CardImage src={cmeIcon} alt="notification" />
                              ) : (
                                [
                                  item.target === 'organization' || item.target === 'expert' ? (
                                    <CardImage
                                      src={item.organization?.thumbnail || item.expert?.thumbnail}
                                      alt="notification"
                                    />
                                  ) : (
                                    [
                                      item.target === 'course' ? (
                                        <CardImage src={item.course?.thumbnail_url} alt="notification" />
                                      ) : (
                                        [
                                          item.target === 'specialty' ? (
                                            <SpecialtyAvatar>{item.specialty?.name.slice(0, 2)}</SpecialtyAvatar>
                                          ) : (
                                            [
                                              item.target === 'card' ? (
                                                <CcAvatar>
                                                  <img src={ccIcon} alt="notification" className="w-100" />
                                                </CcAvatar>
                                              ) : (
                                                <CardImage src={icon} alt="notification" />
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )}
                            </div>
                            <div className="col-md-6 noticard">
                              <Card.Text className="time">
                                <Moment fromNow>{item.created_at}</Moment>
                              </Card.Text>
                              <Card.Text>{item.text}</Card.Text>
                            </div>
                          </div>
                        </Card.Body>
                      </NotificationCard>
                    )}

                    <Line />
                  </div>
                ))
              ) : (
                <p className="text-dark">No notifications</p>
              )}
            </div>
          </MobilePanel>
        </>
      ) : (
        <></>
      )}
    </>
  );
});

export default NotificationsPanel;
