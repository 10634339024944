import styled from 'styled-components';
import {Card} from 'react-bootstrap';

export const Title = styled.h5`
  font-weight: bold;
  color: ${({theme}) => (theme.name === 'light' ? '#252730' : '#F9F9F9')};

  @media screen and (max-width: 576px) {
    font-size: 15px;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 20px;
  }
`;

export const Line = styled.hr`
  background-color: #4e505a;
  color: #4e505a;
  margin-top: 0;
  margin-bottom: 0rem;
`;

export const TopLine = styled(Line)`
  margin-top: 0rem;
`;

export const DeskPanel = styled.div`
  background: ${({theme}) => (theme.name === 'light' ? '#F0F0F0' : '#2E3038')};
  margin-top: 50px;
  color: white;
  height: calc(100vh - 55px);
  min-width: 346px;
  top: 0.5rem !important;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 100 !important;
  position: absolute;
  overflow-y: auto;
  max-width: 400px;

  .header {
    background: ${({theme}) => (theme.name === 'light' ? '#F0F0F0' : '#252730')};
    width: 100%;
    min-width: 346px;
    margin-left: 0px !important;
  }

  .close {
    margin-left: auto;
    margin-right: 30px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
  }
  .close:hover {
    background-color: transparent;
  }

  .time {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 0;
  }
  .text-label {
    white-space: pre-line;
  }
  .link {
    padding: 0rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-left: 30px;
    font-size: 13px;
    font-color: #30a0ff;
    line-height: 18px;
  }

  .no-notification {
    padding: 15px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin-left: -228px;
  }
  @media (min-width: 500px) and (max-width: 768px) {
    margin-left: -192px;
  }
`;

export const MobilePanel = styled.div`
  background: ${({theme}) => (theme.name === 'light' ? '#F0F0F0' : '#2E3038')};
  margin-top: 50px;
  color: white;
  height: 100vh;
  min-width: 264px;
  max-width: 264px;
  top: 0.5rem !important;
  display: flex;
  flex-direction: column;
  z-index: 100 !important;
  position: absolute;

  .header {
    background: ${({theme}) => (theme.name === 'light' ? '#F0F0F0' : '#252730')};
    width: 264px;
    margin-left: 1px;
  }

  .close {
    margin-left: auto;
    margin-right: 19px;
  }

  .time {
    font-size: 7.5px;
    line-height: 13px;
    margin-bottom: 0;
  }

  .link {
    padding: 0rem;
    margin-left: 30px;
    font-size: 9.75px;
    font-color: #30a0ff;
    line-height: 18px;
  }

  @media screen and (max-width: 800px) {
    right: 0;
  }
  @media screen and (min-width: 500px) {
    display: none;
  }
`;

export const CcAvatar = styled.div`
  border-radius: 65vh;
  width: 32px;
  height: 32px;
  margin: 2px 5px 5px;
  padding: 4px 7px;
  background: #35afcb;
`;

export const NotificationCard = styled(Card)`
  background: ${({theme}) => (theme.name === 'light' ? '#F0F0F0' : '#2E3038')};
  font-size: 15px;
  font-weight: bolder;
  border: 0px;
  // height: 56px;
  color: ${({theme}) => (theme.name === 'light' ? '#252730' : '#F9F9F9')};
  padding: 0;

  @media screen and (max-width: 576px) {
    height: 43px;
  }
`;

export const ReadCard = styled(Card)`
  background: ${({theme}) => (theme.name === 'light' ? '#F0F0F0' : '#2E3038')};
  font-size: 15px;
  border: 0px;
  // height: 56px;
  padding: 0;

  @media screen and (max-width: 576px) {
    height: 43px;
  }
`;

export const CardText = styled(Card.Text)`
  color: ${({theme}) => (theme.name === 'light' ? '#252730' : '#F9F9F9')};
  @media screen and (max-width: 576px) {
    size: 11.25px;
  }
`;

export const CardImage = styled(Card.Img)`
  border-radius: 65vh;
  width: 32px;
  height: 32px;
  margin: 2px 5px 5px;

  @media screen and (max-width: 576px) {
    width: 24px;
    height: 24px;
  }
`;

export const Badge = styled.button`
  border: none;
  background: transparent;
  .fa-layers {
    width: 24px;
    height: 24px;
    top: 5px;
  }
  .fa-bell {
    width: 14px;
    height: 16px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
  }
  .fa-circle {
    color: #e04663;
    width: 8px;
    margin-left: 12px !important;
    margin-bottom: 8px !important;
  }
`;

export const SpecialtyAvatar = styled.span`
  margin: 2px 5px 5px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0677d8;
  color: #ffffff;
  letter-spacing: -1px;
  font-weight: 400;
  line-height: 1.5;
`;
