import {makeAutoObservable, runInAction} from 'mobx';
import {banUser, ignoreUser, updateUserPwd, uploadData, updateCarouselOrder} from '../../api/admin';
import {getCarouselTypes} from '../../api/carousel';
import ErrorStore from '../error/errorStore';

class AdminStore {
  errorStore: ErrorStore;

  banResp = {};

  ignoreResp = {};

  updatePwdResp = {};

  file = [];

  cmeMessage = '';

  cmeStatus = false;

  videoMessage = '';

  videoStatus = false;

  carouselTypes = [];

  videoUploading = false;

  cmeUploading = false;

  userMessage = '';

  userStatus = false;

  userUploading = false;

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  setBanResponse = value => {
    this.banResp = value;
  };

  setIgnoreResponse = value => {
    this.ignoreResp = value;
  };

  setUpdatePwdResponse = value => {
    this.updatePwdResp = value;
  };

  setBanUser = async email => {
    try {
      const resp = await banUser(email);
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          this.setBanResponse({type: 'success', email});
        } else {
          this.setBanResponse({type: 'error', email});
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setIgnoreUser = async email => {
    try {
      const resp = await ignoreUser(email);
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          this.setIgnoreResponse({type: 'success', email});
        } else {
          this.setIgnoreResponse({type: 'error', email});
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setNewUserPwd = async (email, password) => {
    try {
      const resp = await updateUserPwd(email, password);
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          this.setUpdatePwdResponse({type: 'success', email});
        } else {
          this.setUpdatePwdResponse({type: 'error', email});
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setStatus = status => {
    this.cmeStatus = status;
  };

  setMessage = message => {
    this.cmeMessage = message;
  };

  setVideoStatus = status => {
    this.videoStatus = status;
  };

  setVideoMessage = message => {
    this.videoMessage = message;
  };

  setVideoUploading = value => {
    this.videoUploading = value;
  };

  setCMEUploading = value => {
    this.cmeUploading = value;
  };

  uploadCME = async file => {
    this.setCMEUploading(true);
    this.setMessage('');
    try {
      const resp = await uploadData(file, 'cme');
      this.setStatus(true);
      this.setMessage(resp.data.message);
    } catch (error) {
      const response = {...error.response};
      if (!response?.data?.error) {
        this.errorStore.setError(error);
        return;
      }
      this.setStatus(false);
      this.setMessage(response.data.error);
    }
    this.setCMEUploading(false);
  };

  uploadVideo = async file => {
    this.setVideoUploading(true);
    this.setVideoMessage('');

    try {
      const resp = await uploadData(file, 'video');
      this.setVideoStatus(true);
      this.setVideoMessage(resp.data.message);
    } catch (error) {
      const response = {...error.response};
      if (!response?.data?.error) {
        this.errorStore.setError(error);
        return;
      }
      this.setVideoStatus(false);
      this.setVideoMessage(response.data.error);
    }
    this.setVideoUploading(false);
  };

  setUserStatus = status => {
    this.userStatus = status;
  };

  setUserMessage = message => {
    this.userMessage = message;
  };

  setUserUploading = value => {
    this.userUploading = value;
  };

  uploadUser = async file => {
    this.setUserUploading(true);
    this.setUserMessage('');

    try {
      const resp = await uploadData(file, 'user');
      this.setUserStatus(true);
      this.setUserMessage(resp.data.message);
    } catch (error) {
      const response = {...error.response};
      if (!response?.data?.error) {
        this.errorStore.setError(error);
        return;
      }
      this.setUserStatus(false);
      this.setUserMessage(response.data.error);
    }
    this.setUserUploading(false);
  };

  setCarouselTypes = list => {
    this.carouselTypes = list;
  };

  getCarouselTypes = async () => {
    try {
      const res = await getCarouselTypes();
      if (res.data.status) {
        this.setCarouselTypes(res.data.carouselTypes);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updateOrder = async data => {
    try {
      const res = await updateCarouselOrder(data);
      const {status} = res.data;
      if (status) {
        this.setCarouselTypes(data);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default AdminStore;
