// @flow
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {Button, Container, OverlayTrigger} from 'react-bootstrap';
import Moment from 'react-moment';
import {observer} from 'mobx-react-lite';
import Thumbnail from '../elements/video-thumbnail.png';
import {
  ThumbnailDiv,
  ThumbnailImg,
  ButtonGroup,
  VideoCardHeader,
  VideoTitle,
  Organization,
  Details,
  VideoCard,
  DetailDiv,
} from '../elements/style';
import LocalStorageService from '../../../utils/LocalStorageService';
import {useStore} from '../../../store';
import UpgradeModal from '../../../components/UpgradeModal';
import {DeleteModal} from './DeleteModal';
import AlertComponent from '../../../components/AlertComponent';
import PlaylistPopover from '../../../components/PlaylistPopover';
import {DownloadButton} from '../../../components/AssessmentButton';
import {amplitude} from '../../../utils/Amplitude';
import {secondsToTime} from '../../../utils/helper';

//   item: {
//     id: number,
//     started_at: Date,
//     quiz_completed_at: Date,
//     certificate_downloaded_at: Date,
//     is_certificate_downloaded: Boolean,
//     answers: [],
//     certificate_url: null,
//     user_id: 0000,
//     video_id: 0000,
//     created_at: Date,
//     updated_at: Date,
//     deleted_at: Date,
//     video: {
//       id: 2646,
//       duration: 797,
//       title: 'Women&#8217;s Health Update',
//       thumbnail: 'https://wp-media-development.giblib.com/wp-content/uploads/2022/01/21041327/image_7225_202201202313.jpg',
//       organization: {
//         name: 'Mayo Clinic',
//       },
//       bookmark: [],
//       playlist: [],
//       specialties: [],
//     },
//   },

const CertificateCompletedCards = observer(({item, isDownload}: Props) => {
  const location = useHistory();
  const {videoStore, certificateStore, alertStore, accountStore} = useStore();
  const {download, deleteCompletedCertificate} = certificateStore;
  const {account} = accountStore;
  const {message, status, resetAlert} = alertStore;
  const {playlist, getAllPlaylists, addToPlaylist, updatePlaylist, toggleVideoBookmark} = videoStore;

  const [dowloadLoader, setDowloadLoader] = useState(false);

  /* Delete cme modal state */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);
  const handleDeleteModalClose = () => setShowDeleteModal(false);

  const handleSubmitForm = () => {
    deleteCompletedCertificate(item.id);
    setShowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    handleDeleteModalShow();
  };

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const loggedInUser = LocalStorageService.getUser();

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) {
      setUserState(true);
      getAllPlaylists();
    }
  }, [account, getAllPlaylists]);

  const createAndAddToPlaylist = data => {
    addToPlaylist(item?.video?.id, data.listname, true);
  };

  const updateExistingPlaylist = data => {
    updatePlaylist(item?.video?.id, data, true);
  };

  const [bookmarkIcon, setBookmarkIcon] = useState(false);

  useEffect(() => {
    if (item?.video?.bookmark?.length) setBookmarkIcon(true);
  }, [item]);

  const openVideo = video => {
    location.push(`/video/${video.id}`);
  };

  const toggleBookmark = () => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic')) {
        toggleVideoBookmark(bookmarkIcon, item?.video?.id).then(() => {
          setBookmarkIcon(!bookmarkIcon);
        });
        amplitude.getInstance().logEvent('bookmarked');
      } else setShowBookmarkUpgradeModal(true);
    } else location.push('/login');
  };

  const onDownload = () => {
    if (!dowloadLoader && isDownload) {
      setDowloadLoader(true);
      download([item?.id]).then(() => {
        setDowloadLoader(false);
      });
    }
  };

  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <VideoCard>
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
        <Container>
          <div className="card-border-bottom d-flex card-padding" id={item.id}>
            <ThumbnailDiv>
              <ThumbnailImg src={item?.video?.thumbnail || Thumbnail} alt={item?.video?.title} />
            </ThumbnailDiv>
            <DetailDiv className="w-100">
              <VideoCardHeader className="d-flex">
                <div className="d-flex">
                  <div className="progress-badge completed-badge">Completed</div>
                </div>
              </VideoCardHeader>
              <VideoTitle onClick={() => openVideo(item?.video)}>
                <h5>{item?.video?.title}</h5>
              </VideoTitle>
              <Organization>
                {item?.video?.organization?.name}
                {item?.video?.organization?.name && item?.video?.specialties.length > 0 ? ' | ' : ''}
                {item?.video?.specialties.length > 0
                  ? `${item?.video?.specialties
                      .slice(0, 2)
                      ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                  : ''}
              </Organization>
              <Details>
                {secondsToTime(item?.video?.duration || 0)}
                &nbsp;|&nbsp;
                <span>
                  Completed <Moment format="MM/DD/YYYY">{item?.quiz_completed_at}</Moment>
                </span>
              </Details>
            </DetailDiv>
            <ButtonGroup>
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={props => (
                  <PlaylistPopover
                    props={props}
                    videoPlaylist={item?.video?.playlist}
                    allPlaylist={playlist}
                    onExisting={updateExistingPlaylist}
                    onNew={createAndAddToPlaylist}
                    userState={userState}
                    showUpgradeModal={isShowPlaylistUpgradeModal}
                    onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                  />
                )}
                rootClose
              >
                <Button
                  variant="basic"
                  className="a_playlist action-icon-button mr-3"
                  data-tip="Playlist"
                  data-for="videoActions"
                  onClick={() => setShowPlaylistUpgradeModal(true)}
                >
                  <i className="fak fa-list-ul" />
                </Button>
              </OverlayTrigger>
              <Button
                variant="basic"
                onClick={() => toggleBookmark()}
                className="a_bookmark action-icon-button mr-3"
                data-tip="Bookmark"
                data-for="videoActions"
              >
                {bookmarkIcon ? (
                  <span key="video_bookmark">
                    <i className="fas fa-bookmark" />
                  </span>
                ) : (
                  <span key="video_bookmark_active">
                    <i className="far fa-bookmark" />
                  </span>
                )}
              </Button>
              <Button variant="basic" className="action-icon-button a_remove" onClick={handleDeleteModal}>
                <i className="fa fa-trash" />
              </Button>
              <DownloadButton
                className="download_button"
                onDownload={onDownload}
                canDownload={isDownload}
                disabled={dowloadLoader}
              />
            </ButtonGroup>
          </div>
        </Container>
      </VideoCard>
      {/* Update Modal to delete cme */}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteModalClose={handleDeleteModalClose}
        handleSubmitForm={handleSubmitForm}
        videoTitle={item?.video?.title}
        tabName="Completed"
      />
    </>
  );
});

export default CertificateCompletedCards;
