/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
const loadHubspot = (id, callback) => {
  const existingScript = document.getElementById('hs-script-loader');
  if (!existingScript) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.defer = true;
    script.async = true;
    script.src = `//js.hs-scripts.com/${id}.js`;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const trackUserEmail = email => {
  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['identify', {email}]);
  _hsq.push(['trackPageView']);
};

export {loadHubspot, trackUserEmail};
