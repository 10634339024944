import React, {useState, useEffect} from 'react';
import {Button, OverlayTrigger, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {CourseTitle, ButtonGroup} from '../elements/catalog';
import stringToURLSlug from '../../../utils/helper';
import CoursePlaylistPopover from '../../../components/CoursePlaylistPopover';
import AlertComponent from '../../../components/AlertComponent';
import LocalStorageService from '../../../utils/LocalStorageService';
import CMEIcon from '../../../assets/icons/icon_CME.svg';
import {useStore} from '../../../store';
import UpgradeModal from '../../../components/UpgradeModal';

type Props = {
  item: {
    id: number,
    title: string,
    description: string,
    number_of_videos: number,
    total_runtime: {
      hours?: number,
      minutes?: number,
      seconds?: number,
    },
    is_cme: Boolean,
    thumbnail_url: String,
    bookmark: Array,
    follow: Array,
    created_at: date,
    organization: {
      name: string,
    },
    videos: [{id: number}],
  },
  onBookmark: Function,
  playlist: {
    id: number,
    name: string,
  },
  type: String,
};
// Courses List
const CourseCard = ({item, onBookmark, playlist, type}: Props) => {
  const location = useHistory();
  const {accountStore, alertStore, courseStore, conferenceStore} = useStore();
  const {account} = accountStore;

  const loggedInUser = LocalStorageService.getUser();

  const {message, status, resetAlert} = alertStore;

  const {addCourseToNewPlaylist, addCourseToPlaylist} = courseStore;
  const {addConferenceToNewPlaylist, addConferenceToPlaylist} = conferenceStore;

  const [userState, setUserState] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  const bookmarkCourse = (e, selectedItem) => {
    e.stopPropagation();
    if (!account?.subscription?.name?.includes('Basic')) {
      onBookmark(selectedItem);
      setShowBookmarkUpgradeModal(false);
    } else setShowBookmarkUpgradeModal(true);
  };

  const gotoCourseDetail = id => {
    const slug = stringToURLSlug(item.title);
    if (type === 'course') {
      location.push(`/courses/${id}/${slug}`);
    } else if (type === 'conference') {
      location.push(`/conferences/${id}/${slug}`);
    }
  };

  const createAndAddToPlaylist = async data => {
    setUpdating(true);
    if (type === 'course') {
      await addCourseToNewPlaylist(item.id, data.listname);
    } else {
      await addConferenceToNewPlaylist(item.id, data.listname);
    }
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    if (type === 'course') {
      await addCourseToPlaylist(item.id, data);
    } else {
      await addConferenceToPlaylist(item.id, data);
    }
    setUpdating(false);
  };

  return (
    <div>
      {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <div className="d-flex" id={item.id} data-tip={`Filmed: ${item.created_at}`}>
        <div
          className="w-100"
          role="button"
          tabIndex="0"
          onClick={() => gotoCourseDetail(item.id)}
          onKeyDown={() => gotoCourseDetail(item.id)}
        >
          <CourseTitle>
            {item.is_cme ? (
              <span style={{display: 'flex'}}>
                <img src={CMEIcon} className="icon-cme mx-1" alt="cme" />
                <p>{item.title}</p>
              </span>
            ) : (
              <p className="ml-3">&nbsp;&nbsp;{item.title}</p>
            )}
          </CourseTitle>
        </div>
        <ButtonGroup className="text-right">
          {loggedInUser ? (
            [
              !updating ? (
                <OverlayTrigger
                  trigger="click"
                  placement="auto-start"
                  overlay={props => (
                    <CoursePlaylistPopover
                      props={props}
                      allPlaylist={playlist}
                      onNew={createAndAddToPlaylist}
                      onExisting={updateExistingPlaylist}
                      courseVideos={item.videos}
                      userState={userState}
                      showUpgradeModal={isShowPlaylistUpgradeModal}
                      onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                    />
                  )}
                  rootClose
                >
                  <Button variant="basic" className="a_playlist" onClick={() => setShowPlaylistUpgradeModal(true)}>
                    <i className="fak fa-list-ul" />
                  </Button>
                </OverlayTrigger>
              ) : (
                <Spinner animation="border" size="sm" variant="primary" className="mr-3" />
              ),
            ]
          ) : (
            <Button variant="basic" className="a_playlist pl-0 py-0 px-3" onClick={() => location.push('/login')}>
              <i className="fak fa-list-ul" />
            </Button>
          )}
          <Button
            variant="basic"
            className={`a_bookmark ${item.bookmark?.length ? 'd-none' : ''}`}
            onClick={e => bookmarkCourse(e, item)}
          >
            <i className="far fa-bookmark" />
          </Button>
          <Button
            variant="basic"
            className={`a_bookmark ${item.bookmark?.length ? 'bookmark-fill' : 'd-none'}`}
            onClick={e => bookmarkCourse(e, item)}
          >
            <i className="fas fa-bookmark" />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default CourseCard;
