import styled from 'styled-components';

export const StyledDiv = styled.div`
  background: #181a21;
  color: ${({theme}) => (theme.name === 'light' ? '#252730' : '#F9F9F9')};
  z-index: 9997;
  height: 56px;
  width: 90vw;
  transform: translate(1px, 0px) !important;
  .row {
    margin-left: 0px;
  }
  .form-inline {
    position: absolute;
    left: 30px;
    transform: translate(-20px, 0px);
    height: 56px;
    background: #181a21;
    @media screen and (max-width: 576px) {
      padding-left: 8px;
    }
  }
  .input-group {
    padding-top: 12px;
    width: 83%;
    @media screen and (max-width: 576px) {
      padding-top: 10px;
    }
  }
  .close {
    position: absolute;
    right: 20px;
    top: 12px;
    float: right;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    @media screen and (max-width: 576px) {
      top: 8px;
      span {
        font-size: 28px;
      }
    }
  }
  .btn-primary:focus,
  .btn-primary.focus,
  .btn-primary:hover,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: transparent;
    border-color: none;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 768px) {
    width: 92vw;
  }
  @media screen and (max-width: 576px) {
    width: 100vw;
  }
`;

export const Badge = styled.button`
  border: none;
  background: transparent;
  .svg-inline--fa,
  .fa-search {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
  }
`;

export const SearchBar = styled.div`
  .form-control {
    background: rgba(255, 255, 255, 0.1);
    border-left: none;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    height: 32px;
    max-width: 400px;
    color: #fff !important;
    font-size: 14px;
  }
  .form-control::placeholder {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
  }
  .input-group-prepend {
    margin-right: 0px;
  }
  .input-group .form-control:not(:first-child),
  .input-group .custom-select:not(:first-child) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .search-button {
    height: 32px;
    padding: 0.4rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    border-right: none;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    z-index: 9999;
  }
  .fa-search {
    background-color: none !important;
    margin-left: 0px !important;
    margin-right: -10px !important;
    font-size: 18px !important;
  }
  @media (min-width: 576px) and (max-width: 768px) {
    .form-control {
      max-width: 700px;
    }
  }
`;
