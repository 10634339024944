import styled from 'styled-components';

export const Label = styled.label`
  /* Adapt the colors based on primary prop */
  box-sizing: border-box;
  height: 48px;
  width: 262px;
  border: 1px solid #8a8d90;
  border-radius: 8px;
  background-color: #2e3038;
  text-align: center;
  margin: 12px;
  padding: 13px;
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;

  input[type='radio'] {
    visibility: hidden;
  }
`;

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-size: 15px;

  .courseBorder-top {
    border-top: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  }
  .courseBorder-bottom {
    border-bottom: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  }
  /* Adapt the colors based on primary prop */
`;

export const SubscriptionCancelContainer = styled.div`
  background-color: #181a21;
  min-height: 100vh !important;
  width: 100vw;
  margin: auto;
  font-family: Roboto;
`;

export const Wrapper = styled.div`
  & .btn.active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
  }

  & .a_reasonOptions-div {
    margin-bottom: 57px;
  }

  & .btn {
    cursor: pointer;
  }

  & .a_ReasonOptions {
    & .btn-dark {
      border: 1px solid #8a8d90;
      background-color: #2e3038;
      color: #dcdee1 !important;
      letter-spacing: -0.1px;
      line-height: 20px;
    }
    & .btn-primary {
      color: #ffffff;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: -0.1px;
      line-height: 20px;
    }
  }

  & .a_btn-reason {
    border-radius: 8px !important;
    font-size: 15px;
    letter-spacing: -0.1px;

    &:hover,
    &:focus {
      border-color: #0062cc;
    }
  }

  & .a_gotoNextButton {
    & .btn-dark {
      color: #b8bbbe !important;
      opacity: 0.5;
      border-radius: 4px;
      background-color: #4e505a;
    }
  }

  & .a_feedback_buttons {
    & .btn-dark {
      color: #b8bbbe !important;
      opacity: 0.5;
      border-radius: 4px;
      background-color: #4e505a;
    }
  }

  & .btn-bottom {
    height: 48px;
    width: 80px;
    font-weight: bold;
    letter-spacing: -0.11px;
    text-align: center;
    color: #ffffff;
    padding: 15px 0px 13px;
    line-height: 16px;
    margin-top: 22px;
  }

  & .btn-back {
    border: 1px solid #4e505a;
  }
`;

export const Textarea = styled.textarea`
  box-sizing: border-box;
  height: 200px;
  width: 300px;
  border: 1px solid #0055ff;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 12px;
  margin-top: 6px;
  margin-bottom: 18px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  color: #919699;

  &:focus {
    outline: navajowhite;
  }
`;

export const ReasonHeading = styled.h4`
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.33px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 14px;
`;

export const ReasonCaption = styled.p`
  color: #dcdee1;
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  text-align: center;
`;
