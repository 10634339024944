import styled from 'styled-components';
import {Caption} from '../../../../utils/styles/typography';
import darkTheme from '../../../../utils/styles/darkTheme';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll-hidden;
  .a_iconDiv {
    height: 50px;
    width: 50px;
    background-color: #35cb95;
    border-radius: 30px;
    margin-top: 24px;
  }

  .a_cmeIcon {
    width: 33px;
    height: 33px;
  }

  .a_title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.33px;
    line-height: 21px;
    margin-bottom: 19px;
    margin-top: 8px;
  }

  .a_cmeIconB {
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }

  .a_completed {
    background: #2e3038;
    color: #35cb95;
    border: 2px solid #35cb95;
    height: 32px;
    width: 167px;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 22px;
    margin-top: 4px;
  }

  .a_completed span {
    height: 16px;
    width: 116px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.11px;
    line-height: 16px;
    margin-bottom: 9px;
    margin-top: 7px;
    text-align: center;
  }

  .a_completed svg {
    height: 16px;
    width: 16px;
    color: #35cb95;
    font-size: 16px;
    margin-left: 6px;
    line-height: 16px;
    text-align: center;
  }

  .question-block h5 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
    margin-bottom: 7px;
  }
  .question-block h6 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
  }
  .question-block p {
    margin-bottom: 5px;
  }

  .question-block p {
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 20px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  }

  .icon-download {
    margin-right: 8px;
  }

  .a_download {
    display: flex;
    background: #0dfff3;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    border: 0px;
    height: 30px;
    padding: 8px;
    gap: 7px;
    box-sizing: border-box;
    margin-bottom: 22px;
    margin-right: 12px;
  }

  .a_download span {
    color: #181a21;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.11px;
    line-height: 14px;
  }

  .a_download:hover {
    display: flex;
    padding: 8px;
    opacity: 0.8;
  }

  .a_download:active {
    opacity: 0.8;
  }

  .a_view_credit {
    display: flex;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 8px;
    min-width: 80px;
  }

  .credits_count {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.12px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 8px;
  }

  .a_cmeLogoText {
    font-size: 14px;
  }

  .a_cmeUpgradeHeading {
    color: ${({theme}) => theme.text};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
    text-align: center;
    margin-top: 9px;
    margin-bottom: 2px;
  }

  .a_cmeUpgradeText {
    color: ${({theme}) => theme.text1};
    width: 276px;
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
    text-align: center;
  }

  .a_upgradeButton {
    // Beta
    height: 32px;
    width: 194px;
    // height: 32px;
    // width: 105px;
  }

  h5 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
  }

  .cme-content-container {
    overflow: auto;
    flex: 1 1 1px;
    @media screen and (max-width: 576px) {
      padding-bottom: 250px;
    }
  }
  .a_textArea {
    margin-top: 11px;
    padding: 12px;
    background: ${({theme}) => theme.content_basic_bg};
    border-color: ${({theme}) => theme.content_border};
    min-height: 100px;
    margin-bottom: 42px;
  }

  .a_textArea:focus,
  .bgTextarea {
    background: #eaecf1;
    font-size: 14px;
    color: #4e505a;
    min-height: 126px;
    border: none;
    outline: none;
    margin-bottom: 16px;
  }

  .a_textArea::placeholder {
    font-size: 14px;
    color: #b8bbbe;
  }

  .cme-content-container .a_cmeDescription {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.14px;
    line-height: 19px;
    text-decoration: underline;
    padding-left: 0;
  }

  .progress {
    height: 8px;
    margin-bottom: 31px;
  }
`;

export const Disclaimer = styled(Caption)`
  color: ${darkTheme.teal};
  position: static;
  width: 368px;
  height: 24px;
  left: 0px;
  top: 18px;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.09px;
  line-height: 18px;
`;

export const DescriptionContainer = styled.div`
  margin-top: 35px;

  h6 {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.09px;
    line-height: 20px;
    margin-bottom: 0.2rem;
  }
  p {
    color: ${({theme}) => theme.text1};
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 20px;
  }
  .accreditation {
    height: 82px;
    width: 64px;
    display: block;
  }
  .subheader {
    text-decoration: underline;
    margin-bottom: 0rem;
  }
  .credit {
    font-style: italic;
    font-size: 14px;
  }
  .image {
    height: 105px;
    width: 153px;
    border-radius: 3px;
  }
  .accreditation {
    height: 105px;
    width: 153px;
    display: block;
  }
  .expert {
    margin-bottom: 0;
  }
  .bibliography,
  .learning-objectives {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 1.5em;
    text-indent: -1em;
    &:last-child {
      margin-bottom: 1em;
    }
  }
  .disclosure {
    text-decoration: underline;
  }
  .date {
    margin-bottom: 0rem;
  }
  .header {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
  .table tr {
    td {
      padding: 0px 0px 0px 00px;
    }
    &:nth-child(4) {
      td {
        padding: 0px 0px 10px 0px;
      }
    }
  }
  .table {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 8px;
    margin-bottom: 8px;
    &:last-child {
      border-bottom: 0px;
    }
  }
`;

export const QuestionDiv = styled.div`
  h5 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.33px;
    line-height: 21px;
    margin-bottom: 19px;
  }
  .question-block h5 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
    margin-bottom: 7px;
  }
  .question-block h6 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
  }
  .question-block p {
    margin-bottom: 5px;
  }
  .question-block .form-group.radio-form {
    display: flex;
    justify-content: space-around;
    margin: 32px 0;
  }
  .question-block small {
    font-size: 12px;
    letter-spacing: -0.27px;
    line-height: 16px;
    margin-bottom: 17px;
    display: block;
  }
  .question-block .a_textArea {
    margin-top: 11px;
    padding: 12px;
    background: ${({theme}) => theme.content_basic_bg};
    border-color: ${({theme}) => theme.content_border};
    min-height: 126px;
    margin-bottom: 16px;
  }

  .question-block .a_textArea:focus,
  .question-block .bgTextarea {
    background: #eaecf1;
    font-size: 14px;
    color: #4e505a;
    min-height: 126px;
    border: none;
    outline: none;
    margin-bottom: 16px;
  }
  .question-block small.text-required,
  .question-block small.text-required-green {
    font-size: 12px;
    letter-spacing: -0.27px;
    line-height: 16px;
  }
  .question-block small.text-required {
    color: #e04463;
  }
  .question-block small.text-required-green {
    color: #35cb95;
  }
  .question-block p {
    font-size: 14px;
    letter-spacing: -0.09px;
    line-height: 20px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  }
  .a_save_quit {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    padding-left: 0;
  }
  .a_back,
  .a_download {
    color: ${({theme}) => theme.card_text};
    margin-right: 12px;
  }
  .a_back:hover {
    color: #fff;
  }
  .a_back,
  .a_save,
  .a_next,
  .a_download {
    padding: 3px 15px;
    min-width: 80px;
  }
  .question-block input:checked ~ label::before,
  .question-block .custom-control-label::before,
  .question-block .custom-control-label::after {
    left: calc(50% - 8px);
    border: #008aff solid 1px;
    border-radius: 50px;
  }

  .question-block .custom-control-label::before,
  .question-block .custom-control-label::after {
    background: #2e3038;
  }
  .question-block input:checked ~ label::before {
    background: #008aff;
  }
  .question-block input:checked ~ label::after {
    left: calc(50% - 3px);
    border: none;
    background: #2e3038 !important;
    border-radius: 50px;
    top: 9px;
    width: 6px;
    height: 6px;
  }
  .question-block .custom-control {
    padding-left: 0;
  }
  .question-block label {
    padding-top: 22px;
  }
  .a_cmeIcon {
    height: 64px;
    width: 64px;
    margin-right: 16px;
  }
  .credits_count {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.12px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 8px;
  }
  .progress {
    height: 8px;
    margin-bottom: 31px;
  }
  .choice {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
  }

  .question-block .choice label {
    padding-top: 6px;
  }

  .choice label {
    width: 100%;
    padding: 7px 10px;
  }
  .form-check {
    padding-left: 2.25em;
  }

  .question-block .choice input:checked ~ label::before,
  .question-block .choice .custom-control-label::before,
  .question-block .choice .custom-control-label::after {
    left: calc(50% - 8px);
    border: #008aff solid 1px;
    border-radius: 0px !important;
  }

  .question-block .choice .custom-control-label::before,
  .question-block .choice .custom-control-label::after {
    background: #2e3038;
    transform: translate(-175px, 0px) !important;
  }
  .question-block .choice input:checked ~ label::before {
    background: #008aff;
  }
  .question-block .choice input:checked ~ label::after {
    border: none;
    background: transparent !important;
    top: 4px !important;
    width: 16px;
    height: 16px;
    border-radius: 0px !important;
  }
  .choice .form-check-input {
    display: block;
  }
`;

export const FinishAssessment = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 4px 8px;
  width: 154px;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: linear-gradient(90deg, #08d1f8 0%, #008aff 100%);
  border-radius: 3px;
  :hover {
    opacity: 0.8;
  }
  span {
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const StartAssessment = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px;
  width: 147px;
  height: 30px;
  padding: 4px 8px;
  background: ${props => (props.watched ? '#008aff' : 'rgba(255, 255, 255, 0.1)')};
  border-radius: 3px;
  :hover {
    opacity: 0.8;
  }
  span {
    color: ${props => (props.watched ? '#ffffff' : 'rgba(255, 255, 255, 0.4)')};
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  }
`;

export const CMEStatusDiv = styled.div`
  margin-bottom: 10px;
  #completed {
    color: #0dfff3;
  }
  #revoked {
    color: #ff5964;
  }
`;

export const CMEStatusTextDiv = styled.div`
  .credit-count {
    font-weight: 700;
  }
`;

export const Cast = styled.li`
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;
`;
