// @flow
import axios from 'axios';
import {makeAutoObservable, runInAction} from 'mobx';
import getSearchResults from '../../api/search';
import {getCourse} from '../../api/courseLanding';
import {getExpert} from '../../api/expert';
import {getOrganization, getSortedOrganizations} from '../../api/organization';
import {getVideo, getContentTypeList} from '../../api/video';
import {getConferences} from '../../api/conference';
import ErrorStore from '../error/errorStore';
import MediaStore from '../media/mediaStore';
import {getSpecialties} from '../../api/course';

const {CancelToken} = axios;

class SearchStore {
  errorStore: ErrorStore;

  mediaStore: MediaStore;

  searchText: string = '';

  isRestrictSearchText: boolean = false;

  searchResults: any[] = [];

  organizationResults: any[] = [];

  conferenceResults: any[] = [];

  courseResults: any[] = [];

  expertResults: any[] = [];

  videoResults: any[] = [];

  transcriptResults: any[] = [];

  suggestions: any[] = [];

  isLoading: boolean = false;

  isSuggestionLoading: boolean = false;

  isResultsLoading: boolean = false;

  isResultsEmpty: boolean = false;

  hasMoreVideos: boolean = true;

  transcriptCount = 0;

  allVideoResults: any[] = [];

  source;

  suggestedSearchTerm = '';

  contentSubtype = '';

  isVR: boolean = false;

  organizationsList: Array = [];

  selectedContenttypeList: Array = [];

  selectedOrganizationList: Array = [];

  specialtiesList: Array = [];

  subSpecialtiesList: Array = [];

  selectedSpecialtiesList: Array = [];

  selectedSubSpecialtiesList: Array = [];

  selectedContenttypes: Array = [];

  selectedOrganizationIds: Array = [];

  selectedSpecialtyIds: Array = [];

  selectedSubSpecialtyIds: Array = [];

  contenttypeList: Array = [];

  page: number = 1;

  count: number = 25;

  videoCount: Number = 0;

  specialtyList: Array = [];

  constructor({errorStore, mediaStore}) {
    this.errorStore = errorStore;
    this.mediaStore = mediaStore;
    makeAutoObservable(this);
    this.source = CancelToken.source();
  }

  storeSearchText = (value: string) => {
    this.searchText = value;
  };

  setIsRestrictSearchText = (value: boolean) => {
    this.isRestrictSearchText = value;
  };

  setConferencesObservables = result => {
    this.conferenceResults = result.filter(item => item.type === 'conference')[0]?.values;
  };

  setCoursesObservables = result => {
    this.courseResults = result.filter(item => item.type === 'course')[0]?.values;
  };

  setExpertsObservables = result => {
    this.expertResults = result.filter(item => item.type === 'expert')[0]?.values;
  };

  setVideosObservables = result => {
    const videos = result.filter(item => item.type === 'video')[0]?.values;
    this.videoResults = videos;
  };

  resetSearch = () => {
    this.isSuggestionLoading = false;
    this.searchResults = [];
    this.suggestions = [];
  };

  setVR = value => {
    this.isVR = value;
    this.page = 1;
  };

  resetPage = () => {
    this.page = 1;
  };

  pageIncrement = () => {
    this.page += 1;
  };

  pageDecrement = () => {
    this.page -= 1;
  };

  changeCount = option => {
    this.count = option;
    this.page = 1;
  };

  // remove duplicates from subspecialties
  removeDuplicates = () => {
    this.subSpecialtiesList = [...new Map(this.subSpecialtiesList.map(item => [item.id, item])).values()];
  };

  setSpecialtyPageSubspecialty = subspecialty => {
    this.subSpecialtiesList = subspecialty;
  };

  setSelectedContenttype = Ids => {
    this.page = 1;
    this.selectedContenttypes = Ids;
    this.selectedContenttypeList = this.contenttypeList.filter(item => Ids.includes(item.id));
  };

  setSelectedOrganizations = Ids => {
    this.page = 1;
    this.selectedOrganizationIds = Ids;
    this.selectedOrganizationList = this.organizationsList.filter(item => Ids.includes(item.id));
  };

  setSelectedSpecialties = Ids => {
    this.page = 1;
    this.selectedSpecialtyIds = Ids;
    this.selectedSpecialtiesList = this.specialtiesList?.filter(item => Ids.includes(item.id));
    this.mediaStore.setSubSpecialtyList(this.selectedSpecialtiesList, this.selectedSubSpecialtiesList);
    this.subSpecialtiesList = this.mediaStore.subSpecialtiesList;
  };

  setSelectedSubSpecialties = Ids => {
    this.page = 1;
    this.selectedSubSpecialtyIds = Ids;
    this.selectedSubSpecialtiesList = this.subSpecialtiesList.filter(item => Ids.includes(item.id));
  };

  setSpecialtiesObservables = specialties => {
    this.specialtiesList = specialties;
  };

  /**
   * set course providers list observables
   * @param {array} organizations
   */
  setOrganizationsObservables = organizations => {
    this.organizationsList = organizations;
  };

  /** Fetch All specialties */
  listSpecialties = async () => {
    try {
      const resp = await getSpecialties();
      let {specialties} = resp.data;
      specialties = specialties.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setSpecialtiesObservables(specialties);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All organizations */
  listOrganizations = async () => {
    try {
      const resp = await getSortedOrganizations('DESC');
      let {organizations} = resp.data;
      organizations = organizations.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setOrganizationsObservables(organizations);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onRemoveContenttype = id => {
    this.page = 1;
    this.selectedContenttypeList = this.selectedContenttypeList.filter(item => item.id !== id);
    this.selectedContenttypes = this.selectedContenttypes.filter(item => item !== id);
  };

  onRemoveOrganization = id => {
    this.page = 1;
    this.selectedOrganizationList = this.selectedOrganizationList.filter(item => item.id !== id);
    this.selectedOrganizationIds = this.selectedOrganizationIds.filter(item => item !== id);
  };

  /** Remove Specialty */
  onRemoveSpecialty = id => {
    this.page = 1;
    this.selectedSpecialtiesList = this.selectedSpecialtiesList.filter(item => item.id !== id);
    this.selectedSpecialtyIds = this.selectedSpecialtyIds.filter(item => item !== id);
    this.mediaStore.setSubSpecialtyList(this.selectedSpecialtiesList, this.selectedSubSpecialtiesList);
    this.subSpecialtiesList = this.mediaStore.subSpecialtiesList;
  };

  onRemoveSubSpecialty = id => {
    this.page = 1;
    this.selectedSubSpecialtiesList = this.selectedSubSpecialtiesList.filter(item => item.id !== id);
    this.selectedSubSpecialtyIds = this.selectedSubSpecialtyIds.filter(item => item !== id);
  };

  // remove related specialty when sub specialty is selected
  removeSpecialtyOnSelectSubspecialty = () => {
    const sp = this.selectedSpecialtiesList.filter(x =>
      x.children.every(item => !this.selectedSubSpecialtiesList?.includes(item)),
    );
    this.specialtyList = sp.map(item => item.id);
  };

  listContentTypes = async () => {
    this.contenttypeList = [];
    const res = await getContentTypeList();
    const content_subtype = res?.data?.content_subtype;
    if (content_subtype.length) {
      content_subtype.forEach(
        (item, index) =>
          item.content_subtype !== null &&
          item.content_subtype !== '' &&
          this.contenttypeList.push({id: index, name: item}),
      );
    }
  };

  fetchSearchResults = async (text: string, suggest: boolean) => {
    if (!this.isRestrictSearchText) {
      this.isSuggestionLoading = true;
      this.searchResults = [];
      this.suggestions = [];

      const types = ['organization', 'expert', 'video', 'conference', 'course', 'transcript'];
      const cmeValue = null;
      const sortBy = null;
      const offset = 0;
      const count = 3;
      try {
        const res = await getSearchResults(text, types, cmeValue, sortBy, offset, count, suggest);
        const {status, result} = res.data;
        runInAction(() => {
          this.isSuggestionLoading = false;
          if (status) {
            this.suggestions = [];
            this.searchResults = result;
            result.map(item => {
              if (item.values.length > 0) {
                if (item.type === 'video') {
                  this.suggestions = [...this.suggestions, ...item.values];
                } else {
                  this.suggestions = [...this.suggestions, item.values[0]];
                }
              }
              return item;
            });
          }
          this.isSuggestionLoading = false;
        });
      } catch (error) {
        this.errorStore.setError(error);
      }
    } else this.isSuggestionLoading = false;
  };

  fetchItem = async (type: string, carouselId: number, itemId: number, storeVar: string = null) => {
    this.source?.cancel('Operation canceled by nextItem.');
    this.source = CancelToken.source();
    let itemList = [];
    let item = null;
    try {
      switch (type) {
        case 'videos':
          item = await getVideo(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.video || null);
          itemList = !storeVar ? this.videoResults : this[storeVar];
          break;
        case 'expert':
          item = await getExpert(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.expert || null);
          itemList = this.expertResults;
          break;
        case 'organization':
          item = await getOrganization(itemId, {onHover: true}, this.source?.token).then(
            res => res?.data?.organization || null,
          );
          itemList = this.organizationResults;
          break;
        case 'conference':
          item = await getConferences(itemId, {onHover: true}, this.source?.token).then(
            res => res?.data?.conference || null,
          );
          itemList = this.conferenceResults;
          break;
        case 'courses':
          item = await getCourse(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.course || null);
          itemList = this.courseResults;
          break;
        default:
          break;
      }
      this.updateItem(itemList, item, storeVar?.includes('transcript'));
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updateItem = (itemList: any[], item: any, isTranscript: boolean = null) => {
    if (isTranscript) {
      this.transcriptResults = [...this.transcriptResults].map(transcript => {
        if (+transcript?.video?.id === +item?.id) {
          return {
            ...transcript,
            video: {
              isExtraDataLoaded: true,
              ...item,
            },
          };
        }
        return transcript;
      });
    } else {
      const itemIndex = itemList.findIndex(listItem => +listItem?.id === +item?.id);
      if (itemIndex !== -1) {
        /* eslint-disable no-param-reassign */
        itemList[itemIndex] = {
          isExtraDataLoaded: true,
          ...item,
        };
      }
    }
  };

  setContentSubtype = value => {
    this.contentSubtype = value;
  };

  getAllSearchResults = async (text: string, cmeValue: string) => {
    this.isResultsLoading = true;
    this.searchText = text;
    this.organizationResults = [];
    this.transcriptResults = [];
    this.courseResults = [];
    this.expertResults = [];
    this.videoResults = [];
    const content_subtype = this.contentSubtype;

    const types = ['organization', 'expert', 'video', 'conference', 'course', 'transcript'];
    const sortBy = null;
    const offset = 0;
    try {
      const res = await getSearchResults(text, types, cmeValue, sortBy, offset, null, null, content_subtype);
      const {status, result} = res.data;
      runInAction(() => {
        this.isResultsLoading = false;
        if (status) {
          this.organizationResults = result.filter(item => item.type === 'organization')[0]?.values;
          this.transcriptResults = result.filter(item => item.type === 'transcript')[0]?.values;
          this.setConferencesObservables(result);
          this.setCoursesObservables(result);
          this.setExpertsObservables(result);
          this.setVideosObservables(result);
        }
        if (!result || !result.length) {
          this.isResultsEmpty = true;
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getConferenceResults = async (text: string, offset: number, sortBy: string) => {
    this.isLoading = true;
    this.searchText = text;
    if (!offset) {
      this.conferenceResults = [];
    }
    const types = ['conference'];
    const cmeValue = null;
    const count = 9;
    try {
      const res = await getSearchResults(text, types, cmeValue, sortBy, offset, count);
      runInAction(() => {
        this.isLoading = false;
        const {status, result} = res.data;
        if (status) {
          if (!offset) {
            this.setConferencesObservables(result);
          } else {
            const conferences = result.filter(item => item.type === 'conference')[0]?.values;
            if (conferences?.length) {
              this.conferenceResults = [...this.conferenceResults, ...conferences];
            }
          }
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getCourseResults = async (text: string, offset: number, sortBy: string) => {
    this.isLoading = true;
    this.searchText = text;
    if (!offset) {
      this.videoResults = [];
    }
    const types = ['course'];
    const cmeValue = null;
    const count = 9;
    try {
      const res = await getSearchResults(text, types, cmeValue, sortBy, offset, count);
      runInAction(() => {
        this.isLoading = false;
        const {status, result} = res.data;
        if (status) {
          if (!offset) {
            this.setCoursesObservables(result);
          } else {
            const courses = result.filter(item => item.type === 'course')[0]?.values;
            if (courses?.length) {
              this.courseResults = [...this.courseResults, ...courses];
            }
          }
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getExpertResults = async (text: string, offset: number, sortBy: string) => {
    this.isLoading = true;
    this.searchText = text;
    if (!offset) {
      this.expertResults = [];
    }
    const types = ['expert'];
    const cmeValue = null;
    const count = 30;
    try {
      const res = await getSearchResults(text, types, cmeValue, sortBy, offset, count);
      runInAction(() => {
        this.isLoading = false;
        const {status, result} = res.data;
        if (status) {
          if (!offset) {
            this.setExpertsObservables(result);
          } else {
            const experts = result.filter(item => item.type === 'expert')[0]?.values;
            if (experts?.length) {
              this.expertResults = [...this.expertResults, ...experts];
            }
          }
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setVideosLoadedObservable = result => {
    this.allVideoResults = result;
  };

  setHasMoreVideos = value => {
    this.hasMoreVideos = value;
  };

  getVideoResults = async (text: string, offset: number, cmeValue: string, sortBy: string) => {
    this.isLoading = true;
    this.removeSpecialtyOnSelectSubspecialty();
    this.searchText = text;
    if (!offset) {
      this.allVideoResults = [];
    }
    const types = ['video'];
    try {
      const res = await getSearchResults(
        text,
        types,
        cmeValue,
        sortBy,
        this.count * (this.page - 1),
        this.count,
        null,
        this.selectedContenttypeList.map(item => item.name),
        this.isVR,
        this.specialtyList.concat(this.selectedSubSpecialtyIds),
        this.selectedOrganizationIds,
      );
      runInAction(() => {
        this.isLoading = false;
        const {status, result} = res.data;
        if (status) {
          const videos = result.filter(item => item.type === 'video')[0]?.values;
          this.videoCount = result[0].count;
          if (videos?.length) {
            const videosExtend = [...videos];
            this.setVideosLoadedObservable(videosExtend);
            this.setHasMoreVideos(true);
          } else {
            this.setHasMoreVideos(false);
          }
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  scrollVideoResults = async (text: string, offset: number, cmeValue: string, sortBy: string) => {
    if (!this.isLoading && this.hasMoreVideos) {
      this.getVideoResults(text, offset, cmeValue, sortBy);
    }
  };

  getTranscriptResults = async (text: string, offset: number) => {
    this.isLoading = true;
    this.searchText = text;
    if (!offset) {
      this.transcriptResults = [];
    }
    const types = ['transcript'];
    const count = 20;

    try {
      const res = await getSearchResults(text, types, null, null, offset, count);
      const {status, result} = res.data;
      this.isLoading = false;
      if (status) {
        runInAction(() => {
          if (!offset) {
            this.transcriptResults = result.filter(item => item.type === 'transcript')[0]?.values;
          } else {
            const transcriptVideos = result.filter(item => item.type === 'transcript')[0]?.values;
            if (transcriptVideos?.length) {
              this.transcriptResults = [...this.transcriptResults, ...transcriptVideos];
            }
          }
          this.transcriptCount += 20;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setSuggestedSearchTerm = value => {
    this.suggestedSearchTerm = value;
  };
}

export default SearchStore;
