import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {Modal} from 'react-bootstrap';

export const AccountDiv = styled.div`
  .account-body {
    padding-bottom: 40px;
  }

  .__react_component_tooltip {
    width: auto;
    max-width: 239px;
    font-size: 12px;
    color: #2e3038;
    letter-spacing: -0.13px;
    line-height: 14px;
    text-align: center;
    background-color: #f5f499;
    border-radius: 8px;
    padding: 13px 15px;
  }

  .__react_component_tooltip.place-top::before {
    border-top-color: #f5f499;
  }

  .__react_component_tooltip.place-top::after {
    border-top-color: #f5f499;
  }

  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
`;

export const AccountHeader = styled.div`
  background: ${({theme}) => (theme.name === 'light' ? '#F9F9F9' : '#252730')};
  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};
  button {
    box-sizing: border-box;
    padding: 0.3em 0.6em;
    border: ${({theme}) => {
      const color = theme.name === 'light' ? '#B8BBBE' : '#4e505a';
      return `1px solid ${color}`;
    }};
    border-radius: 4px;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#ffffff')};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 22px;
  }
  .profile-image {
    background-color: #0677d8;
    color: #ffffff;
    border-radius: 50%;
    height: 72px;
    width: 72px;
    font-size: 32px;
  }
  .inviteAColleague {
    color: #fff;
    &hover {
      cursor: pointer;
    }
  }
`;

export const CancelSubscription = styled.a`
  margin-bottom: 40px;
`;

export const ProfileTab = styled.nav`
  background-color: ${({theme}) => (theme.name === 'light' ? '#f9f9f9' : '#252730')};
  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};
`;

export const NavItem = styled.li`
  width: auto !important;
`;

export const Link = styled(NavLink)`
  color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#DCDEE1')};
  font-size: 17px;
  letter-spacing: -0.17px;
  line-height: 20px;
  text-align: center;
  padding: 1em 0.5em;
  &.active {
    font-weight: bold;
    border-bottom: 4px var(--primary) solid;
  }
`;

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  padding-bottom: 2.5rem;
  padding-top: 1.5rem;
  background: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#252730')};

  .icon {
    height: 48px;
    width: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 85px;
    padding: auto;
  }
  .icon-green .nav-link {
    padding-bottom: 5px;
  }
  .icon-pink {
    background: #e04463;
  }
  .icon-blue {
    background: #35afcb;
  }
  .icon-green {
    background: #35cb95;
  }
  .icon-orange {
    background: #ff6800;
  }
  .stat {
    color: ${({theme}) => (theme.name === 'light' ? '#2e3038' : '#FFFFF')};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
  }
  .title {
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#DCDEE1')};
    letter-spacing: -0.1px;
    line-height: 20px;
  }
  svg {
    color: #fff;
  }
  .icon .fa-cme-1 {
    width: 30px !important;
    height: 30px !important;
  }
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1em;
  margin: 1em auto;

  &:after {
    content: '';
    flex: auto;
  }
`;

export const SettingsGroup = styled.div`
  padding: 1em;
  border-radius: 8px;
  background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#252730')};

  .settings-group-header {
    display: flex;
    padding: 0 0 1em 0;
    justify-content: space-between;
  }
  .settings-group-header-label {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
  }
`;

export const UpdateButton = styled.button`
  border: none;
  background: none;
  color: #30a0ff;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
`;

export const UpdateModal = styled(Modal)`
  .modal-dialog {
    max-width: 700px;
    margin: 7.75rem auto !important;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;

    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-footer {
      border: none !important;
    }
    .close {
      color: ${({theme}) => theme.circle};
    }
  }
  &.paymentInfoModal .modal-dialog {
    max-width: 636px;
  }
  .account-email {
    padding: 1rem 1rem;
  }
  .account-email-header-label {
    font-size: 18px;
    // font-weight: bold;
    letter-spacing: -0.3px;
    line-height: 24px;
  }
`;
export const LabelDiv = styled.div`
  padding: 0.25em 0;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.16px;
  line-height: 20px;
`;

export const ValueDiv = styled.div`
  padding: 0.25em 0;
  color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const FieldName = styled.div`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0.3em 0;
`;

export const ModalInput = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #4e505a;
  border-radius: 20px;
  background-color: #eaecf1;

  &:focus {
    outline: none;
  }
`;

export const UpdateLink = styled.a`
  text-decoration: none;
  color: #30a0ff;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
`;

export const SubscriptionStatus = styled.div`
  display: flex;
  align-items: center;

  .a_reactivate {
    height: 32px;
    width: 106px;
    padding: 0;
  }

  .a_skipTrial {
    height: 24px;
    width: 87px;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 16px;
  }
`;

export const SubscriptionUpgradeContainer = styled.div`
  display: flex;
  background-color: transparent;
  padding-top: 20px;

  @media screen and (min-width: 576px) {
    padding-top: 56px;
  }
`;

export const UpgradeModal = styled(Modal)`
  .modal-dialog {
    max-width: 459px !important;
    min-height: 225px !important;
    margin: 7.75rem auto !important;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;

    .a_upgradeModalContent {
      color: #ffffff;
      font-size: 12px;
      letter-spacing: -0.2px;
      line-height: 24px;
      margin-bottom: 37px;
      .a_upgradePlanEmail {
        color: #0677d8;
      }
    }
    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-body {
      padding-top: 14px;
      padding-bottom: 22px;
    }
    .modal-footer {
      border: none !important;
    }
    .a_closeUpgradeInfoButton {
      width: 73px;
      height: 48px;
    }
  }
  &.paymentInfoModal .modal-dialog {
    max-width: 636px;
  }
`;
export const DocumentsFieldStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 16px 0;
  .browseButton {
    padding: 4px 24px !important;
    background: #0677d8;

    border: none;
    color: #fff;
    text-align: center;
  }

  .TaskDiv {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: #60686f;
    height: 50px;
    justify-content: space-between;
    background: #050507;
    border-radius: 8px;
  }
  .dropText {
    margin-bottom: 0 !important;
    color: #60686f;
    font-weight: 400;
  }
`;
export const FormStyles = styled.div`
  padding: 3%;
  position: relative;
  input,
  .react-datepicker-wrapper {
    width: 100%;
    height: 48px;
    border: none;
    background: #050507;
    color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    :focus {
      background: #050507;
      color: #fff;
    }
    :disabled {
      background: #050507;
      color: #fff;
    }
  }
  .react-datepicker-wrapper {
    margin-top: 25px;
    input {
      padding: 0 15px;
      &::placeholder {
        color: #60686f !important;
      }
    }
  }

  & .react-datepicker {
    & .react-datepicker__triangle {
      left: 220px !important;
    }
  }
  .buttonContainer {
    background: #181a21;
    width: 45%;
    height: 50px;
    border-radius: 16px;
    border: none;
    font-weight: 600;
    color: #fff;
    :hover {
      background: linear-gradient(58deg, rgb(4 25 83) 13%, rgb(44 62 80 / 82%) 91%);
    }
  }
  .a_textArea {
    background: #050507;
    color: #fff;
    border: none;
    :focus {
      background: #050507;
      color: #fff;
    }
  }
  .formTabDiv {
    margin-top: 10px;
  }

  .terms-span {
    color: #60686f;
    font-size: 14px;
  }
  .jvucNG {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .progressBarContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: #000;
    opacity: 0.8;
  }
`;
export const VideoUploadStyles = styled.div`
  background: #252730;
  border-radius: 16px;
  & .form-control {
    margin: 25px 0 0 0;
    height: 48px;
  }
  option {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 100%;
    max-height: 200px;
    overflow: auto;
    text-align: left;
    color: #fff;
  }
  .dropdown {
    background: #050507;
    border-radius: 8px;
  }
  & .dropdown-item {
    background-color: #2f3137;
    color: white;
    font-weight: 300;

    font-size: 14px;
    border: 1px solid #2f3137;
    padding: 3px 0;
    :hover {
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid #008aff;
    }
  }
  .browseButton {
    padding: 3px 30px;
    border-radius: 4px;
    background: #0677d8;
    border: none;
    color: #fff;
    text-align: center;
  }
  select {
    border: none;
  }
  & .form-group {
    margin: 0;
  }
  .ErrorClass {
    color: red;
  }
`;

export const MessageStyles = styled.div`
  width: 100%;
  height: calc(100vh - 350px);
  background: #212529;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  .messageContent {
    width: 100%;
    height: 40px;
    font-size: 14px;
    margin-bottom: 8px;
    text-align-last: start;
    color: #f9f9f9;
    border: none;
    &.selected,
    :hover {
      background: linear-gradient(90deg, #008aff 0%, #9043ff 100%);
      width: 100%;
    }
    &.boldReceiver {
      font-weight: 600;
    }
  }
  .btn-primary {
    background: none;
  }
  .btn-primary:active,
  .&focus {
    background: #f9f9f9;
    width: 100%;
  }
  .messageText {
    padding-left: 10px;
    padding-top: 5px;
    color: #000;
    font-size: 14px;
  }
  .receiverDiv {
    margin: 8px 43px;
    width: 200px;
    background: #ffe5e5;
    border-radius: 8px;
    min-height: 30px;
  }
  .submitBtn {
    background: #007bff;
    border-radius: 8px;
    width: 100px;
    border: none;
    padding: 7px;
    height: 38px;
  }
  .messageBoxDiv {
    background: #181a21;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    right: 0;
    padding-left: 10px;
    height: 100%;
  }
  .messageDiv {
    margin: 8px 43px;
    width: 200px;
    background: #ffe5e5;
    border-radius: 8px;
    margin-left: auto;
    right: 0;
    min-height: 30px;
  }
  .userDiv {
    display: flex;
    flex-direction: column;
    width: 24%;
    background: #050507;
    padding: 10px;
    overflow-y: scroll;
    height: 100%;
    &::-webkit-scrollbar {
      width: 4px;
      min-height: 100px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  textarea {
    border-radius: 8px;
    width: 100%;
    border: none;
    padding: 7px;
    height: 38px;
    :focus {
      outline: none;
    }
  }
  .formDiv {
    bottom: 0;
    margin-bottom: 8px;
  }
  .scrollBar {
    overflow-y: scroll;
    height: 90%;
    padding-bottom: 15px;
    &::-webkit-scrollbar {
      width: 4px;
      min-height: 100px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
  .inputDiv {
    width: calc(100% - 130px);
  }
  .notSelectUserDiv {
    background: #181a21;
    width: calc(100% - 100px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 10px;
    height: 100%;
  }
  .extIcon {
    width: 25px;
    height: 25px;
    padding: 3px;
    color: grey;
  }
`;
