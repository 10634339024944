import {makeAutoObservable} from 'mobx';

export default class SidebarUIStore {
  show = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShow(show) {
    this.show = show;
  }
}
