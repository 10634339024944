import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';
import {Row, Col, Container, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {Main, Breadcrumbs, PageTitle, ActiveCrumb, Crumb, CourseFilter, Line} from './elements/styles';
import OrganizationTile from '../../components/OrganizationTile';
import {useStore} from '../../store';
import Sort from '../../components/Sort';
import Pagination from '../../components/Pagination';
import PageBottom from '../../components/PageBottom';

const Organizations = observer(() => {
  const {organizationStore} = useStore();
  const location = useHistory();

  const [sortType, setSortType] = useState('order');
  const [sortBy, setSortBy] = useState('ASC');

  const {
    organizationList,
    listOrganizations,
    onFollowOrganization,
    onUnfollowOrganization,
    loading,
    organizationPage,
    organizationCount,
    count,
    pageDecrement,
    pageIncrement,
    changeCount,
  } = organizationStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    listOrganizations(sortBy, sortType, false, false);
  }, [listOrganizations, sortBy, sortType, organizationPage, count]);

  const changeSort = option => {
    switch (option) {
      case 'Featured':
        setSortType('order');
        setSortBy('ASC');
        break;
      case 'Name-ASC':
        setSortType('name');
        setSortBy('ASC');
        break;
      case 'Name-DESC':
        setSortType('name');
        setSortBy('DESC');
        break;
      default:
        break;
    }
  };

  const onHandleFollow = item => {
    const user = localStorage.getItem('user');
    if (user) {
      if (item.is_following) {
        onUnfollowOrganization(item);
      } else {
        onFollowOrganization(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  const sortValues = [
    {text: 'Featured', id: 'Featured', order: 'ASC', sort: 'order'},
    {text: 'Name(A-Z)', id: 'Name-ASC', order: 'ASC', sort: 'name'},
    {text: 'Name(Z-A)', id: 'Name-DESC', order: 'DESC', sort: 'name'},
  ];

  return (
    <div className="d-flex">
      <Helmet
        title="GIBLIB | Organizations"
        meta={[
          {
            name: 'description',
            content: 'Learn from the top subject matter experts from the leading academic medical institutions.',
          },
          {
            property: 'og:title',
            content: 'GIBLIB | Organizations',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Sidebar />
      <div className="w-100">
        <Navbar />
        <Main>
          <div>
            <div className="d-flex container">
              <Breadcrumbs>
                <Crumb href="/home">Home</Crumb>
                <ActiveCrumb href="#">Organizations</ActiveCrumb>
              </Breadcrumbs>
            </div>

            <Container>
              <PageTitle>Organizations</PageTitle>
              <Line />
              <div className="organization-head">
                <CourseFilter>
                  <Sort change={changeSort} sort={sortValues} sortOrder={sortBy} sortBy={sortType} />
                  <Pagination
                    totalCount={organizationCount}
                    page={organizationPage}
                    countPerPage={count}
                    pageIncrement={pageIncrement}
                    pageDecrement={pageDecrement}
                    changeCount={changeCount}
                  />
                </CourseFilter>
              </div>

              <Row className="mt-4">
                {loading ? (
                  <Col className="text-center my-3" xs={12}>
                    <Spinner animation="grow" />
                  </Col>
                ) : (
                  <></>
                )}
                {!loading &&
                  organizationList?.map(el => (
                    <Col key={el.id} className="organization-col" xs={6} md={4} lg={3} xl={2}>
                      <OrganizationTile org={el} onFollow={onHandleFollow} storeName="organizationStore" />
                    </Col>
                  ))}
              </Row>
            </Container>
          </div>
          {!loading && <Footer />}
        </Main>
      </div>
      <PageBottom />
    </div>
  );
});

export default Organizations;
