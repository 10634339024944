import React from 'react';
import DarkModal from './style';
import image from '../../assets/icons/times.png';

type Props = {
  onHide: () => {},
  show: boolean,
};
const BrowserAlert = ({show, onHide}: Props) => {
  return (
    <DarkModal show={show} onHide={onHide} centered>
      {/* <DarkModal.Header closeButton /> */}
      <DarkModal.Body>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="w-100 mb-3">
            <h5 className="tbd-text mb-2">Unsupported Browser</h5>
            <button type="button" className="a_closeAlertModal" onClick={onHide}>
              <img src={image} alt="close_button" />
            </button>
          </div>
          <p className="tbd-sub-text mb-3">
            Your browser is not currently supported. For the best experience, please use the latest version of Chrome.
          </p>
        </div>
      </DarkModal.Body>
    </DarkModal>
  );
};

export default BrowserAlert;
