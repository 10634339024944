import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import CMEDefault from '../../../../assets/icons/CMEDefault.svg';
import CMEInProgress from '../../../../assets/icons/CMEInProgress.svg';
import CMECompleted from '../../../../assets/icons/CMECompleted.svg';
import CMERevoked from '../../../../assets/icons/CMERevoked.svg';
import {CMEStatusDiv, CMEStatusTextDiv} from './style';

const CMEStatusInfo = ({status, credit, answersLength, completed}) => {
  const [realStatus, setRealStatus] = useState(status);

  useEffect(() => {
    if (status === undefined && answersLength > 0) setRealStatus('In Progress');
    if (completed) setRealStatus('Completed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersLength, completed]);

  const renderCMEInfo = () => {
    switch (realStatus) {
      case 'In Progress':
        return (
          <>
            <object data={CMEInProgress} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />
            <CMEStatusTextDiv>
              <div className="credit-count">{credit}&nbsp;Credits</div>
              <div>{status}</div>
            </CMEStatusTextDiv>
          </>
        );
      case 'Completed':
        return (
          <>
            <object data={CMECompleted} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />
            <CMEStatusTextDiv id="completed">
              <div className="credit-count">{credit}&nbsp;Credits</div>
              <div>{status}</div>
            </CMEStatusTextDiv>
          </>
        );
      case 'Revoked':
        return (
          <>
            <object data={CMERevoked} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />
            <CMEStatusTextDiv id="revoked">
              <div className="credit-count">{credit}&nbsp;Credits</div>
              <div>{status}</div>
            </CMEStatusTextDiv>
          </>
        );
      default:
        return (
          <>
            <object data={CMEDefault} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />
            <CMEStatusTextDiv>
              <div className="credit-count">{credit}&nbsp;Credits</div>
            </CMEStatusTextDiv>
          </>
        );
    }
  };
  return <CMEStatusDiv className="lg-3 credits order-lg-0 order-0">{renderCMEInfo()}</CMEStatusDiv>;
};

CMEStatusInfo.propTypes = {
  status: PropTypes.string.isRequired,
  credit: PropTypes.number.isRequired,
  answersLength: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
};

export default CMEStatusInfo;
