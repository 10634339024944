/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import axios, {AxiosInstance} from 'axios';
import errorStore from '../store/error/errorStore';
import LocalStorageService from '../utils/LocalStorageService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

instance.interceptors.request.use(
  config => {
    const access_token = LocalStorageService.getAccessToken();
    if (access_token) {
      const item = {...config};
      item.headers.Authorization = `Bearer ${access_token}`;
      return item; // replace original with new instance
    }
    return config;
  },
  async error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const refreshToken = LocalStorageService.getRefreshToken();
    if (refreshToken) {
      const originalRequest = error?.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const res = await instance.post('/auth/refresh-token', {
          refresh_token: refreshToken,
        });
        if (res.status === 201) {
          const {access_token, refresh_token} = res?.data || {};
          if (access_token && refresh_token) {
            LocalStorageService.setToken({access_token, refresh_token});
          }
          axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
          return instance(originalRequest);
        }
      }
      if (error?.response?.status === 401 || originalRequest?.url === '/auth/refresh-token') {
        LocalStorageService.clearToken();
      }
    }
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }
    return Promise.reject(error);
  },
);

// If backend returns  503, redirect to maintenance page
instance.interceptors.response.use(
  response => response,
  async error => {
    if (error?.response?.status === 503) {
      window.location = '/503.html';
    }
    return Promise.reject(error);
  },
);

export default instance;
