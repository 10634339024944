import React, {useEffect, useState, useRef, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useForm} from 'react-hook-form';
import {Button, Form} from 'react-bootstrap';
import {useStore} from '../../../store';
import {MessageStyles} from '../elements/profile';
import LocalStorageService from '../../../utils/LocalStorageService';

const Messages = observer(() => {
  const {messageStore} = useStore();
  const {
    fetchUsers,
    receivers,
    fetchMessages,
    messages,
    sendDirectMessage,
    unreadMessageSenderIds,
    getS3urlForFile,
  } = messageStore;
  const [setId, setShowId] = useState('');
  const {register, handleSubmit, reset} = useForm({});
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loggedInUser = LocalStorageService.getUser();
  const [file, setFile] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    if (setId) {
      fetchMessages(setId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setId]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
  };

  const onSubmitMessageForm = useCallback(
    value => {
      const formData = new FormData();
      formData.append('content', value.content);
      formData.append('receiver_id', setId);
      formData.append('file', file);
      if (value.content || file) {
        sendDirectMessage(
          formData,
          () => {
            reset();
            setFile('');
            setError('');
          },
          () => setError('Invalid file format'),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendDirectMessage, setId, reset, scrollToBottom],
  );

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, onSubmitMessageForm]);

  const handleClick = item => {
    getS3urlForFile(
      item?.attachment?.substring(item?.attachment?.lastIndexOf('/') + 1),
      'getObject',
      async signedURL => {
        window.open(signedURL);
      },
    );
  };
  const handleKeyPress = event => {
    const {key, shiftKey, target} = event;

    if (key === 'Enter') {
      if (shiftKey) {
        // Shift+Enter: Insert a new line
        event.preventDefault();
        const {selectionStart, selectionEnd, value} = target;
        const updatedValue = `${value.substring(0, selectionStart)}\n${value.substring(selectionEnd)}`;
        target.value = updatedValue;
        target.selectionStart = selectionStart + 1;
        target.selectionEnd = selectionStart + 1;
      } else {
        // Enter: Submit the form
        event.preventDefault();
        handleSubmit(onSubmitMessageForm)();
      }
    }
  };
  const changeHandler = event => {
    const fileObj = event.target.files[0];
    setFile(fileObj);
  };

  useEffect(() => {
    setError('');
  }, [file]);
  return (
    <>
      <MessageStyles>
        <div className="userDiv">
          {receivers.length ? (
            receivers?.map(item => (
              <>
                <Button
                  onClick={() => {
                    setShowId(item.id);
                  }}
                  className={`messageContent ${item.id === setId ? 'selected' : ''} ${
                    unreadMessageSenderIds?.includes(item.id) ? 'boldReceiver' : ''
                  }`}
                >
                  {item.last_name ? `${item.first_name} ${item.last_name}` : item.first_name}
                </Button>
              </>
            ))
          ) : (
            <div className="emptyField">No Messages Found </div>
          )}
        </div>
        {setId ? (
          <div className="messageBoxDiv">
            <div className="scrollBar">
              {messages?.map(item => (
                <>
                  {item?.content?.length !== 0 && (
                    <div
                      ref={messagesEndRef}
                      className={`${loggedInUser.id !== item?.sender_id ? 'receiverDiv' : 'messageDiv'} `}
                    >
                      <div className="messageText">{item.content}</div>
                    </div>
                  )}

                  {item?.attachment && (
                    <div className={`${loggedInUser.id !== item?.sender_id ? 'receiverDiv' : 'messageDiv'} `}>
                      <span className="extIcon">
                        <i className="fa fa-file" />
                      </span>
                      <button
                        style={{background: 'none', border: 'none'}}
                        type="button"
                        className="messageText imageIcon"
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        {item?.attachment?.substring(item?.attachment?.lastIndexOf('/') + 1)}
                      </button>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div
              className="formDiv"
              style={{
                width: '96%',
                zIndex: '1',
                padding: '8px',
              }}
            >
              <Form
                onSubmit={handleSubmit(onSubmitMessageForm)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div className="inputDiv">
                  <textarea
                    placeholder="Message"
                    className="inputField"
                    type="text"
                    name="content"
                    ref={register}
                    autoComplete="off"
                    onKeyPress={handleKeyPress}
                  />
                </div>

                <div style={{cursor: 'pointer', padding: '6px 9px'}}>
                  <label htmlFor="fileUpload" className="iconBtn">
                    <i className="far fa-paperclip fa-flip-vertical" />
                    <input type="file" id="fileUpload" onChange={changeHandler} className="d-none" ref={register} />
                  </label>
                </div>
                <div style={{marginRight: '10px'}}>
                  <Button className="submitBtn" type="submit">
                    Send
                  </Button>
                </div>
              </Form>
              <span>{file && <div>{file?.name}</div>}</span>
              {error && <span className="text-danger">{error}</span>}
            </div>
          </div>
        ) : (
          <div className="notSelectUserDiv">
            <div>Select a chat</div>
          </div>
        )}
      </MessageStyles>
    </>
  );
});

export default Messages;
