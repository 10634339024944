import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useStore} from '../../../store';
import Navbar from '../../../components/Navbar';
import Sidebar from '../../../components/Sidebar';
import AlertComponent from '../../../components/AlertComponent';
import PlaylistVideoCard from './playlistVideoCard';
import PlaylistInformation from './playlistInformation';
import {Main} from '../elements/playlist';
import LocalStorageService from '../../../utils/LocalStorageService';

// Playlist detail page
const playlistDetail = observer(() => {
  const match = useRouteMatch();
  const playlistId = match.params?.id;
  const location = useHistory();
  const {playlistStore, alertStore} = useStore();
  const {
    playlist: playlists,
    deleteConfirmation,
    massUpdatePlaylists: updatePlaylist,
    updatePlylistOrder,
    deletePlaylist,
    updatePlaylistname,
    getDeleteConfirmation,
    updateDeleteConfirmation,
  } = playlistStore;
  const {message, status, resetAlert} = alertStore;

  const loggedInUser = LocalStorageService.getUser();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getDeleteConfirmation();
  }, [getDeleteConfirmation]);

  const [currentPlaylist, setCurrentPlaylist] = useState(null);

  const editable = currentPlaylist?.user_id == loggedInUser?.id;

  useEffect(() => {
    playlistStore.getAllPlaylists();
  }, [playlistStore]);

  useEffect(() => {
    setCurrentPlaylist(playlistStore.findPlaylist(playlistId));
  }, [playlistId, playlistStore, playlists]);

  const [dragId, setDragId] = useState();
  const [videoList, setvideoList] = useState([]);

  useEffect(() => {
    setvideoList(currentPlaylist?.videos || []);
  }, [currentPlaylist]);

  const handleDrag = id => {
    setDragId(id);
  };

  const handleDrop = ev => {
    const {id: dropId} = ev.currentTarget;
    const dragBox = videoList.findIndex(video => +video.id === +dragId);
    const dropBox = videoList.findIndex(video => +video.id === +dropId);

    /* eslint-disable no-param-reassign */
    const newVideoState = videoList
      .map((video, index) => {
        if (dragBox < dropBox) {
          if (dragBox < index && index <= dropBox) {
            video.PlaylistVideo.order = index - 1;
          } else if (index === dragBox) {
            video.PlaylistVideo.order = dropBox;
          } else {
            video.PlaylistVideo.order = index;
          }
        }
        if (dragBox > dropBox) {
          if (dragBox > index && index >= dropBox) {
            video.PlaylistVideo.order = index + 1;
          } else if (index === dragBox) {
            video.PlaylistVideo.order = dropBox;
          } else {
            video.PlaylistVideo.order = index;
          }
        }
        return video;
      })
      .sort((a, b) => a.PlaylistVideo.order - b.PlaylistVideo.order);

    /* eslint-enable no-param-reassign */
    setvideoList(newVideoState);
    updatePlylistOrder(playlistId, newVideoState);
  };

  const handledeletePlayList = () => {
    if (loggedInUser) {
      deletePlaylist(parseInt(playlistId, 10));
      location.push('/playlist');
    } else location.push('/login');
  };

  const renamePlaylist = playlistName => {
    updatePlaylistname(parseInt(playlistId, 10), playlistName);
  };

  const updateExistingPlaylist = id => {
    updatePlaylist(id, [{playlistId: parseInt(playlistId, 10), value: false}]);
  };

  return (
    <>
      <div id="following" className="d-flex flex-column flex-fill overflow-hidden">
        <Navbar />
        <div className="d-flex">
          <Sidebar />
          <Main className="w-100 pt-0">
            {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
            <PlaylistInformation
              playlist={currentPlaylist}
              handledeletePlayList={handledeletePlayList}
              renamePlaylist={renamePlaylist}
            />

            {currentPlaylist?.videos.map(vList => (
              <PlaylistVideoCard
                key={vList.id}
                item={vList}
                handleDrag={handleDrag}
                handleDrop={handleDrop}
                updateExistingPlaylist={updateExistingPlaylist}
                deleteConfirmation={deleteConfirmation}
                updateDeleteConfirmation={updateDeleteConfirmation}
                editable={editable}
              />
            ))}
          </Main>
        </div>
      </div>
    </>
  );
});

export default playlistDetail;
