import React, {useRef} from 'react';
import {Form, Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import {DataDiv} from '../../elements/styles';
import {useStore} from '../../../../store';

const DataUpload = observer(() => {
  const {adminStore} = useStore();
  const {
    uploadCME,
    cmeStatus,
    cmeMessage,
    uploadVideo,
    videoStatus,
    videoMessage,
    videoUploading,
    cmeUploading,
    userUploading,
    userMessage,
    userStatus,
    uploadUser,
  } = adminStore;

  const hiddenCMEFileInput = useRef(null);
  const hiddenVideoFileInput = useRef(null);
  const hiddenUserFileInput = useRef(null);

  const handleCMEClick = () => {
    hiddenCMEFileInput.current.click();
  };

  const handleCMEChange = event => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      uploadCME(fileUploaded);
    }
  };

  const handleVideoClick = () => {
    hiddenVideoFileInput.current.click();
  };

  const handleVideoChange = event => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      uploadVideo(fileUploaded);
    }
  };

  const handleUserClick = () => {
    hiddenUserFileInput.current.click();
  };

  const handleUserChange = event => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      uploadUser(fileUploaded);
    }
  };

  return (
    <DataDiv>
      <Form>
        <Form.Label>Database: video</Form.Label>

        <Button className="a_upload a_video" onClick={handleVideoClick} disabled={videoUploading}>
          <span>Upload Excel Sheet</span>
          {videoUploading && (
            <div>
              Uploading...
              <i className="fas fa-circle-notch fa-spin" />
            </div>
          )}
        </Button>

        <Form.Control type="file" ref={hiddenVideoFileInput} onChange={handleVideoChange} />
        <p
          className={classNames({
            a_error: !videoStatus,
          })}
        >
          {videoMessage}
        </p>

        <Form.Label>Database: organization_video_cme</Form.Label>

        <Button className="a_upload a_org_video_cme" onClick={handleCMEClick} disabled={cmeUploading}>
          <span>Upload Excel Sheet</span>
          {cmeUploading && (
            <div>
              Uploading...
              <i className="fas fa-circle-notch fa-spin" />
            </div>
          )}
        </Button>
        <Form.Control type="file" ref={hiddenCMEFileInput} onChange={handleCMEChange} />
        <p
          className={classNames({
            a_error: !cmeStatus,
          })}
        >
          {cmeMessage}
        </p>

        <Form.Label>Database: user &amp; access_history</Form.Label>

        <Button className="a_upload a_org_video_user" onClick={handleUserClick} disabled={userUploading}>
          <span>Upload Excel Sheet</span>
          {userUploading && (
            <div>
              Uploading...
              <i className="fas fa-circle-notch fa-spin" />
            </div>
          )}
        </Button>
        <Form.Control type="file" ref={hiddenUserFileInput} onChange={handleUserChange} />
        <p
          className={classNames({
            a_error: !userStatus,
          })}
        >
          {userMessage}
        </p>
      </Form>
    </DataDiv>
  );
});

export default DataUpload;
