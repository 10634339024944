import {makeAutoObservable, runInAction} from 'mobx';
import {
  getProfessions,
  getSpecialties,
  getOrganizations,
  getCourseHeros,
  getCourses,
  createBookmark,
  deleteBookmark,
  followItem,
  unfollowItem,
} from '../../api/course';
import type {Course} from '../../utils/types';
import {getAllPlaylists} from '../../api/video';
import {addCoursePlaylist, addNewCoursePlaylist} from '../../api/playlist';
import {getSortedOrganizations} from '../../api/organization';
import ErrorStore from '../error/errorStore';
import MediaStore from '../media/mediaStore';
import AlertStore from '../alert/alertStore';
import PlaylistStore from '../playlist/playlistStore';

class CourseStore {
  errorStore: ErrorStore;

  mediaStore: MediaStore;

  alertStore: AlertStore;

  playlistStore: PlaylistStore;

  professionsList: Array = [];

  selectedProfessionList: Array = [];

  selectedProfessions: Array = [];

  selectedSpecialties: Array = [];

  specialtiesList: Array = [];

  courseSpecialtiesList: Array = [];

  selectedSpecialtiesList: Array = [];

  organizationsList: Array = [];

  selectedOrganizationList: Array = [];

  selectedOrganizations: Array = [];

  sortBy: String = 'created_at';

  sortOrder: String = 'DESC';

  isCme: Boolean = false;

  providersList: Array = [];

  coursesList: Array = [];

  courseHeros: Array = [];

  playlist: Array = [];

  courseCount: Number = 0;

  count: Number = 25;

  page: Number = 1;

  loading: Boolean = false;

  heroLoading: Boolean = false;

  course: Course = [];

  searchText: string = '';

  catalogLoading: Boolean = true;

  constructor({errorStore, mediaStore, alertStore, playlistStore}) {
    this.errorStore = errorStore;
    this.mediaStore = mediaStore;
    this.alertStore = alertStore;
    this.playlistStore = playlistStore;
    makeAutoObservable(this);
  }

  changeCount = option => {
    this.count = option;
    this.page = 1;
  };

  pageIncrement = () => {
    this.page += 1;
    this.listCourses();
  };

  pageDecrement = () => {
    this.page -= 1;
    this.listCourses();
  };

  setSearch = value => {
    this.page = 1;
    this.searchText = value;
  };

  resetSearch = () => {
    this.searchText = null;
  };

  setCatalogLoading = value => {
    this.catalogLoading = value;
  };

  addCourseToPlaylist = async (courseId, data) => {
    try {
      const resp = await addCoursePlaylist(courseId, data);
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Playlist updated successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addCourseToNewPlaylist = async (courseId, name) => {
    try {
      const resp = await addNewCoursePlaylist(courseId, name);
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Playlist created successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setPlaylistObservables = playlist => {
    this.playlist = playlist;
    this.playlistStore.setPlaylist(playlist);
  };

  getAllPlaylists = async () => {
    try {
      const res = await getAllPlaylists();
      if (res.data.status) {
        this.setPlaylistObservables(res.data.playlist);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All professions */
  listProfessions = async () => {
    try {
      const resp = await getProfessions('course');
      const {professions} = resp.data;
      runInAction(() => {
        this.professionsList = professions;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All specialties */
  listSpecialties = async () => {
    try {
      const resp = await getSpecialties();
      const {specialties} = resp.data;
      runInAction(() => {
        this.specialtiesList = specialties;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All Course Specialties */
  listCourseSpecialties = async () => {
    try {
      const resp = await getSpecialties('course');
      const {specialties} = resp.data;
      runInAction(() => {
        this.courseSpecialtiesList = specialties;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All course providers */
  listProviders = async () => {
    try {
      const resp = await getOrganizations();
      const {organizations} = resp.data;
      runInAction(() => {
        this.providersList = organizations;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set course organizations list observables
   * @param {array} organizations
   */
  setOrganizationsObservables = organizations => {
    this.organizationsList = organizations;
  };

  /** Fetch All organizations */
  listOrganizations = async () => {
    try {
      const res = await getSortedOrganizations('DESC');
      let {organizations} = res.data;
      organizations = organizations.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setOrganizationsObservables(organizations);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set courseHeros observable
   * @param {array} heros
   */
  setCourseHeroObservable = heros => {
    this.courseHeros = heros;
  };

  /**
   * Fetch course heros
   */
  fetchHeros = async () => {
    try {
      const resp = await getCourseHeros();
      const {heros} = resp.data;
      this.setCourseHeroObservable(heros);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set courses and page list observables
   * @param {array} courses
   * @param {array} page
   */
  setCoursesObservables = (courses, count, reload) => {
    if (window.innerWidth <= 576 && !reload) {
      this.coursesList = [...this.coursesList, ...courses];
    } else {
      this.coursesList = courses;
    }
    this.courseCount = count;
    this.loading = false;
  };

  /**
   * Fetch All courses
   * @param {array} professions
   * @param {array} specialties
   * @param {array} organizations
   * @param {boolean} isCme
   * @param {string} sortValue
   * @param {string} sortByValue
   * @param {number} pageValue
   */
  listCourses = async (reload = false) => {
    this.loading = true;
    this.catalogLoading = true;
    try {
      if (reload) {
        this.coursesList = [];
      }
      const resp = await getCourses(
        this.selectedSpecialties,
        this.selectedOrganizations,
        this.selectedProfessions,
        this.isCme ? this.isCme : null,
        this.sortBy,
        this.sortOrder,
        this.page || 1,
        'tile',
        this.count,
        this.searchText ? this.searchText : null,
      );
      this.catalogLoading = false;
      const {courses, count} = resp.data;
      this.setCoursesObservables(courses, count, reload);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  scrollCatalog = () => {
    if (!this.loading && this.courseCount >= this.page * this.count) {
      this.pageIncrement();
    }
  };

  setSelectedProfessions = (Ids, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.selectedProfessions = Ids;
    this.selectedProfessionList = this.professionsList.filter(item => Ids.includes(item.id));
  };

  setSelectedSpecialities = (Ids, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.selectedSpecialties = Ids;
    this.selectedSpecialtiesList = this.courseSpecialtiesList.filter(item => Ids.includes(item.id));
  };

  setSelectedOrganizations = (Ids, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.selectedOrganizations = Ids;
    this.selectedOrganizationList = this.organizationsList.filter(item => Ids.includes(item.id));
  };

  setSort = selectedOption => {
    switch (selectedOption) {
      case 'Most Popular':
        this.sortBy = 'title';
        this.sortOrder = 'ASC';
        break;
      case 'Date Added (Newest)':
        this.sortBy = 'created_at';
        this.sortOrder = 'DESC';
        break;
      case 'Date Added (Oldest)':
        this.sortBy = 'created_at';
        this.sortOrder = 'ASC';
        break;
      case 'Name (Z-A)':
        this.sortBy = 'title';
        this.sortOrder = 'DESC';
        break;
      case 'Name (A-Z)':
        this.sortBy = 'title';
        this.sortOrder = 'ASC';
        break;
      case '# of Hrs Descending':
        this.sortBy = 'total_runtime';
        this.sortOrder = 'DESC';
        break;
      case '# of Hrs Ascending':
        this.sortBy = 'total_runtime';
        this.sortOrder = 'ASC';
        break;

      default:
        break;
    }
  };

  setCME = (value, reload = true) => {
    if (reload) {
      this.page = 1;
    }
    this.isCme = value;
  };

  /** Create new bookmark */
  onCreateBookmark = async courseId => {
    const updatesCourses = [...this.coursesList];
    try {
      const resp = await createBookmark(courseId, 'course');
      const {status, bookmark} = resp.data;
      if (status) {
        const index = updatesCourses.findIndex(course => course.id === courseId);
        updatesCourses[index].bookmark = [bookmark];
        this.setCoursesObservables(updatesCourses, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onDeleteBookmark = async course => {
    const {id} = course;
    const updatesCourses = [...this.coursesList];
    try {
      const resp = await deleteBookmark(id, 'course');
      const {status} = resp.data;
      if (status) {
        const index = updatesCourses.findIndex(uCourse => uCourse.id === id);
        delete updatesCourses[index].bookmark;
        this.setCoursesObservables(updatesCourses, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Remove Profession */
  onRemoveProfession = id => {
    this.selectedProfessionList = this.selectedProfessionList.filter(item => item.id !== id);
    this.selectedProfessions = this.selectedProfessions.filter(item => item !== id);
  };

  onResetProfession = () => {
    this.selectedProfessions = [];
  };

  /** Remove Specialty */
  onRemoveSpecialty = id => {
    this.selectedSpecialtiesList = this.selectedSpecialtiesList.filter(item => item.id !== id);
    this.selectedSpecialties = this.selectedSpecialties.filter(item => item !== id);
  };

  /** Remove Organization */
  onRemoveOrganization = id => {
    this.selectedOrganizationList = this.selectedOrganizationList.filter(item => item.id !== id);
    this.selectedOrganizations = this.selectedOrganizations.filter(item => item !== id);
  };

  /** Follow course */
  onFollowCourse = async courseId => {
    const updatesCourses = [...this.coursesList];
    try {
      const resp = await followItem(courseId, 'course');
      const {status, follow} = resp.data;
      if (status) {
        this.mediaStore.updateCarouselFollow(courseId, true, 'courses');
        const index = updatesCourses.findIndex(course => course.id === courseId);
        updatesCourses[index].follow = [follow];
        this.setCoursesObservables(updatesCourses, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow course */
  onUnfollowCourse = async course => {
    const {id} = course;
    const updatesCourses = [...this.coursesList];
    try {
      const resp = await unfollowItem(id, 'course');
      const {status} = resp.data;
      if (status) {
        this.mediaStore.updateCarouselFollow(id, false, 'courses');
        const index = updatesCourses.findIndex(uCourse => uCourse.id === id);
        delete updatesCourses[index].follow;
        this.setCoursesObservables(updatesCourses, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default CourseStore;
