// @flow
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Section from '../../components/Section';
import VideoTile from '../../components/VideoTile';
import SlickSlider from '../../components/Slider';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {store, useStore} from '../../store';
import SpecialSlider from '../../components/SpecialSlider';
import OrganizationTile from '../../components/OrganizationTile';
import Toast from '../../components/Toast';
import {Main, CarouselContainer} from './elements/home';
import ExpertTile from '../../components/ExpertTile';
import ScreenLoader from '../../components/ScreenLoader';
import UserScreenLoader from './components/UserScreenLoader';
import LocalStorageService from '../../utils/LocalStorageService';
import stringToURLSlug from '../../utils/helper';
import PlaylistCard from '../Playlist/components/playlistCard';
import AlertComponent from '../../components/AlertComponent';
import {amplitude} from '../../utils/Amplitude';
import ConferenceTile from '../../components/ConferenceTile';
import Hero from './components/Hero';
import CMEPromotion from './components/CMEPromotion';
import Ads from './components/Ads';

const DESTINATIONS = new Map([
  ['watch-it-again', '/history'],
  ['my-list', '/bookmarks'],
  ['liked-videos', '/likes'],
  ['resume-cme', '/cme/in-progress'],
  ['purchased-content', '/purchased'],
]);

const Home = observer(() => {
  const {
    authStore,
    mediaStore,
    announcementStore,
    videoStore,
    alertStore,
    accessStore,
    adsStore,
    accountStore,
  } = useStore();
  const location = useHistory();
  const {userId} = authStore;
  const {
    carousels,
    loadingCarousels,
    generatingCarousels,
    loadCarousel,
    loadResumeVideos,
    isCarouselLoading,
    carouselAPICallCount,
    fetchCarousel,
  } = mediaStore;
  const {announcementList, loadAnnouncement, readAnnouncement} = announcementStore;
  const {getAllPlaylists, setSpecialtyPage, newWatches, setNewWatches, setReferrer} = videoStore;
  const {message, status, resetAlert} = alertStore;
  const {anonymousAccess} = accessStore;
  const loggedInUser = LocalStorageService.getUser();
  const [cmePromotion, setCmePromotion] = useState();
  const {getAd, ads, userTrackingAds, trackStatus} = adsStore;
  const {account} = accountStore;

  useEffect(() => {
    const url = new URL(window.location.href);
    const campaign = url.searchParams.get('campaign');
    if (campaign === 'cme-gift-card') setCmePromotion(true);
  }, []);

  const renderCarouselDialogue = () => {
    if (cmePromotion) return <CMEPromotion />;
    if (!loggedInUser && !anonymousAccess) return null;
    return <></>;
  };

  useEffect(() => {
    fetchCarousel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!carousels?.length) {
      loadCarousel(location.location?.state?.from === '/register/content');
    }
  }, [userId, loadCarousel, carousels, location.location?.state?.from]);

  useEffect(() => {
    if (!announcementList) {
      loadAnnouncement();
    }
  }, [userId, announcementList, loadAnnouncement]);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  useEffect(() => {
    if (newWatches) {
      loadResumeVideos().then(() => setNewWatches(false));
    }
  }, [newWatches, loadResumeVideos, setNewWatches]);

  const openVideo = video => {
    setReferrer('carousel_type_hero');
    amplitude.getInstance().logEvent('hero-clicked');
    location.push(`/video/${video.id}`);
  };

  const openConference = conference => {
    setReferrer('carousel_type_hero');
    const eventProperties = {
      'carousel name': 'hero',
    };
    amplitude.getInstance().logEvent('carousel-conference-clicked', eventProperties);
    const slug = stringToURLSlug(conference.title);
    location.push(`/conferences/${conference.id}/${slug}`);
  };

  useEffect(() => {
    getAd('hero');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoOrganization = org => {
    if (org.display) {
      const slug = org.name;
      location.push(`/organization/${org.id}/${slug}`);
    }
  };

  const gotoSpecialty = s => {
    if (s?.id && s?.display_name) {
      setSpecialtyPage(s.name);
      const slug = stringToURLSlug(s.display_name);
      location.push(`/specialty/${s.id}/${slug}`);
    }
  };

  const gotoExpert = experts => {
    if (experts.length && experts[0].name) {
      const slug = stringToURLSlug(experts[0].name);
      location.push(`/experts/${experts[0].id}/${slug}`);
    }
  };

  const gotoPlaylistDetail = id => {
    location.push(`/playlist/${id}`);
  };

  // Function to get the destination url for 'View All'
  const getDestination = (id, slug) => {
    let destination = '';
    if (id && Math.sign(id) === 1) {
      destination = `/all/${id}`;
    } else if (DESTINATIONS.get(slug)) {
      destination = DESTINATIONS.get(slug);
    } else {
      destination = `/all/${slug}`;
    }
    return destination;
  };

  const ScreenLoading = () => {
    if (generatingCarousels) {
      return <UserScreenLoader containerHeight="calc(80vh - 3.5rem)" />;
    }
    return <ScreenLoader containerHeight="calc(80vh - 3.5rem)" />;
  };
  /**
   * Function to get sections based on carousel
   */

  const getSection = item => {
    const {carouselType, videos, organization, expert, conferences, playlist, id, title, tags, hero_items} = item;
    const hasVideo = videos && videos.length;
    const hasOrganization = organization && organization.length;
    const hasExpert = expert && expert.length;
    // const hasCourse = courses && courses.length;
    const hasConference = conferences && conferences.length;
    const carouselTitle = title || carouselType?.text;
    const carouselTag = tags || carouselType?.tags;
    const slug = carouselType?.slug;
    const carouselTypeId = carouselType?.id;
    if (slug === 'hero' && hero_items) {
      return (account?.access?.id ? account?.access?.banner_ads === true : !account?.subscription?.is_premium) &&
        ads.length > 0 ? (
        <>
          <CarouselContainer>
            <Ads hero_items={ads} userTrackingAds={userTrackingAds} trackStatus={trackStatus} />
          </CarouselContainer>
        </>
      ) : (
        <>
          <CarouselContainer>
            <Hero
              hero_items={hero_items}
              openVideo={openVideo}
              openConference={openConference}
              gotoOrganization={gotoOrganization}
              gotoExpert={gotoExpert}
              gotoSpecialty={gotoSpecialty}
            />
          </CarouselContainer>
          {renderCarouselDialogue()}
        </>
      );
    }

    if (slug === 'conferences' && hasConference) {
      return (
        <Section key={id || carouselTitle} title={carouselTitle} destination="/conferences" tags={carouselTag} viewAll>
          <SlickSlider card="course">
            {conferences.map(el => (
              <ConferenceTile
                key={el.name}
                item={el}
                type={id}
                onFollow={null}
                storeName="mediaStore"
                courseStore="conferenceLandingStore"
                onBookmark={async conference => {
                  if (conference.bookmark?.length) {
                    await store.mediaStore.onDeleteBookmark(conference, id, 'conference');
                  } else {
                    await store.mediaStore.onCreateBookmark(conference, id, 'conference');
                  }
                }}
              />
            ))}
          </SlickSlider>
        </Section>
      );
    }

    if (slug === 'organization' && hasOrganization) {
      return (
        <Section key={id || carouselTitle} title={carouselTitle} destination="/organization" tags={carouselTag} viewAll>
          <SpecialSlider>
            {organization.map(org => (
              <OrganizationTile key={org.name} org={org} type={id} onFollow={null} storeName="mediaStore" />
            ))}
          </SpecialSlider>
        </Section>
      );
    }
    if (hasExpert) {
      return (
        <Section key={id || carouselTitle} title={carouselTitle} destination="/experts" tags={carouselTag} viewAll>
          <SlickSlider card="expert">
            {expert.map(el => (
              <ExpertTile key={el.name} expert={el} type={id} onFollow={null} storeName="mediaStore" />
            ))}
          </SlickSlider>
        </Section>
      );
    }
    if (slug === 'playlists') {
      return (
        <Section key={id || carouselTitle} title={carouselTitle} destination="/playlist" tags={carouselTag} viewAll>
          <SlickSlider card="playlist">
            {playlist.map(p => (
              <div
                className="px-2"
                role="button"
                tabIndex="0"
                onClick={() => gotoPlaylistDetail(p.id)}
                onKeyDown={() => gotoPlaylistDetail(p.id)}
              >
                <PlaylistCard title={p.name} videoCount={p.videos.length} thumbnail={p.videos[0]?.thumbnail} />
              </div>
            ))}
          </SlickSlider>
        </Section>
      );
    }
    if (slug === 'resume-watching') {
      return isCarouselLoading ? (
        <Section title={carouselTitle} key={id || carouselTitle}>
          <SlickSlider card="resume-watching">
            {[1, 2, 3, 4].map(() => (
              <div>
                <div className="skeleton" />
              </div>
            ))}
          </SlickSlider>
        </Section>
      ) : (
        <Section title={carouselTitle} key={id || carouselTitle} tags={carouselTag}>
          <SlickSlider card="resume-watching">
            {videos.map(v => (
              <VideoTile
                key={v.title}
                item={v}
                starttime={0}
                type={id}
                source={carouselTypeId}
                sourceTitle={carouselTitle}
                storeName="mediaStore"
                referrer="carousel_type"
              />
            ))}
          </SlickSlider>
        </Section>
      );
    }
    if (hasVideo) {
      return (
        <Section
          title={carouselTitle}
          key={id || carouselTitle}
          destination={getDestination(id, carouselType?.slug)}
          tags={carouselTag}
          viewAll
        >
          <SlickSlider card="video">
            {videos.map(v => (
              <VideoTile
                key={v.title}
                item={v}
                starttime={0}
                type={id}
                source={carouselTypeId}
                sourceTitle={carouselTitle}
                storeName="mediaStore"
                referrer="carousel_type"
              />
            ))}
          </SlickSlider>
        </Section>
      );
    }
    return null;
  };

  return (
    <div>
      <div id="home" className="d-flex">
        <Helmet
          title="GIBLIB"
          meta={[
            {
              name: 'description',
              content:
                "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
            },
            {
              property: 'og:title',
              content: 'GIBLIB',
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:image',
              content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
            },
            {
              name: 'image',
              property: 'og:image',
              content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
            },
            {
              property: 'og:url',
              content: window.location.origin + window.location.pathname,
            },
          ]}
          script={[
            {
              id: 'tracer-tag',
              width: '0',
              height: '0',
              adUnitId: '3890',
              src: 'https://res.lassomarketing.io/scripts/sell-side-tag-min.js',
            },
          ]}
        />
        <Sidebar />
        <div className="w-100">
          <Navbar />
          <Main role="main" className="w-100">
            {announcementList && announcementList.length ? (
              <Toast toast={announcementList[0]} click={readAnnouncement} />
            ) : null}
            {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
            {(loadingCarousels && !carousels?.length) || generatingCarousels || carouselAPICallCount != 0 ? (
              <ScreenLoading />
            ) : (
              carousels.map(item => getSection(item))
            )}
          </Main>
          {!loadingCarousels && <Footer />}
        </div>
      </div>
    </div>
  );
});

export default Home;
