import styled from 'styled-components';

const SwitchContainer = styled.div`
  .btn-group-toggle {
    background-color: ${({theme}) => (theme.name === 'light' ? '#adaeb2' : '#4e505a')};
    padding: 0.03rem;
    .btn {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
      background-color: transparent;
      border-color: transparent;
      color: ${({theme}) => (theme.name === 'light' ? '#ffffff' : '#b8bbbe')};
      &.active {
        color: #fff;
        background-color: #0677d8;
        border-color: #0677d8;
      }
    }
  }
`;

export default SwitchContainer;
