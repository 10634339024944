import {action, makeObservable, observable, runInAction} from 'mobx';
import {getAnnouncements, setAnnouncementAsRead} from '../../api/announcement';
import {getNotifications, readAllNotifications, readNotification} from '../../api/notification';
import ErrorStore from '../error/errorStore';

class AnnouncementStore {
  errorStore: ErrorStore;

  announcementList = null;

  notificationList = [];

  constructor({errorStore}) {
    this.errorStore = errorStore;

    makeObservable(this, {
      announcementList: observable,
      loadAnnouncement: action,
      clearAnnouncement: action,
      readAnnouncement: action,
      notificationList: observable,
      loadNotification: action,
      addNotification: action,
      addAnnouncement: action,
      readNotifications: action,
      markNotificationasRead: action,
    });
  }

  loadAnnouncement = async () => {
    try {
      const res = await getAnnouncements();
      const {announcements} = res;
      this.setAnnouncementList(announcements);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setAnnouncementList = announcements => {
    this.announcementList = announcements;
  };

  readAnnouncement = async id => {
    try {
      await setAnnouncementAsRead(id);
      const announcements = this.announcementList.filter(an => !an.id === id);
      this.setAnnouncementList(announcements);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  clearAnnouncement = () => {
    this.announcementList = [];
  };

  loadNotification = async () => {
    try {
      const {notifications} = await getNotifications();
      runInAction(() => {
        this.notificationList = notifications;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * Append notiifcation
   * @param {*} notiifcation
   */
  addNotification = notification => {
    this.notificationList = [notification, ...this.notificationList];
  };

  /**
   * Append announcement
   * @param {*} announcement
   */
  addAnnouncement = announcement => {
    this.announcementList = [...this.announcementList, announcement];
  };

  readNotifications = async () => {
    try {
      const {status} = await readAllNotifications();

      if (status) {
        runInAction(() => {
          this.notificationList = this.notificationList.map(notify => ({
            ...notify,
            read: true,
          }));
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  markNotificationasRead = async id => {
    try {
      const updatedList = [...this.notificationList];
      const result = await readNotification(id);

      runInAction(() => {
        const notifIndex = updatedList.findIndex(element => element.id === result.data.id);
        updatedList[notifIndex].read = result.data.notification.read;
        this.notificationList = updatedList;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default AnnouncementStore;
