import React from 'react';
import Moment from 'react-moment';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import stringToURLSlug from '../../../utils/helper';
import {Logo, Name, TimeStamp, FollowCard, OfVideos} from '../elements/following';
import LogoImg from '../elements/logo.png';
import {useStore} from '../../../store';
import {ActiveSmallBtn} from '../../../components/FollowButtons';

type Props = {
  item: {
    id: String,
    name: String,
    thumbnail: String,
    created_at: String,
    number_of_videos: number,
    specialty: {
      display_name: String,
      id: number,
      name: String,
    },
  },
};
// FollowCard
const FollowCards = observer(({item}: Props) => {
  const {accountStore} = useStore();
  const {unFollow} = accountStore;

  const unfollow = selectedItem => {
    unFollow(selectedItem);
  };
  const location = useHistory();

  const gotoItem = selectedItem => {
    if (selectedItem.course) {
      const slug = stringToURLSlug(selectedItem.course.title);
      location.push(`/courses/${selectedItem.course.id}/${slug}`);
    } else if (selectedItem.expert) {
      const slug = stringToURLSlug(selectedItem.expert.name);
      location.push(`/experts/${selectedItem.expert.id}/${slug}`);
    } else if (selectedItem.conference) {
      const slug = stringToURLSlug(selectedItem.conference.title);
      location.push(`/conferences/${selectedItem.conference.id}/${slug}`);
    } else if (selectedItem.organization) {
      const slug = stringToURLSlug(selectedItem.organization.name);
      location.push(`/organization/${selectedItem.organization.id}/${slug}`);
    } else if (selectedItem.specialty) {
      const slug = stringToURLSlug(selectedItem.specialty.display_name);
      location.push(`/specialty/${selectedItem.specialty.id}/${slug}`);
    } else if (selectedItem.carousel) {
      location.push(`/all/${selectedItem.carousel.id}`);
    }
  };

  return (
    <FollowCard className="d-flex">
      {item.specialty || item.carousel ? (
        <div
          id="specialty-avatar"
          onClick={() => gotoItem(item)}
          onKeyDown={() => gotoItem(item)}
          role="button"
          tabIndex="0"
        >
          {item.specialty ? item.specialty.display_name.slice(0, 2) : item.carousel.carouselType.text.slice(0, 2)}
        </div>
      ) : (
        <Logo src={item.thumbnail ? item.thumbnail : LogoImg} alt="logo" onClick={() => gotoItem(item)} />
      )}

      <div className="w-100">
        <TimeStamp>
          <Moment fromNow>{item.created_at}</Moment>
        </TimeStamp>
        <Name onClick={() => gotoItem(item)}>{item.name}</Name>
      </div>
      <div>
        <ActiveSmallBtn variant="basic" className="a_follow" onClick={() => unfollow(item)}>
          <span className="following">
            <i className="fas fa-check mr-2" />
            Following
          </span>
          <span className="unfollow">
            <i className="fas fa-times mr-2" />
            Unfollow
          </span>
        </ActiveSmallBtn>
        {item.number_of_videos ? <OfVideos className="text-right">{item.number_of_videos} Videos</OfVideos> : ''}
      </div>
    </FollowCard>
  );
});

export default FollowCards;
