import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Row, Col, Spinner} from 'react-bootstrap';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {Main, InformationDiv, InfoTitle} from '../CME/elements/cme';
import CertificateTab from './Components/certificateTab';
import {useStore} from '../../store';
import {CertificateFinishAssessment} from './elements/style';
import CertificateQuestion from '../Video/components/Certificate';

// Certificate page
const Certificate = observer(() => {
  const {accountStore, certificateStore} = useStore();
  const location = useLocation();
  const history = useHistory();
  const {account, getAccount} = accountStore;
  const {
    question,
    progressSortBy,
    completedSortBy,
    completedStartDate,
    completedEndDate,
    getInprogressCertificates,
    getCompletedCertificates,
    inProgressPage,
    inProgressRecordCount,
    completedPage,
    completedRecordCount,
    certificateLoading,
    videoTitle,
    showQuestionBlock,
  } = certificateStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getInprogressCertificates('progress', 'created_at');
  }, [getInprogressCertificates, progressSortBy, inProgressPage, inProgressRecordCount]);

  useEffect(() => {
    getCompletedCertificates('completed', 'quiz_completed_at');
  }, [
    getCompletedCertificates,
    completedSortBy,
    completedStartDate,
    completedEndDate,
    completedPage,
    completedRecordCount,
  ]);

  useEffect(() => {
    const hasUser = localStorage.getItem('user');

    const url = location.search;
    const query = new URLSearchParams(url);
    const access_code = query.get('code');

    if (access_code) {
      if (!hasUser) {
        localStorage.setItem('access_token', access_code);
        getAccount();
      }
      query.delete('code');
      history.replace({
        search: query.toString(),
      });
    }
    if (!hasUser && account?.id) {
      localStorage.setItem('user', JSON.stringify(account));
    }
  }, [history, location, getAccount, account]);

  return (
    <div id="cme" className="d-flex">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Sidebar />
      <div className="w-100">
        <Navbar />
        <Main
          className={classNames('', {
            'sidetab-expanded': question && showQuestionBlock,
            'flex-column': !question,
          })}
        >
          <div className="cme-Container">
            <InformationDiv className="px-4 pb-4" aria-hidden="true">
              <Row>
                <Col sm={7} xs={6}>
                  <InfoTitle>Certificate</InfoTitle>
                  <h6>Track your completed videos and access certificates.</h6>
                </Col>
              </Row>
            </InformationDiv>
            <CertificateTab />
            {!certificateLoading && <Footer />}
          </div>
          {question && showQuestionBlock ? (
            <CertificateFinishAssessment>
              {certificateLoading ? (
                <div className="spinner-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
                  <Spinner animation="grow" role="status" variant="secondary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <CertificateQuestion expanded={question} title="CME Assessment" videoTitle={videoTitle} />
              )}
            </CertificateFinishAssessment>
          ) : (
            ''
          )}
        </Main>
      </div>
    </div>
  );
});

export default Certificate;
