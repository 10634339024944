import axios from './axios';

export const banUser = email => axios.post('/admin/ban-user', {email});

export const ignoreUser = email => axios.post('/admin/ignore-user', {email});

export const updateUserPwd = (email, password) => axios.post('/admin/update-user-password', {email, password});

/**
 * calls the upload cme api
 */

export const uploadData = (file, type) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return axios.post('admin/update-data', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/** calls the update carousel order api
 */
export const updateCarouselOrder = data => axios.put('/admin/update-carousel-order', {data});

export const revokeCMe = cmeIds => {
  return axios.post('admin/revoke-cme', {ids: cmeIds});
};
