import {makeAutoObservable, runInAction} from 'mobx';

import ErrorStore from '../error/errorStore';
import {
  startCertificate,
  setCertificateAnswer,
  getCertificates,
  deleteCertificate,
  downloadCertificate,
} from '../../api/Certificate';
import {getQuiz} from '../../api/video';

class CertificateStore {
  errorStore: ErrorStore;

  question: Boolean = false;

  certificateInprogress: Array = [];

  certificateCompleted: Array = [];

  progressSortBy: String = 'DESC';

  completedSortBy: String = 'DESC';

  certificateInprogressLoading: Boolean = true;

  certificateCompletedLoading: Boolean = true;

  inProgressTotalCount: Number = 0;

  completedTotalCount: Number = 0;

  inProgressRecordCount: Number = 10;

  completedRecordCount: Number = 10;

  inProgressPage: Number = 1;

  completedPage: Number = 1;

  completedStartDate: String = null;

  completedEndDate: String = null;

  answers: Array = [];

  currentQuestion: Object = {};

  questions: Array = [];

  totalQuestion: Number = 0;

  certificateLoading: Boolean = false;

  currentAnswer: Object = {};

  videoId: Number = null;

  UserCertificate: Object = null;

  certificateDetails: Object = null;

  showComplete: Boolean = false;

  showRetake: Boolean = true;

  showQuestionBlock: Boolean = false;

  videoTitle: String = '';

  completedIds: Array = [];

  selectedIds: Array = [];

  totalCredit: Number = 0;

  certificateCount: Number = 0;

  downloadMessage: String = '';

  questionchanged: Boolean = false;

  changeStartDate = option => {
    this.completedStartDate = option;
    this.completedPage = 1;
  };

  changeEndDate = option => {
    this.completedEndDate = option;
    this.completedPage = 1;
  };

  completedPageIncrement = () => {
    this.completedPage += 1;
  };

  completedPageDecrement = () => {
    this.completedPage -= 1;
  };

  setInProgressLoading = value => {
    this.certificateInprogressLoading = value;
  };

  setCompletedLoading = value => {
    this.certificateCompletedLoading = value;
  };

  setQuestionChanged = value => {
    this.questionchanged = value;
  };

  setshowQuestionBlock = value => {
    this.showQuestionBlock = value;
  };

  setshowComplete = value => {
    this.showComplete = value;
  };

  setshowRetake = value => {
    this.showRetake = value;
  };

  setvideoTitle = value => {
    this.videoTitle = value;
  };

  setAnswer = () => {
    this.answers = [...this.answers, this.currentAnswer];
  };

  setVideoId = async id => {
    this.videoId = id;
  };

  setCurrentAnswer = async (value: []) => {
    this.currentAnswer = {
      id: this.currentQuestion.id,
      question: this.currentQuestion.question,
      answer: value,
    };
  };

  resetQuestionAndAnswers = () => {
    this.showQuestionBlock = false;
    this.questions = [];
    this.answers = [];
    this.totalQuestion = 0;
    this.UserCertificate = null;
    this.setshowComplete(false);
  };

  getCertificateQuestions = async () => {
    this.certificateLoading = true;
    this.resetQuestionAndAnswers();
    try {
      const res = await getQuiz(this.videoId);
      runInAction(() => {
        if (res.status) {
          this.certificateLoading = false;
        }
        this.questions = res.data.certificateQuiz?.quiz_questions?.questions;
        this.UserCertificate = res.data.userCertificate;
        this.answers = res.data.userCertificate?.answers || [];
        this.totalQuestion = this.questions?.length;
        this.certificateDetails = res.data?.certificateQuiz;
        if (this.UserCertificate?.quiz_completed_at) {
          this.setshowComplete(true);
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getQuestionById = () => {
    this.currentAnswer = this.answers.pop();
    this.currentQuestion = this.questions.find((ques: any) => ques.id === this.currentAnswer.id);
    this.setQuestionChanged(!this.questionchanged);
  };

  getNextQuestion = () => {
    this.questions?.forEach(question => {
      if (!this.answers.some((ans: any) => ans.id === question.id) && this.validateQuestion(question, this.answers)) {
        this.currentQuestion = question;
        this.setCurrentAnswer();
      }
    });
    this.setQuestionChanged(!this.questionchanged);
    return {};
  };

  validatePredicate = (predicate: any, answers: any): boolean => {
    const answer = answers.find((ans: any) => ans.id === predicate.question_id);
    if (predicate.answered && answer) {
      return true;
    }
    if (predicate.value && predicate.compare && answer) {
      switch (predicate.compare) {
        case 'lte':
          return answer.answer <= predicate.value;
        case 'gte':
          return answer.answer >= predicate.value;
        case 'gt':
          return answer.answer > predicate.value;
        case 'lt':
          return answer.answer < predicate.value;
        case 'eq':
          return answer.answer === predicate.value;
        default:
          return false;
      }
    }
    return false;
  };

  validateQuestion = (question: any, answers: any): boolean => {
    const {condition} = question;
    if (condition) {
      const {connector} = condition;
      switch (connector) {
        case 'or': {
          const orResult = condition.predicates.map((predicate: any) => {
            return this.validatePredicate(predicate, answers);
          });
          return orResult.reduce((combined: boolean, next: boolean) => combined || next, false);
        }
        case 'and': {
          const andResult = condition.predicates.map((predicate: any) => {
            return this.validatePredicate(predicate, answers);
          });
          return andResult.reduce((combined: boolean, next: boolean) => combined && next, true);
        }
        default:
          return this.validatePredicate(condition.predicates[0], answers);
      }
    } else {
      return true;
    }
  };

  saveAnswer = async () => {
    const answer = {
      answers: this.answers,
      completed: true,
    };
    if (this.UserCertificate?.id) {
      answer.id = this.UserCertificate.id;
    } else {
      answer.video_id = this.videoId;
    }
    try {
      const res = await setCertificateAnswer(answer);
      if (res.data.status) {
        runInAction(() => {
          this.UserCertificate = res.data.userCertificate;
          if (this.certificateInprogress.length > 0) {
            const index = this.certificateInprogress.findIndex(
              certificate => certificate?.id === this.UserCertificate.id,
            );

            // consider calling the inprogress and completed apis,
            // pros -get data from server side
            //  cons-network calls

            this.certificateCompleted.unshift(this.certificateInprogress[index]);
            this.certificateInprogress.splice(index, 1);
            this.inProgressTotalCount -= 1;
            this.completedTotalCount += 1;
          }
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  savePartialAnswer = async () => {
    const answer = {
      answers: this.answers,
      completed: false,
    };
    if (this.UserCertificate?.id) {
      answer.id = this.UserCertificate.id;
    } else {
      answer.video_id = this.videoId;
    }
    try {
      const res = await setCertificateAnswer(answer);
      if (res.data.status) {
        runInAction(() => {
          this.UserCertificate = res.data.userCertificate;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  showQuestions = option => {
    this.question = option;
  };

  changeInProgressRecordCount = option => {
    this.inProgressRecordCount = option;
    this.inProgressPage = 1;
  };

  changeCompletedRecordCount = option => {
    this.completedRecordCount = option;
    this.completedPage = 1;
  };

  changeProgressSort = option => {
    this.progressSortBy = option;
    this.inProgressPage = 1;
  };

  changeCompletedSort = option => {
    this.completedSortBy = option;
    this.completedPage = 1;
  };

  setInProgressObservable = (certificateList, certificateCount) => {
    if (window.innerWidth <= 768) {
      this.certificateInprogress = [...this.certificateInprogress, ...certificateList];
    } else {
      this.certificateInprogress = certificateList;
    }
    this.inProgressTotalCount = certificateCount;
  };

  setCompletedObservable = (certificateList, certificateCount) => {
    if (window.innerWidth <= 768) {
      this.certificateCompleted = [...this.certificateCompleted, ...certificateList];
    } else {
      this.certificateCompleted = certificateList;
    }
    this.completedTotalCount = certificateCount;
  };

  getInprogressCertificates = async (type, sort) => {
    this.setInProgressLoading(true);
    try {
      const resp = await getCertificates(
        type,
        sort,
        this.progressSortBy,
        this.inProgressPage,
        this.inProgressRecordCount,
      );
      const {certificate, certificateCount} = resp.data;
      runInAction(() => {
        this.setInProgressObservable(certificate, certificateCount);
        this.setInProgressLoading(false);
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getCompletedCertificates = async (type, sort) => {
    this.setCompletedLoading(true);
    try {
      const resp = await getCertificates(
        type,
        sort,
        this.completedSortBy,
        this.completedPage,
        this.completedRecordCount,
        this.completedStartDate ? new Date(this.completedStartDate) : this.completedStartDate,
        this.completedEndDate ? new Date(this.completedEndDate) : this.completedEndDate,
      );
      const {certificate, certificateCount} = resp.data;
      runInAction(() => {
        this.setCompletedObservable(certificate, certificateCount);
        this.setCompletedLoading(false);
        this.resetDownloadMessage();
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deleteInProgressCertificate = async id => {
    try {
      const res = await deleteCertificate(id);
      const {status} = res.data;
      if (status) {
        this.getInprogressCertificates('progress', 'created_at');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deleteCompletedCertificate = async id => {
    try {
      const res = await deleteCertificate(id);
      const {status} = res.data;
      if (status) {
        this.getCompletedCertificates('completed', 'created_at');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  download = async cmeIds => {
    try {
      await downloadCertificate(cmeIds);
    } catch (error) {
      this.errorStore.setError(error);
      if (error?.response?.status === 400) {
        this.downloadMessage = 'Please try again after sometime';
      }
    }
  };

  startCertificateVideo = async video_id => {
    try {
      const resp = await startCertificate(video_id);
      if (resp.status === 201) {
        this.UserCertificate = resp.data?.certificate;
        this.setInProgressObservable([resp.data?.certificate], 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  inProgressPageIncrement = () => {
    this.inProgressPage += 1;
  };

  inProgressPageDecrement = () => {
    this.inProgressPage -= 1;
  };

  // retakeAssessment = async (id, ocmeVid) => {
  //   try {
  //     const resp = await retakeCME(id, ocmeVid);
  //     const {status} = resp.data;
  //     if (status) {
  //       runInAction(() => {
  //         const index = this.cmeRevoked.findIndex(cme => cme?.id === id);
  //         this.cmeInprogress.unshift(this.cmeRevoked[index]);
  //         this.cmeRevoked.splice(index, 1);
  //       });
  //       this.setshowRetake(false);
  //     }
  //   } catch (error) {
  //     this.errorStore.setError(error);
  //   }
  // };

  setSelectedIds = ids => {
    this.selectedIds = ids;
  };

  setCompletedIds = ids => {
    this.completedIds = ids;
    this.setSelectedIds(ids);
  };

  setCMECount = count => {
    this.cmeCount = count;
  };

  scrollInProgress = () => {
    if (
      !this.cmeInprogressLoading &&
      this.inProgressTotalCount >= this.inProgressPage * 10 &&
      window.innerWidth <= 768
    ) {
      this.inProgressPageIncrement();
    }
  };

  scrollCompleted = () => {
    if (!this.cmeCompletedLoading && this.completedTotalCount >= this.completedPage * 10 && window.innerWidth <= 768) {
      this.completedPageIncrement();
    }
  };

  resetDownloadMessage = () => {
    this.downloadMessage = '';
  };
}

export default CertificateStore;
