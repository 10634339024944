import React, {useState} from 'react';
import {Button, Container, ProgressBar} from 'react-bootstrap';
import {
  ButtonGroup,
  DetailDiv,
  Details,
  ThumbnailDiv,
  ThumbnailImg,
  VideoTitle,
} from '../../Playlist/elements/playlist';
import {VideoListStyles} from '../elements/styles';

type Props = {
  data: any,
  updateModeration: any,
};

const VideoList = ({data, updateModeration}: Props) => {
  const [isModerated, setIsModerated] = useState(data?.moderated);
  return (
    <VideoListStyles>
      <div className="listContainer">
        <Container className="listcontent d-flex">
          <ThumbnailDiv>
            <ThumbnailImg src={data?.thumbnail ? data?.thumbnail : ''} alt="img" />
            <ProgressBar now={90} />
          </ThumbnailDiv>
          <DetailDiv className="w-100 d-flex justify-content-between">
            <div className="w-100">
              <VideoTitle>
                <h5>{data?.title}</h5>
              </VideoTitle>
              <Details>
                <span className="shortDescription">{data?.short_description}</span>
              </Details>
              <div className="longDescription">{data?.long_description}</div>
              <span> &bull;</span>{' '}
              <span className={isModerated ? 'text-success' : 'text-wrong'}>
                {isModerated ? 'Moderated' : 'Need to Moderate'}
              </span>
            </div>
            <div>
              <ButtonGroup className="text-right">
                <Button
                  variant="basic"
                  className="a_check"
                  onClick={() => {
                    setIsModerated(true);
                    updateModeration(data?.id, {validated: true});
                  }}
                >
                  <i className="fal fa-check" />
                </Button>

                <Button variant="basic" className="a_remove">
                  <i className="fal fa-trash-alt" />
                </Button>
              </ButtonGroup>
            </div>
          </DetailDiv>
        </Container>
      </div>
    </VideoListStyles>
  );
};

export default VideoList;
