import styled from 'styled-components';

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-size: 15px;
  height: 100vh;
  overflow-y: scroll;

  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
`;
export const Breadcrumbs = styled.ul`
  list-style: none;
  font-weight: 600;
  padding: 1em;
  margin-bottom: 0em;
  & > li:after {
    content: '${props => props.separator || '/'}';
    padding: 0 5px;
  }
`;
export const Crumb = styled.li`
  font-weight: normal;
  display: inline-block;
  font-size: 12px;
  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;
export const ActiveCrumb = styled.li`
  display: inline-block;
  font-size: 12px;
  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;
export const PageTitle = styled.h5`
  font-weight: 600;
  // margin-left: 5.2em;
  // padding: 1em 0.5rem;
  height: 1em;
`;
export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 100px 8px 16px;
  border-radius: 30px;
  margin-top: 0.1em;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  &:focus {
    outline: none !important;
  }
`;
export const FormLabel = styled.h5`
  font-size: 13px;
`;
