import {useLocation} from 'react-router-dom';

export default function stringToURLSlug(str) {
  let temp = str.replace(/^\s+|\s+$/g, ''); // trim
  temp = temp.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    temp = temp.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  temp = temp
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return temp;
}

// Function to format Time in HH:MM:SS format
export function formatTime(hours = 0, minutes = 0, seconds = 0) {
  const hh = String(hours).padStart(2, '0');
  const mm = String(minutes).padStart(2, '0');
  const ss = String(seconds).padStart(2, '0');
  if (hours > 0) {
    return `${hh}:${mm}:${ss}`;
  }
  return `${mm}:${ss}`;
}

// Function to convert seconds to HH:MM:SS format
export function secondsToTime(seconds = 0) {
  let timeCode = new Date(seconds * 1000).toISOString().substr(11, 8);
  if (seconds < 3600) timeCode = timeCode.substring(3);
  return timeCode;
}
export function millisecondToTime(milliseconds = 0) {
  const seconds = Math.floor(milliseconds / 1000);
  let timeCode = new Date(seconds * 1000).toISOString().substr(11, 8);
  if (seconds < 3600) timeCode = timeCode.substring(3);
  return timeCode;
}

export function containsAny(str, substrings) {
  for (let i = 0; i < substrings.length; i += 1) {
    if (str?.includes(substrings[i])) {
      return {status: true, tag: substrings[i]};
    }
  }
  return {status: false};
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// isIntegrationTest checks whether a request was made by the integration tests
// using a value from a query parameter.
export function isIntegrationTest() {
  if (!process.env.REACT_APP_INTEGRATION_TEST_TOKEN) {
    return false;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery().get('integration-test-token') === process.env.REACT_APP_INTEGRATION_TEST_TOKEN;
}
