import React from 'react';
import {Form, Dropdown} from 'react-bootstrap';
import SortContainer from './style';

type Props = {
  sort: Array,
  change: Function,
  sortBy: String,
  sortOrder: String,
};

const Sort = ({sort, change, sortBy, sortOrder}: Props) => {
  return (
    <SortContainer className="sort-mobile">
      <Dropdown>
        <Dropdown.Toggle variant="basic" id="sort" className="a_sort">
          Sort by
        </Dropdown.Toggle>

        <Dropdown.Menu onChange={e => change(e.target.value)}>
          {sort?.map((value, index) => (
            <Form.Check
              type="radio"
              label={value.text}
              id={`${index}_${value.id}`}
              value={value.id}
              name="sort"
              checked={sortOrder === value.order && sortBy == value.sort}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </SortContainer>
  );
};

export default Sort;
