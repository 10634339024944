import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../../../store';
import {CarouselDiv} from '../../elements/styles';

const Carousel = observer(() => {
  const {adminStore} = useStore();
  const {getCarouselTypes, carouselTypes, updateOrder} = adminStore;

  useEffect(() => {
    getCarouselTypes();
  }, [getCarouselTypes]);

  const [dragId, setDragId] = useState();

  const handleDrag = id => {
    setDragId(id);
  };

  const handleDrop = dropId => {
    const dragBox = carouselTypes.findIndex(carousel => +carousel.id === +dragId);
    const dropBox = carouselTypes.findIndex(carousel => +carousel.id === +dropId);

    /* eslint-disable no-param-reassign */
    const newVideoState = carouselTypes
      .map((carousel, index) => {
        if (dragBox < dropBox) {
          if (dragBox < index && index <= dropBox) {
            carousel.order = index - 1;
          } else if (index === dragBox) {
            carousel.order = dropBox;
          } else {
            carousel.order = index;
          }
        }
        if (dragBox > dropBox) {
          if (dragBox > index && index >= dropBox) {
            carousel.order = index + 1;
          } else if (index === dragBox) {
            carousel.order = dropBox;
          } else {
            carousel.order = index;
          }
        }
        return carousel;
      })
      .sort((a, b) => a.order - b.order);

    updateOrder(newVideoState);
  };

  return (
    <CarouselDiv>
      <h6 className="carousel-order">Carousel Order</h6>
      {carouselTypes?.map((item, index) => (
        <div className="carousel-card">
          <h6 className="order">{index + 1}</h6>
          <div
            className="carousel-box"
            key={item.id}
            draggable
            onDragOver={ev => ev.preventDefault()}
            onDragStart={() => handleDrag(item.id)}
            onDrop={() => handleDrop(item.id)}
          >
            <h6 className="carousel-name">{item.text}</h6>
          </div>
        </div>
      ))}
    </CarouselDiv>
  );
});

export default Carousel;
