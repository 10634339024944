import React, {useEffect, useState} from 'react';
import {ProgressBar} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {observer, useLocalStore} from 'mobx-react-lite';
import Moment from 'react-moment';
import {Details, Badge, CourseTitle, ThumbnailImg, ThumbnailDiv, Unlock} from '../../elements/video';
import {useStore} from '../../../../store';
import {secondsToTime} from '../../../../utils/helper';
import LocalStorageService from '../../../../utils/LocalStorageService';
import VRIcon from '../../../../assets/icons/360vr.svg';
import NewIcon from '../../../../assets/icons/new.png';
import CMEIcon from '../../../../assets/icons/CME.svg';
import CMEDefaultIcon from '../../../../assets/icons/CMEDefault.svg';
import CMEInProgressIcon from '../../../../assets/icons/CMEInProgress.svg';
import CMECompletedIcon from '../../../../assets/icons/CMECompleted.svg';
import CMERevokedIcon from '../../../../assets/icons/CMERevoked.svg';
import UnlockIcon from '../../../../assets/icons/unlock.png';

type Props = {
  item: {
    id: number,
    title: string,
    description: string,
    number_of_videos: number,
    watchProgress: number,
    duration: number,
    brightcove_id: string,
    cme: boolean,
    thumbnail: string,
    bookmark: Array,
    follow: Array,
    video_type: string,
    created_at: number,
    filmed_at: string,
    released_date: number,
    content_type: string,
    organization: {
      name: string,
    },
  },
  type: string,
  order?: number,
  courseId?: number,
};

const VideoCard = observer(({type, item, order, courseId}: Props) => {
  const {videoStore, accessStore, accountStore} = useStore();
  const {video, setReferrer} = videoStore;
  const {anonymousAccess} = accessStore;
  const {account} = accountStore;
  const location = useHistory();

  const loggedInUser = LocalStorageService.getUser();
  const [lock, setLock] = useState(Boolean(!loggedInUser));

  useEffect(() => {
    if (item.access_level === 'Free') setLock(false);
    else if (item.access_level === 'Premium') setLock(false);
    else if (anonymousAccess) setLock(false);
    else if (
      loggedInUser &&
      (!account.access || account.access?.status === 'Inactive') &&
      (!account.subscription || account.subscription.status === 'cancelled')
    )
      setLock(true);
  }, [loggedInUser, account, item, anonymousAccess]);

  // New Logic
  const state = useLocalStore(() => ({
    isNew: false,
  }));
  // new badge (displays for 30 days from release date)
  useEffect(() => {
    const createdVideo = new Date(item.release_date);
    const after30Video = new Date(createdVideo.getFullYear(), createdVideo.getMonth(), createdVideo.getDate() + 30);
    const today = new Date();
    if (after30Video > today) {
      state.isNew = true;
    }
  }, [state, item]);

  const gotoVideo = id => {
    if (type === 'course') {
      setReferrer('course-up-next');
      location.push(`/video/${id}?course_id=${courseId}&order=${order}`);
    } else if (type === 'playlist') {
      setReferrer('playlist-up-next');
      location.push(`/video/${id}?playlist_id=${courseId}&order=${order}`);
    } else if (type === 'conference') {
      setReferrer('conference-up-next');
      location.push(`/video/${id}?conference_id=${courseId}&order=${order}`);
    } else if (type === 'recommend') {
      setReferrer(`up-next_${video.brightcove_id}`);
      location.push(`/video/${id}`);
    }
  };

  const renderCMEIcon = () => {
    if (!loggedInUser) return <object data={CMEIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;

    const cmes = item.organizationCME?.CMEs;
    const cmeStatus = cmes?.length && cmes[0]?.status;

    switch (cmeStatus) {
      case 'In Progress':
        return <object data={CMEInProgressIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
      case 'Completed':
        return <object data={CMECompletedIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
      case 'Revoked':
        return <object data={CMERevokedIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
      default:
        return <object data={CMEDefaultIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
    }
  };

  return (
    <div
      className="courseBorder-bottom mb-3"
      role="button"
      tabIndex="0"
      onClick={() => gotoVideo(item.id)}
      onKeyDown={() => gotoVideo(item.id)}
    >
      <div className="d-flex" id={item.id}>
        <ThumbnailDiv>
          <ThumbnailImg src={item.thumbnail} alt={item.title} lock={lock} />
          <ProgressBar now={item.watchProgress} />
        </ThumbnailDiv>
        <div className="w-100">
          <CourseTitle>
            <h5>{item.title}</h5>
          </CourseTitle>
          <Details>
            {item?.organization?.name}
            <br />
            {secondsToTime(item.duration)} |&nbsp;
            {item.content_type === 'procedure' ? (
              <Moment format="YYYY">{item.release_date}</Moment>
            ) : (
              <Moment format="YYYY">{item.filmed_at || item.released_date}</Moment>
            )}
          </Details>
          <Badge>
            {item?.isNew ? <img src={NewIcon} className="icon-new" alt="new" /> : null}
            {item.video_type?.includes('360vr') ? <img src={VRIcon} className="icon-vr" alt="vr" /> : null}
            {item?.organizationCME ? renderCMEIcon() : null}
            {item.access_level === 'Free' && (
              <Unlock>
                <img src={UnlockIcon} alt="unlock" />
              </Unlock>
            )}
          </Badge>
        </div>
      </div>
    </div>
  );
});

export default VideoCard;
