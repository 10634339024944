import React, {useRef, useState} from 'react';
import {Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import LocalStorageService from '../../utils/LocalStorageService';
import {CommentsStyles} from './style';
import CommentSection from './CommentSection';
import {useStore} from '../../store';
import {UpdateModal} from '../../pages/Playlist/elements/playlist';

const VideoComments = observer(() => {
  const store = useStore();
  const {videoComments, DeleteAComments, commentCount} = store.commentStore;
  const [deleteComment, setDeleteComment] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentColors, setCommentColors] = useState([]);
  const [id, setId] = useState();
  const loggedInUser = LocalStorageService.getUser();
  const generateColor = userId => {
    const color = commentColors.find(el => el.id === userId);
    if (color) {
      return color.color;
    }
    const randomColor = Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0');
    setCommentColors([...commentColors, {id: userId, color: `#${randomColor}`}]);
    return `#${randomColor}`;
  };

  const deleteVideo = () => {
    DeleteAComments(id, () => {
      setDeleteComment(false);
      setId();
    });
  };
  // const useClickOutside = ref => {
  //   useEffect(() => {
  //     const handleClickOutside = event => {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         setDeleteModal(false);
  //       }
  //     };

  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside);
  //     };
  //   }, [ref]);
  // };

  const wrapperRef = useRef(null);
  // useClickOutside(wrapperRef);
  return (
    <CommentsStyles>
      <h5>
        <span>{commentCount}</span> Comments
      </h5>
      <div className="commentContainer">
        {loggedInUser && <CommentSection loggedInUser={loggedInUser} />}
        <hr />
        {videoComments?.map(item => (
          <div className="d-flex w-100">
            <div
              style={{background: generateColor(item?.user?.id)}}
              className="profile-image d-flex justify-content-center align-items-center mr-4 mt-4"
            >
              {[
                item?.user?.first_name?.substring(0, 1).toUpperCase(),
                item?.user?.last_name?.substring(0, 1).toUpperCase(),
              ].join('')}
            </div>

            <div className="w-100 position-relative">
              <div className="d-flex justify-content-between">
                <div className=" m-2">
                  {`${item?.user?.first_name} ${item?.user?.last_name}`}{' '}
                  <span className="dateContent ml-2">
                    {new Date(item?.updated_at).toLocaleString('en-US', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </span>
                </div>
                {loggedInUser && (loggedInUser?.id === item?.user?.id || loggedInUser.role === 'admin') && (
                  <button
                    ref={wrapperRef}
                    className="deleteButton mr-2"
                    type="button"
                    onClick={() => {
                      setDeleteModal(!deleteModal);
                      setId(item.id);
                    }}
                  >
                    <i className="fas fa-ellipsis-v" />
                    {deleteModal && id === item.id && (
                      <button type="button" className="deleteContainer" onClick={() => setDeleteComment(true)}>
                        Delete
                      </button>
                    )}
                  </button>
                )}
              </div>
              <div className="usersComments w-100">
                <pre>{item?.comment}</pre>
              </div>
            </div>
            {deleteComment && (
              <UpdateModal show={deleteComment} onHide={setDeleteComment} className="deletePlaylistModal">
                <UpdateModal.Header closeButton>Delete Comment</UpdateModal.Header>
                <UpdateModal.Body>
                  <p>Are you sure you want delete this comment ?</p>
                  <div className="w-100 d-flex flex-row-reverse">
                    <Button variant="basic" className="validationButton approve " onClick={deleteVideo}>
                      Delete
                    </Button>
                  </div>
                </UpdateModal.Body>
              </UpdateModal>
            )}
          </div>
        ))}
      </div>
    </CommentsStyles>
  );
});

export default VideoComments;
