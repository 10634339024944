import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
// import Footer from '../../components/Footer';
import {Main, Top} from './elements/contentrequest';
import {useStore} from '../../store';
import LocalStorageService from '../../utils/LocalStorageService';
import SuggestedVideos from './components/SuggestedVideos';
import useDebounce from '../../components/Navbar/use-debounce';
import {amplitude} from '../../utils/Amplitude';

// Content request page
const ContentRequest = observer(() => {
  const {authStore, accountStore, searchStore} = useStore();
  const {
    specialtyList,
    isContentRequestSuccess,
    getSpecialtyList,
    requestContent,
    setIsContentRequestSuccess,
  } = authStore;
  const {account} = accountStore;
  const {getVideoResults} = searchStore;
  const {register, watch, handleSubmit, errors, reset} = useForm();
  const watchField = watch('specialty');
  const watchContent = watch('contentrequest');

  const loggedInUser = LocalStorageService.getUser();
  const debouncedSearchTerm = useDebounce(watchContent, 500);

  useEffect(() => {
    getSpecialtyList();
  }, [getSpecialtyList]);

  useEffect(() => {
    if (errors) setIsContentRequestSuccess(false);
  }, [errors, setIsContentRequestSuccess]);

  const onSubmit = data => {
    let formData = {};
    formData = data;
    if (loggedInUser) formData.useremail = account.email;
    requestContent(formData);
    reset();
    amplitude.getInstance().logEvent('content-request-submitted');
  };

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === '') getVideoResults(debouncedSearchTerm);
  }, [debouncedSearchTerm, getVideoResults]);

  return (
    <>
      <Navbar />
      <div id="contentrequest" className="d-flex overflow-hidden vh-100">
        <Sidebar />
        <Main>
          <Top>
            <div className="title">Content Request</div>
            <div className="text">I would like to see more content on</div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Row>
                <Form.Group controlId="select-specialty">
                  <Form.Control
                    size="sm"
                    as="select"
                    placeholder="Select Specialty"
                    name="specialty"
                    className="rounded-pill pl-3 py-2 content control"
                    ref={register({required: true})}
                    custom
                    isInvalid={!!errors.specialty}
                  >
                    <option value="">Select Specialty</option>
                    {specialtyList?.map(item => (
                      <option key={item.id} value={item.display_name}>
                        {item.display_name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.specialty && (
                    <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                  )}
                  {watchField === 'Other' ? (
                    <>
                      <Form.Control
                        type="text"
                        placeholder="Please type your specialty here"
                        name="otherspecialty"
                        className="rounded-pill pl-3 py-2 content control"
                        ref={register({required: true})}
                        custom
                        isInvalid={!!errors.otherspecialty}
                      />
                      {errors.otherspecialty && (
                        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                      )}
                    </>
                  ) : null}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group controlId="enter-contentrequest">
                  <Form.Control
                    type="search"
                    placeholder="I'd like to see more content on..."
                    name="contentrequest"
                    className="rounded-pill pl-3 py-2 content control-bottom"
                    ref={register({required: true})}
                    custom
                    isInvalid={!!errors.contentrequest}
                  />
                  {errors.otherspecialty && (
                    <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              {!loggedInUser ? (
                <Form.Row>
                  <Form.Group controlId="enter-email">
                    <Form.Control
                      type="email"
                      placeholder="Enter your subscription email here"
                      name="useremail"
                      className="rounded-pill pl-3 py-2 content control-bottom"
                      ref={register({required: true})}
                      custom
                      isInvalid={!!errors.useremail}
                    />
                    {errors.uesremail && (
                      <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Form.Row>
              ) : null}
              <div className="submit-btn">
                {isContentRequestSuccess ? (
                  <div className="text-success success-submit-mobile mb-2">
                    Your content request has been successfully submitted
                  </div>
                ) : null}
                <Button variant="primary" type="submit" id="contentrequest-Submit" className="text-white">
                  Submit
                </Button>
                {isContentRequestSuccess ? (
                  <div className="text-success success-submit-desk">
                    Your content request has been successfully submitted
                  </div>
                ) : null}
              </div>
            </Form>
          </Top>
          <SuggestedVideos content={watchContent} />
          {/* <Footer /> */}
        </Main>
      </div>
    </>
  );
});

export default ContentRequest;
