import styled from 'styled-components';
import Slider from 'react-slick';
import nextIcon from '../../assets/icons/chevron-right-gray.svg';
import nextHoverIcon from '../../assets/icons/chevron-right-hover.svg';
import prevIcon from '../../assets/icons/chevron-left-gray.svg';
import prevHoverIcon from '../../assets/icons/chevron-left-hover.svg';

export const NextDiv = styled.div`
  background-image: url(${nextIcon}) !important;
  background-repeat: no-repeat !important;
  background-size: 25px auto !important;
  width: 25px !important;
  height: 30px !important;

  @media screen and (max-width: 1200px) {
    right: -17px;
  }

  &:hover {
    background-image: url(${nextHoverIcon}) !important;
  }

  &:before {
    content: '' !important;
  }
`;

export const PrevDiv = styled.div`
  background-image: url(${prevIcon}) !important;
  background-repeat: no-repeat !important;
  background-size: 25px auto !important;
  width: 25px !important;
  height: 30px !important;

  @media screen and (max-width: 1200px) {
    left: -17px;
  }

  &:hover {
    background-image: url(${prevHoverIcon}) !important;
  }

  &:before {
    content: '' !important;
  }
`;
export const CustomSlider = styled(Slider)`
  .slick-track {
    display: flex !important;
    .slick-slide {
      height: inherit !important;
      > div {
        height: 100%;
      }
    }
  }
`;
