import {Modal} from 'react-bootstrap';
import styled from 'styled-components';

const ErrorModal = styled(Modal)`
  .modal-dialog {
    width: 375px;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;

    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-body {
      padding: 1rem 1.5rem;
      .modal-title-text {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.3px;
        line-height: 24px;
        margin-bottom: 2px;
      }
      .modal-text {
        font-size: 12px;
        letter-spacing: -0.1px;
        line-height: 20px;
      }
      .modal-small-text {
        font-size: 8px;
        color: gray;
        letter-spacing: -0.1px;
        line-height: 8px;
      }
    }
    .close {
      color: ${({theme}) => theme.circle};
    }
  }
`;

export default ErrorModal;
