import React, {useEffect, useRef, useState} from 'react';
import {Spinner, Button, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import Main, {
  Time,
  Subtitle,
  Videoplayer,
  Skip,
  Bumper,
  Sponsored,
  SurveyForm,
  SubmitButton,
} from './elements/VideoPlayer';
import {useStore} from '../../store';
import Play from '../../assets/icons/play-solid.png';
import Replay from '../../assets/icons/replay.png';

// eslint-disable-next-line no-unused-vars
const VideoPlayer = observer(({videoId, thumbnail, freeVideoLimitExceed, watchVideoCount}) => {
  const ref = useRef();
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [playerLoadInitiated, setPlayerLoadInitiated] = useState(false);
  const [videoWatch, setVideoWatch] = useState(true);
  const {videoStore, accountStore, adsStore} = useStore();
  const {
    setVideoPlayer,
    videoTimeExceeded,
    setVideoTimeExceeded,
    videoPause,
    setVideoPause,
    setRewatch,
    videoPlayer,
    noFreeMin,
    hasWatched,
    bumperEnded,
    showBumperSkip,
    setBumperEnded,
    setShowBumperSkip,
    showAd,
    bumperCounter,
    setBumperCounter,
  } = videoStore;

  const {account} = accountStore;

  const {
    bumperAd,
    userTrackingAds,
    showBumper,
    handleShowBumper,
    updateAdHistory,
    survey,
    addSurveyResponse,
  } = adsStore;

  const [showSkip, setShowSkip] = useState(false);
  const [afterSkip, setAfterSkip] = useState(false);
  const [suveySkip, setSurveySkip] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState([]);

  const removeAllPlayers = () => {
    const players = window.videojs?.getAllPlayers();
    if (players?.length && videoPlayer) {
      for (let i = 0; i < players?.length; i += 1) {
        if (players[i]?.id_ !== undefined) {
          players[i].dispose();
        }
      }
    }
  };

  useEffect(() => {
    removeAllPlayers();
    setShowSkip(false);
    setAfterSkip(false);
    setPlayerLoadInitiated(false);
    setBumperEnded(false);
    setShowBumperSkip(false);
    setSurveySkip(false);
    // eslint-disable-next-line
  }, [videoId]);

  useEffect(() => {
    if (bumperAd !== null && afterSkip) {
      removeAllPlayers();
      setAfterSkip(false);
      setPlayerLoadInitiated(false);
    }

    if (!playerLoadInitiated) {
      setPlayerLoadInitiated(true);
      if (showAd && bumperAd !== null && showBumper && bumperAd?.brightcove_id) {
        setVideoPlayer(ref, bumperAd?.brightcove_id, true)
          .then(() => {
            setPlayerLoaded(true);
            userTrackingAds({
              ad_id: bumperAd.id,
              impression_type: 'view',
              impression_source: window.location.pathname,
              device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
            });
          })
          .catch(() => {
            setPlayerLoaded(false);
          });
      } else if (videoId && !noFreeMin && !freeVideoLimitExceed && videoWatch) {
        setVideoPlayer(ref, videoId)
          .then(() => {
            setPlayerLoaded(true);
          })
          .catch(() => {
            setPlayerLoaded(false);
          });
      }
    }
    if (videoTimeExceeded) {
      setPlayerLoadInitiated(false);
    } // eslint-disable-next-line
  }, [
    ref,
    playerLoaded,
    videoId,
    setVideoPlayer,
    playerLoadInitiated,
    videoTimeExceeded,
    noFreeMin,
    freeVideoLimitExceed,
    videoWatch,
    showBumper,
    bumperAd,
  ]);

  useEffect(() => {
    if (
      account?.subscription?.name?.includes('Free') &&
      !account.access &&
      (freeVideoLimitExceed || (account?.id && watchVideoCount < 5))
    ) {
      setVideoWatch(false);
    }
    // eslint-disable-next-line
  }, [account?.subscription, freeVideoLimitExceed, account?.id, watchVideoCount]);

  useEffect(() => {
    if (!bumperAd?.brightcove_id) {
      setTimeout(() => {
        setShowSkip(true);
      }, 5000);
    }
    // eslint-disable-next-line
  }, [bumperAd]);

  const [counter, setCounter] = useState(bumperAd?.is_skip ? 5 : 10);

  useEffect(() => {
    if (!bumperAd?.brightcove_id && showBumper && bumperAd !== null) {
      setTimeout(() => {
        handleShowBumper(false);
        setPlayerLoadInitiated(false);
      }, 10000);
    }
    setCounter(bumperAd?.is_skip ? 5 : 10);
    setBumperCounter(bumperAd?.is_skip ? 5 : 10);
    // eslint-disable-next-line
  }, [videoId]);

  useEffect(() => {
    if (bumperAd?.brightcove_id) {
      if (bumperEnded) {
        handleShowBumper(false);
        setPlayerLoadInitiated(false);
      }
    }
    // eslint-disable-next-line
  }, [bumperEnded]);

  const adRef = useRef();

  useEffect(() => {
    if (bumperAd !== null) {
      try {
        const adObserver = new IntersectionObserver(([entry]) => {
          if (entry?.isIntersecting) {
            userTrackingAds({
              ad_id: bumperAd.id,
              impression_type: 'view',
              impression_source: window.location.pathname,
              device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
            });
          }
        });
        adObserver?.observe(adRef?.current);
        return () => {
          adObserver?.disconnect();
        };
      } catch {
        return true;
      }
    }
    return true;
    // eslint-disable-next-line
  }, [adRef]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  const handleBumperSkip = is_bumper_skipped => {
    updateAdHistory(is_bumper_skipped, window.location.pathname);
  };

  const handleSurveySubmit = () => {
    if (surveyResponse.length > 0) {
      setSurveySkip(true);
      setPlayerLoadInitiated(false);
      handleShowBumper(false);
      userTrackingAds({
        ad_id: null,
        impression_type: 'view',
        impression_source: window.location.pathname,
        device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
        ad_type: 'survey',
        is_answered: true,
        survey_id: survey.id,
      });
      addSurveyResponse(survey.id, JSON.stringify(surveyResponse));
    }
  };
  const handleSurveySkip = () => {
    setSurveySkip(true);
    setPlayerLoadInitiated(false);
    handleShowBumper(false);
    userTrackingAds({
      ad_id: null,
      impression_type: 'view',
      impression_source: window.location.pathname,
      device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
      ad_type: 'survey',
      survey_id: survey.id,
    });
  };

  const handleChange = e => {
    const {value, checked} = e.target;

    if (checked) {
      setSurveyResponse([...surveyResponse, value]);
    } else {
      const index = surveyResponse.findIndex(el => el === value);
      const array = [...surveyResponse];
      if (index > -1) {
        array.splice(index, 1);
      }
      setSurveyResponse(array);
    }
  };

  return (
    <Videoplayer>
      {!playerLoaded && !noFreeMin && videoWatch && (
        <Main>
          <div className="spinner-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
            <Spinner animation="grow" role="status" variant="secondary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Main>
      )}
      {freeVideoLimitExceed &&
      !account?.access &&
      account?.subscription &&
      account?.subscription?.name?.includes('Free') &&
      !hasWatched ? (
        <Main>
          <img className="position-absolute w-100 h-100" src={thumbnail} ref={ref} alt="Video thumbnail" />
          <div className="lock-wrapper">
            <Link to={account?.id ? '/profile/plan-update' : '/register'} className="lock-link">
              <i className="fas fa-lock-alt lock-icon" />
            </Link>
            <Subtitle className="py-3">Upgrade your plan today to watch unlimited videos.</Subtitle>
            <p>{watchVideoCount < 5 ? watchVideoCount : '5'} / 5 videos watched</p>
            <Row>
              <Link to={account?.id ? '/profile/plan-update' : '/register'} className="register paused">
                <Button className="mx-3 register-btn">Upgrade Now</Button>
              </Link>
            </Row>
          </div>
        </Main>
      ) : (
        [
          noFreeMin ? (
            <Main>
              <img className="position-absolute w-100 h-100" src={thumbnail} ref={ref} alt="Video thumbnail" />
              <div className="lock-wrapper">
                <Link to="/login" className="lock-link">
                  <i className="fas fa-lock-alt lock-icon" />
                </Link>
              </div>
            </Main>
          ) : (
            [
              videoTimeExceeded ? (
                <Main>
                  <img className="position-absolute w-100 h-100" src={thumbnail} ref={ref} alt="Video thumbnail" />
                  <div className="lock-wrapper">
                    <Time>Finish this video</Time>
                    <Subtitle>Register today to watch the full video.</Subtitle>
                    <Row className="py-4">
                      <Button
                        className="watch-btn"
                        onClick={() => {
                          setVideoTimeExceeded(false);
                          setRewatch(true);
                        }}
                      >
                        Rewatch video
                        <img src={Replay} className="pl-3 pr-1 watch-icon" alt="replay" />
                      </Button>
                      <Link to={account?.id ? '/profile/plan-update' : '/register'} className="register paused">
                        <Button className="mx-3 register-btn">Subscribe Now</Button>
                      </Link>
                    </Row>
                  </div>
                </Main>
              ) : (
                <div className="video-screen">
                  {survey !== null && survey.id && !bumperAd?.id && !suveySkip ? (
                    <Main>
                      <div className="lock-wrapper">
                        <div className="question" dangerouslySetInnerHTML={{__html: survey.question}} />

                        <div className="row w-100 justify-content-center">
                          <div className="col-8 text-left">
                            <SurveyForm>
                              <div>
                                {survey?.options?.map((option, index) => (
                                  <label htmlFor={`option_${index}`} className="shadow bg-body-tertiary rounded">
                                    <input
                                      type="checkbox"
                                      id={`option_${index}`}
                                      onChange={handleChange}
                                      value={option}
                                    />{' '}
                                    {option}
                                  </label>
                                ))}
                              </div>

                              <SubmitButton onClick={handleSurveySubmit} type="button">
                                Submit Survey
                              </SubmitButton>
                            </SurveyForm>
                          </div>
                        </div>
                      </div>
                      <Sponsored>
                        <Skip
                          onClick={() => {
                            handleSurveySkip();
                          }}
                        >
                          Skip Survey
                        </Skip>
                      </Sponsored>
                    </Main>
                  ) : (
                    <>
                      {showAd && bumperAd !== null && showBumper && !bumperAd?.brightcove_id ? (
                        <Bumper>
                          <img src={bumperAd?.ad_asset} alt="" ref={adRef} />
                          <Sponsored>
                            <small>Sponsored</small>
                            {bumperAd?.is_skip && showSkip && (
                              <Skip
                                onClick={() => {
                                  handleShowBumper(false);
                                  setPlayerLoadInitiated(false);
                                  handleBumperSkip(true);
                                }}
                              >
                                Skip Ad
                              </Skip>
                            )}
                            {(!showSkip || !bumperAd?.is_skip) && <Skip>{counter}</Skip>}
                          </Sponsored>
                        </Bumper>
                      ) : (
                        <>
                          <div ref={ref} />
                          {showAd && showBumper && bumperAd?.brightcove_id && playerLoaded && (
                            <Sponsored>
                              <small>Sponsored</small>
                              {showBumperSkip && bumperAd.is_skip && (
                                <Skip
                                  onClick={() => {
                                    handleShowBumper(false);
                                    setAfterSkip(true);
                                    handleBumperSkip();
                                    setPlayerLoadInitiated(false);
                                  }}
                                >
                                  Skip Ad
                                </Skip>
                              )}
                              {!showBumperSkip && bumperCounter && <Skip>{bumperCounter}</Skip>}
                            </Sponsored>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {videoPause ? (
                    <Main className="pausedContainer">
                      <img className="position-absolute w-100 h-100" src={thumbnail} alt="Video thumbnail" />
                      <div className="lock-wrapper">
                        <Time>Keep Learning</Time>
                        <Subtitle>Register today to watch the full video.</Subtitle>
                        <Row className="py-4">
                          <Button
                            className="watch-btn"
                            onClick={() => {
                              setVideoPause(false);
                              videoPlayer?.play();
                            }}
                          >
                            Keep watching
                            <img src={Play} className="pl-3 pr-1 watch-icon" alt="play" />
                          </Button>
                          <Link to={account?.id ? '/profile/plan-update' : '/register'} className="register paused">
                            <Button className="mx-3 register-btn">Subscribe Now</Button>
                          </Link>
                        </Row>
                      </div>
                    </Main>
                  ) : (
                    [
                      account?.id &&
                      watchVideoCount < 5 &&
                      account?.subscription?.name?.includes('Free') &&
                      !account?.access &&
                      !videoWatch ? (
                        <Main className="pausedContainer">
                          <img className="position-absolute w-100 h-100" src={thumbnail} alt="Video thumbnail" />
                          <div className="lock-wrapper">
                            <Time>Keep Learning</Time>
                            <Subtitle>Upgrade your plan today to watch unlimited videos.</Subtitle>
                            <span className="">{watchVideoCount} / 5 videos watched</span>
                            <Row className="py-4">
                              <Button
                                className="watch-btn"
                                onClick={() => {
                                  setVideoWatch(true);
                                }}
                              >
                                Watch Now
                                <img src={Play} className="pl-3 pr-1 watch-icon" alt="play" />
                              </Button>
                              <Link to={account?.id ? '/profile/plan-update' : '/register'} className="register paused">
                                <Button className="mx-3 register-btn">Upgrade Now</Button>
                              </Link>
                            </Row>
                          </div>
                        </Main>
                      ) : null,
                    ]
                  )}
                </div>
              ),
            ]
          ),
        ]
      )}
    </Videoplayer>
  );
});
VideoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  freeVideoLimitExceed: PropTypes.bool,
  watchVideoCount: PropTypes.number,
};

export default VideoPlayer;
