import {makeAutoObservable} from 'mobx';
import ErrorStore from '../error/errorStore';

class AlertStore {
  errorStore: ErrorStore;

  message: string = '';

  status: string = '';

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  setMessage = (msg: string, status: string) => {
    this.message = msg;
    this.status = status;
    setTimeout(() => {
      this.resetAlert();
    }, 3000);
  };

  resetAlert = () => {
    this.message = '';
    this.status = '';
  };
}

export default AlertStore;
