import axios from './axios';

/**
 * calls the account GET api to get the account details
 */
export const getVideo = (id, cancelToken = null) => axios.get(`/video/${id}`, {params: {cancelToken}});

/** calls the getVideos api
 * @params {array} specialty - list of specialty IDs
 * @params {array} organization - list of organization IDs
 * @params {boolean} cme - cme
 * @params {string} sort - sort
 * @params {string} sortBy - sortBy
 * @params {number} page - page
 * @params {string} videoType - videoType
 */
export const getVideos = ({
  specialty,
  organization,
  content_subtype,
  cme,
  videoType,
  sort,
  sortBy,
  page,
  limit,
  q,
  include,
  ids,
}) =>
  axios.get(`/video/list`, {
    params: {
      specialty,
      organization_id: organization,
      content_subtype,
      is_cme: cme,
      video_type: videoType,
      sort,
      sortBy,
      page,
      limit,
      q,
      include,
      ids,
    },
  });
/**
 * calls the GET api to get the video notes
 */
export const getVideoNotes = id => axios.get(`/video/get-notes/${id}`);
/**
 * calls the GET api to get all videos of specialty
 */
export const getSpecialtyVideos = (id, page, limit, content_subtype, sort, sortBy) =>
  axios.get(`/video/list?specialty=${id}`, {
    params: {
      page,
      limit,
      content_subtype,
      sort,
      sortBy,
    },
  });

/**
 * calls the GET api to get all 360vr videos
 */
export const get360Videos = (page, limit, specialty, sortBy) =>
  axios.get(`/video/list?video_type=360vr`, {
    params: {
      page,
      limit,
      specialty,
      sortBy,
    },
  });
/**
 * calls the GET api to get all notes
 */
export const getAllNotes = (sortBy, page, count, specialty, organization) =>
  axios.get(`/video/all-notes?sortBy=${sortBy}`, {
    params: {
      page,
      count,
      specialty,
      organization,
    },
  });

/**
 * calls the POST api to save the video notes
 */
export const addVideoNote = (id, note, videoTimeCode) => axios.post(`/video/add-note/${id}`, {note, videoTimeCode});

/**
 * calls the PUT api to edit the video note
 */
export const editVideoNote = (id, note) => axios.put(`/video/edit-note/${id}`, {note});

/**
 * calls the DELETE api to delete the video note
 */
export const deleteVideoNote = id => axios.delete(`/video/delete-note/${id}`);

/**
 * calls the GET api to get the playlist
 */
export const getAllPlaylists = (sortBy = 'DESC') => axios.get(`/playlist?sortBy=${sortBy}`);

/**
 * calls the POST api to create and add video to playlist
 */
export const addToPlaylist = (videoId, name, access_ids = []) => axios.post(`/playlist`, {videoId, name, access_ids});

/**
 * calls the PUT api to update the playlist
 */
export const massUpdatePlaylists = (videoId, data) => axios.put(`/playlist`, {videoId, data});

export const updatePlaylist = (id: number, data) => axios.put(`/playlist/${id}`, data);

/**
 * calls the POST api to add thumbs_up_down for a video
 */
export const updateThumbsUp = (videoId, type) => axios.post(`/like`, {videoId, type});

/**
 * calls the POST api to log watch history
 */
export const updateWatchHistory = history => axios.post(`/watch-history`, history);

/**
 * calls the GET api to get recommended videos
 */
export const getRecommendedVideos = videoId => axios.get(`/video/${videoId}/related-videos`);

/**
 * calls the GET api to get ml recommended videos
 * This is a mock endpoint that returns ML recommended videos
 */
export const getMLRecommendedVideos = videoId => axios.get(`/video/${videoId}/ml-recommended`);

/**
 * calls the GET api to get playlist videos
 */
export const getNextPlaylistVideos = (playlistId, order) =>
  axios.get(`/playlist/${playlistId}/up-next-videos/${order}`);

/**
 * calls cme questions
 */
export const getCmeQuestions = id => axios.get(`/video/${id}/get-cme`);

export const getQuiz = id => axios.get(`/video/${id}/get_quiz`);

/**
 * callss the content-request api
 */
export const requestContent = data =>
  axios.post('/video/content-request', {
    specialty: data.specialty,
    other_specialty: data.otherSpecialty,
    content: data.content,
    user_email: data.userEmail,
  });

export const getContentTypeList = () => axios.get('/video/list/content-subtype');

export const getEachSpecialtyContentSubtypeList = id => axios.get(`/video/list/content-subtype/${id}`);

export const getUserUploadVideos = () => axios.get('/uservideo/getVideo');

export const userUploadVideo = data => axios.post('/uservideo/upload', data);

export const moderateVideo = (id, data) => axios.put(`/uservideo/approve/${id}`, {data});

export const uploadVideo = formData => {
  return axios
    .post('uservideo/uploadVideo', formData, {
      headers: {'Content-Type': 'multipart/form-data'},
    })
    .then(
      res => {
        return res;
      },
      error => {
        return error.res;
      },
    );
};
export const uploadVideoWithS3 = data => axios.post('/uservideo/uploadWithUrl', data);

export const getUserUploadedVideo = brightcovId => axios.get(`/uservideo/getUserVideo`, {params: {brightcovId}});

export const getSignedS3Url = (name, putOrGet) => axios.get(`/uservideo/getSignedUrl`, {params: {name, putOrGet}});

export const getAllUsersVideos = () => axios.get('/uservideo/getAllUsersVideos');

export const deleteUserVideo = id => axios.delete(`/userVideo/delete/${id}`);

export const publishVideo = (id, isPublish) => axios.put(`/uservideo/publish/${id}`, {isPublish});

/**
 * calls the GET api to count current month watch history
 */
export const getWatchVideoCount = () => axios.get(`/watch-history/get-monthly-watch-count`);

export const chatgptHistory = (id, prompt, answer) => axios.post(`/chatgpt-history/${id}`, {prompt, answer});
