import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams, useHistory} from 'react-router-dom';

import {Container} from 'react-bootstrap';
import MainLayout from '../../layouts/MainLayout';
import DeskHeader from '../../layouts/components/DeskHeader';
import {Select} from '../../layouts/components/Select';
import MobileHeader from '../../layouts/components/MobileHeader';
import {TabBar, Tab} from '../../layouts/components/Tabs';
import LoaderWrapper from '../../layouts/components/LoaderWrapper';

import VideosTab from './VideosTab';
import DetailsTab from './DetailsTab';

import {useStore} from '../../store';

const PurchasedContent = observer(() => {
  const {videoStore, contentPackageStore} = useStore();
  const {contentPackages} = contentPackageStore;
  const videos = videoStore.videosList;

  const [selectedContentPackage, setSelectedContentPackage] = useState();

  const {id, action} = useParams();
  const loading = contentPackageStore.loading || videoStore.isLoading;
  const history = useHistory();

  // Set current CP based on params
  useEffect(() => {
    if (id && contentPackages.length) {
      const selected = contentPackageStore.getById(id);
      setSelectedContentPackage(selected);
    }
  }, [id, contentPackages, contentPackageStore]);

  // Load CPs
  useEffect(() => {
    contentPackageStore.fetchAll();
  }, [contentPackageStore]);

  // Load CP's videos
  useEffect(() => {
    if (!selectedContentPackage) return;
    const videoIds = selectedContentPackage.videos.map(v => v.id);
    videoStore.listVideos({ids: videoIds});
  }, [contentPackageStore, selectedContentPackage, videoStore]);

  // Select default CP if none is previously selected
  useEffect(() => {
    if (!id && contentPackages.length) {
      history.push(`/purchased/${contentPackages[0].id}/videos`);
    }
  }, [contentPackages, history, id]);

  const onDropdownSelect = selectedId => {
    history.push(`/purchased/${selectedId}/${action}`);
  };

  const renderHeaderContent = ({selectClass} = {}) => {
    return (
      <>
        <div className="title">Purchased</div>
        {contentPackages.length ? (
          <Select onChange={e => onDropdownSelect(e.target.value)} className={selectClass} value={id}>
            {contentPackages.map(cp => (
              <option value={cp.id}>{cp.name}</option>
            ))}
          </Select>
        ) : null}
      </>
    );
  };

  const renderTabBar = () => {
    return selectedContentPackage ? (
      <TabBar>
        <Tab href={`/purchased/${id}/videos`}>{selectedContentPackage?.name} Videos</Tab>
        <Tab href={`/purchased/${id}/details`}>Details</Tab>
      </TabBar>
    ) : null;
  };

  return (
    <MainLayout title="My Purchases">
      <DeskHeader className="container">{renderHeaderContent()}</DeskHeader>
      {renderTabBar()}
      <MobileHeader>{renderHeaderContent({selectClass: 'mobile-select'})}</MobileHeader>
      <LoaderWrapper loading={loading}>
        <Container>
          {action == 'videos' && videos ? <VideosTab videos={videos} /> : null}
          {action == 'details' && selectedContentPackage ? (
            <DetailsTab contentPackage={selectedContentPackage} />
          ) : null}
          {!action && !loading ? <p>You don&apos;t have any purchased videos</p> : null}
        </Container>
      </LoaderWrapper>
    </MainLayout>
  );
});

export default PurchasedContent;
