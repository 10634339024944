import React, {useState} from 'react';
import {Button, ProgressBar} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import Moment from 'react-moment';
import {observer} from 'mobx-react-lite';
import {
  Details,
  VideoTitle,
  ButtonGroup,
  ThumbnailImg,
  ThumbnailDiv,
  VideoCardHolder,
  TimeStamp,
  Note,
} from '../elements/notes';
import {useStore} from '../../../store';

type Props = {
  item: {
    created_at: String,
    id: Number,
    note: String,
    updated_at: String,
    video_id: Number,
    video_time_code: String,
    video: {
      id: Number,
      title: String,
      thumbnail: String,
      brightcove_id: Number,
      watchProgress: Number,
    },
  },
  deleteNote: Function,
};
// Video Card
const VideoCard = observer(({item, deleteNote}: Props) => {
  const {noteStore} = useStore();
  const {download} = noteStore;
  const [downloadLoader, setDownloadLoader] = useState(false);
  const location = useHistory();

  const openVideo = v => {
    if (v.video_time_code) {
      const time = v.video_time_code.split(':').reverse();
      let startTime = Number(time[0]);
      for (let i = 1; i < time.length; i += 1) {
        startTime += Number(time[i]) * 60 ** i;
      }
      location.push({
        pathname: `/video/${v.video.id}`,
        state: {
          start_time: startTime,
        },
      });
    } else {
      location.push(`/video/${v.video.id}`);
    }
  };

  const onDownload = () => {
    if (!downloadLoader) {
      setDownloadLoader(true);
      download(item.id).then(() => {
        setDownloadLoader(false);
      });
    }
  };

  return (
    <VideoCardHolder className="py-3">
      <div className="d-flex">
        <ThumbnailDiv onClick={() => openVideo(item)}>
          <ThumbnailImg src={item.video?.thumbnail} alt={item.video?.title} />
          <ProgressBar now={item.video.watchProgress} />
        </ThumbnailDiv>
        <div className="w-100">
          <TimeStamp>
            <Moment fromNow>{item.created_at}</Moment>
          </TimeStamp>
          <VideoTitle data-testid="go-to-detail" onClick={() => openVideo(item)}>
            <h5>{item.video?.title}</h5>
          </VideoTitle>

          <Details>
            <span>{item.video_time_code}</span>
            <Note>{item.note}</Note>
          </Details>
        </div>
        <ButtonGroup className="text-right">
          <Button variant="basic" className="a_downloadNote" onClick={onDownload} disabled={downloadLoader}>
            {downloadLoader ? (
              <span key="download-note-loading">
                <i className="fad fa-spinner-third fa-spin" />
              </span>
            ) : (
              <span key="download-note">
                <i className="far fa-download" />
              </span>
            )}
          </Button>
          <Button
            variant="basic"
            className="a_delete"
            data-testid="delete-bookmark"
            onClick={() => deleteNote(item.id)}
          >
            <i className="fa fa-trash" />
          </Button>
        </ButtonGroup>
      </div>
    </VideoCardHolder>
  );
});

export default VideoCard;
