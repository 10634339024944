import styled from 'styled-components';
import {Title1} from '../../../utils/styles/typography';

export const Main = styled.div`
  height: 100%;
  min-height: 100vh;
  margin-top: 50px;

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }
  @media screen and (max-width: 576px) {
    padding-top: 0 !important;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;

export const SpecialtyLabel = styled.div`
  max-width: 334px;
  margin-left: 25px;
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const BrowseHeader = styled(Title1)`
  @media screen and (max-width: 414px) {
    text-align: center;
  }

  font-weight: 700;
  line-height: 25px;
  margin-top: 40px;
  .closeMenu {
    position: absolute;
    right: 75px;
    color: #fff;
    background: none;
    border: none;
  }
`;

export const BrowseHeaderSub = styled(Title1)`
  @media screen and (max-width: 414px) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  font-weight: 700;
  line-height: 25px;
  margin-top: 40px;
  .closeMenu {
    position: absolute;
    right: 75px;
    color: #fff;
    background: none;
    border: none;
  }
`;

export const SpecialtyDiv = styled.div`
  .container-fluid > .row {
    clear: both;
  }
  .browseHighlight {
    font-size: 16px;
    font-weight: 700;
    @media screen and (max-width: 414px) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  #browseMenu {
    padding: 0px;
  }
  #browseMenu:hover {
    cursor: pointer;
  }
  .browseArrow {
    margin-right: 24px;
  }
  hr {
    overflow: hidden;
    width: 100%;
    float: left;
    background-color: rgba(255, 255, 255, 0.4);
    margin-top: 32px;
    margin-bottom: 40px;
  }
  a {
    color: ${({theme}) => theme.dropdown_item};
  }
  a:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  #Specialties {
    @media (min-width: 801px) {
      display: flex;
      flex-flow: column wrap;
    }
    font-size: 14px;
    height: 70vh;
    margin-left: 25px;
  }
`;
