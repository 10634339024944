import styled from 'styled-components';
import {Modal} from 'react-bootstrap';

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-size: 15px;
  padding-top: 60px;
  overflow-y: auto;
  height: 100vh;

  .sortDiv label {
    font-size: 13px;
    letter-spacing: 0.2px;
    line-height: 14px;
  }

  .a_create {
    height: 32px;
    width: 92px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.11px;
    line-height: 16px;
  }
  .courseBorder-top {
    border-top: ${({theme}) => (theme.name === 'light' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }
  /* Adapt the colors based on primary prop */
`;

export const Title = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 26px;
`;

export const PlaylistIcon = styled.div`
  width: 60px;
  height: 60px;
  background: ${({theme}) => theme.content_active_bg};
  border-radius: 50px;
  margin: auto;
  font-size: 32px;
  text-align: center;
  padding: 7px 0;
`;

export const EmptyList = styled.div`
  h4 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.33px;
    line-height: 24px;
    margin-top: 35px;
  }

  h6 {
    font-size: 18px;
    letter-spacing: -0.4px;
    line-height: 24px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
    font-weight: normal;
  }
`;

export const UpdateModal = styled(Modal)`
  .modal-dialog {
    max-width: 400px;
    margin: 7.75rem auto !important;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;

    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-footer {
      border: none !important;
    }
    input[type='text'] {
      background: #65686e;
      border: none;
      color: #fff;
      font-size: 14px;
      height: 40px;
      font-weight: 500;
      letter-spacing: -0.09px;
      line-height: 24px;
    }
    input[type='text']::placeholder {
      color: #fff;
    }
    label {
      font-weight: bold;
      margin-bottom: 18px;
      letter-spacing: -0.16px;
      line-height: 20px;
    }
    .close {
      color: ${({theme}) => theme.circle};
      opacity: 1;
      font-weight: 400;
    }
    .btn-outline-secondary {
      border-color: #4e505a;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.11px;
      padding: 3px 16px;
    }
    .a_renamePlaylistButton {
      padding: 3px 25px;
      opacity: 0.5;
      border-radius: 4px;
      background-color: #4e505a;
      color: #b8bbbe;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.11px;
    }
    .a_createPlaylistButton {
      height: 32px;
      width: 135px;
      background-color: #4e505a;
      color: #b8bbbe;
      padding-top: 4px;
      border: none;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.11px;
      line-height: 16px;
    }
    .a_removeVideoButton {
      color: ${({theme}) => theme.circle};
    }
  }

  #deleteCheckbox label {
    color: ${({theme}) => theme.circle};
    font-size: 15px;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: normal;
  }

  &.renamePlaylistModal .modal-body,
  &.deletePlaylistModal .modal-body {
    padding-top: 0;
  }
  &.deletePlaylistModal p {
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
  }
  &.deletePlaylistModal .a_deletePlaylistButton {
    margin-top: 15px;
    color: ${({theme}) => theme.circle};
  }
  .validationButton {
    border: 2px solid #0677d8;
    color: #fff;
    min-width: 100px;
    :hover {
      background: #0677d8;
    }
  }
  .reject,
  delete {
    color: #9d0a0a;
    border: 2px solid #9d0a0a;
    :hover {
      background: #9d0a0a;
      color: #fff;
    }
  }
  .commentText {
    min-height: 40px;
    max-height: 100px;
    overflow: auto;
    padding: 8px;
    width: 98%;
    outline: none;
    border: none;
    border-radius: 12px;
    background: #000;
    color: #fff;
    &::-webkit-scrollbar {
      width: 0px;
      height: 159px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e6e9f0;
    }
  }
`;

export const PlaylistCard = styled.div`
  width: 100%;
  height: 100%;
  min-height: 140px;
  border-radius: 8px;
  background-color: #2e3038;
  padding: 24px;
  padding-top: 45px;
  position: relative;
  z-index: 1;

  div {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: 8px;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
    color: #dcdee1;
  }
`;

export const PlaylistVideoCard = styled.div`
  background-color: ${({theme}) => theme.card_bg};
`;

export const InformationDiv = styled.div`
  background: ${({theme}) => theme.card_bg};
  padding-top: 60px;
  min-height: 220px;
  box-shadow: inset 0 -1px 0 0 #4e505a;

  .breadcrumb {
    background: transparent;
    padding-left: 0;
  }
  .breadcrumb-item a {
    color: ${({theme}) => theme.text};
    font-weight: normal;
    font-size: 12px;
    letter-spacing: -0.12px;
    line-height: 16px;
  }
  .breadcrumb-item.active,
  .breadcrumb-item + .breadcrumb-item::before {
    color: ${({theme}) => theme.text};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.12px;
    line-height: 22px;
    vertical-align: top;
    display: inline-block;
    max-width: 600px;
    max-height: 16px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: nowrap;
  }
  p {
    font-size: 15px;
    letter-spacing: -0.1px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  }
  .dropdown-toggle::after {
    border: none;
  }
  .dropdown {
    text-align: right;
    top: calc(50% - 19px);
  }
  .dropdown-menu {
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    padding: 0;
  }
  .dropdown-item {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
    border-bottom: 1px solid #4e505a;
    padding: 10px 15px;
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
  }
  .dropdown-item:last-child {
    border-bottom: none;
  }
  .dropdown-item:hover {
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
  }
  #playlistMenu {
    padding: 0px 10px;
  }
  .a_playlist-menu {
    color: #b8bbbe;
  }
`;

export const PlaylistTitle = styled.h3`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.62px;
  line-height: 46px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 92px;
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 30px 8px 16px;
  border-radius: 30px;
  width: 191px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  font-weight: 500;

  &:active,
  &:focus {
    outline: 0;
  }
`;

export const ThumbnailDiv = styled.div`
  margin-right: 20px;
  .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }
`;

export const DetailDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 15px;
`;

export const ThumbnailImg = styled.img`
  width: 168px;
  height: 95px;
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 15px;
`;

export const ButtonGroup = styled.div`
  width: 230px;

  .a_remove,
  .a_bookmark {
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-right: 15px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  }
`;

export const VideoTitle = styled.div`
  height: 28px;

  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }
`;

export const Organization = styled.h6`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-top: 0px;
  margin-bottom: 4px;
  min-height: 20px;
  font-weight: normal;
`;

export const Specialty = styled.h6`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  min-height: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: normal;
`;

export const Details = styled.p`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-bottom: 0;

  & .icon-cme {
    margin-right: 4px;
    margin-bottom: 4px;
    width: 19px;
    @media screen and (max-width: 576px) {
      width: 14px;
    }
  }
`;

export const VideoCard = styled.div`
  .container {
    position: relative;
  }

  .card-border-bottom {
    border-bottom: ${({theme}) => (theme.name === 'light' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  }

  :hover {
    border: 1px solid #30a0ff;
    background-color: ${({theme}) => theme.card_bg};
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.5);
  }
  .drag-icon {
    display: none;
    position: absolute;
    top: calc(50% - 7px);
    left: -10px;
  }
  :hover .drag-icon {
    display: block;
  }
  .card-padding {
    padding: 16px 0;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 15px !important;
  line-height: 19px;
  padding-top: 7px !important;
  padding-bottom: 7px;
  margin-bottom: 0;
  color: #fff;
  position: relative;
  padding-left: 5px;
  cursor: pointer;

  input[type='checkbox'] {
    visibility: hidden;
  }

  &:hover input ~ span {
    box-shadow: 0 0 3px #008aff;
  }

  input:checked ~ span {
    background-color: #008aff;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 5px;
    bottom: 3px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CustomCheckbox = styled.span`
  position: absolute;
  top: 10px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: transparent;
  border: 1px solid #008aff;
  box-shadow: 0 0 2px #008aff;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;
