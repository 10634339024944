import React, {useState, useEffect, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Card, ToggleButton} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {runInAction} from 'mobx';

import {useQuery} from '../../../utils/helper';

import {
  Wrapper,
  TitleNumberDiv,
  TitleText,
  SectionTitle,
  PlanButtonGroup,
  PlanButtonText,
  DurationButtonGroup,
  SummaryDesc,
  HorizontalDivider,
} from '../style';
import {amplitude} from '../../../utils/Amplitude';
import {useStore} from '../../../store';
import {ButtonXLarge} from '../../../utils/styles/typography';

const product_arr = [
  // Removed Premium Plan(Temp)

  // {
  //   has_giftcard: false,
  //   is_premium: true,
  //   css_selector: 'premium-product',
  //   name: 'Premium Discount Plan',
  //   name_with_tag: (
  //     <>
  //       <strong>Premium</strong>
  //     </>
  //   ),
  //   description: ``,
  //   onSelectProduct: (products, prices, selectPrice) => {
  //     const product = products?.find(p => p?.name === process.env.REACT_APP_STRIPE_PREMIUM_PLAN_NAME);
  //     const defaultPrice = prices.find(
  //       price => price?.metadata?.id && price?.metadata?.has_giftcard !== 'true' && price?.product === product?.id,
  //     );

  //     selectPrice(defaultPrice);
  //   },
  // },

  {
    has_giftcard: false,
    is_premium: false,
    css_selector: 'standard-product',
    name: 'Standard Plan',
    name_with_tag: <strong>Standard</strong>,
    // description: <span style={{color: '#FF5964'}}>This plan does not include CME</span>,
    description: '',
    onSelectProduct: (products, prices, selectPrice) => {
      const defaultPrice = prices.find(price => price?.metadata?.id === '1yr-standard-plan-subscription');

      selectPrice(defaultPrice);
    },
  },
  {
    has_giftcard: false,
    is_premium: false,
    css_selector: 'free-product',
    name: 'Free Plan',
    name_with_tag: <strong>Free</strong>,
    description: '',
    onSelectProduct: (products, prices, selectPrice) => {
      const defaultPrice = prices.find(price => price?.metadata?.id === 'free-plan-subscription');
      selectPrice(defaultPrice);
    },
  },
];

type Props = {
  activeRegistrationStep: string,
  usecase: string,
  navigate: (e?: React.MouseEvent<HTMLButtonElement>) => void,
};
const PlanSelection = ({activeRegistrationStep, usecase, navigate}: Props) => {
  const location = useHistory();
  const {authStore, subscriptionStore, registrationUIStore, accountStore} = useStore();
  const {
    selectedPrice,
    onSelectPrice,
    setActiveStep,
    selectedProduct,
    onSelectProduct,
    onClickGiftCard,
    isSelectGiftCard,
  } = authStore;
  const {account} = accountStore;
  const {currentCampaign} = registrationUIStore;
  const {products, fetchStripeProducts, prices, setUpgradeCompletionFalse, getGiftCardPrices} = subscriptionStore;
  const [filterProducts, setFilterProducts] = useState(products);
  const [filterPrices, setFilterPrices] = useState(prices);
  const query = useQuery();
  const queryPriceId = query.get('price_id');
  const onContinue = () => {
    if (usecase === 'accountsettings') {
      setUpgradeCompletionFalse();
      location.push(`/profile/payment`);
      amplitude.getInstance().logEvent('subscription-upgraded');
    } else if (selectedPrice) {
      // need user id for this--won't get this until account step
      // sendHubpostToPlanName();
      setActiveStep('account');
      amplitude.getInstance().logEvent('plan-selected-v3', {name: selectedPrice?.nickname});
    }
  };

  useEffect(() => {
    fetchStripeProducts();
  }, [fetchStripeProducts]);

  useEffect(() => {
    onClickGiftCard(true);
  }, [onClickGiftCard]);

  useEffect(() => {
    if (selectedPrice) {
      return;
    }
    const defaultProduct = filterProducts?.find(product => product?.name.includes('Standard'));
    onSelectProduct(defaultProduct);
    let defaultPrice = defaultProduct?.prices.find(price => price.metadata.product_default === 'true');
    // Giftcard price not valid for plan upgrades.
    // User cannot select a gift card price if they are in an active plan or are trialing.
    const canSelectGCPrice = usecase !== 'accountsettings' || !account?.is_subscribed;
    if (!canSelectGCPrice && defaultPrice?.metadata?.has_giftcard === 'true') {
      defaultPrice = defaultProduct?.prices.find(
        price => price?.name === process.env.REACT_APP_STRIPE_PREMIUM_PLAN_NAME,
      );
    }
    onSelectPrice(defaultPrice);
  }, [filterProducts, onSelectProduct, onSelectPrice, usecase, account?.is_subscribed, selectedPrice]);
  useEffect(() => {
    let allowed_prices = prices;
    let allowed_products = products;

    // User cannot select a gift card price if they are in an active plan or are trialing.
    const canSelectGCPrice = usecase !== 'accountsettings' || !account?.is_subscribed;
    if (!canSelectGCPrice) {
      const premium_product = products.find(product => product?.name.includes('Premium'));
      allowed_prices = premium_product?.prices?.filter(price => price?.metadata?.has_giftcard !== 'true') || [];
      allowed_products = [premium_product];
      if (
        account?.subscription?.name === 'Free Monthly Plan' ||
        account?.subscription?.name.includes('Basic') ||
        !account?.subscription?.name
      ) {
        const standard_product = products.find(product => product?.name.includes('Standard'));
        const standardPrice = standard_product?.prices?.filter(price => price?.metadata?.has_giftcard !== 'true') || [];
        allowed_prices = [...allowed_prices, ...standardPrice];
        allowed_products = [...allowed_products, standard_product];
      }
    }
    if (currentCampaign && allowed_products.length > 0) {
      setFilterProducts(
        allowed_products.filter(
          product => _.intersection(_.map(product?.prices, 'id'), currentCampaign.price_ids).length > 0,
        ),
      );
      setFilterPrices(allowed_prices.filter(price => currentCampaign.price_ids.includes(price.id)));
    } else {
      setFilterProducts(allowed_products);
      setFilterPrices(allowed_prices);
    }
    // If a free user logins and try to upgrade the plan remove the free plan from the product_arr in account settings page
    if (usecase === 'accountsettings') {
      product_arr.splice(1, 1);
      if (account?.subscription?.name?.includes('Standard')) {
        product_arr.splice(1, 1);
      }
      const premium_product = products.find(product => product?.name.includes('Premium'));
      const defaultPrice = premium_product?.prices.find(price => price.metadata.product_default === 'true');
      // defaultPrice = premium_product?.prices.find(
      //   price => price?.name === process.env.REACT_APP_STRIPE_PREMIUM_PLAN_NAME,
      // );

      onSelectPrice(defaultPrice);
    }
  }, [currentCampaign, products, prices, usecase, account?.is_subscribed, account?.subscription?.name, onSelectPrice]);
  const selectPrice = useCallback(
    (price_or_id, goToNextStep = false) => {
      let price = price_or_id;
      if (typeof price_or_id === 'string') {
        price = filterPrices?.find(p => p.id === price_or_id);
      }
      if (!price) {
        return;
      }
      const product = products.find(p => price.product === p.id);
      runInAction(() => {
        onSelectProduct(product);
        onSelectPrice(price);
        onClickGiftCard(price?.metadata?.has_giftcard === 'true');
        if (goToNextStep) {
          setActiveStep('account');
          amplitude.getInstance().logEvent('plan-selected-v3', {name: price?.nickname});
        }
      });
    },
    [filterPrices, onClickGiftCard, onSelectPrice, onSelectProduct, products, setActiveStep],
  );
  useEffect(() => {
    if (!selectedPrice?.id && queryPriceId) {
      selectPrice(queryPriceId, true);
    }
  }, [selectedPrice?.id, queryPriceId, selectPrice]);

  const calculatePrices = price => {
    let gift_card_amount = 0;
    const giftCardPrices = getGiftCardPrices(price);
    if (giftCardPrices?.length > 0) {
      gift_card_amount = Math.floor(giftCardPrices[0].unit_amount / 100);
    }
    const selected_price_amount = Math.floor((price.unit_amount || price.amount) / 100 + gift_card_amount);
    return {gift_card_amount, selected_price_amount};
  };

  const _renderGiftCardPrices = product => {
    if (!selectedPrice) {
      const defaultPrices = filterPrices?.find(price => price.has_giftcard === 'true');
      const defaultPrice = defaultPrices?.length > 0 ? defaultPrices[0] : null;
      onSelectPrice(defaultPrice);
      return <></>;
    }

    return (
      <>
        <DurationButtonGroup toggle>
          {filterPrices
            ?.filter(price => {
              if (price.product !== product?.id) return false;

              if (isSelectGiftCard && price?.metadata?.has_giftcard === 'true') {
                return true;
              }
              return false;
            })
            .sort((a, b) => parseInt(a.metadata.display_order, 10) - parseInt(b.metadata.display_order, 10))
            .map(price => {
              const {selected_price_amount, gift_card_amount} = calculatePrices(price);
              return (
                <ToggleButton
                  type="radio"
                  key={price.id}
                  className={`mt-2 duration ${selectedPrice.id === price.id ? 'durationFocused' : ''}`}
                  variant={
                    selectedPrice?.id === price.id ||
                    (selectedProduct?.metadata?.display_name !== product?.metadata?.display_name &&
                      product?.metadata?.default_monthly_amount === price?.metadata?.monthly_amount)
                      ? 'primary'
                      : 'secondary'
                  }
                  id={price?.metadata?.id}
                  onChange={() => {
                    onSelectPrice(price);
                  }}
                >
                  <p className="duration_year">
                    {price?.metadata?.display_name.includes('yr')
                      ? price?.metadata?.display_name.replace('yr', 'Year')
                      : price?.metadata?.display_name}
                  </p>
                  <p className="duration_text mb-2 mt-2">
                    <span className="duration_price">${selected_price_amount}</span>
                  </p>
                  <p className="duration_text">
                    <span className="duration_giftCard">with ${gift_card_amount} gift card</span>
                  </p>
                </ToggleButton>
              );
            })}
        </DurationButtonGroup>
      </>
    );
  };
  const _renderProductPrices = product => {
    if (!selectedPrice || (!isSelectGiftCard && selectedPrice?.metadata?.has_giftcard === 'true')) {
      const defaultPrice = filterPrices?.find(
        price => price?.metadata?.has_giftcard !== 'true' && price?.product === product?.id,
      );
      onSelectPrice(defaultPrice);
      return <></>;
    }

    return (
      <DurationButtonGroup toggle>
        {filterPrices
          ?.filter(price => {
            if (price.product !== product?.id) return false;

            if (!price?.metadata?.id) return false;

            if (!isSelectGiftCard && price.metadata?.has_giftcard !== 'true') {
              return true;
            }
            return false;
          })
          .sort((a, b) => parseInt(a.metadata.display_order, 10) - parseInt(b.metadata.display_order, 10))
          .map(price => {
            return (
              <ToggleButton
                type="radio"
                key={price.id}
                className={`mt-2 duration ${selectedPrice.id === price.id ? 'durationFocused' : ''}`}
                variant={
                  selectedPrice?.id === price.id ||
                  (selectedProduct?.metadata?.display_name !== product?.metadata?.display_name &&
                    product?.metadata?.default_monthly_amount === price?.metadata?.monthly_amount)
                    ? 'primary'
                    : 'secondary'
                }
                id={price?.metadata?.id}
                onChange={() => {
                  onSelectPrice(price);
                }}
              >
                <p className="duration_year">
                  {price?.metadata?.display_name.includes('yr')
                    ? price?.metadata?.display_name.replace('yr', 'Year')
                    : price?.metadata?.display_name}
                </p>
                <p className="duration_text mb-2 mt-2">
                  <span className="duration_price">{price?.metadata?.monthly_amount}</span>
                  /mo
                </p>
                <p className="duration_text">
                  <span className="duration_giftCard">{price?.metadata?.price_description}</span>
                </p>
              </ToggleButton>
            );
          })}
      </DurationButtonGroup>
    );
  };
  const renderSummaryCard = () => {
    return (
      <Card className="small-card" onClick={navigate}>
        <Card.Body>
          <div className="d-flex align-items-center" id="card-title">
            <TitleNumberDiv>
              <span>1</span>
            </TitleNumberDiv>
            <TitleText>Plan Selection</TitleText>
            <HorizontalDivider />
            <SummaryDesc>{selectedPrice?.nickname}</SummaryDesc>
          </div>
        </Card.Body>
      </Card>
    );
  };
  return (
    <Wrapper>
      {activeRegistrationStep === 'plan' ? (
        <Card>
          <Card.Body>
            <div className="d-flex align-items-center mb-4" id="card-title">
              <TitleNumberDiv>
                <TitleText>1</TitleText>
              </TitleNumberDiv>
              <TitleText>Plan Selection</TitleText>
            </div>
            <div id="card-content">
              <div>
                <SectionTitle>SELECT PLAN</SectionTitle>
              </div>
              <PlanButtonGroup vertical toggle>
                {product_arr.map(plan => {
                  let focused = false;
                  const isGCPrice = selectedPrice?.metadata?.has_giftcard === 'true';
                  const isPremiumPrice = selectedPrice?.nickname?.toLowerCase()?.includes('premium');
                  // Complicated since the premium product is really two products: one with a giftcard
                  // and one without.
                  if (isGCPrice === plan.has_giftcard && plan.is_premium === isPremiumPrice) {
                    // Selected price has matching gift card eligibiltiy and premium status.
                    focused = true;
                  }
                  if (
                    usecase === 'accountsettings' &&
                    account.is_subscribed &&
                    (plan.name === 'Free Plan' || plan.name === 'Basic Plan')
                  ) {
                    return <></>;
                  }
                  const canSelectGCPrice = usecase !== 'accountsettings' || !account?.is_subscribed;
                  if (!canSelectGCPrice && plan.has_giftcard) {
                    return <></>;
                  }
                  return (
                    <ToggleButton
                      type="radio"
                      id={`toggle-${plan?.css_selector}`}
                      name="plan"
                      className={`mt-2 plan ${plan?.css_selector} ${
                        focused && (plan?.name === selectedProduct?.name || plan?.is_premium) ? 'focus' : ''
                      }`}
                      onChange={() => plan.onSelectProduct(filterProducts, filterPrices, selectPrice)}
                    >
                      <PlanButtonText>
                        <p className="plan_name mb-0">{plan?.name_with_tag}</p>
                        <p className="cme_desc">{plan?.description}</p>
                      </PlanButtonText>
                    </ToggleButton>
                  );
                })}
              </PlanButtonGroup>
            </div>

            <div className="mt-3" id="card-content">
              <SectionTitle>SELECT DURATION</SectionTitle>
              {isSelectGiftCard ? (
                <div>{_renderGiftCardPrices(selectedProduct)}</div>
              ) : (
                <div>{_renderProductPrices(selectedProduct)}</div>
              )}
            </div>

            <div className="button-div">
              <ButtonXLarge
                variant={selectedProduct && selectedPrice ? 'primary' : 'secondary'}
                className="a_continueToPaymentPageButton mt-3"
                id="continue-to-payment-page-button"
                onClick={onContinue}
              >
                Next
              </ButtonXLarge>
            </div>
          </Card.Body>
        </Card>
      ) : (
        renderSummaryCard()
      )}
    </Wrapper>
  );
};

PlanSelection.propTypes = {
  usecase: PropTypes.string,
};
PlanSelection.defaultProps = {
  usecase: 'registration',
};

export default observer(PlanSelection);
