import React from 'react';
import {Button, Card, Row, Col, Container} from 'react-bootstrap';
import {Wrapper, ReasonHeading, ReasonCaption} from '../elements/cancelSubscription';

type Props = {
  reason: String,
  setCancelReason: Function,
  onContinue: Function,
};
const SubscriptionReason = ({reason, setCancelReason, onContinue}: Props) => {
  const reasonList = [
    {
      key: 1,
      text: 'Found a better alternative',
    },
    {
      key: 2,
      text: 'Too expensive',
    },
    {
      key: 3,
      text: 'Not enough content',
    },
    {
      key: 4,
      text: 'Product performance',
    },
    {
      key: 5,
      text: 'Customer service',
    },
    {
      key: 6,
      text: 'Other',
    },
  ];

  const onClickReason = item => {
    setCancelReason(item);
  };

  const onClickContinue = () => {
    onContinue();
  };

  return (
    <Container className="min-vh-100 pt-5">
      <Wrapper>
        <div className="text-center text-white mb-4">
          <ReasonHeading>Why are you leaving GIBLIB?</ReasonHeading>
          <ReasonCaption>We appreciate your feedback!</ReasonCaption>
        </div>
        <Card className="bg-transparent border-0 w-100 a_reasonOptions-div">
          <Card.Body>
            <Row>
              {reasonList.map(item => (
                <Col md={4} sm={6} key={item.key} className="px-3 a_ReasonOptions">
                  <Button
                    variant={reason?.key === item.key ? 'primary' : 'dark'}
                    id={`training-list-item-${item.key}`}
                    className="mb-4 w-100 rounded-lg py-3 a_btn-reason"
                    onClick={() => onClickReason(item)}
                  >
                    {item.text}
                  </Button>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
        <div className="w-100 d-flex justify-content-center a_gotoNextButton">
          <Button
            variant={reason ? 'primary' : 'dark'}
            disabled={!reason}
            id="reason-Next"
            className="a_goToFeedbackPage btn-bottom"
            onClick={() => onClickContinue()}
          >
            Next
          </Button>
        </div>
      </Wrapper>
    </Container>
  );
};

export default SubscriptionReason;
