import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import {Row, Col, Form, Container, Spinner} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {Breadcrumbs, PageTitle, ActiveCrumb, Crumb, CourseFilter, SearchVideoDiv, Line} from '../elements/search';
import VideoTile from '../../../components/VideoTile';
import {useStore} from '../../../store';
import AlertComponent from '../../../components/AlertComponent';
import Sort from '../../../components/Sort';
import Pagination from '../../../components/Pagination';
import Filter from '../../../components/Filter';

// Search Videos page
const SearchVideos = observer(() => {
  const params = useLocation();
  const text = new URLSearchParams(params.search).get('q');
  const {searchStore, alertStore} = useStore();
  const {
    allVideoResults,
    getVideoResults,
    isLoading,
    isVR,
    setVR,
    listContentTypes,
    contenttypeList,
    setSelectedContenttype,
    selectedContenttypes,
    selectedContenttypeList,
    specialtiesList,
    subSpecialtiesList,
    organizationsList,
    selectedOrganizationList,
    onRemoveOrganization,
    selectedOrganizationIds,
    setSelectedOrganizations,
    setSelectedSpecialties,
    selectedSpecialtiesList,
    selectedSpecialtyIds,
    onRemoveSpecialty,
    selectedSubSpecialtyIds,
    selectedSubSpecialtiesList,
    setSelectedSubSpecialties,
    onRemoveSubSpecialty,
    onRemoveContenttype,
    listOrganizations,
    listSpecialties,
    pageDecrement,
    pageIncrement,
    page,
    changeCount,
    count,
    videoCount,
    resetPage,
  } = searchStore;
  const [cmeValue, setCMEValue] = useState(null);
  const [sortValue, setSortValue] = useState('release_desc');
  const [sort, setSort] = useState('DESC');
  const [sortType, setSortType] = useState('release_date');

  const {message, status, resetAlert} = alertStore;

  useEffect(() => {
    getVideoResults(text, 0, cmeValue ? true : null, sortValue);
  }, [
    getVideoResults,
    text,
    sortValue,
    cmeValue,
    selectedOrganizationIds,
    selectedSpecialtyIds,
    selectedSubSpecialtyIds,
    selectedContenttypes,
    page,
    isVR,
    count,
  ]);

  useEffect(() => {
    listContentTypes();
    listOrganizations();
    listSpecialties();
  }, [listContentTypes, listOrganizations, listSpecialties]);

  const changeSortby = option => {
    switch (option) {
      case 'date_desc':
        setSortValue('release_desc');
        setSort('DESC');
        setSortType('release_date');
        resetPage();
        break;
      case 'date_asc':
        setSortValue('release_asc');
        setSort('ASC');
        setSortType('release_date');
        resetPage();
        break;
      case 'name_asc':
        setSortValue('asc');
        setSort('ASC');
        setSortType('title');
        resetPage();
        break;
      case 'name_desc':
        setSortValue('desc');
        setSort('DESC');
        setSortType('title');
        resetPage();
        break;
      default:
        break;
    }
  };

  const sortValues = [
    {text: 'Name (A-Z)', id: 'name_asc', order: 'ASC', sort: 'title'},
    {text: 'Name (Z-A)', id: 'name_desc', order: 'DESC', sort: 'title'},
    {text: 'Date added (Newest)', id: 'date_desc', order: 'DESC', sort: 'release_date'},
    {text: 'Date added (Oldest)', id: 'date_asc', order: 'ASC', sort: 'release_date'},
  ];

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedOrganizations(selectedIDs, false);
    } else if (type === 2) {
      setSelectedSpecialties(selectedIDs, false);
    } else if (type === 3) {
      setSelectedSubSpecialties(selectedIDs, false);
    } else {
      setSelectedContenttype(selectedIDs, false);
    }
  };

  const removeOrganization = item => {
    onRemoveOrganization(item.id);
  };

  const removeContenttype = item => {
    onRemoveContenttype(item.id);
  };

  const removeSubSpecialty = item => {
    onRemoveSubSpecialty(item.id);
  };

  const removeSpecialty = item => {
    onRemoveSpecialty(item.id);
  };

  const organization = {
    selectedOrganizationList,
    selectedOrganizations: selectedOrganizationIds,
    organizationFilter: organizationsList,
    removeOrganization,
  };

  const specialties = {
    selectedSpecialtiesList,
    selectedSpecialties: selectedSpecialtyIds,
    specialityFilter: specialtiesList,
    removeSpecialty,
  };

  const subSpecialties = {
    selectedSubSpecialtiesList,
    selectedSubSpecialties: selectedSubSpecialtyIds,
    subSpecialityFilter: subSpecialtiesList,
    removeSubSpecialty,
  };

  const contenttype = {
    contenttypeFilter: contenttypeList,
    selectedContenttypes,
    selectedContenttypeList,
    removeContenttype,
  };

  const setCME = item => {
    resetPage();
    setCMEValue(item);
  };

  const cme = {
    isCme: cmeValue,
    setCME,
  };

  const vr = {
    isVR,
    setVR,
  };

  const clearFunc = () => {
    selectOptions([], 0);
    selectOptions([], 1);
    selectOptions([], 2);
    selectOptions([], 3);
    setCMEValue(false);
    setVR(false);
  };

  useEffect(() => {
    clearFunc();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SearchVideoDiv
        className={classNames({
          a_resultDiv: isLoading,
        })}
      >
        <div className="d-flex container">
          <Breadcrumbs className="a_searchVideoCrumb">
            <Crumb href="/home">Search</Crumb>
            <ActiveCrumb href="#">{text}</ActiveCrumb>
          </Breadcrumbs>
        </div>
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}

        <Container>
          <div className="a_filterHeadDiv">
            <PageTitle className="mr-auto a_searchVideoHead col-4">Videos with &apos;{text || ' '}&apos;</PageTitle>
            <Line />
            <CourseFilter>
              <Form>
                <Form.Row>
                  <Form.Group className="mr-auto ml-0">
                    <Filter
                      count={videoCount}
                      organization={organization}
                      selectOptions={selectOptions}
                      specialties={specialties}
                      subSpecialties={subSpecialties}
                      contenttype={contenttype}
                      cme={cme}
                      vr={vr}
                      clearFunc={clearFunc}
                    />
                  </Form.Group>
                  <Form.Group className="m-0 mr-2">
                    <Sort change={changeSortby} sort={sortValues} sortOrder={sort} sortBy={sortType} />
                  </Form.Group>
                  <Form.Group className="m-0 mr-2">
                    <Pagination
                      totalCount={videoCount}
                      page={page}
                      countPerPage={count}
                      pageIncrement={pageIncrement}
                      pageDecrement={pageDecrement}
                      changeCount={changeCount}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </CourseFilter>
          </div>
          <Row className="mb-5">
            {allVideoResults?.map(el => (
              <Col key={el.id} id={el.id} md={3} lg={4} xl={3}>
                <VideoTile key={el.title} item={el} storeName="searchStore" storeVar="allVideoResults" />
              </Col>
            ))}
            {!allVideoResults?.length && !isLoading && <p className="px-4 pt-5">No results</p>}
          </Row>
          {!allVideoResults?.length && isLoading && (
            <div className="text-center my-3 mt-5 pt-5">
              <Spinner animation="grow" className="mt-5" />
            </div>
          )}
        </Container>
        {allVideoResults?.length && isLoading ? (
          <div className="text-center my-3">
            <Spinner animation="grow" className="mt-5" />
          </div>
        ) : (
          <></>
        )}
      </SearchVideoDiv>
    </>
  );
});

export default SearchVideos;
