import {Modal} from 'react-bootstrap';
import styled from 'styled-components';

const DarkModal = styled(Modal)`
  text-align: left;
  backdrop-filter: blur(20px);
  .modal-dialog {
    width: 336px;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;

    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
    }
    .modal-body {
      padding: 20px;
      .icon-wrapper {
        margin-bottom: 1rem;
      }
      .icon-img {
        width: 50px;
        height: 50px;
      }
      .a_closeAlertModal {
        border: none;
        background: transparent;
      }
    }
    .cancel-btn {
      border: 1px solid rgba(255, 255, 255, 0.4);
      background-color: transparent;
      :hover,
      :active {
        border: 1px solid #46484d;
        background-color: #46484d;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
    .skip-btn {
      opacity: 0.8;
      :hover,
      :active {
        opacity: 1;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
    button {
      padding: 10px 16px;
      border-radius: 5px;
      font-size: 14px;
      line-height: 14px;
      color: #ffffff;
      font-weight: bold;
    }
  }
`;

export default DarkModal;
