// @flow
/* eslint-disable react/no-danger */
import React, {useState, useEffect, useRef} from 'react';
import {observer, useLocalStore} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';
import {Button, OverlayTrigger, Card, Spinner, Dropdown, Form, ProgressBar} from 'react-bootstrap';
import classNames from 'classnames';
import GiblibIcon from '../../assets/images/GBLB_Logo_Mark_FullColor.png';
import {
  CardWrapper,
  Badge,
  BadgeNew,
  DurationText,
  Title,
  Text,
  TitlePopover,
  TextPopover,
  PopoverDiv,
  Description,
  TranscriptCard,
  CardBottom,
} from './styles';
import './style.css';
import {useStore} from '../../store';
import PlaylistPopover from '../PlaylistPopover';
import LocalStorageService from '../../utils/LocalStorageService';
import {secondsToTime, millisecondToTime} from '../../utils/helper';
import CMEIcon from '../../assets/icons/CME.svg';
import CMEDefaultIcon from '../../assets/icons/CMEDefault.svg';
import CMEInProgressIcon from '../../assets/icons/CMEInProgress.svg';
import userUpload from '../../assets/icons/userVideo.svg';
import CMECompletedIcon from '../../assets/icons/CMECompleted.svg';
import CMERevokedIcon from '../../assets/icons/CMERevoked.svg';
import VRIcon from '../../assets/icons/360vr.svg';
import UnlockIcon from '../../assets/icons/unlock.svg';
import TileOverlay from '../TileOverlay';
import UpgradeModal from '../UpgradeModal';
import {amplitude} from '../../utils/Amplitude';

const POPOVER_WIDTH = 386;
const POPOVER_HEIGHT = 420;
type Props = {
  item: {
    id: number,
    title: string,
    thumbnail: string,
    cme: boolean,
    ownership: string,
    tags: string,
    published_at: Date,
    release_date: string,
    filmed_at: Date,
    duration: number,
    active: boolean,
    video_type: string,
    content_type: string,
    short_description: string,
    long_description: string,
    brightcove_id: string,
    fromMyVideo: boolean,
    specialty: {
      id: number,
      display_name: string,
    },
    playlist: any[],
    bookmark: {id: string} | null,
    isExtraDataLoaded: boolean,
    organizationCME: {
      CMEs: any[],
      id: number,
      credit_count: number,
    },
    watchProgress: number,
  },
  type: number,
  source: number,
  sourceTitle?: String,
  storeName: string,
  storeVar?: string,
  starttime: number,
  transcript?: [],
  searchTerm?: string,
  mentionCount?: number,
  referrer: string,
};

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log('Video popover error boundary received error', error, errorInfo);
  }

  render() {
    const {hasError} = this.state;
    if (hasError) {
      // Nothing shown for the popover until user reloads page.
      // The effect is that the pop up box is not shown on hover
      // for this particular video.
      return <></>;
    }
    const {children} = this.props;
    return children;
  }
}
// VideoTile component
const VideoTile = observer(
  ({
    item,
    type,
    source,
    sourceTitle,
    storeName,
    starttime,
    storeVar,
    transcript,
    searchTerm,
    mentionCount,
    referrer,
    brightcove_id,
    fromMyVideo,
  }: Props) => {
    const triggerRef = useRef(null);

    const [popoverOpen, togglePopover] = useState(false);
    const [target, setTarget] = useState(null);
    const [description, setDescription] = useState('');
    const [bookmarkIcon, setBookmarkIcon] = useState(false);
    const [updating, setUpdating] = useState(false);

    const [language, setLanguage] = useState('ENG');
    const [languageCode, setLanguageCode] = useState('en');

    const loggedInUser = LocalStorageService.getUser();
    const [unlock, setUnlock] = useState(false);

    const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
    const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
    const [userState, setUserState] = useState(false);

    const displayLanguage = term => {
      switch (term) {
        case 'en':
          return 'ENG';
        case 'ko':
          return 'KOR';
        case 'es':
          return 'SPA';
        case 'zh-CN':
          return 'CHI';
        case 'ja':
          return 'JPN';
        case 'fr':
          return 'FRE';
        case 'pt':
          return 'POR';
        case 'ru':
          return 'RUS';
        default:
          return term;
      }
    };
    useEffect(() => {
      const long = item?.long_description ? item?.long_description : '';
      const short = item?.short_description ? item?.short_description : '';
      setDescription(long + short);
    }, [item]);

    const state = useLocalStore(() => ({
      isNew: false,
    }));
    // new badge (displays for 30 days from release date)
    useEffect(() => {
      const createdVideo = new Date(item?.release_date);
      const after30Video = new Date(createdVideo.getFullYear(), createdVideo.getMonth(), createdVideo.getDate() + 30);
      const today = new Date();
      if (after30Video > today) {
        state.isNew = true;
      }
    }, [state, item]);
    const timer = useRef(null);
    const ref = useRef(null);
    const location = useHistory();
    const store = useStore();
    const {addToPlaylist, updatePlaylist, toggleVideoTileBookmark, setReferrer} = store.videoStore;
    const {fetchItem} = store[storeName];
    const {anonymousAccess} = store.accessStore;
    const {account} = store.accountStore;

    useEffect(() => {
      if (!loggedInUser && item?.access_level === 'Free') setUnlock(true);
      else if (anonymousAccess) setUnlock(false);
      else if (
        loggedInUser &&
        (!account?.access || account?.access?.status === 'Inactive') &&
        (!account?.subscription || account?.subscription.status === 'cancelled')
      )
        setUnlock(false);
    }, [loggedInUser, account, item, anonymousAccess]);

    useEffect(() => {
      if (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free'))
        setUserState(true);
    }, [account]);

    const onClickTile = () => {
      if (source) {
        setReferrer(`${referrer}_${source}`);
        const eventProperties = {
          'carousel name': `${sourceTitle}`,
        };
        amplitude.getInstance().logEvent('carousel-video-clicked', eventProperties);
      } else {
        setReferrer(referrer);
      }
      location.push({
        pathname: `/video/${item?.id}`,
      });
    };
    /* istanbul ignore next */
    const onHover = async event => {
      setTarget(event.currentTarget);
      if (window.outerWidth > 890) {
        timer.current = setTimeout(() => {
          togglePopover(true);
        }, 500);
      }

      if (brightcove_id && !item?.duration) {
        await fetchItem('userVideo', 'userVideo', item?.id, storeVar, brightcove_id);
      } else if (!brightcove_id && item?.duration) {
        await fetchItem('videos', type, item?.id, storeVar);
      }
    };
    /* istanbul ignore next */
    const onHoverLeave = () => {
      togglePopover(false);
      clearTimeout(timer.current);
    };

    const openVideo = video => {
      if (source) {
        setReferrer(`${referrer}_${source}`);
        const eventProperties = {
          'carousel name': `${sourceTitle}`,
        };
        amplitude.getInstance().logEvent('carousel-video-clicked', eventProperties);
      } else {
        setReferrer(referrer);
      }
      if (starttime) {
        location.push({
          pathname: `/video/${video.id}`,
          state: {
            start_time: starttime,
          },
        });
      } else {
        location.push(`/video/${video.id}`);
      }
    };

    const goToVideo = video => {
      location.push({
        pathname: `/video/${item?.id}`,
        state: {
          start_time: video.start_time,
        },
      });
      const eventProperties = {
        'carousel name': `${sourceTitle}`,
      };
      amplitude.getInstance().logEvent('carousel-video-clicked', eventProperties);
    };

    const createAndAddToPlaylist = async data => {
      setUpdating(true);
      await addToPlaylist(item?.id, data.listname, false);
      await fetchItem('videos', type, item?.id, storeVar);
      setUpdating(false);
    };

    const updateExistingPlaylist = async data => {
      setUpdating(true);
      await updatePlaylist(item?.id, data, false);
      await fetchItem('videos', type, item?.id, storeVar);
      setUpdating(false);
    };

    const compareSpecialties = (a, b) => {
      if (a.name === b.name) {
        return 0;
      }
      if (a.name > b.name) {
        return 1;
      }
      return -1;
    };

    const renderOverlayTrigger = () => {
      return !updating ? (
        <OverlayTrigger
          trigger="click"
          placement="auto"
          overlay={props => (
            <PlaylistPopover
              props={props}
              videoPlaylist={item?.playlist}
              onExisting={updateExistingPlaylist}
              onNew={createAndAddToPlaylist}
              userState={userState}
              showUpgradeModal={isShowPlaylistUpgradeModal}
              onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
            />
          )}
          rootClose
        >
          <Button variant="basic" className="a_playlist py-0 px-3" onClick={() => setShowPlaylistUpgradeModal(true)}>
            <i className="fak fa-list-ul" />
          </Button>
        </OverlayTrigger>
      ) : (
        <div className="px-3">
          <Spinner animation="border" size="sm" variant="primary" />
        </div>
      );
    };
    useEffect(() => {
      if (item?.bookmark?.length) setBookmarkIcon(true);
      else setBookmarkIcon(false);
    }, [item]);

    const toggleBookmark = () => {
      if (loggedInUser) {
        if (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) {
          setShowBookmarkUpgradeModal(false);
          toggleVideoTileBookmark(item, bookmarkIcon).then(() => {
            setBookmarkIcon(!bookmarkIcon);
          });
        } else setShowBookmarkUpgradeModal(true);
      } else location.push('/login');
    };
    const renderCMEIcon = () => {
      if (!loggedInUser)
        return <object data={CMEIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;

      const cmes = item?.organizationCME?.CMEs;
      const cmeStatus = cmes?.length && cmes[0]?.status;

      switch (cmeStatus) {
        case 'In Progress':
          return <object data={CMEInProgressIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
        case 'Completed':
          return <object data={CMECompletedIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
        case 'Revoked':
          return <object data={CMERevokedIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
        default:
          return <object data={CMEDefaultIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
      }
    };

    return (
      <>
        <UpgradeModal
          access="bookmark"
          isShow={isShowBookmarkUpgradeModal}
          onHideModal={() => setShowBookmarkUpgradeModal(false)}
        />
        <CardWrapper onMouseLeave={onHoverLeave} unlock={unlock}>
          <Card className="mx-2 my-2 hover-card border-0" onMouseEnter={onHover} onClick={onClickTile} ref={triggerRef}>
            <Card.Img className="hover-card-img" src={item?.thumbnail} alt={item?.title} />
            <Card.ImgOverlay>
              <Badge>
                {item?.ownership?.includes('giblib original') || item?.ownership?.includes('co-owned') ? (
                  <img className="icon-giblib" src={GiblibIcon} alt="giblib" />
                ) : (
                  ''
                )}
                {!item?.uploader_id && state.isNew ? <BadgeNew>NEW</BadgeNew> : ''}
                {item?.video_type?.includes('360vr') ? <img src={VRIcon} className="icon-VR" alt="vr" /> : ''}
              </Badge>
              {fromMyVideo && (
                <Badge>
                  <div className={`ApprovedIndicator ${item?.status}`}>{item?.status?.toLowerCase()}</div>
                </Badge>
              )}
              {unlock ? (
                <div className="unlock-wrapper">
                  <img src={UnlockIcon} alt="unlock-icon" />
                </div>
              ) : null}
              <DurationText>
                {item?.isuploaded ? millisecondToTime(item?.duration) : secondsToTime(item?.duration)}
              </DurationText>
            </Card.ImgOverlay>
            <Card.Body>
              <Title className="my-2">{item?.title}</Title>
              {item?.specialties ? (
                <Text>
                  {item?.specialties
                    ? item?.specialties
                        ?.filter(sp => sp?.level === 'specialty')
                        ?.sort(compareSpecialties)
                        ?.map((s, i) => <>{i !== 0 ? `, ${s.display_name}` : s.display_name}</>)
                    : !account?.subscription && item?.specialty?.display_name}
                </Text>
              ) : (
                <Text>{item?.specialty?.display_name}</Text>
              )}
              <Text className="f-expert">
                {item?.experts?.map((el, i) => (
                  <>{i !== 0 ? `, ${el?.name}` : el?.name}</>
                ))}
              </Text>
              {transcript ? (
                <Description className="mt-auto mb-1">
                  <mark className="searchterm">{searchTerm}</mark> mentioned {mentionCount} times
                </Description>
              ) : null}
              <CardBottom className="justify-content-between">
                <div className="d-flex align-items-center">
                  {item?.organizationCME && renderCMEIcon()}
                  {item?.organizationCME && (
                    <span className="card-desc mr-2">{item?.organizationCME?.credit_count}</span>
                  )}
                  <i className="fal fa-calendar" />

                  <span className={`desktop card-desc ${item?.organizationCME && 'date-span'}`}>
                    {new Date(item?.release_date ? item?.release_date : item?.filmed_at).toLocaleString('en-US', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="mr-2">
                    <i className="far fa-comment-dots " />
                  </span>
                  {item?.commentCount}
                </div>
                <div className="d-flex align-items-center">
                  {item?.isuploaded ? (
                    <div>
                      <img className="userUploadImage" src={userUpload} alt="userImage" />
                    </div>
                  ) : (
                    <div className="organization-img-div">
                      <img className="organization-img" src={item?.organization?.thumbnail} alt="organization-img" />
                    </div>
                  )}
                </div>
              </CardBottom>
            </Card.Body>
          </Card>
          <ErrorBoundary>
            <TileOverlay
              popoverOpen={popoverOpen}
              target={target}
              container={ref.current}
              containerPadding={20}
              placement="top-end"
              //
              popoverClassName="video-popover"
              popoverHeight={POPOVER_HEIGHT}
              popoverWidth={POPOVER_WIDTH}
              triggerRef={triggerRef}
            >
              <PopoverDiv width={POPOVER_WIDTH} height={POPOVER_HEIGHT} unlock={unlock}>
                <Card className="popover-card">
                  <Card.Img
                    className="popover-card-img"
                    src={item?.gif_link ? item?.gif_link : item?.thumbnail}
                    alt={item?.title}
                  />
                  {/* {fromMyVideo && (
                    <Badge>
                      <div className={`ApprovedIndicatoronHover ${item?.status}`}>{item?.status?.toLowerCase()}</div>
                    </Badge>
                  )} */}
                  <Card.ImgOverlay onClick={() => openVideo(item)}>
                    <Button onClick={() => openVideo(item)} variant="basic">
                      {!item?.gif_link ? <i className="fas fa-play" /> : null}
                    </Button>
                  </Card.ImgOverlay>
                  {item?.watchProgress > 0 && <ProgressBar now={item?.watchProgress} />}
                  <Card.Body>
                    <div className="d-flex justify-content-between mb-2">
                      {item?.isExtraDataLoaded && (
                        <>
                          <Badge className="d-flex align-items-center">
                            {item?.ownership?.includes('giblib original') || item?.ownership?.includes('co-owned') ? (
                              <img src={GiblibIcon} className="icon-giblib" alt="giblib" />
                            ) : (
                              ''
                            )}
                            {!item?.uploader_id && state?.isNew ? <BadgeNew>NEW</BadgeNew> : ''}
                            {item?.video_type?.includes('360vr') ? (
                              <img src={VRIcon} className="icon-VR" alt="vr" />
                            ) : (
                              ''
                            )}
                          </Badge>
                          <div className="d-flex align-items-center popover-top">
                            {loggedInUser ? (
                              renderOverlayTrigger()
                            ) : (
                              <Button
                                variant="basic"
                                className="a_playlist pl-0 py-0 px-3"
                                onClick={() => location.push('/login')}
                              >
                                <i className="fak fa-list-ul" />
                              </Button>
                            )}
                            <Button
                              variant="basic"
                              onClick={() => toggleBookmark()}
                              className="a_bookmark px-0 py-0"
                              data-tip="Bookmark"
                              data-for="videoActions"
                            >
                              {bookmarkIcon ? (
                                <span key="video_bookmark">
                                  <i className="fas fa-bookmark" />
                                </span>
                              ) : (
                                <span key="video_bookmark_active">
                                  <i className="fal fa-bookmark" />
                                </span>
                              )}
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                    <TitlePopover
                      className={classNames({
                        a_transcript: transcript,
                        videotitle: true,
                      })}
                      onClick={() => openVideo(item)}
                    >
                      {item?.title}
                    </TitlePopover>
                    <TextPopover className="specialties">
                      <div className="specialties-tip">
                        {item?.specialties ? (
                          item?.specialties
                            ?.filter(sp => sp?.level === 'specialty')
                            ?.sort(compareSpecialties)
                            ?.map((s, i) => <>{i !== 0 ? `, ${s.display_name}` : s.display_name}</>)
                        ) : (
                          <div className="specialties-tip">{item?.specialty?.display_name}</div>
                        )}
                      </div>
                    </TextPopover>
                    <Text className="f-expert">
                      {item?.experts?.map((el, i) => (
                        <>{i !== 0 ? `, ${el?.name}` : el?.name}</>
                      ))}
                    </Text>
                    {transcript ? (
                      <>
                        <Dropdown>
                          <Dropdown.Toggle variant="basic" id="selectLanguage" className="a_selectLanguage">
                            <span>{language}</span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            onChange={e => {
                              setLanguageCode(e.target.value);
                              setLanguage(e.target.labels[0].innerText);
                            }}
                          >
                            {[...new Set(transcript.map(lang => lang.language))].map(selected => (
                              <Form.Check
                                type="radio"
                                label={displayLanguage(selected)}
                                id={selected}
                                value={selected}
                                name="language"
                                checked={language === displayLanguage(selected)}
                              />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>

                        <div
                          id="a_transcriptsListDiv"
                          className={classNames('a_transcriptsListDiv', {
                            a_transcriptsListScroll: transcript.length > 3,
                          })}
                        >
                          {transcript
                            .filter(ts => ts.language === languageCode)
                            .map(result => {
                              return (
                                <TranscriptCard onClick={() => goToVideo(result)}>
                                  <p className="a_time">
                                    {`${Math.floor(result.start_time / 60)}:${(result.start_time % 60).toFixed(0)}`}
                                  </p>
                                  <p
                                    className="a_transcript"
                                    dangerouslySetInnerHTML={{
                                      __html: result.highlightText,
                                    }}
                                  />
                                </TranscriptCard>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <Description>{description}</Description>
                    )}
                    <CardBottom className="justify-content-between">
                      <div className="d-flex align-items-center">
                        {item?.organizationCME && renderCMEIcon()}
                        {item?.organizationCME && (
                          <span className="card-desc mr-4">
                            {item?.organizationCME?.credit_count}{' '}
                            <span className="cme-count-credit">
                              Credit
                              {item?.organizationCME?.credit_count > 1 ? 's' : ''}
                            </span>
                          </span>
                        )}
                        <i className="fal fa-calendar" />
                        {item?.duration && (
                          <span className="desktop card-desc">
                            {new Date(item?.release_date ? item?.release_date : item?.filmed_at).toLocaleString(
                              'en-US',
                              {
                                month: 'long',
                                year: 'numeric',
                              },
                            )}
                          </span>
                        )}
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">
                          <i className="far fa-comment-dots " />
                        </span>
                        {item?.commentCount}
                      </div>
                      <div className="d-flex align-items-center">
                        {fromMyVideo || item?.isuploaded ? (
                          <>
                            <div>
                              <img className="userUploadImage" src={userUpload} alt="userImage" />
                            </div>
                          </>
                        ) : (
                          <div className="organization-img-div mr-2">
                            <img
                              className="organization-img"
                              src={item?.organization?.thumbnail}
                              alt="organization-img"
                            />
                          </div>
                        )}
                        <span className="organization">{item?.organization?.name ? item?.organization?.name : ''}</span>
                      </div>
                    </CardBottom>
                  </Card.Body>
                </Card>
              </PopoverDiv>
            </TileOverlay>
          </ErrorBoundary>
        </CardWrapper>
      </>
    );
  },
);

VideoTile.defaultProps = {
  transcript: null,
  storeVar: '',
  searchTerm: '',
  mentionCount: null,
};
export default VideoTile;
