import styled from 'styled-components';

const StyledChatGpt = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .search-form {
    margin: 1em 0;
    font-size: 14px;
    .form-control {
      height: 32px;
      background: #65686e;
      color: #fff;
      opacity: 0.4;
      border: 0;

      &:focus {
        opacity: 1;
      }
    }
    .form-control::placeholder {
      color: #e9e9e9;
    }
  }

  .a_upgradeButton {
    height: 32px;
    width: 105px;
  }

  .a_regenerate {
    width: max-content;
    background: transparent;
    border: 1px solid #6f7077;
    margin: 10px auto;
  }

  .answer-section span {
    color: #6f7077;
    font-size: 16px;
    margin-right: 3px;
  }
  .botSubmit {
    background: transparent;
    border: none;
    margin-left: -35px;
  }

  .input-group-append {
    z-index: 10;
  }

  .answer-container {
    height: 100%;
    max-height: calc(100% - 380px);
    overflow-y: auto;
    margin-bottom: 20px;
  }
`;

export default StyledChatGpt;
