import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Navbar, Row} from 'react-bootstrap';
import {AccessButton, LoginButton} from '../style';
import logo from '../../../assets/images/GBLB_Logo_Horizontal_Notag_White_FullColor.png';

const verifyEmailPage = '/register/email';
const registerPage = '/register';

function RegNavBar() {
  const emailMatch = useRouteMatch(verifyEmailPage);
  const registerMatch = useRouteMatch(registerPage);

  return (
    <Navbar as={Row} className="nav mx-0">
      <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
        <img alt="logo" src={logo} height="20" className="d-inline-block align-top" />
      </Navbar.Brand>
      {registerMatch?.isExact && (
        <AccessButton as={Link} to="/access" id="register-navbar-login" className="ml-auto">
          Institutional Registration
        </AccessButton>
      )}
      {(emailMatch?.isExact || registerMatch?.isExact) && (
        <LoginButton as={Link} to="/login" id="register-navbar-login" className="a_LoginButton">
          Log in
        </LoginButton>
      )}
    </Navbar>
  );
}

export default observer(RegNavBar);
