// @flow
import {makeAutoObservable, runInAction} from 'mobx';
import axios from 'axios';
import {followItem, unfollowItem, getSpecialties, createBookmark, deleteBookmark} from '../../api/course';
import {getOrganization, getOrganizationCourses} from '../../api/organization';
import type {Organization as OrganizationBasic, Course, Specialty, Playlist} from '../../utils/types';
import {getAllPlaylists} from '../../api/video';
import {getCourse} from '../../api/courseLanding';
import {addCoursePlaylist, addNewCoursePlaylist} from '../../api/playlist';
import ErrorStore from '../error/errorStore';

const {CancelToken} = axios;

type Organization = OrganizationBasic & {
  course: Course[],
};
class OrganizationCoursesStore {
  errorStore: ErrorStore;

  organization: Organization = {};

  organizationCourses: Array<Course> = [];

  specialtiesList: Array<Specialty> = [];

  selectedSpecialtiesList: Array<Specialty> = [];

  selectedSpecialtyIds: Array<number> = [];

  sortBy: string = 'created_at';

  sortOrder: string = 'DESC';

  coursesLoading: boolean = false;

  hasMoreCourses: boolean = true;

  playlist: Array<Playlist> = [];

  source;

  setOrganization = (organization: Organization) => {
    this.organization = organization;
  };

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  setOrganizationCoursesObservables = (courses: Array<Course>) => {
    this.organizationCourses = courses;
  };

  resetOrganizationCourses = () => {
    this.organizationCourses = [];
  };

  // fetchItem on hover
  fetchItem = async (type: string, carouselId: number, itemId: number) => {
    this.source?.cancel('Operation canceled by nextItem.');
    this.source = CancelToken.source();
    let itemList = [];
    let item = null;
    try {
      switch (type) {
        case 'courses':
          item = await getCourse(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.course || null);
          itemList = this.organizationCourses;
          break;
        default:
          break;
      }
      this.updateItem(itemList, item);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // hover update
  updateItem = (itemList: any[], item: any) => {
    const itemIndex = itemList.findIndex(listItem => +listItem?.id === +item?.id);
    if (itemIndex !== -1) {
      /* eslint-disable no-param-reassign */
      itemList[itemIndex] = {
        isExtraDataLoaded: true,
        ...item,
      };
      itemList = [...itemList];
    }
    this.organizationCourses = [...this.organizationCourses];
  };

  /** Fetch organization */
  getOrganization = async (id: number) => {
    try {
      const res = await getOrganization(id);
      const {status, organization} = res.data;
      if (status) this.setOrganization(organization);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow course */
  followCourse = async (id: number) => {
    try {
      const resp = await followItem(id, 'course');
      const {status, follow} = resp.data;
      runInAction(() => {
        if (status) {
          const index = this.organizationCourses.findIndex(exp => exp?.id === id);
          this.organizationCourses[index] = {
            ...this.organizationCourses[index],
            is_following: true,
            followId: follow?.id,
            follow,
          };
          this.organizationCourses = [...this.organizationCourses];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow course */
  unFollowCourse = async (id: number) => {
    try {
      const resp = await unfollowItem(id, 'course');
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          const index = this.organizationCourses.findIndex(exp => exp?.id === id);
          this.organizationCourses[index] = {
            ...this.organizationCourses[index],
            is_following: false,
            followId: null,
            follow: [],
          };
          this.organizationCourses = [...this.organizationCourses];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Bookmark Course */
  onCreateBookmark = async selectedItem => {
    try {
      const resp = await createBookmark(selectedItem.id, 'course');
      const {status, bookmark} = resp.data;
      runInAction(() => {
        if (status) {
          const {id} = bookmark;
          const index = this.organizationCourses.findIndex(org => org?.id === selectedItem.id);
          this.organizationCourses[index] = {
            ...this.organizationCourses[index],
            bookmark: [{id}],
          };
          this.organizationCourses = [...this.organizationCourses];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete course bookmark */
  onDeleteBookmark = async selectedItem => {
    const {id} = selectedItem;
    try {
      const resp = await deleteBookmark(id, 'course');
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          const index = this.organizationCourses.findIndex(org => org?.id === id);
          this.organizationCourses[index] = {
            ...this.organizationCourses[index],
            bookmark: null,
          };
          this.organizationCourses = [...this.organizationCourses];
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setPlaylistObservables = (playlist: Array<Playlist>) => {
    this.playlist = playlist;
  };

  getAllPlaylists = async () => {
    try {
      const res = await getAllPlaylists();
      if (res.data.status) {
        this.setPlaylistObservables(res.data.playlist);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addCourseToPlaylist = async (courseId: number, data) => {
    try {
      const resp = await addCoursePlaylist(courseId, data);
      const {status} = resp.data;
      if (status) {
        this.getAllPlaylists();
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addCourseToNewPlaylist = async (courseId: number, name: string) => {
    try {
      const resp = await addNewCoursePlaylist(courseId, name);
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          const {playlist, playlistvideos} = resp.data;
          const videos = playlistvideos.map(pv => ({
            id: pv.video_id,
          }));
          const index = this.playlist.findIndex(pl => pl?.id === playlist.id);
          if (index !== -1) {
            this.playlist[index] = {
              ...playlist,
              videos,
            };
            this.playlist = [...this.playlist];
          } else {
            this.playlist.push({...playlist, videos});
          }
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch organization courses */
  onFetchAllOrganizationCourses = async (id: number, offset: number) => {
    this.coursesLoading = true;
    try {
      const res = await getOrganizationCourses({
        id,
        limit: 9,
        sort: this.sortBy,
        sortBy: this.sortOrder,
        page: Math.ceil(offset / 9) + 1,
        specialty: this.selectedSpecialtyIds,
        type: 'tile',
      });
      runInAction(() => {
        this.coursesLoading = false;

        const {courses} = res.data;
        if (courses.length) {
          const coursesExtend = [...this.organizationCourses, ...courses];
          this.setOrganizationCoursesObservables(coursesExtend);
          this.hasMoreCourses = true;
        } else {
          this.hasMoreCourses = false;
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setSelectedSpecialties = Ids => {
    this.selectedSpecialtyIds = Ids;
    this.selectedSpecialtiesList = this.specialtiesList.filter(item => Ids.includes(item.id));
    this.organizationCourses = [];
  };

  /**
   * set specialties list observables
   * @param {array} specialties
   */
  setSpecialtiesObservables = (specialties: Array<Specialty>) => {
    this.specialtiesList = specialties;
  };

  /** Fetch All specialties */
  listSpecialties = async () => {
    try {
      const resp = await getSpecialties();
      let {specialties} = resp.data;
      specialties = specialties.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setSpecialtiesObservables(specialties);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Remove Specialty */
  onRemoveSpecialty = (id: number) => {
    this.selectedSpecialtiesList = this.selectedSpecialtiesList.filter(item => item.id !== id);
    this.selectedSpecialtyIds = this.selectedSpecialtyIds.filter(item => item !== id);
    this.organizationCourses = [];
  };

  changeSort = (option: string) => {
    switch (option) {
      case 'Newest':
        this.sortBy = 'created_at';
        this.sortOrder = 'DESC';
        break;
      case 'Oldest':
        this.sortBy = 'created_at';
        this.sortOrder = 'ASC';
        break;
      default:
        break;
    }
    this.organizationCourses = [];
  };
}

export default OrganizationCoursesStore;
