import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {useForm} from 'react-hook-form';
import {useStore} from '../../../store';
import {RegisterDiv, Wrapper} from '../style';
import Analytics from '../../../analytics';
import logo from '../../../assets/images/GBLB_Logo_Horizontal_Notag_White_FullColor.png';
import {ButtonXLarge, Title1, Caption} from '../../../utils/styles/typography';
import {amplitude} from '../../../utils/Amplitude';

// eslint-disable-next-line react/prop-types
const AccountDetails = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const {authStore} = useStore();
  const {user, accountRegistering, onSetAccountDetails} = authStore;
  const {register, handleSubmit, errors, watch} = useForm();
  const watchAllFields = watch();

  const onSubmit = data => {
    onSetAccountDetails({...data, email: data.email || user?.email});
  };

  useEffect(() => {
    return Analytics.purchaseConversionEvent;
  }, []);

  useEffect(() => {
    if (
      (errors.firstName && errors.firstName.type === 'required') ||
      errors.lastName ||
      (errors.password && errors.password.type === 'required') ||
      (errors.confirmPassword && errors.confirmPassword.type === 'required')
    ) {
      setErrorMsg('This field is required.');
    } else if (
      (errors.password && errors.password.type === 'minLength') ||
      (errors.confirmPassword && errors.confirmPassword.type === 'minLength')
    ) {
      setErrorMsg('The passwords should have at least 8 characters.');
    } else if (errors.confirmPassword && errors.confirmPassword.type === 'validate') {
      setErrorMsg('The passwords does not match.');
    }
  }, [errors]);

  const AccountCreated = () => {
    amplitude.getInstance().logEvent('account-created-v3');
  };

  return (
    <Wrapper className="d-flex flex-column align-items-center mx-auto">
      <Helmet>
        <title>Account details</title>
        <meta name="description" content="Giblib account details" />
      </Helmet>
      <div className="text-white text-center">
        <Title1>
          Welcome to &nbsp;
          <img src={logo} alt="GIBLIB" height="20" className="d-inline-block align-top logo" />
        </Title1>
      </div>
      <Card text="white" className="mb-3 mt-3  mt-sm-5">
        <Card.Body>
          <div className="mb-3">
            <Title1>Create your account</Title1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Form.Group as={Col} sm={12}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="bg-transparent border-primary text-white"
                  type="text"
                  placeholder="Account Email"
                  name="firstName"
                  disabled
                  defaultValue={user?.email}
                  ref={register({required: true, minLength: 1})}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={6} controlId="account-first-name">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  className={`bg-transparent text-white ${user?.first_name && 'border-primary'}`}
                  type="text"
                  placeholder="Enter first name"
                  name="firstName"
                  defaultValue={user?.first_name}
                  ref={register({required: true, minLength: 1})}
                />
              </Form.Group>
              <Form.Group as={Col} sm={6} controlId="account-last-name">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  className="bg-transparent text-white"
                  type="text"
                  placeholder="Enter last name"
                  name="lastName"
                  defaultValue={user?.last_name}
                  ref={register({required: true})}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={12} controlId="account-password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  className="bg-transparent text-white"
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  ref={register({required: true, minLength: 8})}
                />
                <Caption className="mt-1">8 Character Min</Caption>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={12} controlId="account-confirm-password">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  className="bg-transparent text-white"
                  type="password"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: value => value === watch('password'),
                  })}
                />
              </Form.Group>
            </Row>
            <Row className="pt-5 pb-2 mt-3">
              <RegisterDiv>
                <ButtonXLarge
                  variant={
                    watchAllFields.firstName === '' ||
                    watchAllFields.lastName === '' ||
                    watchAllFields.password === '' ||
                    watchAllFields.confirmPassword === ''
                      ? 'secondary'
                      : 'primary'
                  }
                  disabled={accountRegistering}
                  id="continue-to-training-page-button"
                  className="a_continueToTrainingPageButton mb-2"
                  type="submit"
                  onClick={AccountCreated}
                >
                  Create
                </ButtonXLarge>
              </RegisterDiv>
            </Row>
            <Row>
              {errorMsg ? (
                <div className="text-center w-100">
                  <Caption className="text-danger">{errorMsg}</Caption>
                </div>
              ) : null}
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Wrapper>
  );
};

export default observer(AccountDetails);
