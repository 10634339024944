// @flow
import * as React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import nextIcon from '../../assets/icons/chevron-right-gray.svg';
import nextHoverIcon from '../../assets/icons/chevron-right-hover.svg';
import prevIcon from '../../assets/icons/chevron-left-gray.svg';
import prevHoverIcon from '../../assets/icons/chevron-left-hover.svg';

const NextDiv = styled.div`
  background-image: url(${nextIcon}) !important;
  background-repeat: no-repeat !important;
  background-size: 25px auto !important;
  width: 25px !important;
  height: 30px !important;

  &:hover {
    background-image: url(${nextHoverIcon}) !important;
  }

  &:before {
    content: '' !important;
  }
`;

const PrevDiv = styled.div`
  background-image: url(${prevIcon}) !important;
  background-repeat: no-repeat !important;
  background-size: 25px auto !important;
  width: 25px !important;
  height: 30px !important;

  &:hover {
    background-image: url(${prevHoverIcon}) !important;
  }

  &:before {
    content: '' !important;
  }
`;

export const NextArrow = (props: React.ElementProps<any>) => {
  const {className, style, onClick} = props;
  return (
    <NextDiv
      role="button"
      tabIndex={0}
      className={className}
      style={{...style, display: 'block'}}
      onClick={onClick}
      onKeyDown={onClick}
    />
  );
};

export const PreviousArrow = (props: React.ElementProps<any>) => {
  const {className, style, onClick} = props;
  return (
    <PrevDiv
      role="button"
      tabIndex={0}
      className={className}
      style={{...style, display: 'block'}}
      onClick={onClick}
      onKeyDown={onClick}
    />
  );
};

const settings = {
  dots: true,
  infinite: false,
  speed: 350,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <NextArrow />,
  prevArrow: <PreviousArrow />,
  responsive: [
    {
      breakpoint: 398,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 1149,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 1439,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        swipeToSlide: true,
      },
    },
  ],
};
type Props = {
  children: React.Node,
};

const SpecialSlider = ({children}: Props) => {
  return <Slider {...settings}>{children}</Slider>;
};

export default SpecialSlider;
