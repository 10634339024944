import styled from 'styled-components';

export const SelectTitle = styled.div`
  font-size: 13px;
  margin-right: 23px;
  @media screen and (max-width: 576px) {
    margin-right: 0;
    margin-top: 6px;
    font-size: 14px;
  }
`;

export const Select = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 30px 8px 16px;
  border-radius: 30px;
  max-width: 220px;
  height: 40px;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};

  @media screen and (max-width: 576px) {
    border: none;
    padding-right: 30px;
    width: auto;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
  }
`;
