import React from 'react';
import {Col, Container, Row, Form, Card, Navbar, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {useStore} from '../../store';
import logo from '../../assets/images/GBLB_Logo_Horizontal_Notag_White_FullColor.png';
import {ForgotPasswordModal, SendEmail, ForgotPasswordContainer} from './ForgotPassword';

const ForgotPassword = observer(() => {
  const {handleSubmit, register, watch, errors} = useForm();
  const {authStore} = useStore();
  const {
    resetPasswordStatus,
    forgotPasswordResponseText,
    onForgotPassword,
    resetForgotPasswordResponseText,
  } = authStore;

  /** calls the onForgotPassword function from the store
   * @params {object} data - contains email address
   */
  const onSubmit = data => {
    onForgotPassword(data);
  };

  const watchField = watch('email', false);
  const watchForgotPasswordResponseText = () => {
    if (forgotPasswordResponseText) {
      resetForgotPasswordResponseText();
    }
  };

  return (
    <ForgotPasswordContainer>
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar as={Row} className="nav mx-0">
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img alt="logo" src={logo} height="20" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Button
          as={Link}
          to="/login"
          variant="outline-light"
          id="register-navbar-login"
          className="ml-auto a_LoginButton"
        >
          Log In
        </Button>
      </Navbar>
      <Container>
        <ForgotPasswordModal>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={7} xl={6}>
              <Card className="mx-auto forgot-password-card">
                <Card.Body>
                  <Card.Title className="text-uppercase text-center">
                    <Link to="/">
                      <img src={logo} alt="GIBLIB" className="logo" height="16" />
                    </Link>
                  </Card.Title>
                  <h5 className="text-center">Forgot Password</h5>
                  {forgotPasswordResponseText && resetPasswordStatus ? (
                    <>
                      <p
                        id="forgot-Password-Response-Text"
                        className={classNames('text-center small mb-0 a_forgotPasswordResponseText', {
                          'send-success': resetPasswordStatus,
                          'text-danger': !resetPasswordStatus,
                        })}
                      >
                        {forgotPasswordResponseText}
                      </p>
                      <Card.Link href="/login" className="d-block text-center text-white back-to-login">
                        Back to Log In
                      </Card.Link>
                    </>
                  ) : (
                    <>
                      <Card.Text className="text-center forgot-password-description mx-auto">
                        Enter your email address and we&lsquo;ll send you a link to reset your password
                      </Card.Text>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                          <Form.Group as={Col} sm={12} controlId="emailAddress">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              sm={12}
                              type="text"
                              name="email"
                              placeholder="Enter email"
                              onChange={() => watchForgotPasswordResponseText()}
                              className={classNames(null, {
                                'error-email': errors.email || forgotPasswordResponseText,
                                'active-textbox': watchField,
                              })}
                              ref={register({
                                required: 'This field is required.',
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: 'Invalid email address',
                                },
                              })}
                            />
                            {errors.email && <small className="text-danger">{errors.email.message}</small>}
                            <p
                              id="forgot-Password-Response-Text"
                              className={classNames('small mb-0 a_forgotPasswordResponseText', {
                                'text-success': resetPasswordStatus,
                                'text-danger': !resetPasswordStatus,
                              })}
                            >
                              {forgotPasswordResponseText}
                            </p>
                          </Form.Group>
                        </Form.Row>
                        <SendEmail
                          type="submit"
                          id="clickSend"
                          disabled={errors.email}
                          className={
                            watchField && !errors.email && !forgotPasswordResponseText ? 'button-active' : null
                          }
                        >
                          Send Email
                        </SendEmail>
                        <Card.Link href="/login" className="d-block text-center text-white">
                          Back to Log In
                        </Card.Link>
                      </Form>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </ForgotPasswordModal>
      </Container>
    </ForgotPasswordContainer>
  );
});

export default ForgotPassword;
