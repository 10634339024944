import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {CMEPromotionDiv} from '../elements/home';

const CMEPromotion = () => {
  return (
    <Row className="w-100 justify-content-end mx-0">
      <Col className="col-lg-4">
        <CMEPromotionDiv>
          <p className="product_name mb-4">
            <strong>CME WITH A GIFT CARD</strong>
          </p>
          <p className="total_price mb-0">$1,990</p>
          <p className="giftCard_desc mt-0">Total Cost</p>
          <p className="giftCard_desc mt-0 font-italic">
            with <strong>$1,000</strong> Amazon or AMEX gift card
          </p>
          <div className="my-5">
            <a
              className="a_promotion_start_link"
              href={`/register?price_id=${process.env.REACT_APP_ONE_YEAR_CME_GIFT_CARD_PRICE_ID}`}
            >
              PURCHASE GIFT CARD
            </a>
          </div>
        </CMEPromotionDiv>
      </Col>
    </Row>
  );
};

export default CMEPromotion;
