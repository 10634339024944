import React, {useEffect, useState} from 'react';
import {Card, Form, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {useForm} from 'react-hook-form';
import {observer} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';
import {useStore} from '../../../store';
import './profession.css';
import {Wrapper, RegisterDiv} from '../style';
import Analytics from '../../../analytics';
import {ButtonXLarge, Title1} from '../../../utils/styles/typography';
import {amplitude} from '../../../utils/Amplitude';

const Profession = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {registrationUIStore, authStore} = useStore();
  const {
    professionList,
    getAllLists,
    registrationForm,
    setProfessionFields,
    onSetProfession,
    user,
    loading,
  } = authStore;

  const campaign = registrationUIStore.currentCampaign;
  const location = useHistory();

  const {register, watch, handleSubmit, errors, setValue} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      profession_id: registrationForm?.profession_id || user?.profession_id,
      otherProfession: registrationForm?.extraData?.other_profession,
    },
  });
  const watchProfField = watch('profession_id');
  const watchOtherProfField = watch('otherProfession');

  useEffect(() => {
    return Analytics.registerEmailConversionEvent;
  }, []);

  useEffect(() => {
    if (!professionList?.length) {
      setIsLoading(true);
      getAllLists(() => {
        setIsLoading(false);
      });
    }
  }, [errors, professionList, getAllLists]);

  useEffect(() => {
    if (campaign && professionList?.length) {
      if (!watchProfField && campaign.profession_id) {
        setValue('profession_id', campaign.profession_id);
      }
    }
  }, [campaign, professionList, setValue, watchProfField]);

  const onSubmit = data => {
    onSetProfession(data);
  };

  const onBack = () => {
    location.goBack();
  };

  const ProfessionCompleted = () => {
    amplitude
      .getInstance()
      .logEvent('profession-completed-v3', {name: registrationForm?.profession_id || user?.profession_id});
  };

  const renderProfessionForm = () => {
    if (watchProfField) {
      const selectedProfession = professionList?.find(p => p.id === +watchProfField);
      if (!selectedProfession) return null;
      setProfessionFields(selectedProfession);
      if (selectedProfession.specialty_type === 'other') {
        return (
          <Form.Group controlId="select-otherprofession">
            <Form.Label>Other Profession</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter profession"
              name="otherProfession"
              className={`bg-transparent step-field text-white ${watchOtherProfField && 'border-primary'} ${
                errors.otherProfession && 'border-danger'
              }`}
              ref={register({required: true})}
              isInvalid={!!errors.otherProfession}
            />
            {errors.otherProfession && (
              <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
            )}
          </Form.Group>
        );
      }
    }
    return null;
  };

  return (
    <Wrapper className="d-flex flex-column align-items-center profession mx-auto">
      <Helmet>
        <title>About you</title>
        <meta name="description" content="About you section" />
      </Helmet>
      <Card>
        <Card.Body>
          <Title1 className="mb-3">Choose your profession</Title1>
          {isLoading ? (
            <div style={{width: '20rem'}} className="d-flex justify-content-center align-items-center p-5 h-100">
              <Spinner animation="grow" />
            </div>
          ) : (
            <>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="select-profession">
                  <Form.Label className="font-weight-bold">Profession</Form.Label>
                  <Form.Control
                    as="select"
                    className={`bg-transparent step-field ${watchProfField && 'border-primary'} ${
                      errors.profession_id && 'border-danger'
                    }`}
                    name="profession_id"
                    ref={register({required: true})}
                    isInvalid={!!errors.profession_id}
                  >
                    <option value="">Select profession</option>
                    {professionList?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.display_name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.profession_id && (
                    <Form.Control.Feedback type="invalid">Please select field</Form.Control.Feedback>
                  )}
                </Form.Group>
                {renderProfessionForm()}
                {campaign ? <p className="campaign-profession-msg">{campaign.prompt}</p> : null}

                <RegisterDiv>
                  <ButtonXLarge
                    variant={!watchProfField && (errors.profession_id || errors.otherProfession) ? 'basic' : 'primary'}
                    disabled={(errors.profession_id || errors.otherProfession) && loading}
                    id="AboutYou-Continue"
                    className={`a_continueToPlansPageButton mb-2 ${watchProfField ? 'bg-primary' : 'bg-secondary'}`}
                    type="submit"
                    onClick={ProfessionCompleted}
                  >
                    Continue
                  </ButtonXLarge>
                  <ButtonXLarge variant="secondary" type="button" id="AboutYou-Goback" onClick={onBack}>
                    Go back
                  </ButtonXLarge>
                </RegisterDiv>
              </Form>
            </>
          )}
        </Card.Body>
      </Card>
    </Wrapper>
  );
};

export default observer(Profession);
