import styled from 'styled-components';
import {MenuSmall} from '../../utils/styles/typography';

export const Title = styled.h5`
  font-weight: bold;
  margin-top: 0.5rem;
  color: ${({theme}) => (theme.name === 'light' ? '#252730' : '#F9F9F9')};
`;

export const LinkText = styled(MenuSmall)`
  line-height: 14px;
  margin: 8px 0px;
  font-weight: 700;
`;

export const StyledDiv = styled.div`
  background: #181a21;
  color: #ffffff;
  height: 94vh;
  position: fixed !important;
  top: 0.5rem !important;
  z-index: 123;
  transform: translate(0px, 46px) !important;
  #sidebar_admin {
    margin-top: 44px;
  }
  .nav-item {
    width: 215px;
    font-size: 18px;
    display: inline;
  }
  .under-menu {
    padding-left: 12px;
  }
  .svg-inline--fa {
    margin-right: 10px;
    margin-left: 20px;
  }
  .mobile-login {
    text-align: center;
    margin: auto;
  }
  #login-button {
    text-align: center;
    margin: auto;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 5px;
    width: 68px;
    height: 36px;
  }
  .bottom-sidebar {
    bottom: 60px;
    position: absolute;
    left: 0;
    right: 0;
    .nav-link {
      width: 215px;
      margin-right: -15px;
      margin-left: -15px;
    }
    .tos {
      padding: 1.5em;
    }
    a {
      color: #ffffff;
    }
    .social-links .svg-inline--fa {
      display: inline-block;
      margin: 10px;
      border-radius: 100%;
      color: #ffffff;
      border: 1px solid #b8bbbe;
      height: 25px;
      width: 25px;
      padding: 5px;
    }
  }
  a.nav-link {
    color: #ffffff;
  }
  .nav-link {
    color: #ffffff;
    .svg-inline--fa {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
      width: 24px !important;
    }
    .mobile-bookmark {
      padding: 0 !important;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }
    .fa-home,
    .fa-globe,
    .fa-bookmark,
    .fa-books-medical,
    .fa-diploma,
    .fa-list-ul {
      display: inline;
    }
    .fa-home-lg-alt,
    .fa-globe-solid,
    .fa-bookmark-solid,
    .fa-books-medical-solid,
    .fa-diploma-1,
    .fa-list-ul-solid {
      display: none;
    }
  }
  .collapseText {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
    padding-left: 10px;
  }
  .dropdown-menu {
    position: absolute;
    height: 100vh;
    margin-left: 100% !important;
    margin-top: -43px !important;
    border-radius: 0 !important;
    z-index: 999999;
    background: ${({theme}) => (theme.body === '#FFF' ? '#EDEDED' : '#2E3038')};
    transform: translate(0px, 0px) !important;
  }
  .dropdown-menu .dropdown-item {
    color: ${({theme}) => (theme.body === '#FFF' ? '#252730' : '#F9F9F9')};
  }
  .dropdown-item:hover {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
    background: ${({theme}) => (theme.body === '#FFF' ? '#B8BBBE' : '#4e505a')};
  }
  .browse:focus-within > dropdown-menu,
  .browse:hover > dropdown-menu,
  .browse-specialty:hover {
    color: ${({theme}) => theme.text2};
    background: ${({theme}) => theme.nav_background};
    .svg-inline--fa {
      color: #0677d8;
    }
  }
  .lock:not(:hover) {
    color: #4e505a !important;
    white-space: nowrap;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    max-width: 200px;
    .bottom-sidebar .links-tablet {
      display: none;
    }
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 2em;
    vertical-align: 0em;
    content: '';
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }

  .active {
    background: ${({theme}) => (theme.body === '#FFF' ? '#B8BBBE' : '#4e505a')};
    .svg-inline--fa {
      color: #0677d8;
    }
  }
  #sidebar_purchases {
    background: linear-gradient(90deg, #008aff 0%, #9043ff 100%);
  }
  #sidebar_purchases.active,
  #sidebar_purchases:hover {
    background: #008aff;
    .svg-inline--fa {
      color: ${({theme}) => theme.text3};
    }
  }
  .navbar-nav .nav-link:hover {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
    background: ${({theme}) => theme.nav_background};

    .svg-inline--fa {
      color: ${({theme}) => theme.text2};
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
    }
    .fa-home,
    .fa-globe,
    .fa-bookmark,
    .fa-books-medical,
    .fa-diploma,
    .fa-list-ul {
      display: none;
    }
    .fa-home-lg-alt,
    .fa-globe-solid,
    .fa-bookmark-solid,
    .fa-books-medical-solid,
    .fa-diploma-1,
    .fa-list-ul-solid {
      display: inline;
    }
  }
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

export const Badge = styled.button`
  border: none;
  background: transparent;
  .svg-inline--fa {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
  }
`;

export const BrowseDiv = styled.div`
  background: #181a21;
  color: #ffffff;
  height: 94vh;
  // overflow-y: auto;
  top: 0.5rem !important;
  z-index: 123;
  position: fixed !important;
  transform: translate(0px, 46px) !important;
  width: 100vw;
  overflow-x: hidden;
  .nav-item {
    font-size: 18px;
    display: block;
    padding: 0em 1em;
  }
  .closeBrowse {
    padding: 0.5em;
  }
  .svg-inline--fa {
    margin-right: 10px;
    margin-left: 20px;
  }
  a.nav-link {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  }
  .nav-link {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
    .svg-inline--fa {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 300;
      width: 24px !important;
    }
  }
  .collapseText {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
    padding-left: 10px;
  }
  .dropdown-menu {
    position: absolute;
    height: 100vh;
    margin-left: 100% !important;
    margin-top: -43px !important;
    border-radius: 0 !important;
    z-index: 999999;
    background: ${({theme}) => (theme.body === '#FFF' ? '#EDEDED' : '#2E3038')};
    transform: translate(0px, 0px) !important;
  }
  .dropdown-menu .dropdown-item {
    color: ${({theme}) => (theme.body === '#FFF' ? '#252730' : '#F9F9F9')};
  }
  .dropdown-item:hover {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
    background: ${({theme}) => (theme.body === '#FFF' ? '#B8BBBE' : '#4e505a')};
  }
  .browse:focus-within > dropdown-menu,
  .browse:hover > dropdown-menu,
  .browse-specialty:hover {
    color: ${({theme}) => theme.text2};
    background: ${({theme}) => theme.nav_background};
    .svg-inline--fa {
      color: ${({theme}) => theme.text2};
    }
  }
  .mobile-topics {
    padding: 0.5rem 1rem;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .bottom-sidebar .links-tablet {
      display: none;
    }
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 2em;
    vertical-align: 0em;
    content: '';
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }

  .active {
    background: ${({theme}) => (theme.body === '#FFF' ? '#B8BBBE' : '#4e505a')};
    .svg-inline--fa {
      color: #0677d8;
    }
  }
  .navbar-nav .nav-link:hover {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#F9F9F9')};
    background: ${({theme}) => theme.nav_background};

    .svg-inline--fa {
      color: #0677d8;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
    }
  }
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

export const OuterToggleSideBar = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 122;
  background-color: transparent;
`;
