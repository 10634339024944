import styled from 'styled-components';

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 106px 0;
  position: relative;
  margin: 0 100px;

  /* Adapt the colors based on primary prop */
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
    padding-top: 54px;
  }
  @media screen and (max-width: 576px) {
    padding: 48px 16px 16px;
    margin: 0;
  }
`;

export const Body = styled.div`
  margin-top: 45px;
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 15px;
`;

export const ConferenceTitle = styled.div`
  min-height: 47px;

  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }
  @media screen and (max-width: 576px) {
    min-height: 25px;
    h5 {
      font-size: 12px;
      letter-spacing: -0.08px;
      line-height: 14px;
    }
  }
`;

export const Img = styled.img`
  /* Adapt the colors based on primary prop */
  filter: ${({theme}) => (theme.body === '#FFF' ? 'invert(1)' : 'invert(.3)')};
`;

export const ButtonGroup = styled.div`
  width: 230px;

  .a_follow {
    font-size: 14px;
    padding: 4px 5px 2px 8px;
    line-height: 16px;
    letter-spacing: -0.09px;
    background: #0677d8;
    font-weight: 500;
  }

  .a_playlist,
  .a_bookmark {
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-right: 15px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  }
`;

export const InfoOrganization = styled.h5`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 0;
`;

export const Organization = styled.h6`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 4px;
  @media screen and (max-width: 576px) {
    font-size: 10px;
    letter-spacing: -0.05px;
    line-height: 10px;
  }
`;

export const Information = styled.p`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 8px;
  text-align: justify;
`;

export const DescriptionContainer = styled.div`
  max-width: 643px;
`;

export const Details = styled.p`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-bottom: 0;
  @media screen and (max-width: 576px) {
    font-size: 10px;
    letter-spacing: -0.05px;
    line-height: 10px;
  }
`;

export const ThumbnailImg = styled.img`
  width: 168px;
  height: 95px;
  border-radius: 8px;
  mix-blend-mode: ${props => (props.lock ? 'luminosity' : 'normal')};
  @media screen and (max-width: 576px) {
    width: 84px;
    height: 47.5px;
    border-radius: 4px;
  }
  @media screen and (max-width: 320px) {
    width: 70px;
    height: 40px;
  }
`;

export const ThumbnailDiv = styled.div`
  margin-right: 20px;
  position: relative;
  .lock-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.4;
      z-index: 0;
    }
  }
  .lock-link {
    position: relative;
    z-index: 1;
    height: 2rem;
    width: 2rem;
    border: 3px solid #fff;
    border-radius: 2rem;
    background-color: #a7a7a791;
    display: flex;
    align-items: center;
    justify-content: center;
    .lock-icon {
      color: #fff;
      font-size: 1rem;
    }
  }
  .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }
`;

export const InformationDiv = styled.div`
  background-color: ${({theme}) => (theme.body === '#FFF' ? '#ededed' : '#252730')};
  border-bottom: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  padding-top: 80px;

  @media (max-width: 1200px) {
    padding-top: 1.5rem;
  }
`;

export const DescriptionDiv = styled.div`
  padding-bottom: 12px;

  .a_playlist,
  .a_bookmark {
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-right: 15px;
    font-size: 18px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#000' : '#b8bbbe')};
  }
  @media (max-width: 768px) {
    .a_playlist,
    .a_bookmark {
      margin-right: 12px;
    }
  }
`;

export const InfoTitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.62px;
  line-height: 46px;
  margin-bottom: 6px;
`;

export const InfoRight = styled.div`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  span {
    width: 120px;
    font-size: 15px;
    letter-spacing: -0.1px;
    line-height: 20px;
    display: inline-block;
    margin-left: 12px;
    text-align: left;
  }

  .a_conferenceWatchTime {
    margin-left: 12px;
  }
  .fa-cme-1 {
    width: 1.1em !important;
  }
`;

export const ConferenceDescContainer = styled.div`
  margin-top: 18px;
`;

export const ConferenceTab = styled.nav`
  background-color: ${({theme}) => (theme.name === 'light' ? '#f9f9f9' : '#252730')};
  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};
  .nav {
    flex-wrap: nowrap;
    white-space: nowrap;
    @media screen and (max-width: 576px) {
      overflow-x: scroll;
    }
  }
`;

export const NoConferenceMessage = styled.h6`
  color: ${({theme}) => theme.text};
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  line-height: 24px;
`;

export const NavItem = styled.li`
  width: auto !important;
  .nav-link {
    color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#DCDEE1')};
    font-size: 17px;
    letter-spacing: -0.17px;
    line-height: 20px;
    text-align: center;
    padding: 16px 18px 17px 15px;
    &.active {
      font-weight: bold;
      border-bottom: 4px var(--primary) solid;
    }
  }
`;
