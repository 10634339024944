import React, {useEffect, useState} from 'react';
import AlertSkipTrialModal from '../../pages/Profile/components/alertSkipTrial';
import ComingSoonModal from './components/ComingSoonModal';
import Button from './elements/Button';
import {useStore} from '../../store';

type Props = {
  children: React.Node,
  text: sting,
  subText: sting,
  icon: string,
  isShow: boolean,
  isTrial: boolean,
};
const TBDButton = ({children, text, subText, icon, isShow, isTrial}: Props) => {
  const {account} = useStore().accountStore;

  const [modalShow, setModalShow] = useState(false);
  const [isShowing, setIsShow] = useState(true);
  const [isTrialUser, setIsTrialUser] = useState(isTrial);
  const [price, setPrice] = useState(false);

  useEffect(() => {
    if (isShow !== undefined) setIsShow(isShow);
  }, [isShow]);

  useEffect(() => {
    if (isTrial === undefined) setIsTrialUser(false);
    else setIsTrialUser(isTrial);

    const subscriptionPrice = account?.subscription?.subscription_price || 0;
    const giftcardPrice = account?.subscription?.giftcard_price || 0;
    const percent_off = account?.subscription?.percent_off || 0;
    const total = giftcardPrice + subscriptionPrice * (1 - percent_off / 100);
    setPrice(total.toFixed(2));
  }, [isTrial, account]);

  const openModal = () => {
    setModalShow(true);
  };
  const closeModal = () => {
    setModalShow(false);
  };

  return (
    <>
      <Button type="button" onClick={openModal}>
        {children}
      </Button>
      {isShowing && !isTrialUser ? (
        <ComingSoonModal text={text} subText={subText} icon={icon} show={modalShow} onHide={closeModal} />
      ) : null}
      {isShowing && isTrialUser ? (
        <AlertSkipTrialModal show={modalShow} dollar={`$${price}`} onHide={closeModal} />
      ) : null}
    </>
  );
};

export default TBDButton;
