import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Container} from 'react-bootstrap';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import AdminTab from './components/AdminTab';
import {Header, MobileScreen} from './elements/styles';
import {adminRoutes, orgMgrRoutes, instnMgrRoutes} from './routes';
import LocalStorageService from '../../utils/LocalStorageService';
import AdminIcon from './elements/bars.svg';
import MobileIcon from './elements/warning-icon.svg';

// Admin page
const AdminPanel = () => {
  const [routes, setRoutes] = useState([]);
  const match = useRouteMatch();
  const user = LocalStorageService.getUser();
  useEffect(() => {
    switch (user?.role) {
      case 'admin':
        setRoutes(adminRoutes);
        break;
      case 'organization_manager':
        setRoutes(orgMgrRoutes);
        break;
      case 'institution_manager':
        setRoutes(instnMgrRoutes);
        break;
      default:
        setRoutes([]);
        break;
    }
  }, [user]);

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const renderMobileScreen = () => {
    return (
      <MobileScreen>
        <img src={MobileIcon} alt="" />
        <h5>Admin Dashboard is currently only available on desktop.</h5>
      </MobileScreen>
    );
  };

  return (
    <div id="admin" className="d-flex">
      <Helmet>
        <title>Admin Panel</title>
        <meta name="description" content="Giblib admin panel" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:image"
          content="https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png"
        />
        <meta
          name="image"
          property="og:image"
          content="https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png"
        />
      </Helmet>
      <Sidebar />
      <div className="w-100">
        <Navbar />
        <div className="pt-5 w-100">
          <Header>
            <div className="d-flex container pt-5">
              <h5>
                <img src={AdminIcon} alt="" />
                GIBLIB Admin
              </h5>
            </div>
          </Header>
          <AdminTab />
          <Container className="pt-3" id="admin-landing-contents">
            <Switch>
              {routes.map(route => (
                <Route
                  key={route.path}
                  path={`${match.path}/${route.path}`}
                  render={props => (isMobile ? renderMobileScreen() : <route.component {...props} />)}
                />
              ))}
              <Redirect to={`${match.path}/${routes[0]?.path}`} />
            </Switch>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
