import axios from './axios';

export const downloadNote = id =>
  axios
    .get(`/note/${id}/download`, {
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'notes.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

export const downloadAllNote = () =>
  axios.get(`/note/download`, {responseType: 'blob'}).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'notes.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  });

export const downloadMultipleNote = noteIds =>
  axios.get(`/note/download-multiple`, {params: {noteIds}, responseType: 'blob'}).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'notes.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
