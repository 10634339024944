import axios from './axios';

/**
 * calls the profession GET api and get the professions data
 */
export const getProfessions = type => axios.get(`/profession?type=${type}`);

/**
 * calls the specialty GET api and get the specialties data
 */
export const getSpecialties = (type, videoType, orgId) =>
  axios.get('/specialty', {
    params: {
      type,
      video_type: videoType,
      organization_id: orgId,
    },
  });

export const getEducations = () => axios.get('/specialty?specialty_type=education');

/**
 * calls the institution GET api and get the institution data
 */
export const getInstitutions = () => axios.get('/institution');

/**
 * calls the organization GET api and get the organizations data
 */
export const getOrganizations = () => axios.get('/organization');

/**
 * calls the /conference/heros GET api and get the conference heros data
 */
export const getConferenceHeros = () => axios.get('/conference/heros');

/** calls the getConferences api
 * @params {array} profession - list of profession IDs
 * @params {array} specialty - list of specialty IDs
 * @params {boolean} cme - cme
 * @params {string} sort - sort
 * @params {string} sortBy - sortBy
 * @params {number} page - page
 * @return res.data if success or err.response.data on failure
 */
export const getConferences = (
  // provider,
  specialty,
  organization,
  profession,
  cme,
  sort,
  sortBy,
  page,
  type,
  count,
  q,
) =>
  axios.get(`/conference/list`, {
    params: {
      specialty,
      organization_id: organization,
      profession,
      is_cme: cme,
      sort,
      sortBy,
      page,
      type,
      limit: count,
      q,
    },
  });

/** calls the create bookmark api
 * @params {number} conferenceId - conferenceId
 * @return res.data if success or err.response.data on failure
 */
export const createBookmark = (id, type) => axios.post(`/bookmark/create-bookmark?type=${type}`, {id});

/** calls the delete bookmark api
 * @params {number} id - id
 * @return res.data if success or err.response.data on failure
 */
export const deleteBookmark = (id, type) => axios.delete(`/bookmark/delete-bookmark/${id}?type=${type}`);

/** calls the create follow api
 * @params {number} id - id
 * @return res.data if success or err.response.data on failure
 */
export const followItem = (id, type) => axios.post(`/follow/create-follow?type=${type}`, {id});

/** calls the delete follow api
 * @params {number} id - id
 * @return res.data if success or err.response.data on failure
 */
export const unfollowItem = (id, type) => axios.delete(`/follow/delete-follow/${id}?type=${type}`);

/** calls the get follow api
 * @return res.data if success or err.response.data on failure
 */
export const getUserFollows = sortBy => axios.get(`/follow?sortBy=${sortBy}`);
