import React from 'react';
import styled from 'styled-components';
import {Nav, Container} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

const TabBarStyle = styled.nav`
  background-color: ${({theme}) => (theme.name === 'light' ? '#f9f9f9' : '#252730')};
  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};
  margin-bottom: 30px;

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

const Link = styled(NavLink)`
  color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#DCDEE1')};
  font-size: 17px;
  letter-spacing: -0.17px;
  line-height: 20px;
  text-align: center;
  padding: 18px 18px 15px 15px;
  &.active {
    font-weight: bold;
    border-bottom: 4px var(--primary) solid;
  }
`;

const NavItem = styled.li`
  width: auto !important;
`;

export const TabBar = ({children}: any) => {
  return (
    <TabBarStyle>
      <Container>
        <Nav>{children}</Nav>
      </Container>
    </TabBarStyle>
  );
};

export const Tab = ({children, href}: any) => {
  return (
    <NavItem className="nav-item">
      <Link className="nav-link" activeClassName="active" to={href}>
        {children}
      </Link>
    </NavItem>
  );
};
