import React from 'react';
import {Spinner, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {HomeSpinner, SpinnerText} from '../elements/home';

const UserScreenLoader = ({spinnerSize, containerHeight}) => {
  return (
    <HomeSpinner style={{height: containerHeight}} className="d-flex w-100 align-items-center justify-content-center">
      <Col>
        <SpinnerText className="align-items-center justify-content-center">
          Preparing your personal homepage
        </SpinnerText>
        <div className="home-spinner">
          <Spinner size={spinnerSize} animation="grow" />
        </div>
      </Col>
    </HomeSpinner>
  );
};

UserScreenLoader.propTypes = {
  spinnerSize: PropTypes.oneOf(['sm', 'lg']),
  containerHeight: PropTypes.string.isRequired,
};
UserScreenLoader.defaultProps = {
  spinnerSize: 'sm',
};

export default UserScreenLoader;
