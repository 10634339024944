import styled from 'styled-components';

export const Organization = styled.h6`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 4px;

  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 11px;
  }
`;

export const Details = styled.p`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: revert;

  @media screen and (max-width: 576px) {
    font-size: 10px;
    line-height: 11px;
  }
`;

export const ThumbnailImg = styled.img`
  width: 168px;
  height: 95px;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    width: 124px;
    height: 85px;
  }
`;

export const ThumbnailDiv = styled.div`
  margin-right: 20px;
  width: 245px;
  height: 95px;
  .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    width: 124px;
    height: 85px;
  }
`;

export const CardTitle = styled.div`
  min-height: 47px;

  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    h5 {
      font-size: 13px;
    }
  }
`;

export const VideoCardHolder = styled.div`
  border-bottom: 1px solid #4e505a;
  .row {
    margin-top: 10px;
  }
  #playlist_remove {
    display: none;
    color: #fff;
    font-size: 12px;
    @media screen and (max-width: 576px) {
      display: block;
    }
  }
  #playlist_remove::after {
    border-top: none;
    border-right: none;
  }
`;

export const AlertSpan = styled.span`
  font-size: 0.8em;
  color: #e04463;
`;
