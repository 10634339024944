import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import NotAvailableModal from './elements/styles';

type Props = {
  show: boolean,
  onLogout: Function,
};

const IPNotAccessModal = ({show, onLogout}: Props) => {
  const location = useHistory();

  const clickLogout = async () => {
    await onLogout();
    location.go('');
  };
  return (
    <NotAvailableModal show={show} centered>
      <NotAvailableModal.Body>
        <p className="a_notAccessModalHeader">IP Not Allowed</p>

        <div className="a_notAccessModalContent">
          You cannot access this GIBLIB account with your current IP address. Please contact your administrator for
          assistance.
        </div>

        <Button type="submit" variant="primary" id="log-out" className="a_logoutButton" onClick={() => clickLogout()}>
          Log Out
        </Button>
      </NotAvailableModal.Body>
    </NotAvailableModal>
  );
};

export default IPNotAccessModal;
