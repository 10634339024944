/* eslint-disable camelcase */
import {observer} from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import {useStore} from '../../../store';
import LocalStorageService from '../../../utils/LocalStorageService';

const RegisterProtectedRoute = ({children, ...rest}) => {
  const {authStore} = useStore();
  const {emailRegistered} = authStore;
  const isValid = () => {
    switch (rest.path) {
      case '/register/profession':
      case '/register/specialty':
      case '/register/plan':
      case '/register/payment':
      case '/register/account':
        return emailRegistered;

      case '/register/training':
      case '/register/institution':
      case '/register/subspecialty':
      case '/register/medical-school':
      case '/register/residency-program':
      case '/register/referrel':
      case '/register/content':
      case '/register/review':
        return LocalStorageService.getUser();

      default:
        return false;
    }
  };
  return isValid() ? <Route {...rest}>{children}</Route> : <Redirect to="/register/email" />;
};

RegisterProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
export default observer(RegisterProtectedRoute);
