import axios from './axios';

/**
 * calls the expert GET api to get expert details
 */

const getExpert = (id, params = null, cancelToken = null) => axios.get(`/expert/${id}`, {params, cancelToken});

/** calls the getExperts api
 * @params {array} organization - list of organization IDs
 * @params {array} specialty - list of specialty IDs
 * @return res.data if success or err.response.data on failure
 */

const getExperts = (organization, specialty, sortBy, page, limit, q) =>
  axios.get(`/expert/filter-experts/?sortBy=${sortBy}`, {
    params: {
      organization,
      specialty,
      page,
      limit,
      q,
    },
  });

export {getExpert, getExperts};
