import axios from './axios';

/** calls the delete playlist api
 * @params {number} id - id
 * @return res.data if success or err.response.data on failure
 */
export const deletePlaylist = id => axios.delete(`/playlist/${id}`);

/** calls the update playlist video order api
 */
export const updatePlaylistOrder = (playlistId, data, type) =>
  axios.put(`/playlist/update-order?type=${type}`, {playlistId, data});

/**
 * calls the playlist GET api to get delete confirmation check
 */
export const getDeleteConfirmation = () => axios.get(`/playlist/delete-confirmation`);

export const updateDeleteConfirmation = data => axios.put('/playlist/delete-confirmation', {data});

export const addCoursePlaylist = (courseId, data) => axios.put(`/playlist/course`, {courseId, data});

export const addNewCoursePlaylist = (courseId, name) => axios.post(`/playlist/course`, {courseId, name});

export const addConferencePlaylist = (conferenceId, data) => axios.put(`/playlist/conference`, {conferenceId, data});

export const addNewConferencePlaylist = (conferenceId, name) =>
  axios.post(`/playlist/conference`, {conferenceId, name});
