import {observer} from 'mobx-react-lite';
import React from 'react';

import {UpdateModal} from '../elements/cme';

type Props = {
  handleModalClose: Function,
  downloadMessage: String,
};
export default observer(({handleModalClose = () => {}, downloadMessage}: Props) => {
  return (
    <UpdateModal show="true" onHide={handleModalClose} className="createPlaylistModal">
      <UpdateModal.Header closeButton onHide={handleModalClose}>
        Error
      </UpdateModal.Header>
      <UpdateModal.Body className="pt-0">
        <p>{downloadMessage}</p>
      </UpdateModal.Body>
    </UpdateModal>
  );
});
