import {Button, Dropdown} from 'react-bootstrap';
import styled from 'styled-components';
import darkTheme from '../../utils/styles/darkTheme';

/* eslint-disable import/prefer-default-export */
export const MobNavBar = styled.div`
  display: block;
  height: 56px;
  background: ${darkTheme.surface};

  .navbar {
    z-index: 1000 !important;
    background: ${darkTheme.surface};
  }
  & .search-button.fa-search {
    color: #ffffff;
  }
  & .a_collapseIcon {
    & .fa-bars {
      color: #ffffff;
    }
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
  // @media (min-width: 767px) and (max-width: 1199px) {
  //   #search-button {
  //     display: none;
  //   }
  //   .input-group.form-inline {
  //     margin-left: 0px;
  //     width: 320px;
  //   }
  //   #open-mobile-search {
  //     display: none;
  //   }
  // }
  @media screen and (max-width: 767px) {
    .input-group.form-inline {
      display: none;
    }
    #search-button {
      display: inline-block;
    }
  }
`;

export const DeskNavBar = styled.div`
  display: none;
  .navbar {
    z-index: 1000 !important;
  }
  @media screen and (min-width: 1200px) {
    display: block;
    .navbar {
      background: ${darkTheme.surface};
      color: ${({theme}) => theme.text2};
      width: 100%;
      height: 58px;
    }
    .row {
      flex-wrap: wrap;
      margin-right: -8px;
      margin-left: -8px;
    }
    .form-inline {
      margin-left: 215px;
    }
    .user-stats {
      .fa-icon-cme-filled-green {
        color: #0dfff3;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    display: block;
    .navbar {
      background: ${darkTheme.surface};
      color: ${({theme}) => theme.text2};
      width: 100%;
      height: 58px;
    }
    .row {
      flex-wrap: wrap;
      margin-right: -8px;
      margin-left: -8px;
    }
    .form-inline {
      margin-left: 60px;
    }
    .user-stats {
      display: none !important;
    }
  }
`;

export const Logo = styled.img`
  height: 16px;
  margin: 10px 0 10px 18px;
`;

export const SearchBar = styled.div`
  .form-control {
    background: ${({theme}) => theme.search_button};
    border-left: none;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    height: 32px;
    width: 280px;
    max-width: 320px;
    color: #fff !important;
    font-size: 14px;
    box-shadow: none;
  }
  .form-control::placeholder {
    color: #fff;
    font-family: Roboto;
  }
  .input-group-prepend {
    margin-right: 0px;
  }
  .input-group .form-control:not(:first-child),
  .input-group .custom-select:not(:first-child) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .search-button {
    height: 32px;
    padding: 0.4rem;
    padding-right: 20px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: none;
    background: ${({theme}) => theme.search_button};
    color: #fff;
    border-right: none;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    z-index: 9999;
  }
  .fa-search {
    background-color: none !important;
    margin-left: 4px !important;
    margin-right: -15px !important;
    font-size: 20px !important;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    max-width: 320px;
    margin: auto;
  }
`;

export const UserMenu = styled.div`
  z-index: 300 !important;
  position: absolute;
  width: 176px;
  height: 213px;
  right: 25px;
  background: ${darkTheme.surface};
  color: ${({theme}) => theme.text2};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
  margin-top: 8px;
  .classic {
    color: ${({theme}) => theme.text2};
    p {
      display: inline-block;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    right: 25px;
  }
  @media screen and (max-width: 576px) {
    right: 25px;
  }
  @media (max-width: 1199px) {
    right: 25px;
  }
`;

export const UserOptions = styled.button`
  padding: 10px 0px 10px 18px;
  background: ${darkTheme.surface};
  color: ${({theme}) => theme.text2};
  z-index: 5;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background: ${({theme}) => theme.user_option};
  }
`;

export const OptionText = styled.p`
  margin-bottom: 0px;
  font-size: 13.09px;
`;

export const Bar = styled.div`
  .trans {
    background: linear-gradient(to bottom, rgba(46, 48, 56, 1) 0%, rgba(46, 48, 56, 0) 100%);
  }
  .opaque {
    background: ${darkTheme.surface};
  }
`;

export const MobileSearch = styled.div`
  display: inline-block;
  a {
    color: ${({theme}) => theme.text2};
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MarketingDropdown = styled(Dropdown)`
  display: inline-block;
  margin-right: 24px;

  button {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    padding: 0;
    height: 38px;
    border-radius: 0;
    :hover {
      color: #0dfff3;
      background-color: transparent;
    }
  }
  .dropdown-menu.show {
    background-color: #181a21;
    padding: 0;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
    .dropdown-item {
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      padding: 12px 24px;
      :hover {
        background-color: hsla(0, 3%, 81.5%, 0.21);
        color: #0dfff3;
      }
    }
  }
`;

export const MarketingButton = styled(Button)`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  margin-right: 24px;
  padding: 0;
  :hover {
    color: #0dfff3;
  }
`;
