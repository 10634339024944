import React from 'react';
import AlertContainer from './styles';
import image from '../../assets/icons/close.png';

type Props = {
  message: string,
  status: string,
  click: Function,
};

const Toast = ({message, status, click}: Props) => {
  return (
    <AlertContainer className={status}>
      <p>{message}</p>
      <button type="button" className="a_closeAnnouncement" onClick={() => click()}>
        <img src={image} alt="close_button" />
      </button>
    </AlertContainer>
  );
};

export default Toast;
