import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  initAmplitude,
  sendAmplitudeEventData,
  setAmplitudeUserId,
  setAmplitudeUserProperties,
  setAmplitudeUserDevice,
} from './utils/Amplitude';
import {store, StoreContext} from './store';

require('dotenv').config();

initAmplitude();
sendAmplitudeEventData();
setAmplitudeUserId();
setAmplitudeUserProperties();
setAmplitudeUserDevice();

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <App />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
