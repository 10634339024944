// @flow
import {makeAutoObservable, runInAction} from 'mobx';
import _ from 'lodash';
import {
  getAccount as getAccountFromApi,
  setAccount,
  deleteSubscription,
  reactivateSubscription as reactivateApiSubscription,
} from '../../api/account';
import type {USER_ROLE} from '../../utils/types';
import {unfollowItem, getUserFollows} from '../../api/course';
import MediaStore from '../media/mediaStore';
import ErrorStore from '../error/errorStore';
import ExpertStore from '../expert/expertStore';
import RegStore from '../reg/regStore';
import history from '../../history';

type UserSettings = {
  is_general_notification_allowed: boolean,
  is_following_notification_allowed: boolean,
  is_content_notification_allowed: boolean,
  selected_theme: string,
};
type Subscription = {
  id: string,
  name: string,
  price_id: string,
  is_premium: Boolean,
  status: string,
  trial_end: Date,
  last_payment: Date,
  next_payment: Date,
  is_cancelled: Boolean,
};
type PaymentDetails = {
  card: {
    brand: string,
    last4: string,
    funding: string,
  },
};

export type Account = {
  id: string,
  first_name: string,
  last_name: string,
  password: string,
  email: string,
  video_watched_time: number,
  total_credits_earned: number,
  daily_streak: number,
  weekly_streak: number,
  most_watched_specialty: {
    name: string,
    id: number,
  },
  is_verified: boolean,
  is_subscribed: boolean,
  profession_id: string,
  specialty_id: string,
  specialties: [],
  reason_for_cancellation: {},
  extra_data: {
    other_profession: string,
    other_specialty: string,
  },
  watch_history_count: number,
  watch_history_total: number,
  settings: UserSettings,
  subscription: Subscription,
  payment_details: PaymentDetails,
  access: any,
  role: USER_ROLE,
  allSubscriptions: {
    data: [],
  },
  profession_specialty: boolean,
  is_trial_eligible: Boolean,
};
type Stats = {
  most_watched_specialty: {
    name: string,
    id: number,
  },
  videos_watched: number,
  total_time_watched: number,
  total_credits_earned: number,
  daily_streak: number,
  weekly_streak: number,
};
type Followings = {
  Follow: {
    course_id: number,
    created_at: Date,
    deleted_at: Date,
    id: number,
    organization_id: number,
    expert_id: number,
    specialty_id: number,
    updated_at: Date,
    user_id: number,
  },
  description: string,
  id: number,
  name: string,
  number_of_videos: number,
  timestamp: Date,
  title: string,
  type: string,
};
class AccountStore {
  mediaStore: MediaStore;

  errorStore: ErrorStore;

  expertStore: ExpertStore;

  regStore: RegStore;

  isAccountLoading = false;

  cancellationCompleted: boolean = false;

  personalInfoSubmitted: boolean = false;

  unauthorizedIP: boolean = false;

  account: Account = {};

  followList: Followings[] = [];

  stats: Stats = {
    most_watched_specialty: {
      name: 'None',
      id: 0,
    },
    videos_watched: 0,
    total_time_watched: 0,
    total_credits_earned: 0,
    daily_streak: 0,
    weekly_streak: 0,
  };

  showAds: boolean = false;

  get userSettings(): UserSettings {
    return this.account?.settings;
  }

  set userSettings(newSettings: UserSettings) {
    this.account.settings = newSettings;
  }

  get subscription(): Subscription {
    return this.account?.subscription;
  }

  set subscription(newSubscription: Subscription) {
    this.account.subscription = newSubscription;
  }

  get paymentDetails(): PaymentDetails {
    return this.account?.payment_details;
  }

  set paymentDetails(newPaymentDetails: PaymentDetails) {
    this.account.payment_details = newPaymentDetails;
  }

  get purchasedVideoIds() {
    return _.flatMap(_.flatMap(this.account?.content_packages, 'videos'), 'id');
  }

  constructor({mediaStore, errorStore, expertStore, regStore}) {
    this.mediaStore = mediaStore; // Store that can resolve account.
    this.errorStore = errorStore; // Store to manage error.
    this.expertStore = expertStore;
    this.regStore = regStore;
    makeAutoObservable(this);
  }

  setUnauthorizedIP = (value: boolean) => {
    this.unauthorizedIP = value;
  };

  setAccountAndStats = (account: Account) => {
    this.account = account;
    if (account.subscription) {
      this.account.subscription = {
        ...account.subscription,
        is_cancelled: false,
      };
    }
    let existingUserData = JSON.parse(localStorage.getItem('user'));

    existingUserData = this.account;

    const userData = existingUserData || {};
    userData.subscription = account.subscription;

    const stringifiedUserData = JSON.stringify(userData);
    localStorage.setItem('user', stringifiedUserData);

    if (account.reason_for_cancellation) {
      if (account.subscription?.status !== 'active') {
        this.account.subscription = {
          ...account.subscription,
          status: 'cancelled',
          is_cancelled: true,
        };
      } else {
        const canceledAt = new Date(this.account.subscription.cancel_at).getTime();
        const today = new Date().getTime();
        if (today < canceledAt) {
          this.account.subscription = {
            ...account.subscription,
            status: 'active',
            is_cancelled: true,
          };
        }
      }
    }

    // videos watched
    this.stats.videos_watched = account?.watch_history_count || 0;
    // time watched
    const totalTimeWatched = account?.watch_history_total || 0;
    this.stats.total_time_watched = +((totalTimeWatched || 0) / 3600).toFixed(1);
    // total credits
    this.stats.total_credits_earned = account?.total_credits_earned || 0;
    // daily steak
    this.stats.daily_streak = account?.daily_streak || 0;
    this.stats.weekly_streak = account?.weekly_streak || 0;
    this.stats.most_watched_specialty.name = account?.most_watched_specialty?.name || 'None';
    this.stats.most_watched_specialty.id = account?.most_watched_specialty?.id || 0;

    // access
    if (!this.account.access.length) this.account.access = null;
    if (this.account.access) {
      this.account.access = this.account.access.sort((a, b) => {
        const aDate = new Date(a.AccessHistory.created_at);
        const bDate = new Date(b.AccessHistory.created_at);
        return bDate - aDate;
      });
      const access = this.account.access[0];

      const today = Date.now();
      if (access.AccessHistory?.extended_date) {
        const extendedDate = new Date(access.AccessHistory.extended_date).setMilliseconds(0);
        if (today <= extendedDate) access.status = 'Active';
        else access.status = 'Inactive';
      } else if (access.access_period_days) {
        const createdDate = new Date(access.AccessHistory.created_at);
        const createdDateTime = createdDate.setMilliseconds(0);
        const endDate = new Date(createdDate.setDate(createdDate.getDate() + access.access_period_days)).getTime();
        if (today >= createdDateTime && today <= endDate) access.status = 'Active';
        else access.status = 'Inactive';
      } else {
        let startDate = Date.now();
        let endDate = Date.now();
        if (access.start_date) startDate = new Date(access.start_date).setMilliseconds(0);
        if (access.end_date) endDate = new Date(access.end_date).getTime();
        if (today >= startDate && today <= endDate) access.status = 'Active';
        else access.status = 'Inactive';
      }

      this.account.access = {};
      if (access) this.account.access = access;
    }
    // this.setShowAd();

    this.isAccountLoading = false;
  };

  // check whether the user has the access to the feature

  setFeaturePermission = action => {
    const userData = localStorage.getItem('user');
    const localAccount = JSON.parse(userData);
    if (localAccount?.id) {
      if (localAccount?.access) {
        switch (action) {
          case 'bumper_ads':
            return localAccount?.access[0]?.bumper_ads;
          case 'banner_ads':
            return localAccount?.access[0]?.banner_ads;
          case 'sidebar':
            return false;
          case 'certificate':
            return localAccount?.access[0]?.certificate;

          default:
            return false;
        }
      } else if (localAccount?.subscription) {
        switch (action) {
          case 'bumper_ads':
          case 'banner_ads':
            return !localAccount?.subscription?.is_premium;
          case 'sidebar':
            return false;
          case 'certificate':
            return false;

          default:
            return false;
        }
      }
    } else {
      switch (action) {
        case 'bumper_ads':
        case 'banner_ads':
          return true;
        case 'sidebar':
          return true;
        case 'certificate':
          return false;

        default:
          return true;
      }
    }
    return true;
  };

  /** Fetch user */
  getAccount = async (isFromLogin: Boolean = false) => {
    try {
      this.isAccountLoading = true;
      const res = await getAccountFromApi();
      const user = res?.data?.user || null;
      this.setAccountAndStats(user);
      if (isFromLogin) {
        if (this.account.access?.content_based) {
          history.push({
            pathname: '/purchased',
            state: {
              from: '/login',
            },
          });
          if (window.innerWidth <= 576) {
            this.regStore.launchScreenVisibility(true);
          }
        } else {
          history.go(-1);
          if (window.innerWidth <= 576) {
            this.regStore.launchScreenVisibility(true, true);
          } else {
            // safari/firefox doesn't reload after going back
            window.location.reload();
          }
        }
      }
    } catch (error) {
      if (!error?.response?.data?.unauthorizedIP) {
        this.errorStore.setError(error);
      }
      runInAction(() => {
        this.unauthorizedIP = error?.response?.data?.unauthorizedIP;
        this.isAccountLoading = false;
      });
    }
  };

  /** Fetch user total credits earned */
  updateAccountStat = async () => {
    try {
      const res = await getAccountFromApi();
      runInAction(() => {
        const user = res?.data?.user || null;
        this.stats.total_credits_earned = user?.total_credits_earned || 0;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  userSettingsChanged = async (key: string, val: boolean | string) => {
    try {
      await setAccount({
        settings: {[key]: val},
      });
      this.userSettings = {
        ...this.userSettings,
        [key]: val,
      };
    } catch (error) {
      this.userSettings = {...this.userSettings};
      this.errorStore.setError(error);
    }
  };

  userInfoChanged = async (data: any) => {
    this.personalInfoSubmitted = false;
    try {
      await setAccount({
        ...data,
        profession_id: data?.profession_id || null,
        // prevent updating the specialty to null on updating profile
        // specialty_id: data?.specialty_id || null,
        extra_data: data?.extra_data
          ? {
              other_profession: data?.extra_data?.other_profession || null,
              other_specialty: data?.extra_data?.other_specialty || null,
            }
          : null,
      });
      runInAction(() => {
        this.account = {
          ...this.account,
          first_name: data?.first_name,
          last_name: data?.last_name,
          profession_id: data?.profession_id,
          // prevent updating the specialty to null on updating profile
          // specialty_id: data?.specialty_id,
          extra_data: {
            other_profession: data?.extra_data?.other_profession,
            other_specialty: data?.extra_data?.other_specialty,
          },
        };
        this.personalInfoSubmitted = true;
      });
      if (data?.profession_id || data?.specialty_id) {
        this.mediaStore.loadCarousel(true);
      }
    } catch (error) {
      runInAction(() => {
        this.account = {...this.account};
        this.personalInfoSubmitted = false;
      });
      this.errorStore.setError(error);
    }
  };

  userTutorialChanged = async (key: String, val: boolean) => {
    try {
      await setAccount({
        tutorial: {...this.account.tutorial, [key]: val},
      });
      this.account = {
        ...this.account,
        tutorial: {...this.account.tutorial, [key]: val},
      };
    } catch (error) {
      this.errorStore.setError(error);
      this.account = {...this.account};
    }
  };

  cancelSubsciption = async (reason: string, feedback: string) => {
    try {
      this.cancellationCompleted = false;
      const res = await deleteSubscription(reason, feedback);
      if (res.status) {
        this.cancellationCompleted = true;
        const sub = res.data.subscription;
        if (sub.canceled_at < sub.cancel_at) {
          this.account.is_subscribed = true;
          this.account.subscription = {
            ...this.account.subscription,
            status: 'active',
            is_cancelled: true,
          };
        } else {
          this.account.is_subscribed = false;
          this.account.subscription = {
            ...this.account.subscription,
            status: 'cancelled',
            is_cancelled: true,
          };
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
      this.cancellationCompleted = false;
    }
  };

  // reactivate cancelled subscription
  reactivateSubscription = async () => {
    try {
      const res = await reactivateApiSubscription();
      runInAction(() => {
        if (res?.status) {
          this.subscription = {
            ...this.subscription,
            status: res?.data?.subscriptionStatus,
            is_cancelled: false,
          };
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set follow list observables
   * @param {array} list
   */
  setFollowObservables = async (list: any) => {
    this.followList = list;
  };

  /** Fetch all following list of user */
  fetchUserFollows = async (sortBy: string) => {
    try {
      const resp = await getUserFollows(sortBy);
      let {follow} = resp.data;
      follow = (follow || []).map(item => {
        const newFollow = item;
        if (item.course) {
          newFollow.type = 'course';
          newFollow.name = item.course.title;
          newFollow.thumbnail = item.course.thumbnail_url;
          newFollow.followID = item.course.id;
        } else if (item.conference) {
          newFollow.type = 'conference';
          newFollow.name = item.conference.title;
          newFollow.thumbnail = item.conference.thumbnail_url;
          newFollow.followID = item.conference.id;
        } else if (item.organization) {
          newFollow.type = 'organization';
          newFollow.name = item.organization.name;
          newFollow.thumbnail = item.organization.thumbnail;
          newFollow.followID = item.organization.id;
        } else if (item.specialty) {
          newFollow.type = 'specialty';
          newFollow.name = item.specialty.name;
          newFollow.thumbnail = item.specialty.thumbnail;
          newFollow.followID = item.specialty.id;
        } else if (item.expert) {
          newFollow.type = 'expert';
          newFollow.name = item.expert.name;
          newFollow.thumbnail = item.expert.thumbnail;
          newFollow.followID = item.expert.id;
        } else if (item.carousel) {
          newFollow.type = 'carousel';
          newFollow.name = item.carousel.carouselType.text;
          newFollow.thumbnail = null;
          newFollow.followId = item.carousel_id;
        }
        return newFollow;
      });
      this.setFollowObservables(follow);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow item */
  unFollow = async (selectedItem: any) => {
    try {
      const {id, followID, followId, type} = selectedItem;
      const resp = await unfollowItem(followID || followId, type);
      const {status} = resp.data;
      let itemId = null;
      let itemType = null;
      if (status) {
        runInAction(() => {
          if (selectedItem.course) {
            itemId = selectedItem.course_id;
            itemType = 'courses';
          } else if (selectedItem.expert) {
            itemId = selectedItem.expert_id;
            itemType = 'experts';
          } else if (selectedItem.organization) {
            itemId = selectedItem.organization_id;
            itemType = 'organization';
          } else if (selectedItem.carousel) {
            itemId = selectedItem.carousel_id;
            itemType = 'carousel';
          }
          if (itemType === 'experts') {
            this.expertStore.updateExpertFollow(itemId, []);
          }
          this.mediaStore.updateCarouselFollow(itemId, false, itemType);
          this.followList = this.followList.filter(item => item.id !== id);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default AccountStore;
