import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form, Dropdown} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cmeIcon from '../../../assets/images/icon_CME.svg';
import {
  Logo,
  NoCME,
  SortSelect,
  // SortLabel,
  Label,
  CustomCheckbox,
  SelectedCredits,
  DateFilter,
} from '../elements/cme';
import {PaginationContainer} from '../../Bookmarks/elements/styles';
import ScreenLoader from '../../../components/ScreenLoader';
import CompletedCard from './CompletedCard';
import {useStore} from '../../../store';
import TBDButton from '../../../components/TBDButton';
import ErrorModal from './ErrorModal';

const CMECompleted = observer(() => {
  const [canDownload, setCanDownload] = useState(true);
  const {accountStore, cmeStore} = useStore();
  const {account} = accountStore;
  const {
    cmeCompleted,
    cmeCompletedLoading,
    completedSortBy,
    changeCompletedSort,
    download,
    completedStartDate,
    completedEndDate,
    changeStartDate,
    changeEndDate,
    completedPage,
    completedRecordCount,
    completedTotalCount,
    changeCompletedRecordCount,
    completedPageIncrement,
    completedPageDecrement,
    selectAllCME,
    totalCredit,
    selectedIds,
    completedIds,
    setSelectedIds,
    setCredit,
    scrollCompleted,
    downloadMessage,
    resetDownloadMessage,
  } = cmeStore;

  const [dowloadLoader, setDowloadLoader] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isTrialUser, setIsTrialUser] = useState(false);

  const onDownload = () => {
    if (!dowloadLoader && canDownload) {
      setDowloadLoader(true);
      download(selectedIds).then(() => {
        setDowloadLoader(false);
      });
    }
  };

  useEffect(() => {
    return () => resetDownloadMessage();
  }, [resetDownloadMessage]);

  useEffect(() => {
    if (account?.subscription?.status === 'trialing' && !account.access) setCanDownload(false);
    else setCanDownload(true);
  }, [account]);

  const onToggle = index => {
    const newItems = cmeCompleted;
    if (selectedIds?.includes(newItems[index].id)) {
      setSelectedIds(selectedIds.filter(item => item !== newItems[index].id));
      setCredit(totalCredit - (newItems[index]?.organizationCME?.credit_count || 0));
    } else {
      setSelectedIds([...selectedIds, newItems[index].id]);
      setCredit(totalCredit + (newItems[index]?.organizationCME?.credit_count || 0));
    }
  };
  const selectAll = e => {
    if (e.target.checked) {
      selectAllCME(completedStartDate, completedEndDate);
    } else {
      setSelectedIds([]);
      setCredit(0);
    }
  };

  const setSelectAll = () => {
    let count = 0;
    completedIds.forEach(item => {
      if (selectedIds.includes(item)) count += 1;
    });
    if (count !== 0 && count === completedIds.length) return true;
    return false;
  };

  const onStartDateChange = date => {
    setSelectedIds([]);
    setCredit(0);
    changeStartDate(date);
  };

  const onEndDateChange = date => {
    setSelectedIds([]);
    setCredit(0);
    changeEndDate(date);
  };

  const resizeWindow = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    setIsTrialUser(account.subscription?.status === 'trialing');
  }, [account]);

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    if (windowSize <= 768) {
      window.addEventListener('scroll', e => {
        const {scrollTop, clientHeight, scrollHeight} = e.target.scrollingElement;
        if (Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight)) {
          scrollCompleted();
        }
      });
    }
  }, [scrollCompleted, windowSize]);

  const handleModalClose = () => {
    resetDownloadMessage();
  };

  const renderCompletedList = () => {
    return (
      <>
        {cmeCompleted && cmeCompleted.length
          ? cmeCompleted.map((item, i) => (
              <Label htmlFor={item?.id} className="checkLabel" key={item?.id}>
                <input
                  type="checkbox"
                  id={item.id}
                  checked={selectedIds?.includes(item?.id)}
                  onChange={() => onToggle(i)}
                />
                <CompletedCard item={item} key={item?.id} isDownload={canDownload} />
                <CustomCheckbox />
              </Label>
            ))
          : !cmeCompletedLoading && (
              <div className="text-center my-5 mx-auto">
                <Logo>
                  <img src={cmeIcon} className="a_cmeIcon" alt="cme-icon" />
                </Logo>
                <NoCME>No CME’s are Completed</NoCME>
              </div>
            )}
      </>
    );
  };
  return (
    <div className="px-3 container">
      <div className="d-flex flex-wrap justify-content-space-between">
        <div>
          <Label htmlFor="select-all" className="checkLabel">
            <input type="checkbox" id="select-all" checked={setSelectAll()} onChange={e => selectAll(e)} />
            Select All
            <CustomCheckbox />
          </Label>
        </div>
        {selectedIds.length ? (
          <SelectedCredits>
            <Button variant="primary" className="a_downloadCredit mx-4" onClick={onDownload} disabled={dowloadLoader}>
              <TBDButton
                text="Not available during trial"
                subText="You can download your CME certificate after your trial"
                isShow={!canDownload}
                isTrial={isTrialUser}
              >
                <span className="cme-download">
                  {dowloadLoader && canDownload ? 'Downloading Credits...' : 'Download Credits'}
                </span>
              </TBDButton>
            </Button>
            <span>{totalCredit} Credits</span>
          </SelectedCredits>
        ) : null}
        <div className="d-flex ml-auto picker-select">
          <div>
            <DateFilter>
              <Label>Filter</Label>
              <DatePicker
                selected={completedStartDate}
                className="rounded-pill mx-sm-2"
                name="start_date"
                dateFormat="MM/dd/yyyy"
                placeholderText="mm/dd/yyyy"
                onChange={date => onStartDateChange(date)}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
              />
              <DatePicker
                selected={completedEndDate}
                className="rounded-pill mx-sm-2"
                name="end_date"
                dateFormat="MM/dd/yyyy"
                placeholderText="mm/dd/yyyy"
                onChange={date => onEndDateChange(date)}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
              />
            </DateFilter>
          </div>
          <div className="d-flex">
            <Label>Sort</Label>
            <SortSelect
              className="ml-sm-3 completed-sort"
              value={completedSortBy}
              onChange={e => changeCompletedSort(e.target.value)}
            >
              <option value="DESC">Newest</option>
              <option value="ASC">Oldest</option>
            </SortSelect>
          </div>
        </div>
      </div>
      <PaginationContainer className="pt-3 mx-3 pagination-div">
        <span>
          {completedRecordCount === 0 ? (
            <>0 </>
          ) : (
            <>{completedPage * completedRecordCount - (completedRecordCount - 1)} </>
          )}
          -
          {completedPage * completedRecordCount > completedTotalCount ? (
            <>{completedTotalCount} </>
          ) : (
            <>{completedPage * completedRecordCount} </>
          )}
          of {completedTotalCount}
        </span>
        <Button
          onClick={completedPageDecrement}
          variant="basic"
          className="a_p_dec"
          disabled={completedPage * completedRecordCount - (completedRecordCount - 1) === 1}
        >
          <i className="far fa-angle-left" />
        </Button>
        <Button
          onClick={completedPageIncrement}
          variant="basic"
          className="a_p_inc"
          disabled={completedPage * completedRecordCount >= completedTotalCount}
        >
          <i className="far fa-angle-right" />
        </Button>
        <Dropdown>
          <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
            <i className="fas fa-ellipsis-v" />
          </Dropdown.Toggle>
          <Dropdown.Menu onChange={e => changeCompletedRecordCount(e.target.value)}>
            <Form.Check
              type="radio"
              label="10 per page"
              id="limit_10"
              value="10"
              name="count"
              checked={parseInt(completedRecordCount, 10) === 10}
            />
            <Form.Check
              type="radio"
              label="25 per page"
              id="limit_25"
              value="25"
              name="count"
              checked={parseInt(completedRecordCount, 10) === 25}
            />
            <Form.Check
              type="radio"
              label="50 per page"
              id="limit_50"
              value="50"
              name="count"
              checked={parseInt(completedRecordCount, 10) === 50}
            />
            <Form.Check
              type="radio"
              label="100 per page"
              id="limit_100"
              value="100"
              name="count"
              checked={parseInt(completedRecordCount, 10) === 100}
            />
          </Dropdown.Menu>
        </Dropdown>
      </PaginationContainer>
      {!cmeCompletedLoading || windowSize <= 768 ? (
        renderCompletedList()
      ) : (
        <ScreenLoader containerHeight="calc(50vh)" />
      )}
      {cmeCompletedLoading && windowSize <= 768 && <ScreenLoader containerHeight="calc(50px)" />}
      {downloadMessage?.length ? (
        <ErrorModal handleModalClose={handleModalClose} downloadMessage={downloadMessage} />
      ) : (
        ''
      )}
    </div>
  );
});

export default CMECompleted;
