import styled from 'styled-components';
import {NavLink, Container, Modal} from 'react-bootstrap';

export const ExpertCard = styled(Container)`
  padding-left: 50px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding-left: 0;
    margin: auto;
  }

  & .experts-profile {
    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin: auto;
    }
  }

  & img {
    margin: 21px auto 13px;
  }

  & .refer-patient {
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 14px;
    padding: 4px 9px;
    margin-top: 12px;
  }
`;
export const StyledLink = styled(NavLink)`
  font-size: 13px;
  font-weight: 600;
  text-align: right;
  padding-right: 0em;
  padding-top: 0em;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-size: 15px;
  height: 100vh;
  overflow-y: scroll;

  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }
`;

export const BioTitle = styled.p`
  font-weight: 550;
  margin-top: 13px;
  margin-bottom: 0px;
  font-size: 16px;
`;

export const StyledModal = styled(Modal)`
  font-size: 13px;
  font-weight: 50;
  top: ${() => 7}%;

  .close-icon {
    margin-left: auto;
    margin-bottom: auto;
    margin-right: -15px;
    margin-top: -15px;
  }
  .container-row {
    width: 1200px;
  }

  .row {
    margin: 1.5em;
  }

  .publication-title {
    font-weight: 550;
    font-size: 18px;
    margin-bottom: 0px;
  }

  #modal-label {
    margin-top: 16px;
    font-weight: 600;
    font-size: 14px;
  }

  .modal-content {
    background: ${({theme}) => theme.body};
  }

  .bio-details {
    background: ${({theme}) => (theme.name === 'light' ? '#F9F9F9' : '#252730')};
  }
  .row {
    @media screen and (max-width: 576px) {
      display: block;
      width: 90vw;
      overflow-y: scroll;
    }
  }
  .line {
    background-color: ${({theme}) => (theme.name === 'light' ? '#25273041' : '#F9F9F941')};
    margin-top: 1px;
  }

  .publication {
    text-align: justify;
    text-justify: inter-word;
  }
`;

export const Details = styled.p`
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  font-size: 15px;
  letter-spacing: -0.1px;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-word;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const ExpertHeader = styled.div`
  background: ${({theme}) => (theme.name === 'light' ? '#F9F9F9' : '#252730')};
  box-shadow: ${({theme}) => {
    const color = theme.name === 'light' ? '#DCDEE1' : '#4E505A';
    return `inset 0 -1px 0 0 ${color}`;
  }};
  padding-bottom: 1.5em;
  margin-bottom: 2em;
  .tbd-button {
    font-size: 13px;
    font-weight: 600;
    text-align: right;
    padding: 0rem 0rem;
  }
`;

export const Breadcrumbs = styled.ul`
  background: ${({theme}) => (theme.name === 'light' ? '#F9F9F9' : '#252730')};
  color: ${({theme}) => theme.text};
  list-style: none;
  font-weight: 600;
  padding-top: 1em;
  margin-bottom: 0em;
  & > li:after {
    content: '${props => props.separator || '/'}';
    padding: 0 5px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    margin-left: 50px;
  }
`;

export const Crumb = styled.li`
  display: inline-block;
  font-size: 12px;
  &:last-of-type:after {
    content: '';
    padding: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  margin-left: 2px;
  padding: 8px 150px 8px 16px;
  border-radius: 30px;
  width: 300px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};

  @media screen and (max-width: 767px) {
    min-width: 143px;
    width: 143px;
    height: 30px;
    padding: 6px 12px;
    margin-left: 7px;
    font-size: 11px;
    line-height: 18px;
    letter: -0.07px;
  }
`;

export const TitleBar = styled.div`
  margin-bottom: 0.5em;

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 25px;
    padding-right: 10px;
  }

  & h5 {
    @media screen and (max-width: 767px) {
      letter: -0.2px;
      width: 107px;
    }
  }

  & form {
    @media screen and (max-width: 767px) {
      width: 190px;
    }
  }
`;

export const FormLabel = styled.h5`
  font-size: 13px;
  margin-top: 14px;

  @media screen and (max-width: 767px) {
    font-size: 10px;
    margin: auto 0;
  }
`;
