import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';

import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Main from './components/Main';
import Navbar from '../components/Navbar';
import AlertComponent from '../components/AlertComponent';

import {useStore} from '../store';

const MainLayout = ({title, children}) => {
  const {alertStore} = useStore();
  const {message, status, resetAlert} = alertStore;

  return (
    <div className="d-flex">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Sidebar />
      <Main className="w-100">
        <Navbar />
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
        {children}
        <Footer />
      </Main>
    </div>
  );
};

MainLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

MainLayout.defaultProps = {
  title: 'GIBLIB',
};

export default MainLayout;
