import React, {useState, useRef, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, Dropdown, Row} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {observer} from 'mobx-react-lite';
// import debounce from 'lodash.debounce';
import useDebounce from './use-debounce';
import {useStore} from '../../store';
import UserStatistics from './userstat';
import MobileSidebar from '../MobileSidebar';
import MobileSearch from '../MobileSearch';
import NotificationsPanel from '../NotificationsPanel';
import SearchDropdown from '../SearchDropdown';
import LocalStorageService from '../../utils/LocalStorageService';
import {amplitude} from '../../utils/Amplitude';
import logo from '../../assets/images/GBLB_Logo_Horizontal_Notag_White_FullColor.png';
import './navbar.css';
import {
  MobNavBar,
  DeskNavBar,
  Logo,
  SearchBar,
  UserMenu,
  UserOptions,
  OptionText,
  MarketingButton,
  MarketingDropdown,
} from './navbar';

// Navbar component
const Navbar = observer(() => {
  const location = useHistory();
  const {authStore, searchStore, accountStore, accessStore} = useStore();
  const {logout} = authStore;
  const {
    searchText,
    setIsRestrictSearchText,
    storeSearchText,
    fetchSearchResults,
    resetSearch,
    setSuggestedSearchTerm,
  } = searchStore;
  const {stats, account, isAccountLoading} = accountStore;
  const {anonymousAccess} = accessStore;

  const [menuOpen, toggleMenu] = useState(false);
  const [searchValue, setSearchValue] = useState(searchText);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchMobileDropdown, setSearchMobileDropdown] = useState(false);
  const [accessCmeTrialUser, setAccessCmeTrialUser] = useState(false);
  const [cmeUser, setCmeUser] = useState(false);

  useEffect(() => {
    if (account?.access) {
      if (account.access.cme) {
        setCmeUser(true);
        if (account.access.free_or_paid === 'trial' && account.access.cme_allowed > 0) setAccessCmeTrialUser(true);
      }
    }
    if (account?.subscription) {
      if (account.subscription.is_premium) setCmeUser(true);
    }
  }, [account]);

  const userStats = [
    {
      id: 1,
      name: 'Credits-Earned',
      icon: 'fak fa-icon-cme-filled-green',
      primaryText: accessCmeTrialUser
        ? `${stats.total_credits_earned}/${account.access.cme_allowed}`
        : stats.total_credits_earned,
      tooltip: accessCmeTrialUser
        ? `${account.access.cme_allowed} free CME credits available during trial`
        : 'Credits Earned',
      show: cmeUser,
    },
    {
      id: 2,
      name: 'Time-Watched',
      icon: 'fak fa-clock-light',
      primaryText: stats?.total_time_watched,
      tooltip: 'Time Watched',
      show: true,
    },
    {
      id: 3,
      name: 'Weekly-Streak',
      icon: 'fak fa-fire-alt-light',
      primaryText: stats.weekly_streak,
      tooltip: 'Weekly Streak',
      show: true,
    },
    {
      id: 4,
      name: 'Videos-Watched',
      icon: 'fak fa-video-light',
      primaryText: stats.videos_watched,
      tooltip: 'Videos Watched',
      show: true,
    },
  ];

  const loggedIn = () => {
    amplitude.getInstance().logEvent('logged-in');
  };

  const loggedInUser = LocalStorageService.getUser();
  /** calls logout function from the store and redirect to login page
   */

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const clickLogout = async () => {
    await logout();
    location.go(0);
    amplitude.getInstance().logEvent('logged-out');
  };

  const goToRegister = () => {
    const eventProperties = {
      location: 'topnav',
    };
    amplitude.getInstance().logEvent('register-clicked-v3', eventProperties);
  };

  const onSearch = event => {
    const value = event?.target?.value;
    setSearchValue(value);
    if (value) {
      setSearchDropdown(true);
    } else {
      resetSearch();
      setSearchDropdown(false);
    }
  };

  const onSearchMobile = event => {
    const value = event?.target?.value;
    setSearchValue(value);
    if (searchValue) {
      setSearchMobileDropdown(true);
    } else {
      resetSearch();
      setSearchMobileDropdown(false);
    }
  };

  const onClickSeeMore = () => {
    if (searchValue) {
      location.push(`/search?q=${searchValue}`);
      setSearchDropdown(false);
      setSearchMobileDropdown(false);
      storeSearchText(searchValue);
    }
  };

  const searchKeyUpHandler = event => {
    if (searchValue && searchValue.length <= 200 && event.key === 'Enter') {
      onClickSeeMore();
      const eventProperties = {
        keywords: `${searchValue}`,
      };
      amplitude.getInstance().logEvent('search-completed', eventProperties);
    }
    if (searchValue.length <= 200) setIsRestrictSearchText(false);
    else {
      setIsRestrictSearchText(true);
    }
  };

  const onClickOutside = value => {
    setSearchDropdown(value);
  };

  const onClickOutsideMobile = value => {
    setSearchMobileDropdown(value);
  };

  const wrapperRef = useRef(null);
  const wrapperRefMobile = useRef(null);

  const handleClickOutside = event => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !wrapperRefMobile.current.contains(event.target)
    ) {
      toggleMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSuggestedSearchTerm(debouncedSearchTerm);
      fetchSearchResults(debouncedSearchTerm, true);
    }
  }, [debouncedSearchTerm, fetchSearchResults, setSuggestedSearchTerm]);
  return (
    <>
      <DeskNavBar>
        <nav className="navbar fixed-top">
          <div>
            <SearchBar>
              <div className="input-group form-inline" data-tut="hometutorial__search_desk">
                <div className="input-group-prepend">
                  <button className="btn btn-outline-secondary search-button" type="button" onClick={onClickSeeMore}>
                    <i className="far fa-search" />
                  </button>
                </div>
                <input
                  type="search"
                  id="search"
                  defaultValue={searchValue}
                  data-testid="search-input"
                  className="form-control pl-0"
                  placeholder="Search topics, procedures, experts, and more"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  autoComplete="off"
                  onKeyUp={searchKeyUpHandler}
                  onChange={onSearch}
                />
              </div>
            </SearchBar>
            {searchDropdown ? <SearchDropdown clickSeeMore={onClickSeeMore} clickOutside={onClickOutside} /> : <></>}
          </div>
          <div className="justify-content-end">
            {loggedInUser?.id ? (
              <>
                <div
                  className="user-stats"
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                    paddingRight: '15px',
                  }}
                  data-tut="hometutorial__learning"
                >
                  {userStats.map(i => (
                    <Row key={i.id} id={i.name} className="d-inline-block" onClick={() => location.push('/profile')}>
                      <UserStatistics data={i} />
                    </Row>
                  ))}
                </div>
                <ReactTooltip place="bottom" effect="solid" id="userStatistics" />
                <NotificationsPanel />
                <Button
                  variant="link"
                  className="mr-2"
                  id="profile-button"
                  onClick={() => toggleMenu(!menuOpen)}
                  ref={wrapperRef}
                >
                  {!isAccountLoading
                    ? [
                        account?.first_name?.substring(0, 1).toUpperCase(),
                        account?.last_name?.substring(0, 1).toUpperCase(),
                      ].join(' ')
                    : null}
                </Button>
                {menuOpen ? (
                  <UserMenu onClick={() => toggleMenu(!menuOpen)}>
                    <UserOptions className="mb-1" onClick={() => location.push('/profile')} id="user-profile">
                      <i className="fal fa-user mr-2" />
                      <OptionText>Profile</OptionText>
                    </UserOptions>
                    <UserOptions className="mb-1" onClick={() => location.push('/notes')} id="user-notes">
                      <i className="fal fa-sticky-note mr-2" />
                      <OptionText>Notes</OptionText>
                    </UserOptions>
                    <UserOptions className="mb-1" onClick={() => location.push('/history')} id="user-history">
                      <i className="fal fa-history mr-2" />
                      <OptionText>History</OptionText>
                    </UserOptions>
                    <UserOptions className="mb-1" onClick={() => location.push('/following')} id="user-follow">
                      <i className="fal fa-eye mr-2" />
                      <OptionText>Following</OptionText>
                    </UserOptions>
                    <UserOptions className="mb-1" onClick={() => clickLogout()} id="user-logout">
                      <i className="fal fa-sign-out mr-2" />
                      <OptionText>Logout</OptionText>
                    </UserOptions>
                  </UserMenu>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <MarketingDropdown className="marketing-dropdown">
                  <Dropdown.Toggle variant="basic">For Institutions</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="https://www.giblib.com/institutions/institutional-pricing">
                      Institutional Inquires
                    </Dropdown.Item>
                    <Dropdown.Item href="https://www.giblib.com/institutions/content-partner">
                      Become a Content Partner
                    </Dropdown.Item>
                    <Dropdown.Item target="_blank" href="https://www.giblib.com/institutions/resources">
                      Resources
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </MarketingDropdown>
                <MarketingButton variant="basic" href="https://www.giblib.com/pricing">
                  Plans
                </MarketingButton>
                <MarketingButton variant="basic" href="https://www.giblib.com/blog">
                  Blog
                </MarketingButton>
                <Button
                  variant="success"
                  className="mr-2"
                  id="freetrial-button"
                  data-testid="freetrial-button"
                  href={anonymousAccess ? '/access' : '/register'}
                  onClick={anonymousAccess ? null : goToRegister}
                >
                  {anonymousAccess ? `Create free account` : `Register`}
                </Button>
                <Button
                  variant="basic"
                  className="mr-2 login-button"
                  id="login-button"
                  data-testid="login-button"
                  href="/login"
                  onClick={loggedIn}
                >
                  Log In
                </Button>
              </>
            )}
          </div>
        </nav>
      </DeskNavBar>
      <MobNavBar>
        <nav className="navbar fixed-top">
          <div>
            <MobileSidebar />
            <Link to="/">
              <Logo src={logo} alt="GIBLIB" className="logo ml-1" />
            </Link>
          </div>
          <SearchBar>
            <div className="input-group form-inline">
              <div className="input-group-prepend">
                <button
                  className="btn btn-outline-secondary search-button"
                  type="button"
                  onClick={onClickSeeMore}
                  data-tut="hometutorial__search_tablet"
                >
                  <i className="far fa-search" />
                </button>
              </div>
              <input
                type="search"
                id="search-tablet"
                data-testid="search-input-tablet"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                onChange={onSearchMobile}
              />
            </div>
          </SearchBar>
          <div className="justify-content-end">
            <MobileSearch keyupHandler={searchKeyUpHandler} search={onSearchMobile} searchButtonClick />
            {loggedInUser?.id ? (
              <>
                <NotificationsPanel />
                <Button
                  variant="link"
                  className="mr-2"
                  id="profile-button"
                  onClick={() => toggleMenu(!menuOpen)}
                  ref={wrapperRefMobile}
                >
                  {!isAccountLoading
                    ? [
                        account?.first_name?.substring(0, 1).toUpperCase(),
                        account?.last_name?.substring(0, 1).toUpperCase(),
                      ].join(' ')
                    : null}
                </Button>
                {menuOpen ? (
                  <UserMenu onClick={() => toggleMenu(!menuOpen)}>
                    <UserOptions className="mb-1" onClick={() => location.push('/profile')}>
                      <i className="fal fa-user mr-2" />
                      <OptionText>Profile</OptionText>
                    </UserOptions>
                    <UserOptions className="mb-1" onClick={() => location.push('/notes')}>
                      <i className="fal fa-sticky-note mr-2" />
                      <OptionText>Notes</OptionText>
                    </UserOptions>
                    <UserOptions className="mb-1" onClick={() => location.push('/history')}>
                      <i className="fal fa-history mr-2" />
                      <OptionText>History</OptionText>
                    </UserOptions>
                    <UserOptions className="mb-1" onClick={() => location.push('/following')}>
                      <i className="fal fa-eye mr-2" />
                      <OptionText>Following</OptionText>
                    </UserOptions>
                    <UserOptions className="mb-1" onClick={() => clickLogout()}>
                      <i className="fal fa-sign-out mr-2" />
                      <OptionText>Logout</OptionText>
                    </UserOptions>
                  </UserMenu>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <Button
                  variant="success"
                  className="mr-2"
                  id="freetrial-button"
                  href={anonymousAccess ? '/access' : '/register'}
                  onClick={goToRegister}
                >
                  Register
                </Button>
              </>
            )}
          </div>
        </nav>
        {searchMobileDropdown ? (
          <SearchDropdown clickSeeMore={onClickSeeMore} clickOutside={onClickOutsideMobile} />
        ) : (
          <></>
        )}
      </MobNavBar>
    </>
  );
});

export default Navbar;
