import styled from 'styled-components';

export const DefaultBtn = styled.button`
  background-color: #008aff;
  border: none;
  border-radius: 3px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  display: center;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  :hover {
    opacity: 0.8;
  }
`;

export const DefaultSmallBtn = styled(DefaultBtn)`
  padding: 5px 8px;
  width: 78px;
`;

export const DefaultLargeBtn = styled(DefaultBtn)`
  width: 86px;
  height: 38px;
`;

export const ActiveBtn = styled.button`
  background: linear-gradient(90deg, #008aff 0%, #9043ff 100%);
  border: none;
  border-radius: 3px;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  display: center;
  justify-content: center;
  align-items: center;
  :hover {
    background: #ff5964;
  }
  .fa-check,
  .fa-times {
    width: 12px;
  }
  .unfollow {
    display: none;
  }
  :hover {
    .following {
      display: none;
    }
    .unfollow {
      display: inline;
    }
  }
`;

export const ActiveSmallBtn = styled(ActiveBtn)`
  padding: 5px 8px;
  width: 97px;
`;

export const ActiveMediumBtn = styled(ActiveBtn)`
  min-width: 86px;
  height: 38px;
`;

export const ActiveLargeBtn = styled(ActiveBtn)`
  width: 105px;
  height: 38px;
`;
