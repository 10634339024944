import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownButton, Form, Button} from 'react-bootstrap';
import {useStore} from '../../store';
import PageTab from '../PageTab';
import {SearchBar, Div, PaginationContainer} from './elements/styles';

const TabNavItems = [
  {
    id: 1,
    className: 'a_showBookmarks',
    linkTo: '/bookmarks',
    name: 'Bookmarks',
  },
  {
    id: 2,
    className: 'a_showHistory',
    linkTo: '/history',
    name: 'History',
  },
  {
    id: 3,
    className: 'a_showLikes',
    linkTo: '/likes',
    name: 'Likes',
  },
];

const HistoryTab = observer(({title}: String) => {
  const {bookmarkStore} = useStore();
  const [searchValue, setSearchValue] = useState(null);
  const [sortName, setSortName] = useState('Sort by');
  const [pageCount, setPageCount] = useState(false);
  const [page, setPage] = useState(false);
  const [pageTotalCount, setPageTotalCount] = useState(false);

  const {
    sortBy,
    isSorting,
    bookmarkCount,
    bookmarkPage,
    bookmarkTotalCount,
    historyCount,
    historyPage,
    historyTotalCount,
    likedVideosCount,
    likedVideosPage,
    likedVideosTotalCount,
    changeBookmarkCount,
    changeSort,
    resetSearch,
    setBookmarkSearch,
    setHistorySearch,
    setLikesSearch,
    bookmarkPageIncrement,
    bookmarkPageDecrement,
    historyPageDecrement,
    historyPageIncrement,
    changeHistoryCount,
    likedVideosPageDecrement,
    likedVideosPageIncrement,
    changeLikedVideosCount,
  } = bookmarkStore;

  useEffect(() => {
    if (isSorting) {
      if (sortBy === 'DESC') setSortName('Newest');
      else if (sortBy === 'ASC') setSortName('Oldest');
    } else {
      setSortName('Sort by');
    }
  }, [isSorting, sortBy]);

  useEffect(() => {
    if (title === 'Bookmarks') {
      setPageCount(bookmarkCount);
      setPage(bookmarkPage);
      setPageTotalCount(bookmarkTotalCount);
    } else if (title === 'History') {
      setPageCount(historyCount);
      setPage(historyPage);
      setPageTotalCount(historyTotalCount);
    } else {
      setPageCount(likedVideosCount);
      setPage(likedVideosPage);
      setPageTotalCount(likedVideosTotalCount);
    }
  }, [
    title,
    bookmarkCount,
    bookmarkPage,
    bookmarkTotalCount,
    historyCount,
    historyPage,
    historyTotalCount,
    likedVideosCount,
    likedVideosPage,
    likedVideosTotalCount,
  ]);

  const pageDecrement = () => {
    if (title === 'Bookmarks') bookmarkPageDecrement();
    else if (title === 'History') historyPageDecrement();
    else likedVideosPageDecrement();
  };

  const pageIncrement = () => {
    if (title === 'Bookmarks') bookmarkPageIncrement();
    else if (title === 'History') historyPageIncrement();
    else likedVideosPageIncrement();
  };

  const changePageCount = value => {
    if (title === 'Bookmarks') changeBookmarkCount(value);
    else if (title === 'History') changeHistoryCount(value);
    else changeLikedVideosCount(value);
  };

  const onSearch = event => {
    const value = event?.target?.value;
    if (value) {
      setSearchValue(value);
    } else {
      resetSearch();
    }
  };

  const submitSearch = () => {
    if (searchValue) {
      if (title === 'Bookmarks') setBookmarkSearch(searchValue);
      else if (title === 'History') setHistorySearch(searchValue);
      else setLikesSearch(searchValue);
    }
  };

  const onSearchInputKeyUp = event => {
    if (searchValue && searchValue.length <= 200 && event.key === 'Enter') {
      submitSearch();
    }
  };

  const SearchBarDiv = () => (
    <SearchBar>
      <div className="input-group form-inline" data-tut="hometutorial__search_desk">
        <div className="input-group-prepend">
          <button className="btn btn-outline-secondary search-button" type="button">
            <i className="far fa-search" />
          </button>
        </div>
        <input
          type="search"
          id="search"
          defaultValue={searchValue}
          data-testid="search-input"
          className="form-control pl-0"
          placeholder={`Search ${title}`}
          aria-label="Search"
          aria-describedby="basic-addon1"
          autoComplete="off"
          onChange={onSearch}
          onKeyUp={onSearchInputKeyUp}
        />
      </div>
    </SearchBar>
  );

  const SortDropdown = () => (
    <Dropdown className="select-dropdown">
      <DropdownButton title={sortName} onSelect={e => changeSort(e)}>
        <Dropdown.Item eventKey="DESC">
          <Form.Check type="radio" checked={sortBy === 'DESC'} label="Date added (Newest)" />
        </Dropdown.Item>
        <Dropdown.Item eventKey="ASC">
          <Form.Check type="radio" checked={sortBy === 'ASC'} label="Date added (Oldest)" />
        </Dropdown.Item>
      </DropdownButton>
    </Dropdown>
  );

  return (
    <div>
      <PageTab TabNavItems={TabNavItems} />
      <Div>
        <div className="mob">
          {SearchBarDiv()}
          {SortDropdown()}
        </div>
        <div className="w-100 text-right desk container">
          {SearchBarDiv()}
          {SortDropdown()}
          <PaginationContainer className="d-inline-block">
            <span>
              {pageCount === 0 ? <>0</> : <>{page * pageTotalCount - (pageTotalCount - 1)}</>} -
              {page * pageTotalCount > pageCount ? <> {pageCount} </> : <>{page * pageTotalCount} </>}
              of {pageCount}
            </span>
            <Button
              onClick={pageDecrement}
              variant="basic"
              className="a_p_dec ml-2"
              disabled={page * pageTotalCount - (pageTotalCount - 1) === 1}
            >
              <i className="far fa-angle-left" />
            </Button>
            <Button
              onClick={pageIncrement}
              variant="basic"
              className="a_p_inc"
              disabled={page * pageTotalCount >= pageCount}
            >
              <i className="far fa-angle-right" />
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
                <i className="fas fa-ellipsis-v" />
              </Dropdown.Toggle>

              <Dropdown.Menu onChange={e => changePageCount(e.target.value)}>
                <Form.Check
                  type="radio"
                  label="10 per page"
                  id="limit_10"
                  value="10"
                  name="count"
                  checked={parseInt(pageTotalCount, 10) === 10}
                />
                <Form.Check
                  type="radio"
                  label="25 per page"
                  id="limit_25"
                  value="25"
                  name="count"
                  checked={parseInt(pageTotalCount, 10) === 25}
                />
                <Form.Check
                  type="radio"
                  label="50 per page"
                  id="limit_50"
                  value="50"
                  name="count"
                  checked={parseInt(pageTotalCount, 10) === 50}
                />
                <Form.Check
                  type="radio"
                  label="100 per page"
                  id="limit_100"
                  value="100"
                  name="count"
                  checked={parseInt(pageTotalCount, 10) === 100}
                />
              </Dropdown.Menu>
            </Dropdown>
          </PaginationContainer>
        </div>
      </Div>
    </div>
  );
});

export default HistoryTab;
