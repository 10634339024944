import React from 'react';

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // eslint-disable-next-line no-console
    console.log('ERROR', error);
    if (error?.name === 'TypeError') {
      return {hasError: true, error};
    }
    return {};
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log('Video player error boundary received error', error, errorInfo);
  }

  render() {
    const {hasError, error} = this.state;
    if (hasError && error?.name === 'TypeError') {
      // Likely the video player crashed. For now, just reload the page.
      // TODO: fix the root issue. This is typically caused by scrubbing
      // or hitting FF or RW.
      window.location.reload();
    }
    const {children} = this.props;
    return children;
  }
}

export default ErrorBoundary;
