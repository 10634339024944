import React from 'react';
import {observer} from 'mobx-react-lite';
import {Wrapper, NumberStep, StepperLine} from '../../Register/style';

const Stepper = ({activeRegistrationStep}: Props) => {
  return (
    <Wrapper>
      <div className="d-flex align-items-center justify-content-center w-100">
        <NumberStep status>
          <span>1</span>
        </NumberStep>
        <StepperLine status={activeRegistrationStep === 'payment'} />
        <NumberStep status={activeRegistrationStep === 'payment'}>
          <span>2</span>
        </NumberStep>
      </div>
    </Wrapper>
  );
};

export default observer(Stepper);
