import React, {useState, useEffect} from 'react';
import {Button, ProgressBar, OverlayTrigger, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {observer, useLocalStore} from 'mobx-react-lite';
import {
  ButtonGroup,
  ThumbnailImg,
  ThumbnailDiv,
  DurationText,
  VideoCardHolder,
  IconDiv,
  VideoCardFooter,
} from './style';
import PlaylistPopover from '../PlaylistPopover';
import {useStore} from '../../store';
import stringToURLSlug, {secondsToTime, millisecondToTime} from '../../utils/helper';
import CoursePlaylistPopover from '../CoursePlaylistPopover';
import GiblibIcon from '../../assets/images/GBLB_Logo_Mark_FullColor.png';
import {ReactComponent as VRIcon} from '../../assets/icons/360vr.svg';
import {ReactComponent as NewIcon} from '../../assets/icons/new.svg';
import CMEDefaultIcon from '../../assets/icons/CMEDefault.svg';
import CMEInProgressIcon from '../../assets/icons/CMEInProgress.svg';
import CMECompletedIcon from '../../assets/icons/CMECompleted.svg';
import CMERevokedIcon from '../../assets/icons/CMERevoked.svg';
import {Body2, Title2} from '../../utils/styles/typography';

type Props = {
  item: {
    id: number,
    title: string,
    description: string,
    number_of_videos: number,
    watchProgress: number,
    duration: number,
    brightcove_id: string,
    cme: Boolean,
    organizationCME: any,
    thumbnail: String,
    bookmark: Array,
    follow: Array,
    created_at: Date,
    organization: {
      name: string,
    },
    playlist: {
      id: number,
      name: string,
    },
    videos: Array,
    type: String,
  },
  playlist: {
    id: number,
    name: string,
  },
};
// Video Card
const VideoListCard = observer(({item, playlist}: Props) => {
  const location = useHistory();
  const {bookmarkStore, videoStore, accountStore} = useStore();

  const {onDeleteBookmark, addToPlaylist, updatePlaylist} = bookmarkStore;
  const {setReferrer} = videoStore;
  const {account} = accountStore;

  const [userState, setUserState] = useState(false);
  const [playlistUpdating, setUpdating] = useState(false);

  const state = useLocalStore(() => ({
    isNew: false,
  }));

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  useEffect(() => {
    const createdVideo = new Date(item.release_date);
    const after30Video = new Date(createdVideo.getFullYear(), createdVideo.getMonth(), createdVideo.getDate() + 30);
    const today = new Date();
    if (after30Video > today) state.isNew = true;
  }, [state, item]);

  const deleteBookmark = (id, type) => {
    onDeleteBookmark(id, type);
  };

  const gotoVideoDetail = (id, type) => {
    if (type === 'course') {
      const slug = stringToURLSlug(item.title);
      location.push(`/courses/${item.id}/${slug}`);
    } else if (type === 'conference') {
      const slug = stringToURLSlug(item.title);
      location.push(`/conferences/${item.id}/${slug}`);
    } else {
      setReferrer('bookmark page');
      if (type) {
        location.push(`/${type}/${id}`);
      } else {
        location.push(`/video/${id}`);
      }
    }
  };

  const createAndAddToPlaylist = async data => {
    setUpdating(true);
    await addToPlaylist(item.id, data.listname, item.type);
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    await updatePlaylist(item.id, data, item.type);
    setUpdating(false);
  };

  const renderCMEIcon = () => {
    const cmes = item.organizationCME?.CMEs;
    const cmeStatus = cmes?.length && cmes[cmes.length - 1]?.status;

    switch (cmeStatus) {
      case 'In Progress':
        return <object data={CMEInProgressIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
      case 'Completed':
        return <object data={CMECompletedIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
      case 'Revoked':
        return <object data={CMERevokedIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
      default:
        return <object data={CMEDefaultIcon} className="icon-cme" type="image/svg+xml" aria-label="icon-cme" />;
    }
  };

  return (
    <VideoCardHolder className="py-3">
      <div className="d-flex">
        <ThumbnailDiv
          onClick={() => gotoVideoDetail(window.location.pathname === '/history' ? item.video_id : item.id, item.type)}
        >
          <ThumbnailImg src={item.thumbnail} alt={item.title} />
          <DurationText>
            {!item.organization ? millisecondToTime(item.duration) : secondsToTime(item.duration)}
          </DurationText>
          <div className="mobile mobile-icon-div">
            <img className="icon-giblib" src={GiblibIcon} alt="giblib" />
            {state.isNew ? <NewIcon alt="new" /> : null}
            {item.video_type?.includes('360vr') ? <VRIcon alt="vr" /> : null}
          </div>
          <ProgressBar now={item.watchProgress} />
        </ThumbnailDiv>
        <div className="w-100">
          <OverlayTrigger
            trigger="click"
            placement="auto-start"
            overlay={props =>
              item.type === 'video' ? (
                <PlaylistPopover
                  props={props}
                  allPlaylist={playlist}
                  videoPlaylist={item.playlist}
                  onExisting={updateExistingPlaylist}
                  onNew={createAndAddToPlaylist}
                  onDelete={() => deleteBookmark(item.id, item.type)}
                  userState={userState}
                />
              ) : (
                <CoursePlaylistPopover
                  props={props}
                  allPlaylist={playlist}
                  onNew={createAndAddToPlaylist}
                  onExisting={updateExistingPlaylist}
                  courseVideos={item?.videos}
                  onDelete={() => deleteBookmark(item.id, item.type)}
                  userState={userState}
                />
              )
            }
            rootClose
          >
            <Button variant="basic" id="playlist_remove" className="a_playlist float-right text-white">
              <i className="fas fa-ellipsis-v" />
            </Button>
          </OverlayTrigger>
          <IconDiv className="justify-content-between">
            <div className="desk">
              <img className="icon-giblib" src={GiblibIcon} alt="giblib" />
              {state.isNew ? <NewIcon alt="new" /> : null}
              {item.video_type?.includes('360vr') ? <VRIcon alt="vr" /> : null}
            </div>
            <ButtonGroup className="text-right">
              {!playlistUpdating ? (
                <OverlayTrigger
                  trigger="click"
                  placement="auto-start"
                  overlay={props =>
                    item.type === 'video' ? (
                      <PlaylistPopover
                        props={props}
                        allPlaylist={playlist}
                        videoPlaylist={item.playlist}
                        onExisting={updateExistingPlaylist}
                        onNew={createAndAddToPlaylist}
                        userState={userState}
                      />
                    ) : (
                      <CoursePlaylistPopover
                        props={props}
                        allPlaylist={playlist}
                        onNew={createAndAddToPlaylist}
                        onExisting={updateExistingPlaylist}
                        courseVideos={item?.videos}
                        userState={userState}
                      />
                    )
                  }
                  rootClose
                >
                  <Button variant="basic" className="a_playlist">
                    <i className="fak fa-list-ul" />
                  </Button>
                </OverlayTrigger>
              ) : (
                <div className="p-0 mr-3 btn">
                  <Spinner animation="border" size="sm" variant="primary" />
                </div>
              )}
              <Button
                variant="basic"
                className="a_delete"
                data-testid="delete-bookmark"
                onClick={() => deleteBookmark(item.id, item.type)}
              >
                <i className="fa fa-trash" />
              </Button>
            </ButtonGroup>
          </IconDiv>
          <Title2
            className="title mb-1"
            data-testid="go-to-detail"
            onClick={() =>
              gotoVideoDetail(window.location.pathname === '/history' ? item.video_id : item.id, item.type)
            }
          >
            {item.title}
          </Title2>
          <Body2 className="desk specialty mb-2">{item.specialty?.name}</Body2>
          <VideoCardFooter className="justify-content-between">
            <div className="d-flex align-items-center">
              {item?.organizationCME && renderCMEIcon()}
              {item?.organizationCME && <span className="card-desc mr-4">{item.organizationCME?.credit_count}</span>}
              <div>
                <i className="fal fa-calendar" />
              </div>
              {item.duration && (
                <span className="desktop card-desc">
                  {new Date(item.release_date ? item.release_date : item.filmed_at).toLocaleString('en-US', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center mr-4">
              <span className="desk organization">{item.organization?.name}</span>
            </div>
          </VideoCardFooter>
        </div>
      </div>
    </VideoCardHolder>
  );
});

export default VideoListCard;
