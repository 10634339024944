// @flow
import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import StyledTranscript from './styles';
import {useStore} from '../../../../store';
import './transcript.css';
import {amplitude} from '../../../../utils/Amplitude';

const Transcript = observer(() => {
  const location = useHistory();
  const {accountStore, videoStore} = useStore();
  const {account} = accountStore;
  // Don't pull the videoPlayer from the videoStore since it may be removed
  // and replaced. Using the attribute on the videoStore ensures that the
  // most up-to-date player is used.
  const {transcript} = videoStore;
  const {handleSubmit, register} = useForm();
  const [displayTranscript, setDisplayTrasnscript] = useState(null);
  const [userStatus, setUserStatus] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cueStart, setCurrentCueStart] = useState(false);
  const contentRef = useRef();

  useEffect(() => {
    const onSeek = item => {
      videoStore.videoPlayer?.play();
      videoStore.videoPlayer?.currentTime(item);
      setCurrentCueStart(item);
    };
    setDisplayTrasnscript(
      transcript ? (
        <div className="transcript-div">
          {transcript.cues?.map(cue => (
            <p
              key={cue.identifier}
              className="transcript-line"
              onClick={() => onSeek(cue.start)}
              onKeyDown={() => onSeek(cue.start)}
              role="presentation"
            >
              {cue.text}
            </p>
          ))}
        </div>
      ) : (
        <p className="m-0 transcript-line">
          Transcript currently not available. We are adding transcripts every day, so please check back later
        </p>
      ),
    );
    return () => {
      setDisplayTrasnscript(null);
    };
  }, [transcript, cueStart, videoStore.videoPlayer]);

  useEffect(() => {
    if (
      (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) ||
      (account?.access?.status === 'Active' && account?.access?.transcripts)
    ) {
      setUserStatus('active');
    } else if (!account.access) setUserStatus('subscriptionInactive');
    else setUserStatus('accessInactive');
  }, [account]);

  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => {
          return part.toLowerCase() === highlight.toLowerCase() ? (
            // eslint-disable-next-line react/no-array-index-key
            <mark key={i + 1}>{part}</mark>
          ) : (
            part
          );
        })}
      </span>
    );
  };

  const onClickUpgrade = () => {
    location.push('/profile/subscription');
  };

  const onSubmit = ({search}) => {
    const onSeek = item => {
      videoStore.videoPlayer?.play();
      videoStore.videoPlayer?.currentTime(item);
      amplitude.getInstance().logEvent('search-transcript');
    };
    if (search && search.trim()) {
      setDisplayTrasnscript(
        transcript?.cues
          ?.filter(fText => fText.text.toLowerCase().includes(search.toLowerCase()))
          .map(cue => (
            <p
              key={cue.identifier}
              className="m-0 transcript-line"
              onClick={() => onSeek(cue.start)}
              onKeyDown={() => onSeek(cue.start)}
              role="presentation"
            >
              {getHighlightedText(cue.text, search.trim())}
            </p>
          )),
      );
    } else {
      setDisplayTrasnscript(
        <div className="transcript-div">
          {transcript?.cues?.map(cue => (
            <p
              key={cue.identifier}
              className="transcript-line"
              onClick={() => onSeek(cue.start)}
              onKeyDown={() => onSeek(cue.start)}
              role="presentation"
            >
              {cue.text}
            </p>
          ))}
        </div>,
      );
    }
  };

  return (
    <StyledTranscript>
      {userStatus === 'active' ? (
        <>
          <div className="search-form">
            <input
              id="search-transcript"
              className="form-control rounded-pill"
              type="search"
              placeholder="Search Transcript"
              aria-label="search"
              name="search"
              ref={register()}
              onChange={handleSubmit(onSubmit)}
            />
            <button className="d-none" type="submit" />
          </div>
          <div ref={contentRef} className="content">
            <div>{displayTranscript}</div>
          </div>
        </>
      ) : (
        userStatus === 'subscriptionInactive' && (
          <div className="d-flex flex-column align-items-center">
            <div className="a_iconDiv d-flex align-items-center justify-content-center">
              <i className="fas fa-file-alt" />
            </div>
            <p className="a_transcriptUpgradeText">Upgrade to Standard or Premium Account to access transcript.</p>
            <Button variant="primary" type="button" className="a_upgradeButton py-0" onClick={() => onClickUpgrade()}>
              Upgrade
            </Button>
          </div>
        )
      )}
      {userStatus === 'accessInactive' ? (
        <div className="d-flex flex-column align-items-center">
          <div className="a_iconDiv d-flex align-items-center justify-content-center">
            <i className="fas fa-file-alt" />
          </div>
          <p className="a_transcriptUpgradeText">
            <big>
              <b>Not Available</b>
            </big>
            <br />
            This feature is not available with your current plan. Please ask your administrator to contact GIBLIB to
            potentially add this feature.
          </p>
          {/* <Button
            variant="primary"
            type="button"
            className="a_upgradeButton py-0"
            onClick={() => onClickUpgrade()}
          >
            Upgrade
          </Button> */}
        </div>
      ) : null}
    </StyledTranscript>
  );
});
export default Transcript;
