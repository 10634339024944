// @flow
import axios from 'axios';
import {makeAutoObservable, runInAction} from 'mobx';
import {
  getCarousel,
  getCarouselTopics,
  getCarouselVideos,
  getLogicCarouselVideos,
  getNewWatches,
} from '../../api/carousel';
import {followItem, unfollowItem, createBookmark, deleteBookmark, getSpecialties} from '../../api/course';
import {getCourse} from '../../api/courseLanding';
import {getConference} from '../../api/conferenceLanding';
import {getExpert} from '../../api/expert';
import {getOrganization, getSortedOrganizations} from '../../api/organization';
import {getVideo, getContentTypeList} from '../../api/video';
import LocalStorageService from '../../utils/LocalStorageService';
import type {Carousel} from '../../utils/types';
import ErrorStore from '../error/errorStore';

const {CancelToken} = axios;

class MediaStore {
  loadingCarousels = true;

  generatingCarousels = false;

  carousel: Carousel = {};

  carousels: Carousel[] = null;

  carouselTopics: Array = [];

  source;

  videos: Array = [];

  title: string = '';

  sortBy: string = 'DESC';

  sort: string = 'release_date';

  currentCarouselId: string = '';

  allVideosFilter: Array = [];

  errorStore: ErrorStore;

  page: number = 1;

  videoCount: number = 0;

  count: number = 10;

  selectedContenttypes: Array = [];

  selectedContenttypeList: Array = [];

  contenttypeList: Array = [];

  organizationsList: Array = [];

  selectedOrganizationList: Array = [];

  selectedOrganizationIds: Array = [];

  searchText: string = '';

  specialtyId: number = null;

  selectedSpecialtiesList: string = [];

  selectedSpecialtyIds: Array = [];

  selectedSubSpecialtyIds: Array = [];

  subSpecialtiesList: Array = [];

  specialtyList: Array = [];

  selectedSubSpecialtiesList: Array = [];

  isCME: Boolean = false;

  isVR: Boolean = false;

  isCarouselLoading = false;

  carouselAPICallCount = 0;

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
    this.source = CancelToken.source();
  }

  changeSort = option => {
    this.sortBy = option;
    this.videos = [];
  };

  pageIncrement = () => {
    this.page += 1;
  };

  pageDecrement = () => {
    this.page -= 1;
  };

  changeCount = option => {
    this.count = option;
    this.page = 1;
  };

  setSearch = value => {
    this.page = 1;
    this.searchText = value;
  };

  resetSearch = () => {
    this.searchText = null;
  };

  setCME = value => {
    this.isCME = value;
  };

  setVR = value => {
    this.isVR = value;
  };

  changeSortBy = sort => {
    this.page = 1;
    switch (sort) {
      case 'name_asc':
        this.changeSort('ASC');
        this.sort = 'title';
        break;
      case 'name_desc':
        this.changeSort('DESC');
        this.sort = 'title';
        break;
      case 'date_asc':
        this.changeSort('ASC');
        this.sort = 'release_date';
        break;
      case 'date_desc':
        this.changeSort('DESC');
        this.sort = 'release_date';
        break;
      default:
        this.changeSort('ASC');
        this.sort = 'title';
        break;
    }
  };

  resetTitle = () => {
    this.title = '';
  };

  setCurrentCarouselId = id => {
    this.currentCarouselId = id;
  };

  setSelectedContenttype = (Ids, reload = true) => {
    this.selectedContenttypes = Ids;
    this.selectedContenttypeList = this.contenttypeList.filter(item => Ids.includes(item.id));
    if (reload) {
      this.page = 1;
    }
  };

  onRemoveContenttype = id => {
    this.selectedContenttypeList = this.selectedContenttypeList.filter(item => item.id !== id);
    this.selectedContenttypes = this.selectedContenttypes.filter(item => item !== id);
  };

  setSelectedOrganizations = Ids => {
    this.selectedOrganizationIds = Ids;
    this.selectedOrganizationList = this.organizationsList.filter(item => Ids.includes(item.id));
  };

  onRemoveOrganization = id => {
    this.selectedOrganizationList = this.selectedOrganizationList.filter(item => item.id !== id);
    this.selectedOrganizationIds = this.selectedOrganizationIds.filter(item => item !== id);
  };

  /**
   * set course providers list observables
   * @param {array} organizations
   */
  setOrganizationsObservables = organizations => {
    this.organizationsList = organizations;
  };

  // remove related specialty when sub specialty is selected
  removeSpecialtyOnSelectSubspecialty = () => {
    const sp = this.selectedSpecialtiesList?.filter(x =>
      x.children.every(item => !this.selectedSubSpecialtiesList?.includes(item)),
    );
    this.specialtyList = sp?.map(item => item.id);
  };

  /** Fetch All organizations */
  listOrganizations = async () => {
    try {
      const resp = await getSortedOrganizations('DESC');
      let {organizations} = resp.data;
      organizations = organizations.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setOrganizationsObservables(organizations);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * To formate data on data recieved from apis
   * @param {Carousel} carouselItem
   */
  formatCarouselOnAppend = carouselItem => {
    const loggedUser = LocalStorageService.getUser();
    const carousel = {...carouselItem};

    if (carousel?.organization?.length) {
      carousel.organization = carousel.organization.map(item => ({
        ...item,
        is_following: item?.follow?.length && loggedUser,
      }));
    }

    if (carousel?.expert?.length) {
      carousel.expert = carousel.expert.map(item => ({
        ...item,
        is_following: item?.follow?.length && loggedUser,
      }));
    }

    if (carousel?.courses?.length) {
      carousel.courses = carousel.courses.map(item => ({
        ...item,
        is_following: item?.follow?.length && loggedUser,
      }));
    }

    if (carousel?.conferences?.length) {
      carousel.conferences = carousel.conferences.map(item => ({
        ...item,
        is_following: item?.follow?.length && loggedUser,
      }));
    }

    return carousel;
  };

  /**
   * set fetched carousels to carousels observer
   * @param {Carousel} carouselList
   */
  setCarousels = carousels => {
    this.generatingCarousels = false;
    this.loadingCarousels = false;
    // Combine hero objects (just videos and conferences for now) in the store to keep
    // the components clean.
    const hero_carousel = carousels.find(carousel => carousel?.carouselType?.slug === 'hero');
    if (hero_carousel) {
      const videos = hero_carousel.videos || [];
      const conferences = hero_carousel.conferences || [];
      hero_carousel.hero_items = [...videos, ...conferences].sort((a, b) => {
        const a_order = a?.CarouselVideo?.order || a?.carousel_conference?.order || 0;
        const b_order = b?.CarouselVideo?.order || b?.carousel_conference?.order || 0;
        if (+a_order > +b_order) return 1;
        if (+a_order < +b_order) return -1;
        return 0;
      });
    }
    this.carousels = carousels;
  };

  updateCarousels = (updatedCarousels: Carousel[]) => {
    if (this.loadingCarousels) return;
    let newCarousels = [...(this.carousels || [])];
    updatedCarousels.forEach(carousel => {
      if (carousel?.id) {
        const index = newCarousels.findIndex(cr => cr.id === carousel.id);
        if (index !== -1) {
          newCarousels[index] = this.formatCarouselOnAppend(carousel);
        } else {
          newCarousels.push(this.formatCarouselOnAppend(carousel));
          newCarousels = newCarousels.sort((a, b) => a?.carouselType?.order - b?.carouselType?.order);
        }
      }
    });
    this.setCarousels(newCarousels);
  };

  fetchItem = async (type: String, carouselId: number, itemId: number, storeVar: string) => {
    this.source?.cancel('Operation canceled by nextItem.');
    let getItem;
    this.source = CancelToken.source();
    switch (type) {
      case 'videos':
        getItem = () => getVideo(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.video || null);
        break;
      case 'expert':
        getItem = () => getExpert(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.expert || null);
        break;
      case 'organization':
        getItem = () =>
          getOrganization(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.organization || null);
        break;
      case 'courses':
        getItem = () => getCourse(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.course || null);
        break;
      case 'conferences':
        getItem = () =>
          getConference(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.course || null);
        break;
      case 'userVideos':
        getItem = () => getVideo(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.video || null);
        break;
      default:
        break;
    }
    try {
      const item = await getItem();
      if (item) {
        if (!storeVar) {
          this.updateItem(type, carouselId, item);
        } else {
          this.updateExtraVars(storeVar, item);
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updateExtraVars = (storeVar, item) => {
    const videoIndex = this.videos.findIndex(video => +video.id === +item.id);
    if (videoIndex !== -1) {
      this[storeVar][videoIndex] = {
        isExtraDataLoaded: true,
        ...item,
      };
      this[storeVar] = [...this[storeVar]];
    }
  };

  updateItem = (type: String, carouselId: number, data: any) => {
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === +carouselId);
    if (carouselIndex !== -1) {
      const itemIndex = this.carousels[carouselIndex][type]?.findIndex(item => +item.id === +data.id);
      if (itemIndex !== -1) {
        this.carousels[carouselIndex][type][itemIndex] = {
          ...this.carousels[carouselIndex][type][itemIndex],
          isExtraDataLoaded: true,
          ...data,
        };
      }
    }
    this.carousels = [...this.carousels];
  };

  loadCarousel = generatingCarousels => {
    if (!this.loadingCarousels) {
      this.loadingCarousels = true;
      if (generatingCarousels) {
        this.generatingCarousels = true;
        setTimeout(() => {
          this.fetchCarousel();
        }, 7 * 1000);
        return;
      }
      this.fetchCarousel();
    }
  };

  fetchCarousel = async () => {
    this.carouselAPICallCount += 1;
    try {
      const res = await getCarousel();
      const carousels = (res?.data?.carousels || []).map(this.formatCarouselOnAppend);
      this.setCarousels(carousels);
    } catch (error) {
      this.errorStore.setError(error);
      this.carouselAPICallCount -= 1;
    } finally {
      this.carouselAPICallCount -= 1;
    }
  };

  loadLogicCarouselVideos = async (slug, content_subtype) => {
    this.removeSpecialtyOnSelectSubspecialty();
    try {
      const res = await getLogicCarouselVideos(
        slug,
        this.sortBy,
        this.sort,
        this.count,
        this.page,
        content_subtype,
        this.selectedOrganizationIds,
        this.specialtyList ? this.specialtyList.concat(this.selectedSubSpecialtyIds) : null,
        this.searchText ? this.searchText : null,
        this.isCME,
        this.isVR ? '360vr' : null,
      );
      runInAction(() => {
        this.videos = res?.data?.videos;
        this.title = res?.data?.title;
        this.videoCount = res?.data.count;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  updateCarouselFollowing = (follow: boolean) => {
    this.carousel.is_following = follow;
  };

  /** Follow carousel */
  followCarousel = async (id: number) => {
    try {
      const resp = await followItem(id, 'carousel');
      const {status} = resp.data;
      if (status) {
        this.updateCarouselFollowing(true);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow carousel */
  unFollowCarousel = async (id: number) => {
    try {
      const resp = await unfollowItem(id, 'carousel');
      const {status} = resp.data;
      if (status) {
        this.updateCarouselFollowing(false);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setCarousel = (carousel: Carousel) => {
    this.carousel = carousel;
    this.videos = carousel?.videos;
    this.title = carousel?.title;
    this.videoCount = carousel?.count;
    if (carousel?.follow?.length) this.updateCarouselFollowing(true);
    else this.updateCarouselFollowing(false);
  };

  listContentTypes = async () => {
    this.contenttypeList = [];
    const res = await getContentTypeList();
    const content_subtype = res?.data?.content_subtype;
    if (content_subtype.length) {
      content_subtype.forEach(
        (item, index) =>
          item.content_subtype !== null &&
          item.content_subtype !== '' &&
          this.contenttypeList.push({id: index, name: item}),
      );
    }
  };

  setSelectedSpecialties = Ids => {
    this.selectedSpecialtyIds = Ids;
    this.selectedSpecialtiesList = this.specialtiesList?.filter(item => Ids.includes(item.id));
    this.setSubSpecialtyList(this.selectedSpecialtiesList, this.selectedSubSpecialtiesList);
  };

  setSelectedSubSpecialties = Ids => {
    this.selectedSubSpecialtyIds = Ids;
    this.selectedSubSpecialtiesList = this.subSpecialtiesList.filter(item => Ids.includes(item.id));
  };

  // remove duplicates from subspecialties
  removeDuplicates = () => {
    this.subSpecialtiesList = [...new Map(this.subSpecialtiesList.map(item => [item.id, item])).values()];
  };

  setSubSpecialtyList = (
    selectedSpecialtiesList = this.selectedSpecialtiesList,
    selectedSubSpecialtiesList = this.selectedSubSpecialtiesList,
  ) => {
    this.subSpecialtiesList = [];
    const allChildren = selectedSpecialtiesList?.map(({children}) => children);
    allChildren?.forEach(item => this.subSpecialtiesList.push(...item?.map(x => x)));
    // add the selected subspecialty to the subspecialty list when its related specialty is not selected
    const exist = selectedSubSpecialtiesList?.filter(r => !this.subSpecialtiesList.includes(r));
    this.subSpecialtiesList?.unshift(...exist.map(x => x));
    this.removeDuplicates();
  };

  /** Remove Specialty */
  onRemoveSpecialty = id => {
    this.selectedSpecialtiesList = this.selectedSpecialtiesList.filter(item => item.id !== id);
    this.selectedSpecialtyIds = this.selectedSpecialtyIds.filter(item => item !== id);
    this.setSubSpecialtyList(this.selectedSpecialtiesList, this.selectedSubSpecialtiesList);
  };

  onRemoveSubSpecialty = id => {
    this.selectedSubSpecialtiesList = this.selectedSubSpecialtiesList.filter(item => item.id !== id);
    this.selectedSubSpecialtyIds = this.selectedSubSpecialtyIds.filter(item => item !== id);
  };

  setSpecialtiesObservables = specialties => {
    this.specialtiesList = specialties;
  };

  /** Fetch All specialties */
  listSpecialties = async () => {
    try {
      const resp = await getSpecialties();
      let {specialties} = resp.data;
      specialties = specialties.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setSpecialtiesObservables(specialties);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  loadCarouselVideos = async (id, content_subtype) => {
    this.videos = [];
    this.removeSpecialtyOnSelectSubspecialty();
    try {
      const res = await getCarouselVideos(
        id,
        this.sortBy,
        this.sort,
        content_subtype,
        this.count,
        this.page,
        this.selectedOrganizationIds,
        this.specialtyList.concat(this.selectedSubSpecialtyIds),
        this.searchText ? this.searchText : null,
        this.isCME,
        this.isVR ? '360vr' : null,
      );
      if (content_subtype === '') this.allVideosFilter = [];
      runInAction(() => {
        this.setCarousel(res?.data);
        if (!this.allVideosFilter.length) {
          this.videos.forEach(
            item =>
              !this.allVideosFilter.includes(item.content_subtype.name) &&
              this.allVideosFilter.push(item.content_subtype.name),
          );
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  loadCarouselTopics = async () => {
    try {
      const res = await getCarouselTopics();
      runInAction(() => {
        this.carouselTopics = this.carousels
          .filter(v => res?.data?.carouselTypes.filter(topic => topic.id === v.carouselType.id).length > 0)
          .filter(v => v.videos.length > 0);
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  loadResumeVideos = async () => {
    this.isCarouselLoading = true;
    try {
      const res = await getNewWatches();
      const {status, videos} = res.data;
      this.isCarouselLoading = false;
      if (status) {
        const carouselIndex = this.carousels?.findIndex(carousel => carousel.carouselType?.slug === 'resume-watching');
        if (carouselIndex && carouselIndex !== -1) {
          const carousels = [...this.carousels];
          if (videos?.length >= 5) {
            carousels[carouselIndex].videos = videos;
          } else {
            carousels.splice(carouselIndex, 1);
          }
          runInAction(() => {
            this.carousels = carousels;
          });
        } else if (videos?.length >= 5) this.loadCarousel();
      }
    } catch (error) {
      this.isCarouselLoading = false;
      this.errorStore.setError(error);
    }
  };

  /** Follow Expert */
  followExpert = async (selectedItem, type) => {
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(type));
    const expertIndex = this.carousels[carouselIndex].expert.findIndex(item => item.id === Number(selectedItem.id));
    const followType = 'expert';
    try {
      const resp = await followItem(selectedItem.id, followType);
      const {status, follow} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex].expert[expertIndex].follow = [follow];
        carousels[carouselIndex].expert[expertIndex].is_following = true;
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow Expert */
  unFollowExpert = async (selectedItem, type) => {
    const {id} = selectedItem;
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(type));
    const expertIndex = this.carousels[carouselIndex].expert.findIndex(item => item.id === Number(selectedItem.id));
    try {
      const resp = await unfollowItem(id, 'expert');
      const {status} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex].expert[expertIndex].follow = [];
        carousels[carouselIndex].expert[expertIndex].is_following = false;
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow organization */
  followOrganization = async (selectedItem, type) => {
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(type));
    const orgIndex = this.carousels[carouselIndex].organization.findIndex(org => org.id === Number(selectedItem.id));
    const followType = 'organization';
    try {
      const resp = await followItem(selectedItem.id, followType);
      const {status, follow} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex].organization[orgIndex].follow = [follow];
        carousels[carouselIndex].organization[orgIndex].is_following = true;
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow organization */
  unFollowOrganization = async (selectedItem, carouselType) => {
    const {id} = selectedItem;
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(carouselType));
    const orgIndex = this.carousels[carouselIndex].organization.findIndex(org => org.id === Number(selectedItem.id));
    try {
      const resp = await unfollowItem(id, 'organization');
      const {status} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex].organization[orgIndex].follow = [];
        carousels[carouselIndex].organization[orgIndex].is_following = false;
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow Course */
  followCourse = async (selectedItem, type) => {
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(type));
    const courseIndex = this.carousels[carouselIndex].courses.findIndex(
      course => course.id === Number(selectedItem.id),
    );
    const followType = 'course';
    try {
      const resp = await followItem(selectedItem.id, followType);
      const {status, follow} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex].courses[courseIndex].follow = [follow];
        carousels[carouselIndex].courses[courseIndex].is_following = true;
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow Course */
  unFollowCourse = async (selectedItem, type) => {
    const {id} = selectedItem;
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(type));
    const courseIndex = this.carousels[carouselIndex].courses.findIndex(
      course => course.id === Number(selectedItem.id),
    );

    try {
      const resp = await unfollowItem(id, 'course');
      const {status} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex].courses[courseIndex].follow = [];
        carousels[carouselIndex].courses[courseIndex].is_following = false;
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Bookmark Course or Conference */
  onCreateBookmark = async (selectedItem, carouselId, type = 'course') => {
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(carouselId));
    const {id} = selectedItem;
    const itemIndex = this.carousels[carouselIndex][`${type}s`].findIndex(item => item.id === Number(id));
    try {
      const resp = await createBookmark(id, type);
      const {status, bookmark} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex][`${type}s`][itemIndex].bookmark = [bookmark];
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete course or conference bookmark */
  onDeleteBookmark = async (selectedItem, carouselId, type = 'course') => {
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(carouselId));
    const {id} = selectedItem;
    const itemIndex = this.carousels[carouselIndex][`${type}s`].findIndex(item => item.id === Number(id));
    try {
      const resp = await deleteBookmark(id, type);
      const {status} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex][`${type}s`][itemIndex].bookmark = [];
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow Conference */
  onFollowConference = async (selectedItem, type) => {
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(type));
    const conferenceIndex = this.carousels[carouselIndex].conferences.findIndex(
      conference => conference.id === Number(selectedItem.id),
    );
    const followType = 'conference';
    try {
      const resp = await followItem(selectedItem.id, followType);
      const {status, follow} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex].conferences[conferenceIndex].follow = [follow];
        carousels[carouselIndex].conferences[conferenceIndex].is_following = true;
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow Course */
  onUnfollowConference = async (selectedItem, type) => {
    const {id} = selectedItem;
    const carouselIndex = this.carousels.findIndex(carousel => carousel.id === Number(type));
    const conferenceIndex = this.carousels[carouselIndex].conferences.findIndex(
      conference => conference.id === Number(selectedItem.id),
    );

    try {
      const resp = await unfollowItem(id, 'conference');
      const {status} = resp.data;
      if (status) {
        const carousels = [...this.carousels];
        carousels[carouselIndex].courses[conferenceIndex].follow = [];
        carousels[carouselIndex].courses[conferenceIndex].is_following = false;
        runInAction(() => {
          this.carousels = carousels;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /* update carousel follow with respect to change in respective pages */
  updateCarouselFollow = async (id, follow, type) => {
    const index = this.carousels?.findIndex(item => item.carouselType?.slug === type);
    let itemIndex = null;
    if (index) {
      switch (type) {
        case 'organization':
          itemIndex = this.carousels[index]?.organization.findIndex(item => item.id === id);
          if (itemIndex >= 0) {
            this.carousels[index].organization[itemIndex].is_following = follow;
          }
          break;
        case 'courses':
          itemIndex = this.carousels[index]?.courses.findIndex(item => item.id === id);
          if (itemIndex >= 0) {
            this.carousels[index].courses[itemIndex].is_following = follow;
          }
          break;
        default:
          break;
      }
    }
  };
}

export default MediaStore;
