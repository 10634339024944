import axios from './axios';

/**
 * calls the account GET api to get the account details
 */
const getAccount = () => axios.get('/account');

/**
 * calls the account PUT api to update the account details
 */
const setAccount = data => axios.put('/account', data);

const updatePayment = (paymentMethodId: string) => axios.put(`/account/update-payment`, {paymentMethodId});

const deleteSubscription = (reason, feedback) =>
  axios.delete(`/account/cancel-subscription?reason=${reason}&feedback=${feedback}`);

const updateSubscription = (
  priceId,
  couponCode,
  couponStripeId,
  skipTrial,
  shipping_address,
  shipping_zip,
  shipping_city,
  shipping_state,
  mobilephone,
) =>
  axios.put(`/account/upgrade-subscription`, {
    priceId,
    couponCode,
    couponStripeId,
    skipTrial,
    shipping_address,
    shipping_zip,
    shipping_city,
    shipping_state,
    mobilephone,
  });

const reactivateSubscription = () => axios.put(`/account/reactivate-subscription`);

/**
 * calls the user interest GET api to get the interests
 */
const getInterests = () => axios.get('/interest');

const getRemoteIP = () => axios.get('/account/get-ip');

export {
  getAccount,
  setAccount,
  updatePayment,
  deleteSubscription,
  updateSubscription,
  reactivateSubscription,
  getInterests,
  getRemoteIP,
};
