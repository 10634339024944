import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useHistory, Route, Switch, Redirect, useRouteMatch} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {SubscriptionCancelContainer} from './elements/cancelSubscription';
import SubscriptionReason from './components/SubscriptionReason';
import SubscriptionFeedback from './components/SubscriptionFeedback';
import {useStore} from '../../store';
import SubscriptionNavbar from '../../components/SubscriptionNavbar';

const CancelSubscription = () => {
  const {path} = useRouteMatch();
  const {accountStore} = useStore();
  const {cancellationCompleted, cancelSubsciption} = accountStore;
  const location = useHistory();
  const [cancelReason, setReason] = useState(null);
  const [cancelFeedback, setFeedback] = useState('');
  const [submitClicked, setSubmitStatus] = useState(false);

  useEffect(() => {
    if (cancellationCompleted) {
      location.push('/profile/settings');
    } else {
      setSubmitStatus(false);
    }
  }, [cancellationCompleted, location]);

  const gotoNext = () => {
    location.push('/cancel/description');
  };

  const onSetReason = value => {
    setReason(value);
  };

  const onSetFeedback = value => {
    setFeedback(value);
  };

  const gotoPrevious = () => {
    location.push('/cancel/reason');
  };

  const completeCancellation = () => {
    setSubmitStatus(true);
    cancelSubsciption(cancelReason.text, cancelFeedback);
  };

  return (
    <SubscriptionCancelContainer>
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <SubscriptionNavbar />
      <Switch>
        <Route path={`${path}/reason`}>
          <SubscriptionReason reason={cancelReason} setCancelReason={onSetReason} onContinue={gotoNext} />
        </Route>
        <Route path={`${path}/description`}>
          <SubscriptionFeedback
            feedback={cancelFeedback}
            setCancelFeedback={onSetFeedback}
            onBack={gotoPrevious}
            onContinue={completeCancellation}
            buttonClicked={submitClicked}
          />
        </Route>
        <Redirect to={`${path}/reason`} />
      </Switch>
    </SubscriptionCancelContainer>
  );
};

export default observer(CancelSubscription);
