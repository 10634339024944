import styled from 'styled-components';

export const Breadcrumbs = styled.ul`
  list-style: none;
  font-weight: 600;
  padding-top: 1em;
  padding-inline-start: 0px;
  margin-bottom: -20px;
  & > li:after {
    content: '${props => props.separator || '/'}';
    padding: 0 3px;
  }
`;

export const Crumb = styled.li`
  font-weight: bold;
  display: inline-block;
  font-size: 12px;
  color: ${({theme}) => theme.text2};
  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;

export const ActiveCrumb = styled.li`
  display: inline-block;
  font-size: 12px;
  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;

export const PageTitle = styled.h5`
  font-weight: 600;
  margin-top: 1em;
  font-size: 20px;
  line-height: 26px;
  margin-left: 15px;
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 30px 8px 16px;
  border-radius: 30px;
  margin-top: 0.1em;
  width: 191px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => theme.text4};
  @media screen and (min-width: 576px) and (max-width: 1200px) {
    width: 170px;
  }
  @media screen and (max-width: 500px) {
    width: 175px;
  }
  @media screen and (max-width: 414px) {
    width: 158px;
  }
  @media screen and (max-width: 320px) {
    width: 130px;
  }
`;

export const FormLabel = styled.h5`
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
`;

export const ChipText = styled.p`
  color: #0677d8;
  margin-bottom: 8px;
  margin-right: 8px;
  padding-left: 4px;
  height: 24px;
  border-radius: 8px;
  background-color: #cce8ff;
`;

export const CourseFilter = styled.div`
  margin-left: auto;

  .form-group {
    margin-bottom: 8px;
    margin-top: 8px;
    padding-right: 0px;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const MobileFilter = styled.div`
  display: none;
  @media screen and (max-width: 576px) {
    display: block;
    .mobileSort {
      margin-left: 176px;
    }
  }
  @media screen and (max-width: 500px) {
    #filterSpecialty {
      left: 210px;
    }
  }
  @media screen and (max-width: 414px) {
    #filterSpecialty {
      left: 180px;
    }
  }
  @media screen and (max-width: 375px) {
    .mobileSort {
      margin-left: 136px;
    }
    #filterSpecialty {
      left: 135px;
    }
  }
  @media screen and (max-width: 360px) {
    .mobileSort {
      margin-left: 122px;
    }
  }
  @media screen and (max-width: 320px) {
    // h5 {
    //   white-space: normal;
    // }
    #filterSpecialty {
      left: 102px;
    }
    .mobileSort {
      margin-left: 110px;
    }
  }
`;

export const Line = styled.div`
  @media screen and (max-width: 576px) {
    height: 24px;
    border-bottom: 1px solid #4e505a;
    margin-bottom: 25px;
  }
`;

export const Main = styled.div`
  overflow: hidden;
  height: 100%;
  min-height: 100vh;

  hr {
    border-color: rgba(255, 255, 255, 0.4);
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
