import React, {useState, useEffect} from 'react';
import {useLocalStore} from 'mobx-react-lite';
import {Button, OverlayTrigger, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import Moment from 'react-moment';
import {useStore} from '../../../store';
import {
  Badge,
  BadgeNew,
  Organization,
  ConferenceTitle,
  ReleaseDate,
  ButtonGroup,
  VideoImgCard,
  VideosCnt,
  ThumbnailImg,
  DeskFilter,
  MobFilter,
} from '../elements/conferences';
import '../elements/conferences.css';
import stringToURLSlug from '../../../utils/helper';
import ConferencePlaylistPopover from '../../../components/CoursePlaylistPopover';
import LocalStorageService from '../../../utils/LocalStorageService';
import UpgradeModal from '../../../components/UpgradeModal';
import {ActiveSmallBtn, DefaultSmallBtn} from '../../../components/FollowButtons';
import GiblibIcon from '../../../assets/images/GBLB_Logo_Mark_FullColor.png';
import CMEIcon from '../../../assets/icons/CME.svg';
import VRIcon from '../../../assets/icons/360vr.svg';

type Props = {
  conference: {
    id: number,
    title: string,
    description: string,
    number_of_videos: number,
    total_runtime: {
      hours?: number,
      minutes?: number,
      seconds?: number,
    },
    is_giblib_produced: Boolean,
    is_360vr: Boolean,
    is_cme: Boolean,
    earliest_date: Date,
    latest_date: Date,
    credit_count: number,
    thumbnail_url: String,
    bookmark: Array,
    follow: Array,
    organization: {
      name: string,
    },
    specialty: Array,
    videos: [{id: number}],
  },
  onFollow: Function,
  onBookmark: Function,
  playlist: {
    id: number,
    name: string,
  },
  playlistStore: string,
};
// Conferences List
const ConferenceCard = ({conference, onFollow, onBookmark, playlist, playlistStore}: Props) => {
  const location = useHistory();

  const loggedInUser = LocalStorageService.getUser();

  const store = useStore();
  const {account} = store.accountStore;
  const {addConferenceToNewPlaylist, addConferenceToPlaylist} = store[playlistStore];

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const [playlistUpdating, setUpdating] = useState(false);

  const state = useLocalStore(() => ({
    isNew: false,
  }));
  // new badge (displays for 30 days from release date)
  useEffect(() => {
    const createdVideo = new Date(conference.latest_date);
    const after30Video = new Date(createdVideo.getFullYear(), createdVideo.getMonth(), createdVideo.getDate() + 30);
    const today = new Date();
    if (after30Video > today) {
      state.isNew = true;
    }
  }, [state, conference]);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  const bookmarkConference = (e, selectedItem) => {
    e.stopPropagation();
    onBookmark(selectedItem);
  };

  const gotoConferenceDetail = id => {
    const slug = stringToURLSlug(conference.title);
    location.push(`/conferences/${id}/${slug}`);
  };

  const followConference = (e, selectedItem) => {
    e.stopPropagation();
    if (!account?.subscription?.name?.includes('Basic')) onFollow(selectedItem);
    else setShowFollowUpgradeModal(true);
  };

  const createAndAddToPlaylist = async data => {
    setUpdating(true);
    await addConferenceToNewPlaylist(conference.id, data.listname);
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    await addConferenceToPlaylist(conference.id, data);
    setUpdating(false);
  };

  return (
    <div className="conferenceBody p-3 mb-3">
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <DeskFilter>
        <div className="d-flex" id={conference.id}>
          <VideoImgCard>
            <VideosCnt>{conference.number_of_videos} Videos</VideosCnt>
            <ThumbnailImg
              className="mr-4"
              src={conference.thumbnail_url}
              alt={conference.title}
              onClick={() => gotoConferenceDetail(conference.id)}
            />
          </VideoImgCard>
          <div
            className="w-100"
            role="button"
            tabIndex="0"
            onClick={() => gotoConferenceDetail(conference.id)}
            onKeyDown={() => gotoConferenceDetail(conference.id)}
          >
            <Badge className="d-flex align-items-center">
              {conference.is_giblib_produced ? <img className="icon-giblib" src={GiblibIcon} alt="giblib" /> : ''}
              {state.isNew ? <BadgeNew>NEW</BadgeNew> : ''}
              {conference.is_360vr ? <img src={VRIcon} className="icon-vr" alt="vr" /> : ''}
              {conference.is_cme ? (
                <>
                  <object data={CMEIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />
                  <div className="credit">{conference?.credit_count} Credits</div>
                </>
              ) : (
                ''
              )}
            </Badge>
            <ConferenceTitle>
              <h5>{conference.title}</h5>
            </ConferenceTitle>
            <Organization>
              {conference.organization ? conference.organization.name : ''}
              {conference.organization && conference.specialty.length > 0 ? ' | ' : ''}
              {conference.specialty?.length > 0
                ? `${conference?.specialty
                    .slice(0, 2)
                    ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                : ''}
            </Organization>
            {conference?.earliest_date && conference?.latest_date ? (
              <ReleaseDate>
                <Moment format="MM/YYYY">{conference?.earliest_date}</Moment> -{' '}
                <Moment format="MM/YYYY">{conference?.latest_date}</Moment>
              </ReleaseDate>
            ) : (
              ''
            )}
          </div>
          <ButtonGroup className="text-right">
            {conference.follow?.length ? (
              <ActiveSmallBtn className=" a_followed" onClick={e => followConference(e, conference)}>
                <span className="following">
                  <i className="fas fa-check mr-2" />
                  Following
                </span>
                <span className="unfollow">
                  <i className="fas fa-times mr-2" />
                  Unfollow
                </span>
              </ActiveSmallBtn>
            ) : (
              <DefaultSmallBtn className="a_follow" onClick={e => followConference(e, conference)}>
                <i className="fas fa-plus mr-2" />
                Follow
              </DefaultSmallBtn>
            )}
            {loggedInUser ? (
              [
                !playlistUpdating ? (
                  <OverlayTrigger
                    trigger="click"
                    placement="auto-start"
                    overlay={props => (
                      <ConferencePlaylistPopover
                        props={props}
                        allPlaylist={playlist}
                        onNew={createAndAddToPlaylist}
                        onExisting={updateExistingPlaylist}
                        courseVideos={conference.videos}
                        userState={userState}
                        showUpgradeModal={isShowPlaylistUpgradeModal}
                        onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                      />
                    )}
                    rootClose
                  >
                    <Button variant="basic" className="a_playlist" onClick={() => setShowPlaylistUpgradeModal(true)}>
                      <i className="fak fa-list-ul" />
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <div className="p-0 mr-3 btn">
                    <Spinner animation="border" size="sm" variant="primary" />
                  </div>
                ),
              ]
            ) : (
              <Button variant="basic" className="a_playlist" onClick={() => location.push('/login')}>
                <i className="fak fa-list-ul" />
              </Button>
            )}
            {account?.subscription?.name?.includes('Basic') ? (
              <Button
                variant="basic"
                className="px-0 py-0 a_bookmark mr-0"
                onClick={() => setShowBookmarkUpgradeModal(true)}
              >
                <i className="far fa-bookmark" />
              </Button>
            ) : (
              <>
                <Button
                  variant="basic"
                  className={`a_bookmark ${conference.bookmark?.length ? 'd-none' : ''} mr-0`}
                  onClick={e => bookmarkConference(e, conference)}
                >
                  <i className="far fa-bookmark" />
                </Button>
                <Button
                  variant="basic"
                  className={`a_bookmark ${conference.bookmark?.length ? 'bookmark-fill' : 'd-none'} mr-0`}
                  onClick={e => bookmarkConference(e, conference)}
                >
                  <i className="fas fa-bookmark" />
                </Button>
              </>
            )}
          </ButtonGroup>
        </div>
      </DeskFilter>
      <MobFilter>
        <div id={conference.id}>
          <VideoImgCard>
            <VideosCnt>{conference.number_of_videos} Videos</VideosCnt>
            <ThumbnailImg
              className="w-100"
              src={conference.thumbnail_url}
              alt={conference.title}
              onClick={() => gotoConferenceDetail(conference.id)}
            />
          </VideoImgCard>
          <div
            className="w-100"
            role="button"
            tabIndex="0"
            onClick={() => gotoConferenceDetail(conference.id)}
            onKeyDown={() => gotoConferenceDetail(conference.id)}
          >
            <div className="d-flex mt-3">
              <Badge className="d-flex align-items-center">
                {conference.is_giblib_produced ? <img className="icon-giblib" src={GiblibIcon} alt="giblib" /> : ''}
                {state.isNew ? <BadgeNew>NEW</BadgeNew> : ''}
                {conference.is_360vr ? <img src={VRIcon} className="icon-vr" alt="vr" /> : ''}
                {conference.is_cme ? (
                  <object data={CMEIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />
                ) : (
                  ''
                )}
              </Badge>
              <ButtonGroup className="text-right">
                {conference.follow?.length ? (
                  <ActiveSmallBtn className=" a_followed" onClick={e => followConference(e, conference)}>
                    <span className="following">
                      <i className="fas fa-check mr-2" />
                      Following
                    </span>
                    <span className="unfollow">
                      <i className="fas fa-times mr-2" />
                      Unfollow
                    </span>
                  </ActiveSmallBtn>
                ) : (
                  <DefaultSmallBtn className="a_follow" onClick={e => followConference(e, conference)}>
                    <i className="fas fa-plus mr-2" />
                    Follow
                  </DefaultSmallBtn>
                )}
                {loggedInUser ? (
                  [
                    !playlistUpdating ? (
                      <OverlayTrigger
                        trigger="click"
                        placement="auto-start"
                        overlay={props => (
                          <ConferencePlaylistPopover
                            props={props}
                            allPlaylist={playlist}
                            onNew={createAndAddToPlaylist}
                            onExisting={updateExistingPlaylist}
                            courseVideos={conference.videos}
                            userState={userState}
                            showUpgradeModal={isShowPlaylistUpgradeModal}
                            onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                          />
                        )}
                        rootClose
                      >
                        <Button
                          variant="basic"
                          className="a_playlist"
                          onClick={() => setShowPlaylistUpgradeModal(true)}
                        >
                          <i className="fak fa-list-ul" />
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <div className="p-0 mr-3 btn">
                        <Spinner animation="border" size="sm" variant="primary" />
                      </div>
                    ),
                  ]
                ) : (
                  <Button variant="basic" className="a_playlist" onClick={() => location.push('/login')}>
                    <i className="fak fa-list-ul" />
                  </Button>
                )}
                {account?.subscription?.name?.includes('Basic') ? (
                  <Button
                    variant="basic"
                    className="px-0 py-0 a_bookmark mr-0"
                    onClick={() => setShowBookmarkUpgradeModal(true)}
                  >
                    <i className="far fa-bookmark" />
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="basic"
                      className={`a_bookmark ${conference.bookmark?.length ? 'd-none' : ''} mr-0`}
                      onClick={e => bookmarkConference(e, conference)}
                    >
                      <i className="far fa-bookmark" />
                    </Button>
                    <Button
                      variant="basic"
                      className={`a_bookmark ${conference.bookmark?.length ? 'bookmark-fill' : 'd-none'} mr-0`}
                      onClick={e => bookmarkConference(e, conference)}
                    >
                      <i className="fas fa-bookmark" />
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </div>
            <ConferenceTitle>
              <h5>{conference.title}</h5>
            </ConferenceTitle>
            <Organization>
              {conference.organization ? conference.organization.name : ''}
              {conference.organization && conference.specialty.length > 0 ? ' | ' : ''}
              {conference.specialty?.length > 0
                ? `${conference?.specialty
                    .slice(0, 2)
                    ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                : ''}
            </Organization>
            {conference?.earliest_date && conference?.latest_date ? (
              <ReleaseDate>
                <Moment format="MM/YYYY">{conference?.earliest_date}</Moment> -{' '}
                <Moment format="MM/YYYY">{conference?.latest_date}</Moment>
              </ReleaseDate>
            ) : (
              ''
            )}
          </div>
        </div>
      </MobFilter>
    </div>
  );
};

export default ConferenceCard;
