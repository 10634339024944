// @flow
import React, {useState, useRef, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import {Popover, Overlay} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import './style.css';
import {observer} from 'mobx-react-lite';
import {Title, Text, PopoverDiv} from './style';
import {useStore} from '../../store';
import stringToURLSlug from '../../utils/helper';
import UpgradeModal from '../UpgradeModal';
import {amplitude} from '../../utils/Amplitude';
import {ActiveSmallBtn, DefaultSmallBtn} from '../FollowButtons';

type Props = {
  org: {
    id: number,
    name: string,
    thumbnail: string,
    videoCount: number,
    is_following: boolean,
    isExtraDataLoaded: boolean,
  },
  type: number,
  onFollow?: Function,
  storeName: string,
};

const OrganizationTile = observer(({org, type, onFollow, storeName}: Props) => {
  const location = useHistory();
  const store = useStore();
  const {fetchItem} = store[storeName];
  const {followOrganization, unFollowOrganization} = store.mediaStore;
  const [popoverOpen, togglePopover] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const timer = useRef(null);
  const {account} = store.accountStore;
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);

  const onClickTile = () => {
    const slug = stringToURLSlug(org.name);
    location.push(`/organization/${org.id}/${slug}`);
  };

  /* istanbul ignore next */
  const onHover = async event => {
    setTarget(event.currentTarget);
    if (window.outerWidth > 890) {
      timer.current = setTimeout(() => {
        togglePopover(true);
      }, 500);
    }
    // if (!org?.isExtraDataLoaded) {
    await fetchItem('organization', type, org?.id);
    // }
  };

  /* istanbul ignore next */
  const onHoverLeave = () => {
    togglePopover(false);
    clearTimeout(timer.current);
  };

  const follow = (e, selectedItem) => {
    e.stopPropagation();
    const user = localStorage.getItem('user');
    if (user) {
      if (selectedItem.is_following) {
        unFollowOrganization(selectedItem, type);
        const eventProperties = {
          type: 'organization',
          source: 'organization tile',
          name: `${selectedItem.name}`,
        };
        amplitude.getInstance().logEvent('unfollow', eventProperties);
      } else {
        followOrganization(selectedItem, type);
        const eventProperties = {
          type: 'organization',
          source: 'organization tile',
          name: `${selectedItem.name}`,
        };
        amplitude.getInstance().logEvent('follow', eventProperties);
      }
    } else {
      location.push('/login');
    }
  };

  const [isSponsored, setSponsored] = useState(false);

  useEffect(() => {
    const sponsored = window.location.pathname.split('/');
    setSponsored(sponsored.includes('sponsored'));
  }, [setSponsored]);

  const organizationDetail = id => {
    const slug = stringToURLSlug(org.name);
    if (isSponsored) {
      location.push(`/sponsored/${id}/${slug}`);
    } else {
      location.push(`/organization/${id}/${slug}`);
    }
  };

  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <div onMouseLeave={onHoverLeave}>
        <Card className="mx-auto my-2 a_orgTile" onMouseEnter={onHover} onClick={onClickTile}>
          <Card.Img src={org.thumbnail} alt={org.name} />
        </Card>
        <Overlay show={popoverOpen} target={target} container={ref.current}>
          <Popover id="popover-basic" className="org-popover" onMouseLeave={onHoverLeave}>
            <PopoverDiv>
              <Popover.Content>
                <Card>
                  <Card.Body className="d-flex justify-content-center p-0">
                    <div className="a_orgImgContainer">
                      <Card.Img
                        src={org.thumbnail}
                        alt={org.name}
                        className="a_orgImg"
                        onClick={() => organizationDetail(org.id)}
                      />
                    </div>
                  </Card.Body>
                  {org?.isExtraDataLoaded && (
                    <div className="px-3 mb-2 text-center">
                      <div>
                        <Title>{org.name}</Title>
                        <Text>{org.videoCount} Videos</Text>
                      </div>
                      <div className="ml-2 py-1">
                        {account?.subscription?.name?.includes('Basic') ? (
                          <DefaultSmallBtn className="a_follow" onClick={() => setShowFollowUpgradeModal(true)}>
                            <i className="fas fa-plus mr-2" />
                            Follow
                          </DefaultSmallBtn>
                        ) : null}
                        {!account?.subscription?.name?.includes('Basic') && org.is_following ? (
                          <ActiveSmallBtn
                            className="a_followed"
                            onClick={e => (onFollow ? onFollow(org) : follow(e, org))}
                          >
                            <span className="following">
                              <i className="fas fa-check mr-2" />
                              Following
                            </span>
                            <span className="unfollow">
                              <i className="fas fa-times mr-2" />
                              Unfollow
                            </span>
                          </ActiveSmallBtn>
                        ) : (
                          !account?.subscription?.name?.includes('Basic') && (
                            <DefaultSmallBtn
                              className="a_follow"
                              onClick={e => (onFollow ? onFollow(org) : follow(e, org))}
                            >
                              <i className="fas fa-plus mr-2" />
                              Follow
                            </DefaultSmallBtn>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </Card>
              </Popover.Content>
            </PopoverDiv>
          </Popover>
        </Overlay>
      </div>
    </>
  );
});

OrganizationTile.defaultProps = {
  onFollow: () => {},
};

export default OrganizationTile;
