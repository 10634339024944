/* eslint-disable no-nested-ternary */
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import VideoCard from './VideoCard';
import {Line, WatchNextWrapper} from '../../elements/video';
import {useStore} from '../../../../store';

const WatchNext = observer(() => {
  const {videoStore} = useStore();
  const {
    video,
    watchNextLoading,
    onFetchCourseVideos,
    courseVideos,
    onFetchPlaylistVideos,
    playlistVideos,
    onFetchRecommendedVideos,
    recommendedVideos,
    onFetchConferenceVideos,
  } = videoStore;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const courseId = query.get('course_id');
  const order = query.get('order');
  const playlistId = query.get('playlist_id');
  const conferenceId = query.get('conference_id');

  useEffect(() => {
    if (video?.id) {
      if (courseId) {
        onFetchCourseVideos(courseId, order);
      } else if (playlistId) {
        onFetchPlaylistVideos(playlistId, order);
      } else if (conferenceId) {
        onFetchConferenceVideos(conferenceId, order);
      } else {
        onFetchRecommendedVideos();
      }
    }
  }, [
    video,
    courseId,
    order,
    playlistId,
    conferenceId,
    onFetchConferenceVideos,
    onFetchCourseVideos,
    onFetchPlaylistVideos,
    onFetchRecommendedVideos,
  ]);

  const renderRecommendedVideos = () => {
    return (
      <WatchNextWrapper>
        <div className="content">
          {watchNextLoading ? (
            <div className="spinner-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
              <Spinner animation="grow" role="status" variant="secondary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : recommendedVideos.length ? (
            recommendedVideos?.map(v => (
              <>
                <VideoCard key={v.id} type="recommend" item={v} />
              </>
            ))
          ) : (
            <h6>No Recommendations to show</h6>
          )}
        </div>
      </WatchNextWrapper>
    );
  };

  const renderPlaylistVideos = () => {
    return (
      <>
        {playlistVideos?.map(v => (
          <>
            <VideoCard key={v.id} type="playlist" item={v} order={v.PlaylistVideo.order} courseId={playlistId} />
            <Line />
          </>
        ))}
      </>
    );
  };

  return (
    <>
      {courseId
        ? courseVideos?.map(v => (
            <>
              <VideoCard key={v.video.id} type="course" item={v.video} order={v.order} courseId={v.course_id} />
              <Line />
            </>
          ))
        : playlistId
        ? renderPlaylistVideos()
        : conferenceId
        ? courseVideos?.map(v => (
            <>
              <VideoCard key={v.video.id} type="conference" item={v.video} order={v.order} courseId={conferenceId} />
              <Line />
            </>
          ))
        : renderRecommendedVideos()}
    </>
  );
});

export default WatchNext;
