import {observer} from 'mobx-react-lite';
import React from 'react';
import {Button, Carousel} from 'react-bootstrap';
import {Video, Conference, Organization, Specialty as SpecialtyType, Expert} from '../../../utils/types';
import {CarouselBg, Title, Specialty, Physician, Logo} from '../elements/home';
import CommunityIcon from '../../../assets/images/communityIcon.jpg';
import history from '../../../history';

type Props = {
  hero_items: (Video | Conference)[],
  openVideo: (video: Video) => void,
  openConference: (conference: Conference) => void,
  gotoOrganization: (organization: Organization) => void,
  gotoSpecialty: (specialty: SpecialtyType) => void,
  gotoExpert: (expert: Expert) => void,
};

const Hero = observer(({hero_items, openVideo, openConference, gotoOrganization, gotoExpert, gotoSpecialty}: Props) => {
  return (
    <Carousel indicators={false}>
      {hero_items.map(hero_item => {
        const specialties = hero_item.specialties || hero_item.specialty || [];
        const {title, experts, organization} = hero_item;
        let openFunc;
        let thumbnail;
        switch (hero_item.type) {
          case 'conference':
            openFunc = () => openConference(hero_item);
            thumbnail = hero_item.thumbnail_url;
            break;
          case 'video':
            openFunc = () => openVideo(hero_item);
            thumbnail = hero_item.thumbnail;
            break;
          default:
            // default to video for now.
            openFunc = () => openVideo(hero_item);
            thumbnail = hero_item.thumbnail;
        }
        return (
          <Carousel.Item>
            <img
              className="d-block w-100 hero"
              style={{maxHeight: 400, objectFit: 'cover'}}
              src={thumbnail}
              alt={title}
              role="presentation"
              onClick={() => openFunc(hero_item)}
              onKeyDown={() => openFunc(hero_item)}
            />
            <CarouselBg onClick={() => openFunc(hero_item)} />
            <Carousel.Caption>
              <Title className="text-left" onClick={() => openFunc(hero_item)}>
                {hero_item.title}
                <div className="title-tip">{title}</div>
              </Title>
              <div className="d-flex mt-1 mb-2 mt-sm-2 mb-sm-4 content">
                {organization && (
                  <div>
                    {organization?.display && organization?.thumbnail && (
                      <Logo
                        className="avatar"
                        onClick={() => gotoOrganization(organization)}
                        src={organization?.thumbnail}
                        alt={organization.name}
                      />
                    )}
                  </div>
                )}
                <div className="text-left carousel-description">
                  {(specialties || []).slice(0, 2)?.map((s, i) => (
                    <Specialty
                      clickable={s.display}
                      className="specialties"
                      role="presentation"
                      onClick={() => gotoSpecialty(s)}
                    >
                      {i !== 0 ? `, ${s.name}, and more` : s.name}
                    </Specialty>
                  ))}
                  <Physician onClick={() => gotoExpert(experts)}>
                    {experts && experts.length === 1 ? experts[0].name : ''}
                    {experts && experts.length === 2 ? `${experts[0].name}; ${experts[1].name}` : ''}
                    {experts && hero_item.experts.length > 2 ? `${experts[0].name}; ${experts[1].name}; and more` : ''}
                  </Physician>
                </div>
              </div>
              <div className="d-flex">
                <Button className="a_WatchNow" variant="primary" onClick={() => openFunc(hero_item)}>
                  <i className="fas fa-play mr-2" />
                  Watch Now
                </Button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
      <Carousel.Item>
        <img
          className="d-block w-100 hero"
          style={{maxHeight: 400, objectFit: 'cover'}}
          src={CommunityIcon}
          alt="GiblibIcon"
          role="presentation"
          onClick={() => history.push('/all/from-our-community')}
          onKeyDown={() => history.push('/all/from-our-community')}
        />
        <CarouselBg onClick={() => history.push('/all/from-our-community')} />
        <Carousel.Caption>
          <Title className="text-left" onClick={() => history.push('/all/from-our-community')}>
            Latest Videos From Our Giblib Community
            <div className="title-tip">Latest Videos From Our Giblib Community</div>
          </Title>
          <div className="d-flex">
            <Button className="a_WatchNow" variant="primary" onClick={() => history.push('/all/from-our-community')}>
              <i className="fas fa-play mr-2" />
              Watch Now
            </Button>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
});

export default Hero;
