import React from 'react';
import {Button, Navbar, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import logo from '../../assets/images/GBLB_Logo_Horizontal_Notag_White_FullColor.png';
import {Main, Content, ContinueButton} from './launchApp';
import Arrow from './elements/arrow.svg';
import AppIcon from './elements/app-icon.svg';
import {useStore} from '../../store';

const LaunchApp = () => {
  const {regStore} = useStore();
  const {launchScreenVisibility, reloadPage} = regStore;
  // set height to the root of the document
  document.documentElement.style.setProperty('height', '100%');
  document.getElementsByTagName('body')[0].style = 'height: 100%';
  document.getElementById('root').style.height = '100%';
  return (
    <Main>
      <Navbar as={Row} className="nav mx-0">
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img alt="logo" src={logo} height="18" className="d-inline-block align-top" />
        </Navbar.Brand>
      </Navbar>
      <Content>
        <h2>Welcome</h2>
        <p>
          If you have an active GIBLIB subscription, download
          <br />
          the free GIBLIB app for a better experience.
        </p>
        <a href="https://apps.apple.com/us/app/giblib/id1606398783 " className="a_download-app">
          <img src={AppIcon} alt="app icon" />
        </a>
        <p>Already have the GIBLIB App?</p>
        <Button className="a_launch">Launch the GIBLIB App </Button>
      </Content>
      <ContinueButton
        onClick={() => {
          launchScreenVisibility(false);
          if (reloadPage) window.location.reload();
        }}
      >
        Continue to the GIBLIB website
        <img src={Arrow} alt="arrow" />
      </ContinueButton>
    </Main>
  );
};

export default LaunchApp;
