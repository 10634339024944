import {makeAutoObservable, runInAction} from 'mobx';
import axios from 'axios';
import {getExpert, getExperts} from '../../api/expert';
import {followItem, getSpecialties, unfollowItem} from '../../api/course';
import {getExpertsOrganizations} from '../../api/organization';
import {getVideo} from '../../api/video';
import ErrorStore from '../error/errorStore';
import MediaStore from '../media/mediaStore';

const {CancelToken} = axios;
type Expert = {
  id: number,
  name: string,
  thumbnail: string,
  description: string,
  videos: Array<Video>,
  follow: [],
};

class ExpertStore {
  errorStore: ErrorStore;

  mediaStore: MediaStore;

  organizationsList: Array = [];

  selectedOrganizationList: Array = [];

  specialtiesList: Array = [];

  selectedSpecialtiesList: Array = [];

  selectedSubSpecialtiesList: Array = [];

  sortBy: String = 'ASC';

  selectedOrganizationIds: Array = [];

  selectedSpecialtyIds: Array = [];

  selectedSubSpecialtyIds: Array = [];

  subSpecialtiesList: Array = [];

  expert: Expert = {};

  experts: Array = [];

  expertsPage: number = 1;

  expertVideos: Array = [];

  isLoading: Boolean = false;

  hasMoreExperts: Boolean = true;

  expertDetailIsLoading: Boolean = false;

  source;

  count: Number = 25;

  expertsCount: Number = 0;

  searchText: string = '';

  specialtyList: Array = [];

  get expertDetail(): Expert {
    return this.expert;
  }

  changeSort = option => {
    this.expertsPage = 1;
    this.sortBy = option;
    this.experts = [];
  };

  setSelectedOrganizations = Ids => {
    this.selectedOrganizationIds = Ids;
    this.selectedOrganizationList = this.organizationsList.filter(item => Ids.includes(item.id));
    this.experts = [];
    this.expertsPage = 1;
  };

  pageIncrement = () => {
    this.expertsPage += 1;
  };

  pageDecrement = () => {
    this.expertsPage -= 1;
  };

  setSearch = value => {
    this.page = 1;
    this.expertsPage = 1;
    this.searchText = value;
    this.experts = [];
  };

  resetSearch = () => {
    this.expertsPage = 1;
    this.searchText = null;
  };

  changeCount = option => {
    this.count = option;
    this.expertsPage = 1;
    this.experts = [];
  };

  // remove related specialty when sub specialty is selected
  removeSpecialtyOnSelectSubspecialty = () => {
    const sp = this.selectedSpecialtiesList.filter(x =>
      x.children.every(item => !this.selectedSubSpecialtiesList?.includes(item)),
    );
    this.specialtyList = sp.map(item => item.id);
  };

  // remove duplicates from subspecialties
  removeDuplicates = () => {
    this.subSpecialtiesList = [...new Map(this.subSpecialtiesList.map(item => [item.id, item])).values()];
  };

  setSelectedSpecialties = Ids => {
    this.selectedSpecialtyIds = Ids;
    this.selectedSpecialtiesList = this.specialtiesList.filter(item => Ids.includes(item.id));
    this.mediaStore.setSubSpecialtyList(this.selectedSpecialtiesList, this.selectedSubSpecialtiesList);
    this.subSpecialtiesList = this.mediaStore.subSpecialtiesList;
    this.experts = [];
    this.expertsPage = 1;
  };

  setSelectedSubSpecialties = Ids => {
    this.selectedSubSpecialtyIds = Ids;
    this.selectedSubSpecialtiesList = this.subSpecialtiesList.filter(item => Ids.includes(item.id));
    this.experts = [];
    this.expertsPage = 1;
  };

  /**
   * set specialties list observables
   * @param {array} specialties
   */
  setSpecialtiesObservables = specialties => {
    this.specialtiesList = specialties;
  };

  /** Fetch All specialties */
  listSpecialties = async () => {
    try {
      const resp = await getSpecialties('expert');
      let {specialties} = resp.data;
      specialties = specialties.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setSpecialtiesObservables(specialties);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // fetchItem on hover
  fetchItem = async (type: string, carouselId: number, itemId: number) => {
    this.source?.cancel('Operation canceled by nextItem.');
    this.source = CancelToken.source();
    let item = null;
    try {
      switch (type) {
        case 'videos':
          item = await getVideo(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.video || null);
          this.updateItem(this.expertVideos, item);
          break;
        case 'expert':
          item = await getExpert(itemId, {onHover: true}, this.source?.token).then(res => res?.data?.expert || null);
          this.updateItem(this.experts, item);
          break;
        default:
          break;
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // hover update
  updateItem = (itemList: any[], item: any) => {
    const itemIndex = itemList.findIndex(listItem => +listItem?.id === +item?.id);
    if (itemIndex !== -1) {
      /* eslint-disable no-param-reassign */
      itemList[itemIndex] = {
        isExtraDataLoaded: true,
        ...item,
      };
    }
    this.expertVideos = [...this.expertVideos];
    this.experts = [...this.experts];
  };

  /**
   * set course providers list observables
   * @param {array} organizations
   */
  setOrganizationsObservables = organizations => {
    this.organizationsList = organizations;
  };

  /** Fetch All organizations */
  listOrganizations = async () => {
    try {
      const resp = await getExpertsOrganizations();
      let {organizations} = resp.data;
      organizations = organizations.map(item => ({
        ...item,
        name: item.name,
        id: item.id,
      }));
      this.setOrganizationsObservables(organizations);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setExpert = (expert: Expert) => {
    this.expert = expert;
    this.expertVideos = expert.videos.sort((a, b) => (a.release_date < b.release_date ? 1 : -1));
  };

  sortVideos = (option: string) => {
    const videolist = [...this.expertVideos];
    switch (option) {
      case 'shortest':
        videolist.sort((a, b) => a.duration - b.duration);
        break;
      case 'longest':
        videolist.sort((a, b) => b.duration - a.duration);
        break;
      case 'oldest':
        videolist.sort((a, b) => (a.release_date > b.release_date ? 1 : -1));
        break;
      case 'newest':
        videolist.sort((a, b) => (a.release_date < b.release_date ? 1 : -1));
        break;
      case 'alphainc':
        videolist.sort((a, b) => (a.title > b.title ? 1 : -1));
        break;
      case 'alphadec':
        videolist.sort((a, b) => (a.title < b.title ? 1 : -1));
        break;
      default:
        break;
    }
    this.expertVideos = videolist;
  };

  constructor({errorStore, mediaStore}) {
    this.errorStore = errorStore;
    this.mediaStore = mediaStore;
    makeAutoObservable(this);
  }

  followExpert = async (expert: Expert) => {
    const followType = 'expert';
    try {
      const resp = await followItem(expert.id, followType);
      const {status, follow} = resp.data;
      runInAction(() => {
        if (status) {
          this.getExpert(expert.id);
          if (this.experts.length) {
            const updatedExperts = [...this.experts];
            const index = updatedExperts.findIndex(item => item.id === expert.id);
            updatedExperts[index].follow = [follow];
            this.setExperts(updatedExperts, 1);
          }
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onFollowExpert = async expertId => {
    const followType = 'expert';
    try {
      const resp = await followItem(expertId, followType);
      const {status, follow} = resp.data;
      if (status) {
        const updatedExperts = [...this.experts];
        const index = updatedExperts.findIndex(item => item.id === expertId);
        updatedExperts[index].follow = [follow];
        this.setExperts(updatedExperts, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onUnfollowExpert = async expert => {
    const {id} = expert;
    try {
      const res = await unfollowItem(id, 'expert');
      const {status} = res.data;

      if (status) {
        const updatedExperts = [...this.experts];
        const index = updatedExperts.findIndex(item => item.id === id);
        updatedExperts[index].follow = [];
        this.setExperts(updatedExperts, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow expert */
  unFollowExpert = async (expert: Expert) => {
    const followType = 'expert';
    try {
      const resp = await unfollowItem(expert.id, followType);
      const {status} = resp.data;
      runInAction(() => {
        if (status) {
          this.getExpert(expert.id);
          if (this.experts.length) {
            const updatedExperts = [...this.experts];
            const index = updatedExperts.findIndex(item => item.id === expert.id);
            updatedExperts[index].follow = [];
            this.setExperts(updatedExperts, 1);
          }
        }
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch expert */
  getExpert = async (id: number) => {
    try {
      if (!this.expert?.name) {
        this.expertDetailIsLoading = true;
      }
      const res = await getExpert(id);
      const {expert} = res.data;
      if (expert.follow.length) {
        expert.is_following = true;
      } else {
        expert.is_following = false;
      }
      if (expert) {
        this.expertDetailIsLoading = false;
      }
      this.setExpert(expert);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setExperts = experts => {
    this.experts = experts;
  };

  onRemoveOrganization = id => {
    this.selectedOrganizationList = this.selectedOrganizationList.filter(item => item.id !== id);
    this.selectedOrganizationIds = this.selectedOrganizationIds.filter(item => item !== id);
    this.experts = [];
  };

  /** Remove Specialty */
  onRemoveSpecialty = id => {
    this.selectedSpecialtiesList = this.selectedSpecialtiesList.filter(item => item.id !== id);
    this.selectedSpecialtyIds = this.selectedSpecialtyIds.filter(item => item !== id);
    this.mediaStore.setSubSpecialtyList(this.selectedSpecialtiesList, this.selectedSubSpecialtiesList);
    this.subSpecialtiesList = this.mediaStore.subSpecialtiesList;
    this.experts = [];
  };

  onRemoveSubSpecialty = id => {
    this.selectedSubSpecialtiesList = this.selectedSubSpecialtiesList.filter(item => item.id !== id);
    this.selectedSubSpecialtyIds = this.selectedSubSpecialtyIds.filter(item => item !== id);
    this.experts = [];
  };

  listExperts = async () => {
    this.removeSpecialtyOnSelectSubspecialty();
    this.isLoading = true;
    try {
      const res = await getExperts(
        this.selectedOrganizationIds,
        this.specialtyList.concat(this.selectedSubSpecialtyIds),
        this.sortBy,
        this.expertsPage,
        this.count,
        this.searchText,
      );
      this.isLoading = false;
      const {count, experts} = res.data;
      this.expertsCount = count;
      if (experts.length) {
        this.setExperts(experts);
      }
      runInAction(() => {
        this.hasMoreExperts = !!experts.length;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  scrollLoad = () => {
    if (!this.isLoading && this.hasMoreExperts) {
      this.listExperts(this.experts.length);
      this.expertsPage += 1;
    }
  };

  /* update expert follow with respect to change in following page */
  updateExpertFollow = async (id, follow) => {
    const index = this.experts?.findIndex(item => item.id === id);
    if (index >= 0) {
      this.experts[index].follow = follow;
    }
  };

  resetExpert = () => {
    this.expert = {};
    this.expertVideos = [];
  };
}

export default ExpertStore;
