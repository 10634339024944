/* Pigma link: https://www.figma.com/file/n1xLyxYsnVM0AtbMYX4i5q/Design-System---Chang?node-id=0%3A1 */

export default {
  gradient: 'linear-gradient(90deg, #0DFFF3 0%, #008AFF 100%)',
  PMgradient: 'linear-gradient(90deg, #008AFF 0%, #9043FF 100%)',
  background: '#050507',
  blue: '#008aff',
  teal: '#0dfff3',
  pink: '#ff50A0',
  purple: '#9043ff',
  red: '#ff5964',
  teal_accent: '#0dfff3',
  surface: '#181a21',
  light_blue: '#cce8ff',
  highlight_high: 'rgba(255, 255, 255, 0.75)',
  highlight_mid: 'rgba(255, 255, 255, 0.4)',
  highlight_low: 'rgba(255, 255, 255, 0.1)',
  darken_high: 'rgba(5, 5, 7, 0.75)',
  darken_mid: 'rgba(24, 26, 33, 0.4)',
  darken_low: 'rgba(24, 26, 33, 0.1)',
};
