import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Card, Col, Dropdown, Form, FormControl, FormLabel, Row} from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import {useForm} from 'react-hook-form';
import Analytics from '../../../analytics';
import {useStore} from '../../../store';
import {amplitude} from '../../../utils/Amplitude';
import {isIntegrationTest} from '../../../utils/helper';
import {ButtonXLarge, Caption} from '../../../utils/styles/typography';
import {AccountDesc, HorizontalDivider, RegisterDiv, TitleNumberDiv, TitleText, Wrapper} from '../style';

type Props = {
  activeRegistrationStep: string,
  navigate: (e?: React.MouseEvent<HTMLButtonElement>) => void,
};

const Accountcreation = ({activeRegistrationStep, navigate}: Props) => {
  const {authStore} = useStore();
  const {
    user,
    accountRegistering,
    onSetAccountDetails,
    registeredEmailResponseText,
    onRegisterEmail,
    getSpecialtyList,
    specialtyList,
    selectedProduct,
    professionList,
    getAllLists,
  } = authStore;
  const {register, handleSubmit, errors, watch} = useForm({});
  const watchEmailField = watch('email', false);
  const watchFirstNameField = watch('firstName', false);
  const watchLastNameField = watch('lastName', false);
  const watchFieldProfessionOther = watch('other_profession', false);
  const watchHospitalField = watch('hospital', false);
  const watchPwField = watch('password', false);
  const watchIsHcpField = watch('is_hcp', false);
  const watchPwConfirmField = watch('confirmPassword', false);
  const [loading, setLoading] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState();
  const [profession_id, setProfession] = useState();
  const [otherId, setOtherId] = useState(0);
  const recaptchaRef = useRef();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  useEffect(() => {
    if (!professionList?.length) {
      getAllLists();
    }
  }, [errors, professionList, getAllLists]);

  useEffect(() => {
    const other_id = professionList?.find(el => el?.display_name === 'Other');
    setOtherId(other_id?.id);
  }, [professionList]);

  const onRecaptchaCompleted = token => {
    // Save recaptcha token on successful completion.
    // eslint-disable-next-line
    recaptchaRef.current.reset();
    setRecaptchaToken(token);
  };

  const bypassRecaptcha = isIntegrationTest();
  const [errorMsg, setErrorMsg] = useState(null);
  const watchAllFields = watch();

  const CheckEmail = useCallback(
    formData => {
      if (formData.email) {
        setLoading(true);
        onRegisterEmail(formData.email, null).then(() => {
          onSetAccountDetails(formData);
          amplitude.getInstance().logEvent('account-created-v3');
          setLoading(false);
        });
      }
    },
    [onRegisterEmail, onSetAccountDetails],
  );
  useEffect(() => {
    return Analytics.purchaseConversionEvent;
  }, []);
  useEffect(() => {
    getSpecialtyList();
  }, [getSpecialtyList]);

  useEffect(() => {
    if (
      (errors.firstName && errors.firstName.type === 'required') ||
      errors.lastName ||
      (errors.password && errors.password.type === 'required') ||
      (errors.confirmPassword && errors.confirmPassword.type === 'required')
    ) {
      setErrorMsg('This field is required.');
    } else if (
      (errors.password && errors.password.type === 'minLength') ||
      (errors.confirmPassword && errors.confirmPassword.type === 'minLength')
    ) {
      setErrorMsg('The passwords should have at least 8 characters.');
    } else if (errors.confirmPassword && errors.confirmPassword.type === 'validate') {
      setErrorMsg('The passwords does not match.');
    } else if (errors.specialty_id && errors.specialty_id.type === 'required') {
      setErrorMsg('This field is required.');
    } else if (errors.profession_id && errors.profession_id.type === 'required') {
      setErrorMsg('This field is required.');
    } else if (errors.is_hcp && errors.is_hcp.type === 'required') {
      setErrorMsg('This field is required.');
    }
  }, [errors]);
  const renderSummaryCard = () => {
    return activeRegistrationStep === 'plan' ? (
      <Card className="small-card mt-3">
        <Card.Body className="ready-card">
          <div className="d-flex align-items-center" id="card-title">
            <TitleNumberDiv>
              <span>2</span>
            </TitleNumberDiv>
            <TitleText>Account Creation</TitleText>
          </div>
        </Card.Body>
      </Card>
    ) : (
      <Card className="small-card mt-3" onClick={navigate}>
        <Card.Body>
          <div className="d-flex align-items-center" id="card-title">
            <TitleNumberDiv>
              <TitleText>2</TitleText>
            </TitleNumberDiv>
            <TitleText>Account Creation</TitleText>
            <HorizontalDivider />
            <AccountDesc>{user?.email}</AccountDesc>
          </div>
        </Card.Body>
      </Card>
    );
  };
  return (
    <Wrapper>
      <Card className="mt-3" style={activeRegistrationStep === 'account' ? {} : {display: 'none'}}>
        <Card.Body>
          <div className="d-flex align-items-center mb-4" id="card-title">
            <TitleNumberDiv>
              <span>2</span>
            </TitleNumberDiv>
            <TitleText>Account Creation</TitleText>
          </div>
          <Form
            onSubmit={async e => {
              e.preventDefault();
              if (user?.is_registration_completed && selectedProduct !== 'Free Plan') {
                authStore.setActiveStep('payment');
                return;
              }
              // Handle case where recaptcha already passed from onChange event.
              if (recaptchaToken || bypassRecaptcha) {
                handleSubmit(CheckEmail)();
                return;
              }
              const token = await recaptchaRef.current.executeAsync();
              recaptchaRef.current.reset();
              if (token) {
                handleSubmit(CheckEmail)();
              }
              setRecaptchaToken(token);
            }}
          >
            <Row>
              <Form.Group as={Col} sm={12}>
                <FormLabel className="font-weight-bold">Your email</FormLabel>
                <FormControl
                  placeholder="Your email"
                  type="text"
                  name="email"
                  id="register-email-input"
                  className={`bg-transparent text-white ${watchEmailField && !errors.email && 'border-primary'} ${
                    errors.email && 'border-danger'
                  }`}
                  aria-describedby="basic-addon2"
                  ref={register({
                    required: 'This field is required.',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  defaultValue={user?.email}
                  readOnly={!!user?.is_registration_completed || !!user.email}
                />
                {errors.email && <small className="text-danger">{errors.email.message}&nbsp;</small>}
                <small id="registered-Email-Response-Text" className="text-danger emailregisteredEmailResponseText">
                  {registeredEmailResponseText}
                </small>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={6} controlId="account-first-name">
                <Form.Control
                  className={`bg-transparent text-white ${
                    watchFirstNameField && !errors.lastName && 'border-primary'
                  } ${errors.lastName && 'border-danger'}`}
                  type="text"
                  name="firstName"
                  placeholder="First name*"
                  defaultValue={user?.first_name}
                  ref={register({required: true, minLength: 1})}
                  readOnly={!!user?.is_registration_completed}
                />
              </Form.Group>
              <Form.Group as={Col} sm={6} controlId="account-last-name">
                <Form.Control
                  className={`bg-transparent text-white ${
                    watchLastNameField && !errors.firstName && 'border-primary'
                  } ${errors.lastName && 'border-danger'}`}
                  type="text"
                  placeholder="Last name*"
                  name="lastName"
                  defaultValue={user?.last_name}
                  ref={register({required: true})}
                  readOnly={!!user?.is_registration_completed}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={6}>
                <span>Are you a healthcare professional?*</span>
              </Form.Group>
              <Form.Group as={Col} sm={6} controlId="account-hcp">
                <Form.Check
                  type="radio"
                  label="Yes"
                  id="yes"
                  value="true"
                  name="is_hcp"
                  className="d-inline mr-5"
                  ref={register({required: true})}
                />
                <Form.Check
                  type="radio"
                  label="No"
                  id="no"
                  value="false"
                  name="is_hcp"
                  className="d-inline"
                  ref={register({required: true})}
                />
              </Form.Group>
            </Row>
            <Row>
              {/* <Form.Group as={Col} sm={6} controlId="account-licenseNumber">
                <Form.Control
                  className={`bg-transparent text-white ${
                    watchLiscenseField && !errors.licenseNumber && 'border-primary'
                  } ${errors.licenseNumber && 'border-danger'}`}
                  type="text"
                  name="licenseNumber"
                  placeholder="NPI"
                  defaultValue={user?.licenseNumber}
                  ref={register({required: false})}
                  readOnly={!!user?.is_registration_completed}
                />
              </Form.Group> */}
              <Form.Group as={Col} sm={6} controlId="account-profession">
                <Form.Control
                  defaultValue=""
                  as="select"
                  placeholder="Profession*"
                  name="profession_id"
                  className={`bg-transparent text-white ${profession_id && 'border-primary'} ${
                    errors.profession_id && 'border-danger'
                  }`}
                  onChange={e => setProfession(e.target.value)}
                  ref={register({required: true})}
                >
                  <option value="">Profession*</option>
                  {professionList?.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.display_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {parseInt(profession_id, 10) === parseInt(otherId, 10) && (
                <Form.Group as={Col} sm={6} controlId="other_profession">
                  <Form.Control
                    className={`bg-transparent text-white ${
                      parseInt(profession_id, 10) === parseInt(otherId, 10) &&
                      watchFieldProfessionOther &&
                      !errors.other_profession &&
                      'border-primary'
                    } ${errors.other_profession && 'border-danger'}`}
                    type="text"
                    name="extraData.other_profession"
                    placeholder="Profession"
                    ref={register({required: false})}
                    readOnly={!!user?.is_registration_completed}
                  />
                </Form.Group>
              )}
            </Row>
            <Row>
              <Form.Group as={Col} sm={6} controlId="account-speciality">
                <Dropdown>
                  <Form.Control
                    as="select"
                    defaultValue=""
                    name="specialty_id"
                    placeholder="Speciality*"
                    className={`bg-transparent text-white ${selectedSpeciality && 'border-primary'} ${
                      errors.specialty_id && 'border-danger'
                    }`}
                    onChange={e => {
                      setSelectedSpeciality(e.target.value);
                    }}
                    ref={register({required: watchIsHcpField === 'true'})}
                  >
                    <option value="" desabled>
                      Speciality{watchIsHcpField === 'true' ? '*' : ''}
                    </option>
                    {specialtyList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.display_name}
                      </option>
                    ))}
                  </Form.Control>
                </Dropdown>
              </Form.Group>
              <Form.Group as={Col} sm={6} controlId="account-subSpeciality">
                <Form.Control
                  className={`bg-transparent text-white ${watchHospitalField && !errors.hospital && 'border-primary'} ${
                    errors.hospital && 'border-danger'
                  }`}
                  type="text"
                  placeholder="Organization"
                  name="hospital"
                  defaultValue={user?.hospital}
                  ref={register({required: false})}
                  readOnly={!!user?.is_registration_completed}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={6} controlId="account-password">
                <Form.Control
                  className={`bg-transparent text-white ${watchPwField && !errors.password && 'border-primary'} ${
                    errors.password && 'border-danger'
                  }`}
                  type="password"
                  placeholder="Create password*"
                  name="password"
                  ref={register({required: true, minLength: 8})}
                  readOnly={!!user?.is_registration_completed}
                />
              </Form.Group>
              <Form.Group as={Col} sm={6} controlId="account-confirm-password">
                <Form.Control
                  className={`bg-transparent text-white ${
                    watchPwConfirmField && !errors.confirmPassword && 'border-primary'
                  } ${errors.confirmPassword && 'border-danger'}`}
                  type="password"
                  placeholder="Confirm password*"
                  name="confirmPassword"
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: value => value === watch('password'),
                  })}
                  readOnly={!!user?.is_registration_completed}
                />
              </Form.Group>
            </Row>
            <Row>
              {errorMsg ? (
                <div className="text-center w-100">
                  <Caption className="text-danger">{errorMsg}</Caption>
                </div>
              ) : null}
            </Row>
            <Row className="pt-5 pb-2 mt-3">
              <RegisterDiv>
                <ButtonXLarge
                  variant={
                    watchAllFields.email === '' ||
                    watchAllFields.firstName === '' ||
                    watchAllFields.lastName === '' ||
                    watchAllFields.password === '' ||
                    watchAllFields.speciality === '' ||
                    watchAllFields.confirmPassword === '' ||
                    loading
                      ? 'secondary'
                      : 'primary'
                  }
                  disabled={accountRegistering}
                  id="continue-to-training-page-button"
                  className="a_continueToTrainingPageButton mb-2"
                  type="submit"
                >
                  {loading ? 'Loading...' : 'Next'}
                </ButtonXLarge>
              </RegisterDiv>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      {activeRegistrationStep !== 'account' && renderSummaryCard()}
      {!bypassRecaptcha && (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          // onChange is called when user completes recaptcha successfully.
          onChange={onRecaptchaCompleted}
          size="invisible"
          ref={recaptchaRef}
          theme="dark"
        />
      )}
    </Wrapper>
  );
};

export default observer(Accountcreation);
