import {observer} from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';

export const Breadcrumb = styled.ul`
  list-style: none;
  font-weight: 600;
  padding-left: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  white-space: nowrap;
  width: 100%;
  display: flex;
  & > li:after {
    content: '${props => props.separator || '/'}';
    padding: 0 5px;
  }
  li:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Crumb = styled.li`
  color: #b8bbbe;
  display: inline-block;
  letter-spacing: -0.12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  a {
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  }
  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;

export const ActiveCrumb = styled.li`
  display: inline-block;
  color: ${({theme}) => (theme.body === '#FFF' ? '#000' : '#fff')};
  font-weight: bold;
  letter-spacing: -0.12px;
  font-size: 16px;
  line-height: 20px;

  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;

const Breadcrumbs = observer(({parentActivecrumb_name, parentActivecrumb_url, activeCrumb_name}) => (
  <Breadcrumb>
    <Crumb>
      <a href="/">Home</a>
    </Crumb>
    {parentActivecrumb_name ? <ActiveCrumb href={parentActivecrumb_url}>{parentActivecrumb_name}</ActiveCrumb> : null}
    <ActiveCrumb href="#">{activeCrumb_name}</ActiveCrumb>
  </Breadcrumb>
));

export default Breadcrumbs;
