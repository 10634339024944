// @flow
import axios from 'axios';
import instance from './axios';
import type {CreateSubscriptionBody, RetryInvoiceBody} from '../utils/types';

const subscriptionInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

/** ******STRIPE******* */
export const listProducts = () => subscriptionInstance.get('/subscription/product');
export const checkPromoCode = (code: string, plan: string, id: number) =>
  subscriptionInstance.get(`/subscription/promo-code?code=${code}&plan=${plan}&userId=${id}`);
export const createSubscription = (data: CreateSubscriptionBody) =>
  subscriptionInstance.post(`/subscription/create`, data);
export const retrySubscription = (data: RetryInvoiceBody) =>
  subscriptionInstance.post(`/subscription/retry-invoice`, data);
export const skipTrial = id => instance.post(`/subscription/skip-trial/${id}`);
