import React, {useEffect, useState} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import * as Api from '../../api';
import {useStore} from '../../store';

const VerifyEmail = () => {
  const location = useHistory();
  const [error, setError] = useState('');
  const {authStore} = useStore();
  const {setActiveStep, setUser, setRegistrationForm} = authStore;
  useEffect(() => {
    const token = window.location.search.slice(7);
    async function handleVerification() {
      try {
        const {user} = await Api.verifyEmail(token);
        setRegistrationForm({email: user.email});
        setUser(user);
        setActiveStep('about-you');
        location.push('/register');
      } catch (err) {
        setError('Invalid Token');
      }
    }
    handleVerification();
  }, [location, setActiveStep, setUser, setRegistrationForm]);

  /**
   * Render relevent elements on conditions
   */
  const renderElement = () => {
    if (!error) {
      return (
        <Spinner animation="grow" role="status" variant="secondary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    }
    return <h3 className="text-error text-light">{error}</h3>;
  };

  return (
    <Container fluid className="bg-dark1 min-vh-100 pt-5 d-flex align-items-center justify-content-center">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      {renderElement()}
    </Container>
  );
};

export default VerifyEmail;
