// @flow
import * as React from 'react';
import {Route, Redirect} from 'react-router-dom';
import LocalStorageService from './utils/LocalStorageService';
import {ADMINS} from './utils/types/auth';

/** checks and returns the token in the localstorage */
const isAuthenticated = () => {
  const user = LocalStorageService.getUser();
  return user?.id;
};

/** checks and returns the token in the localstorage */
const isRegistering = () => {
  const user = LocalStorageService.getUser();
  return !user?.is_registration_complete;
};

const isAdmin = () => {
  // return true; // Uncomment below lines after backend is completed.

  const user = LocalStorageService.getUser();
  return ADMINS.includes(user?.role);
};

type Props = {
  component: React.ComponentType<any>,
};

export const PrivateRoute = ({component: Component, ...rest}: Props) => {
  return isAuthenticated() ? (
    <Route {...rest} render={props => <Component {...rest} {...props} />} />
  ) : (
    <Redirect to={{pathname: '/login'}} />
  );
};

export const AdminRoute = ({component: Component, ...rest}: Props) => {
  return isAdmin() ? (
    <Route {...rest} render={props => <Component {...rest} {...props} />} />
  ) : (
    <Redirect to={{pathname: '/'}} />
  );
};

export const LoginRoute = ({component: Component, ...rest}: Props) => {
  return isAuthenticated() ? (
    <Redirect to={{pathname: '/'}} />
  ) : (
    <Route {...rest} render={props => <Component {...rest} {...props} />} />
  );
};

export const RegisterRoute = ({component: Component, ...rest}: Props) => {
  return !isRegistering() ? (
    <Redirect to={{pathname: '/'}} />
  ) : (
    <Route {...rest} render={props => <Component {...rest} {...props} />} />
  );
};
