import styled from 'styled-components';
import darkTheme from '../../utils/styles/darkTheme';
import {MenuRegular, Title2} from '../../utils/styles/typography';

export const Wrapper = styled.div`
  background: ${darkTheme.background};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px auto 20px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #ffffff;
  z-index: 5 !important;
  opacity: 0.95;
  position: relative;
  @media screen and (min-width: 768px) {
    height: 488px;
  }
  @media screen and (max-width: 768px) {
    padding: 100px 0px 50px;
    margin: auto;
    max-width: calc(100vw - 50px);
  }
`;

export const Title = styled(Title2)`
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  padding-bottom: 10px;
  @media screen and (min-width: 576px) {
    min-width: 130px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 15px;
  }
`;

export const MenuLink = styled(MenuRegular)`
  display: block;
  text-align: center;
  line-height: 14px;
  color: #ffffff;
  margin: auto auto 1rem auto;
  a {
    color: #ffffff;
  }
  @media screen and (min-width: 540px) {
    width: 130px;
  }
`;

export const Copyright = styled(MenuRegular)`
  color: #ffffff;
  text-align: center;
`;

export const Icon = styled.div`
  display: inline-block;
  border: 1px solid #ffffff;
  border-radius: 25px;
  width: 24px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  align-items: center;
  text-align: center;
  margin: 0px 7px;
  padding: 5px;
  .svg-inline--fa {
    color: #ffffff;
  }
  &:hover {
    cursor: pointer;
  }
`;
