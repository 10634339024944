import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {Main} from './elements/search';
import SearchHome from './components/searchHome';
import SearchVideos from './components/searchVideos';
import SearchExperts from './components/searchExperts';
// import SearchCourses from './components/searchCourses';
import SearchConferences from './components/searchConferences';
import SearchTranscript from './components/searchTranscript';

// Search page
const Search = () => {
  const {path} = useRouteMatch();
  return (
    <div id="search" className="d-flex overflow-hidden vh-100">
      <Sidebar />
      <Main className="w-100">
        <Navbar />
        <Switch>
          <Route exact path={path}>
            <SearchHome />
          </Route>
          <Route path={`${path}/conferences`}>
            <SearchConferences />
          </Route>
          {/* <Route path={`${path}/courses`}>
            <SearchCourses />
          </Route> */}
          <Route path={`${path}/experts`}>
            <SearchExperts />
          </Route>
          <Route path={`${path}/videos`}>
            <SearchVideos />
          </Route>
          <Route path={`${path}/transcript`}>
            <SearchTranscript />
          </Route>
        </Switch>
        <Footer />
      </Main>
    </div>
  );
};

export default Search;
