import {makeAutoObservable, runInAction, observable} from 'mobx';
import {
  getCmes,
  downloadCME,
  downloadCMEZip,
  deleteCme,
  getGoal,
  setGoal,
  updateGoal,
  setCmeAnswer,
  startCME,
  retakeCME,
  selectAllCompleted,
  clearGoal,
} from '../../api/cme';
import {getCmeQuestions} from '../../api/video';
import ErrorStore from '../error/errorStore';
import {amplitude} from '../../utils/Amplitude';

class CmeStore {
  errorStore: ErrorStore;

  question: Boolean = false;

  cmeInprogress: Array = [];

  cmeCompleted: Array = [];

  cmeRevoked: Array = [];

  progressSortBy: String = 'DESC';

  completedSortBy: String = 'DESC';

  revokedSortBy: String = 'DESC';

  cmeInprogressLoading: Boolean = true;

  cmeCompletedLoading: Boolean = true;

  cmeRevokedLoading: Boolean = true;

  revokedTotalCount: Number = 0;

  inProgressTotalCount: Number = 0;

  completedTotalCount: Number = 0;

  inProgressRecordCount: Number = 10;

  completedRecordCount: Number = 10;

  inProgressPage: Number = 1;

  completedPage: Number = 1;

  completedStartDate: String = null;

  completedEndDate: String = null;

  goal: Object = null;

  answers: Array = [];

  currentQuestion: Object = {};

  questions: Array = [];

  totalQuestion: Number = 0;

  cmeLoading: Boolean = false;

  currentAnswer: Object = {};

  videoId: Number = null;

  UserCme: Object = null;

  cmeDetails: Object = null;

  showComplete: Boolean = false;

  showRetake: Boolean = true;

  showQuestionBlock: Boolean = false;

  videoTitle: String = '';

  completedIds: Array = [];

  selectedIds: Array = [];

  totalCredit: Number = 0;

  cmeCount: Number = 0;

  downloadMessage: String = '';

  questionchanged: Boolean = false;

  setQuestionChanged = value => {
    this.questionchanged = value;
  };

  setshowQuestionBlock = value => {
    this.showQuestionBlock = value;
    amplitude.getInstance().logEvent('cme-started');
  };

  setshowComplete = value => {
    this.showComplete = value;
  };

  setshowRetake = value => {
    this.showRetake = value;
  };

  setvideoTitle = value => {
    this.videoTitle = value;
  };

  setAnswer = () => {
    this.answers = [...this.answers, this.currentAnswer];
  };

  setVideoId = async id => {
    this.videoId = id;
  };

  setCurrentAnswer = async (value: []) => {
    this.currentAnswer = {
      id: this.currentQuestion.id,
      question: this.currentQuestion.question,
      answer: value,
    };
  };

  resetQuestionAndAnswers = () => {
    this.showQuestionBlock = false;
    this.questions = [];
    this.answers = [];
    this.cmeDetails = null;
    this.totalQuestion = 0;
    this.UserCme = null;
  };

  getQuestions = async () => {
    this.cmeLoading = true;
    this.resetQuestionAndAnswers();
    try {
      const res = await getCmeQuestions(this.videoId);
      runInAction(() => {
        if (res.status) {
          this.cmeLoading = false;
        }
        this.questions =
          res.data.videoCME?.cme_questions?.questions || res.data.videoCME?.organization?.cme_questions?.questions;

        this.UserCme = res.data.userCME;
        this.answers = res.data.userCME?.answers || [];
        this.setshowComplete(this.UserCme?.completed_at);
        this.cmeDetails = res.data.videoCME;
        this.totalQuestion = this.questions?.length;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getQuestionById = () => {
    this.currentAnswer = this.answers.pop();
    this.currentQuestion = this.questions.find((ques: any) => ques.id === this.currentAnswer.id);
    this.setQuestionChanged(!this.questionchanged);
  };

  getNextQuestion = () => {
    this.questions?.forEach(question => {
      if (!this.answers.some((ans: any) => ans.id === question.id) && this.validateQuestion(question, this.answers)) {
        this.currentQuestion = question;
        this.setCurrentAnswer();
      }
    });
    this.setQuestionChanged(!this.questionchanged);
    return {};
  };

  validatePredicate = (predicate: any, answers: any): boolean => {
    const answer = answers.find((ans: any) => ans.id === predicate.question_id);
    if (predicate.answered && answer) {
      return true;
    }
    if (predicate.value && predicate.compare && answer) {
      switch (predicate.compare) {
        case 'lte':
          return answer.answer <= predicate.value;
        case 'gte':
          return answer.answer >= predicate.value;
        case 'gt':
          return answer.answer > predicate.value;
        case 'lt':
          return answer.answer < predicate.value;
        case 'eq':
          return answer.answer === predicate.value;
        default:
          return false;
      }
    }
    return false;
  };

  validateQuestion = (question: any, answers: any): boolean => {
    const {condition} = question;
    if (condition) {
      const {connector} = condition;
      switch (connector) {
        case 'or': {
          const orResult = condition.predicates.map((predicate: any) => {
            return this.validatePredicate(predicate, answers);
          });
          return orResult.reduce((combined: boolean, next: boolean) => combined || next, false);
        }
        case 'and': {
          const andResult = condition.predicates.map((predicate: any) => {
            return this.validatePredicate(predicate, answers);
          });
          return andResult.reduce((combined: boolean, next: boolean) => combined && next, true);
        }
        default:
          return this.validatePredicate(condition.predicates[0], answers);
      }
    } else {
      return true;
    }
  };

  showQuestions = option => {
    this.question = option;
  };

  saveAnswer = async () => {
    const answer = {
      answers: this.answers,
      completed: true,
    };
    if (this.UserCme?.id) {
      answer.id = this.UserCme.id;
    } else {
      answer.video_id = this.videoId;
    }
    try {
      const res = await setCmeAnswer(answer);
      if (res.data.status) {
        runInAction(() => {
          this.UserCme = res.data.userCME;
          if (this.cmeInprogress.length > 0) {
            const index = this.cmeInprogress.findIndex(cme => cme?.id === this.UserCme.id);

            // consider calling the inprogress and completed apis,
            // pros -get data from server side
            //  cons-network calls

            this.cmeCompleted.unshift(this.cmeInprogress[index]);
            this.cmeInprogress.splice(index, 1);
            this.inProgressTotalCount -= 1;
            this.completedTotalCount += 1;
          }
        });
        this.fetchGoal();
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  savePartialAnswer = async () => {
    const answer = {
      answers: this.answers,
      completed: false,
    };
    if (this.UserCme?.id) {
      answer.id = this.UserCme.id;
    } else {
      answer.video_id = this.videoId;
    }
    try {
      const res = await setCmeAnswer(answer);
      if (res.data.status) {
        runInAction(() => {
          this.UserCme = res.data.userCME;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
    this.showQuestions = true;
  };

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this, {goal: observable});
  }

  showQuestions = option => {
    this.question = option;
  };

  changeInProgressRecordCount = option => {
    this.inProgressRecordCount = option;
    this.inProgressPage = 1;
  };

  changeCompletedRecordCount = option => {
    this.completedRecordCount = option;
    this.completedPage = 1;
  };

  inProgressPageIncrement = () => {
    this.inProgressPage += 1;
  };

  inProgressPageDecrement = () => {
    this.inProgressPage -= 1;
  };

  completedPageIncrement = () => {
    this.completedPage += 1;
  };

  completedPageDecrement = () => {
    this.completedPage -= 1;
  };

  changeProgressSort = option => {
    this.progressSortBy = option;
    this.inProgressPage = 1;
  };

  changeRevokedSort = option => {
    this.revokedSortBy = option;
  };

  changeCompletedSort = option => {
    this.completedSortBy = option;
    this.completedPage = 1;
  };

  changeStartDate = option => {
    this.completedStartDate = option;
    this.completedPage = 1;
  };

  changeEndDate = option => {
    this.completedEndDate = option;
    this.completedPage = 1;
  };

  setInProgressObservable = (cmeList, cmeCount) => {
    if (window.innerWidth <= 768) {
      this.cmeInprogress = [...this.cmeInprogress, ...cmeList];
    } else {
      this.cmeInprogress = cmeList;
    }
    this.inProgressTotalCount = cmeCount;
  };

  setCompletedObservable = (cmeList, cmeCount) => {
    if (window.innerWidth <= 768) {
      this.cmeCompleted = [...this.cmeCompleted, ...cmeList];
    } else {
      this.cmeCompleted = cmeList;
    }
    this.completedTotalCount = cmeCount;
  };

  setRevokedObservable = (cmeList, cmeCount) => {
    this.cmeRevoked = cmeList;
    this.revokedTotalCount = cmeCount;
  };

  setInProgressLoading = value => {
    this.cmeInprogressLoading = value;
  };

  setCompletedLoading = value => {
    this.cmeCompletedLoading = value;
  };

  setRevokedLoading = value => {
    this.cmeRevokedLoading = value;
  };

  getInprogressCmes = async (type, sort) => {
    this.setInProgressLoading(true);
    try {
      const resp = await getCmes(type, sort, this.progressSortBy, this.inProgressPage, this.inProgressRecordCount);
      const {cmes, cmeCount} = resp.data;
      runInAction(() => {
        this.setInProgressObservable(cmes, cmeCount);
        this.setInProgressLoading(false);
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getCompletedCmes = async (type, sort) => {
    this.setCompletedLoading(true);
    try {
      const resp = await getCmes(
        type,
        sort,
        this.completedSortBy,
        this.completedPage,
        this.completedRecordCount,
        this.completedStartDate ? new Date(this.completedStartDate) : this.completedStartDate,
        this.completedEndDate ? new Date(this.completedEndDate) : this.completedEndDate,
      );
      const {cmes, cmeCount} = resp.data;
      runInAction(() => {
        this.setCompletedObservable(cmes, cmeCount);
        this.setCompletedLoading(false);
        this.resetDownloadMessage();
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deleteInProgressCme = async id => {
    try {
      const res = await deleteCme(id);
      const {status} = res.data;
      if (status) {
        this.getInprogressCmes('progress', 'created_at');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deleteCompletedCme = async id => {
    try {
      const res = await deleteCme(id);
      const {status} = res.data;
      if (status) {
        this.getCompletedCmes('completed', 'created_at');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch Goal */
  fetchGoal = async () => {
    try {
      const resp = await getGoal();
      runInAction(() => {
        this.goal = resp.data.goal;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create Goal */
  createGoal = async (creditGoal, startDate, endDate) => {
    try {
      const resp = await setGoal(creditGoal, startDate, endDate);
      runInAction(() => {
        this.goal = resp.data.goal;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Update Goal */
  updateGoal = async (creditGoal, startDate, endDate) => {
    try {
      const resp = await updateGoal(creditGoal, startDate, endDate, this.goal.id);
      runInAction(() => {
        const {goal} = resp.data;
        this.goal = goal;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  download = async (cmeIds: number[]) => {
    try {
      if (cmeIds?.length > 1) {
        await downloadCMEZip(cmeIds);
      } else if (cmeIds?.length === 1) {
        await downloadCME(cmeIds[0]);
      }
    } catch (error) {
      this.errorStore.setError(error);
      if (error?.response?.status === 400) {
        this.downloadMessage = "You can't download files when you are using trial";
      }
    }
  };

  getRevokedCmes = async sort => {
    this.setRevokedLoading(true);
    try {
      const resp = await getCmes('revoked', sort, this.revokedSortBy, 1, 200);
      const {cmes, cmeCount} = resp.data;
      runInAction(() => {
        this.setRevokedObservable(cmes, cmeCount);
        this.setRevokedLoading(false);
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  startCMEVideo = async ocmeVid => {
    try {
      const resp = await startCME(ocmeVid);
      if (resp.status === 201) {
        this.UserCme = resp.data?.cme;
        this.setInProgressObservable([resp.data?.cme], 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  retakeAssessment = async (id, ocmeVid) => {
    try {
      const resp = await retakeCME(id, ocmeVid);
      const {status} = resp.data;
      if (status) {
        runInAction(() => {
          const index = this.cmeRevoked.findIndex(cme => cme?.id === id);
          this.cmeInprogress.unshift(this.cmeRevoked[index]);
          this.cmeRevoked.splice(index, 1);
        });
        this.setshowRetake(false);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  deleteRevokedCme = async id => {
    try {
      const res = await deleteCme(id);
      const {status} = res.data;
      if (status) {
        const index = this.cmeRevoked.findIndex(cme => cme?.id === id);
        this.cmeRevoked.splice(index, 1);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setSelectedIds = ids => {
    this.selectedIds = ids;
  };

  setCompletedIds = ids => {
    this.completedIds = ids;
    this.setSelectedIds(ids);
  };

  setCredit = credit => {
    this.totalCredit = credit;
  };

  setCMECount = count => {
    this.cmeCount = count;
  };

  selectAllCME = async (start, end) => {
    try {
      const res = await selectAllCompleted(start, end);
      const {status} = res.data;
      if (status) {
        this.setCredit(res.data.total_credits);
        this.setCompletedIds(res.data.cmeids);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  clearGoal = async () => {
    try {
      const res = await clearGoal();
      const {status} = res.data;
      if (status) {
        runInAction(() => {
          this.goal = null;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  scrollInProgress = () => {
    if (
      !this.cmeInprogressLoading &&
      this.inProgressTotalCount >= this.inProgressPage * 10 &&
      window.innerWidth <= 768
    ) {
      this.inProgressPageIncrement();
    }
  };

  scrollCompleted = () => {
    if (!this.cmeCompletedLoading && this.completedTotalCount >= this.completedPage * 10 && window.innerWidth <= 768) {
      this.completedPageIncrement();
    }
  };

  resetDownloadMessage = () => {
    this.downloadMessage = '';
  };
}

export default CmeStore;
