import React, {useEffect, useState} from 'react';
import {Button, OverlayTrigger, ProgressBar, Row, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import Moment from 'react-moment';
import {observer, useLocalStore} from 'mobx-react-lite';
import {ThumbnailImg, ThumbnailDiv, VideoCardHolder, IconDiv, CreditDiv, ButtonGroup} from './style';
import {useStore} from '../../store';
import {secondsToTime} from '../../utils/helper';
import {ReactComponent as GiblibIcon} from '../../assets/icons/Giblib_icon.svg';
import {ReactComponent as VRIcon} from '../../assets/icons/360vr.svg';
import {ReactComponent as NewIcon} from '../../assets/icons/new.svg';
import CMEIcon from '../../assets/icons/CME.svg';
import CMEDefaultIcon from '../../assets/icons/CMEDefault.svg';
import CMEInProgressIcon from '../../assets/icons/CMEInProgress.svg';
import CMECompletedIcon from '../../assets/icons/CMECompleted.svg';
import CMERevokedIcon from '../../assets/icons/CMERevoked.svg';
import {Body2, Title2} from '../../utils/styles/typography';
import LocalStorageService from '../../utils/LocalStorageService';
import PlaylistPopover from '../PlaylistPopover';
import UpgradeModal from '../UpgradeModal';

const LandingVideoCard = observer(({item, goToVideoDetail, playlist, bookmarkLandingVideo}) => {
  const location = useHistory();
  const {accessStore, accountStore, conferenceLandingStore} = useStore();
  const {anonymousAccess} = accessStore;
  const {account} = accountStore;
  const {addToNewPlaylist, addToPlaylist} = conferenceLandingStore;

  const state = useLocalStore(() => ({
    isNew: false,
  }));

  const loggedInUser = LocalStorageService.getUser();
  const [lock, setLock] = useState(Boolean(!loggedInUser));
  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const [playlistUpdating, setUpdating] = useState(false);

  useEffect(() => {
    const createdVideo = new Date(item.release_date);
    const after30Video = new Date(createdVideo.getFullYear(), createdVideo.getMonth(), createdVideo.getDate() + 30);
    const today = new Date();
    if (after30Video > today) state.isNew = true;
  }, [state, item]);

  useEffect(() => {
    if (item.access_level === 'Free') setLock(false);
    else if (anonymousAccess) setLock(false);
  }, [loggedInUser, item, anonymousAccess]);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  const createAndAddToPlaylist = async data => {
    setUpdating(true);
    await addToNewPlaylist(item.id, data.listname);
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    await addToPlaylist(item.id, data);
    setUpdating(false);
  };

  const renderCMEIcon = () => {
    if (!loggedInUser) return <object data={CMEIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />;

    const cmes = item.organizationCME?.CMEs;
    const cmeStatus = cmes?.length && cmes[0]?.status;

    switch (cmeStatus) {
      case 'In Progress':
        return <object data={CMEInProgressIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />;
      case 'Completed':
        return <object data={CMECompletedIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />;
      case 'Revoked':
        return <object data={CMERevokedIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />;
      default:
        return <object data={CMEDefaultIcon} className="icon-cme" type="image/svg+xml" aria-label="cme" />;
    }
  };

  return (
    <VideoCardHolder className="py-3">
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <div className="d-flex">
        <ThumbnailDiv onClick={() => goToVideoDetail()}>
          <ThumbnailImg src={item.thumbnail} alt={item.title} lock={lock} />
          <ProgressBar now={item.watchProgress} />
        </ThumbnailDiv>
        <div className="w-100">
          <IconDiv className="desk d-flex align-items-center">
            <GiblibIcon alt="giblib" width={20} />
            {state.isNew ? <NewIcon alt="new" /> : null}
            {item.video_type?.includes('360vr') ? <VRIcon alt="vr" /> : null}
            {item?.organizationCME ? (
              <>
                {renderCMEIcon()}
                <CreditDiv status={item.organizationCME.CMEs[0]?.status}>
                  {item.organizationCME.credit_count} Credit
                  {item.organizationCME.credit_count > 1 ? 's' : ''}
                  {item.organizationCME.CMEs.length ? (
                    <span> &nbsp;|&nbsp; {item.organizationCME.CMEs[0].status}</span>
                  ) : null}
                </CreditDiv>
              </>
            ) : null}
          </IconDiv>
          <Title2 className="title mb-1" data-testid="go-to-detail" onClick={() => goToVideoDetail()}>
            {item.title}
          </Title2>

          {item?.organization?.name ? <Body2 className="detail mb-2">{item?.organization?.name}</Body2> : <Row />}
          <Body2 className="detail">
            {secondsToTime(item.duration)}
            {
              <span>
                {' '}
                | <Moment format="MM/DD/YY">{item.created_at}</Moment>
              </span>
            }
          </Body2>
        </div>
        <ButtonGroup className="text-right">
          {loggedInUser ? (
            [
              !playlistUpdating ? (
                <OverlayTrigger
                  trigger="click"
                  placement="auto-start"
                  overlay={props => (
                    <PlaylistPopover
                      props={props}
                      allPlaylist={playlist}
                      videoPlaylist={item.playlist}
                      onNew={createAndAddToPlaylist}
                      onExisting={updateExistingPlaylist}
                      userState={userState}
                      showUpgradeModal={isShowPlaylistUpgradeModal}
                      onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                    />
                  )}
                  rootClose
                >
                  <Button variant="basic" className="a_playlist" onClick={() => setShowPlaylistUpgradeModal(true)}>
                    <i className="fak fa-list-ul" />
                  </Button>
                </OverlayTrigger>
              ) : (
                <div className="p-0 mr-3 btn">
                  <Spinner animation="border" size="sm" variant="primary" />
                </div>
              ),
            ]
          ) : (
            <Button variant="basic" className="a_playlist pl-0 py-0 px-3" onClick={() => location.push('/login')}>
              <i className="fak fa-list-ul" />
            </Button>
          )}
          {account?.subscription?.name?.includes('Basic') ? (
            <Button variant="basic" className="a_bookmark" onClick={() => setShowBookmarkUpgradeModal(true)}>
              <i className="far fa-bookmark" />
            </Button>
          ) : (
            <>
              <Button
                variant="basic"
                className={`a_bookmark ${item.bookmark?.id || item.bookmark?.length ? 'd-none' : ''}`}
                onClick={event => bookmarkLandingVideo(event, item)}
              >
                <i className="far fa-bookmark" />
              </Button>
              <Button
                variant="basic"
                className={`a_bookmark ${item.bookmark?.id || item.bookmark?.length ? 'bookmark-fill' : 'd-none'}`}
                onClick={event => bookmarkLandingVideo(event, item)}
              >
                <i className="fas fa-bookmark" />
              </Button>
            </>
          )}
        </ButtonGroup>
      </div>
    </VideoCardHolder>
  );
});

export default LandingVideoCard;
