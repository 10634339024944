import axios from './axios';

/** calls the get conference details api
 * @return res.data if success or err.response.data on failure
 */
export const getConference = (id, params = null, cancelToken = null) =>
  axios.get(`/conference/${id}`, {params, cancelToken}).then(
    res => res,
    err => err?.response.data,
  );
/** calls the get conference videos api
 * @return res.data if success or err.response.data on failure
 */
export const getConferenceVideos = id => axios.get(`/conference/${id}/conference-videos`);

/** calls the get related conferences api
 * @return res.data if success or err.response.data on failure
 */
export const getRelatedConferences = id => axios.get(`/conference/${id}/related-conferences`);

export const getNextConferenceVideos = (id, order) => axios.get(`/conference/${id}/up-next-videos/${order}`);
