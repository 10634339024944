/* eslint-disable react/no-danger */
import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch, Redirect, Route, Switch, NavLink} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Row, Col, Container, Nav, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import AlertComponent from '../../components/AlertComponent';
import {useStore} from '../../store';
import {Main, OrganizationHeader} from './elements/styles';
import {CourseTab, NavItem} from '../CoursesLanding/elements/courseLanding';
import Section from '../../components/Section';
import SpecialSlider from '../../components/SpecialSlider';
import VideoTile from '../../components/VideoTile';
import CourseTile from '../../components/CourseTile';
import ExpertTile from '../../components/ExpertTile';
import UpgradeModal from '../../components/UpgradeModal';
import {amplitude} from '../../utils/Amplitude';
import PageBottom from '../../components/PageBottom';
import {ActiveSmallBtn, DefaultSmallBtn} from '../../components/FollowButtons';

const OrganizationDetail = observer(() => {
  const {organizationStore, videoStore, alertStore, accountStore} = useStore();
  const location = useHistory();
  const match = useRouteMatch();
  const orgId = match.params?.id;

  const {
    organization,
    loading,
    getOrganization,
    orgVideosLoading,
    organizationVideos,
    organizationCourses,
    organizationExperts,
    onFetchOrganizationVideos,
    onFetchOrganizationCourses,
    onFetchOrganizationExperts,
    resetExpert,
    followOrganization,
    unFollowOrganization,
    followExpert,
    unFollowExpert,
    followCourse,
    unFollowCourse,
    onCreateBookmark,
    onDeleteBookmark,
    playlist,
    getAllPlaylists,
  } = organizationStore;

  const {message, status, resetAlert} = alertStore;

  const {account} = accountStore;

  useEffect(() => {
    getOrganization(orgId);
    onFetchOrganizationVideos(orgId);
    onFetchOrganizationCourses(orgId);
    resetExpert();
    onFetchOrganizationExperts(orgId, 1);
    const eventProperties = {
      'organization name': `${organization?.name}`,
    };
    amplitude.getInstance().logEvent('organization-page-viewed', eventProperties);
    // eslint-disable-next-line
  }, [
    orgId,
    getOrganization,
    onFetchOrganizationVideos,
    onFetchOrganizationExperts,
    onFetchOrganizationCourses,
    resetExpert,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  useEffect(() => {
    videoStore.getAllPlaylists();
  }, [videoStore.getAllPlaylists, videoStore]);

  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);

  const [isSponsored, setSponsored] = useState(false);

  useEffect(() => {
    const sponsored = window.location.pathname.split('/');
    setSponsored(sponsored.includes('sponsored'));
  }, [setSponsored]);

  const organizationFollowHandler = () => {
    const user = localStorage.getItem('user');
    if (user) {
      if (!account?.subscription?.name?.includes('Basic')) {
        if (organization.is_following) {
          unFollowOrganization(organization.id);
        } else {
          followOrganization(organization.id);
        }
      } else setShowFollowUpgradeModal(true);
    } else {
      location.push('/login');
    }
  };

  const expertFollowHandler = expert => {
    const user = localStorage.getItem('user');
    if (user) {
      if (expert.is_following) {
        unFollowExpert(expert.id);
      } else {
        followExpert(expert.id);
      }
    } else {
      location.push('/login');
    }
  };

  const courseFollowHandler = course => {
    const user = localStorage.getItem('user');
    if (user) {
      if (course.is_following) {
        unFollowCourse(course.id);
      } else {
        followCourse(course.id);
      }
    } else {
      location.push('/login');
    }
  };

  const courseBookmarkHandler = selectedItem => {
    const user = localStorage.getItem('user');
    if (user) {
      if (selectedItem.bookmark?.length) {
        onDeleteBookmark(selectedItem);
      } else {
        onCreateBookmark(selectedItem);
      }
    } else {
      location.push('/login');
    }
  };

  return (
    <div id="organization-detail" className="d-flex flex-column flex-fill">
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <Helmet
        title={`${organization?.name}`}
        meta={[
          {
            name: 'description',
            content: organization?.description,
          },
          {
            property: 'og:title',
            content: organization?.name,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content:
              organization?.thumbnail ||
              'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content:
              organization?.thumbnail ||
              'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        {!loading ? (
          <Main className="w-100">
            {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
            <OrganizationHeader>
              <Row>
                <Col className="org-col">
                  <img src={organization?.thumbnail} alt={`${organization?.name}`} />
                  <div className="mx-3">
                    <div className="org-name">{organization?.name}</div>
                    <div className="org-specialty">Various Specialties</div>
                    {organization.is_following ? (
                      <ActiveSmallBtn className="a_followed" onClick={() => organizationFollowHandler()}>
                        <span className="following">
                          <i className="fas fa-check mr-2" />
                          Following
                        </span>
                        <span className="unfollow">
                          <i className="fas fa-times mr-2" />
                          Unfollow
                        </span>
                      </ActiveSmallBtn>
                    ) : (
                      <DefaultSmallBtn className="a_follow" onClick={() => organizationFollowHandler()}>
                        <i className="fas fa-plus mr-2" />
                        Follow
                      </DefaultSmallBtn>
                    )}
                  </div>
                </Col>
                <Col className="org-col2">
                  {organization?.description?.length ? (
                    <div
                      className="org-description"
                      dangerouslySetInnerHTML={{
                        __html: organization.description.replace(/\\n/g, ''),
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </OrganizationHeader>
            <CourseTab>
              <Container>
                <Nav id="organization-tab">
                  <NavItem className="nav-item">
                    <NavLink
                      className="nav-link a_orgVideos"
                      id="org-videos"
                      activeClassName="active"
                      to={`${match.url}/videos`}
                    >
                      Videos
                    </NavLink>
                  </NavItem>
                  {/* {organizationCourses.length ? (
                    <NavItem className="nav-item">
                      <NavLink
                        className="nav-link a_orgCourses"
                        id="org-courses"
                        activeClassName="active"
                        to={`${match.url}/courses`}
                      >
                        Courses
                      </NavLink>
                    </NavItem>
                  ) : null} */}
                  {organizationExperts.length ? (
                    <NavItem className="nav-item">
                      <NavLink
                        className="nav-link a_orgExperts"
                        id="org-experts"
                        activeClassName="active"
                        to={`${match.url}/experts`}
                      >
                        Experts
                      </NavLink>
                    </NavItem>
                  ) : null}
                </Nav>
              </Container>
            </CourseTab>
            <Container className="pt-3" id="course-landing-contents">
              <Switch>
                <Route
                  path={`${match.path}/videos`}
                  render={() => (
                    <>
                      {orgVideosLoading && (
                        <div className="m-auto d-flex justify-content-center">
                          <Spinner animation="grow" />
                        </div>
                      )}
                      {!orgVideosLoading && organizationVideos?.length > 0 ? (
                        <Section
                          title="Newly Arrived"
                          destination={
                            isSponsored
                              ? `/sponsored/${orgId}/${match.params.slug}/all-videos`
                              : `/organization/${orgId}/${match.params.slug}/all-videos`
                          }
                          viewAll
                        >
                          <Row className="mt-4">
                            {organizationVideos.map(v => (
                              <Col key={v.id} xs={6} md={6} lg={3}>
                                <VideoTile
                                  key={v.title}
                                  item={v}
                                  storeName="organizationStore"
                                  referrer="organization page"
                                />
                              </Col>
                            ))}
                          </Row>
                        </Section>
                      ) : null}
                    </>
                  )}
                />
                {organizationCourses.length ? (
                  <Route
                    exact
                    path={`${match.path}/courses`}
                    render={() => (
                      <Section
                        title="Newly Arrived"
                        viewAll
                        destination={
                          isSponsored
                            ? `/sponsored/${orgId}/${match.params.slug}/all-courses`
                            : `/organization/${orgId}/${match.params.slug}/all-courses`
                        }
                      >
                        <Row className="mt-4">
                          {organizationCourses.map(el => (
                            <Col key={el.id} xs={10} md={6} lg={4}>
                              <CourseTile
                                key={el.name}
                                item={el}
                                onBookmark={courseBookmarkHandler}
                                onFollow={courseFollowHandler}
                                courseStore="organizationStore"
                                storeName="organizationStore"
                                playlist={playlist}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Section>
                    )}
                  />
                ) : null}
                {organizationExperts.length ? (
                  <Route
                    exact
                    path={`${match.path}/experts`}
                    render={() => (
                      <Section
                        title="Experts"
                        destination={isSponsored ? `/sponsored/${orgId}/experts` : `/organization/${orgId}/experts`}
                        viewAll
                      >
                        <SpecialSlider>
                          {organizationExperts?.map(el => (
                            <ExpertTile
                              key={el.name}
                              expert={el}
                              onFollow={expertFollowHandler}
                              storeName="organizationStore"
                            />
                          ))}
                        </SpecialSlider>
                      </Section>
                    )}
                  />
                ) : null}
                <Redirect to={`${match.path}/videos`} />
              </Switch>
            </Container>
            <Footer />
          </Main>
        ) : (
          <div className="m-auto d-flex justify-content-center">
            <Spinner animation="grow" />
          </div>
        )}
      </div>
      <PageBottom />
    </div>
  );
});

export default OrganizationDetail;
