import styled from 'styled-components';

export default styled.div`
  overflow: hidden;
  height: 100%;

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
