import styled from 'styled-components';
import React from 'react';

import {Container} from 'react-bootstrap';

const Style = styled.div`
  background: ${({theme}) => (theme.name === 'light' ? '#F9F9F9' : '#181A21')};
  padding: 10px 0;
  display: none;
  @media screen and (max-width: 576px) {
    display: flex;
  }
  .title {
    font-size: 1.25em;
    font-weight: 500;
  }

  .mobile-select {
    background-color: transparent;
    color: white;
    font-size: 14px;
    line-height: 16px;
  }

  .container {
    display: none;
    @media screen and (max-width: 576px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export default ({children}: any) => {
  return (
    <Style>
      <Container>{children}</Container>
    </Style>
  );
};
