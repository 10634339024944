import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Container, Row, Col, Form} from 'react-bootstrap';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useStore} from '../../store';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import ExpertTile from '../../components/ExpertTile';
import {Main, Breadcrumbs, Crumb, PageTitle, SortSelect, FormLabel, ActiveCrumb} from './elements/styles';

const OrganizationExperts = observer(() => {
  const {organizationStore} = useStore();
  const match = useRouteMatch();
  const location = useHistory();
  const [sortValue, setSortValue] = useState('default');

  const {
    organization,
    getOrganization,
    onFetchOrganizationExperts,
    followExpert,
    unFollowExpert,
    sortExperts,
    experts,
    isLoading,
    resetExpert,
  } = organizationStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const {id} = match.params;
    getOrganization(id);
    onFetchOrganizationExperts(id, 1, 18);
    resetExpert();
  }, [getOrganization, onFetchOrganizationExperts, match.params, resetExpert]);

  useEffect(() => {
    sortExperts(sortValue);
  }, [sortExperts, sortValue]);

  const changeSort = option => {
    setSortValue(option.value);
  };

  const expertFollowHandler = expert => {
    const user = localStorage.getItem('user');
    if (user) {
      if (expert.follow.length) {
        unFollowExpert(expert.id);
      } else {
        followExpert(expert.id);
      }
    } else {
      location.push('/login');
    }
  };

  const [isSponsored, setSponsored] = useState(false);

  useEffect(() => {
    const sponsored = window.location.pathname.split('/');
    setSponsored(sponsored.includes('sponsored'));
  }, [setSponsored]);

  const gotoOrganization = () => {
    const {id} = match.params;
    if (isSponsored) {
      location.push(`/sponsored/${id}`);
    } else {
      location.push(`/organization/${id}`);
    }
  };

  const scroll = event => {
    const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && !isLoading) {
      const {id} = match.params;
      onFetchOrganizationExperts(id, Math.trunc(experts.length / 18) + 1, 18);
    }
  };

  return (
    <div className="d-flex flex-column flex-fill">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex overflow-hidden">
        <Sidebar />
        <Main role="main" className="w-100" onScroll={scroll}>
          <Container>
            <Breadcrumbs>
              <Crumb onClick={() => gotoOrganization()} data-testid="orgcrumb">
                {organization?.name}
              </Crumb>
              <ActiveCrumb href="#">Experts</ActiveCrumb>
            </Breadcrumbs>
            <div className="mt-1 d-flex align-items-center justify-content-between mb-2 mr-1">
              <PageTitle className="mb-0">{organization?.name} &lsquo;Experts&apos;</PageTitle>
              <Form>
                <Form.Group controlId="sort" className="mb-0 ">
                  <div className="d-flex">
                    <FormLabel style={{whiteSpace: 'nowrap'}} className="mt-3 mr-2">
                      Sort
                    </FormLabel>
                    <SortSelect data-testid="select" value={sortValue} onChange={e => changeSort(e.target)}>
                      <option value="default">Select</option>
                      <option value="alphainc">Name(A-Z)</option>
                      <option value="alphadec">Name(Z-A)</option>
                    </SortSelect>
                  </div>
                </Form.Group>
              </Form>
            </div>
            <Row className="pl-2 pr-3">
              {experts?.map(el => (
                <Col key={el.id} md={4} lg={3} xl={3} className="p-0">
                  <ExpertTile expert={el} onFollow={expertFollowHandler} storeName="organizationStore" />
                </Col>
              ))}
            </Row>
          </Container>
        </Main>
      </div>
    </div>
  );
});

export default OrganizationExperts;
