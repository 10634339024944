import React, {useEffect, useState} from 'react';
import {Overlay} from 'react-bootstrap';
import Wrapper from './style';

type Props = {
  triggerRef: React.MutableRefObject<any>,
  popoverHeight: number,
  popoverWidth: number,
  popoverOpen: boolean,
  popoverClassName: string,
  children: any,
};

const TileOverlay = (
  {triggerRef, popoverHeight, popoverWidth, popoverOpen, children, popoverClassName, ...props}: Props,
  ref,
) => {
  const [popoverLeft, setPopoverLeft] = useState(0);
  const [popoverBottom, setPopoverBottom] = useState(0);

  useEffect(() => {
    const setLeftandBottom = () => {
      const rect = triggerRef?.current?.getBoundingClientRect();
      if (rect) {
        const {innerWidth: windowWidth, innerHeight: windowHeight} = window;

        const topRemainingSpace = rect?.y;
        const bottomRemainingSpace = windowHeight - ((rect?.y || 0) + (rect?.height || 0));
        const leftRemainingSpace = rect?.x;
        const rightRemainingSpace = windowWidth - ((rect?.x || 0) + (rect?.width || 0));

        if (leftRemainingSpace < (popoverWidth * 3) / 4) {
          const leftPos = popoverWidth - rect.width;
          setPopoverLeft(leftPos);
        } else if (rightRemainingSpace < (popoverWidth * 3) / 4) {
          setPopoverLeft(0);
        } else {
          const leftPos = (popoverWidth - rect.width) / 2;
          setPopoverLeft(leftPos);
        }
        if (topRemainingSpace < popoverHeight / 3) {
          setPopoverBottom(-popoverHeight);
        } else if (bottomRemainingSpace < popoverHeight / 3) {
          setPopoverBottom(-rect.height);
        } else {
          const bottomPos = popoverHeight - (popoverHeight - rect.height) / 2;
          setPopoverBottom(-bottomPos);
        }
      }
    };
    if (popoverOpen) {
      setLeftandBottom();
    }
  }, [ref, popoverOpen, triggerRef, popoverWidth, popoverHeight]);

  return (
    <Overlay {...props} show={popoverOpen}>
      <Wrapper className={popoverClassName} popoverLeft={popoverLeft} popoverBottom={popoverBottom}>
        {children}
      </Wrapper>
    </Overlay>
  );
};

export default TileOverlay;
