import axios from './axios';

/**
 * calls the getAnnouncements api
 */

export const getAnnouncements = () => {
  return axios.get('/announcement').then(
    res => res?.data,
    err => err?.response.data,
  );
};

/**
 * calls setAnnouncement read
 */
export const setAnnouncementAsRead = id => {
  return axios.post('/announcement/dismiss', {announcement_id: id});
};
