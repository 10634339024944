/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow
import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import SpecialtiesList from './SpecialtyList';
import {amplitude} from '../../../utils/Amplitude';

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpecialtyBrowseItem = ({specialty, setActiveSpecialty, hasActiveSpecialty}: any) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const children = _.sortBy(
    (specialty.children || []).filter(s => s.type == 'common' && s.videoCount > 0),
    'name',
  );

  const onHoverLeave = () => {
    setShow(false);
  };

  return (
    <div key={specialty.id}>
      <ItemRow ref={target} onMouseLeave={onHoverLeave}>
        {!children.length ? (
          <span
            style={{minWidth: '334px'}}
            onClick={() => {
              setShow(false);
              const eventProperties = {
                'subspecialty name': `${specialty.display_name}`,
              };
              amplitude.getInstance().logEvent('browse-clicked', eventProperties);
            }}
          >
            <SpecialtiesList specialty={specialty} subSpecialty="true" />
          </span>
        ) : (
          <span
            style={{minWidth: '334px'}}
            onClick={() => {
              setShow(show);
              setActiveSpecialty(specialty);
              const eventProperties = {
                'specialty name': `${specialty.display_name}`,
              };
              amplitude.getInstance().logEvent('browse-clicked', eventProperties);
            }}
          >
            <SpecialtiesList specialty={specialty} subSpecialty={hasActiveSpecialty} />
          </span>
        )}
      </ItemRow>
    </div>
  );
};

export default SpecialtyBrowseItem;
