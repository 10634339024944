import React from 'react';
import {Carousel, Button} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {
  Logo,
  CaptionTitle,
  CarouselDescription,
  CarouselContainer,
  CarouselBg,
  CarouselThumbnail,
  Hero,
} from '../elements/courses';
import Thumbnail from '../elements/video-thumbnail.png';
import LogoImg from '../elements/logo.png';
import stringToURLSlug from '../../../utils/helper';

type Props = {
  course: Array,
};
// CourseCarousel
const CourseCarousel = ({course}: Props) => {
  const location = useHistory();

  const gotoCourseDetail = (id, title) => {
    const slug = stringToURLSlug(title);
    location.push(`/courses/${id}/${slug}`);
  };

  const setSpecialty = specialty => {
    let str = '';
    if (specialty?.length) {
      str = specialty[0].display_name;
      if (specialty.length >= 2) str += `, ${specialty[1].display_name}`;
      if (specialty.length > 2) str += ', and more';
    }
    return str;
  };

  return (
    <CarouselContainer>
      <Carousel indicators={false}>
        {course.map(v => (
          <Carousel.Item key={v.id}>
            <img
              className="d-block w-100 hero"
              style={{maxHeight: 400}}
              src={v.thumbnail_url || Thumbnail}
              alt={v.title}
            />
            <CarouselBg />
            <Carousel.Caption>
              <CaptionTitle className="text-left">{v.title}</CaptionTitle>
              <div className="d-flex mt-1 mb-2 mt-sm-2 mb-sm-4">
                <div>{v.organization ? <Logo src={v.organization?.thumbnail || LogoImg} /> : ''}</div>
                <CarouselDescription className="text-left">
                  <div className="organization">{setSpecialty(v.specialty)}</div>
                  <div className="descript">
                    {v.number_of_videos} Videos &bull;{' '}
                    {/* {(v.total_runtime?.hours || 0) +
                      ((v.total_runtime?.minutes || 0) / 60).toFixed(1)}{' '}
                    hrs */}
                    {v.is_cme ? <span> CME Credits Offered</span> : ''}
                  </div>
                </CarouselDescription>
              </div>
              <div className="d-flex">
                <Button className="a_WatchNow" variant="primary" onClick={() => gotoCourseDetail(v.id, v.title)}>
                  <i className="fas fa-play mr-2" />
                  Watch Now
                </Button>
              </div>
            </Carousel.Caption>
            <CarouselThumbnail>
              <Hero src={v.thumbnail_url} />
            </CarouselThumbnail>
          </Carousel.Item>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default CourseCarousel;
