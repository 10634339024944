import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Button, Row, Col, Form} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {InfoTitle, DescriptionDiv, UpdateModal} from '../elements/cme';
import cmeIcon from '../../../assets/icons/icon_CME.svg';
import {useStore} from '../../../store';

// CourseLanding page
const CmeInformation = observer(() => {
  /* Set Goal modal state */
  const [showGoalModal, setShowGoalModal] = useState(false);
  const [goalValue, setGoalValue] = useState(0);
  const handleGoalModalShow = () => setShowGoalModal(true);
  const handleGoalModalClose = () => setShowGoalModal(false);
  const {register, handleSubmit} = useForm();

  const {cmeStore, accountStore} = useStore();
  const {fetchGoal, goal, createGoal, updateGoal, clearGoal} = cmeStore;
  const {stats} = accountStore;
  const [startDateDefault, setStartDate] = useState(new Date());
  const [endDateDefault, setEndDate] = useState(new Date());

  const handleSubmitForm = data => {
    if (goal != null) {
      updateGoal(data.creditGoal, startDateDefault.getTime(), endDateDefault.getTime());
    } else {
      createGoal(data.creditGoal, startDateDefault.getTime(), endDateDefault.getTime());
    }
    setShowGoalModal(false);
  };

  const handleGoalModal = () => {
    handleGoalModalShow();
  };

  useEffect(() => {
    if (goal?.start_date) {
      setStartDate(new Date(goal?.start_date));
    }
    if (goal?.end_date) {
      setEndDate(new Date(goal?.end_date));
    }
  }, [goal]);

  useEffect(() => {
    fetchGoal();
  }, [fetchGoal]);

  return (
    <>
      <DescriptionDiv>
        <Row>
          <Col sm={7} xs={6}>
            <InfoTitle>My CME</InfoTitle>
            <h6>Track your progress and credits earned</h6>
          </Col>
          <Col className="text-right d-sm-flex justify-content-end pt-2 pl-2" sm={5} xs={6}>
            <div data-tut="cmetutorial">
              <Button variant="link" id="set-goal" className="a_setGoal py-0 pr-0" onClick={e => handleGoalModal(e)}>
                {goal != null ? 'Edit Goal' : 'Set Goal'}
              </Button>
              <h5>
                {goal != null ? (
                  <div>
                    <img src={cmeIcon} alt="CME" className="a_cmeIcon" />
                    {goal.credit_acquired || '0'}/{goal.credit_goal} Credits
                    <p>
                      <Moment format="MM/DD/YYYY">{goal.start_date}</Moment> -{' '}
                      <Moment format="MM/DD/YYYY">{goal.end_date}</Moment>
                    </p>
                  </div>
                ) : (
                  `${stats.total_credits_earned} Total Credits`
                )}
              </h5>
            </div>
          </Col>
        </Row>
      </DescriptionDiv>
      {/* Modal to set goal */}
      <UpdateModal show={showGoalModal} onHide={handleGoalModalClose} className="addGoalModal">
        <UpdateModal.Header closeButton>Set CME Credits Goal</UpdateModal.Header>
        <UpdateModal.Body>
          <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <Form.Row>
              <Form.Group as={Col} sm={4} xs={6}>
                <Form.Label>
                  <small>Start Date</small>
                </Form.Label>
                <DatePicker
                  selected={startDateDefault}
                  className="rounded-pill w-100"
                  name="startDate"
                  dateFormat="MM/dd/yyyy"
                  onChange={date => setStartDate(date)}
                />
              </Form.Group>
              <Form.Group as={Col} sm={4} xs={6}>
                <Form.Label>
                  <small>End Date</small>
                </Form.Label>
                <DatePicker
                  selected={endDateDefault}
                  className="rounded-pill w-100"
                  name="endDate"
                  dateFormat="MM/dd/yyyy"
                  onChange={date => setEndDate(date)}
                />
              </Form.Group>
              <Form.Group as={Col} sm={4} xs={6}>
                <Form.Label>
                  <small>Credit Quantity</small>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Add Goal"
                  name="creditGoal"
                  max="100"
                  defaultValue={goal?.credit_goal}
                  ref={register({required: true})}
                  className="rounded-pill"
                  onChange={e => setGoalValue(e.target.value)}
                />
              </Form.Group>
              <Col xs={12} className="save_button_container">
                <Button
                  type="reset"
                  onClick={() => {
                    clearGoal();
                    handleGoalModalClose();
                  }}
                  variant="basic"
                  id="clear_goal"
                  className="a_clear"
                >
                  Clear Goal
                </Button>
                <Button type="submit" variant={goalValue ? 'primary' : 'secondary'} id="save_goal" className="a_save">
                  Save
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>
    </>
  );
});

export default CmeInformation;
