import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Row, Col, Form, Container, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import AlertComponent from '../../components/AlertComponent';
import {
  Breadcrumbs,
  PageTitle,
  FormLabel,
  ActiveCrumb,
  Crumb,
  ChipText,
  CourseFilter,
  Main,
  SortSelect,
} from './Components/vr';
import VideoTile from '../../components/VideoTile';
import Dropdown from '../../components/Multiselect';
import {useStore} from '../../store';
import close from './Elements/close-chip.png';
import PageBottom from '../../components/PageBottom';

const VRPage = observer(() => {
  const {videoStore, alertStore} = useStore();

  const {
    onFetchAll360Videos,
    all360Videos,
    vrSpecialtiesList,
    selectedVRSpecialtiesList,
    selectedSpecialtyIds,
    setSelectedVRSpecialties,
    listVRSpecialties,
    onRemoveSpecialty,
    isLoading,
    sortBy,
    changeSort,
    onScroll360,
    getAllPlaylists,
  } = videoStore;

  const {message, status, resetAlert} = alertStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  useEffect(() => {
    listVRSpecialties();
  }, [listVRSpecialties]);

  useEffect(() => {
    onFetchAll360Videos(1, 16, selectedSpecialtyIds);
  }, [sortBy, selectedSpecialtyIds, onFetchAll360Videos]);

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedVRSpecialties(selectedIDs);
    }
  };

  const removeSpecialty = item => {
    onRemoveSpecialty(item.id);
  };

  useEffect(() => {
    window.addEventListener('scroll', async e => {
      const {scrollTop, clientHeight, scrollHeight} = e.target.scrollingElement;
      if (Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight)) {
        onScroll360();
      }
    });
  }, [onScroll360]);

  return (
    <div className="d-flex">
      <Helmet
        title="GIBLIB | 360VR"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB | 360VR',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Sidebar />
      <div className="w-100">
        <Navbar />
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
        <Main>
          <div>
            <div className="d-flex container">
              <Breadcrumbs>
                <Crumb href="/home">Home</Crumb>
                <ActiveCrumb href="#">360VR</ActiveCrumb>
              </Breadcrumbs>
            </div>

            <Container>
              <div className="mt-2">
                <Row>
                  <PageTitle>360VR</PageTitle>
                  <CourseFilter>
                    <div className="d-flex">
                      <Form.Group as={Col} controlId="filterSpecialty">
                        <div className="d-flex">
                          <FormLabel className="mt-3 mr-2">Filter</FormLabel>
                          <Dropdown
                            values={vrSpecialtiesList}
                            selectedIds={selectedSpecialtyIds}
                            id="filterSpecialty"
                            title="Specialty"
                            onSelectOptions={selectedIDs => selectOptions(selectedIDs, 1)}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} controlId="sort">
                        <div className="d-flex">
                          <FormLabel className="mt-3 mr-2">Sort</FormLabel>
                          <SortSelect onChange={e => changeSort(e.target.value)} value={sortBy}>
                            <option value="DESC">Newest</option>
                            <option value="ASC">Oldest</option>
                          </SortSelect>
                        </div>
                      </Form.Group>
                    </div>
                  </CourseFilter>
                </Row>
              </div>
              <div className="d-flex container mt-2 flex-wrap">
                {selectedVRSpecialtiesList.map(item => (
                  <ChipText>
                    {item.name}
                    <span>
                      <button type="button" className="bg-transparent border-0" onClick={() => removeSpecialty(item)}>
                        <img alt="close" src={close} />
                      </button>
                    </span>
                  </ChipText>
                ))}
              </div>
              <Row className="mt-4">
                {all360Videos?.map(el => (
                  <Col key={el.id} md={4} lg={3} xl={3}>
                    <VideoTile item={el} storeName="videoStore" storeVar="all360Videos" referrer="360 vr page" />
                  </Col>
                ))}
                {!all360Videos?.length && !isLoading && <p className="px-3 pt-5">No results</p>}
              </Row>
              {all360Videos?.length && isLoading ? (
                <div className="text-center my-3">
                  <Spinner animation="grow" className="mt-5" />
                </div>
              ) : (
                <></>
              )}
            </Container>
          </div>
          {!isLoading && <Footer />}
        </Main>
      </div>
      <PageBottom />
    </div>
  );
});

export default VRPage;
