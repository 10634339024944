import React from 'react';
import {observer} from 'mobx-react-lite';
import {Spinner} from 'react-bootstrap';
import Section from '../../../components/Section';
import SlickSlider from '../../../components/Slider';
import VideoTile from '../../../components/VideoTile';
import {useStore} from '../../../store';
import {Bottom} from '../elements/contentrequest';

const SuggestedVideos = observer(({content}) => {
  const {searchStore} = useStore();
  const {allVideoResults, isLoading} = searchStore;

  return (
    <Bottom>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="grow" />
        </div>
      ) : (
        <></>
      )}
      {!isLoading && allVideoResults?.length ? (
        <Section title="Suggested Videos" destination={`/search/videos?q=${content}`} viewAll>
          <SlickSlider card="video">
            {allVideoResults.map(v => (
              <VideoTile key={v.title} item={v} storeName="searchStore" />
            ))}
          </SlickSlider>
        </Section>
      ) : (
        <></>
      )}
    </Bottom>
  );
});

export default SuggestedVideos;
