import DataUpload from './components/DataUpload';
import UserTools from './components/UserTools';
import CMERevoke from './components/CMERevoke';
import Carousel from './components/Carousel';
import Dashboard from './components/Dashboard';
import UsersVideos from './components/UsersVideos';

export const adminRoutes = [
  {
    title: 'Data Upload',
    path: 'data-upload',
    component: DataUpload,
  },
  {
    title: 'User Tools',
    path: 'user-tools',
    component: UserTools,
  },
  {
    title: 'Carousel',
    path: 'carousel',
    component: Carousel,
  },
  {
    title: 'CME Revoke',
    path: 'cme-revoke',
    component: CMERevoke,
  },
  {
    title: 'User Management',
    path: 'user-management',
    component: Dashboard,
  },
  {
    title: 'Users Videos',
    path: 'users-videos',
    component: UsersVideos,
  },
];

export const orgMgrRoutes = [
  {
    title: 'CME Revoke',
    path: 'cme-revoke',
    component: CMERevoke,
  },
  {
    title: 'User Management',
    path: 'user-management',
    component: Dashboard,
  },
];

export const instnMgrRoutes = [
  {
    title: 'User Management',
    path: 'user-management',
    component: Dashboard,
  },
];
