import React from 'react';
import {ToggleButton, ToggleButtonGroup} from 'react-bootstrap';
import PropTypes from 'prop-types';
import SwitchContainer from './SwitchContainer';

const Switch = props => {
  const {id} = props;
  return (
    <SwitchContainer>
      <ToggleButtonGroup className="rounded" type="radio" {...props}>
        <ToggleButton id={`${id}-off`} className="rounded" value={false}>
          Off
        </ToggleButton>
        <ToggleButton id={`${id}-on`} className="rounded" value>
          On
        </ToggleButton>
      </ToggleButtonGroup>
    </SwitchContainer>
  );
};

export default Switch;

Switch.propTypes = {
  id: PropTypes.string.isRequired,
};
