import React, {useEffect} from 'react';
import {Form, FormLabel, Spinner} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {useLocation, useHistory} from 'react-router-dom';
import Section from '../../../components/Section';
// import CourseTile from '../../../components/CourseTile';
import ConferenceTile from '../../../components/ConferenceTile';
import SlickSlider from '../../../components/Slider';
import SpecialSlider from '../../../components/SpecialSlider';
import ExpertTile from '../../../components/ExpertTile';
import VideoTile from '../../../components/VideoTile';
import OrganizationTile from '../../../components/OrganizationTile';
import {SearchHomeDiv, SortSelect} from '../elements/search';
import {useStore} from '../../../store';
import AlertComponent from '../../../components/AlertComponent';

// Search page
const SearchHome = observer(() => {
  const params = useLocation();
  const text = new URLSearchParams(params.search).get('q');
  const {searchStore, alertStore, videoStore} = useStore();
  const location = useHistory();
  const {
    organizationResults,
    // courseResults,
    expertResults,
    videoResults,
    conferenceResults,
    transcriptResults,
    isResultsLoading,
    isResultsEmpty,
    contentSubtype,
    getAllSearchResults,
    setContentSubtype,
  } = searchStore;
  const {listContentTypes, contenttypeList} = videoStore;
  const id = 1;

  const {message, status, resetAlert} = alertStore;

  useEffect(() => {
    getAllSearchResults(text);
  }, [getAllSearchResults, text, contentSubtype]);

  useEffect(() => {
    listContentTypes();
  }, [listContentTypes]);

  const goToContentRequest = () => {
    location.push(`/content-request`);
  };

  return (
    <SearchHomeDiv>
      {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
      <div className="container px-4 d-flex justify-content-between align-items-center">
        <h5 className="mb-0 search-head">Search results for &apos;{text || ' '}&apos;</h5>
        <Form>
          <Form.Group className="m-0 mr-2">
            <FormLabel className="mt-3 mr-2">Filter</FormLabel>
            <SortSelect data-testid="select" onChange={e => setContentSubtype(e.target.value)}>
              <option value="">Content subtype</option>
              {contenttypeList.map(item => (
                <option value={item.name}>{item.name}</option>
              ))}
            </SortSelect>
          </Form.Group>
        </Form>
      </div>
      <p className="container py-2 px-4 a_content">
        Don&apos;t see what you&apos;re looking for? Submit a{' '}
        <span
          className="a_contentRequest"
          role="link"
          tabIndex="0"
          onClick={goToContentRequest}
          onKeyDown={goToContentRequest}
        >
          content request
        </span>
        .
      </p>
      {videoResults?.length ? (
        <Section title="Videos" key={id} destination={`/search/videos?q=${text}`} viewAll>
          <SlickSlider card="video">
            {videoResults.length ? (
              videoResults.map(v => <VideoTile key={v.title} item={v} storeName="searchStore" referrer="search page" />)
            ) : (
              <span className="px-2">No results</span>
            )}
          </SlickSlider>
        </Section>
      ) : (
        <></>
      )}
      {transcriptResults?.length ? (
        <Section
          title={`'${text || ' '}' mentioned in video`}
          key={id + 1}
          destination={`/search/transcript?q=${text}`}
          viewAll
        >
          <SlickSlider card="video">
            {transcriptResults.map(v =>
              v.video ? (
                <VideoTile
                  key={v.video.title}
                  item={v.video}
                  searchTerm={text}
                  storeName="searchStore"
                  storeVar="transcriptResults"
                  transcript={v.transcripts}
                  mentionCount={v.total_transcripts}
                  referrer="search page"
                />
              ) : (
                <></>
              ),
            )}
          </SlickSlider>
        </Section>
      ) : (
        <></>
      )}
      {conferenceResults?.length ? (
        <Section title="Conferences" destination={`/search/conferences?q=${text}`} viewAll>
          <SlickSlider card="conference">
            {conferenceResults.map(el => (
              <ConferenceTile key={el.title} item={el} type={el.id} onFollow={null} storeName="searchStore" />
            ))}
          </SlickSlider>
        </Section>
      ) : (
        <></>
      )}
      {/* {courseResults?.length ? (
        <Section title="Courses" destination={`/search/courses?q=${text}`} viewAll>
          <SlickSlider card="course">
            {courseResults.map(el => (
              <CourseTile
                key={el.title}
                item={el}
                type={el.id}
                onFollow={null}
                storeName="searchStore"
                courseStore="courseStore"
              />
            ))}
          </SlickSlider>
        </Section>
      ) : (
        <></>
      )} */}
      {organizationResults?.length ? (
        <Section title="Organizations" destination="" viewAll={false}>
          <SpecialSlider>
            {organizationResults.map(org => (
              <OrganizationTile key={org.name} org={org} type={org.id} storeName="searchStore" />
            ))}
          </SpecialSlider>
        </Section>
      ) : (
        <></>
      )}
      {expertResults?.length ? (
        <Section title="Experts" destination={`/search/experts?q=${text}`} viewAll>
          <SpecialSlider>
            {expertResults.map(el => (
              <ExpertTile key={el.name} expert={el} type={el.id} onFollow={null} storeName="searchStore" />
            ))}
          </SpecialSlider>
        </Section>
      ) : (
        <></>
      )}
      {isResultsLoading && (
        <div className="text-center my-3 mt-5 pt-5">
          <Spinner animation="grow" className="mt-5" />
        </div>
      )}
      {isResultsEmpty && !isResultsLoading && <p className="px-4 pt-5 container">No results</p>}
    </SearchHomeDiv>
  );
});

export default SearchHome;
