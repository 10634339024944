import {observer} from 'mobx-react-lite';
import React, {createRef, useEffect, useMemo, useState} from 'react';
import {Carousel} from 'react-bootstrap';
import {useOnScreen} from '../../../hooks/useOnScreen';

type Props = {
  hero_items: [],
  userTrackingAds: Function,
  // trackStatus: Boolean,
};

const Ads = observer(({hero_items, userTrackingAds}: Props) => {
  const clickAd = (link, id, index) => {
    userTrackingAds({
      ad_id: id,
      impression_type: 'click',
      impression_source: `/hero/${index}`,
      device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
    });
    if (link) {
      window.open(link);
    }
  };

  const [ids, setIds] = useState([]);

  const adRef = useMemo(
    () => hero_items.map(() => createRef()), // eslint-disable-next-line
    [hero_items.join(',')],
  );
  useOnScreen(adRef, ids, setIds);
  useEffect(() => {
    ids.map(el =>
      userTrackingAds({
        ad_id: el,
        impression_type: 'view',
        impression_source: `/hero/${hero_items.findIndex(item => parseInt(item.id, 10) === parseInt(el, 10))}`,
        device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
      }),
    );
    // eslint-disable-next-line
  }, [ids]);
  return (
    <Carousel indicators={false}>
      {hero_items.map((hero_item, index) => {
        const {ad_asset, link, id} = hero_item;

        return (
          <Carousel.Item>
            <img
              className="d-block w-100 hero"
              style={{maxHeight: 400, objectFit: 'cover'}}
              src={ad_asset}
              alt="ad"
              role="presentation"
              onClick={() => clickAd(link, id, index)}
              onKeyDown={() => clickAd(link, id, index)}
              ref={adRef[index]}
              id={id}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
});

export default Ads;
