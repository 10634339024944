import {observer} from 'mobx-react-lite';
import React from 'react';

import {UpdateModal} from '../elements/playlist';

type Props = {
  handleModalClose: Function,
  children: React.Node,
};
export default observer(({handleModalClose = () => {}, children}: Props) => {
  return (
    <UpdateModal show="true" onHide={handleModalClose} className="createPlaylistModal">
      <UpdateModal.Body>{children}</UpdateModal.Body>
    </UpdateModal>
  );
});
