import styled from 'styled-components';

export const IconDiv = styled.div`
  margin-bottom: 36px;
  svg {
    margin-right: 8px;
    path {
      height: 20px;
    }
  }
  .icon-cme {
    margin-right: 8px;
  }
`;

const handleColorType = status => {
  switch (status) {
    case 'Completed':
      return '#0dfff3';
    case 'Revoked':
      return '#ff5964';
    default:
      return '#ffffff';
  }
};

export const CreditDiv = styled.div`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px;
  color: ${({theme}) => (theme.name === 'light' ? '#2e3038' : props => handleColorType(props.status))};
`;

export const ThumbnailImg = styled.img`
  width: 248px;
  height: 140px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    width: 124px;
    height: 85px;
  }
`;

export const ThumbnailDiv = styled.div`
  margin-right: 20px;
  width: 245px;
  height: 95px;
  .progress {
    background: transparent;
    margin-top: -5px;
    height: 5px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .progress-bar {
    background: linear-gradient(270deg, #01b0ef 0%, #0074dc 100%);
  }
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    width: 124px;
    height: 85px;
  }
`;

export const VideoCardHolder = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  :hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 16px 0 18px 16px;
  }
  .row {
    margin-top: 10px;
  }
  #playlist_remove {
    display: none;
    color: #fff;
    font-size: 12px;
    @media screen and (max-width: 576px) {
      display: block;
    }
  }
  #playlist_remove::after {
    border-top: none;
    border-right: none;
  }
  .title {
    cursor: pointer;
    min-height: 40px;
    @media screen and (max-width: 576px) {
      font-size: 13px;
    }
  }
  .detail {
    @media screen and (max-width: 576px) {
      font-size: 10px;
      line-height: 11px;
    }
  }
`;

export const ButtonGroup = styled.div`
  width: 230px;

  .a_follow {
    font-size: 14px;
    padding: 4px 5px 2px 8px;
    line-height: 16px;
    letter-spacing: -0.09px;
    background: #0677d8;
    font-weight: 500;
  }

  .a_playlist,
  .a_bookmark {
    padding: 0;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-right: 15px;
    color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#dcdee1')};
  }
`;
