import styled from 'styled-components';

export const P = styled.p`
  /* Adapt the colors based on primary prop */
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  margin: 1em;
  padding: 0.25em 1em;
`;

export const Main = styled.main`
  background: ${({theme}) => theme.body};
  color: ${({theme}) => theme.text};
  font-family: Roboto;
  padding-top: 0px;
  padding-bottom: 10%;
  /* Adapt the colors based on primary prop */

  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
    padding-top: 54px;
  }

  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    height: 300px;
    background: #2e3038;
    margin: 0.5rem;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(228, 10%, 20%);
    }
    100% {
      background-color: hsl(228, 10%, 25%);
    }
  }
  .slick-arrow:hover {
    ~ .slick-dots {
      display: block !important;
    }
  }

  & .slick-dots {
    display: none !important;
    bottom: 0;
    width: auto;
    top: -22px;
    right: 10px;

    @media screen and (max-width: 576px) {
      top: -19px;
    }

    & li,
    & li button:before {
      width: 4px;
      height: 4px;
    }

    & li.slick-active button:before,
    & li button:before {
      color: ${({theme}) => theme.circle};
    }
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  @media screen and (min-width: 1200px) {
    padding-top: 54px;
    .content {
      box-sizing: content-box;
      position: absolute;
      left: 0%;
      top: -65%;
    }
  }
  .hero {
    @media screen and (max-width: 576px) {
      height: 211px;
    }
  }

  .carousel-caption {
    left: 60px;
    bottom: 63px;

    @media screen and (max-width: 768px) {
      bottom: 26px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 576px) {
      left: 28px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 32px;
    height: 32px;
    border: 1px solid;
    border-radius: 50px;
    top: 67px;
    box-shadow: 0px 0px 3px #000;

    @media screen and (max-width: 768px) {
      top: 42px;
    }

    @media screen and (max-width: 576px) {
      width: 15px;
      height: 15px;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 13px;
    height: 13px;

    @media screen and (max-width: 576px) {
      width: 6px;
      height: 6px;
    }
  }

  .carousel-control-prev {
    left: 60px;

    @media screen and (max-width: 576px) {
      left: 28px;
    }
  }

  .carousel-control-next {
    left: 108px;

    @media screen and (max-width: 576px) {
      left: 52px;
    }
  }

  .a_WatchNow {
    @media screen and (max-width: 576px) {
      font-size: 10px;
      letter-spacing: normal;
      padding: 4px 8px;

      .fa-play {
        width: 11px;
        height: 11px;
        vertical-align: middle;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

export const CarouselBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 400px;
  width: 670px;
  background: linear-gradient(90deg, rgba(24, 26, 33, 0.7) 0%, rgba(24, 26, 33, 0.9) 47.99%, rgba(24, 26, 33, 0) 100%);
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  letter-spacing: -0.8px;
  max-width: 55%;
  height: 84px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 1200px) {
    box-sizing: content-box;
    position: absolute;
    left: 0%;
    top: -175%;
  }
  .title-tip {
    display: none;
    max-width: 240px;
    white-space: normal !important;
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    .title-tip {
      letter-spacing: -0.13px;
      line-height: 18px;
      font-size: 13px;
      font-weight: 300;
      display: block;
      position: absolute;
      top: 5px;
      padding: 5px;
      -webkit-line-clamp: 2;
      font-size: 12px;
      color: #fff;
      background-color: #4e505a;
    }
  }
  @media screen and (max-width: 576px) {
    font-size: 16px;
    letter-spacing: -0.6px;
    line-height: 20px;
    height: 40px;
  }
`;

export const Specialty = styled.div`
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 24px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
    letter-spacing: -0.15px;
    line-height: 14px;
  }
`;

export const Physician = styled.div`
  font-size: 16px;
  letter-spacing: -0.14px;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 13px;
  }
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 15px;
  cursor: pointer;

  @media screen and (max-width: 576px) {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
`;

export const HomeSpinner = styled.div`
  .spinner-grow {
    width: 56px;
    height: 56px;
  }

  .home-spinner {
    margin: auto;
    width: 56px;
    height: 56px;
  }
`;

export const SpinnerText = styled.h6`
  height: 26px;
  width: 444px;
  color: ${({theme}) => theme.text};
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.31px;
  line-height: 26px;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
`;

export const PageTitle = styled.h5`
  font-weight: 600;
  margin-bottom: 0px;
  height: 26px;
  font-size: 20px;
  line-height: 26px;
  button {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: bold;
    padding-bottom: 3px;
  }
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 30px 8px 16px;
  border-radius: 30px;
  margin-top: 0.1em;
  width: 250px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 576px) {
    background-color: transparent;
    color: white;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    width: auto;
    padding-right: 30px;
  }
`;

export const FormLabel = styled.h5`
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
`;

export const ViewAllDiv = styled.div`
  padding-top: 3rem !important;
  width: 100% !important;
  margin: 1.5rem 0 !important;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;

  .a_filterHeadDiv {
    margin: 48px 0 15px;

    & .filter {
      font-size: 13px;
      line-height: 43px;
      margin-right: 8px;
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }

  .a_videoCrumb {
    padding-left: 6px;
  }

  .a_videoHead {
    padding-left: 6px;
    margin-right: 18px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    padding-top: 3rem !important;
    margin-left: 5rem !important;
    margin-right: 0 !important;
    width: auto !important;
  }

  @media screen and (max-width: 576px) {
    padding-top: 1rem !important;
    margin: 0px !important;

    .a_filterHeadDiv {
      & .title {
        flex-direction: column;

        & .a_videoHead {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-height: 26px;
        }
      }
    }

    .a_viewallSortBy {
      padding-left: 6px;
    }
  }
`;

export const ViewAllFilter = styled.div`
  .form-group {
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: auto;
    margin-right: 12px;
  }

  @media screen and (min-width: 576px) {
    .form-row {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 576px) {
    max-width: 100% !important;
    padding: 0px;
    margin-top: 10px;

    .form-row {
      margin-left: 6px;
    }
  }
`;

export const Line = styled.div`
  height: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  margin: 20px 0;
`;

export const InstituteRestBannerDiv = styled.div`
  display: ${props => (props.open ? 'flex' : 'none')} !important;
  position: relative;
  vertical-align: center;
  margin-bottom: 0px;
  top: 3.5rem;
  right: 0;
  left: 0;
  z-index: 200;
  align-items: center;
  height: 48px;
  background: linear-gradient(90deg, #008aff 0%, #9043ff 100%);
  @media screen and (max-width: 820px) {
    top: 0rem;
    height: auto;
    top: 0rem;
    padding: 29px 29px 29px 89px;
  }
  div {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
  }
  a {
    color: #ffffff;
    background: none;
    border: none;
    text-decoration: underline;
    @media screen and (max-width: 576px) {
      display: block;
    }
  }
  button {
    padding: 0px;
    background: none;
    border: none;
    margin-right: 29px;
    @media screen and (max-width: 576px) {
      margin-left: 60px;
      margin-right: 0px;
      position: relative;
      display: flex;
      top: -55px;
    }
  }
  img {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const CMEPromotionDiv = styled.div`
  position: absolute;
  top: -375px;
  padding: 24px;
  z-index: 300;
  background: rgba(24, 26, 33, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;
  @media screen and (max-width: 820px) {
    position: static;
    margin-top: 1rem;
  }

  .product_name {
    font-size: 30px;
    line-height: 28px;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  .total_price {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
  }
  .giftCard_desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
  }
  .a_promotion_start_link {
    padding: 5%;
    /* Primary/Black 2 */
    background: linear-gradient(90deg, #008aff 0%, #9043ff 100%);
    border-radius: 5px;
    border: none;
    font-weight: bold;
    font-size: 22px;
    line-height: 14px;
    color: #ffffff;
    :hover {
      text-decoration: none;
      background: #008aff;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;
