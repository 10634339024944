import React from 'react';
import {observer} from 'mobx-react-lite';
import {VideoUploadStyles} from '../elements/profile';
import UploadForm from './uploadForm';

const UploadVideos = observer(() => {
  return (
    <VideoUploadStyles>
      <UploadForm />
    </VideoUploadStyles>
  );
});

export default UploadVideos;
