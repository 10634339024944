import React, {useState, useEffect} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Button, Form, Tooltip, OverlayTrigger, Table} from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {useStore} from '../../../../store';
import {Wrap, Description, DescriptionContainer, Cast} from './style';
import cmeIcon from '../../../../assets/icons/icon_CME.svg';
import DownloadArrow from '../../../../assets/icons/download-arrow.svg';
import QuestionBlock from './questionBlock';
import {TitleDiv} from '../../elements/video';
import TBDButton from '../../../../components/TBDButton';
import {StartAssessmentButton, FinishAssessmentButton} from './AssessmentButton';
import ErrorModal from '../../../CME/Components/ErrorModal';
import {amplitude} from '../../../../utils/Amplitude';
import {Body1, Body2, SubTitle1} from '../../../../utils/styles/typography';

const CME = observer(({videoTitle, expanded, title}) => {
  // const location = useHistory();
  const {accountStore, videoStore, cmeStore} = useStore();
  const {account} = accountStore;
  const {video, watched} = videoStore;
  const {
    setVideoId,
    cmeDetails,
    UserCme,
    showComplete,
    setshowComplete,
    download,
    showQuestionBlock,
    setshowQuestionBlock,
    downloadMessage,
    resetDownloadMessage,
  } = cmeStore;

  const match = useRouteMatch();
  const location = useHistory();
  const [canDownload, setCanDownload] = useState(true);
  const [isTrialUser, setIsTrialUser] = useState(false);
  const [userStatus, setUserStatus] = useState(false);

  // const onClickClassic = () => {
  //   window.open(`https://app.giblib.com/video/${video.brightcove_id}`);
  // };

  const getRemainingDays = (date: Date) =>
    date ? Math.floor((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : 0;

  useEffect(() => {
    const {id} = match.params;
    if (id) {
      setVideoId(id);
    }
  }, [match.params, setVideoId]);

  useEffect(() => {
    if (UserCme?.completed_at) {
      setshowComplete(true);
    }
  }, [setshowComplete, UserCme?.completed_at]);

  useEffect(() => {
    if (
      (!account.subscription ||
        (account.subscription.status === 'cancelled' && !account.subscription.name) ||
        account.subscription.status === 'trialing') &&
      !account.access
    )
      setCanDownload(false);
    else setCanDownload(true);

    setIsTrialUser(account.subscription?.status === 'trialing');
  }, [account]);

  const onViewCredits = () => {
    location.push('/cme/completed');
    setshowQuestionBlock(false);
  };

  const [dowloadLoader, setDowloadLoader] = useState(false);
  const [isAssessmentDisabled, setAssessmentDisabled] = useState(false);

  useEffect(() => {
    if (
      !video?.organizationCME ||
      getRemainingDays(video?.organizationCME?.withdraw_at) < 0 ||
      getRemainingDays(video?.organizationCME?.cme_expire_at) < 0
    ) {
      setAssessmentDisabled(true);
    } else {
      setAssessmentDisabled(false);
    }
  }, [video]);

  useEffect(() => {
    if (
      account?.subscription?.name?.includes('Premium') ||
      (account?.access?.status === 'Active' && account?.access?.cme)
    ) {
      setUserStatus('active');
    } else if (!account.access) setUserStatus('subscriptionInactive');
    else setUserStatus('accessInactive');
  }, [account]);

  const onDownload = () => {
    if (!dowloadLoader && canDownload) {
      setDowloadLoader(true);
      download([UserCme?.id]).then(() => {
        setDowloadLoader(false);
        amplitude.getInstance().logEvent('cme-downloaded');
      });
    }
  };

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      CME Assessment will be available only after the video has been started.
    </Tooltip>
  );

  const onClickUpgrade = () => {
    location.push('/profile/subscription');
  };

  const renderAssessmentButton = () => {
    if (isAssessmentDisabled) {
      return null;
    }
    if (video?.organizationCME?.CMEs[0]?.status === 'In Progress') {
      return (
        <FinishAssessmentButton
          id="start-exam-desc-button"
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          onClick={() => setshowQuestionBlock(true)}
          disabled={isAssessmentDisabled}
          watched={watched}
        />
      );
    }
    if (watched) {
      return (
        <StartAssessmentButton
          id="start-exam-desc-button"
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          onClick={() => setshowQuestionBlock(true)}
          disabled={isAssessmentDisabled}
          watched={watched}
        />
      );
    }
    return (
      <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}} overlay={renderTooltip}>
        <StartAssessmentButton
          id="start-exam-desc-button"
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          disabled={isAssessmentDisabled}
          watched={watched}
        />
      </OverlayTrigger>
    );
  };

  const handleModalClose = () => {
    resetDownloadMessage();
  };

  useEffect(() => {
    return () => resetDownloadMessage();
  }, [resetDownloadMessage]);

  return (
    <Wrap className={classNames({expanded})}>
      {userStatus === 'active' ? (
        // Beta CME page
        <div className="cme-content-container">
          {showComplete ? (
            <Form>
              {title !== '' ? <TitleDiv className="py-2 m-0">{title}</TitleDiv> : ''}
              <h5 className="a_title">CME Assessment</h5>
              <div className="question-block">
                <Button className="a_completed" type="button">
                  <span>CME Completed</span>
                  <i className="far fa-check-circle" aria-hidden="true" />
                </Button>
                <div className="d-flex">
                  <img src={cmeIcon} alt="CME" className="a_cmeIconB" />
                  <div>
                    <h6 className="credits_count">
                      {cmeDetails?.credit_count}/{cmeDetails?.credit_count}
                      &nbsp;Credits
                    </h6>
                    <p>
                      <i>AMA PRA Category 1 Credits</i>™
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-4">
                <Button
                  type="button"
                  className="a_download"
                  onClick={() => onDownload()}
                  disabled={dowloadLoader || !UserCme?.id}
                >
                  <TBDButton
                    text="Not available during trial"
                    subText="You can download your CME certificate after your trial"
                    isShow={!canDownload}
                    isTrial={isTrialUser}
                  >
                    <img src={DownloadArrow} className="icon-download" alt="download" />
                    <span className="video-download">{dowloadLoader ? 'Downloading...' : 'Download CME'}</span>
                  </TBDButton>
                </Button>
                <Button type="button" className="a_view_credit" onClick={() => onViewCredits()}>
                  View Credits
                </Button>
              </div>
            </Form>
          ) : (
            ''
          )}
          {!showQuestionBlock && !showComplete ? (
            <div className="cme-disclosure">
              <h5>Earn {cmeDetails?.credit_count} CME Credits</h5>
              <Description>
                Complete the assessment in order to receive
                <i>AMA PRA Category 1 Credits</i>™
              </Description>
              {renderAssessmentButton()}
              <DescriptionContainer>
                {video?.organization?.name === 'Mayo Clinic' ? (
                  <>
                    <h6>{video.organization?.cme_description?.title1}</h6>
                    <p>{video?.organization?.cme_description?.description1}</p>
                    <h6>{video?.organization?.cme_description?.title2}</h6>
                    <p>{video?.organization?.cme_description?.description2}</p>
                    <h6>{video?.organization?.cme_description?.title3}</h6>
                    <img
                      src={video?.organization?.cme_description?.image1}
                      alt="jointly accredited provider"
                      className="image"
                    />
                    <p>{video?.organization?.cme_description?.description3}</p>
                    <h6>{video?.organization?.cme_description?.title4}</h6>
                    <p className="subheader">{video?.organization?.cme_description?.header1}</p>
                    <p>
                      {video?.organization?.cme_description?.text1}
                      {video.organizationCME?.credit_count}
                      &nbsp;
                      <span className="credit">{video?.organization?.cme_description?.text2}</span>
                      {video?.organization?.cme_description?.text3}
                    </p>
                    <p className="subheader">{video?.organization?.cme_description?.header2}</p>
                    <p>{video?.organization?.cme_description?.description5}</p>
                    <p className="subheader">{video?.organization?.cme_description?.title5}</p>
                    <p>{video?.organization?.cme_description?.description6}</p>
                    {video.surgicalmedical === 'medical' ? (
                      <p className="disclosure">
                        {video?.organization?.cme_description?.medical ? (
                          <a href={video.organization.cme_description?.medical}>
                            {video?.organization?.cme_description?.title6}
                          </a>
                        ) : null}
                      </p>
                    ) : (
                      <p className="disclosure">
                        {video?.organization?.cme_description?.surgical ? (
                          <a href={video.organization.cme_description?.surgical}>
                            {video?.organization?.cme_description?.title6}
                          </a>
                        ) : null}
                      </p>
                    )}
                    <p className="subheader">Release and Expiration Dates</p>
                    {video?.release_date && (
                      <p className="date">
                        Release Date:&nbsp;
                        <Moment format="MM/DD/YYYY">{video?.release_date}</Moment>
                      </p>
                    )}
                    {cmeDetails?.cme_expire_at && (
                      <p className="date">
                        Expiration Date:&nbsp;
                        <Moment format="MM/DD/YYYY">{cmeDetails?.cme_expire_at}</Moment>
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <SubTitle1 className="subtitle">{video.organizationCME?.cme_description?.title1}</SubTitle1>
                    <Body1>{video?.organizationCME?.cme_description?.description1}</Body1>
                    <Body1>
                      {video?.organizationCME?.learning_objectives?.map(item => (
                        <li className="learning-objectives">{item}</li>
                      ))}
                    </Body1>
                    <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title2}</SubTitle1>
                    {video?.experts?.map(item => (
                      <Cast>{item.name}</Cast>
                    ))}
                    <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title3}</SubTitle1>
                    <Body1>
                      {video.specialties
                        ?.filter(sp => sp.level === 'specialty')
                        ?.map((s, i) => (
                          <>{i !== 0 ? `, ${s.display_name}` : s.display_name}</>
                        ))}
                    </Body1>
                    <Body1>{video?.organizationCME?.cme_description?.description3}</Body1>
                    <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title4}</SubTitle1>
                    <img
                      src={video?.organizationCME?.cme_description?.image2}
                      alt="GIBLIB CME"
                      className="accreditation"
                    />
                    <Body1 className="description">{video?.organizationCME?.cme_description?.description4}</Body1>
                    <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title5}</SubTitle1>
                    <Body1>
                      {video?.organizationCME?.cme_description?.text1}
                      {cmeDetails?.credit_count}
                      <span className="credit">{video?.organizationCME?.cme_description?.text2}</span>
                      {video?.organizationCME?.cme_description?.text3}
                    </Body1>
                    <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title6}</SubTitle1>
                    <Body1>{video?.organizationCME?.cme_description?.description6}</Body1>
                    <Body1 className="description">{video?.organizationCME?.cme_description?.description7}</Body1>
                    <Body1 className="description">
                      Release Date: <Moment format="MM/DD/YYYY">{video?.release_date}</Moment>
                    </Body1>
                    <Body1 className="description">
                      Expiration Date: <Moment format="MM/DD/YYYY">{video?.organizationCME?.cme_expire_at}</Moment>
                    </Body1>
                    <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title7}</SubTitle1>
                    <Body1>
                      {video?.organizationCME?.cme_description?.description8}
                      <a
                        href={video?.organizationCME?.cme_description?.link1}
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        {' '}
                        {video?.organizationCME?.cme_description?.linkText1},
                      </a>
                      <a
                        href={video?.organizationCME?.cme_description?.link2}
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        {' '}
                        {video?.organizationCME?.cme_description?.linkText2},
                      </a>
                      <a
                        href={video?.organizationCME?.cme_description?.link3}
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        {' '}
                        {video?.organizationCME?.cme_description?.linkText3},
                      </a>
                      <a
                        href={video?.organizationCME?.cme_description?.link4}
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        {' '}
                        {video?.organizationCME?.cme_description?.linkText4}.
                      </a>
                    </Body1>
                    <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title9}</SubTitle1>
                    <Body2>
                      {cmeDetails?.bibliography?.map(item => (
                        <li className="bibliography">{item}</li>
                      ))}
                    </Body2>
                    <SubTitle1>Financial Disclosures</SubTitle1>
                    {cmeDetails?.cme_disclosure?.map(item => (
                      <Table borderless className="text-white">
                        <tr>
                          <td>
                            <Body1>
                              <span className="header">Name: </span>
                              {item.expert.name}
                            </Body1>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Body1>
                              <span className="header">Content Controlled as: </span>
                              {item.content_controlled_as}
                            </Body1>
                          </td>
                        </tr>
                        <tr>
                          <td className="last">
                            <Body1>
                              <span className="header">Financial Relationships with/what: </span>
                              {item.financial_relationships === null ? 'None' : `${item.financial_relationships}`}
                            </Body1>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Body1>
                              <span className="header">Mitigated: </span>
                              {item.mitigated === null ? 'N/A' : item.mitigated}
                            </Body1>
                          </td>
                        </tr>
                      </Table>
                    ))}
                  </>
                )}
              </DescriptionContainer>
            </div>
          ) : (
            ''
          )}
          {showQuestionBlock && !showComplete ? <QuestionBlock videoTitle={videoTitle} expanded={expanded} /> : ''}
        </div>
      ) : (
        userStatus === 'subscriptionInactive' && (
          <div className="d-flex flex-column align-items-center">
            <div className="a_iconDiv d-flex align-items-center justify-content-center">
              <img src={cmeIcon} alt="CME" className="a_cmeIcon" />
            </div>
            <p className="a_cmeUpgradeHeading">Earn {cmeDetails?.credit_count} CME Credits</p>
            <p className="a_cmeUpgradeText">Upgrade to a Premium Account to start earning CME credits.</p>
            <Button variant="primary" type="button" className="a_upgradeButton py-0" onClick={() => onClickUpgrade()}>
              Upgrade
            </Button>
          </div>
        )
      )}
      {userStatus === 'accessInactive' ? (
        <div className="d-flex flex-column align-items-center">
          <div className="a_iconDiv d-flex align-items-center justify-content-center">
            <img src={cmeIcon} alt="CME" className="a_cmeIcon" />
          </div>
          <p className="a_cmeUpgradeHeading">Not Available</p>
          <p className="a_cmeUpgradeText">
            This feature is not available with your current plan. Please ask your administrator to contact GIBLIB to
            potentially add this feature.
          </p>
          {/* <Button
            variant="primary"
            type="button"
            className="a_upgradeButton py-0"
            onClick={() => onClickUpgrade()}
          >
            Upgrade
          </Button> */}
        </div>
      ) : null}
      {downloadMessage?.length ? (
        <ErrorModal handleModalClose={handleModalClose} downloadMessage={downloadMessage} />
      ) : null}
    </Wrap>
  );
});

CME.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.string,
};
CME.defaultProps = {
  expanded: false,
  title: '',
};

export default CME;
