import styled from 'styled-components';

const size = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};
const StyledVideoTabContent = styled.div`
  height: auto;
  position: fixed;
  right: 0;
  bottom: 0;
  // left: 0;
  z-index: 123;
  width: 100%;
  background-color: ${({theme}) => theme.content_basic_bg};
  .tab-content {
    box-shadow: ${({theme}) => theme.content_boxshadow};
    height: calc(100vh - 3.5rem);
    background-color: ${({theme}) => theme.content_basic_bg};
    width: 100%;
    position: absolute;
    transition: top 0.8s ease-in-out;
    z-index: -1;
    left: 0;
    top: 0;
    margin-top: 56.5%;
    &.expanded {
      left: 0;
      top: calc(-100vh + 5.5rem);
      @media screen and (max-width: 992px) and (min-width: 767px) {
        left: 76px;
      }
    }
    .tab-pane {
      height: 100%;
    }
  }
  .nav {
    width: 100%;
    background-color: ${({theme}) => theme.video_right_panel};
    box-shadow: ${({theme}) => theme.content_boxshadow};
  }
  .nav-link {
    color: var(--secondary);
    &.active {
      color: var(--primary);
      background: ${({theme}) => theme.content_active_bg};
      box-shadow: inset 0px 2px #008aff;
    }
  }
  .a_cmeTabLink {
    padding-left: 15px;
  }
  .a_noteTabLink {
    padding-left: 18px;
  }
  .a_transcriptionTabLink {
    padding-left: 19px;
  }
  .new-tag {
    position: absolute;
    color: #000;
    font-size: 9px;
    background: #de0606;
    padding: 0px 2px;
    border-radius: 2px;
    right: 4px;
    top: 3px;
    text-transform: uppercase;
    line-height: 14px;
    font-weight: 500;
  }
  div#video-tab-menu-tabpane-transcript {
    position: relative;
  }
  .a_chatgptTabLink {
    position: relative;
  }
  .ads-container {
    padding: 0 1rem;
  }
  .ads-container img {
    width: 100%;
    height: auto;
    margin-top: 1rem;
    object-fit: cover;
  }
  .has-ads .expanded.tab-content {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${size.lg}) {
    height: calc(100vh - 3.5rem);
    right: 0;
    bottom: 0;
    z-index: 123;
    width: auto;
    .tab-content {
      height: 100%;
      width: 25rem;
      box-shadow: ${({theme}) => theme.content_boxshadow};
      position: absolute;
      transition: left 0.8s ease-in-out;
      z-index: -1;
      left: 100%;
      top: 0;
      margin-top: 0;
      &.expanded {
        left: -25rem;
        top: 0;
      }
      .tab-pane {
        height: 100%;
      }
    }
    .nav {
      height: calc(100vh - 3.175rem);
      box-shadow: ${({theme}) => theme.content_boxshadow};
    }
    .nav-link {
      &.active {
        border-top: none;
        box-shadow: inset 2px 0px #008aff;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    margin-left: 76px;
  }
`;

export default StyledVideoTabContent;
