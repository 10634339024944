import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {useStore} from '../../store';
import {Bottom, PaginationContainer} from './style';
import {Body1} from '../../utils/styles/typography';

const HistoryBottom = observer(({type}: String) => {
  const {bookmarkStore} = useStore();
  const [pageCount, setPageCount] = useState(false);
  const [page, setPage] = useState(false);
  const [pageTotalCount, setPageTotalCount] = useState(false);
  const [pagesArray, setPagesArray] = useState([]);
  const [pagesIndex, setPagesIndex] = useState(1);

  const {
    bookmarkCount,
    bookmarkPage,
    bookmarkTotalCount,
    historyCount,
    historyPage,
    historyTotalCount,
    likedVideosCount,
    likedVideosPage,
    likedVideosTotalCount,
    changeBookmarkCount,
    bookmarkPageIncrement,
    bookmarkPageDecrement,
    bookmarkPageTransfer,
    historyPageDecrement,
    historyPageIncrement,
    historyPageTransfer,
    changeHistoryCount,
    likedVideosPageDecrement,
    likedVideosPageIncrement,
    likedVideosPageTransfer,
    changeLikedVideosCount,
  } = bookmarkStore;

  useEffect(() => {
    if (type === 'Bookmarks') {
      setPageCount(bookmarkCount);
      setPage(bookmarkPage);
      setPageTotalCount(bookmarkTotalCount);
    } else if (type === 'History') {
      setPageCount(historyCount);
      setPage(historyPage);
      setPageTotalCount(historyTotalCount);
    } else {
      setPageCount(likedVideosCount);
      setPage(likedVideosPage);
      setPageTotalCount(likedVideosTotalCount);
    }
  }, [
    type,
    bookmarkCount,
    bookmarkPage,
    bookmarkTotalCount,
    historyCount,
    historyPage,
    historyTotalCount,
    likedVideosCount,
    likedVideosPage,
    likedVideosTotalCount,
  ]);

  useEffect(() => {
    if (pageCount && pageTotalCount && pageCount != 0) {
      setPagesIndex(Math.ceil(pageCount / pageTotalCount));
    }
  }, [pageCount, pageTotalCount]);

  useEffect(() => {
    const arr = [];
    for (let i = 0; i < pagesIndex; i += 1) {
      arr[i] = i + 1;
    }
    setPagesArray(arr);
  }, [pagesIndex]);

  const pageDecrement = () => {
    if (type === 'Bookmarks') bookmarkPageDecrement();
    else if (type === 'History') historyPageDecrement();
    else likedVideosPageDecrement();
  };

  const pageIncrement = () => {
    if (type === 'Bookmarks') bookmarkPageIncrement();
    else if (type === 'History') historyPageIncrement();
    else likedVideosPageIncrement();
  };

  const changePageCount = value => {
    if (type === 'Bookmarks') changeBookmarkCount(value);
    else if (type === 'History') changeHistoryCount(value);
    else changeLikedVideosCount(value);
  };

  const transferPage = pageIndex => {
    if (type === 'Bookmarks') bookmarkPageTransfer(pageIndex);
    else if (type === 'History') historyPageTransfer(pageIndex);
    else likedVideosPageTransfer(pageIndex);
  };

  return (
    <Bottom>
      {pageCount === 0 ? null : (
        <PaginationContainer>
          <Button
            onClick={pageDecrement}
            variant="basic"
            className="a_p_dec ml-2"
            disabled={page * pageTotalCount - (pageTotalCount - 1) === 1}
          >
            <i className="far fa-angle-left" />
            <Body1 className="font-weight-bold ml-2">Prev</Body1>
          </Button>
          {pagesArray.map(val => (
            <Button
              variant="basic"
              className={`a_p_index ${val === page && 'a_p_active'}`}
              onClick={() => transferPage(val)}
            >
              {val === page ? <Body1 className="font-weight-bold">{val}</Body1> : <Body1>{val}</Body1>}
            </Button>
          ))}
          <Button
            onClick={pageIncrement}
            variant="basic"
            className="a_p_inc"
            disabled={page * pageTotalCount >= pageCount}
          >
            <Body1 className="font-weight-bold mr-2">Next</Body1>
            <i className="far fa-angle-right" />
          </Button>
          <Dropdown>
            <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
              <i className="fas fa-ellipsis-v" />
            </Dropdown.Toggle>

            <Dropdown.Menu onChange={e => changePageCount(e.target.value)}>
              <Form.Check
                type="radio"
                label="10 per page"
                id="limit_10"
                value="10"
                name="count"
                checked={parseInt(pageTotalCount, 10) === 10}
              />
              <Form.Check
                type="radio"
                label="25 per page"
                id="limit_25"
                value="25"
                name="count"
                checked={parseInt(pageTotalCount, 10) === 25}
              />
              <Form.Check
                type="radio"
                label="50 per page"
                id="limit_50"
                value="50"
                name="count"
                checked={parseInt(pageTotalCount, 10) === 50}
              />
              <Form.Check
                type="radio"
                label="100 per page"
                id="limit_100"
                value="100"
                name="count"
                checked={parseInt(pageTotalCount, 10) === 100}
              />
            </Dropdown.Menu>
          </Dropdown>
        </PaginationContainer>
      )}
    </Bottom>
  );
});

export default HistoryBottom;
