// @flow
import React, {useState, useRef} from 'react';
import {Overlay, Nav} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Browse} from '../sidebar';
import {useStore} from '../../../store';
import OrganizationList from './organizationList';

const OrganizationBrowse = observer(() => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const {organizationStore} = useStore();
  const location = useHistory();
  const {listOrganizationsAlpha} = organizationStore;

  const onHoverLeave = () => {
    setShow(false);
  };

  const goToOrganizationPage = () => {
    location.push('/organization');
  };

  return (
    <div className={`a_openOrganization browseItem ${show ? 'active' : 'not-active'}`} onMouseLeave={onHoverLeave}>
      <Nav.Item
        id="open-organizations-browse-sidebar"
        data-testid="open-organizations-browse-sidebar"
        className="a_openOrganizationsBrowseButton"
        ref={target}
        onClick={() => setShow(!show)}
      >
        <span className="browseText">Organizations</span>
        <i className="fal fa-chevron-right" />
      </Nav.Item>
      <Overlay target={target.current} show={show} placement="right">
        {({placement, arrowProps, show: _show, popper, ...props}) => (
          <Browse {...props}>
            <div className="row browse flex-column">
              <Nav.Item id="Organizations">
                <div>
                  <div className="a_orgBrowseList">
                    <Nav.Link
                      className="a_orgBrowseItem"
                      onClick={() => goToOrganizationPage()}
                      onKeyDown={() => goToOrganizationPage()}
                    >
                      View All
                    </Nav.Link>
                  </div>
                </div>
                {listOrganizationsAlpha?.map(el => (
                  <div key={el.id}>
                    <OrganizationList org={el} storeName="organizationStore" />
                  </div>
                ))}
              </Nav.Item>
            </div>
          </Browse>
        )}
      </Overlay>
    </div>
  );
});

export default OrganizationBrowse;
