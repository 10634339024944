/* istanbul ignore next */
import React from 'react';
import {FinishAssessment, StartAssessment} from './style';

export const StartAssessmentButton = ({watched, ...rest}: any) => {
  return (
    <StartAssessment {...rest} watched={watched}>
      <span>
        Start Assessment <i className="fas fa-chevron-right" />{' '}
      </span>
    </StartAssessment>
  );
};

export const FinishAssessmentButton = ({watched, ...rest}: any) => {
  return (
    <FinishAssessment {...rest}>
      <span>
        Finish Assessment <i className="fas fa-chevron-right" />{' '}
      </span>
    </FinishAssessment>
  );
};

export default StartAssessmentButton;
