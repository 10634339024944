import {observer} from 'mobx-react-lite';
import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import _ from 'lodash';
import styled from 'styled-components';
import {useStore} from '../../../store';

type Props = {
  onFinish: Function,
  playlist: any,
};

const Style = styled.div`
  input[type='text'] {
    background: #65686e;
    border: none;
    color: #fff;
    font-size: 14px;
    height: 40px;
    font-weight: 500;
    letter-spacing: -0.09px;
    line-height: 24px;
  }
  input[type='text']::placeholder {
    color: #fff;
  }
  label {
    font-weight: bold;
    margin-bottom: 18px;
    letter-spacing: -0.16px;
    line-height: 20px;
  }
`;

const PlaylistForm = ({onFinish, playlist}: Props) => {
  const {playlistStore, accountStore, accessStore, errorStore} = useStore();
  const {account} = accountStore;
  const {managedAccesses} = accessStore;
  const {addToPlaylist, updatePlaylist} = playlistStore;

  const isUpdate = !!playlist;

  const {register, handleSubmit} = useForm();

  const [isInstitutional, setIsInstitutional] = useState(isUpdate && playlist.accesses.length);

  const onFormSubmit = data => {
    const access_ids = _.compact(_.flatten([data.access_ids])).map(i => parseInt(i, 10));
    const newData = {
      ...data,
      access_ids: isInstitutional ? access_ids : undefined,
    };

    if (!newData.playlistName) {
      errorStore.setUnhandledError({message: 'Please enter a name for the playlist'});
      return;
    }
    if (isInstitutional && !newData.access_ids.length) {
      errorStore.setUnhandledError({message: 'Need to select at least one cohort'});
      return;
    }
    if (isUpdate) {
      updatePlaylist(playlist.id, {name: newData.playlistName, access_ids: newData.access_ids});
    } else {
      addToPlaylist(null, newData.playlistName, newData.access_ids);
    }
    onFinish();
  };

  useEffect(() => {
    accessStore.fetchManaged();
  }, [accessStore]);

  const renderInstitutionalPlaylistSettings = () => {
    return (
      <>
        {isUpdate ? null : (
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Check
                type="checkbox"
                label="This is an institutional playlist"
                id="isInstitutionalPlaylist"
                checked={isInstitutional}
                onChange={e => {
                  setIsInstitutional(e.target.checked);
                }}
              />
            </Form.Group>
          </Form.Row>
        )}

        {isInstitutional ? (
          <>
            <Form.Label>Please select the cohorts for this playlist</Form.Label>
            {managedAccesses.map(access => (
              <Form.Check
                type="checkbox"
                label={access.access_name}
                id={access.id}
                name="access_ids"
                value={access.id}
                defaultChecked={isUpdate && playlist.accesses.find(a => a.id == access.id)}
                ref={register()}
              />
            ))}
          </>
        ) : null}
      </>
    );
  };

  return (
    <Style>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Row id="playlist-form">
          <Form.Group as={Col}>
            <Form.Label>{isUpdate ? 'Update' : 'Create'} playlist</Form.Label>
            <Form.Control
              type="text"
              placeholder="Playlist Name"
              name="playlistName"
              className="rounded-pill pl-3 py-2 step-field"
              defaultValue={playlist?.name}
              autoComplete="off"
              ref={register()}
            />
          </Form.Group>
        </Row>
        {account.role == 'institution_manager' ? renderInstitutionalPlaylistSettings() : null}

        <Form.Row>
          <Col className="w-100 d-flex flex-row-reverse">
            <Button type="submit" id="createPlaylistButton">
              {isUpdate ? 'Update' : 'Create'} Playlist
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </Style>
  );
};
export default observer(PlaylistForm);
