/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Container, Button, Form, Dropdown, Nav, Row} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../../components/Navbar';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';
import VideoCard from './VideoCard';
import VideoFilter from './VideoFilter';
import {
  Main,
  CatalogList,
  CourseFilter,
  ChipText,
  Header,
  PaginationContainer,
  NavItem,
  Tab,
  Link,
  FilterContainer,
} from '../elements/catalog';
import close from '../elements/close-chip.svg';
import {useStore} from '../../../store';
import AlertComponent from '../../../components/AlertComponent';
import Icon from '../elements/bars.svg';
import FilterIcon from '../elements/filter.png';
import ScreenLoader from '../../../components/ScreenLoader';
import PageBottom from '../../../components/PageBottom';
import {amplitude} from '../../../utils/Amplitude';

// Video Catalog
const VideoCatalog = observer(() => {
  const {videoStore, alertStore} = useStore();
  const {
    videosList,
    listVideos,
    listContentTypes,
    contenttypeList,
    isLoading,
    videoCount,
    changeCount,
    count,
    changeSort,
    sortBy,
    sortOrder,
    page,
    pageIncrement,
    pageDecrement,
    specialtiesList,
    organizationsList,
    selectedContenttypeList,
    selectedOrganizationList,
    selectedSpecialtiesList,
    selectedSubSpecialtiesList,
    listOrganizations,
    listSpecialties,
    onRemoveContenttype,
    onRemoveOrganization,
    onRemoveSpecialty,
    setSearch,
    searchText,
    resetSearch,
    playlist,
    getAllPlaylists,
    setSelectedSpecialties,
    setSelectedSubSpecialties,
    setSelectedOrganizations,
    setSelectedContenttype,
    setCME,
    setVR,
    scrollCatalog,
    changeItem,
    subSpecialtiesList,
    onRemoveSubSpecialty,
    isCME,
    isVR,
  } = videoStore;

  const [searchValue, setSearchValue] = useState(searchText);
  const {message, status, resetAlert} = alertStore;
  const [showFilter, setShowFilter] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [filterLoaded, setFilterLoaded] = useState(false);

  useEffect(() => {
    setSelectedOrganizations([], false);
    setSelectedSpecialties([], false);
    setSelectedSubSpecialties([], false);
    setSelectedContenttype([], false);
    setCME(false);
    setVR(false);
    resetSearch();
    amplitude.getInstance().logEvent('catalog-page-viewed');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading && page === 1) {
      setFilterLoaded(true);
    } else if (isLoading && page === 1) {
      setFilterLoaded(false);
    }
  }, [page, isLoading]);

  useEffect(() => {
    if (!playlist?.length) {
      getAllPlaylists();
    }
  }, [getAllPlaylists, playlist?.length]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const resizeWindow = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    const scrollEvent = e => {
      const {scrollTop, clientHeight, scrollHeight} = e.target.scrollingElement;
      if (Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight)) {
        scrollCatalog();
      }
    };
    if (windowSize <= 576) {
      window.addEventListener('scroll', e => scrollEvent(e));
      window.setTimeout(() => window.removeEventListener('scroll', e => scrollEvent(e)), 1000);
    }
  }, [scrollCatalog, windowSize]);

  useEffect(() => {
    listSpecialties();
    listOrganizations();
    listContentTypes();
  }, [listSpecialties, listOrganizations, listContentTypes]);

  useEffect(() => {
    listVideos({include: ['bookmark', 'playlist']}); // include bookmark and playlist
  }, [searchText, sortBy, sortOrder, count, listVideos]);

  const removeContenttype = item => {
    onRemoveContenttype(item.id, false);
  };

  const removeOrganization = item => {
    onRemoveOrganization(item.id, false);
  };

  const removeSpecialty = item => {
    onRemoveSpecialty(item.id, false);
  };

  const removeSubSpecialty = item => {
    onRemoveSubSpecialty(item.id, false);
  };

  const onSearch = event => {
    const value = event?.target?.value;
    setSearchValue(value);
    if (value) {
      setSearchValue(value);
      setShowCount(true);
    } else {
      resetSearch();
      if (
        selectedContenttypeList.length > 0 ||
        selectedOrganizationList.length > 0 ||
        selectedSpecialtiesList.length > 0 ||
        selectedSubSpecialtiesList.length > 0 ||
        isCME ||
        isVR
      ) {
        setShowCount(true);
      } else {
        setShowCount(false);
      }
    }
  };

  const onSubmit = () => {
    if (searchValue) {
      // location.push(`/catalog/videos?q=${searchValue}`);
      setSearch(searchValue);
    }
  };

  const searchKeyUpHandler = event => {
    if (searchValue && searchValue.length <= 200 && event.key === 'Enter') {
      onSubmit();
    }
  };

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedOrganizations(selectedIDs, false);
    } else if (type === 2) {
      setSelectedSpecialties(selectedIDs, false);
    } else if (type === 3) {
      setSelectedSubSpecialties(selectedIDs, false);
    } else {
      setSelectedContenttype(selectedIDs, false);
    }
  };

  const clearFunc = () => {
    selectOptions([], 0);
    selectOptions([], 1);
    selectOptions([], 2);
    selectOptions([], 3);
    setCME(false);
    setVR();
    resetSearch();
    setShowCount(false);
  };

  const setSort = option => {
    if (option === 'release_desc') {
      changeItem('release_date');
      changeSort('DESC');
    } else if (option === 'release_asc') {
      changeItem('release_date');
      changeSort('ASC');
    } else if (option === 'DESC') {
      changeItem('title');
      changeSort('DESC');
    } else if (option === 'ASC') {
      changeItem('title');
      changeSort('ASC');
    }
  };

  return (
    <div id="courses" className="d-flex flex-column flex-fill">
      <Helmet
        title="GIBLIB | Catalog"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB | Catalog',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <Main role="main">
          <Header>
            <Container>
              <h5>
                <img src={Icon} alt="icon" />
                Catalog
              </h5>
            </Container>
          </Header>
          {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
          <Container>
            <Tab>
              <Nav>
                <NavItem className="nav-item">
                  <Link className="nav-link a_showBookmarks" activeClassName="active" to="/catalog/videos">
                    Videos
                  </Link>
                </NavItem>
                {/* <NavItem className="nav-item">
                  <Link className="nav-link a_showBookmarks" activeClassName="active" to="/catalog/courses">
                    Courses
                  </Link>
                </NavItem> */}
                <NavItem className="nav-item">
                  <Link className="nav-link a_showBookmarks" activeClassName="active" to="/catalog/conferences">
                    Conferences
                  </Link>
                </NavItem>
              </Nav>
            </Tab>
            <Row>
              <CourseFilter className="col-sm-4">
                <h5>
                  <PaginationContainer className="sort-mobile">
                    <Dropdown>
                      <Dropdown.Toggle variant="basic" id="sort" className="a_sort">
                        Sort by
                      </Dropdown.Toggle>

                      <Dropdown.Menu onChange={e => setSort(e.target.value)}>
                        <Form.Check
                          type="radio"
                          label="Name(A-Z)"
                          id="asc"
                          value="ASC"
                          name="sort"
                          checked={sortBy === 'ASC' && sortOrder === 'title'}
                        />
                        <Form.Check
                          type="radio"
                          label="Name(Z-A)"
                          id="desc"
                          value="DESC"
                          name="sort"
                          checked={sortBy === 'DESC' && sortOrder === 'title'}
                        />
                        <Form.Check
                          type="radio"
                          label="Release date (Newest)"
                          id="release_desc"
                          value="release_desc"
                          name="sort"
                          checked={sortBy === 'DESC' && sortOrder === 'release_date'}
                        />
                        <Form.Check
                          type="radio"
                          label="Release date (Oldest)"
                          id="release_asc"
                          value="release_asc"
                          name="sort"
                          checked={sortBy === 'ASC' && sortOrder === 'release_date'}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </PaginationContainer>
                  <Button className={`filter-btn ${!showFilter ? 'show' : 'hide'}`} onClick={() => setShowFilter(true)}>
                    <img src={FilterIcon} alt="" />
                  </Button>
                  <div className="filter-img">
                    <img src={FilterIcon} alt="" />
                    Filter
                  </div>
                </h5>
                <FilterContainer className={showFilter ? 'show' : 'hide'}>
                  <Form.Row className="justify-content-between m-0 px-3 hide-mobile">
                    {showCount && filterLoaded ? (
                      <span>
                        {videoCount === 1 ? 'Result' : 'Results'} {videoCount}
                      </span>
                    ) : (
                      <span />
                    )}
                    <Button onClick={clearFunc} className="clear-btn" type="reset">
                      Reset
                    </Button>
                  </Form.Row>
                  <Form.Row className="justify-content-between m-0 px-3 show-mobile">
                    <h5>
                      <Button className="filter-btn" onClick={() => setShowFilter(false)}>
                        <img src={FilterIcon} alt="" />
                      </Button>
                      Filter
                    </h5>
                    <div>
                      <Button onClick={clearFunc} className="clear-btn" type="reset">
                        Reset
                      </Button>
                      <Button onClick={() => setShowFilter(false)} className="closeFilter">
                        <img alt="close" src={close} />
                      </Button>
                    </div>
                  </Form.Row>
                  <hr />
                  <div className="d-flex container flex-wrap mb-3">
                    {selectedSpecialtiesList?.map(item => (
                      <ChipText>
                        {item.name}
                        <span>
                          <button
                            type="button"
                            className="bg-transparent border-0"
                            onClick={() => removeSpecialty(item, false)}
                          >
                            <img alt="close" src={close} />
                          </button>
                        </span>
                      </ChipText>
                    ))}
                    {selectedSubSpecialtiesList?.map(item => (
                      <ChipText>
                        {item.name}
                        <span>
                          <button
                            type="button"
                            className="bg-transparent border-0"
                            onClick={() => removeSubSpecialty(item, false)}
                          >
                            <img alt="close" src={close} />
                          </button>
                        </span>
                      </ChipText>
                    ))}
                    {selectedOrganizationList.map(item => (
                      <ChipText>
                        {item.name}
                        <span>
                          <button
                            type="button"
                            className="bg-transparent border-0"
                            onClick={() => removeOrganization(item, false)}
                          >
                            <img alt="close" src={close} />
                          </button>
                        </span>
                      </ChipText>
                    ))}
                    {selectedContenttypeList?.map(item => (
                      <ChipText>
                        {item.name}
                        <span>
                          <button
                            type="button"
                            className="bg-transparent border-0"
                            onClick={() => removeContenttype(item, false)}
                          >
                            <img alt="close" src={close} />
                          </button>
                        </span>
                      </ChipText>
                    ))}
                  </div>
                  <Form.Row className="input-group form-inline m-0">
                    <div className="input-group-prepend">
                      <button className="btn btn-outline-secondary search-button" type="button" onClick={onSubmit}>
                        <i className="far fa-search" />
                      </button>
                    </div>
                    <input
                      id="search-catalog"
                      defaultValue={searchValue}
                      className="form-control search control-bottom"
                      type="search"
                      placeholder="Search Catalog"
                      aria-label="search"
                      name="search"
                      onChange={onSearch}
                      onKeyUp={searchKeyUpHandler}
                      autoComplete="off"
                    />
                  </Form.Row>
                  <VideoFilter
                    specialityFilter={specialtiesList}
                    organizationFilter={organizationsList}
                    contenttypeFilter={contenttypeList}
                    subSpecialityFilter={subSpecialtiesList}
                    setShowCount={setShowCount}
                  />
                </FilterContainer>
              </CourseFilter>
              <CatalogList className="col-sm-8">
                <Container id="catalog_container" className="pr-0">
                  <div className="pb-3 d-flex justify-content-end">
                    <PaginationContainer>
                      <Dropdown>
                        <Dropdown.Toggle variant="basic" id="sort" className="a_sort">
                          Sort by
                        </Dropdown.Toggle>

                        <Dropdown.Menu onChange={e => setSort(e.target.value)}>
                          <Form.Check
                            type="radio"
                            label="Name(A-Z)"
                            id="asc"
                            value="ASC"
                            name="sort"
                            checked={sortBy === 'ASC' && sortOrder === 'title'}
                          />
                          <Form.Check
                            type="radio"
                            label="Name(Z-A)"
                            id="desc"
                            value="DESC"
                            name="sort"
                            checked={sortBy === 'DESC' && sortOrder === 'title'}
                          />
                          <Form.Check
                            type="radio"
                            label="Release date (Newest)"
                            id="release_desc"
                            value="release_desc"
                            name="sort"
                            checked={sortBy === 'DESC' && sortOrder === 'release_date'}
                          />
                          <Form.Check
                            type="radio"
                            label="Release date (Oldest)"
                            id="release_asc"
                            value="release_asc"
                            name="sort"
                            checked={sortBy === 'ASC' && sortOrder === 'release_date'}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </PaginationContainer>
                    <PaginationContainer className="text-right">
                      {showCount && filterLoaded ? (
                        <span>
                          {videoCount === 0 ? <>0 </> : <>{page * count - (count - 1)} </>}-{' '}
                          {page * count > videoCount ? <>{videoCount} </> : <>{page * count} </>}
                          of {videoCount}
                        </span>
                      ) : (
                        <span>
                          {videoCount === 0 ? <>0 </> : <>{page * count - (count - 1)} </>}-{' '}
                          {page * count > videoCount ? <>{videoCount} </> : <>{page * count} </>}
                        </span>
                      )}
                      <Button
                        onClick={pageDecrement}
                        variant="basic"
                        className="a_p_dec"
                        disabled={page * count - (count - 1) === 1}
                      >
                        <i className="far fa-angle-left" />
                      </Button>
                      <Button
                        onClick={pageIncrement}
                        variant="basic"
                        className="a_p_inc"
                        disabled={page * count >= videoCount}
                      >
                        <i className="far fa-angle-right" />
                      </Button>
                      <Dropdown>
                        <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
                          <i className="fas fa-ellipsis-v" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu onChange={e => changeCount(e.target.value)}>
                          <Form.Check
                            type="radio"
                            label="10 per page"
                            id="limit_10"
                            value="10"
                            name="count"
                            checked={parseInt(count, 10) === 10}
                          />
                          <Form.Check
                            type="radio"
                            label="25 per page"
                            id="limit_25"
                            value="25"
                            name="count"
                            checked={parseInt(count, 10) === 25}
                          />
                          <Form.Check
                            type="radio"
                            label="50 per page"
                            id="limit_50"
                            value="50"
                            name="count"
                            checked={parseInt(count, 10) === 50}
                          />
                          <Form.Check
                            type="radio"
                            label="100 per page"
                            id="limit_100"
                            value="100"
                            name="count"
                            checked={parseInt(count, 10) === 100}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </PaginationContainer>
                  </div>
                  {!isLoading || window.innerWidth < 576 ? (
                    videosList.map(video => <VideoCard key={video.id} item={video} storeName="videoStore" />)
                  ) : (
                    <ScreenLoader containerHeight="calc(50vh)" />
                  )}
                  {isLoading && window.innerWidth < 576 && <ScreenLoader containerHeight="calc(50px)" />}
                </Container>
              </CatalogList>
            </Row>
          </Container>
          {!isLoading && <Footer />}
        </Main>
      </div>
      <PageBottom />
    </div>
  );
});

export default VideoCatalog;
