import React, {useState, useEffect, useRef} from 'react';
import {Container, Form, FormCheck, Button} from 'react-bootstrap';
import {Filter, ChipText, FilterContainer, FilterDropContainer} from './styles';
import Dropdown from '../Multiselect';
import FilterIcon from './elements/filter.png';
import close from './elements/close-chip.svg';

type Props = {
  count: Number,
  clearFunc: Function,
  contenttype: {
    selectedContenttypeList: Array,
    selectedContenttypes: Array,
    contenttypeFilter: Array,
    removeContenttype: Function,
  },
  specialties: {
    selectedSpecialtiesList: Array,
    selectedSpecialties: Array,
    specialityFilter: Array,
    removeSpecialty: Function,
  },
  subSpecialties: {
    selectedSubSpecialtiesList: Array,
    selectedSubSpecialties: Array,
    subSpecialityFilter: Array,
    removeSubSpecialty: Function,
  },
  organization: {
    selectedOrganizationList: Array,
    selectedOrganizations: Array,
    organizationFilter: Array,
    removeOrganization: Function,
  },
  search: {
    submitSearch: Function,
    searchText: String,
    resetSearch: Function,
  },
  cme: {
    isCme: Boolean,
    setCME: Function,
  },
  vr: {
    isVR: Boolean,
    setVR: Function,
  },
  selectOptions: Function,
  carouselType: String,
};

const FilterComponent = ({
  count,
  clearFunc,
  contenttype,
  search,
  specialties,
  organization,
  subSpecialties,
  cme,
  vr,
  selectOptions,
  carouselType,
}: Props) => {
  const [showFilter, setShowFilter] = useState(false);
  const [searchValue, setSearchValue] = useState(search?.searchText);
  const onSearch = event => {
    const value = event?.target?.value;
    setSearchValue(value);
    if (value) {
      setSearchValue(value);
    } else {
      search?.resetSearch();
    }
  };

  const onSubmit = () => {
    if (searchValue) {
      search?.submitSearch(searchValue);
    }
  };

  const searchKeyUpHandler = event => {
    event.preventDefault();
    if (searchValue && searchValue.length <= 200 && event.key === 'Enter') {
      onSubmit();
    }
  };

  /**
   * Hook that detects clicks outside of the passed ref
   */
  const useClickOutside = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowFilter(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef);

  return (
    <Filter>
      <h5>
        <Button className="filter-btn show" onClick={() => setShowFilter(!showFilter)}>
          <img src={FilterIcon} alt="" />
          Filter
        </Button>
      </h5>
      <FilterContainer className={showFilter ? 'show' : 'hide'} ref={wrapperRef}>
        <Form.Row className="justify-content-between m-0 px-3 hide-mobile">
          <span className="result-text">
            {count === 1 ? 'Result' : 'Results'} {count}
          </span>
          <Button onClick={clearFunc} className="clear-btn" type="reset">
            Reset
          </Button>
        </Form.Row>
        <Form.Row className="justify-content-between m-0 px-3 show-mobile">
          <Button onClick={clearFunc} className="clear-btn" type="reset">
            Reset
          </Button>
          <Button onClick={() => setShowFilter(false)} className="closeFilter">
            <img alt="close" src={close} />
          </Button>
        </Form.Row>
        <hr />
        <div className="d-flex container flex-wrap mb-3">
          {specialties?.selectedSpecialtiesList?.map(item => (
            <ChipText>
              {item.name}
              <span>
                <button
                  type="button"
                  className="bg-transparent border-0"
                  onClick={() => specialties.removeSpecialty(item)}
                >
                  <img alt="close" src={close} />
                </button>
              </span>
            </ChipText>
          ))}
          {subSpecialties?.selectedSubSpecialtiesList?.map(item => (
            <ChipText>
              {item.name}
              <span>
                <button
                  type="button"
                  className="bg-transparent border-0"
                  onClick={() => subSpecialties.removeSubSpecialty(item)}
                >
                  <img alt="close" src={close} />
                </button>
              </span>
            </ChipText>
          ))}
          {carouselType !== 'from-our-community' && (
            <>
              {organization?.selectedOrganizationList?.map(item => (
                <ChipText>
                  {item.name}
                  <span>
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => organization.removeOrganization(item)}
                    >
                      <img alt="close" src={close} />
                    </button>
                  </span>
                </ChipText>
              ))}
              {contenttype?.selectedContenttypeList?.map(item => (
                <ChipText>
                  {item.name}
                  <span>
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onClick={() => contenttype.removeContenttype(item, false)}
                    >
                      <img alt="close" src={close} />
                    </button>
                  </span>
                </ChipText>
              ))}
            </>
          )}
        </div>
        {search ? (
          <Form.Row className="input-group form-inline m-0">
            <div className="input-group-prepend">
              <button className="btn btn-outline-secondary search-button" type="button" onClick={onSubmit}>
                <i className="far fa-search" />
              </button>
            </div>
            <input
              id="search-catalog"
              defaultValue={searchValue}
              className="form-control search control-bottom"
              type="search"
              placeholder="Default"
              aria-label="search"
              name="search"
              onChange={onSearch}
              onKeyUp={searchKeyUpHandler}
              autoComplete="off"
            />
          </Form.Row>
        ) : (
          ''
        )}
        <Container>
          <FilterDropContainer>
            <Form>
              {specialties ? (
                <Form.Row className="d-inline-block w-100 m-0">
                  <Form.Group controlId="filterSpecialty">
                    <div>
                      <Dropdown
                        title="Specialty"
                        values={specialties.specialityFilter}
                        selectedIds={specialties.selectedSpecialties}
                        id="filterSpecialty"
                        onSelectOptions={selectedIDs => selectOptions(selectedIDs, 2)}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
              ) : null}
              {subSpecialties ? (
                <Form.Row className="d-inline-block w-100 m-0">
                  <Form.Group controlId="filterSubSpecialty">
                    <div>
                      <Dropdown
                        title="Subspecialty"
                        values={subSpecialties.subSpecialityFilter}
                        selectedIds={subSpecialties.selectedSubSpecialties}
                        id="filterSubSpecialty"
                        onSelectOptions={selectedIDs => selectOptions(selectedIDs, 3)}
                        disabled={subSpecialties.subSpecialityFilter.length === 0}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
              ) : null}
              {carouselType !== 'from-our-community' && (
                <>
                  {organization ? (
                    <Form.Row className="d-inline-block w-100 m-0">
                      <Form.Group controlId="filterOrganization">
                        <div>
                          <Dropdown
                            title="Organization"
                            values={organization.organizationFilter}
                            selectedIds={organization.selectedOrganizations}
                            id="filterOrganization"
                            onSelectOptions={selectedIDs => selectOptions(selectedIDs, 1)}
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                  ) : null}
                  {contenttype ? (
                    <Form.Row className="d-inline-block w-100 m-0">
                      <Form.Group controlId="filterContenttype" className="text-nobreak">
                        <div>
                          <Dropdown
                            title="Content Subtype"
                            values={contenttype.contenttypeFilter}
                            selectedIds={contenttype.selectedContenttypes}
                            id="filterContenttype"
                            onSelectOptions={selectedIDs => selectOptions(selectedIDs, 4)}
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                  ) : null}
                  {cme ? (
                    <Form.Row className="switchFilter d-inline-block w-100 mx-0">
                      <Form.Group controlId="cme">
                        <div className="d-flex justify-content-between">
                          <Form.Label className="mr-2">CME</Form.Label>
                          <FormCheck
                            id="cme"
                            type="switch"
                            onChange={e => cme.setCME(e.target.checked)}
                            checked={cme.isCme}
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                  ) : null}
                  {vr ? (
                    <Form.Row className="switchFilter d-inline-block w-100 mx-0">
                      <Form.Group controlId="vr">
                        <div className="d-flex justify-content-between">
                          <Form.Label className="mr-2">360VR</Form.Label>
                          <FormCheck
                            id="vr"
                            type="switch"
                            onChange={e => vr.setVR(e.target.checked)}
                            checked={vr.isVR}
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                  ) : null}
                </>
              )}

              <Form.Row>
                <Button className="a_filter-btn" onClick={() => setShowFilter(false)}>
                  Done
                </Button>
              </Form.Row>
            </Form>
          </FilterDropContainer>
        </Container>
      </FilterContainer>
    </Filter>
  );
};

export default FilterComponent;
