import React, {useState, useEffect} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Button, Form, Tooltip, OverlayTrigger} from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useStore} from '../../../../store';
import {Wrap, Description} from './style';
import certificateIcon from '../../../../assets/icons/graduation-cap-sharp-solid.svg';
import DownloadArrow from '../../../../assets/icons/download-arrow.svg';
import QuestionBlock from './questionBlock';
import {TitleDiv} from '../../elements/video';
import {StartAssessmentButton, FinishAssessmentButton} from './AssessmentButton';
import ErrorModal from '../../../CME/Components/ErrorModal';

const Certificate = observer(({videoTitle, expanded, title}) => {
  // const location = useHistory();
  const {accountStore, videoStore, certificateStore} = useStore();
  const {account} = accountStore;
  const {video, watched} = videoStore;
  const {
    setVideoId,
    UserCertificate,
    showComplete,
    setshowComplete,
    download,
    showQuestionBlock,
    setshowQuestionBlock,
    downloadMessage,
    resetDownloadMessage,
  } = certificateStore;

  const match = useRouteMatch();
  const location = useHistory();
  const [canDownload, setCanDownload] = useState(true);
  const [userStatus, setUserStatus] = useState(false);

  useEffect(() => {
    const {id} = match.params;
    if (id) {
      setVideoId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);

  useEffect(() => {
    if (UserCertificate?.quiz_completed_at) {
      setshowComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserCertificate?.quiz_completed_at]);

  useEffect(() => {
    if (account.access?.certificate) {
      setCanDownload(true);
    } else setCanDownload(false);
  }, [account]);

  const onViewCredits = () => {
    location.push('/certificate/completed');
    setshowQuestionBlock(false);
  };

  const [dowloadLoader, setDowloadLoader] = useState(false);
  const [isAssessmentDisabled, setAssessmentDisabled] = useState(false);

  useEffect(() => {
    if (!account?.access?.certificate) {
      setAssessmentDisabled(true);
    } else {
      setAssessmentDisabled(false);
    }
  }, [video, account]);

  useEffect(() => {
    if (account.access) {
      setUserStatus('active');
    } else setUserStatus('accessInactive');
  }, [account]);

  const onDownload = () => {
    if (!dowloadLoader && canDownload) {
      setDowloadLoader(true);
      download([UserCertificate?.id]).then(() => {
        setDowloadLoader(false);
      });
    }
  };

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Certificate Assessment will be available only after the video has been started.
    </Tooltip>
  );

  const renderAssessmentButton = () => {
    if (isAssessmentDisabled) {
      return null;
    }
    if (!video?.certificate?.quiz_completed_at && video?.certificate[0]?.answers) {
      return (
        <FinishAssessmentButton
          id="start-exam-desc-button"
          np
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          onClick={() => setshowQuestionBlock(true)}
          disabled={isAssessmentDisabled}
          watched={watched}
        />
      );
    }
    if (watched) {
      return (
        <StartAssessmentButton
          id="start-exam-desc-button"
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          onClick={() => setshowQuestionBlock(true)}
          disabled={isAssessmentDisabled}
          watched={watched}
        />
      );
    }
    return (
      <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}} overlay={renderTooltip}>
        <StartAssessmentButton
          id="start-exam-desc-button"
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          disabled={watched}
        />
      </OverlayTrigger>
    );
  };

  const handleModalClose = () => {
    resetDownloadMessage();
  };

  useEffect(() => {
    return () => resetDownloadMessage();
  }, [resetDownloadMessage]);

  return (
    <Wrap className={classNames({expanded})}>
      {userStatus === 'active' ? (
        // Beta CME page
        <div className="cme-content-container">
          {showComplete ? (
            <Form>
              {title !== '' ? <TitleDiv className="py-2 m-0">{title}</TitleDiv> : ''}
              <h5 className="a_title">Certificate</h5>
              <div className="question-block">
                <Button className="a_completed" type="button">
                  <span>Completed</span>
                  <i className="far fa-check-circle" aria-hidden="true" />
                </Button>
                <div className="d-flex">
                  <p style={{fontSize: '14px', fontFamily: 'Roboto, sans-serif', fontWeight: 300}}>
                    Click download for Certification of completion
                  </p>
                </div>
              </div>
              <div className="d-flex mt-4">
                <Button
                  type="button"
                  className="a_download"
                  onClick={() => onDownload()}
                  disabled={dowloadLoader || !UserCertificate?.id}
                >
                  <img src={DownloadArrow} className="icon-download" alt="download" />
                  <span className="video-download">{dowloadLoader ? 'Downloading...' : 'Download'}</span>
                </Button>
                <Button type="button" className="a_view_credit" onClick={() => onViewCredits()}>
                  View progress
                </Button>
              </div>
            </Form>
          ) : (
            ''
          )}
          {!showQuestionBlock && !showComplete ? (
            <div className="cme-disclosure">
              <Description>Complete the assessment to receive certificate of completion</Description>
              {renderAssessmentButton()}
            </div>
          ) : (
            ''
          )}
          {showQuestionBlock && !showComplete ? <QuestionBlock videoTitle={videoTitle} expanded={expanded} /> : ''}
        </div>
      ) : (
        ''
      )}
      {userStatus === 'accessInactive' ? (
        <div className="d-flex flex-column align-items-center">
          <div className="a_iconDiv d-flex align-items-center justify-content-center">
            <img src={certificateIcon} alt="CERTIFICATE" className="a_cmeIcon" />
          </div>
          <p className="a_certificateUpgradeHeading">Not Available</p>
          <p className="a_certificateUpgradeText">
            This feature is not available with your current plan. Please ask your administrator to contact GIBLIB to
            potentially add this feature.
          </p>
        </div>
      ) : null}
      {downloadMessage?.length ? (
        <ErrorModal handleModalClose={handleModalClose} downloadMessage={downloadMessage} />
      ) : null}
    </Wrap>
  );
});

Certificate.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.string,
};
Certificate.defaultProps = {
  expanded: false,
  title: '',
};

export default Certificate;
