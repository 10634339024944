import {makeAutoObservable} from 'mobx';
import {downloadNote, downloadMultipleNote, downloadAllNote} from '../../api/note';
import ErrorStore from '../error/errorStore';

class NoteStore {
  errorStore: ErrorStore;

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  download = async (id: number) => {
    try {
      await downloadNote(id);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  downloadVideoNote = async (noteIds: number[]) => {
    try {
      await downloadMultipleNote(noteIds);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  downloadAll = async () => {
    try {
      await downloadAllNote();
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default NoteStore;
