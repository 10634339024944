import styled from 'styled-components';

export const SearchBar = styled.div`
  display: inline-block;
  max-width: calc(100% - 144px);
  @media screen and (min-width: 576px) {
    width: 336px;
    float: left;
  }
  .form-control {
    background: ${({theme}) => theme.search_button};
    border-left: none;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    height: 36px;
    color: #fff !important;
    font-size: 14px;
    box-shadow: none;
  }
  .form-control::placeholder {
    color: rgba(255, 255, 255, 0.4);
    font-family: Roboto;
  }
  .input-group-prepend {
    margin-right: 0px;
    margin-left: 0px;
  }
  .search-button {
    height: 36px;
    padding: 0.4rem;
    padding-right: 20px;
    border: none;
    background: ${({theme}) => theme.search_button};
    color: #fff;
    border-right: none;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  .fa-search {
    background-color: none !important;
    margin-left: 4px !important;
    margin-right: -5px !important;
    font-size: 16px !important;
  }
`;

export const Div = styled.div`
  .mob {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    .select-dropdown {
      button {
        width: 120px;
        margin-right: 0;
      }
    }
    @media screen and (min-width: 576px) {
      display: none;
    }
  }
  .desk {
    display: block;
    padding-bottom: 8px;
    .select-dropdown {
      button {
        width: 190px;
        margin-right: 32px;
      }
    }
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
  .select-dropdown {
    display: inline-block;
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      padding: 0 16px;
      border-radius: 3px;
      height: 36px;
      position: relative;
      font-size: 14px;
      line-height: 24px;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid rgba(255, 255, 255, 0.4);
      color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};
    }
    .show > .btn-primary.dropdown-toggle {
      border: 1px solid rgba(255, 255, 255, 0.4);
      background-color: transparent;
    }
    .dropdown-menu {
      width: 190px;
      background-color: #2f3137;
    }
    .dropdown-item {
      background-color: #2f3137;
      color: white;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      border: 1px solid #2f3137;
      padding: 3px 0;
      :hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    label {
      width: 100%;
      padding: 5px;
      padding-left: 20px;
    }
    .form-check-input {
      margin-left: -0.5rem;
      appearance: none;
      width: 16px;
      height: 16px;
      border: 2px solid #008aff;
      border-radius: 50%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      :checked {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='rgb(46, 48, 56)'/%3E%3C/svg%3E");
        border: 2px solid rgba(0, 138, 255, 0.5);
        background-color: #008aff;
      }
    }
  }
`;

export const PaginationContainer = styled.div`
  text-align: right;
  position: relative;
  span {
    font-size: 12px;
  }
  label {
    width: 100%;
    padding: 7px;
    padding-left: 18px;
  }
  .form-check-input {
    margin-left: -0.5rem;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #008aff;
    border-radius: 50%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    :checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='4e505a'/%3E%3C/svg%3E");
      border: 2px solid rgba(0, 138, 255, 0.5);
      background-color: #008aff;
    }
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  .a_limit,
  .a_p_dec,
  .a_p_inc {
    padding: 0;
    background: transparent;
    border: none;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 16px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
    :hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
      border-radius: 3px;
    }
  }
  .a_limit {
    font-size: 12px;
    padding-left: 4px;
    padding-top: 2px;
  }
  .a_limit:hover,
  .a_limit:active,
  .a_limit:focus {
    font-size: 12px;
    background-color: #008aff;
    color: #dcdee1;
    border-radius: 3px;
  }
  .dropdown-menu {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 125px;
    text-align: left;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.18px;
    line-height: 16px;
    min-width: 7rem;
  }
  .dropdown {
    display: inline-block;
  }
  #limitMenu::after {
    border: none;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;
