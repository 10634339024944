import React, {useRef, useEffect, useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Card, FormControl, Form, Container} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import {useStore} from '../../store';
import logo from '../../assets/images/GBLB_Logo_Horizontal_Notag_White_FullColor.png';
import {useQuery, isIntegrationTest} from '../../utils/helper';
import RegNavBar from '../Register/components/RegNavBar';
import {RegisterContainer, Wrapper} from '../Register/style';
import {Body1, Body2, ButtonXLarge, Title1} from '../../utils/styles/typography';

const AccessVerify = observer(() => {
  const location = useHistory();
  const {authStore, accessStore} = useStore();
  const {
    user,
    accessCode,
    registrationForm,
    verifiedEmailResponseText,
    verifiedStatus,
    createdStatus,
    isOriginUser,
    gotoLogin,
    setActiveStep,
    onVerfiyAccessEmail,
    onCreateAccessEmail,
    onResetResponseText,
  } = authStore;
  const {anonymousAccess} = accessStore;

  const {register, handleSubmit, errors, watch, setValue} = useForm({
    defaultValues: {
      email: registrationForm.email,
      accessCode,
    },
  });
  const watchEmailField = watch('email');
  const watchACField = watch('accessCode');
  const recaptchaRef = useRef();
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const query = useQuery();
  const queryEmail = query.get('email');

  const bypassRecaptcha = isIntegrationTest();

  const onRecaptchaCompleted = token => {
    // Save recaptcha token on successful completion.
    // eslint-disable-next-line
    recaptchaRef.current.reset();
    setRecaptchaToken(token);
  };

  const moveToNextStep = useCallback(() => {
    if (user.is_registration_completed || gotoLogin) {
      location.push('/login');
    } else {
      setActiveStep('/access/account');
    }
  }, [location, setActiveStep, user.is_registration_completed, gotoLogin]);

  const registerIPBasedUser = useCallback(
    async email => {
      await onVerfiyAccessEmail(email);

      if (authStore.verifiedStatus) {
        return;
      }

      setValue('accessCode', anonymousAccess.access_name);

      await onCreateAccessEmail({email, accessCode: anonymousAccess.access_name});
    },
    [authStore, anonymousAccess, onCreateAccessEmail, onVerfiyAccessEmail, setValue],
  );

  const VerifyEmail = useCallback(
    async data => {
      if (!data.email) {
        return;
      }
      if (anonymousAccess) {
        await registerIPBasedUser(data.email);
      } else if (data.accessCode) {
        await onCreateAccessEmail(data);
      } else {
        await onVerfiyAccessEmail(data.email);
      }
    },
    [anonymousAccess, registerIPBasedUser, onCreateAccessEmail, onVerfiyAccessEmail],
  );

  // Auto-submit the email if passed by query string, but only on first page load
  useEffect(() => {
    if (queryEmail) {
      setValue('email', queryEmail);
    }
    // seems to work if verifyEmail is removed from the dependency list.
  }, [handleSubmit, queryEmail, setValue]);

  useEffect(() => {
    if ((watchEmailField === '' || watchACField === '') && !gotoLogin) onResetResponseText();
  }, [watchEmailField, watchACField, onResetResponseText, gotoLogin]);

  const render = () => (
    <RegisterContainer>
      <RegNavBar />
      <Container className="py-3 py-sm-5" fluid>
        <Wrapper>
          <Card>
            <Card.Body className="text-center">
              <img src={logo} className="logo" alt="GIBLIB" height="16" />
              <Form
                onSubmit={async e => {
                  e.preventDefault();

                  // Add email domain check to automatically pass a user access code @jihye
                  if (watchEmailField.includes('@chulahospital.org') || watchEmailField.includes('@dochula.com')) {
                    setValue('accessCode', 'IGRPChula2023T');
                  }

                  if (watchEmailField.includes('@chula.ac.th') || watchEmailField.includes('student.chula.ac.th')) {
                    setValue('accessCode', 'IGRPChula2023T');
                  }

                  if (watchEmailField.includes('@intusurg.com')) {
                    setValue('accessCode', 'Intuitive2023P');
                  }

                  // Handle case where recaptcha already passed from onChange event.
                  if (recaptchaToken || bypassRecaptcha) {
                    handleSubmit(VerifyEmail)();
                    return;
                  }
                  const token = await recaptchaRef.current.executeAsync();
                  recaptchaRef.current.reset();
                  if (token) {
                    handleSubmit(VerifyEmail)();
                  }
                  setRecaptchaToken(token);
                }}
              >
                <Title1 className="mt-3">Verify your {!verifiedStatus ? 'access code' : 'email'}</Title1>
                {verifiedStatus ? (
                  <Body1 className="descript">
                    Welcome to GIBLIB. Please verify your
                    <br />
                    email and follow additional instructions.
                  </Body1>
                ) : (
                  <Body1 className="descript">
                    Please insert your access code, click verify,
                    <br />
                    and follow instructions.
                  </Body1>
                )}
                <div className="form-middle mt-3">
                  <Form.Row className="verify-div">
                    <Form.Label>Email</Form.Label>
                    <FormControl
                      placeholder="Enter email"
                      type="text"
                      name="email"
                      id="verify-access-email-input"
                      className={`bg-transparent text-white ${
                        (verifiedEmailResponseText && verifiedStatus) || errors.email
                          ? 'border-danger'
                          : watchEmailField && 'border-primary'
                      }`}
                      aria-describedby="basic-addon2"
                      ref={register({
                        required: 'This field is required.',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address.',
                        },
                      })}
                    />
                  </Form.Row>
                  <div className="text-left">
                    {errors.email && <small className="text-danger">{errors.email.message}</small>}
                    {verifiedStatus || gotoLogin ? (
                      <small className="text-danger">{verifiedEmailResponseText}</small>
                    ) : null}
                  </div>
                  <div className="mt-3" style={{visibility: verifiedStatus ? 'hidden' : 'visible'}}>
                    <Form.Row className="verify-div">
                      <Form.Label>Access Code</Form.Label>
                      <FormControl
                        placeholder="Enter access code"
                        type="text"
                        name="accessCode"
                        id="access-code-input"
                        className={`bg-transparent text-white ${
                          verifiedEmailResponseText ? 'border-danger' : watchACField && 'border-primary'
                        }`}
                        aria-describedby="basic-addon2"
                        ref={register()}
                      />
                    </Form.Row>
                  </div>
                </div>
                <div className="form-middle2 text-left">
                  {!verifiedStatus ? <small className="text-danger">{verifiedEmailResponseText}</small> : null}
                  {createdStatus && (
                    <small className="text-success">
                      Access code verified.
                      {isOriginUser ? ' You can now login.' : ' Please continue.'}
                    </small>
                  )}
                </div>
                <Form.Row className="mb-3 pt-5">
                  {createdStatus && (
                    <ButtonXLarge
                      id="continue-access-email"
                      className="a_verifyAccessEmailButton verify-btn"
                      variant={watchACField ? 'primary' : 'secondary'}
                      onClick={moveToNextStep}
                    >
                      {isOriginUser ? 'Go to Log In' : 'Continue'}
                    </ButtonXLarge>
                  )}
                  {!createdStatus &&
                    (!gotoLogin ? (
                      <ButtonXLarge
                        id="verify-access-email"
                        className="a_verifyAccessEmailButton verify-btn"
                        type="submit"
                        variant={watchEmailField ? 'primary' : 'secondary'}
                      >
                        {verifiedStatus ? 'Verify Email' : 'Verify Access Code'}
                      </ButtonXLarge>
                    ) : (
                      <ButtonXLarge
                        className="a_verifyAccessEmailButton verify-btn"
                        type="button"
                        variant="primary"
                        onClick={moveToNextStep}
                      >
                        Go to Log In
                      </ButtonXLarge>
                    ))}
                </Form.Row>
                {!bypassRecaptcha && (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                    // onChange is called when user completes recaptcha successfull
                    onChange={onRecaptchaCompleted}
                    size="invisible"
                    ref={recaptchaRef}
                    theme="dark"
                  />
                )}
                <Form.Row className="verify-div mt-3">
                  <Body2 className="end-text mt-1">
                    By sharing your email, you agree to our&nbsp;
                    <span className="text-primary">
                      <a href="https://www.giblib.com/support/terms-of-service" target="_blank" rel="noreferrer">
                        Terms of Service
                      </a>
                    </span>
                    &nbsp;and&nbsp;
                    <span className="text-primary">
                      <a href="https://www.giblib.com/support/privacy-policy" target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                      .
                    </span>
                  </Body2>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Wrapper>
      </Container>
    </RegisterContainer>
  );

  return render();
});

export default AccessVerify;
