import styled from 'styled-components';

export const Main = styled.div`
  @media (min-width: 768px) and (max-width: 1200px) {
    padding-left: 76px;
  }
  @media screen and (min-width: 768px) {
    padding-top: 54px;
  }

  @media screen and (max-width: 576px) {
    & .a_orgTile {
      width: 140px;
      height: 140px;
    }
    & .organization-col {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  & .organization-head {
    @media screen and (max-width: 576px) {
      margin-bottom: 25px;
    }
    @media screen and (min-width: 576px) {
      display: flex;
    }
  }
`;

export const Breadcrumbs = styled.ul`
  list-style: none;
  font-weight: 600;
  padding-top: 1em;
  padding-left: 0px;
  margin-bottom: -20px;
  & > li:after {
    content: '${props => props.separator || '/'}';
    padding: 0 5px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 0.5em;
  }
`;

export const Crumb = styled.li`
  font-weight: normal;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  &:last-of-type:after {
    content: '';
    padding: 0;
  }
  @media screen and (max-width: 576px) {
    font-size: 10px;
  }
`;

export const ActiveCrumb = styled.li`
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  &:last-of-type:after {
    content: '';
    padding: 0;
  }
`;

export const PageTitle = styled.h5`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-top: 48px;
  margin-bottom: 18px;
`;

export const SortSelect = styled.select`
  background: ${({theme}) => (theme.body === '#FFF' ? '#f9f9f9' : '#2E3038')};
  padding: 8px 0px 8px 16px;
  border-radius: 30px;
  margin-top: 0.1em;
  width: 191px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  background-image: ${({theme}) =>
    theme.body === '#FFF'
      ? `url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`
      : `url("data:image/svg+xml;utf8,<svg fill='grey' height='32' viewBox='0 0 24 24' width='32' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`};
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
  color: ${({theme}) => (theme.body === '#FFF' ? '#4e505a' : '#b8bbbe')};

  @media screen and (max-width: 576px) {
    width: 134px;
    font-size: 11px;
  }
`;

export const FormLabel = styled.h5`
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;

  @media screen and (max-width: 576px) {
    font-size: 10px;
  }
`;

export const CourseFilter = styled.div`
  margin-bottom: 8px;
  margin-left: auto;
  display: flex;

  @media screen and (max-width: 576px) {
    justify-content: flex-end;
  }
`;

export const Line = styled.div`
  height: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  margin-bottom: 20px;
`;
