import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Container, Form, FormCheck} from 'react-bootstrap';
import {useStore} from '../../../store';
import Dropdown from '../elements/Multiselect';
import {Filter} from '../elements/catalog';

type Props = {
  specialityFilter: Array,
  organizationFilter: Array,
  contenttypeFilter: Array,
  setShowCount: Function,
};
// Filters
const VideoFilter = observer(
  ({specialityFilter, organizationFilter, subSpecialityFilter, contenttypeFilter, setShowCount}: Props) => {
    const {videoStore} = useStore();
    const {
      selectedSpecialtyIds,
      selectedSubSpecialtyIds,
      selectedOrganizationIds,
      selectedContenttypes,
      setSelectedSpecialties,
      setSelectedOrganizations,
      setSelectedContenttype,
      setCME,
      setVR,
      isCME,
      isVR,
      listVideos,
      setSelectedSubSpecialties,
      resetPage,
      searchText,
    } = videoStore;
    const selectOptions = (selectedIDs, type) => {
      if (type === 1) {
        setSelectedOrganizations(selectedIDs, false);
      } else if (type === 2) {
        setSelectedSpecialties(selectedIDs, false);
      } else if (type === 3) {
        setSelectedSubSpecialties(selectedIDs, false);
      } else {
        setSelectedContenttype(selectedIDs, false);
      }
    };

    const loadVideo = () => {
      resetPage();
      listVideos({include: ['bookmark', 'playlist']}, true); // include bookmark and playlist
      if (
        selectedSpecialtyIds.length > 0 ||
        selectedSubSpecialtyIds.length > 0 ||
        selectedOrganizationIds.length > 0 ||
        selectedContenttypes.length > 0 ||
        isCME ||
        isVR ||
        (searchText !== '' && searchText !== null)
      ) {
        setShowCount(true);
      } else {
        setShowCount(false);
      }
    };

    return (
      <Container>
        <Filter>
          <Form>
            <Form.Row className="d-inline-block w-100 m-0">
              <Form.Group controlId="filterSpecialty">
                <div>
                  <Dropdown
                    title="Specialty"
                    values={specialityFilter}
                    selectedIds={selectedSpecialtyIds}
                    id="filterSpecialty"
                    onSelectOptions={selectedIDs => selectOptions(selectedIDs, 2)}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row className="d-inline-block w-100 m-0">
              <Form.Group controlId="filterSubSpecialty">
                <div>
                  <Dropdown
                    title="Subspecialty"
                    values={subSpecialityFilter}
                    selectedIds={selectedSubSpecialtyIds}
                    id="filterSubSpecialty"
                    onSelectOptions={selectedIDs => selectOptions(selectedIDs, 3)}
                    disabled={subSpecialityFilter.length === 0}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row className="d-inline-block w-100 m-0">
              <Form.Group controlId="filterOrganization">
                <div>
                  <Dropdown
                    title="Organization"
                    values={organizationFilter}
                    selectedIds={selectedOrganizationIds}
                    id="filterOrganization"
                    onSelectOptions={selectedIDs => selectOptions(selectedIDs, 1)}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row className="d-inline-block w-100 m-0">
              <Form.Group controlId="filterContenttype" className="text-nobreak">
                <div>
                  <Dropdown
                    title="Content Subtype"
                    values={contenttypeFilter}
                    selectedIds={selectedContenttypes}
                    id="filterContenttype"
                    onSelectOptions={selectedIDs => selectOptions(selectedIDs, 4)}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row className="switchFilter d-inline-block w-100">
              <Form.Group controlId="cme">
                <div className="d-flex justify-content-between">
                  <Form.Label className="mr-2">CME</Form.Label>
                  <FormCheck id="cme" type="switch" onChange={e => setCME(e.target.checked, false)} checked={isCME} />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row className="switchFilter d-inline-block w-100">
              <Form.Group controlId="vr">
                <div className="d-flex justify-content-between">
                  <Form.Label className="mr-2">360VR</Form.Label>
                  <FormCheck id="vr" type="switch" onChange={e => setVR(e.target.checked, false)} checked={isVR} />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Button className="a_filter-btn" onClick={() => loadVideo()}>
                Done
              </Button>
            </Form.Row>
          </Form>
        </Filter>
      </Container>
    );
  },
);

export default VideoFilter;
