import React from 'react';
import {Col} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import ReactTooltip from 'react-tooltip';
import stringToURLSlug from '../../../utils/helper';
import {useStore} from '../../../store';
import Widget from './widget';

const Dashboard = observer(() => {
  const {accountStore} = useStore();
  const {stats} = accountStore;
  const slug = stringToURLSlug(stats.most_watched_specialty.name);
  const widgets = [
    {
      id: 1,
      name: 'most-watched',
      icon: 'fak fa-books-medical-solid',
      color: 'blue',
      primaryText: stats.most_watched_specialty.name,
      secondaryText: 'Most Watched',
      hoverText: 'Specialty from which the most time watched.',
      link: stats.most_watched_specialty.name === 'None' ? '' : `/specialty/${stats.most_watched_specialty.id}/${slug}`,
    },
    {
      id: 2,
      name: 'time-watched',
      icon: 'far fa-clock',
      color: 'pink',
      primaryText: `${stats.total_time_watched} hrs`,
      secondaryText: 'Time Watched',
      hoverText: 'Total time watched (hours)',
      link: '/history',
    },
    {
      id: 3,
      name: 'videos-watched',
      icon: 'fas fa-video',
      color: 'pink',
      primaryText: stats.videos_watched,
      secondaryText: 'Videos Watched',
      hoverText: 'Number of videos that have been completed (90% or more) all time',
      link: '/history',
    },
    {
      id: 4,
      name: 'credits-earned',
      icon: 'fak fa-cme-1',
      color: 'green',
      primaryText: stats.total_credits_earned,
      secondaryText: 'Credits Earned',
      hoverText: 'Number of CME credits that have been completed before CME expired',
      link: '/cme/in-progress',
    },
    {
      id: 5,
      name: 'daily-streak',
      icon: 'fas fa-fire-alt',
      color: 'orange',
      primaryText: stats.daily_streak,
      secondaryText: 'Daily Streak',
      hoverText:
        'Number of consecutive days before and including today where user logged in or performed any activity. Using UTC time zone.',
      link: '/history',
    },
    {
      id: 6,
      name: 'weekly-streak',
      icon: 'fas fa-fire-alt',
      color: 'orange',
      primaryText: stats.weekly_streak,
      secondaryText: 'Weekly Streak',
      hoverText:
        'Number of consecutive weeks before and including this week where user logged in or performed any activity. Using UTC time zone and start of the week as Monday.',
      link: '/history',
    },
  ];
  return (
    <div className="row px-2">
      {widgets.map(el => (
        <Col key={el.id} className="mb-2 px-1" md={6} lg={3}>
          <Widget data={el} />
        </Col>
      ))}
      <ReactTooltip place="top" effect="solid" id="profileStatistics" />
    </div>
  );
});

export default Dashboard;
