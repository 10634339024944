import {runInAction, makeAutoObservable} from 'mobx';
import {getBookmarks, getWatchedVideos, deleteHistory, getLikedVideos} from '../../api/bookmark';
import {deleteBookmark, createBookmark} from '../../api/course';
import {updateThumbsUp, getAllPlaylists, addToPlaylist, massUpdatePlaylists} from '../../api/video';
import {
  addCoursePlaylist,
  addNewCoursePlaylist,
  addConferencePlaylist,
  addNewConferencePlaylist,
} from '../../api/playlist';
import AlertStore from '../alert/alertStore';
import ErrorStore from '../error/errorStore';

class BookmarkStore {
  errorStore: ErrorStore;

  alertStore: AlertStore;

  bookmarksList: Array = [];

  historyVideos: Array = [];

  likedVideos: Array = [];

  playlist: Array = [];

  bookmarkCount: Number = 0;

  historyCount: Number = 0;

  likedVideosCount: Number = 0;

  count: Number = 10;

  bookmarkTotalCount: Number = 10;

  historyTotalCount: Number = 10;

  likedVideosTotalCount: Number = 10;

  isSorting: Boolean = false;

  sortBy: String = 'DESC';

  page: Number = 1;

  bookmarkPage: Number = 1;

  historyPage: Number = 1;

  likedVideosPage: Number = 1;

  bookmarkLoading: Boolean = true;

  bookmarkSearchText: string = '';

  historySearchText: string = '';

  likesSearchText: string = '';

  back: Boolean = false;

  constructor({errorStore, alertStore}) {
    this.errorStore = errorStore;
    this.alertStore = alertStore;
    makeAutoObservable(this);
  }

  setBack = value => {
    this.back = value;
  };

  resetSearch = () => {
    this.bookmarkSearchText = null;
    this.likesSearchText = null;
    this.historySearchText = null;
  };

  setBookmarkSearch = value => {
    this.page = 1;
    this.bookmarkPage = 1;
    this.bookmarkSearchText = value;
  };

  setHistorySearch = value => {
    this.page = 1;
    this.historyPage = 1;
    this.historySearchText = value;
  };

  setLikesSearch = value => {
    this.page = 1;
    this.likedVideosPage = 1;
    this.likesSearchText = value;
  };

  changeCount = option => {
    this.count = option;
    this.page = 1;
  };

  changeBookmarkCount = option => {
    this.bookmarkTotalCount = option;
    this.bookmarkPage = 1;
  };

  changeHistoryCount = option => {
    this.historyTotalCount = option;
    this.historyPage = 1;
  };

  changeLikedVideosCount = option => {
    this.likedVideosTotalCount = option;
    this.likedVideosPage = 1;
  };

  setResetSort = () => {
    this.isSorting = false;
  };

  changeSort = option => {
    this.isSorting = true;
    this.sortBy = option;
    this.bookmarksList = [];
    this.historyVideos = [];
    this.likedVideos = [];
  };

  pageIncrement = () => {
    this.page += 1;
  };

  pageDecrement = () => {
    this.page -= 1;
  };

  bookmarkPageIncrement = () => {
    this.page += 1;
    this.bookmarkPage += 1;
  };

  bookmarkPageDecrement = () => {
    this.page -= 1;
    this.bookmarkPage -= 1;
  };

  bookmarkPageTransfer = pageIndex => {
    this.page = pageIndex;
    this.bookmarkPage = pageIndex;
  };

  historyPageIncrement = () => {
    this.page += 1;
    this.historyPage += 1;
  };

  historyPageDecrement = () => {
    this.page -= 1;
    this.historyPage -= 1;
  };

  historyPageTransfer = pageIndex => {
    this.page = pageIndex;
    this.historyPage = pageIndex;
  };

  likedVideosPageIncrement = () => {
    this.page += 1;
    this.likedVideosPage += 1;
  };

  likedVideosPageDecrement = () => {
    this.page -= 1;
    this.likedVideosPage -= 1;
  };

  likedVideosPageTransfer = pageIndex => {
    this.page = pageIndex;
    this.likedVideosPage = pageIndex;
  };

  setPlaylistObservables = playlist => {
    this.playlist = playlist;
  };

  getAllPlaylists = async () => {
    try {
      const res = await getAllPlaylists();
      if (res.data.status) {
        this.setPlaylistObservables(res.data.playlist);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  setBookmarkObservables = bookmarks => {
    this.bookmarksList = bookmarks;
  };

  // create and add bookmark to playlist.
  addToPlaylist = async (videoId: number, name: string, type: string) => {
    const updatedBookmarks = [...this.bookmarksList];
    try {
      let res = null;
      if (type === 'course') {
        res = await addNewCoursePlaylist(videoId, name);
      } else if (type === 'conference') {
        res = await addNewConferencePlaylist(videoId, name);
      } else {
        res = await addToPlaylist(videoId, name);
      }
      const {status} = res.data;
      if (status) {
        const index = this.bookmarksList.findIndex(bookmark => bookmark.id === videoId);
        if (type === 'video') {
          updatedBookmarks[index].playlist.push(res.data.playlist);
          this.setBookmarkObservables(updatedBookmarks);
        }
        this.alertStore.setMessage('Playlist created successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // create and add watched videos to playlist.
  addHistoryToPlaylist = async (videoId: number, name: string) => {
    const updatedHistory = [...this.historyVideos];
    try {
      const res = await addToPlaylist(videoId, name);
      const {status} = res.data;
      if (status) {
        const index = this.historyVideos.findIndex(history => history.video_id === videoId);
        updatedHistory[index].playlist.push(res.data.playlist);
        this.setHistoryVideos(updatedHistory);
        this.alertStore.setMessage('Playlist created successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // create and add liked videos to playlist.
  addLikedToPlaylist = async (videoId: number, name: string) => {
    const updatedLiked = [...this.likedVideos];
    try {
      const res = await addToPlaylist(videoId, name);
      const {status} = res.data;
      if (status) {
        const index = this.likedVideos.findIndex(liked => liked.id === videoId);
        updatedLiked[index].playlist.push(res.data.playlist);
        this.setLikedVideos(updatedLiked);
        this.alertStore.setMessage('Playlist created successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // update playlist from history page.
  updatePlaylistHistory = async (videoId: number, data: [{}]) => {
    const updatedHistory = [...this.historyVideos];
    try {
      const res = await massUpdatePlaylists(videoId, data);
      const {status} = res.data;
      if (status) {
        const index = this.historyVideos.findIndex(history => history.video_id === videoId);
        updatedHistory[index].playlist = res.data.playlist;
        this.setHistoryVideos(updatedHistory);
        this.alertStore.setMessage('Playlist updated successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // update playlist from likes page.
  updatePlaylistLiked = async (videoId: number, data: [{}]) => {
    const updatedLiked = [...this.likedVideos];
    try {
      const res = await massUpdatePlaylists(videoId, data);
      const {status} = res.data;
      if (status) {
        const index = this.likedVideos.findIndex(liked => liked.id === videoId);
        updatedLiked[index].playlist = res.data.playlist;
        this.setLikedVideos(updatedLiked);
        this.alertStore.setMessage('Playlist updated successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  // update playlist from bookmarks page.
  updatePlaylist = async (videoId: number, data: [{}], type: string) => {
    const updatedBookmarks = [...this.bookmarksList];
    let res = 'null';
    try {
      if (type === 'course') {
        res = await addCoursePlaylist(videoId, data);
      } else if (type === 'conference') {
        res = await addConferencePlaylist(videoId, data);
      } else {
        res = await massUpdatePlaylists(videoId, data);
      }
      const {status} = res.data;
      if (status) {
        const index = this.bookmarksList.findIndex(bookmark => bookmark.id === videoId);
        if (type === 'video') {
          updatedBookmarks[index].playlist = res.data.playlist;
          this.setBookmarkObservables(updatedBookmarks);
        }
        this.alertStore.setMessage('Playlist updated successfully', 'success');
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set bookmarks list observables
   * @param {array} bookmarks
   */
  setBookmarksObservables = bookmarks => {
    const result = bookmarks
      .filter(v => v.video || v.course || v.conference)
      .map(a => {
        let item = null;
        if (a.video != null) {
          item = a.video;
          item.type = 'video';
        } else if (a.course != null) {
          item = a.course;
          item.type = 'course';
        } else {
          item = a.conference;
          item.type = 'conference';
        }
        item.created_at = a.created_at;
        return item;
      });
    this.bookmarksList = result;
  };

  /** Fetch All bookmarks */
  listBookmarks = async () => {
    this.bookmarkLoading = true;
    const resp = await getBookmarks(this.sortBy, this.bookmarkPage, this.bookmarkTotalCount, this.bookmarkSearchText);
    if (resp.status) {
      this.bookmarkLoading = false;
    }
    const bookmarks = resp.data.bookmark.rows;
    this.bookmarkCount = resp.data.bookmark.count;
    this.setBookmarksObservables(bookmarks);
  };

  scrollBookmark = async () => {
    if (!this.bookmarkLoading && this.count * this.bookmarkPage <= this.bookmarkCount && window.innerWidth <= 576) {
      this.bookmarkPage += 1;
    }
  };

  /** Delete bookmark */
  onDeleteBookmark = async (id, type) => {
    let typeText = 'video';
    if (type === 'course') {
      typeText = 'course';
    }
    if (type === 'conference') {
      typeText = 'conference';
    }
    try {
      const resp = await deleteBookmark(id, typeText);
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Successfully removed from bookmark', 'success');
        runInAction(() => {
          this.bookmarksList = this.bookmarksList.filter(item => item?.id !== id);
        });
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set history list observables
   * @param {array} history
   */

  setHistoryVideos = videos => {
    this.historyVideos = videos;
  };

  /** Fetch All history */
  listHistory = async () => {
    try {
      const resp = await getWatchedVideos(
        this.sortBy,
        this.historyPage,
        this.historyTotalCount,
        this.historySearchText,
      );
      const history = resp.data.watchedVideos;
      runInAction(() => {
        this.historyCount = parseInt(resp.data.historyCount, 10);
      });
      this.setHistoryVideos(history);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete history */
  onDeleteHistory = async id => {
    try {
      const resp = await deleteHistory(id);
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Video deleted from history', 'success');
        runInAction(() => {
          this.historyVideos = this.historyVideos.filter(item => item.video_id !== id);
        });
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create new bookmark */
  onBookmarkVideo = async (videoId, history) => {
    try {
      const updatedVideos = [...this.historyVideos];
      const resp = await createBookmark(videoId, 'video');
      const {status, bookmark} = resp.data;
      if (status) {
        let index = null;
        if (!history) {
          index = updatedVideos.findIndex(video => video.video_id === videoId);
          updatedVideos[index].bookmark_id = bookmark.id;
        } else {
          index = updatedVideos.findIndex(video => video.id === videoId);
          updatedVideos[index].bookmark = bookmark;
        }
        this.setHistoryVideos(updatedVideos);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onDeleteBookmarkVideo = async (videoId, history) => {
    const updatedVideos = [...this.historyVideos];
    try {
      const resp = await deleteBookmark(videoId, 'video');
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Successfully removed from bookmark', 'success');
        let index = null;
        if (!history) {
          index = updatedVideos.findIndex(video => video.video_id === videoId);
          delete updatedVideos[index].bookmark_id;
        } else {
          index = updatedVideos.findIndex(video => video.id === videoId);
          delete updatedVideos[index].bookmark;
        }
        this.setHistoryVideos(updatedVideos);
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set liked list observables
   * @param {array} bookmarks
   */
  setLikedObservables = liked => {
    const result = liked
      .filter(v => v.video)
      .map(a => {
        const item = a.video;
        item.created_at = a.created_at;
        return item;
      });
    this.likedVideos = result;
  };

  setLikedVideos = videos => {
    this.likedVideos = videos;
  };

  /** Fetch All liked videos */
  listLikedVideos = async () => {
    try {
      const resp = await getLikedVideos(
        this.sortBy,
        this.likedVideosPage,
        this.likedVideosTotalCount,
        this.likesSearchText,
      );
      const liked = resp.data.likedVideos.rows;
      runInAction(() => {
        this.likedVideosCount = resp.data.likedVideos.count;
      });
      this.setLikedObservables(liked);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create new bookmark */
  onBookmarkLikedVideo = async videoId => {
    const updatedVideos = [...this.likedVideos];
    try {
      const resp = await createBookmark(videoId, 'video');
      const {status, bookmark} = resp.data;
      if (status) {
        const index = updatedVideos.findIndex(video => video.id === videoId);
        updatedVideos[index].bookmark = bookmark;
        this.setLikedVideos(updatedVideos);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onDeleteBookmarkLikedVideo = async videoId => {
    const updatedVideos = [...this.likedVideos];
    try {
      const resp = await deleteBookmark(videoId, 'video');
      const {status} = resp.data;
      if (status) {
        this.alertStore.setMessage('Successfully removed from bookmark', 'success');
        const index = updatedVideos.findIndex(video => video.id === videoId);
        delete updatedVideos[index].bookmark;
        this.setLikedVideos(updatedVideos);
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onUndoLike = async (videoId: number) => {
    try {
      const res = await updateThumbsUp(videoId, 'like');
      const {status} = res.data;
      if (status) {
        this.alertStore.setMessage('Removed from liked videos', 'success');
        runInAction(() => {
          this.likedVideos = this.likedVideos.filter(item => item.id !== videoId);
        });
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default BookmarkStore;
