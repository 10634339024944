import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {Main, InformationDiv, CmeFinishAssessment} from './elements/cme';
import {useStore} from '../../store';
import CMETab from './Components/cmeTab';
import CmeInformation from './Components/CmeInformation';
import CmeQuestions from '../Video/components/CME';
// import UpgradeModal from '../../components/UpgradeModal';
import {amplitude} from '../../utils/Amplitude';

// CME page
const Cme = observer(() => {
  const {accountStore, cmeStore} = useStore();
  const {account, getAccount} = accountStore;
  const {
    question,
    progressSortBy,
    completedSortBy,
    completedStartDate,
    completedEndDate,
    getInprogressCmes,
    getCompletedCmes,
    inProgressPage,
    inProgressRecordCount,
    completedPage,
    completedRecordCount,
    getRevokedCmes,
    revokedTotalCount,
    revokedSortBy,
    showQuestions,
    cmeLoading,
    videoTitle,
    showQuestionBlock,
  } = cmeStore;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getRevokedCmes('revoked_at');
  }, [getRevokedCmes, revokedSortBy]);

  useEffect(() => {
    getInprogressCmes('progress', 'created_at');
    amplitude.getInstance().logEvent('cme-page-viewed');
  }, [getInprogressCmes, progressSortBy, inProgressPage, inProgressRecordCount]);

  useEffect(() => {
    getCompletedCmes('completed', 'completed_at');
  }, [getCompletedCmes, completedSortBy, completedStartDate, completedEndDate, completedPage, completedRecordCount]);

  // TODO: Add back better CTA for canceled users on CME dashboard that does not
  // confuse them when (validly) downloading credits after their plan expired.
  // const [isShowUpgradeModal, setShowUpgradeModal] = useState(true);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const hasUser = localStorage.getItem('user');

    const url = location.search;
    const query = new URLSearchParams(url);
    const access_code = query.get('code');

    if (access_code) {
      if (!hasUser) {
        localStorage.setItem('access_token', access_code);
        getAccount();
      }
      query.delete('code');
      history.replace({
        search: query.toString(),
      });
    }
    if (!hasUser && account?.id) {
      localStorage.setItem('user', JSON.stringify(account));
    }
  }, [history, location, getAccount, account]);

  return (
    <div id="cme" className="d-flex">
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Sidebar />
      <div className="w-100">
        <Navbar />
        <Main
          className={classNames('', {
            'sidetab-expanded': question && showQuestionBlock,
            'flex-column': !question,
          })}
        >
          <div className="cme-Container">
            <InformationDiv
              className="px-4 pb-4"
              onClick={() => (question ? showQuestions(false) : '')}
              onKeyDown={() => (question ? showQuestions(false) : '')}
              aria-hidden="true"
            >
              <CmeInformation />
            </InformationDiv>
            <CMETab revoked={revokedTotalCount} />
            {!cmeLoading && <Footer />}
          </div>
          {question && showQuestionBlock ? (
            <CmeFinishAssessment>
              {cmeLoading ? (
                <div className="spinner-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
                  <Spinner animation="grow" role="status" variant="secondary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <CmeQuestions expanded={question} title="CME Assessment" videoTitle={videoTitle} />
              )}
            </CmeFinishAssessment>
          ) : (
            ''
          )}

          {/* Temporarily removing CTA from my cme dashboard. It's else where on the site
          and confuses users who have canceled their subscriptions but still want to download
          their CME credits */}
          {/* CME Non-premium users upgrade modal */}
          {/* {!account?.subscription?.name?.includes('Premium') &&
          !(account?.access?.status === 'Active' && account?.access?.cme) ? (
            <UpgradeModal access="cme" isShow={isShowUpgradeModal} onHideModal={() => setShowUpgradeModal(false)} />
          ) : null} */}
        </Main>
      </div>
    </div>
  );
});

export default Cme;
