import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Button, OverlayTrigger, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import Moment from 'react-moment';
import {useStore} from '../../store';
import stringToURLSlug from '../../utils/helper';
import LocalStorageService from '../../utils/LocalStorageService';
import {Body2} from '../../utils/styles/typography';
import UpgradeModal from '../UpgradeModal';
import {ThumbnailImg, CardHolder, ButtonGroup, NumOfVideos, IconDiv, Title} from './style';
import ConferencePlaylistPopover from '../CoursePlaylistPopover';
import {ActiveSmallBtn, DefaultSmallBtn} from '../FollowButtons';
import {ReactComponent as GiblibIcon} from '../../assets/icons/Giblib_icon.svg';
import {ReactComponent as CMEIcon} from '../../assets/icons/CME.svg';

const LandingCard = observer(({whichType, type, onFollow, onBookmark, playlist, playlistStore}) => {
  const location = useHistory();

  const loggedInUser = LocalStorageService.getUser();

  const store = useStore();
  const {account} = store.accountStore;
  const {addConferenceToNewPlaylist, addConferenceToPlaylist, addCourseToNewPlaylist, addCourseToPlaylist} = store[
    playlistStore
  ];

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const [playlistUpdating, setUpdating] = useState(false);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) setUserState(true);
  }, [account]);

  const bookmarkConference = (e, selectedItem) => {
    e.stopPropagation();
    onBookmark(selectedItem);
  };

  const gotoTypeDetail = id => {
    const slug = stringToURLSlug(type.title);
    location.push(`/${whichType === 'conference' ? 'conferences' : 'courses'}/${id}/${slug}`);
  };

  const followType = (e, selectedItem) => {
    e.stopPropagation();
    if (!account?.subscription?.name?.includes('Basic')) onFollow(selectedItem);
    else setShowFollowUpgradeModal(true);
  };

  const createAndAddToPlaylist = async data => {
    setUpdating(true);
    if (whichType === 'conference') await addConferenceToNewPlaylist(type.id, data.listname);
    else await addCourseToNewPlaylist(type.id, data.listname);
    setUpdating(false);
  };

  const updateExistingPlaylist = async data => {
    setUpdating(true);
    if (whichType === 'conference') await addConferenceToPlaylist(type.id, data);
    else await addCourseToPlaylist(type.id, data);
    setUpdating(false);
  };

  return (
    <CardHolder className="conferenceBorder-bottom py-3">
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      <div className="d-flex" id={type.id}>
        <ThumbnailImg
          className="mr-4"
          src={type.thumbnail_url}
          alt={type.title}
          onClick={() => gotoTypeDetail(type.id)}
        />
        <NumOfVideos>{`${type?.number_of_videos} Videos`}</NumOfVideos>
        <div
          className="w-100"
          role="button"
          tabIndex="0"
          onClick={() => gotoTypeDetail(type.id)}
          onKeyDown={() => gotoTypeDetail(type.id)}
        >
          <div className="d-flex justify-content-between">
            <IconDiv>
              <GiblibIcon alt="giblib" width={20} />
              {/* {state.isNew ? <NewIcon alt="new" /> : null}
              {item.video_type?.includes('360vr') ? <VRIcon alt="vr" /> : null} */}
              {type?.is_cme ? (
                <>
                  <CMEIcon className="cme-icon" alt="cme" />
                  <Body2 className="d-inline detail">{type.credit_count} Credits</Body2>
                </>
              ) : null}
            </IconDiv>
            <ButtonGroup className="text-right">
              {type.follow?.length ? (
                <ActiveSmallBtn className=" a_followed" onClick={e => followType(e, type)}>
                  <span className="following">
                    <i className="fas fa-check mr-2" />
                    Following
                  </span>
                  <span className="unfollow">
                    <i className="fas fa-times mr-2" />
                    Unfollow
                  </span>
                </ActiveSmallBtn>
              ) : (
                <DefaultSmallBtn className="a_follow" onClick={e => followType(e, type)}>
                  <i className="fas fa-plus mr-2" />
                  Follow
                </DefaultSmallBtn>
              )}
              {loggedInUser ? (
                [
                  !playlistUpdating ? (
                    <OverlayTrigger
                      trigger="click"
                      placement="auto-start"
                      overlay={props => (
                        <ConferencePlaylistPopover
                          props={props}
                          allPlaylist={playlist}
                          onNew={createAndAddToPlaylist}
                          onExisting={updateExistingPlaylist}
                          courseVideos={type.videos}
                          userState={userState}
                          showUpgradeModal={isShowPlaylistUpgradeModal}
                          onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                        />
                      )}
                      rootClose
                    >
                      <Button variant="basic" className="a_playlist" onClick={() => setShowPlaylistUpgradeModal(true)}>
                        <i className="fak fa-list-ul" />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <div className="p-0 mr-3 btn">
                      <Spinner animation="border" size="sm" variant="primary" />
                    </div>
                  ),
                ]
              ) : (
                <Button variant="basic" className="a_playlist pl-0 py-0 px-3" onClick={() => location.push('/login')}>
                  <i className="fak fa-list-ul" />
                </Button>
              )}
              {account?.subscription?.name?.includes('Basic') ? (
                <Button
                  variant="basic"
                  className="px-0 py-0 a_bookmark"
                  onClick={() => setShowBookmarkUpgradeModal(true)}
                >
                  <i className="far fa-bookmark" />
                </Button>
              ) : (
                <>
                  <Button
                    variant="basic"
                    className={`a_bookmark ${type.bookmark?.length ? 'd-none' : ''}`}
                    onClick={e => bookmarkConference(e, type)}
                  >
                    <i className="far fa-bookmark" />
                  </Button>
                  <Button
                    variant="basic"
                    className={`a_bookmark ${type.bookmark?.length ? 'bookmark-fill' : 'd-none'}`}
                    onClick={e => bookmarkConference(e, type)}
                  >
                    <i className="fas fa-bookmark" />
                  </Button>
                </>
              )}
            </ButtonGroup>
          </div>
          <Title>{type.title}</Title>
          <Body2>
            {type.organization ? type.organization.name : ''}
            {type?.specialty.length > 0 ? ' | ' : null}
            {type?.specialty.map(
              (value, index) =>
                !value.display_name?.includes('General') &&
                (index !== type?.specialty.length - 1 ? `, ${value.display_name}, ` : value.display_name),
            )}
          </Body2>
          {type?.earliest_date && type?.latest_date ? (
            <Body2>
              <Moment format="MM/YYYY">{type?.earliest_date}</Moment> -{' '}
              <Moment format="MM/YYYY">{type?.latest_date}</Moment>
            </Body2>
          ) : (
            ''
          )}
        </div>
      </div>
    </CardHolder>
  );
});

export default LandingCard;
