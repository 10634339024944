import {observer} from 'mobx-react-lite';
import LocalStorageService from '../../utils/LocalStorageService';
import {useStore} from '../../store';

const PageBottom = observer(() => {
  const {accessStore} = useStore();
  const loggedInUser = LocalStorageService.getUser();

  return !loggedInUser && !accessStore.anonymousAccess ? null : null;
});

export default PageBottom;
