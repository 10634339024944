import {makeAutoObservable} from 'mobx';
import {setAccount, getInterests} from '../../api/account';
import {followItem} from '../../api/course';
import history from '../../history';
import ErrorStore from '../error/errorStore';

type Training = {
  key: number,
  text: string,
  id?: string,
};

class RegStore {
  errorStore: ErrorStore;

  userTraining: Training | null = null;

  prevInstition = null;

  userInstitution = {
    institution_id: '',
    otherinstitution: null,
  };

  userSubspecialty = null;

  userMedicalschool = {
    medicalschool_id: '',
    othermedicalschool: null,
  };

  userResidency = {
    residency_id: '',
    otherresidency: null,
  };

  userReferral = null;

  contentList = [];

  userContent: string[] = [];

  showLaunchScreen = false;

  reloadPage = false;

  constructor({errorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this);
  }

  launchScreenVisibility = (value, reload = false) => {
    this.showLaunchScreen = value;
    this.reloadPage = reload;
  };

  onUserTraining = (training: Training) => {
    this.userTraining = training;
    this.prevInstition = 'training';
  };

  onUserInstitution = async (account, item) => {
    this.userInstitution = {
      institution_id: item?.institutionid,
      otherinstitution: item?.otherinstitution,
    };

    const data = {};
    data.institution_id = item?.institutionid[0]?.id;
    if (this.userTraining) {
      data.training_level = this.userTraining.text;
    }
    data.extra_data = {
      ...account?.extra_data,
      other_institution: item?.otherinstitution,
    };
    data.interest_answered = true;

    try {
      const res = await setAccount(data);
      if (res.data.status) {
        if (account.access?.content_based) {
          history.push({
            pathname: '/purchased',
            state: {
              from: '/register/content',
            },
          });
        } else {
          if (window.innerWidth <= 576) {
            this.launchScreenVisibility(true);
          }
          history.push({
            pathname: '/',
            state: {
              from: '/register/content',
            },
          });
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onUserSubspecialty = (subspecialty: string) => {
    this.userSubspecialty = subspecialty;
  };

  onUserMedicalschool = ({medicalschoolid, othermedicalschool}) => {
    this.userMedicalschool = {
      medicalschool_id: medicalschoolid,
      othermedicalschool,
    };
  };

  onUserResidency = ({residencyid, otherresidency}) => {
    this.userResidency = {
      residency_id: residencyid,
      otherresidency,
    };
  };

  onUserReferral = (referral: string) => {
    this.userReferral = referral;
  };

  onUserContent = (content: string) => {
    if (this.userContent.includes(content)) {
      this.userContent = this.userContent.filter(value => value !== content);
    } else this.userContent.push(content);
  };

  setInterestsObservables = interests => {
    this.contentList = interests;
  };

  listInterests = async () => {
    try {
      const resp = await getInterests();
      const {interests} = resp.data;
      this.setInterestsObservables(interests);
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  onSaveInfo = async (account, specialty) => {
    const data = {};
    data.institution_id = this.userInstitution.institution_id[0]?.id;
    data.subspecialty = this.userSubspecialty;
    if (this.userTraining) {
      data.training_level = this.userTraining.text;
    }
    data.medicalschool_id = this.userMedicalschool.medicalschool_id[0]
      ? this.userMedicalschool.medicalschool_id[0]?.id
      : null;
    data.residency_id = this.userResidency.residency_id[0] ? this.userResidency.residency_id[0].id : null;

    const specialty_ids = [];
    // let otherspecialty = null;
    specialty.forEach(val => {
      specialty_ids.push(val.specialty_id);
      val.subspecialty_id.map(val2 => specialty_ids.push(val2));
      // if (val.otherSpecialty) otherspecialty = val.otherSpecialty;
    });

    data.extra_data = {
      ...account?.extra_data,
      other_institution: this.userInstitution.otherinstitution,
      other_medicalschool: this.userMedicalschool.othermedicalschool,
      other_residency: this.userResidency.otherresidency,
    };

    data.interest_answered = true;
    try {
      const res1 = await setAccount(data);
      const res2 = await followItem(specialty_ids, 'specialty');
      if (res1.data.status && res2.data.status) {
        if (account.access?.content_based) {
          history.push({
            pathname: '/purchased',
            state: {
              from: '/register/content',
            },
          });
        } else {
          if (window.innerWidth <= 576) {
            this.launchScreenVisibility(true);
          }
          history.push({
            pathname: '/',
            state: {
              from: '/register/content',
            },
          });
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default RegStore;
