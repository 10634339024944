import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';
import {Row, Col, Form, Container, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import {
  Breadcrumbs,
  PageTitle,
  SortSelect,
  FormLabel,
  ActiveCrumb,
  Crumb,
  CourseFilter,
  MobileFilter,
  Main,
  Line,
} from './elements/styles';
import ExpertTile from '../../components/ExpertTile';
import {useStore} from '../../store';
import Sort from '../../components/Sort';
import Pagination from '../../components/Pagination';
import Filter from '../../components/Filter';
import PageBottom from '../../components/PageBottom';

const Experts = observer(() => {
  const {expertStore} = useStore();
  const location = useHistory();

  const {
    experts,
    specialtiesList,
    organizationsList,
    selectedOrganizationList,
    selectedSpecialtiesList,
    changeSort,
    selectedOrganizationIds,
    selectedSpecialtyIds,
    sortBy,
    setSelectedOrganizations,
    setSelectedSpecialties,
    listOrganizations,
    listSpecialties,
    listExperts,
    onRemoveOrganization,
    onRemoveSpecialty,
    onUnfollowExpert,
    onFollowExpert,
    isLoading,
    expertsPage,
    expertsCount,
    count,
    pageDecrement,
    pageIncrement,
    changeCount,
    setSearch,
    searchText,
    resetSearch,
  } = expertStore;

  useEffect(() => {
    setSelectedOrganizations([]);
    setSelectedSpecialties([]);
    resetSearch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    listOrganizations();
    listSpecialties();
  }, [listOrganizations, listSpecialties]);

  useEffect(() => {
    listExperts();
  }, [selectedOrganizationIds, selectedSpecialtyIds, sortBy, listExperts, expertsPage, count, searchText]);

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedOrganizations(selectedIDs);
    } else if (type === 2) {
      setSelectedSpecialties(selectedIDs);
    }
  };

  const onHandleFollow = item => {
    const user = localStorage.getItem('user');
    if (user) {
      if (item.follow.length) {
        onUnfollowExpert(item);
      } else {
        onFollowExpert(item.id);
      }
    } else {
      location.push('/login');
    }
  };

  const removeOrganization = item => {
    onRemoveOrganization(item.id);
  };

  const removeSpecialty = item => {
    onRemoveSpecialty(item.id);
  };

  const clearFunc = () => {
    selectOptions([], 0);
    selectOptions([], 1);
    selectOptions([], 2);
    resetSearch();
  };

  const sortValues = [
    {text: 'Name (A-Z)', id: 'ASC', order: 'ASC', sort: 'title'},
    {text: 'Name (Z-A)', id: 'DESC', order: 'DESC', sort: 'title'},
  ];

  const search = {
    submitSearch: setSearch,
    searchText,
    resetSearch,
  };

  const organization = {
    selectedOrganizationList,
    selectedOrganizations: selectedOrganizationIds,
    organizationFilter: organizationsList,
    removeOrganization,
  };

  const specialties = {
    selectedSpecialtiesList,
    selectedSpecialties: selectedSpecialtyIds,
    specialityFilter: specialtiesList,
    removeSpecialty,
  };

  return (
    <div className="d-flex">
      <Helmet
        title="GIBLIB | Experts"
        meta={[
          {
            name: 'description',
            content: 'Learn from the top subject matter experts from the leading academic medical institutions.',
          },
          {
            property: 'og:title',
            content: 'GIBLIB | Experts',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <Sidebar />
      <div className="w-100">
        <Navbar />
        <Main>
          <div>
            <div className="d-flex container">
              <Breadcrumbs>
                <Crumb href="/home">Home</Crumb>
                <ActiveCrumb href="#">Experts</ActiveCrumb>
              </Breadcrumbs>
            </div>

            <Container>
              <div className="mt-2">
                <Row>
                  <PageTitle>Experts</PageTitle>
                </Row>
              </div>
              <hr />
              <MobileFilter>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} sm={3} controlId="sort">
                      <Line />
                      <div className="d-flex mobileSort">
                        <FormLabel className="mt-3 mr-2">Sort</FormLabel>
                        <SortSelect onChange={e => changeSort(e.target.value)}>
                          <option value="ASC">Name(A-Z)</option>
                          <option value="DESC">Name(Z-A)</option>
                        </SortSelect>
                      </div>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </MobileFilter>
              <div className="d-flex mt-2 flex-wrap" id="filter-chip-wrap">
                <Filter
                  count={expertsCount}
                  organization={organization}
                  selectOptions={selectOptions}
                  specialties={specialties}
                  search={search}
                  clearFunc={clearFunc}
                />
                <CourseFilter id="expert-filter-container">
                  <div className="d-flex">
                    <Form.Group as={Col} controlId="sort">
                      <Sort change={changeSort} sort={sortValues} sortOrder={sortBy} sortBy="title" />
                    </Form.Group>
                    <Form.Group>
                      <Pagination
                        totalCount={expertsCount}
                        page={expertsPage}
                        countPerPage={count}
                        pageIncrement={pageIncrement}
                        pageDecrement={pageDecrement}
                        changeCount={changeCount}
                      />
                    </Form.Group>
                  </div>
                </CourseFilter>
              </div>
              <Row className="mt-3" id="expert-container">
                {experts?.map(el => (
                  <Col key={el.id} xs={6} md={4} lg={3} xl={3}>
                    <ExpertTile expert={el} type="expert" onFollow={onHandleFollow} storeName="expertStore" />
                  </Col>
                ))}
                {!experts?.length && !isLoading && <p className="px-3 pt-5">No results</p>}
              </Row>
              {!experts?.length && isLoading && (
                <div className="text-center my-3 mt-5 pt-5">
                  <Spinner animation="grow" className="mt-5" />
                </div>
              )}
            </Container>
          </div>
          {experts?.length && isLoading ? (
            <div className="text-center my-3">
              <Spinner animation="grow" />
            </div>
          ) : (
            <></>
          )}
          {!isLoading && <Footer />}
        </Main>
      </div>
      <PageBottom />
    </div>
  );
});

export default Experts;
