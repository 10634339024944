import styled from 'styled-components';

export const ForgotPasswordModal = styled.div`
  .forgot-password-card {
    height: auto;
    max-width: 336px;
    background: #181a21;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    width: 100%;
  }
  .card-body {
    padding: 24px;
  }
  .card-title {
    margin-bottom: 22px;
  }
  .send-success {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    width: 233px;
    margin: auto;
  }
  .card-link {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-top: 14px;
    text-decoration: underline;

    &.back-to-login {
      height: 40px;
      width: 100%;
      background: #008aff;
      border-radius: 3px;
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      margin: auto;
      margin-top: 24px;
      text-decoration: none;
      padding: 10px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
  .form-control {
    height: 36px;
    width: 100%;
    margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    background: none;
    color: #fff;

    &::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
    }

    &:focus,
    &.active-textbox {
      box-shadow: none;
      border-color: #008aff;
    }

    &.error-email {
      border-color: #ff5964;
    }
  }
  h5 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.4px;
    text-align: center;
    margin-bottom: 8px;
  }
  .forgot-password-description {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.15px;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 22px;
    width: 248px;
  }
  .form-label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 1px;
  }
`;

export const Logo = styled.img`
  filter: invert(1);
  margin: 25px;
`;

export const SendEmail = styled.button`
  height: 40px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin: auto;
  border-style: hidden;
  margin-top: 8px;
  margin-bottom: 8px;

  &.button-active {
    background: #008aff;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
`;

export const ForgotPasswordContainer = styled.div`
  background-color: #050507;
  min-height: 100vh !important;
  width: 100vw;
  margin: auto;
  font-family: Roboto;

  & .nav {
    background-color: #181a21;
    padding: 13px 24px;
    width: 100vw;
    margin-bottom: 40px;
  }
`;
