import React from 'react';
import {observer} from 'mobx-react-lite';
import {Col, Container, Form, FormCheck, Button} from 'react-bootstrap';
import Dropdown from '../../../components/Multiselect';
import {useStore} from '../../../store';
import {SortSelect, DeskFilter, MobFilter} from '../elements/conferences';

type Props = {
  professionFilter: Array,
  specialityFilter: Array,
};
// Filters
const Filter = observer(({professionFilter, specialityFilter}: Props) => {
  const {conferenceStore} = useStore();
  const {
    selectedProfessions,
    selectedSpecialties,
    setSelectedProfessions,
    setSelectedSpecialities,
    setSort,
    setCME,
  } = conferenceStore;

  const selectOptions = (selectedIDs, type) => {
    if (type === 1) {
      setSelectedProfessions(selectedIDs);
    } else {
      setSelectedSpecialities(selectedIDs);
    }
  };

  const clearFunc = () => {
    selectOptions([], 1);
    selectOptions([], 0);
    setCME();
  };

  return (
    <Container>
      <DeskFilter>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="filterProfession">
              <div className="d-flex">
                <Form.Label className="mr-2">Filter</Form.Label>
                <Dropdown
                  title="Profession"
                  values={professionFilter}
                  selectedIds={selectedProfessions}
                  id="filterProfession"
                  onSelectOptions={selectedIDs => selectOptions(selectedIDs, 1)}
                  size="sm"
                />
              </div>
            </Form.Group>
            <Form.Group as={Col} controlId="filterSpecialty">
              <div className="d-flex">
                <Dropdown
                  title="Specialty"
                  values={specialityFilter}
                  selectedIds={selectedSpecialties}
                  id="filterSpecialty"
                  onSelectOptions={selectedIDs => selectOptions(selectedIDs, 2)}
                  size="sm"
                />
              </div>
            </Form.Group>
            <Form.Group as={Col} sm={1} controlId="cme">
              <div className="d-flex">
                <Form.Label className="mr-1">CME</Form.Label>
                <FormCheck id="switchEnabled" type="switch" onClick={e => setCME(e.target.checked)} />
              </div>
            </Form.Group>
            <Form.Group as={Col}>
              <Button onClick={clearFunc} className="clear-btn" type="reset">
                Clear Filters
              </Button>
            </Form.Group>
            <Form.Group as={Col} sm={3} controlId="sort">
              <div className="d-flex">
                <Form.Label style={{whiteSpace: 'nowrap'}} className="mr-2">
                  Sort
                </Form.Label>
                <SortSelect defaultValue="Date Added (Newest)" onChange={e => setSort(e.target.value)}>
                  <option value="Most Popular">Most Popular</option>
                  <option value="Date Added (Newest)">Date Added (Newest)</option>
                  <option value="Date Added (Oldest)">Date Added (Oldest)</option>
                  <option value="# of Hrs Descending"># of Hrs Descending</option>
                  <option value="# of Hrs Ascending"># of Hrs Ascending</option>
                </SortSelect>
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
      </DeskFilter>
      <MobFilter>
        <Form>
          <Form.Row>
            <Form.Group as={Col} sm={1} controlId="filter">
              <div className="d-inline-flex">
                <Form.Label style={{whiteSpace: 'nowrap'}} className="mr-2">
                  Filter
                </Form.Label>
                <Dropdown
                  title="Profession"
                  values={professionFilter}
                  selectedIds={selectedProfessions}
                  id="filterProfession"
                  onSelectOptions={selectedIDs => selectOptions(selectedIDs, 1)}
                />
                <Button onClick={clearFunc} className="clear-btn" type="reset">
                  Clear Filters
                </Button>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={1} controlId="filterSpecialty">
              <div className="d-flex" style={{marginLeft: '55px'}}>
                <Dropdown
                  title="Specialty"
                  values={specialityFilter}
                  selectedIds={selectedSpecialties}
                  id="filterSpecialty"
                  onSelectOptions={selectedIDs => selectOptions(selectedIDs, 2)}
                />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={1} controlId="cme">
              <div className="d-flex" style={{marginLeft: '75px'}}>
                <Form.Label className="mr-2">CME</Form.Label>
                <FormCheck id="cme" type="switch" onChange={e => setCME(e.target.checked)} />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm={3} controlId="sort">
              <div className="d-flex">
                <Form.Label style={{whiteSpace: 'nowrap'}} className="mr-2">
                  Sort
                </Form.Label>
                <SortSelect onChange={e => setSort(e.target.value)}>
                  <option value="Most Popular">Most Popular</option>
                  <option value="Date Added (Newest)">Date Added (Newest)</option>
                  <option value="Date Added (Oldest)">Date Added (Oldest)</option>
                  <option value="# of Hrs Descending"># of Hrs Descending</option>
                  <option value="# of Hrs Ascending"># of Hrs Ascending</option>
                </SortSelect>
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
      </MobFilter>
    </Container>
  );
});

export default Filter;
