import React from 'react';
import {Button} from 'react-bootstrap';
import DarkModal from '../elements/DarkModal';

type Props = {
  children: React.Node,
  onHide: () => {},
  text: sting,
  subText: sting,
  icon: string,
  show: boolean,
  [key: string]: string,
};
const ComingSoonModal = ({onHide, icon, show, text, subText, ...otherProps}: Props) => {
  return (
    <DarkModal show={show} {...otherProps} onHide={onHide} centered>
      {/* <DarkModal.Header closeButton /> */}
      <DarkModal.Body>
        <div className="d-flex flex-column justify-content-center align-items-center">
          {icon && (
            <div className="icon-wrapper d-flex align-items-center justify-content-center">
              <img src={icon} alt="TBD-icon" className="icon-img" />
            </div>
          )}
          <h5 className="tbd-text mb-2">{text || 'Coming Soon'}</h5>
          {subText && <p className="tbd-sub-text mb-3">{subText}</p>}
          <Button
            type="submit"
            variant="primary"
            data-testid="tbdModalClose"
            id="close-tbd-modal"
            className="a_closeTbdModal py-1"
            onClick={onHide}
          >
            Close
          </Button>
        </div>
      </DarkModal.Body>
    </DarkModal>
  );
};

export default ComingSoonModal;
