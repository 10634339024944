import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {Modal, Button} from 'react-bootstrap';
import cmeIcon from '../../assets/icons/icon_CME.svg';

const ModalContainer = styled(Modal)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  .modal-dialog {
    max-width: 459px !important;
    min-height: 225px !important;
    margin: 7.75rem auto !important;
  }
  .modal-content {
    border: none !important;
    background-color: ${({theme}) => (theme.name === 'light' ? '#EDEDED' : '#2e3038')};
    border-radius: 8px;
    height: 273px;
    width: 375px;
    border-radius: 8px;
    margin-left: 3rem !important;

    .close {
      color: white;
    }
    .a_iconDiv {
      height: 50px;
      width: 50px;
      background-color: #e04463;
      border-radius: 30px;
      margin-bottom: 9px;
    }
    .fa-sticky-note {
      color: #fff;
    }
    .a_upgradeModalHeader {
      width: 179.52px;
      color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#dcdee1')};
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 2px;
    }
    .a_upgradeModalContent {
      width: 276px;
      color: ${({theme}) => (theme.name === 'light' ? '#4E505A' : '#dcdee1')};
      font-size: 15px;
      letter-spacing: -0.1px;
      line-height: 20px;
      text-align: center;
    }
    .modal-header {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.3px;
      line-height: 24px;
      border: none !important;
      padding: 1rem 1rem 0;
    }
    .modal-body {
      padding: 14px;
    }
    .modal-footer {
      border: none !important;
    }
    .a_upgradeButton {
      height: 32px;
      width: 105px;
    }
  }
  &.paymentInfoModal .modal-dialog {
    max-width: 636px;
  }
`;

type Props = {
  access: string,
  isShow: Boolean,
  onHideModal: Function,
};

const UpgradeModal = ({access, isShow, onHideModal}: Props) => {
  const location = useHistory();
  const [icon, setIcon] = useState('');

  useEffect(() => {
    if (access === 'note') setIcon('fas fa-sticky-note');
    else if (access === 'bookmark') setIcon('fal fa-bookmark');
    else if (access === 'playlist') setIcon('fak fa-list-ul');
    else if (access === 'likes') setIcon('fa-thumbs-up fas');
  }, [access]);

  const onClickUpgradeButton = () => {
    if (access) location.push(`/profile/settings`);
    else {
      location.push('/register/profession');
      onHideModal();
    }
  };

  return (
    <ModalContainer show={isShow}>
      <ModalContainer.Header closeButton onHide={onHideModal} />
      <ModalContainer.Body>
        <div className="d-flex flex-column justify-content-center align-items-center">
          {access ? (
            <>
              <div className="a_iconDiv d-flex align-items-center justify-content-center">
                {access === 'cme' ? <img src={cmeIcon} alt="CME" className="a_cmeIcon" /> : <i className={icon} />}
              </div>
              <p className="a_upgradeModalHeader">Upgrade Today</p>
              <p className="a_upgradeModalContent">
                {access === 'cme'
                  ? 'Upgrade to Premium plan to unlock this feature.'
                  : 'Upgrade to Standard or Premium plan to unlock this feature.'}
              </p>
            </>
          ) : (
            <p className="text-center mb-5">
              We have expanded our specialty and subspecialty selection in order to provide you with the best GIBLIB
              experience. Please update your information on the next page.
            </p>
          )}
          <Button
            type="submit"
            variant="primary"
            id="close-upgrade-info"
            className="a_upgradeButton py-0"
            onClick={() => onClickUpgradeButton()}
          >
            {access ? 'Upgrade' : 'Continue'}
          </Button>
        </div>
      </ModalContainer.Body>
    </ModalContainer>
  );
};

export default UpgradeModal;
