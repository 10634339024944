/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import {Card, Table} from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useStore} from '../../../store';
import * as Api from '../../../api/subscription';
import {Wrapper, SectionTitle} from '../style';
import {Title1, SubTitle2, Body2} from '../../../utils/styles/typography';
import {amplitude} from '../../../utils/Amplitude';
import type {PromoCode} from '../../../utils/types';
import type {Account} from '../../../store/account/accountStore';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
type Props = {
  promoCode: PromoCode,
  usecase: string,
};

type AmountDueProps = {
  account: Account,
  couponSkipTrial: Boolean,
  totalPrice: number,
};

const AmountDue = ({account, couponSkipTrial, totalPrice}: AmountDueProps) => {
  if (!!account?.id && !account?.is_trial_eligible) {
    return (
      <>
        <tr>
          <td colSpan={2}>
            <Title1 className="text-left">Total Due Today</Title1>
          </td>
          <td className="mb-0 text-right">
            <Title1 className="mb-0">
              <span>${totalPrice}</span>
            </Title1>
          </td>
        </tr>
      </>
    );
  }

  if (couponSkipTrial)
    return (
      <>
        <tr>
          <td colSpan={2}>
            <Title1 className="text-left">Total Due Today</Title1>
          </td>
          <td className="mb-0 text-right">
            <Title1 className="mb-0">
              <span>${totalPrice}</span>
            </Title1>
          </td>
        </tr>
      </>
    );
  return (
    <>
      <tr>
        <td colSpan={2}>
          <Title1 className="mb-0 text-left">Total Due Today</Title1>
        </td>
        <th className="text-right">
          <Title1 className="mb-0">${totalPrice}</Title1>
        </th>
      </tr>
    </>
  );
};

const OrderSummary = observer(({promoCode, usecase}: Props) => {
  const location = useHistory();
  const [dueDate, setDueDate] = useState('');
  const [couponSkipTrial, setCouponSkipTrial] = useState(false);
  const [couponTrialDays, setCouponTrialDays] = useState(0);
  const [totalPrice, setTotalPrice] = useState(null);

  const {authStore, subscriptionStore, accountStore, registrationUIStore} = useStore();
  const {updateSubscriptionCompleted, setIsSkipTrial, setPromoCodeObservables, getGiftCardPrices} = subscriptionStore;
  const {user, selectedPrice, selectedProduct, isSelectGiftCard, onSelectGiftCard, selectedGiftCard} = authStore;
  const {account} = accountStore;
  const {currentCampaign} = registrationUIStore;

  const giftCardPrices = getGiftCardPrices(selectedPrice);
  let amazonGiftCard;
  if (giftCardPrices.length) {
    amazonGiftCard = giftCardPrices.find(price => price.nickname?.toLowerCase()?.includes('amazon'));
  }

  useEffect(() => {
    if (!selectedGiftCard && amazonGiftCard) {
      onSelectGiftCard(amazonGiftCard);
    }
  }, [amazonGiftCard, onSelectGiftCard, selectedGiftCard]);

  const toggleSkipTrial = () => {
    const eventProperties = {
      location: 'payment page',
    };
    amplitude.getInstance().logEvent('skipped-trial', eventProperties);
  };

  useEffect(() => {
    if (usecase !== 'registration' && updateSubscriptionCompleted) {
      location.push('/profile/settings');
    }
  }, [updateSubscriptionCompleted, location, usecase]);

  useEffect(() => {
    if (account?.subscription?.trial_end) {
      const trial_end = new Date(account?.subscription?.trial_end);
      const year = trial_end.getFullYear();
      const mon = trial_end.getMonth() + 1;
      let day = trial_end.getDate().toString();
      day = day.length > 1 ? day : `0${day}`;
      setDueDate(`${mon}/${day}/${year}`);
      return;
    }
    const todayDate = new Date();
    let endDate = new Date();
    if (couponTrialDays === 0) {
      endDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + 3);
    } else {
      endDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + couponTrialDays - 1);
    }
    const year = endDate.getFullYear();
    const mon = endDate.getMonth() + 1;
    let day = endDate.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;
    setDueDate(`${mon}/${day}/${year}`);
  }, [account?.subscription?.trial_end, couponTrialDays]);

  const onCouponActivation = async coupon => {
    try {
      const response = await Api.checkPromoCode(coupon, selectedPrice.metadata.id, user.id || account.id);
      if (response.data.couponHistory.length > 0) {
        const couponId = response.data.dbCoupon.id;
        if (response.data.couponHistory.filter(v => v.coupon_id === couponId).length > 0) {
          setPromoCodeObservables(null);
          return;
        }
      }
      let data = null;
      if (response?.data?.isCoupon) {
        data = {
          isJustCoupon: true,
          coupon: response?.data?.coupon || response?.data?.dbCoupon,
        };
      } else {
        data = response?.data?.promoCode;
      }

      if (response?.data?.dbCoupon?.trial_period_days === 0) {
        setCouponSkipTrial(true);
        setIsSkipTrial(true);
      } else {
        setCouponTrialDays(response?.data?.dbCoupon?.trial_period_days);
      }
      setPromoCodeObservables(data);
    } catch (error) {
      setPromoCodeObservables(null);
    }
  };

  // Insert coupon automatically if coming from campaign
  useEffect(() => {
    if (currentCampaign && currentCampaign.coupon) {
      onCouponActivation(currentCampaign.coupon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaign]);

  useEffect(() => {
    const storePromoCode = promoCode;
    if (isSelectGiftCard) {
      setTotalPrice(selectedGiftCard?.unit_amount / 100 + selectedPrice?.unit_amount / 100);
      return;
    }
    if (storePromoCode?.coupon && storePromoCode?.coupon?.percent_off) {
      setTotalPrice(((selectedPrice?.unit_amount / 100) * (1 - storePromoCode?.coupon.percent_off / 100)).toFixed(2));
      return;
    }
    if (storePromoCode?.coupon && storePromoCode?.coupon?.amount_off) {
      setTotalPrice(
        (selectedPrice?.unit_amount / 100 - (storePromoCode?.coupon.amount_off / 100).toFixed(2)).toFixed(2),
      );
      return;
    }
    setTotalPrice((selectedPrice?.unit_amount / 100).toFixed(2));
  }, [isSelectGiftCard, selectedGiftCard, selectedPrice, promoCode]);

  if (totalPrice === null) {
    return <></>;
  }

  return (
    <Wrapper>
      <Card text="white" className="card-payment-summary">
        <Card.Body>
          <Title1 className="text-left">Order Summary</Title1>
          <Table borderless className="text-white">
            <thead />
            <tbody>
              <tr>
                <td colSpan={2} className="pt-2 pb-2">
                  <SectionTitle className="text-left">BILLING CYCLE</SectionTitle>
                </td>
                <td className="pt-2 pb-2">
                  <SubTitle2 className="text-right">
                    {selectedPrice?.recurring?.interval_count} {selectedPrice?.recurring?.interval}
                  </SubTitle2>
                </td>
              </tr>
              <tr>
                <td colSpan={3} rowSpan={3} className="pt-2">
                  <Body2 className="card-descript">
                    <SectionTitle className="text-left">PLAN</SectionTitle>
                    <SectionTitle className="text-right">COST</SectionTitle>
                  </Body2>
                  <Body2 className="card-descript">
                    <SubTitle2 className="text-left">{selectedPrice?.nickname}</SubTitle2>
                    <SubTitle2 className="text-right">
                      <span>$ {totalPrice}</span>
                    </SubTitle2>
                  </Body2>
                  <Body2 className="card-descript card-descript-cme mb-1">
                    {selectedProduct && selectedProduct.name.includes('Premium') ? (
                      <div>
                        <span>
                          <i className="fas fa-check" />
                        </span>
                        <span>This plan includes CME.</span>
                      </div>
                    ) : (
                      <span className="cme-false">
                        <i className="fas fa-times" />
                        This plan does not include CME.
                      </span>
                    )}
                  </Body2>
                  {isSelectGiftCard ? (
                    <Body2 className="card-descript card-descript-cme">
                      <span>
                        <i className="fas fa-check" />${Math.floor(amazonGiftCard?.unit_amount / 100)} Amazon or Visa
                        Gift Card
                      </span>
                    </Body2>
                  ) : null}
                </td>
              </tr>
            </tbody>

            <tfoot className="total-due">
              <AmountDue
                account={account}
                couponSkipTrial={couponSkipTrial}
                toggleSkipTrial={toggleSkipTrial}
                totalPrice={totalPrice}
                dueDate={dueDate}
              />
            </tfoot>
          </Table>
          <div className="mt-4 d-block payment-warning">
            By making a payment, you agree to enroll in our subscription plan and to our&nbsp;
            <span className="text-primary">Terms of Service</span>. Your payment method will be charged the price above.
            Cancel any time in Account Settings.
          </div>
        </Card.Body>
      </Card>
    </Wrapper>
  );
});

OrderSummary.propTypes = {
  usecase: PropTypes.string,
};

OrderSummary.defaultProps = {
  usecase: 'registration',
};

export default props => (
  <Elements stripe={stripePromise}>
    <OrderSummary {...props} />
  </Elements>
);
