import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Image} from 'react-bootstrap';
import {Header} from './elements/style';
import BookmarkIcon from '../../assets/icons/new-bookmark.svg';
import HistoryIcon from '../../assets/icons/History.svg';
import LikedIcon from '../../assets/icons/Likes.svg';
import {Title1} from '../../utils/styles/typography';
import HistoryTab from '../HistoryTab';

const HistoryTop = observer(({type}: String) => {
  const [showIcon, setIcon] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (type === 'Bookmarks') setIcon(BookmarkIcon);
    else if (type === 'History') setIcon(HistoryIcon);
    else setIcon(LikedIcon);
  }, [type]);

  return (
    <Header>
      <div className="d-flex container align-items-center title-div">
        <Image src={showIcon} width={24} />
        <Title1 className="title">{type}</Title1>
      </div>
      <HistoryTab title={type} />
    </Header>
  );
});

export default HistoryTop;
