class LocalStorageService {
  static setToken = tokenObj => {
    localStorage.setItem('access_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  };

  static getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

  static getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
  };

  static clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  };

  static getUser = () => {
    const loggedUser = localStorage.getItem('user');
    return loggedUser ? JSON.parse(loggedUser) : null;
  };

  static generateStateToken = (useExisting = true) => {
    if (useExisting && this.getStateToken()) {
      return this.getStateToken();
    }
    const state = Math.random().toString(36).slice(2);
    localStorage.setItem('oauth_state', state);
    return state;
  };

  static getStateToken = () => {
    return localStorage.getItem('oauth_state');
  };

  static clearStateToken = () => {
    return localStorage.removeItem('oauth_state');
  };
}
export default LocalStorageService;
