import {action, makeObservable, observable, runInAction} from 'mobx';
import {getCourse, getCourseVideos, getRelatedCourses} from '../../api/courseLanding';
import {createBookmark, deleteBookmark, followItem, unfollowItem} from '../../api/course';
import {getAllPlaylists, addToPlaylist, massUpdatePlaylists} from '../../api/video';
import {addCoursePlaylist, addNewCoursePlaylist} from '../../api/playlist';
import ErrorStore from '../error/errorStore';
import MediaStore from '../media/mediaStore';
import AlertStore from '../alert/alertStore';
import PlaylistStore from '../playlist/playlistStore';
import {amplitude} from '../../utils/Amplitude';

class courseLandingStore {
  errorStore: ErrorStore;

  mediaStore: MediaStore;

  alertStore: AlertStore;

  playlistStore: PlaylistStore;

  course: Array = [];

  courseVideos: Array = [];

  relatedCourses: Array = [];

  playlist: Array = [];

  constructor({errorStore, mediaStore, alertStore, playlistStore}) {
    this.errorStore = errorStore;
    this.mediaStore = mediaStore;
    this.alertStore = alertStore;
    this.playlistStore = playlistStore;
    makeObservable(this, {
      course: observable,
      courseVideos: observable,
      relatedCourses: observable,
      playlist: observable,
      onCreateBookmarkCourse: action,
      onDeleteBookmarkCourse: action,
      onFollowCourse: action,
      onUnfollowCourse: action,
      onBookmarkCourseVideo: action,
      onDeleteBookmarkCourseVideo: action,
      onBookmarkRelatedCourses: action,
      onDeleteBookmarkRelatedCourses: action,
      onFollowRelatedCourses: action,
      onUnfollowRelatedCourses: action,
      setPlaylistObservables: action,
      getAllPlaylists: action,
      addToNewPlaylist: action,
      addToPlaylist: action,
      addCourseToPlaylist: action,
      addCourseToNewPlaylist: action,
    });
  }

  setPlaylistObservables = playlist => {
    this.playlist = playlist;
    this.playlistStore.setPlaylist(playlist);
  };

  getAllPlaylists = async () => {
    try {
      const res = await getAllPlaylists();
      if (res.data.status) {
        this.setPlaylistObservables(res.data.playlist);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addToNewPlaylist = async (videoId: number, name: string) => {
    amplitude.getInstance().logEvent('playlist-created');
    const updatedVideos = [...this.courseVideos];
    try {
      const res = await addToPlaylist(videoId, name);
      const {status} = res.data;
      if (status) {
        this.alertStore.setMessage('Playlist created successfully', 'success');
        const index = this.courseVideos.findIndex(video => video.video_id === videoId);
        updatedVideos[index].video.playlist.push(res.data.playlist);
        this.setCourseVideosObservables(updatedVideos);
        this.getAllPlaylists();
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addToPlaylist = async (videoId: number, data: [{}]) => {
    const updatedVideos = [...this.courseVideos];
    try {
      const res = await massUpdatePlaylists(videoId, data);
      const {status} = res.data;
      if (status) {
        const index = this.courseVideos.findIndex(video => +video.video_id === +videoId);
        updatedVideos[index].video.playlist = res.data.playlist;
        this.setCourseVideosObservables(updatedVideos);
        this.getAllPlaylists();
        this.alertStore.setMessage('Playlist updated successfully', 'success');
      } else {
        const {message} = res.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addCourseToPlaylist = async (courseId, data) => {
    try {
      const resp = await addCoursePlaylist(courseId, data);
      const {status} = resp.data;
      if (status) {
        this.getAllPlaylists();
        const respVideo = resp.data.videos;
        this.courseVideos.map(cVideo => {
          const item = respVideo.find(el => el.id === cVideo.id);
          if (item) {
            cVideo.video.playlist = item.video.playlist; // eslint-disable-line no-param-reassign
          }
          return cVideo;
        });
        this.alertStore.setMessage('Playlist updated successfully', 'success');
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addCourseToNewPlaylist = async (courseId, name) => {
    try {
      const resp = await addNewCoursePlaylist(courseId, name);
      const {status} = resp.data;
      if (status) {
        this.getAllPlaylists();
        const respVideo = resp.data.videos;
        this.courseVideos.map(cVideo => {
          const item = respVideo.find(el => el.id === cVideo.id);
          if (item) {
            cVideo.video.playlist = item.video.playlist; // eslint-disable-line no-param-reassign
          }
          return cVideo;
        });
        this.alertStore.setMessage('Playlist created successfully', 'success');
      } else {
        const {message} = resp.data;
        this.alertStore.setMessage(message, 'failure');
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getCourse = async id => {
    try {
      const resp = await getCourse(id);
      const {status} = resp.data;
      if (status) {
        runInAction(() => {
          this.course = resp.data.course;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  getCourseData = async (id, cb: () => {}) => {
    try {
      const [courseResp, courseVideoResp, relatedCourseResp] = await Promise.all([
        getCourse(id),
        getCourseVideos(id),
        getRelatedCourses(id),
      ]);
      let course = {};
      if (courseResp?.data) {
        course = courseResp?.data?.course;
      }
      let {videos} = courseVideoResp?.data;
      videos = videos.map(item => {
        const newVideo = {...item};
        if (!item.video?.watchHistories) {
          newVideo.video.watchProgress = 0;
        } else {
          const watchTime = Math.max(...item.video?.watchHistories.map(o => o.time_watched), 0);
          const videoDuration = item.video?.duration || 0;
          const watchProgress = (watchTime / videoDuration) * 100;
          newVideo.video.watchProgress = Math.round(watchProgress);
        }
        return newVideo;
      });
      const {courses} = relatedCourseResp?.data;
      runInAction(() => {
        this.course = course;
        this.setCourseVideosObservables(videos);
        this.setRelatedCoursesObservables(courses);
        if (cb) cb(course?.title);
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * set course video list observables
   * @param {array} professions
   */
  setCourseVideosObservables = videos => {
    this.courseVideos = videos;
  };

  /**
   * set course video list observables
   * @param {array} professions
   */
  setRelatedCoursesObservables = courses => {
    this.relatedCourses = courses;
  };

  /** Create new bookmark for course */
  onCreateBookmarkCourse = async courseId => {
    try {
      const resp = await createBookmark(courseId, 'course');
      const {status, bookmark} = resp.data;
      const updatesCourse = {...this.course};
      if (status) {
        runInAction(() => {
          updatesCourse.bookmark = [bookmark];
          this.course = updatesCourse;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onDeleteBookmarkCourse = async selectedCourseId => {
    const updatesCourse = {...this.course};
    try {
      const resp = await deleteBookmark(selectedCourseId, 'course');
      const {status} = resp.data;
      if (status) {
        runInAction(() => {
          delete updatesCourse.bookmark;
          this.course = updatesCourse;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Follow course */
  onFollowCourse = async courseId => {
    const updatesCourse = {...this.course};
    try {
      const resp = await followItem(courseId, 'course');
      const {status, follow} = resp.data;
      if (status) {
        runInAction(() => {
          this.mediaStore.updateCarouselFollow(courseId, true, 'courses');
          updatesCourse.follow = [follow];
          this.course = updatesCourse;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Unfollow course */
  onUnfollowCourse = async selectedCourseId => {
    const updatesCourse = {...this.course};
    try {
      const resp = await unfollowItem(selectedCourseId, 'course');
      const {status} = resp.data;
      if (status) {
        runInAction(() => {
          this.mediaStore.updateCarouselFollow(selectedCourseId, false, 'courses');
          delete updatesCourse.follow;
          this.course = updatesCourse;
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create new bookmark for course video */
  onBookmarkCourseVideo = async videoId => {
    const updatedVideos = [...this.courseVideos];
    try {
      const resp = await createBookmark(videoId, 'video');
      const {status, bookmark} = resp.data;
      if (status) {
        const index = updatedVideos.findIndex(video => String(video.video_id) === String(videoId));
        if (index !== -1) {
          updatedVideos[index].video.bookmark = bookmark;
          this.setCourseVideosObservables(updatedVideos);
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark of course video */
  onDeleteBookmarkCourseVideo = async videoId => {
    const updatedVideos = [...this.courseVideos];
    try {
      const resp = await deleteBookmark(videoId, 'video');
      const {status} = resp.data;
      if (status) {
        const index = updatedVideos.findIndex(video => String(video.video_id) === String(videoId));
        if (index !== -1) {
          delete updatedVideos[index].video.bookmark;
          this.setCourseVideosObservables(updatedVideos);
        }
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create new bookmark for course */
  onBookmarkRelatedCourses = async courseId => {
    const updatesCourses = [...this.relatedCourses];
    try {
      const resp = await createBookmark(courseId, 'course');
      const {status, bookmark} = resp.data;
      if (status) {
        const index = updatesCourses.findIndex(course => course.id === courseId);
        updatesCourses[index].bookmark = [bookmark];
        this.setRelatedCoursesObservables(updatesCourses);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onDeleteBookmarkRelatedCourses = async courseId => {
    const updatesCourses = [...this.relatedCourses];
    try {
      const resp = await deleteBookmark(courseId, 'course');
      const {status} = resp.data;
      if (status) {
        const index = updatesCourses.findIndex(uCourse => uCourse.id === courseId);
        delete updatesCourses[index].bookmark;
        this.setRelatedCoursesObservables(updatesCourses);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Create new bookmark for course */
  onFollowRelatedCourses = async courseId => {
    const updatesCourses = [...this.relatedCourses];
    try {
      const resp = await followItem(courseId, 'course');
      const {status, follow} = resp.data;
      if (status) {
        const index = updatesCourses.findIndex(course => course.id === courseId);
        updatesCourses[index].follow = [follow];
        this.setRelatedCoursesObservables(updatesCourses);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Delete bookmark */
  onUnfollowRelatedCourses = async courseId => {
    const updatesCourses = [...this.relatedCourses];
    try {
      const resp = await unfollowItem(courseId, 'course');
      const {status} = resp.data;
      if (status) {
        const index = updatesCourses.findIndex(uCourse => uCourse.id === courseId);
        delete updatesCourses[index].follow;
        this.setRelatedCoursesObservables(updatesCourses);
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default courseLandingStore;
