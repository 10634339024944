import io from 'socket.io-client';
import LocalStorageService from './utils/LocalStorageService';

const baseURL = process.env.REACT_APP_WEBSOCKET_ENDPOINT;
class SocketClient {
  socket;

  constructor() {
    this.socket = io(baseURL, {
      query: `token=${LocalStorageService.getAccessToken()}`,
      transports: ['websocket'],
    });
    this.socket.on('unauthorized', msg => {
      // eslint-disable-next-line no-console
      console.log(msg.data.type);
    });
  }

  static Instance = new this();

  connect = async () => {
    await this.socket.disconnect();
    this.socket = io(baseURL, {
      query: `token=${LocalStorageService.getAccessToken()}`,
      transports: ['websocket'],
    });
  };

  disconnect = () => {
    this.socket.disconnect();
  };
}

export default SocketClient.Instance;
