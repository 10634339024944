import styled from 'styled-components';

const PaginationContainer = styled.div`
  margin-left: 16px;
  margin-top: 5px;
  .a_sort,
  .a_profession {
    background: transparent;
    padding: 5px 30px 5px 16px;
    border-radius: 3px;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    border: ${({theme}) => (theme.body === '#FFF' ? '1px solid #dcdee1' : '1px solid #4e505a')};
    color: #fff;
    width: 190px;
    text-align: left;

    ::after {
      right: 8px;
      position: absolute;
      top: calc(50% - 0.25em);
      border-top: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-bottom: 0;
      border-left: 0.5em solid transparent;
    }
    ~ .dropdown-menu {
      width: 190px;
    }
  }
  .a_profession {
    width: 100%;
    padding: 8px 30px 8px 16px;

    ~ .dropdown-menu {
      width: 100%;
    }
  }
  text-align: right;
  position: relative;
  label {
    width: 100%;
    padding: 8px 0;
  }
  .form-check-input {
    display: none;
  }
  .form-check {
    padding-left: 44px;
  }
  input ~ label::after {
    content: '';
    left: 16px;
    bottom: 10px;
    width: 16px;
    height: 16px;
    border: 2px solid #008aff;
    position: absolute;
    display: block;
    border-radius: 50px;
  }
  input:checked ~ label::before {
    position: absolute;
    display: block;
    border-radius: 50px;
    content: '';
    left: 14px;
    bottom: 8px;
    border: 2px solid rgba(0, 138, 255, 0.5);
    width: 20px;
    height: 20px;
  }
  input:checked ~ label {
    color: #008aff;
  }
  input:checked ~ label::after {
    border: 4px solid #008aff;
  }
  .form-check:hover {
    background-color: #4e505a;
  }
  span {
    font-size: 12px;
  }
  .a_limit,
  .a_p_dec,
  .a_p_inc {
    padding: 0;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 16px;
    color: ${({theme}) => (theme.name === 'light' ? '#4e505a' : '#dcdee1')};
    border-radius: 3px;
    margin-left: 8px;
  }
  .a_limit {
    font-size: 12px;
    padding-left: 4px;
    padding-top: 2px;
  }
  .a_limit:hover,
  .a_limit:active,
  .a_limit:focus {
    font-size: 12px;
    background-color: #252730;
    color: #dcdee1;
  }
  .dropdown-menu {
    background-color: #2e3038;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
    width: 140px;
    text-align: left;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.18px;
    line-height: 16px;
    min-width: 7rem;
  }
  .dropdown {
    display: inline-block;
  }
  &.professionDrop .dropdown {
    display: block;
  }
  &.professionDrop {
    margin-left: 0;

    .dropdown-menu {
      position: relative !important;
      transform: none !important;
      border-radius: 8px;
    }
  }
  #limitMenu::after {
    border: none;
  }

  &.sort-mobile {
    display: none;
  }
  @media screen and (max-width: 576px) {
    display: none;

    &.sort-mobile {
      display: inline-block;
      float: right;
    }
    &.professionDrop {
      display: block;
    }
  }
`;

export default PaginationContainer;
