import React from 'react';
import {Form, Dropdown, Button} from 'react-bootstrap';
import PaginationContainer from './style';

type Props = {
  totalCount: String,
  page: String,
  countPerPage: String,
  pageIncrement: Function,
  pageDecrement: Function,
  changeCount: Function,
};

const Pagination = ({totalCount, page, countPerPage, pageIncrement, pageDecrement, changeCount}: Props) => {
  return (
    <PaginationContainer>
      <span>
        {totalCount === 0 ? <>0 </> : <>{page * countPerPage - (countPerPage - 1)} </>}-&nbsp;
        {page * countPerPage > totalCount ? <>{totalCount} </> : <>{page * countPerPage} </>}
        of {totalCount}
      </span>
      <Button onClick={pageDecrement} variant="basic" className="a_p_dec" disabled={page === 1}>
        <i className="far fa-chevron-left" />
      </Button>
      <Button onClick={pageIncrement} variant="basic" className="a_p_inc" disabled={page * countPerPage >= totalCount}>
        <i className="far fa-chevron-right" />
      </Button>
      <Dropdown>
        <Dropdown.Toggle variant="basic" id="limitMenu" className="a_limit">
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu onChange={e => changeCount(e.target.value)}>
          <Form.Check
            type="radio"
            label="10 per page"
            id="limit_10"
            value="10"
            name="count"
            checked={parseInt(countPerPage, 10) === 10}
          />
          <Form.Check
            type="radio"
            label="25 per page"
            id="limit_25"
            value="25"
            name="count"
            checked={parseInt(countPerPage, 10) === 25}
          />
          <Form.Check
            type="radio"
            label="50 per page"
            id="limit_50"
            value="50"
            name="count"
            checked={parseInt(countPerPage, 10) === 50}
          />
          <Form.Check
            type="radio"
            label="100 per page"
            id="limit_100"
            value="100"
            name="count"
            checked={parseInt(countPerPage, 10) === 100}
          />
        </Dropdown.Menu>
      </Dropdown>
    </PaginationContainer>
  );
};

export default Pagination;
