import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Form, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {useForm} from 'react-hook-form';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../../store';
import './profession.css';
import {Wrapper, RegisterDiv} from '../style';
import Analytics from '../../../analytics';
import {ButtonXLarge, Title1, SubTitle2, Caption} from '../../../utils/styles/typography';
import {amplitude} from '../../../utils/Amplitude';

const Specialty = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {registrationUIStore, authStore} = useStore();
  const {
    profession_specialtyType,
    specialtyList,
    educationList,
    getAllLists,
    onSetSpecialty,
    setActiveStep,
  } = authStore;

  const campaign = registrationUIStore.currentCampaign;

  const [specialty, setSpecialty] = useState([
    {
      specialty_id: 0,
      subspecialty_id: [],
    },
  ]);

  const {handleSubmit, register, errors, watch, setValue} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const watchSpecField = watch('specialty_id');
  const watchOtherSpecField = watch('otherSpecialty');
  const watchOtherSubSpecField = watch('otherSubspecialty');

  const eduForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const watchEduField = eduForm.watch('education_id');

  useEffect(() => {
    return Analytics.registerEmailConversionEvent;
  }, []);

  useEffect(() => {
    if (profession_specialtyType === 'education') {
      if (!educationList?.length) {
        setIsLoading(true);
        getAllLists(() => {
          setIsLoading(false);
        });
      }
    } else if (profession_specialtyType !== 'education') {
      if (!specialtyList?.length) {
        setIsLoading(true);
        getAllLists(() => {
          setIsLoading(false);
        });
      }
    }
  }, [profession_specialtyType, errors, eduForm.errors, educationList, specialtyList, getAllLists]);

  const onSetSpecialties = useCallback(
    (index, specialtyId) => {
      const Id = parseInt(specialtyId, 10);
      if (specialty[index] === undefined) setSpecialty([...specialty, {specialty_id: Id, subspecialty_id: []}]);
      else if (specialty[index]) {
        setSpecialty(sp =>
          sp.map((value, i) => (i === index ? {...value, specialty_id: Id, subspecialty_id: []} : value)),
        );
      }
    },
    [specialty],
  );

  useEffect(() => {
    if (campaign) {
      if (profession_specialtyType === 'education') {
        if (educationList?.length && !watchEduField && educationList.some(v => v.id === campaign.specialty_id)) {
          eduForm.setValue('education_id', campaign.specialty_id);
          onSetSpecialties(0, campaign.specialty_id);
        }
      } else if (profession_specialtyType !== 'education') {
        if (specialtyList?.length && !watchSpecField && specialtyList.some(v => v.id === campaign.specialty_id)) {
          setValue('specialty_id', campaign.specialty_id);
          setValue('mob_specialty_id', campaign.specialty_id);
          onSetSpecialties(0, campaign.specialty_id);
        }
      }
    }
  }, [
    profession_specialtyType,
    campaign,
    specialtyList,
    educationList,
    watchSpecField,
    watchEduField,
    eduForm,
    setValue,
    onSetSpecialties,
  ]);

  const onSetSubspecialty = (index, subspecialtyId) => {
    let subspecialty = specialty[index].subspecialty_id;
    if (subspecialtyId === 'none') subspecialty = [];
    else if (subspecialtyId !== 'none') {
      if (subspecialty.length > 0 && subspecialty.includes(subspecialtyId))
        subspecialty = subspecialty.filter(id => id !== subspecialtyId);
      else subspecialty = [...subspecialty, subspecialtyId];
    }
    setSpecialty(sp => sp.map((value, i) => (i === index ? {...value, subspecialty_id: subspecialty} : value)));
  };

  const onSetOtherSpecialty = index => {
    setSpecialty(sp =>
      sp.map((value, i) => (i === index - 1 ? {...value, otherSpecialty: watchOtherSpecField} : value)),
    );
  };

  const onSetOtherSubSpecialty = index => {
    setSpecialty(sp =>
      sp.map((value, i) => (i === index - 1 ? {...value, otherSubspecialty: watchOtherSubSpecField} : value)),
    );
  };

  const onSubmit = () => {
    onSetSpecialty(specialty);
  };

  const SpecialtyCompleted = () => {
    amplitude.getInstance().logEvent('specialty-completed-v3');
  };

  const EducationProgramCompleted = () => {
    amplitude.getInstance().logEvent('education-program-completed-v3');
  };

  const onBack = () => {
    setActiveStep('profession');
  };

  const renderOtherSpecialtyForm = index => {
    return (
      <>
        <Form.Group controlId="select-otherspecialty">
          <Form.Label>Specify Specialty</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your specialty"
            name="otherSpecialty"
            className={`bg-transparent step-field text-white ${
              watchOtherSpecField ? 'border-primary' : 'border-secondary'
            }`}
            ref={register({required: true})}
            isInvalid={!!errors.otherSpecialty}
            onChange={() => onSetOtherSpecialty(index)}
          />
          {errors.otherSpecialty && (
            <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
          )}
        </Form.Group>
      </>
    );
  };

  const renderSpecialtyForm = index => {
    const selectedSpecialty = specialtyList?.find(p => p.id === +specialty[index - 1].specialty_id);
    return (
      <>
        {selectedSpecialty?.name === 'Other' && renderOtherSpecialtyForm(index)}
        {selectedSpecialty ? (
          <Form>
            <Form.Group controlId="select-specialty">
              <Form.Label className="font-weight-bold">Additional Specialty, if applicable</Form.Label>
              <Form.Control
                as="select"
                className={`bg-transparent step-field text-white ${
                  specialty[index] && !Number.isNaN(specialty[index]?.specialty_id)
                    ? 'border-primary'
                    : 'border-secondary'
                }`}
                onChange={e => onSetSpecialties(index, e.target.value)}
              >
                <option value="">Select specialty</option>
                {specialtyList
                  .filter(s => s.type === profession_specialtyType)
                  .map(item => (
                    <option
                      key={item.id}
                      value={item.id}
                      disabled={specialty.findIndex(value => value.specialty_id === parseInt(item.id, 10)) !== -1}
                    >
                      {item.display_name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Form>
        ) : null}
        {specialty[index] ? renderSpecialtyForm(index + 1) : null}
      </>
    );
  };

  const renderSubSpecialtyForm = index => {
    if (specialty[index]) {
      const selectedSpecialty = specialtyList.find(p => p.id === +specialty[index].specialty_id);
      if (selectedSpecialty) {
        return selectedSpecialty?.name === 'Other' ? (
          <Form.Group controlId="select-othersubspecialty">
            <Form.Label>Specify subspecialty, if applicable</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your subspecialty"
              name="otherSubspecialty"
              className={`bg-transparent step-field text-white ${
                watchOtherSubSpecField ? 'border-primary' : 'border-secondary'
              }`}
              ref={register()}
              isInvalid={!!errors.otherSubspecialty}
              onChange={() => onSetOtherSubSpecialty(index)}
            />
          </Form.Group>
        ) : (
          <>
            <Form.Group controlId="select-subspecialty">
              <Form.Label className="w-100">
                <strong>{selectedSpecialty?.display_name}</strong>
                <Caption className="mt-1">Select all that apply</Caption>
              </Form.Label>
              <div className="subspecialty-btn">
                <Button
                  variant={specialty[index].subspecialty_id.length === 0 ? 'primary' : 'secondary'}
                  size="sm"
                  onClick={() => onSetSubspecialty(index, 'none')}
                >
                  none
                </Button>
                {selectedSpecialty?.children.length > 0
                  ? selectedSpecialty?.children
                      .filter(item => !item.display_name.includes('(General)'))
                      .map(item => (
                        <Button
                          variant={
                            specialty[index].subspecialty_id.length > 0 &&
                            specialty[index].subspecialty_id.includes(item.id)
                              ? 'primary'
                              : 'secondary'
                          }
                          size="sm"
                          className="text-left"
                          onClick={() => onSetSubspecialty(index, item.id)}
                        >
                          {item.display_name}
                        </Button>
                      ))
                  : null}
              </div>
            </Form.Group>
            {specialty[index + 1] ? renderSubSpecialtyForm(index + 1) : null}
          </>
        );
      }
    }
    return null;
  };

  const renderSpecialtyContainer = () => (
    <Wrapper className="d-sm-block m-auto" style={{maxWidth: 688}}>
      <Helmet>
        <title>About you</title>
        <meta name="description" content="About you section" />
      </Helmet>
      <Card className="card-specialty">
        <Card.Body>
          <Title1 className="text-center">Choose your specialty.</Title1>
          {isLoading ? (
            <div style={{width: '20rem'}} className="d-flex justify-content-center align-items-center p-5 h-100">
              <Spinner animation="grow" />
            </div>
          ) : (
            <>
              <Form onSubmit={handleSubmit(onSubmit)} id="submitForm">
                <Form.Group controlId="select-specialty">
                  <Form.Label className="font-weight-bold mt-2">Primary Specialty</Form.Label>
                  <Form.Control
                    as="select"
                    className={`bg-transparent step-field text-white ${
                      watchSpecField ? 'border-primary' : 'border-secondary'
                    }`}
                    name="specialty_id"
                    ref={register({required: true})}
                    isInvalid={!!errors.specialty_id}
                    onChange={e => onSetSpecialties(0, e.target.value)}
                  >
                    <option value="">Select primary specialty</option>
                    {specialtyList
                      .filter(s => s.type === profession_specialtyType)
                      .map(item => (
                        <option key={item.id} value={item.id}>
                          {item.display_name}
                        </option>
                      ))}
                  </Form.Control>
                  {errors.specialty_id && (
                    <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form>
              {watchSpecField ? renderSpecialtyForm(1) : null}
              <div className="mob pt-3">
                {watchSpecField ? (
                  <div className="w-100">
                    <Title1 className="text-center">Choose your subspecialty.</Title1>
                    <div className="subspecialty-div">{renderSubSpecialtyForm(0)}</div>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </Card.Body>
        {campaign ? <p className="campaign-profession-msg">{campaign.prompt}</p> : null}
        <div className="mob w-100">
          <RegisterDiv className="specialty-btns-div">
            <ButtonXLarge
              variant={!watchSpecField && (errors.mob_specialty_id || errors.otherSpecialty) ? 'secondary' : 'primary'}
              disabled={errors.mob_specialty_id || errors.otherSpecialty}
              id="AboutYou-Continue"
              className={`mb-2 a_continueToPlansPageButton ${watchSpecField ? 'bg-primary' : 'bg-secondary'}`}
              size="sm"
              type="submit"
              form="submitForm"
            >
              Continue
            </ButtonXLarge>
            <ButtonXLarge variant="secondary" type="button" id="specialty-Back" onClick={onBack}>
              Go back
            </ButtonXLarge>
          </RegisterDiv>
        </div>
      </Card>
      <Card className="card-specialty2">
        <Card.Body>
          <Title1 className="text-center">Choose your subspecialty.</Title1>
          {isLoading ? (
            <div style={{width: '20rem'}} className="d-flex justify-content-center align-items-center p-5 h-100">
              <Spinner animation="grow" />
            </div>
          ) : (
            <>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {watchSpecField ? (
                  <div className="subspecialty-div">{renderSubSpecialtyForm(0)}</div>
                ) : (
                  <>
                    <div className="text-center py-3">
                      <i className="fal fa-hand-pointer" />
                    </div>
                    <SubTitle2>Select specialty first to view subspecialty.</SubTitle2>
                  </>
                )}
                <RegisterDiv className="specialty-btns-div">
                  <ButtonXLarge
                    variant={
                      !watchSpecField && (errors.specialty_id || errors.otherSpecialty) ? 'secondary' : 'primary'
                    }
                    disabled={errors.specialty_id || errors.otherSpecialty}
                    id="AboutYou-Continue"
                    className={`a_continueToPlansPageButton mb-2 ${watchSpecField && 'bg-primary'}`}
                    type="submit"
                    onClick={SpecialtyCompleted}
                  >
                    Continue
                  </ButtonXLarge>
                  <ButtonXLarge variant="secondary" type="button" id="specialty-Back" onClick={onBack}>
                    Go back
                  </ButtonXLarge>
                </RegisterDiv>
              </Form>
            </>
          )}
        </Card.Body>
      </Card>
      <div style={{clear: 'both'}} />
    </Wrapper>
  );

  return (
    <>
      {profession_specialtyType === 'education' ? (
        <Wrapper>
          <Card>
            <Card.Body>
              <Title1 className="text-center">Choose your education program</Title1>
              {isLoading ? (
                <div style={{width: '20rem'}} className="d-flex justify-content-center align-items-center p-5 h-100">
                  <Spinner animation="grow" />
                </div>
              ) : (
                <>
                  <Form onSubmit={eduForm.handleSubmit(onSubmit)} id="eduForm">
                    <Form.Group controlId="select-specialty">
                      <Form.Label className="font-weight-bold">Education Program</Form.Label>
                      <Form.Control
                        as="select"
                        className={`bg-transparent step-field text-white ${watchEduField && 'border-primary'} ${
                          errors.education_id && 'border-danger'
                        }`}
                        name="education_id"
                        ref={eduForm.register({required: true})}
                        isInvalid={!!eduForm.errors.education_id}
                        onChange={e => onSetSpecialties(0, e.target.value)}
                      >
                        <option value="">Select education program</option>
                        {educationList.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.display_name}
                          </option>
                        ))}
                      </Form.Control>
                      {eduForm.errors.education_id && (
                        <Form.Control.Feedback type="invalid">This field is required</Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Form>
                </>
              )}
            </Card.Body>
            <RegisterDiv>
              <ButtonXLarge
                variant={!watchEduField && eduForm.errors.education_id ? 'secondary' : 'primary'}
                disabled={!watchEduField}
                id="AboutYou-Continue"
                className={`a_continueToPlansPageButton mb-2 ${watchEduField ? 'bg-primary' : 'secondary'}`}
                form="eduForm"
                type="submit"
                onClick={EducationProgramCompleted}
              >
                Continue
              </ButtonXLarge>
              <ButtonXLarge variant="secondary" type="button" id="specialty-Back" onClick={onBack}>
                Go back
              </ButtonXLarge>
            </RegisterDiv>
          </Card>
        </Wrapper>
      ) : (
        renderSpecialtyContainer()
      )}
    </>
  );
};

export default observer(Specialty);
