import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Helmet} from 'react-helmet';
import {Container} from 'react-bootstrap';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import AlertComponent from '../../components/AlertComponent';
import VideoListCard from '../../components/VideoListCard';
import {useStore} from '../../store';
import UpgradeModal from '../../components/UpgradeModal';
import HistoryTop from '../../components/HistoryTop';
import HistoryBottom from '../../components/HistoryBottom';

// History page

const History = observer(() => {
  const {bookmarkStore, alertStore, accountStore} = useStore();

  const {
    historyVideos,
    listHistory,
    playlist,
    getAllPlaylists,
    historyTotalCount,
    setResetSort,
    isSorting,
    sortBy,
    historyPage,
    historySearchText,
  } = bookmarkStore;

  const {message, status, resetAlert} = alertStore;

  const {account} = accountStore;

  const [isShowUpgradeModal, setShowUpgradeModal] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setResetSort();
  }, [setResetSort]);

  useEffect(() => {
    listHistory();
  }, [historyPage, sortBy, historyTotalCount, listHistory, historySearchText, isSorting]);

  useEffect(() => {
    getAllPlaylists();
  }, [getAllPlaylists]);

  return (
    <div id="history" className="d-flex">
      <Helmet>
        <title>GIBLIB</title>
        <meta
          name="description"
          content="Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:image"
          content="https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png"
        />
        <meta
          name="image"
          property="og:image"
          content="https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png"
        />
      </Helmet>
      <Sidebar />
      <div className="w-100">
        <Navbar />
        {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
        <div className="w-100">
          <HistoryTop type="History" />
          <Container>
            {account?.subscription?.name || (account?.access?.status === 'Active' && account?.access?.notes) ? (
              (historyVideos || []).map((item, index) => (
                <VideoListCard item={historyVideos[index]} playlist={playlist} />
              ))
            ) : (
              <UpgradeModal
                access="bookmark"
                isShow={isShowUpgradeModal}
                onHideModal={() => setShowUpgradeModal(false)}
              />
            )}
          </Container>
          <HistoryBottom type="History" />
          <Footer />
        </div>
      </div>
    </div>
  );
});

export default History;
