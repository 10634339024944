// @flow
import * as React from 'react';
import {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../store';
import {containsAny} from '../../utils/helper';

type Props = {
  title: string,
  children: React.Node,
  destination?: string,
  viewAll?: boolean,
  type: string,
};

const Container = styled.div`
  .title {
    font-size: 18px;
    line-height: 24px;

    @media screen and (max-width: 576px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .view {
    font-size: 13px !important;
    letter-spacing: 0;
    color: #30a0ff;
    &:focus {
      outline: none;
    }
    @media screen and (max-width: 576px) {
      font-size: 10px !important;
  }
`;
const replaceTags = ['SPECIALTY', 'INSTITUTION', 'NAME'];

const Section = observer(({title, children, destination, viewAll, tags}: Props) => {
  const [newTitle, setNewTitle] = useState(title);
  const {accountStore} = useStore();
  const {account} = accountStore;

  useEffect(() => {
    const substituteTag = tag => {
      let replace = tag;
      const specialties = [];
      switch (tag) {
        case 'SPECIALTY':
          if (account?.specialties?.length) {
            specialties.push(...new Set(account.specialties.map(s => s.name)));
          }
          if (specialties.length) {
            [replace] = specialties;
          } else {
            replace = account.specialty ? account.specialty.name : 'your specialty';
          }
          break;
        case 'INSTITUTION':
          replace = account.institute ? account.institute.name : tag;
          break;
        case 'NAME':
          replace = `${account.first_name} ${account.last_name}`;
          break;
        default:
          replace = tag;
          break;
      }
      return replace;
    };

    const match = containsAny(title, replaceTags);

    if (match.status) {
      const newStr = title.replace(match.tag, substituteTag(match.tag));
      setNewTitle(newStr);
    }
  }, [title, account]);
  const location = useHistory();

  return (
    <Container className="container" id={tags}>
      <div className="section-header mb-2 mb-sm-3 mt-4 d-flex justify-content-between font-weight-bold title mx-2">
        {newTitle}
        {viewAll ? (
          <span
            className="view"
            role="button"
            tabIndex={0}
            onClick={() => (destination ? location.push(destination) : {})}
            onKeyDown={() => (destination ? location.push(destination) : {})}
          >
            View All
          </span>
        ) : (
          <></>
        )}
      </div>
      {children}
    </Container>
  );
});

Section.defaultProps = {
  viewAll: false,
  destination: '',
};

export default Section;
