// @flow
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Container, OverlayTrigger} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import LocalStorageService from '../../../utils/LocalStorageService';
import {FinishAssessmentButton} from '../../../components/AssessmentButton';
import {DeleteModal} from './DeleteModal';
import AlertComponent from '../../../components/AlertComponent';
import PlaylistPopover from '../../../components/PlaylistPopover';
import UpgradeModal from '../../../components/UpgradeModal';
import {useStore} from '../../../store';
import Thumbnail from '../elements/video-thumbnail.png';
import {
  ThumbnailDiv,
  ThumbnailImg,
  ButtonGroup,
  VideoTitle,
  Organization,
  Details,
  VideoCardHeader,
  VideoCard,
  DetailDiv,
} from '../elements/style';
import {secondsToTime} from '../../../utils/helper';

// item: {
//     id: number,
//     started_at: Date,
//     quiz_completed_at: Date,
//     certificate_downloaded_at: Date,
//     is_certificate_downloaded: Boolean,
//     answers: [],
//     certificate_url: null,
//     user_id: 32213,
//     video_id: 2646,
//     created_at: '2024-03-07T09:44:30.529Z',
//     updated_at: '2024-03-07T09:44:49.130Z',
//     deleted_at: null,
//     video: {
//       id: 2646,
//       duration: 797,
//       title: 'Women&#8217;s Health Update',
//       thumbnail: 'https://wp-media-development.giblib.com/wp-content/uploads/2022/01/21041327/image_7225_202201202313.jpg',
//       organization: {
//         name: 'Mayo Clinic',
//       },
//       bookmark: [],
//       playlist: [],
//       specialties: [],
//     },
//   },

const CertificateInProgressCard = observer(({item}: Props) => {
  const location = useHistory();
  const {videoStore, accountStore, certificateStore, alertStore} = useStore();
  const {account} = accountStore;
  const {message, status, resetAlert} = alertStore;
  const {
    showQuestions,
    deleteInProgressCertificate,
    setVideoId,
    getCertificateQuestions,
    setvideoTitle,
    setshowQuestionBlock,
  } = certificateStore;

  const {playlist, getAllPlaylists, addToPlaylist, updatePlaylist, toggleVideoBookmark} = videoStore;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);
  const handleDeleteModalClose = () => setShowDeleteModal(false);

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const loggedInUser = LocalStorageService.getUser();

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) {
      setUserState(true);
      getAllPlaylists();
    }
  }, [account, getAllPlaylists]);

  const createAndAddToPlaylist = data => {
    addToPlaylist(item?.video?.id, data.listname, true);
  };

  const updateExistingPlaylist = data => {
    updatePlaylist(item?.video?.id, data, true);
  };

  const [bookmarkIcon, setBookmarkIcon] = useState(false);

  useEffect(() => {
    if (item?.video?.bookmark?.length) setBookmarkIcon(true);
  }, [item]);

  const toggleBookmark = () => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic')) {
        toggleVideoBookmark(bookmarkIcon, item?.video?.id).then(() => {
          setBookmarkIcon(!bookmarkIcon);
        });
      } else setShowBookmarkUpgradeModal(true);
    } else location.push('/login');
  };

  const handleSubmitForm = () => {
    deleteInProgressCertificate(item.id);
    setShowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    handleDeleteModalShow();
  };

  const openVideo = video => {
    location.push(`/video/${video.id}`);
  };

  const onfinishAssessmentbtnClick = () => {
    showQuestions(true);
    setVideoId(item?.video?.id);
    setvideoTitle(item?.video?.title);
    getCertificateQuestions();
    setshowQuestionBlock(true);
  };

  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
      <VideoCard>
        <Container>
          <div className="card-border-bottom d-md-flex card-padding" id={item.id}>
            <div className="detail-container w-100 d-flex">
              <ThumbnailDiv>
                <ThumbnailImg
                  src={item?.video?.thumbnail || Thumbnail}
                  alt={item?.video?.title}
                  onClick={() => openVideo(item?.video)}
                />
              </ThumbnailDiv>
              <DetailDiv className="w-100">
                <VideoCardHeader className="d-flex">
                  <div className="d-flex">
                    <div className="progress-badge inProgress-badge">In Progress</div>
                  </div>
                </VideoCardHeader>
                <VideoTitle onClick={() => openVideo(item?.video)}>
                  <h5>{item?.video?.title}</h5>
                </VideoTitle>
                <Organization>
                  {item?.video?.organization?.name}
                  {item?.video?.organization?.name && item?.video?.specialties.length > 0 ? ' | ' : ''}
                  {item?.video?.specialties.length > 0
                    ? `${item?.video?.specialties
                        .slice(0, 2)
                        ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                    : ''}
                </Organization>
                <Details>{secondsToTime(item?.video?.duration || 0)}</Details>
              </DetailDiv>
            </div>
            <ButtonGroup className="button-alignment">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={props => (
                  <PlaylistPopover
                    props={props}
                    videoPlaylist={item?.video?.playlist}
                    allPlaylist={playlist}
                    onExisting={updateExistingPlaylist}
                    onNew={createAndAddToPlaylist}
                    userState={userState}
                    showUpgradeModal={isShowPlaylistUpgradeModal}
                    onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                  />
                )}
                rootClose
              >
                <Button
                  variant="basic"
                  className="a_playlist action-icon-button mr-3"
                  data-tip="Playlist"
                  data-for="videoActions"
                  onClick={() => setShowPlaylistUpgradeModal(true)}
                >
                  <i className="fak fa-list-ul" />
                </Button>
              </OverlayTrigger>
              <Button
                variant="basic"
                onClick={() => toggleBookmark()}
                className="a_bookmark action-icon-button mr-3"
                data-tip="Bookmark"
                data-for="videoActions"
              >
                {bookmarkIcon ? (
                  <span key="video_bookmark">
                    <i className="fas fa-bookmark" />
                  </span>
                ) : (
                  <span key="video_bookmark_active">
                    <i className="far fa-bookmark" />
                  </span>
                )}
              </Button>
              <Button variant="basic" className="action-icon-button a_remove" onClick={handleDeleteModal}>
                <i className="fa fa-trash" />
              </Button>

              <FinishAssessmentButton onClick={onfinishAssessmentbtnClick} watched />
            </ButtonGroup>
          </div>
        </Container>
      </VideoCard>
      {/* Update Modal to delete cme */}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteModalClose={handleDeleteModalClose}
        handleSubmitForm={handleSubmitForm}
        videoTitle={item?.video?.title}
        tabName="In-Progress"
      />
    </>
  );
});

export default CertificateInProgressCard;
