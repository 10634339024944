import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import {getUserMessage, sendMessage, fetchMessageList, getSignedS3Url} from '../../api/message';
import ErrorStore from '../error/errorStore';

class MessageStore {
  messages = [];

  receivers = [];

  unreadMessage = [];

  isUnReadMessage = false;

  unreadMessageSenderIds = [];

  errorStore: ErrorStore;

  constructor({errorStore}: {errorStore: ErrorStore}) {
    this.errorStore = errorStore;
    makeAutoObservable(this, {
      addMessage: action,
      messages: observable,
    });
  }

  fetchUsers = async () => {
    try {
      const res = await getUserMessage();
      if (res.status) {
        const receiverDetails = res?.data?.receiver;
        const unreadMessages = res?.data?.unreadMessages;
        const unreadMessageSenderArray = res?.data?.messageArray;
        const senderIds = unreadMessageSenderArray.map(message => message.sender_id);
        runInAction(() => {
          this.receivers = receiverDetails;
          if (unreadMessages) {
            this.unreadMessage = unreadMessages;
          }
          if (senderIds) {
            this.unreadMessageSenderIds = senderIds;
          }
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  sendDirectMessage = async (data, successCB, errorCB) => {
    try {
      const response = await sendMessage(data);
      if (response.status) {
        runInAction(() => {
          const createdMessage = response?.data?.createMessage;
          if (createdMessage) {
            this.messages.push(createdMessage);
          }
          successCB(response.data);
        });
      }
    } catch (error) {
      errorCB(error);
      this.errorStore.setError(error);
    }
  };

  fetchMessages = async user_id => {
    try {
      this.messages = [];

      const response = await fetchMessageList(user_id);
      if (response.status) {
        const allMessages = response?.data?.fetchMessages;
        runInAction(() => {
          this.messages = allMessages;
          const index = this.unreadMessageSenderIds.indexOf(user_id);
          if (index !== -1) {
            this.unreadMessageSenderIds.splice(index, 1);
          }
          if (this.unreadMessageSenderIds.length === 0) {
            this.unreadMessage = [];
          }
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  addMessage = message => {
    if (message) {
      runInAction(() => {
        this.messages.push(message);
      });
    }
  };

  getS3urlForFile = async (name, putOrGet, successCB) => {
    try {
      const res = await getSignedS3Url(name, putOrGet);
      if (res.status) {
        const url = res.data || null;
        runInAction(() => {
          successCB(url);
        });
      }
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default MessageStore;
