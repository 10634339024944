import React from 'react';
import {observer} from 'mobx-react-lite';
import {Col, Form, Card, Container} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
// import FacebookLogin from 'react-fb-social-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {useStore} from '../../store';
import './login.css';
import {Logo, Line} from './login';
import logo from '../../assets/images/GBLB_Logo_Horizontal_Notag_White_FullColor.png';
import {RegisterContainer, Wrapper} from '../Register/style';
import RegNavBar from '../Register/components/RegNavBar';
import {Body2, ButtonXLarge, Caption} from '../../utils/styles/typography';
// import EBSCOLogin from './components/EBSCO';

const Login = observer(() => {
  const {authStore} = useStore();
  const {loginResponseText, loginEmailResponseText, onLogin, onSocialLogin, onResetResponseText} = authStore;
  const {register, handleSubmit, watch, errors} = useForm();

  const watchField = watch('email', false);
  const watchPWField = watch('password', false);

  const params = new URLSearchParams(window.location.search);
  const oauth_error = params.get('error') === 'oauth_error';

  /** calls the onLogin function from the store to perform the login and redirect to home page on success and sets error on failure
   * @params {object} data - contains email address and password
   */
  const onSubmit = data => {
    onLogin(data);
  };

  // const responseFacebook = response => {
  //   const socialLoginType = 'facebook';
  //   onSocialLogin(response, socialLoginType);
  // };

  const responseGoogle = response => {
    const socialLoginType = 'google';
    onSocialLogin(response, socialLoginType);
  };

  const onChange = () => {
    onResetResponseText();
  };

  return (
    <RegisterContainer>
      <Helmet
        title="GIBLIB"
        meta={[
          {
            name: 'description',
            content:
              "Stream surgical videos and medical lectures from subject matter experts at the leading academic medical institutions on demand with unlimited access by being a subscriber to GIBLIB today. Earn CME with GIBLIB's premium plan while learning from the world's best doctors, online.",
          },
          {
            property: 'og:title',
            content: 'GIBLIB',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            name: 'image',
            property: 'og:image',
            content: 'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png',
          },
          {
            property: 'og:url',
            content: window.location.origin + window.location.pathname,
          },
        ]}
      />
      <RegNavBar />
      <Container className="py-3 py-sm-5" fluid>
        <Wrapper>
          <Card>
            <Card.Body>
              {oauth_error && (
                <div className="alert alert-danger" role="alert">
                  There was an error logging in with a third-party. Please try again.
                </div>
              )}
              <div className="text-center">
                <Link to="/">
                  <Logo src={logo} alt="GIBLIB" className="logo" />
                </Link>
              </div>
              <div className="my-3 text-center">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  render={renderProps => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      type="button"
                      id="login-google-button"
                      className="a_loginGoogleButton"
                    >
                      <i className="fab fa-google" />
                      Log in with Google
                    </button>
                  )}
                  buttonText="LOGIN WITH GOOGLE"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  id="login-google-button"
                  className="a_loginGoogleButton"
                />
              </div>
              {/* <div className="d-flex justify-content-center mb-2">
                <FacebookLogin
                  appId={process.env.REACT_APP_FB_APP_ID}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  id="login-facebook-button"
                  className="a_loginFacebookButton"
                  render={renderProps => (
                    <button
                      onClick={renderProps.onClick}
                      type="button"
                      id="login-facebook-button"
                      className="a_loginFacebookButton pl-4"
                    >
                      <i className="fab fa-facebook-f float-left mt-1" />
                      Log in with Facebook
                    </button>
                  )}
                />
              </div> */}
              <div className="text-center my-3">
                <Line className="mr-1" />
                <Caption className="d-inline">OR</Caption>
                <Line className="ml-1" />
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} className="loginForm">
                <Form.Row>
                  <Form.Group as={Col} sm={12} controlId="emailAddress">
                    <Form.Label className="font-weight-bold">Email</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Enter email"
                      name="email"
                      ref={register({
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      })}
                      className={`bg-transparent text-white ${
                        watchField && (!errors.email && !loginEmailResponseText ? 'border-primary' : 'border-danger')
                      }`}
                      onChange={onChange}
                    />
                    {errors.email && <Caption className="text-danger mt-1">{errors.email.message}&nbsp;</Caption>}
                    {loginEmailResponseText && (
                      <Caption className="text-danger mt-1">{loginEmailResponseText}&nbsp;</Caption>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm={12} controlId="password">
                    <Form.Label className="font-weight-bold">Password</Form.Label>
                    <Form.Control
                      size="sm"
                      type="password"
                      placeholder="Enter password"
                      name="password"
                      autocomplete="on"
                      ref={register({required: true, minLength: 8})}
                      className={`bg-transparent text-white ${
                        errors.password || loginResponseText ? 'border-danger' : watchPWField && 'border-primary'
                      }`}
                      onChange={onChange}
                    />
                    {errors.password
                      ? (errors.password.type === 'required' && (
                          <Caption className="text-danger mt-1">This field is required.</Caption>
                        )) ||
                        (errors.password.type === 'minLength' && (
                          <Caption className="text-danger mt-1">
                            The passwords should have at least 8 characters.
                          </Caption>
                        ))
                      : null}
                    {!errors.email && !errors.password && !loginEmailResponseText && (
                      <Caption id="login-Status-Text" className="text-danger mt-1 mb-0 a_loginResponseText">
                        {loginResponseText && 'Email and password do not match.'}
                      </Caption>
                    )}
                  </Form.Group>
                </Form.Row>
                <ButtonXLarge
                  variant={watchField && watchPWField && !errors.email && !errors.password ? 'primary' : 'secondary'}
                  className="a_loginButton"
                  type="submit"
                  id="login_confirm"
                >
                  Log in
                </ButtonXLarge>
                <a href="/forgot_password" className="mt-4 d-block a_loginForgotPassword" id="forgot-password">
                  Forgot password?
                </a>
                <Form.Row className="justify-content-center mt-3">
                  <Body2 className="a_loginStartFreeTrial">
                    New to GIBLIB?&nbsp;
                    <a href="/register" className="mt-1 font-weight-bold">
                      Create an account
                    </a>
                  </Body2>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Wrapper>
      </Container>
    </RegisterContainer>
  );
});

export default Login;
